export const WebSpotlightZIndex: Readonly<{
  InactiveResizeHandle: number;
  PreviewIFrame: number;
  PreviewErrorNotification: number;
  CommentsPane: number;
}> = {
  InactiveResizeHandle: 0,
  PreviewIFrame: 1,
  PreviewErrorNotification: 2,
  CommentsPane: 3,
};
