import { delay } from '@kontent-ai/utils';
import Immutable from 'immutable';
import { Action } from '../../../../../../@types/Action.type.ts';
import { ThunkFunction, ThunkPromise } from '../../../../../../@types/Dispatcher.type.ts';
import { trackUserEvent } from '../../../../../../_shared/actions/thunks/trackUserEvent.ts';
import { TrackedEvent } from '../../../../../../_shared/constants/trackedEvent.ts';
import { TrackUserEventAction } from '../../../../../../_shared/models/TrackUserEvent.type.ts';
import { ContentComponentActionType } from '../../../../../../_shared/models/TrackUserEventData.ts';
import {
  ContentItemEditingEventOrigins,
  ContentItemEditingExpandCollapseAction,
} from '../../../../../../_shared/models/events/ContentItemEditingEventData.type.ts';
import { ICommentThread } from '../../../../models/comments/CommentThreads.ts';
import { ContentItemEditing_ContentComponents_Collapsed } from '../../constants/contentItemEditingActionTypes.ts';
import { getAllContentComponentIdsFromElements } from '../../utils/contentComponentUtils.ts';

const contentComponentsCollapsed = (contentComponentIds: Immutable.Set<Uuid>) =>
  ({
    type: ContentItemEditing_ContentComponents_Collapsed,
    payload: { contentComponentIds },
  }) as const;

export type CollapseAllContentComponentsActionsType = ReturnType<typeof contentComponentsCollapsed>;

interface IDeps {
  readonly deactivateContentItemEditingAction: () => Action;
  readonly blurCommentThread: () => ThunkFunction;
  readonly trackUserEvent: TrackUserEventAction;
}

export const createCollapseAllContentComponentsAction =
  (deps: IDeps) => (): ThunkPromise => async (dispatch, getState) => {
    const {
      editedContentItemVariant,
      editedContentItemVariantComments,
      editedContentItemVariantElements,
    } = getState().contentApp;

    if (!editedContentItemVariant) {
      throw new Error('This is available only in edited content item');
    }

    dispatch(deps.deactivateContentItemEditingAction());

    const contentComponentIdsToBeCollapsed = getAllContentComponentIdsFromElements(
      editedContentItemVariantElements,
    );
    const focusedThread = editedContentItemVariantComments.commentThreads.find(
      (ct: ICommentThread) => ct.id === editedContentItemVariantComments.focusedCommentThreadId,
    );

    if (focusedThread && focusedThread.contentComponentId !== null) {
      dispatch(deps.blurCommentThread());
    }

    // Let the preparation finish and collapse the components with a bit of breathing space for React
    // as it may be a huge expensive unmount in case of many content components
    await delay(0);
    dispatch(contentComponentsCollapsed(contentComponentIdsToBeCollapsed));
    dispatch(
      deps.trackUserEvent(TrackedEvent.ContentComponentCollapse, {
        action: ContentComponentActionType.CollapseAll,
        'item-id': editedContentItemVariant.id.itemId,
        'variant-id': editedContentItemVariant.id.variantId,
      }),
    );

    dispatch(
      trackUserEvent(TrackedEvent.ContentItemEditing, {
        action: ContentItemEditingExpandCollapseAction.CollapseComponents,
        origin: ContentItemEditingEventOrigins.MoreActions,
      }),
    );
  };
