import { InvariantException } from '@kontent-ai/errors';
import { Collection } from '@kontent-ai/utils';
import Immutable from 'immutable';
import { Dispatch, ThunkPromise } from '../../../../../@types/Dispatcher.type.ts';
import { TrackedEvent } from '../../../../../_shared/constants/trackedEvent.ts';
import { TrackUserEventAction } from '../../../../../_shared/models/TrackUserEvent.type.ts';
import { AssetBulkActionEventTypes } from '../../../../../_shared/models/TrackUserEventData.ts';
import { logError } from '../../../../../_shared/utils/logError.ts';
import { AssetsRequestTrigger } from '../../../../../_shared/utils/scrollGridUtils.ts';
import { IAsset } from '../../../../../data/models/assets/Asset.ts';
import {
  IMoveAssetItemToFolderServerModel,
  IMoveAssetsToFolderResultServerModel,
} from '../../../../../repositories/serverModels/AssetServerModels.type.ts';
import {
  AssetLibrary_Assets_MoveToFolderCompleted,
  AssetLibrary_Assets_MoveToFolderStarted,
  AssetLibrary_Assets_MovingToFolderFailed,
} from '../../constants/assetLibraryActionTypes.ts';
import { LoadListingAssets } from '../../types/depsTypes.type.ts';
import { AssetFolderReference } from '../../types/lastAssetsAction.ts';
import { AssetsOrderBy } from '../../types/orderBy.type.ts';

interface IDeps {
  readonly assetService: {
    readonly moveAssetsToFolder: (
      items: ReadonlyArray<IMoveAssetItemToFolderServerModel>,
    ) => Promise<IMoveAssetsToFolderResultServerModel>;
  };
  readonly loadListingAssets: LoadListingAssets;
  readonly trackUserEvent: TrackUserEventAction;
}

const started = () =>
  ({
    type: AssetLibrary_Assets_MoveToFolderStarted,
  }) as const;

const completed = (successfulIds: ReadonlyMap<Uuid, AssetFolderReference>) =>
  ({
    type: AssetLibrary_Assets_MoveToFolderCompleted,
    payload: {
      successfulIds,
    },
  }) as const;

const failed = (errorMessage: string) =>
  ({
    type: AssetLibrary_Assets_MovingToFolderFailed,
    payload: { errorMessage },
  }) as const;

export type MoveSelectedAssetsToFolderActionsType = ReturnType<
  typeof started | typeof completed | typeof failed
>;

type Args = {
  readonly assetsById: Immutable.Map<Uuid, IAsset>;
  readonly orderBy: AssetsOrderBy;
  readonly selectedAssets: ReadonlySet<Uuid>;
  readonly targetFolderId: Uuid;
};

export const createMoveSelectedAssetsToFolderAction =
  (deps: IDeps) =>
  ({
    assetsById,
    orderBy,
    selectedAssets,
    targetFolderId,
  }: Args): ThunkPromise<ReadonlySet<Uuid>> =>
  async (dispatch: Dispatch): Promise<ReadonlySet<Uuid>> => {
    dispatch(started());

    const getAsset = (id: Uuid): IAsset | null => {
      const asset = assetsById.get(id);

      if (!asset) {
        logError(`${__filename}: Asset with id '${id}' was not found in data store`);
        return null;
      }

      return asset;
    };

    try {
      const itemsToMove = Array.from(selectedAssets)
        .filter((id) => {
          const asset = getAsset(id);

          return asset && asset.folderId !== targetFolderId;
        })
        .map((assetId: Uuid): IMoveAssetItemToFolderServerModel => ({ assetId, targetFolderId }));

      const result = await deps.assetService.moveAssetsToFolder(itemsToMove);
      const movedAssetsIds = Collection.filter(selectedAssets, (assetId) =>
        result.movedAssetsIds.includes(assetId),
      );

      const successfulIds = Array.from(movedAssetsIds).reduce<
        ReadonlyArray<[Uuid, AssetFolderReference]>
      >((reduced, assetId) => {
        const asset = getAsset(assetId);

        return asset ? [...reduced, [assetId, { id: asset.folderId }]] : reduced;
      }, []);

      dispatch(completed(new Map(successfulIds)));
      dispatch(
        deps.trackUserEvent(TrackedEvent.AssetBulkAction, {
          action: AssetBulkActionEventTypes.MovedToFolder,
          count: movedAssetsIds.size,
        }),
      );

      await dispatch(
        deps.loadListingAssets({
          orderBy,
          requestTrigger: AssetsRequestTrigger.FetchAfterBulkAction,
        }),
      );

      return movedAssetsIds;
    } catch (error) {
      dispatch(failed('Assets could not be moved.'));

      throw InvariantException(`${__filename}: ${error}`);
    }
  };
