import { InputState } from '@kontent-ai/component-library/Input';
import { ISelectItem } from '@kontent-ai/component-library/Selects';
import { PortalContainerContextProvider } from '@kontent-ai/component-library/context';
import React, { useCallback, useMemo } from 'react';
import { ScrollContainerContextProvider } from '../../../../../../../../component-library/components/ScrollContainer/ScrollContainerContext.tsx';
import { FormGroup } from '../../../../../../../_shared/components/input/FormGroup.tsx';
import { ValidatedDateTimePicker } from '../../../../../../../_shared/components/input/ValidatedDateTimePicker.tsx';
import { ValidatedSingleSelect } from '../../../../../../../_shared/components/input/ValidatedSingleSelect.tsx';
import { ValidatedTextArea } from '../../../../../../../_shared/components/input/ValidatedTextArea.tsx';
import { HookFormProps } from '../../../../../../../_shared/types/hookFormProps.type.ts';
import {
  DataUiCollection,
  getDataUiCollectionAttribute,
} from '../../../../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';
import {
  formatCurrentUserName,
  formatUserName,
  makeCurrentUserFirst,
  userLastNameComparer,
} from '../../../../../../../_shared/utils/users/usersUtils.ts';
import { IProjectContributor } from '../../../../../../../data/models/users/ProjectContributor.ts';
import { DatetimePickerPlaceholder } from '../../../../../../projects/constants/UIConstants.ts';
import { UnableToSeeTaskErrorMessage } from '../../../constants/uiConstants.ts';
import { ITaskFormShape } from '../../../models/ITaskFormShape.type.ts';
import { FormPane } from '../FormPane.tsx';

export type TaskFormSharedProps = {
  readonly allActiveContributors: ReadonlyArray<IProjectContributor>;
};

type Props = TaskFormSharedProps & {
  readonly assigneesWithoutAccess: ReadonlyArray<IProjectContributor>;
  readonly currentUserId: Uuid;
  readonly formProps: HookFormProps<ITaskFormShape>;
  readonly formStateDisabled: boolean;
  readonly formRef: React.RefObject<HTMLFormElement>;
  readonly onReset: () => void;
  readonly onSubmit: () => void;
};

type UserOption = IProjectContributor & ISelectItem<UserOption>;

const getUserSelectorStatus = (
  formDisabledState: boolean,
  assigneesWithoutAccess: ReadonlyArray<IProjectContributor>,
): InputState => {
  if (formDisabledState) {
    return InputState.Disabled;
  }
  if (assigneesWithoutAccess.length > 0) {
    return InputState.Alert;
  }

  return InputState.Default;
};

export const TaskForm: React.FC<Props> = (props) => {
  const { allActiveContributors, currentUserId, formRef } = props;

  const mapProjectContributorToUserOption = useCallback(
    (contributor: IProjectContributor): UserOption => {
      return {
        ...contributor,
        label: formatCurrentUserName(currentUserId)(contributor),
        id: contributor.userId,
      };
    },
    [currentUserId],
  );

  const options = useMemo(
    () =>
      makeCurrentUserFirst(
        [...allActiveContributors].sort(userLastNameComparer),
        currentUserId,
      ).map(mapProjectContributorToUserOption),
    [allActiveContributors, currentUserId, mapProjectContributorToUserOption],
  );

  return (
    <PortalContainerContextProvider portalContainerRef={formRef}>
      <ScrollContainerContextProvider tippyBoundaryRef={null}>
        <FormPane onReset={props.onReset} onSubmit={props.onSubmit} ref={formRef}>
          <FormGroup>
            <ValidatedTextArea<ITaskFormShape>
              autoFocus
              formProps={props.formProps}
              isDisabled={props.formStateDisabled}
              label="Task description"
              name="taskDescription"
              placeholder="Type what needs to be done..."
              rows={3}
            />
          </FormGroup>
          <FormGroup>
            <ValidatedSingleSelect<ITaskFormShape, UserOption>
              verticalMenuDataAttributes={getDataUiCollectionAttribute(
                DataUiCollection.Contributors,
              )}
              formProps={props.formProps}
              inputState={getUserSelectorStatus(
                props.formStateDisabled,
                props.assigneesWithoutAccess,
              )}
              label="Assign to"
              name="assignedContributorId"
              items={options}
              placeholder="Type a name or select..."
              caption={
                props.assigneesWithoutAccess.length > 0
                  ? UnableToSeeTaskErrorMessage(props.assigneesWithoutAccess.map(formatUserName))
                  : undefined
              }
            />
          </FormGroup>
          <FormGroup>
            <ValidatedDateTimePicker<ITaskFormShape>
              defaultDate=""
              defaultTime="16:00"
              disabled={props.formStateDisabled}
              formProps={props.formProps}
              isFullWidth
              label="Due date"
              name="taskDueDate"
              placeholder={DatetimePickerPlaceholder}
            />
          </FormGroup>
        </FormPane>
      </ScrollContainerContextProvider>
    </PortalContainerContextProvider>
  );
};

TaskForm.displayName = 'TaskForm';
