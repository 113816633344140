import { forwardRef } from 'react';
import { ConfirmationDialog } from '../../../../../../_shared/components/ModalDialog/ConfirmationDialog.tsx';
import { IAnimatedModalDialogProps } from '../../../../../../_shared/components/ModalDialog/IAnimatedModalDialogProps.type.ts';
import { AppNames } from '../../../../../../_shared/constants/applicationNames.ts';
import { Unpublish } from '../../../../../../_shared/constants/itemActions.ts';
import { useDispatch } from '../../../../../../_shared/hooks/useDispatch.ts';
import { compose } from '../../../../../../_shared/utils/func/compose.ts';
import { itemEditingModalDismissed } from '../../../../actions/contentActions.ts';
import { undoCascadeAction } from '../../../CascadePublish/actions/thunkCascadeModalActions.ts';

export const CascadeUnpublishConfirmationDialog = forwardRef<
  HTMLDivElement,
  IAnimatedModalDialogProps
>((props, ref) => {
  const dispatch = useDispatch();

  return (
    <ConfirmationDialog
      alert
      confirmButtonText={Unpublish}
      confirmButtonIcon="Box"
      headerContent="Unpublish and archive content items"
      onConfirm={compose(dispatch, undoCascadeAction)}
      onClose={compose(dispatch, itemEditingModalDismissed)}
      ref={ref}
      {...props}
    >
      <p className="confirmation-dialog__section">
        Unpublishing these items will make their content read-only and unavailable live. Their
        workflow step changes to Archived.
      </p>
      <p className="confirmation-dialog__section">
        You’ll still find them here in {AppNames.Content}.
      </p>
      <p className="confirmation-dialog__section">
        Do you want to unpublish and archive the selected items?
      </p>
    </ConfirmationDialog>
  );
});

CascadeUnpublishConfirmationDialog.displayName = 'CascadeUnpublishConfirmationDialog';
