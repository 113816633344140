export enum ModalDialogType {
  AiFeedbackDialog = 'AiFeedbackDialog',
  ApiKeyRegenerationDialog = 'ApiKeyRegenerationDialog',
  BulkItemsDeleteConfirmationDialog = 'BulkItemsDeleteConfirmationDialog',
  ChangeDefaultRootConfirmationDialog = 'ChangeDefaultRootConfirmationDialog',
  ChangeWorkflowStepListingDialog = 'ChangeWorkflowStepListingDialog',
  ConfigureContentTypePreviewUrls = 'ConfigureContentTypePreviewUrls',
  ContentModelingSatisfactionSurveyDialog = 'ContentModelingSatisfactionSurveyDialog',
  ContentTypeAsComponentUsageDialog = 'ContentTypeAsComponentUsageDialog',
  HelpUsImproveSurveyDialog = 'HelpUsImproveSurveyDialog',
  MoveItemsToArchivedStepConfirmationDialog = 'ArchiveItemsConfirmationDialog',
  NewContentItemDialog = 'NewContentItemDialog',
  NewContentItemDialogForWebSpotlight = 'NewContentItemDialogForWebSpotlight',
  None = 'none',
  RemoveTaxonomyTermConfirmationDialog = 'RemoveTaxonomyTermConfirmationDialog',
  RestoreRevisionConfirmationDialog = 'RestoreRevisionConfirmationDialog',
  RevokeApiKeyDialog = 'RevokeApiKeyDialog',
  SapiKeyGenerationDialog = 'SapiKeyGenerationDialog',
  SendYourFeedbackDialog = 'SendYourFeedbackDialog',
  ThanksForFeedbackDialog = 'ThanksForFeedbackDialog',
  UnsupportedAssetFileType = 'UnsupportedAssetFileType',
}
