import { ThunkFunction } from '../../../../../../@types/Dispatcher.type.ts';
import { trackUserEvent } from '../../../../../../_shared/actions/thunks/trackUserEvent.ts';
import { TrackedEvent } from '../../../../../../_shared/constants/trackedEvent.ts';
import { ContentItemEditingBasicActions } from '../../../../../../_shared/models/events/ContentItemEditingEventData.type.ts';
import { itemEditingModalWithPropertiesOpened } from '../../../../actions/contentActions.ts';
import { ContentItemEditingModalProperties } from '../../../../types/ItemEditingModalDialogProperties.type.ts';
import { deactivateContentItemEditingAction } from '../contentItemEditingActions.ts';

export const openEditingActionsDialog =
  (
    action: ContentItemEditingBasicActions,
    dialogType: ContentItemEditingModalProperties['dialogType'],
  ) =>
  (origin: ContentItemEditingModalProperties['actionOrigin']): ThunkFunction =>
  (dispatch) => {
    dispatch(
      itemEditingModalWithPropertiesOpened({
        dialogType,
        actionOrigin: origin,
      }),
    );
    dispatch(deactivateContentItemEditingAction());
    dispatch(trackUserEvent(TrackedEvent.ContentItemEditing, { action, origin }));
  };
