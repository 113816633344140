import { createGuid } from '@kontent-ai/utils';
import { Dispatch, ThunkPromise } from '../../../../../../../../@types/Dispatcher.type.ts';
import { showErrorNotification } from '../../../../../../../../_shared/actions/sharedActions.ts';
import { TrackedEvent } from '../../../../../../../../_shared/constants/trackedEvent.ts';
import { TrackUserEventAction } from '../../../../../../../../_shared/models/TrackUserEvent.type.ts';
import { getSelectedLanguageIdOrThrow } from '../../../../../../../../_shared/selectors/getSelectedLanguageId.ts';
import { IStore } from '../../../../../../../../_shared/stores/IStore.type.ts';
import { logError } from '../../../../../../../../_shared/utils/logError.ts';
import { IContentItemRepository } from '../../../../../../../../repositories/interfaces/IContentItemRepository.type.ts';
import { ServerApiErrorCode } from '../../../../../../../../repositories/serverModels/ServerApiError.ts';
import { getItemListingOperationQueryFromFilter } from '../../../../../utils/contentItemListModelConverters.ts';
import {
  ContentListing_DeleteItems_Finished,
  ContentListing_DeleteItems_Started,
} from '../../../constants/contentItemInventoryActionTypes.ts';
import { BulkDeleteErrorMessage } from '../../../constants/uiConstants.ts';
import { createBulkActionResult } from '../../../utils/createBulkActionResult.ts';
import {
  createBulkActionFinished,
  createBulkActionStarted,
} from '../../contentItemInventoryActions.ts';
import { setTimeoutForMessageChange } from './setTimeoutForMessageChange.ts';

interface IDeleteSelectedContentItemsActionDependencies {
  readonly contentItemRepository: IContentItemRepository;
  readonly trackUserEvent: TrackUserEventAction;
  readonly loadListingContentItems: () => ThunkPromise;
  readonly loadContentTypes: () => ThunkPromise;
}

const actionStarted = createBulkActionStarted<typeof ContentListing_DeleteItems_Started>(
  ContentListing_DeleteItems_Started,
);
const actionFinished = createBulkActionFinished<typeof ContentListing_DeleteItems_Finished>(
  ContentListing_DeleteItems_Finished,
);

export type DeleteSelectedContentItemsActionsType = ReturnType<
  typeof actionStarted | typeof actionFinished
>;

export const deleteSelectedContentItemsActionCreator =
  (deps: IDeleteSelectedContentItemsActionDependencies) =>
  (): ThunkPromise =>
  async (dispatch: Dispatch, getState: () => IStore): Promise<void> => {
    const state = getState();

    const selectedLanguageId = getSelectedLanguageIdOrThrow(state);
    const query = getItemListingOperationQueryFromFilter(state);

    const operationId = createGuid();
    dispatch(actionStarted({ operationId }));
    dispatch(setTimeoutForMessageChange(operationId));

    try {
      const operationResult = await deps.contentItemRepository.archiveVariants(
        selectedLanguageId,
        query,
      );

      dispatch(
        actionFinished(
          createBulkActionResult(
            operationResult,
            selectedLanguageId,
            ServerApiErrorCode.ItemHasOtherVariant,
          ),
        ),
      );

      dispatch(
        deps.trackUserEvent(TrackedEvent.ContentEntryBulkDeleted, {
          count: operationResult.modifiedItemIds.length,
        }),
      );
    } catch (error) {
      logError('Error while bulk deleting', error);
      dispatch(showErrorNotification(BulkDeleteErrorMessage));
    }

    dispatch(deps.loadListingContentItems());
    dispatch(deps.loadContentTypes());
  };
