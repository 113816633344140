import { Link } from 'react-router-dom';
import {
  BillingInformationRoute,
  EnsuredSubscriptionRouteParams,
} from '../../../../../_shared/constants/routePaths.ts';
import { useSelector } from '../../../../../_shared/hooks/useSelector.ts';
import { selectIntercomUtils } from '../../../../../_shared/selectors/selectIntercomUtils.ts';
import { LinkLike } from '../../../../../_shared/uiComponents/LinkLike/LinkLike.tsx';
import { buildPath } from '../../../../../_shared/utils/routing/routeTransitionUtils.ts';
import { isAdminOnSubscription } from '../../../../../data/reducers/subscriptions/selectors/subscriptionSelectors.ts';
import {
  getCurrentProjectPlan,
  getCurrentProjectSubscription,
  isProjectPlanSuspended,
} from '../../../../../data/reducers/user/selectors/userProjectsInfoSelectors.ts';
import { isSubscriptionSuspendedAutomatically } from '../../../../subscriptionManagement/shared/utils/subscriptionStatusUtils.ts';
import { SuspendedSubscriptionWarning } from '../../components/userListing/SuspendedSubscriptionWarning.tsx';

const SuspendedSubscriptionWarningContainer = () => {
  const currentProjectId = useSelector((state) => state.sharedApp.currentProjectId);
  const isPlanPrepaid = useSelector(getCurrentProjectPlan).isPrepaid;
  const isPlanSuspended = useSelector((state) => isProjectPlanSuspended(state, currentProjectId));
  const subscription = useSelector(getCurrentProjectSubscription);
  const isSubscriptionAdmin = useSelector((state) =>
    isAdminOnSubscription(state, subscription.subscriptionId),
  );
  const onContactSupport = useSelector(selectIntercomUtils).showIntercom;

  if (!isPlanSuspended) {
    return null;
  }

  const { subscriptionId, subscriptionName, statusReason } = subscription;

  const isSuspendedAutomatically = isSubscriptionSuspendedAutomatically(statusReason);

  const getRenewalAction = (): { renewalAction: JSX.Element; showSupportLink: boolean } => {
    if (!isSubscriptionAdmin) {
      return { renewalAction: <>contact an admin</>, showSupportLink: true };
    }

    if (isPlanPrepaid || !isSuspendedAutomatically) {
      return {
        renewalAction: <LinkLike onClick={onContactSupport}>contact our support</LinkLike>,
        showSupportLink: false,
      };
    }

    const billingInformationPath = buildPath<EnsuredSubscriptionRouteParams>(
      BillingInformationRoute,
      { subscriptionId },
    );

    return {
      renewalAction: <Link to={billingInformationPath}>select a payment method</Link>,
      showSupportLink: true,
    };
  };

  const { renewalAction, showSupportLink } = getRenewalAction();

  return (
    <SuspendedSubscriptionWarning
      onContactSupport={showSupportLink ? onContactSupport : null}
      renewalAction={renewalAction}
      subscriptionName={subscriptionName}
    />
  );
};

export { SuspendedSubscriptionWarningContainer as SuspendedSubscriptionWarning };
