import { combineReducers } from '@reduxjs/toolkit';
import { activeCapabilities } from './reducers/activeCapabilities.ts';
import { approvedSuggestions } from './reducers/approvedSuggestions.ts';
import { submittingSection } from './reducers/assignmentMetadata/submittingSection.ts';
import { cascadeResult } from './reducers/cascadePublish/cascadeResult.ts';
import { expandedNodesData } from './reducers/cascadePublish/expandedNodesData.ts';
import { loadingStatus } from './reducers/cascadePublish/loadingStatus.ts';
import { nodesLoadingStatus } from './reducers/cascadePublish/nodesLoadingStatus.ts';
import { selectedItems } from './reducers/cascadePublish/selectedItems.ts';
import { isCodenameAutogenerated } from './reducers/codenameEditor/isCodenameAutogenerated.ts';
import { status } from './reducers/codenameEditor/status.ts';
import { collapsedContentComponents } from './reducers/collapsedContentComponents.ts';
import { collapsedGuidelinesElementIds } from './reducers/collapsedGuidelinesElementIds.ts';
import { collapsedSitemapNodes } from './reducers/collapsedSitemapNodes.ts';
import { isInitialized } from './reducers/contentItemSidebar/isInitialized.ts';
import { isOpen } from './reducers/contentItemSidebar/isOpen.ts';
import { openedByUser } from './reducers/contentItemSidebar/openedByUser.ts';
import { convertedItemIds } from './reducers/convertedItemIds.ts';
import { editedContentItemVariantUploadingAssets } from './reducers/editedContentItemVariantUploadingAssets.ts';
import { activatedAction } from './reducers/editingActions/activatedAction.ts';
import { elementsAreInaccessibleNotificationBarTrigger } from './reducers/elementsAreInaccessibleNotificationBarTrigger.ts';
import { firstWorkflowStepCurrentRoleCanWorkWithId } from './reducers/firstWorkflowStepCurrentRoleCanWorkWithId.ts';
import { itemEditingModalDialog } from './reducers/itemEditingModalDialog.ts';
import { itemLiveUserIds } from './reducers/itemLiveUserIds.ts';
import { lockedElements } from './reducers/lockedElements.ts';
import { multipleContentItemsModalSelectorAlreadyLinkedItemIds } from './reducers/multipleContentItemsModalSelectorAlreadyLinkedItemIds.ts';
import { newCommentThreadItemTypeMappings } from './reducers/newCommentThreadItemTypeMappings.ts';
import { outdatedElementIds } from './reducers/outdatedElementIds.ts';
import { pendingOperationIds } from './reducers/pendingOperationIds.ts';
import { pendingRefreshElements } from './reducers/pendingRefreshElements.ts';
import { refreshedElementIds } from './reducers/refreshedElementIds.ts';
import { restoredVersionId } from './reducers/restoredVersionId.ts';
import { selectedContentGroups } from './reducers/selectedContentGroups.ts';
import { archivingTasks } from './reducers/tasks/archivingTasks.ts';
import { highlightedTaskIds } from './reducers/tasks/highlightedTaskIds.ts';
import { orderedTaskIds } from './reducers/tasks/orderedTaskIds.ts';
import { selectedTaskId } from './reducers/tasks/selectedTaskId.ts';
import { submittingTasks } from './reducers/tasks/submittingTasks.ts';
import { temporaryItem } from './reducers/temporaryItem.ts';

const codenameEditor = combineReducers({
  isCodenameAutogenerated,
  status,
});

const cascadePublish = combineReducers({
  cascadeResult,
  expandedNodesData,
  loadingStatus,
  nodesLoadingStatus,
  selectedItems,
});

const contentItemSidebar = combineReducers({
  isInitialized,
  isOpen,
  openedByUser,
});

const editingActions = combineReducers({
  activatedAction,
});

const tasks = combineReducers({
  highlightedTaskIds,
  orderedTaskIds,
  selectedTaskId,
  submittingTasks,
  archivingTasks,
});

const assignmentMetadata = combineReducers({
  submittingSection,
});

export const editorUi = combineReducers({
  activeCapabilities,
  approvedSuggestions,
  assignmentMetadata,
  cascadePublish,
  codenameEditor,
  collapsedContentComponents,
  collapsedGuidelinesElementIds,
  collapsedSitemapNodes,
  contentItemSidebar,
  convertedItemIds,
  editedContentItemVariantUploadingAssets,
  editingActions,
  elementsAreInaccessibleNotificationBarTrigger,
  firstWorkflowStepCurrentRoleCanWorkWithId,
  itemEditingModalDialog,
  itemLiveUserIds,
  lockedElements,
  multipleContentItemsModalSelectorAlreadyLinkedItemIds,
  newCommentThreadItemTypeMappings,
  outdatedElementIds,
  pendingOperationIds,
  pendingRefreshElements,
  refreshedElementIds,
  restoredVersionId,
  selectedContentGroups,
  tasks,
  temporaryItem,
});
