import { connect } from 'react-redux';
import { Dispatch } from '../../../../@types/Dispatcher.type.ts';
import { trackUserEvent } from '../../../../_shared/actions/thunks/trackUserEvent.ts';
import { TrackedEvent } from '../../../../_shared/constants/trackedEvent.ts';
import { ProjectOverviewEventTypes } from '../../../../_shared/models/TrackUserEventData.ts';
import {
  ProjectOverviewPageContextProvider as ProjectOverviewPageContextProviderComponent,
  ProjectOverviewPageContextProviderDispatchProps,
} from '../components/ProjectOverviewPageContext.tsx';

const mapDispatchToProps = (
  dispatch: Dispatch,
): ProjectOverviewPageContextProviderDispatchProps => ({
  onChangeCategory: (category: string) => {
    dispatch(
      trackUserEvent(TrackedEvent.ProjectOverview, {
        action: ProjectOverviewEventTypes.CategoryViewed,
        category,
      }),
    );
  },
});

export const ProjectOverviewPageContextProvider = connect(
  null,
  mapDispatchToProps,
)(ProjectOverviewPageContextProviderComponent);
