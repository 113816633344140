import { trackUserEvent } from '../../../../../_shared/actions/thunks/trackUserEvent.ts';
import { TrackedEvent } from '../../../../../_shared/constants/trackedEvent.ts';
import { useDispatch } from '../../../../../_shared/hooks/useDispatch.ts';
import { MissionControlTrackedWidgetName } from '../../../../../_shared/models/TrackUserEventData.ts';

export const useTrackWidgetDetailItemClick = (widgetName: MissionControlTrackedWidgetName) => {
  const dispatch = useDispatch();

  return () =>
    dispatch(
      trackUserEvent(TrackedEvent.MissionControlWidgetDetailItemClicked, {
        widgetName,
      }),
    );
};

export const useTrackWidgetOverviewItemClick = (widgetName: MissionControlTrackedWidgetName) => {
  const dispatch = useDispatch();

  return () =>
    dispatch(
      trackUserEvent(TrackedEvent.MissionControlWidgetOverviewItemClicked, {
        widgetName,
      }),
    );
};

export const useTrackWidgetShowMoreButtonClick = (widgetName: MissionControlTrackedWidgetName) => {
  const dispatch = useDispatch();

  return () =>
    dispatch(
      trackUserEvent(TrackedEvent.MissionControlWidgetShowMoreClicked, {
        widgetName,
      }),
    );
};
