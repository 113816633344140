import Immutable from 'immutable';
import React, { ComponentPropsWithRef, useCallback } from 'react';
import { trackUserEvent } from '../../../../../../_shared/actions/thunks/trackUserEvent.ts';
import { withAutoDispatcher } from '../../../../../../_shared/components/AutoDispatcher.tsx';
import { TrackedEvent } from '../../../../../../_shared/constants/trackedEvent.ts';
import { useDispatch } from '../../../../../../_shared/hooks/useDispatch.ts';
import { useSelector } from '../../../../../../_shared/hooks/useSelector.ts';
import { ActiveCapabilityType } from '../../../../../../_shared/models/activeCapability.type.ts';
import {
  ContentItemEditingChangeAction,
  ContentItemEditingEventOrigins,
} from '../../../../../../_shared/models/events/ContentItemEditingEventData.type.ts';
import { IStore } from '../../../../../../_shared/stores/IStore.type.ts';
import { hasActiveVariantCapabilityForEditedItem } from '../../../../../../_shared/utils/permissions/activeCapabilities.ts';
import {
  sitemapLocationCollapsedStateToggled,
  toggleContentItemSitemapLocation,
} from '../../actions/contentItemEditingActions.ts';
import { saveContentItemSitemapToServer } from '../../actions/thunkContentItemEditingActions.ts';
import { SitemapSelector as SitemapSelectorComponent } from '../../components/SitemapSelector.tsx';

const ConnectedSitemapSelector: React.FC = () => {
  const isDisabled = useSelector(
    (s) => !hasActiveVariantCapabilityForEditedItem(ActiveCapabilityType.UpdateContent, s),
  );
  const sitemap = useSelector((s) => s.data.sitemap.data);
  const collapsedNodes = useSelector((s) => s.contentApp.editorUi.collapsedSitemapNodes);
  const selectedLocations = useSelector(
    (s) => s.contentApp.editedContentItem?.sitemapLocation ?? Immutable.Set<Uuid>(),
  );

  const dispatch = useDispatch();
  const onToggleNodeCollapsed = useCallback(
    (toggledNodeId: Uuid) => dispatch(sitemapLocationCollapsedStateToggled(toggledNodeId)),
    [],
  );
  const onToggleSitemapLocation = useCallback((selectedNodeId: Uuid) => {
    dispatch(toggleContentItemSitemapLocation(selectedNodeId));
    dispatch(
      trackUserEvent(TrackedEvent.ContentItemEditing, {
        action: ContentItemEditingChangeAction.ChangeSitemapLocation,
        origin: ContentItemEditingEventOrigins.ItemDetails,
      }),
    );
  }, []);

  return (
    <SitemapSelectorComponent
      collapsedNodes={collapsedNodes}
      isDisabled={isDisabled}
      onToggleNodeCollapsed={onToggleNodeCollapsed}
      onToggleSitemapLocation={onToggleSitemapLocation}
      selectedLocations={selectedLocations}
      sitemap={sitemap}
    />
  );
};
ConnectedSitemapSelector.displayName = 'ConnectedSitemapSelector';

type ObservedState = {
  sitemapLocation: Immutable.Set<Uuid> | null;
};

const mapObservedState = (state: IStore): ObservedState => {
  const editedContentItem = state.contentApp.editedContentItem;

  return {
    sitemapLocation: editedContentItem ? editedContentItem.sitemapLocation : null,
  };
};

const shouldDispatch = (oldState: IStore, newState: IStore): boolean => {
  const nextEditedContentItem = newState.contentApp.editedContentItem;
  const currentEditedContentItem = oldState.contentApp.editedContentItem;

  if (!nextEditedContentItem || !currentEditedContentItem) {
    return false;
  }

  const nextSitemapLocation = nextEditedContentItem.sitemapLocation;
  const currentSitemapLocation = currentEditedContentItem.sitemapLocation;
  const areSitemapLocationsTheSame =
    nextSitemapLocation === currentSitemapLocation ||
    (nextSitemapLocation.count() === currentSitemapLocation.count() &&
      nextSitemapLocation.isSubset(currentSitemapLocation));

  return !areSitemapLocationsTheSame;
};

export const SitemapSelector = withAutoDispatcher<
  ComponentPropsWithRef<typeof ConnectedSitemapSelector>,
  ObservedState
>(
  mapObservedState,
  saveContentItemSitemapToServer,
  1000,
  shouldDispatch,
)(ConnectedSitemapSelector);
