export enum SidebarNames {
  ActivateUserWarningModal = 'ActivateUserWarningModal',
  ChangePlanModal = 'ChangePlanModal',
  CreateNewSubscriptionModal = 'CreateNewSubscriptionModal',
  DiscussionsSidebar = 'DiscussionsSidebar',
  PromoteUsersModal = 'PromoteUsersModal',
  ResolvedCommentsSidebar = 'ResolvedCommentsSidebar',
  SubscriptionAdminInvitationModal = 'SubscriptionAdminInvitationModal',
  TrialActivationModal = 'TrialActivationModal',
}
