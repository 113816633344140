import { Button } from '@kontent-ai/component-library/Button';
import React from 'react';
import { useDispatch } from '../../../../../../../../_shared/hooks/useDispatch.ts';
import { ContentItemEditingEventOrigins } from '../../../../../../../../_shared/models/events/ContentItemEditingEventData.type.ts';
import {
  DataUiWorkflowAction,
  ItemEditingForms,
  getDataUiWorkflowActionAttribute,
} from '../../../../../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';
import { ItemEditingModalDialogType } from '../../../../../../constants/itemEditingModalDialogType.ts';
import { changeNote } from '../../../../actions/thunkContentItemEditingActions.ts';
import { NonClickableSection } from '../../../../components/sidebar/sectionTypes/NonClickableSection.tsx';

export const NonClickableNoteSection: React.FC = () => {
  const dispatch = useDispatch();
  const onClick = () =>
    dispatch(
      changeNote(
        ContentItemEditingEventOrigins.ItemDetails,
        ItemEditingModalDialogType.ContentItemNoteDialog,
      ),
    );

  return (
    <NonClickableSection dataUiNameAttribute={ItemEditingForms.Note}>
      <Button
        buttonStyle="secondary"
        size="small"
        onClick={onClick}
        {...getDataUiWorkflowActionAttribute(DataUiWorkflowAction.EditNote)}
      >
        Add note
      </Button>
    </NonClickableSection>
  );
};

NonClickableNoteSection.displayName = 'NonClickableNoteSection';
