import { OutwardLink } from '@kontent-ai/component-library/Anchor';
import { Box } from '@kontent-ai/component-library/Box';
import { Inline } from '@kontent-ai/component-library/Inline';
import { NotificationBarSuccess } from '@kontent-ai/component-library/NotificationBar';
import { Stack } from '@kontent-ai/component-library/Stack';
import {
  Spacing,
  Typography,
  colorTextDefault,
  spacingMainLayoutBottom,
  spacingMainLayoutLeft,
  spacingMainLayoutRight,
  spacingMainLayoutTop,
} from '@kontent-ai/component-library/tokens';
import React from 'react';
import styled from 'styled-components';
import { HtmlSubscriptionManagementPageTitle } from '../../../../_shared/components/HtmlSubscriptionManagementPageTitle.tsx';
import { Loader } from '../../../../_shared/components/Loader.tsx';
import { PageTitle } from '../../../../_shared/components/PageTitle.tsx';
import { getLandingPageLinks } from '../../../../_shared/constants/landingPageLinks.ts';
import { LinkLike } from '../../../../_shared/uiComponents/LinkLike/LinkLike.tsx';
import {
  DataUiAppName,
  getDataUiAppNameAttribute,
} from '../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';
import { IPlan } from '../../../../data/models/plans/Plan.ts';
import { SubscriptionTabName } from '../../shared/constants/subscriptionTabName.ts';
import { ISubscriptionUsage } from '../../shared/models/SubscriptionUsage.ts';
import { ButtonAction, PlanCard } from '../containers/PlanCard.tsx';
import { ExpiredPlanMessage } from './ExpiredPlanMessage.tsx';

const StyledParagraph = styled.p`
  ${Typography.BodyLarge};
  color: ${colorTextDefault};
`;

type PlanSelectionProps = {
  readonly developerPlan: IPlan | null;
  readonly isCurrentSubscriptionExpired: boolean;
  readonly isTrial: boolean;
  readonly onContactSupport: () => void;
  readonly planDisplayName: string;
  readonly showChangePlanDialog: (planId: Uuid) => void;
  readonly showChangePlanSuccessMessage: boolean;
  readonly subscriptionUsage: ISubscriptionUsage | undefined;
};

export const PlanSelection: React.FC<PlanSelectionProps> = ({
  developerPlan,
  isCurrentSubscriptionExpired,
  isTrial,
  onContactSupport,
  planDisplayName,
  showChangePlanDialog,
  showChangePlanSuccessMessage,
  subscriptionUsage,
}) => {
  if (!developerPlan || !subscriptionUsage) {
    return <Loader />;
  }

  const selectDeveloperPlanAction: ButtonAction = {
    title: 'Select Developer plan',
    handler: () => showChangePlanDialog(developerPlan.planId),
  };

  return (
    <Box
      paddingTop={spacingMainLayoutTop}
      paddingRight={spacingMainLayoutRight}
      paddingBottom={spacingMainLayoutBottom}
      paddingLeft={spacingMainLayoutLeft}
      overflowX="hidden"
      overflowY="auto"
      {...getDataUiAppNameAttribute(DataUiAppName.SubscriptionPlans)}
    >
      <HtmlSubscriptionManagementPageTitle tabName={SubscriptionTabName.Plans} />
      {showChangePlanSuccessMessage && (
        <Box paddingBottom={Spacing.XXL}>
          <NotificationBarSuccess>Your plan has been changed successfully.</NotificationBarSuccess>
        </Box>
      )}
      <Box width="fit-content">
        <Stack spacing={Spacing.XL}>
          <PageTitle>{SubscriptionTabName.Plans}</PageTitle>
          <ExpiredPlanMessage
            isExpired={isCurrentSubscriptionExpired}
            isTrial={isTrial}
            planDisplayName={planDisplayName}
          />
          <Inline spacing={Spacing.XL}>
            <PlanCard
              planDescription="The basics you need for a non-commercial project"
              planPrice="Free"
              title={`${developerPlan.displayName} plan`}
              action={selectDeveloperPlanAction}
              plan={developerPlan}
              subscriptionUsage={subscriptionUsage}
            />
            <PlanCard
              planPrice="Custom"
              planDescription="Everything you need for an enterprise-wide rollout"
              title="Commercial plans"
            />
          </Inline>
          <Box paddingTop={Spacing.M}>
            <Stack align="center" spacing={Spacing.L}>
              <StyledParagraph>
                In case of any questions about your subscription, see our&nbsp;
                <OutwardLink href={getLandingPageLinks().pricingFaq}>FAQs</OutwardLink> or{' '}
                <LinkLike onClick={onContactSupport}>contact us</LinkLike>.
              </StyledParagraph>
              <StyledParagraph>
                <LinkLike onClick={onContactSupport}>Cancel subscription</LinkLike>
              </StyledParagraph>
            </Stack>
          </Box>
        </Stack>
      </Box>
    </Box>
  );
};

PlanSelection.displayName = 'PlanSelection';
