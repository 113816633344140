import { Collection } from '@kontent-ai/utils';
import { ThunkPromise } from '../../../../../@types/Dispatcher.type.ts';
import { TrackedEvent } from '../../../../../_shared/constants/trackedEvent.ts';
import { TrackUserEventAction } from '../../../../../_shared/models/TrackUserEvent.type.ts';
import { ProjectOverviewEventTypes } from '../../../../../_shared/models/TrackUserEventData.ts';
import { IProjectSpecificStorage } from '../../../../../localStorages/projectSpecificStorage.ts';
import { TrackFilterChange } from '../../../../contentInventory/content/features/ContentItemInventory/actions/thunks/loadListingItems/trackFilterChange.ts';
import { IListingFilter } from '../../../../contentInventory/content/models/filter/IListingFilter.ts';

interface Deps {
  readonly ContentItemFilterStorage: IProjectSpecificStorage<IListingFilter> | null;
  readonly loadItemsMatchingFilter: () => ThunkPromise;
  readonly trackFilterChange: TrackFilterChange;
  readonly trackUserEvent: TrackUserEventAction;
}

export const createFilterProjectOverviewAction =
  ({
    ContentItemFilterStorage,
    loadItemsMatchingFilter,
    trackFilterChange,
    trackUserEvent,
  }: Deps) =>
  (): ThunkPromise =>
  async (dispatch, getState) => {
    const {
      data: { workflows },
      sharedApp: { currentProjectId },
      contentApp: {
        listingUi: { filter, savedFiltersUi },
      },
    } = getState();

    const previousFilter = savedFiltersUi.lastFilteredUsingSavedFilter
      ? null
      : (ContentItemFilterStorage?.load(currentProjectId) ?? null);

    dispatch(trackFilterChange(previousFilter, filter, Collection.getValues(workflows.byId)));

    ContentItemFilterStorage?.save(filter, currentProjectId);

    await dispatch(loadItemsMatchingFilter());
    dispatch(
      trackUserEvent(TrackedEvent.ProjectOverview, {
        action: ProjectOverviewEventTypes.ItemsFiltered,
      }),
    );
  };
