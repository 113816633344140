import { Button } from '@kontent-ai/component-library/Button';
import { Icons } from '@kontent-ai/component-library/Icons';
import React from 'react';
import {
  DataUiAction,
  getDataUiActionAttribute,
} from '../../../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';
import { useIsPreviewUrlOutdated } from '../../../../../webSpotlight/hooks/useIsPreviewUrlOutdated.ts';
import { useWebSpotlightInItemEditing } from '../../context/WebSpotlightInItemEditingContext.tsx';

export const RefreshPreviewButton: React.FC = () => {
  const { refreshPreview } = useWebSpotlightInItemEditing();
  const isPreviewUrlOutdated = useIsPreviewUrlOutdated();

  const onClick = () => {
    refreshPreview({ isManualRefresh: true, isPreviewUrlOutdated });
  };

  return (
    <Button
      buttonStyle="secondary"
      onClick={onClick}
      {...getDataUiActionAttribute(DataUiAction.Refresh)}
    >
      <Button.Icon icon={Icons.RotateDoubleRight} />
      <Button.Label>Refresh</Button.Label>
    </Button>
  );
};
