import React, { ComponentProps } from 'react';
import { DataTableRow } from '../../../../../_shared/components/DataTable/DataTableRow.tsx';
import { LinkDataTableCell } from '../../../../../_shared/components/DataTable/LinkDataTableCell.tsx';
import { WorkflowStatusTagForVariant } from '../../../../../_shared/containers/Workflow/WorkflowStatusTagForVariant.tsx';
import { Task } from '../../../../../_shared/models/Task.ts';
import { IContentType } from '../../../../../data/models/contentModelsApp/contentTypes/ContentType.ts';
import { IListingContentItem } from '../../../../../data/models/listingContentItems/IListingContentItem.ts';
import { IProjectContributor } from '../../../../../data/models/users/ProjectContributor.ts';
import { TableCellDueDate } from '../../../../contentInventory/content/features/ContentItemInventory/components/TableCellDueDate.tsx';
import { ScrollTableItemContributors } from '../../../contentStatus/components/ScrollTableItemContributors.tsx';

export type TaskWidgetDetailTableRowProps = {
  readonly task: Task;
  readonly contentItem: IListingContentItem | undefined;
  readonly contentType: IContentType | undefined;
  readonly contributors: ReadonlyArray<IProjectContributor>;
  readonly linkPath: string | null;
  readonly onClick?: () => void;
};

export const TaskWidgetDetailTableRow: React.FC<TaskWidgetDetailTableRowProps> = ({
  task,
  contentItem,
  contentType,
  contributors,
  linkPath,
  onClick,
}) => {
  const commonCellProps = {
    linkPath,
    onClick,
  } satisfies ComponentProps<typeof LinkDataTableCell>;

  return (
    <DataTableRow key={task.id} dataUiObjectName={task.id} id={task.id}>
      <LinkDataTableCell
        focusableRowLinkAriaLabel={`visit task — ${task.description}`}
        {...commonCellProps}
      >
        {task.description}
      </LinkDataTableCell>
      <LinkDataTableCell {...commonCellProps}>
        <TableCellDueDate dueDate={task.dueDate} />
      </LinkDataTableCell>
      <LinkDataTableCell {...commonCellProps}>
        <ScrollTableItemContributors contributors={contributors} />
      </LinkDataTableCell>
      <LinkDataTableCell {...commonCellProps}>{contentItem?.item.name ?? ''}</LinkDataTableCell>
      <LinkDataTableCell {...commonCellProps}>{contentType?.name ?? ''}</LinkDataTableCell>
      <LinkDataTableCell {...commonCellProps}>
        {contentItem?.variant && (
          <WorkflowStatusTagForVariant
            publishingState={contentItem.variant?.publishingState}
            scheduledToPublishAt={contentItem.variant?.assignment.scheduledToPublishAt}
            scheduledToUnpublishAt={contentItem.variant?.assignment.scheduledToUnpublishAt}
            workflowStatus={contentItem.variant?.assignment.workflowStatus}
          />
        )}
      </LinkDataTableCell>
    </DataTableRow>
  );
};

TaskWidgetDetailTableRow.displayName = 'TaskWidgetDetailTableRow';
