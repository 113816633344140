import Immutable from 'immutable';
import React from 'react';
import { trackUserEvent } from '../../../../../../../_shared/actions/thunks/trackUserEvent.ts';
import { TrackedEvent } from '../../../../../../../_shared/constants/trackedEvent.ts';
import { useDispatch } from '../../../../../../../_shared/hooks/useDispatch.ts';
import { useSelector } from '../../../../../../../_shared/hooks/useSelector.ts';
import {
  ContentItemEditingChangeAction,
  ContentItemEditingEventOrigins,
} from '../../../../../../../_shared/models/events/ContentItemEditingEventData.type.ts';
import { IContentType } from '../../../../../../../data/models/contentModelsApp/contentTypes/ContentType.ts';
import { IModularContentItemElementWarningResult } from '../../../../../utils/itemElementWarningCheckers/types/IModularContentItemElementWarningResult.type.ts';
import { emptyItemElementWarningResult } from '../../../../../utils/itemElementWarningCheckers/types/Warnings.ts';
import { CollectionSection } from '../../../../NewContentItem/constants/collectionSection.ts';
import { ContentTypeSection } from '../../../../NewContentItem/constants/contentTypeSection.ts';
import { duplicateContentItem } from '../../../actions/thunkContentItemEditingActions.ts';
import {
  ILinkedItemsComponentOwnProps,
  LinkedItemsComponent as WrappedComponent,
} from '../../../components/elements/linkedItems/LinkedItemsComponent.tsx';
import { determineTopmostContentElementType } from '../../../utils/contentComponentUtils.ts';
import {
  getAllowedLinkedItemTypesMessage,
  getAllowedTypes,
} from '../../../utils/modularContentUtils.ts';

const defaultValidationResult: IModularContentItemElementWarningResult = {
  ...emptyItemElementWarningResult,
  containsOnlyAllowedContentTypes: true,
  isNumberOfItemsLimitMet: true,
};

interface ILinkedItemsComponentContainerProps extends ILinkedItemsComponentOwnProps {
  readonly validationResultSelectorId: string;
}

export const LinkedItemsComponent: React.FC<ILinkedItemsComponentContainerProps> = (props) => {
  const validationResult =
    useSelector(
      (s) =>
        s.contentApp.itemValidationWarnings.get(
          props.validationResultSelectorId,
        ) as IModularContentItemElementWarningResult,
    ) ?? defaultValidationResult;

  const allowedContentTypes = useSelector((s) =>
    getAllowedTypes(props.typeElement.allowedTypes, s.data.contentTypes.byId, true),
  );
  const allowedTypesMessage = getAllowedLinkedItemTypesMessage(allowedContentTypes.toArray());
  const collectionId = useSelector((s) => s.contentApp.editedContentItem?.collectionId);

  const dispatch = useDispatch();
  const onDuplicateContentItem = (contentItemId: Uuid, destinationCollectionId: Uuid) =>
    dispatch(duplicateContentItem(contentItemId, destinationCollectionId));
  const onContentItemAdded = (
    allowedTypes: Immutable.Set<IContentType>,
    _itemId: Uuid,
    contentTypeSection: ContentTypeSection,
    collectionSection: CollectionSection,
  ) => {
    dispatch(
      trackUserEvent(TrackedEvent.ContentItemEditing, {
        action: ContentItemEditingChangeAction.CreateContentItem,
        allowedContentTypesCount: allowedTypes.count(),
        contentElementType: determineTopmostContentElementType(props),
        collectionSection,
        contentTypeSection,
        origin: ContentItemEditingEventOrigins.Paper,
      }),
    );
  };

  const onContentItemsLinked = (
    allowedTypes: Immutable.Set<IContentType>,
    newlyLinkedItems: ReadonlyArray<Uuid>,
  ) => {
    if (newlyLinkedItems.length === 0) {
      return;
    }
    dispatch(
      trackUserEvent(TrackedEvent.ContentItemEditing, {
        action: ContentItemEditingChangeAction.LinkContentItem,
        allowedContentTypesCount: allowedTypes.count(),
        contentElementType: determineTopmostContentElementType(props),
        origin: ContentItemEditingEventOrigins.Paper,
        selectedItemsCount: newlyLinkedItems.length,
      }),
    );
  };

  return (
    <WrappedComponent
      allowedContentTypes={allowedContentTypes}
      allowedTypesMessage={allowedTypesMessage}
      collectionId={collectionId}
      onContentItemAdded={onContentItemAdded}
      onContentItemsLinked={onContentItemsLinked}
      onDuplicateContentItem={onDuplicateContentItem}
      validationResult={validationResult}
      {...props}
    />
  );
};
