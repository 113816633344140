import { Dispatch, ThunkPromise } from '../../../../../@types/Dispatcher.type.ts';
import { TrackedEvent } from '../../../../../_shared/constants/trackedEvent.ts';
import { TrackUserEventAction } from '../../../../../_shared/models/TrackUserEvent.type.ts';
import { ProgressCallback } from '../../../../../_shared/utils/ajax.ts';
import { processWithConcurrencyLimit } from '../../../../../_shared/utils/processWithConcurrencyLimit.ts';
import { IAsset } from '../../../../../data/models/assets/Asset.ts';
import {
  IAssetInsertMetadataServerModel,
  RequiredAssetCreationMetadata,
} from '../../../../../repositories/serverModels/AssetServerModels.type.ts';
import {
  ServerApiErrorCode,
  tryParseApiError,
} from '../../../../../repositories/serverModels/ServerApiError.ts';
import { IAssetService } from '../../../content/features/Asset/services/assetService.ts';
import {
  AssetLibrary_Assets_CreateAssets,
  AssetLibrary_Assets_UploadFailed,
  AssetLibrary_Assets_UploadFinished,
} from '../../constants/assetLibraryActionTypes.ts';
import { FileWithThumbnail } from '../../models/FileWithThumbnail.type.ts';
import { showUnsupportedAssetFileTypeError } from '../assetLibraryActions.ts';

interface ICreateAssetsDependencies {
  readonly assetService: IAssetService;
  readonly getFileUploadProgressHandler: (assetId: Uuid, dispatch: Dispatch) => ProgressCallback;
  readonly trackUserEvent: TrackUserEventAction;
}

const createAssets = (
  files: ReadonlyMap<Uuid, FileWithThumbnail>,
  metadata: RequiredAssetCreationMetadata,
) =>
  ({
    type: AssetLibrary_Assets_CreateAssets,
    payload: {
      files,
      metadata,
    },
  }) as const;

const assetUploadFailed = (oldAssetId: Uuid) =>
  ({
    type: AssetLibrary_Assets_UploadFailed,
    payload: {
      oldAssetId,
    },
  }) as const;

const assetUploadFinished = (uploadResult: IAssetUploadResult) =>
  ({
    type: AssetLibrary_Assets_UploadFinished,
    payload: uploadResult,
  }) as const;

export type CreateAssetsActionsType = ReturnType<
  typeof assetUploadFailed | typeof assetUploadFinished | typeof createAssets
>;

export interface IAssetUploadResult {
  readonly oldAssetId: Uuid;
  readonly newAsset: IAsset;
}

const createAsset =
  (
    deps: ICreateAssetsDependencies,
    file: FileWithThumbnail,
    assetId: Uuid,
    metadata: RequiredAssetCreationMetadata,
  ) =>
  async (dispatch: Dispatch): Promise<IAssetUploadResult | null> => {
    try {
      if (file.size === 0) {
        // Uploading a zero-sized file doesn’t make sense. Make the user aware of the error.
        dispatch(assetUploadFailed(assetId));
        return null;
      }

      const handleFileUploadProgress = deps.getFileUploadProgressHandler(assetId, dispatch);

      const metadataServerModel: IAssetInsertMetadataServerModel = {
        folderId: metadata.folderId,
        collection: {
          id: metadata.collectionId,
        },
      };
      const createdAsset = await deps.assetService.createAsset(
        file,
        metadataServerModel,
        handleFileUploadProgress,
      );

      const uploadResult: IAssetUploadResult = {
        oldAssetId: assetId,
        newAsset: createdAsset,
      };

      dispatch(assetUploadFinished(uploadResult));

      dispatch(
        deps.trackUserEvent(TrackedEvent.AssetUploaded, {
          size: createdAsset.fileSize,
          type: createdAsset.type,
          'asset-id': createdAsset.id,
        }),
      );

      return uploadResult;
    } catch (e) {
      // file upload failed (other files should continue uploading)
      const apiError = tryParseApiError(e);
      if (apiError?.code === ServerApiErrorCode.UnsupportedAssetFileType) {
        dispatch(showUnsupportedAssetFileTypeError());
      }

      dispatch(assetUploadFailed(assetId));
      return null;
    }
  };

const isUploaded = (asset?: IAssetUploadResult | null): asset is IAssetUploadResult => !!asset;

export type IOnAssetFinished = (oldAssetId: Uuid, newAsset: IAsset | null) => void;

export type ICreateAssets = (
  files: ReadonlyMap<Uuid, FileWithThumbnail>,
  metadata: RequiredAssetCreationMetadata,
  onAssetFinished?: IOnAssetFinished,
) => ThunkPromise<ReadonlyArray<IAssetUploadResult>>;

export const createCreateAssetsAction =
  (deps: ICreateAssetsDependencies): ICreateAssets =>
  (files, metadata, onAssetFinished) =>
  async (dispatch) => {
    dispatch(createAssets(files, metadata));

    const uploadedAssets: Array<IAssetUploadResult> = [];
    const maxConcurrentUploads = 5;
    const uploadPromises = processWithConcurrencyLimit(
      maxConcurrentUploads,
      files.entries(),
      async ([assetId, file]) => {
        const asset = await dispatch(createAsset(deps, file, assetId, metadata));
        if (isUploaded(asset)) {
          uploadedAssets.push(asset);
        }
        onAssetFinished?.(assetId, asset?.newAsset ?? null);
      },
    );
    await Promise.all(uploadPromises);

    return uploadedAssets;
  };
