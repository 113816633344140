import { Checkbox } from '@kontent-ai/component-library/Checkbox';
import { CheckboxGroup } from '@kontent-ai/component-library/CheckboxGroup';
import { Input } from '@kontent-ai/component-library/Input';
import { Stack } from '@kontent-ai/component-library/Stack';
import { Spacing, gridUnit, px } from '@kontent-ai/component-library/tokens';
import { useState } from 'react';
import { ModalDialog } from '../../../../../component-library/components/Dialogs/ModalDialog/ModalDialog.tsx';
import {
  DataUiInput,
  getDataUiInputAttribute,
} from '../../../utils/dataAttributes/DataUiAttributes.ts';
import {
  AnyReason,
  FeedbackFeature,
  FeedbackReasons,
  FeedbackType,
  NegativeFeedbackPayload,
} from '../structuredFeedback.ts';

type Props = {
  readonly feature: FeedbackFeature;
  readonly feedbackReasons: FeedbackReasons;
  readonly isOpen: boolean;
  readonly onClose: () => void;
  readonly onSubmit: (payload: NegativeFeedbackPayload) => void;
};

export const NegativeFeedbackModal: React.FC<Props> = ({
  feature,
  feedbackReasons,
  isOpen,
  onClose,
  onSubmit,
}) => {
  const [canBeContacted, setCanBeContacted] = useState(true);
  const [explanation, setExplanation] = useState('');
  const [selectedReasons, setSelectedReasons] = useState<ReadonlyArray<AnyReason>>([]);

  const submit = () => {
    onSubmit({
      feature,
      feedbackType: FeedbackType.Negative,
      canBeContacted,
      explanation,
      reasons: selectedReasons,
    });
  };

  return (
    <ModalDialog
      cancelAction={{
        onClick: onClose,
      }}
      headline="What went wrong?"
      isDismissable
      isOpen={isOpen}
      minWidth={px(95 * gridUnit)}
      onClose={onClose}
      primaryAction={{
        text: 'Send',
        onClick: submit,
        disabled: !selectedReasons.length,
        tooltipText: selectedReasons.length
          ? undefined
          : 'Select at least one reason to submit the feedback.',
      }}
    >
      <Stack spacing={Spacing.XL}>
        <CheckboxGroup
          aria-label="What went wrong?"
          onChange={(newReasons) => setSelectedReasons(newReasons as ReadonlyArray<AnyReason>)}
          selectedValues={selectedReasons}
        >
          {feedbackReasons.map(({ reason, label }) => (
            <CheckboxGroup.Checkbox key={reason} checkboxState="default" value={reason}>
              {label}
            </CheckboxGroup.Checkbox>
          ))}
          <CheckboxGroup.Checkbox checkboxState="default" value="other">
            Other
          </CheckboxGroup.Checkbox>
        </CheckboxGroup>
        <Input
          caption="Optional"
          {...getDataUiInputAttribute(DataUiInput.Text)}
          label="Can you tell us more?"
          onChange={(e) => setExplanation(e.target.value)}
          placeholder="I’m not satisfied because…"
          value={explanation}
        />
        <Checkbox checkboxState="default" checked={canBeContacted} onToggle={setCanBeContacted}>
          You can contact me about my feedback
        </Checkbox>
      </Stack>
    </ModalDialog>
  );
};
