import { InvariantException } from '@kontent-ai/errors';
import { Dispatch, GetState, ThunkPromise } from '../../../../../@types/Dispatcher.type.ts';
import { TrackedEvent } from '../../../../../_shared/constants/trackedEvent.ts';
import { TrackUserEventAction } from '../../../../../_shared/models/TrackUserEvent.type.ts';
import { EnvironmentEventType } from '../../../../../_shared/models/TrackUserEventData.ts';
import { getCurrentProjectId } from '../../../../../data/reducers/user/selectors/userProjectsInfoSelectors.ts';
import { IProjectRepository } from '../../../../../repositories/interfaces/IProjectRepository.type.ts';
import { UntitledEnvironmentSurrogateName } from '../../constants/UIConstants.ts';
import {
  Environment_RenameEnvironment_Failed,
  Environment_RenameEnvironment_Finished,
  Environment_RenameEnvironment_Started,
} from '../../constants/generalSettingsActionTypes.ts';
import { EnvironmentObservedState } from '../../containers/EnvironmentNameEditorElement.tsx';

interface IDeps {
  readonly loadProjects: () => ThunkPromise;
  readonly loadUserProjectsInfo: () => ThunkPromise;
  readonly projectRepository: IProjectRepository;
  readonly trackUserEvent: TrackUserEventAction;
}

const started = (environmentName: string, environmentId: Uuid) =>
  ({
    type: Environment_RenameEnvironment_Started,
    payload: {
      environmentName,
      environmentId,
    },
  }) as const;

const finished = (environmentName: string, environmentId: Uuid) =>
  ({
    type: Environment_RenameEnvironment_Finished,
    payload: {
      environmentName,
      environmentId,
    },
  }) as const;

const failed = (environmentName: string, environmentId: Uuid) =>
  ({
    type: Environment_RenameEnvironment_Failed,
    payload: {
      environmentName,
      environmentId,
    },
  }) as const;

export type SaveEnvironmentNameActionsType = ReturnType<
  typeof started | typeof failed | typeof finished
>;

export const createSaveEnvironmentNameToServerAction =
  (deps: IDeps) =>
  (observedState: EnvironmentObservedState): ThunkPromise =>
  async (dispatch: Dispatch, getState: GetState): Promise<void> => {
    const state = getState();
    const renamedEnvironmentId = getCurrentProjectId(state);
    const renamedEnvironment = state.data.projects.byId.get(renamedEnvironmentId);

    if (!renamedEnvironment) {
      throw InvariantException(`No environment with id ${renamedEnvironmentId} was found.`);
    }

    const newName = observedState.environmentName || UntitledEnvironmentSurrogateName;

    try {
      dispatch(started(newName, renamedEnvironmentId));

      await deps.projectRepository.renameEnvironment(newName, renamedEnvironmentId);
      await Promise.all([dispatch(deps.loadUserProjectsInfo()), dispatch(deps.loadProjects())]);
      dispatch(finished(newName, renamedEnvironmentId));
      dispatch(
        deps.trackUserEvent(TrackedEvent.Environment, {
          type: EnvironmentEventType.Rename,
          environmentId: renamedEnvironmentId,
        }),
      );
    } catch (error) {
      dispatch(failed(newName, renamedEnvironmentId));
      throw InvariantException(
        `Could not rename the environment because of the following error: ${JSON.stringify(error)}`,
      );
    }
  };
