export enum ItemEditingModalDialogType {
  CancelItemScheduledUnpublishConfirmationDialog = 'CancelItemScheduledUnpublishConfirmationDialog',
  CascadePublishDialog = 'CascadePublishDialog',
  CascadePublishFailedItemsDialog = 'CascadePublishFailedItemsDialog',
  CascadeScheduleDialog = 'CascadeScheduleDialog',
  CascadeScheduleFailedItemsDialog = 'CascadeScheduleFailedItemsDialog',
  CascadeUnpublishConfirmationDialog = 'CascadeUnpublishConfirmationDialog',
  ChangeContentItemCollectionDialog = 'ChangeContentItemCollectionDialog',
  ChangeWorkflowDialog = 'ChangeWorkflowDialog',
  ChangeWorkflowStepDialog = 'ChangeWorkflowStepDialog',
  ContentItemAddTaskDialog = 'ContentItemAddTaskDialog',
  ContentItemAssignContributorsDialog = 'ContentItemAssignContributorsDialog',
  ContentItemCodenameDialog = 'ContentItemCodenameDialog',
  ContentItemConfigurePreviewDialog = 'ContentItemConfigurePreviewDialog',
  ContentItemDueDateDialog = 'ContentItemDueDateDialog',
  ContentItemEditTaskDialog = 'ContentItemEditTaskDialog',
  ContentItemNoteDialog = 'ContentItemNoteDialog',
  DeleteItemConfirmationDialog = 'DeleteItemConfirmationDialog',
  DiscardNewVersionConfirmationDialog = 'DiscardNewVersionConfirmationDialog',
  DuplicateItemWithContent = 'DuplicateItemWithContent',
  DuplicateItemWithoutContent = 'DuplicateItemWithoutContent',
  MoveItemToArchivedStepConfirmationDialog = 'ArchiveItemConfirmationDialog',
  NewVariantWorkflowSelectionDialog = 'NewVariantWorkflowSelectionDialog',
  None = 'none',
}
