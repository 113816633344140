import { Callout } from '@kontent-ai/component-library/Callout';
import { Stack } from '@kontent-ai/component-library/Stack';
import { Spacing } from '@kontent-ai/component-library/tokens';
import React from 'react';
import {
  ActionsPlacement,
  PendingInvitationActions,
} from '../../../../../_shared/components/PendingInvitationActions.tsx';
import {
  IPopoverTexts,
  IUserActiveStatus,
  UserActiveStatusToggleWithConfirmationDialog,
} from '../../../../../_shared/components/users/UserActiveStatusToggleWithConfirmationDialog.tsx';
import { SubscriptionPlanIncludedUserLimitReachedWarning } from '../../../../../_shared/containers/SubscriptionWarnings/SubscriptionPlanIncludedUserLimitReachedWarning.tsx';
import { SubscriptionPlanMaxUserLimitReachedWarning } from '../../../../../_shared/containers/SubscriptionWarnings/SubscriptionPlanMaxUserLimitReachedWarning.tsx';
import { IProjectContributor } from '../../../../../data/models/users/ProjectContributor.ts';

const getPopoverTexts = (
  environmentName: string,
  isInactive: boolean,
  isProjectManager: boolean,
  areMultipleEnvironments: boolean,
): IPopoverTexts => {
  const toggleAction = isInactive ? 'Activate' : 'Deactivate';

  if (!areMultipleEnvironments) {
    return {
      headlineText: toggleAction,
      primaryActionText: toggleAction,
    };
  }

  if (isProjectManager) {
    return {
      headlineText: `${toggleAction} everywhere?`,
      primaryActionText: `${toggleAction} in all environments`,
    };
  }
  return {
    headlineText: `${toggleAction} in “${environmentName}” environment?`,
    primaryActionText: `${toggleAction} in the “${environmentName}” environment`,
  };
};

const getToggleTooltipText = (
  isToggleDisabled: boolean,
  isUserAdmin: boolean,
  isCurrentUser: boolean,
) => {
  if (isToggleDisabled && isCurrentUser) {
    return 'You can’t change your own status.';
  }

  if (isToggleDisabled && isUserAdmin && !isCurrentUser) {
    return 'You can’t change status of a subscription admin.';
  }

  return undefined;
};

const MultipleEnvironmentsFriendlyWarning: React.FC<{
  readonly environmentName: string;
  readonly inactive: boolean;
  readonly isProjectManager: boolean;
}> = ({ environmentName, inactive, isProjectManager }) => {
  return isProjectManager ? (
    <Callout calloutType="friendlyWarning" headline="This user is a Project manager">
      {inactive ? (
        <Stack spacing={Spacing.S}>
          <p>They’ll have access to all collections, languages, and environments in the project.</p>
          <p>
            By activating the user, you activate them in all environments. If the user has a
            different role in other <br />
            environments, they’ll become Project manager there as well.
          </p>
        </Stack>
      ) : (
        <p>They’ll lose access to all collections, languages, and environments in the project.</p>
      )}
    </Callout>
  ) : (
    <Callout
      calloutType="friendlyWarning"
      headline={`This user will be ${
        inactive ? 'activated' : 'deactivated'
      } in the “${environmentName}” environment only`}
    >
      They may still be {inactive ? 'inactive' : 'active'} in other environments.
    </Callout>
  );
};

export interface IUserStatusCellContentProps {
  readonly areMultipleEnvironments: boolean;
  readonly contributor: IProjectContributor;
  readonly disabled: boolean;
  readonly environmentName: string;
  readonly isCurrentUser: boolean;
  readonly isCurrentUserAdmin: boolean;
  readonly isCustomPlan: boolean;
  readonly isEmployee: boolean;
  readonly isProjectManager: boolean;
  readonly isUserIncludedUnitsReached: boolean;
  readonly isUserMaxLimitReached: boolean;
  readonly onResendInvite: () => void;
  readonly onRevokeInvite: () => void;
  readonly onSelectPlan: () => void;
  readonly onShowIntercom: () => void;
  readonly onToggleActiveState: () => void;
}

export const UserStatusCellContent: React.FC<IUserStatusCellContentProps> = ({
  areMultipleEnvironments,
  contributor,
  disabled,
  environmentName,
  isCurrentUser,
  isCurrentUserAdmin,
  isCustomPlan,
  isEmployee,
  isProjectManager,
  isUserIncludedUnitsReached,
  isUserMaxLimitReached,
  onSelectPlan,
  onResendInvite,
  onRevokeInvite,
  onShowIntercom,
  onToggleActiveState,
}) => {
  const popoverTexts = getPopoverTexts(
    environmentName,
    contributor.inactive,
    isProjectManager,
    areMultipleEnvironments,
  );

  const activatingUserOverMaxLimit = !isEmployee && contributor.inactive && isUserMaxLimitReached;
  const activatingUserOverIncludedUnitsLimit =
    !isEmployee && contributor.inactive && (isUserMaxLimitReached || isUserIncludedUnitsReached);

  const userActiveStatus: IUserActiveStatus = {
    isActive: !contributor.inactive,
    isActivating: contributor.isActivating,
    isDeactivating: contributor.isDeactivating,
    isInvitationPending: contributor.isInvitationPending,
  };

  return contributor.isInvitationPending ? (
    <PendingInvitationActions
      isAdminOnProject={contributor.isAdminOnProject}
      isInviteRevoking={contributor.isInviteRevoking}
      isInviteSending={contributor.isInviteSending}
      isInviteSent={contributor.isInviteSent}
      onResendInvite={onResendInvite}
      onRevokeInvite={onRevokeInvite}
      placement={ActionsPlacement.UserListing}
    />
  ) : (
    <UserActiveStatusToggleWithConfirmationDialog
      activatingUserOverMaxLimit={activatingUserOverMaxLimit}
      disabled={disabled}
      isCurrentUserAdmin={isCurrentUserAdmin}
      isCustomPlan={isCustomPlan}
      popoverTexts={popoverTexts}
      showConfirmationDialog={
        areMultipleEnvironments ||
        activatingUserOverMaxLimit ||
        activatingUserOverIncludedUnitsLimit
      }
      userActiveStatus={userActiveStatus}
      onSelectPlan={onSelectPlan}
      onShowIntercom={onShowIntercom}
      onToggleUserActiveState={onToggleActiveState}
      tooltipText={getToggleTooltipText(disabled, contributor.isAdminOnProject, isCurrentUser)}
      renderPopoverDialogContent={() => {
        return activatingUserOverMaxLimit ? (
          <SubscriptionPlanMaxUserLimitReachedWarning hideButton />
        ) : (
          <Stack spacing={Spacing.M}>
            {activatingUserOverIncludedUnitsLimit && (
              <SubscriptionPlanIncludedUserLimitReachedWarning
                newEmails={[contributor.email]}
                isInActivationMode
              />
            )}

            {areMultipleEnvironments && (
              <MultipleEnvironmentsFriendlyWarning
                environmentName={environmentName}
                inactive={contributor.inactive}
                isProjectManager={isProjectManager}
              />
            )}
          </Stack>
        );
      }}
    />
  );
};

UserStatusCellContent.displayName = 'UserStatusCellContent';
