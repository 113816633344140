import { ThunkPromise } from '../../../../@types/Dispatcher.type.ts';
import { trackUserEvent } from '../../../../_shared/actions/thunks/trackUserEvent.ts';
import { TrackedEvent } from '../../../../_shared/constants/trackedEvent.ts';
import { repositoryCollection } from '../../../../_shared/repositories/repositories.ts';
import { ICopyProjectValidationData } from '../../models/ICopyProjectValidationData.type.ts';
import { canProjectBeCopied, getCopyProjectValidationData } from '../../utils/copyProjectUtils.ts';

const { projectRepository } = repositoryCollection;

export const validateProjectCopy =
  (
    sourceProjectId: Uuid,
    targetSubscriptionId: Uuid,
    abortSignal?: AbortSignal,
  ): ThunkPromise<ICopyProjectValidationData | null> =>
  async (dispatch, getState) => {
    const copyProjectDataInfo = sourceProjectId
      ? await projectRepository.getProjectDataInfo(sourceProjectId, abortSignal)
      : null;

    const copyProjectValidationData = getCopyProjectValidationData(
      getState(),
      sourceProjectId,
      targetSubscriptionId,
      copyProjectDataInfo,
    );

    if (canProjectBeCopied(copyProjectValidationData)) {
      return null;
    }

    const templateContentLimitExceededData = {
      'project-template-id': sourceProjectId,
      'has-custom-type-elements': copyProjectDataInfo?.containsCustomElements ?? false,
    };
    dispatch(
      trackUserEvent(TrackedEvent.TemplateContentLimitExceeded, templateContentLimitExceededData),
    );

    return copyProjectValidationData;
  };
