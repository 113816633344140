import { TrackedEvent } from '../../../../../_shared/constants/trackedEvent.ts';
import { TrackUserEvent } from '../../../../../_shared/models/TrackUserEvent.type.ts';
import { CustomTypeElementEventData } from '../../../../../_shared/models/TrackUserEventData.ts';
import { ElementType } from '../../../../contentInventory/content/models/ContentItemElementType.ts';
import { ICustomTypeElementData } from '../../models/elements/CustomTypeElementData.tsx';
import { IBaseTypeElementData } from '../../models/elements/types/TypeElementData.ts';
import { isCustomTypeElement } from '../../types/typeElementTypeGuards.ts';

const convertToCustomTypeElementEventData = (
  elementData: ICustomTypeElementData,
): CustomTypeElementEventData => {
  return {
    url: elementData.sourceUrl,
    config: elementData.config,
    'element-id': elementData.elementId,
  };
};

const wasAnyCustomTypeElementParameterModified = (
  newElement: ICustomTypeElementData,
  originalElement: ICustomTypeElementData,
): boolean => {
  return (
    originalElement.sourceUrl !== newElement.sourceUrl ||
    newElement.config !== originalElement.config
  );
};

const fireTrackingCustomTypeElementParameterChangedEvent = (
  trackUserEvent: TrackUserEvent,
  newElement: ICustomTypeElementData,
  originalElement?: ICustomTypeElementData,
): void => {
  if (
    !newElement ||
    (originalElement && originalElement.type !== newElement.type) ||
    newElement.type !== ElementType.Custom
  ) {
    return;
  }

  if (
    !originalElement ||
    (originalElement && wasAnyCustomTypeElementParameterModified(newElement, originalElement))
  ) {
    trackUserEvent(
      TrackedEvent.CustomElementTypeUpsert,
      convertToCustomTypeElementEventData(newElement),
    );
  }
};

export type ITrackCustomTypeElementUpserted = (
  trackEvent: TrackUserEvent,
  newElements: ReadonlyArray<IBaseTypeElementData>,
  originalElements?: ReadonlyArray<IBaseTypeElementData>,
) => void;

export const trackCustomTypeElementUpserted: ITrackCustomTypeElementUpserted = (
  trackEvent: TrackUserEvent,
  newElements: ReadonlyArray<IBaseTypeElementData>,
  originalElements?: ReadonlyArray<IBaseTypeElementData>,
): void => {
  newElements.filter(isCustomTypeElement).forEach((el: ICustomTypeElementData) => {
    const originalCustomElement = (originalElements || [])
      .filter(isCustomTypeElement)
      .find((original) => original.elementId === el.elementId);
    fireTrackingCustomTypeElementParameterChangedEvent(trackEvent, el, originalCustomElement);
  });
};
