import { Button } from '@kontent-ai/component-library/Button';
import { Card } from '@kontent-ai/component-library/Card';
import { Stack } from '@kontent-ai/component-library/Stack';
import { Spacing } from '@kontent-ai/component-library/tokens';
import { FC, useState } from 'react';

type Props = {
  readonly onRequestPasswordChange: () => void;
};

export const ChangePassword: FC<Props> = ({ onRequestPasswordChange }) => {
  const [isPasswordChangeRequested, setIsPasswordChangeRequested] = useState(false);

  const requestPasswordChange = (): void => {
    onRequestPasswordChange();
    setIsPasswordChangeRequested(true);
  };

  return (
    <Card css="flex: 1" component="section">
      <Card.Headline>Change password</Card.Headline>
      <Card.Body>
        <Stack spacing={Spacing.XL}>
          <p>
            If you need to change your password, click the button below and we’ll send you an email
            to set a new one.
          </p>
          {isPasswordChangeRequested ? (
            'The email is on its way - check your inbox.'
          ) : (
            <Button
              buttonStyle="primary"
              disabled={isPasswordChangeRequested}
              onClick={requestPasswordChange}
            >
              Set new password
            </Button>
          )}
        </Stack>
      </Card.Body>
    </Card>
  );
};

ChangePassword.displayName = 'ChangePassword';
