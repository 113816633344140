import { assert } from '@kontent-ai/utils';
import { ThunkPromise } from '../../../../../../@types/Dispatcher.type.ts';
import { TrackUserEventAction } from '../../../../../../_shared/models/TrackUserEvent.type.ts';
import { TemporaryContentItemState } from '../../../../../contentInventory/content/models/temporaryContentItemState.ts';
import { ITemporaryContentItem } from '../../../../models/ITemporaryContentItem.type.ts';
import { ICreateNewContentItemAction } from '../../../NewContentItem/actions/thunks/createNewContentItem.ts';
import { markEditedItemAsTemporary } from '../contentItemEditingActions.ts';

interface IDeps {
  readonly trackUserEvent: TrackUserEventAction;
  readonly archiveTemporaryContentItem: (temporaryItem: ITemporaryContentItem) => ThunkPromise;
  readonly createNewContentItem: ICreateNewContentItemAction;
}

export type CreateNewItemFromType = (destinationCollectionId?: Uuid) => ThunkPromise<IResult>;

interface IResult {
  readonly createdItemId: Uuid;
}

export const createCreateNewItemFromType =
  (deps: IDeps): CreateNewItemFromType =>
  (destinationCollectionId) =>
  async (dispatch, getState): Promise<{ readonly createdItemId: Uuid }> => {
    const {
      contentApp: {
        editedContentItem,
        editedContentItemVariant,
        loadedContentItemTypes,
        editorUi: { temporaryItem },
      },
    } = getState();

    if (temporaryItem?.itemState === TemporaryContentItemState.Loaded) {
      await dispatch(deps.archiveTemporaryContentItem(temporaryItem));
    }

    assert(
      !!editedContentItem,
      () =>
        'createNewItemFromType.ts: Cannot create another item without original editedContentItem.',
    );

    const editedContentItemType = loadedContentItemTypes.get(
      editedContentItem.editedContentItemTypeId,
    );

    assert(
      !!editedContentItemType,
      () => 'createNewItemFromType.ts: Cannot create another item with no content type ID.',
    );
    assert(
      !!editedContentItemVariant,
      () => 'createNewItemFromType.ts: Cannot create another item with no variantId.',
    );

    const createdItem = await dispatch(
      deps.createNewContentItem({
        typeId: editedContentItemType.id,
        collectionId: destinationCollectionId ?? editedContentItem.collectionId,
      }),
    );

    dispatch(
      markEditedItemAsTemporary({
        itemId: createdItem.item.id,
        variantId: editedContentItemVariant.id.variantId,
        parentItem: null,
      }),
    );

    return { createdItemId: createdItem.item.id };
  };
