import { Collection } from '@kontent-ai/utils';
import { ThunkPromise } from '../../../../../../../@types/Dispatcher.type.ts';
import { IProjectSpecificStorage } from '../../../../../../../localStorages/projectSpecificStorage.ts';
import { IListingFilter } from '../../../../models/filter/IListingFilter.ts';
import { listingFilterChanged } from '../contentItemInventoryActions.ts';
import { TrackFilterChange } from './loadListingItems/trackFilterChange.ts';

interface IFilterContentItemActionDependencies {
  readonly ContentItemFilterStorage: IProjectSpecificStorage<IListingFilter> | null;
  readonly loadListingContentItemsForInventory: () => ThunkPromise;
  readonly trackFilterChange: TrackFilterChange;
}

export const createFilterContentItemsAction =
  ({
    ContentItemFilterStorage,
    loadListingContentItemsForInventory,
    trackFilterChange,
  }: IFilterContentItemActionDependencies) =>
  (): ThunkPromise =>
  async (dispatch, getState) => {
    const {
      data: { workflows },
      sharedApp: { currentProjectId },
      contentApp: {
        listingUi: { filter, savedFiltersUi },
      },
    } = getState();

    const previousFilter = savedFiltersUi.lastFilteredUsingSavedFilter
      ? null
      : (ContentItemFilterStorage?.load(currentProjectId) ?? null);

    dispatch(listingFilterChanged());
    dispatch(trackFilterChange(previousFilter, filter, Collection.getValues(workflows.byId)));

    ContentItemFilterStorage?.save(filter, currentProjectId);

    await dispatch(loadListingContentItemsForInventory());
  };
