import { ThunkPromise } from '../../../@types/Dispatcher.type.ts';
import { getSelectedLanguageIdOrThrow } from '../../../_shared/selectors/getSelectedLanguageId.ts';
import { ILoadListingContentItemsForInventory } from '../../../applications/contentInventory/content/features/ContentItemInventory/actions/thunks/loadListingItems/loadListingContentItems.ts';
import { createAiSearchFilter } from '../../../applications/contentInventory/content/utils/contentItemListModelConverters.ts';
import { ILoadListingItemsAction } from '../../../applications/itemEditor/features/LoadedItems/actions/thunks/loadListingItems.ts';
import { AiSearchRepository } from '../repository/AiSearchRepository.type.ts';
import { AiSearch_Finished, AiSearch_Started } from './aiSearchActionTypes.ts';

interface IDeps {
  readonly aiSearchRepository: AiSearchRepository;
  readonly loadListingItems: ILoadListingItemsAction;
}

export type AiSearchLoadListingContentItemsForInventoryActionsType = ReturnType<
  typeof finished | typeof started
>;

const started = () =>
  ({
    type: AiSearch_Started,
  }) as const;

const finished = (itemIds: ReadonlyArray<Uuid>) =>
  ({
    type: AiSearch_Finished,
    payload: { itemIds },
  }) as const;

export const createAiSearchLoadListingContentItemsForInventoryAction =
  (deps: IDeps): ILoadListingContentItemsForInventory =>
  (scrollPositionChanged, abortSignal): ThunkPromise =>
  async (dispatch, getState) => {
    if (scrollPositionChanged) return;

    const state = getState();

    const selectedLanguageId = getSelectedLanguageIdOrThrow(state);
    const filter = createAiSearchFilter(state.contentApp.listingUi.filter);

    dispatch(started());

    const result = await deps.aiSearchRepository.getListingItems(
      selectedLanguageId,
      filter,
      abortSignal,
    );
    const itemIds = result.items.map((item) => item.itemId);

    await dispatch(deps.loadListingItems(itemIds, abortSignal));

    dispatch(finished(itemIds));
  };
