import { delay } from '@kontent-ai/utils';
import { TrackUserEventAction } from '../../models/TrackUserEvent.type.ts';
import {
  TrackUserEventDirectData,
  eventTrackingService,
} from '../../services/eventTrackingService.ts';
import { getContextuallyTrackedData } from '../../utils/trackingUtils.ts';

export const trackUserEvent: TrackUserEventAction =
  (...[eventName, eventPayload, abortSignal]) =>
  async (_dispatch, getState) => {
    const state = getState();

    const contextuallyTrackedData = getContextuallyTrackedData(state);

    // event payload can overwrite any contextual data, provided tracked event decided to do so
    const completePayload: TrackUserEventDirectData = {
      ...contextuallyTrackedData,
      ...eventPayload,
    };

    // Defer executing the actual tracking as analytics could trigger style calculation when reading some contextual data
    await delay(0);

    if (!abortSignal?.aborted) {
      eventTrackingService.trackUserEvent(eventName, completePayload);
    }
  };
