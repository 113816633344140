import { isElementVerticallyOverflowing } from '@kontent-ai/DOM';
import React, { useRef, useState } from 'react';
import { css } from 'styled-components';
import useResizeObserver from 'use-resize-observer';
import { ScrollContainerContextProvider } from '../../../../../client/component-library/components/ScrollContainer/ScrollContainerContext.tsx';
import { Box } from '../../../layout/Box/Box.tsx';
import { spacingCard } from '../../../tokens/decision/spacing.ts';
import { Color } from '../../../tokens/quarks/colors.ts';
import { GradientType } from '../../../tokens/quarks/gradients.ts';
import { Spacing } from '../../../tokens/quarks/spacing.ts';
import { Typography } from '../../../tokens/quarks/typography.ts';
import { px } from '../../../tokens/utils/utils.ts';
import { Divider } from '../../Dividers/Divider.tsx';
import { DividerDirection } from '../../Dividers/components/StyledDivider.tsx';

const overflowModeValues = ['auto', 'scrollable', 'default'] as const;
type OverflowMode = (typeof overflowModeValues)[number];

interface ICardBodyProps {
  readonly children: React.ReactNode;
  readonly backgroundColor?: Color;
  readonly backgroundImage?: GradientType | string;
  readonly overflowMode?: OverflowMode;
}

const shouldShowDividers = (overflowMode: OverflowMode, isOverflowing: boolean): boolean => {
  if (overflowMode === 'scrollable') {
    return true;
  }

  if (overflowMode === 'default') {
    return false;
  }

  return isOverflowing;
};

export const CardBody = React.forwardRef<HTMLDivElement, React.PropsWithChildren<ICardBodyProps>>(
  (
    { children, backgroundColor, backgroundImage, overflowMode = 'default', ...otherProps },
    forwardedRef,
  ) => {
    const scrollableRef = useRef<HTMLDivElement>(null);

    const [isOverflowing, setIsOverflowing] = useState(false);

    useResizeObserver({
      ref: scrollableRef,
      onResize: () => {
        setIsOverflowing(isElementVerticallyOverflowing(scrollableRef.current));
      },
    });

    const isScrollable = overflowMode === 'scrollable' || overflowMode === 'auto';
    const showDividers = shouldShowDividers(overflowMode, isOverflowing);

    const boxCss = css`
      word-break: break-word;
      ${
        isScrollable &&
        css`
        overflow-x: hidden;
        overflow-y: auto;
      `
      }
      height: 100%;
    `;

    return (
      <Box
        ref={forwardedRef}
        flexGrow={1}
        flexShrink={1}
        flexBasis="auto"
        display="flex"
        flexDirection="column"
        // offset padding of the whole Card because dividers need to be from edge to edge
        marginX={px(-1 * spacingCard)}
        overflow={isScrollable ? 'hidden' : undefined}
        css="grid-area: body;"
      >
        <ScrollContainerContextProvider
          scrollContainerRef={scrollableRef}
          tippyBoundaryRef={scrollableRef}
        >
          {showDividers && (
            <Divider
              direction={DividerDirection.Horizontal}
              offsetAfter={Spacing.None}
              offsetBefore={Spacing.None}
            />
          )}
          <Box
            backgroundColor={backgroundColor}
            backgroundImage={backgroundImage}
            paddingX={spacingCard}
            paddingY={showDividers ? spacingCard : undefined}
            ref={scrollableRef}
            // https://github.com/adobe/react-spectrum/issues/3371#issuecomment-1204529832
            tabIndex={-1}
            typography={Typography.UIParagraph}
            // eslint-disable-next-line @typescript-eslint/no-base-to-string
            css={`${boxCss}`}
            {...otherProps}
          >
            {children}
          </Box>
          {showDividers && (
            <Divider
              direction={DividerDirection.Horizontal}
              offsetAfter={Spacing.None}
              offsetBefore={Spacing.None}
            />
          )}
        </ScrollContainerContextProvider>
      </Box>
    );
  },
);

CardBody.displayName = 'CardBody';
