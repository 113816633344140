import { getSelfOrParent, isElement } from '@kontent-ai/DOM';
import { RequiredA11yLabellingProps } from '@kontent-ai/component-library/component-utils';
import { ShortcutsConfig, useHotkeys } from '@kontent-ai/component-library/hooks';
import { getFocusableTreeWalker } from '@react-aria/focus';
import { useInteractOutside, useInteractionModality } from '@react-aria/interactions';
import { mergeProps } from '@react-aria/utils';
import { HTMLAttributes, RefObject } from 'react';
import { ElementAttributes } from '../../../../constants/elementAttributes.ts';

type Props = RequiredA11yLabellingProps &
  Readonly<{
    isDisabled?: boolean;
    getCommentTriggerElement: () => HTMLElement | null;
    onEscape?: () => void;
    onInteractOutside?: () => void;
  }>;

export const useAccessibleCommentThread = <TInstance extends HTMLElement>(
  {
    'aria-label': ariaLabel,
    'aria-labelledby': ariaLabelledby,
    isDisabled,
    getCommentTriggerElement,
    onEscape,
    onInteractOutside,
  }: Props,
  commentThreadRef: RefObject<TInstance>,
) => {
  const modality = useInteractionModality();

  const onClose = () => {
    if (modality !== 'pointer') {
      const commentThreadTriggerElement = getCommentTriggerElement();
      commentThreadTriggerElement?.focus();
    }
  };

  const treeWalker = commentThreadRef.current
    ? getFocusableTreeWalker(commentThreadRef.current)
    : null;

  const hotkeysProps = useHotkeys(
    {
      [ShortcutsConfig.ShiftTab]: (e) => {
        if (commentThreadRef.current && treeWalker) {
          const firstFocusableNode = treeWalker.firstChild();
          treeWalker.currentNode = commentThreadRef.current;

          const isFirstFocusableNodeCurrentlyFocused = firstFocusableNode?.isSameNode(
            document.activeElement,
          );
          const isCommentThreadCurrentlyFocused =
            document.activeElement === commentThreadRef.current;

          if (isFirstFocusableNodeCurrentlyFocused || isCommentThreadCurrentlyFocused) {
            e.preventDefault();

            onClose();
            onInteractOutside?.();
          }
        }
      },
      [ShortcutsConfig.Tab]: (e) => {
        if (commentThreadRef.current && treeWalker) {
          const lastFocusableNode = treeWalker.lastChild();
          treeWalker.currentNode = commentThreadRef.current;

          const isLastFocusableNodeCurrentlyFocused = lastFocusableNode?.isSameNode(
            document.activeElement,
          );
          if (isLastFocusableNodeCurrentlyFocused) {
            e.preventDefault();

            onClose();
            onInteractOutside?.();
          }
        }
      },
      [ShortcutsConfig.Escape]: () => {
        onClose();
        onEscape?.();
      },
    },
    { ref: commentThreadRef, isDisabled },
  );

  useInteractOutside({
    ref: commentThreadRef,
    onInteractOutside: (event) => {
      if (!isElement(event.target)) {
        return;
      }

      const isBlurHandlingDisabled = getSelfOrParent(
        event.target,
        (element) => element.getAttribute(ElementAttributes.BlurCommentThreadOnClick) === 'false',
      );

      if (!isBlurHandlingDisabled) {
        onClose();
        onInteractOutside?.();
      }
    },
    isDisabled,
  });

  const ariaProps = {
    'aria-label': ariaLabel,
    'aria-labelledby': ariaLabelledby,
    role: 'article',
    tabIndex: -1,
  } satisfies HTMLAttributes<HTMLElement>;

  return {
    commentThreadProps: mergeProps(hotkeysProps, ariaProps),
    onClose,
  };
};
