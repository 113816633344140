import React, { useCallback } from 'react';
import { useHistory } from 'react-router';
import { trackUserEvent } from '../../../../../../_shared/actions/thunks/trackUserEvent.ts';
import { TrackedEvent } from '../../../../../../_shared/constants/trackedEvent.ts';
import { useDispatch } from '../../../../../../_shared/hooks/useDispatch.ts';
import { useSelector } from '../../../../../../_shared/hooks/useSelector.ts';
import { ContentEntryOverwrittenEventData } from '../../../../../../_shared/models/events/ContentItemEventData.type.ts';
import { closeCurrentItemOverwrittenNotification } from '../../../../actions/contentActions.ts';
import { getKeysOfDetectedDifferences } from '../../../../utils/itemOverwriteUtils.ts';
import { refreshItemEditing } from '../../actions/thunkContentItemEditingActions.ts';
import {
  ExtendedContentChange,
  OverwriteNotificationBar as OverwriteNotificationBarComponent,
} from '../../components/contentItemEditorNotifications/overwriteNotification/OverwriteNotificationBar.tsx';
import { emptyContentChange } from '../../constants/emptyContentChange.ts';
import { getExtendedContentChange } from '../../selectors/getExtendedContentChange.ts';

const isChangeEmpty = (contentChange: ExtendedContentChange): boolean =>
  contentChange === emptyContentChange;

const getTrackingData = (
  contentChange: ExtendedContentChange,
): ContentEntryOverwrittenEventData => ({
  changeByManageApi: contentChange.changeByManageApi,
  changeBySelf: contentChange.changeBySelf,
  changeReason: contentChange.changeReason,
  differenceKeys: getKeysOfDetectedDifferences(contentChange.difference),
});

export const OverwriteNotificationBar: React.FC = () => {
  const history = useHistory();
  const dispatch = useDispatch();

  const contentChange = useSelector((s) =>
    getExtendedContentChange(s, s.contentApp.editedContentItemOverwritten),
  );
  const changeByUser = useSelector((s) =>
    contentChange.changeBy ? s.data.users.usersById.get(contentChange.changeBy) : undefined,
  );

  const close = () => dispatch(closeCurrentItemOverwrittenNotification());
  const refresh = (changeContent: ExtendedContentChange) => {
    dispatch(refreshItemEditing(history));

    if (!isChangeEmpty(changeContent)) {
      dispatch(
        trackUserEvent(
          TrackedEvent.ContentEntryOverwriteNotificationRefreshed,
          getTrackingData(changeContent),
        ),
      );
    }
  };
  const display = useCallback(
    (changeContent: ExtendedContentChange) =>
      !isChangeEmpty(changeContent) &&
      dispatch(
        trackUserEvent(
          TrackedEvent.ContentEntryOverwriteNotificationDisplayed,
          getTrackingData(changeContent),
        ),
      ),
    [],
  );

  return (
    <OverwriteNotificationBarComponent
      changeByUser={changeByUser}
      contentChange={contentChange}
      onClose={close}
      onRefresh={refresh}
      onDisplay={display}
    />
  );
};

OverwriteNotificationBar.displayName = 'OverwriteNotificationBar';
