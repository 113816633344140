import { Box } from '@kontent-ai/component-library/Box';
import { Label, LabelSize } from '@kontent-ai/component-library/Label';
import { MultiSelect } from '@kontent-ai/component-library/MultiSelect';
import { IBaseSelectItem } from '@kontent-ai/component-library/Selects';
import { Spacing } from '@kontent-ai/component-library/tokens';
import React from 'react';
import { PublishedItemsFilterModel } from '../types/PublishedItemsFilterModel.type.ts';

type PublishedItemsPublishedBySelectorProps = React.PropsWithChildren &
  Readonly<{
    availableUsers: IBaseSelectItem[];
    filter: PublishedItemsFilterModel;
    setFilter: React.Dispatch<React.SetStateAction<PublishedItemsFilterModel>>;
  }>;

export const PublishedItemsPublishedBySelector = ({
  availableUsers,
  filter,
  setFilter,
}: PublishedItemsPublishedBySelectorProps) => {
  return (
    <>
      <Box>
        <Label size={LabelSize.L}>Published by</Label>
      </Box>
      <Box paddingTop={Spacing.S}>
        <MultiSelect
          aria-label="Select a publisher"
          items={availableUsers}
          onSelectionChange={(userIds: ReadonlySet<string>) => {
            setFilter({
              ...filter,
              userIds: userIds.size > 0 ? Array.from(userIds) : null,
            });
          }}
          placeholder="Select a publisher"
          selectedItemIds={filter.userIds}
        />
      </Box>
    </>
  );
};
