import { AnimatedProgressIcon } from '@kontent-ai/component-library/Icons';
import { Inline } from '@kontent-ai/component-library/Inline';
import { FontSize, colorIconDefault } from '@kontent-ai/component-library/tokens';

export const PreviewRefreshStatus = () => {
  return (
    <Inline align="center">
      <AnimatedProgressIcon color={colorIconDefault} display="inline-block" fontSize={FontSize.L} />
    </Inline>
  );
};
