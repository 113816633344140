import { QuinaryButton } from '@kontent-ai/component-library/Button';
import { Icons } from '@kontent-ai/component-library/Icons';
import { Menu } from '@kontent-ai/component-library/Menu';
import { IconSize } from '@kontent-ai/component-library/tokens';
import React, { RefObject } from 'react';
import { generatePath } from 'react-router';
import { trackUserEvent } from '../../../../../../_shared/actions/thunks/trackUserEvent.ts';
import { TrackedEvent } from '../../../../../../_shared/constants/trackedEvent.ts';
import { useDispatch } from '../../../../../../_shared/hooks/useDispatch.ts';
import { useSelector } from '../../../../../../_shared/hooks/useSelector.ts';
import { CommentAction } from '../../../../../../_shared/models/events/CommentEventData.type.ts';
import { getEditedContentItemVariantId } from '../../../../../../_shared/selectors/getEditedContentItemVariant.ts';
import { getDataAttribute } from '../../../../../../_shared/utils/dataAttributes/DataAttributes.ts';
import {
  DataUiCollection,
  DataUiCommentsAction,
  getDataUiCollectionAttribute,
  getDataUiCommentActionAttribute,
} from '../../../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';
import { getCurrentProjectId } from '../../../../../../data/reducers/user/selectors/userProjectsInfoSelectors.ts';
import { CommentRoute } from '../../../../../smartLink/constants/routePaths.ts';
import { ElementAttributes } from '../../../../constants/elementAttributes.ts';
import { commentThreadItemEditingStarted } from '../../actions/contentItemEditingActions.ts';
import {
  reopenCommentThread,
  resolveCommentThread,
} from '../../actions/thunkContentItemEditingActions.ts';

export interface ICommentContextMenuOwnProps {
  readonly allowEdit: boolean;
  readonly allowResolve: boolean;
  readonly allowUnresolve: boolean;
  readonly commentId: Uuid;
  readonly threadId: Uuid;
}

const getCurrentUrlWihNewPath = (newPath: string): string => {
  const currentURL = window.location.href;
  // Replace the entire path in the current URL
  return currentURL.replace(window.location.pathname, newPath);
};

export const CommentContextMenu: React.FC<ICommentContextMenuOwnProps> = ({
  allowUnresolve,
  allowResolve,
  allowEdit,
  commentId,
  threadId,
}) => {
  const dispatch = useDispatch();
  const currentProjectId = useSelector(getCurrentProjectId);
  const editedItemVariantId = useSelector(getEditedContentItemVariantId);

  const onMarkAsResolved = () => dispatch(resolveCommentThread(threadId, true));
  const onMarkAsUnresolved = () => dispatch(reopenCommentThread(threadId));
  const onStartCommentEdit = () =>
    dispatch(
      commentThreadItemEditingStarted({
        threadId,
        commentId,
      }),
    );

  const getCopyLink = () => {
    if (!editedItemVariantId?.itemId && !editedItemVariantId?.itemId) {
      return '';
    }

    const pathToCopy = generatePath(CommentRoute, {
      commentThreadId: threadId,
      itemGuid: editedItemVariantId?.itemId,
      variantGuid: editedItemVariantId?.variantId,
      projectId: currentProjectId,
    });

    return getCurrentUrlWihNewPath(pathToCopy);
  };

  const handleCopySuccess = () => {
    dispatch(trackUserEvent(TrackedEvent.Comments, { action: CommentAction.CopyLink }));
  };

  return (
    <Menu>
      <Menu.Trigger>
        {(ref: RefObject<HTMLButtonElement>, triggerProps, isOpen) => (
          <QuinaryButton
            ref={ref}
            activated={isOpen}
            tooltipText="More actions"
            tooltipPlacement="top-end"
            {...triggerProps}
            {...getDataUiCommentActionAttribute(DataUiCommentsAction.OpenCommentMenu)}
          >
            <QuinaryButton.Icon icon={Icons.Ellipsis} screenReaderText="More actions" />
          </QuinaryButton>
        )}
      </Menu.Trigger>
      <Menu.List
        isNonModal
        {...getDataUiCollectionAttribute(DataUiCollection.CommentMenuItems)}
        // We need to prevent comment blur upon clicking on its context menu (outside the comment)
        // to make sure the active comment in floating editor doesn't hide upon this action
        {...getDataAttribute(ElementAttributes.BlurCommentThreadOnClick, 'false')}
      >
        <Menu.ClipboardItem
          id="link"
          label="Copy link"
          value={getCopyLink()}
          onCopySuccess={handleCopySuccess}
          {...getDataUiCommentActionAttribute(DataUiCommentsAction.GetLink)}
        />
        {allowResolve && (
          <Menu.Item
            id="resolve"
            leadingElement={<Icons.CheckCircle size={IconSize.S} />}
            label="Resolve"
            onAction={onMarkAsResolved}
            {...getDataUiCommentActionAttribute(DataUiCommentsAction.MarkAsResolved)}
          />
        )}
        {allowUnresolve && (
          <Menu.Item
            id="unresolve"
            leadingElement={<Icons.RotateDoubleRight size={IconSize.S} />}
            label="Reopen"
            onAction={onMarkAsUnresolved}
            {...getDataUiCommentActionAttribute(DataUiCommentsAction.MarkAsUnresolved)}
          />
        )}
        {allowEdit && (
          <Menu.Item
            id="edit"
            leadingElement={<Icons.Edit size={IconSize.S} />}
            label="Edit"
            onAction={onStartCommentEdit}
            {...getDataUiCommentActionAttribute(DataUiCommentsAction.Edit)}
          />
        )}
      </Menu.List>
    </Menu>
  );
};

CommentContextMenu.displayName = 'CommentContextMenu';
