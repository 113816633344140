import { Tooltip } from '@kontent-ai/component-library/Tooltip';
import classNames from 'classnames';
import React from 'react';
import { formatUserName } from '../../../../../../../_shared/utils/users/usersUtils.ts';
import { IProjectContributor } from '../../../../../../../data/models/users/ProjectContributor.ts';

export interface ITaskUserMentionOwnProps {
  readonly wasPinged?: boolean;
}

export interface ITaskUserMentionStateProps {
  readonly user?: IProjectContributor;
  readonly isCurrentUser: boolean;
}

type IUserMentionProps = ITaskUserMentionOwnProps & ITaskUserMentionStateProps;

export const TaskUserMention: React.FC<IUserMentionProps> = ({
  user,
  isCurrentUser,
  wasPinged,
}: IUserMentionProps) => {
  const userName = formatUserName(user);

  const tooltipUserName = isCurrentUser && wasPinged ? 'you' : userName;
  const tooltipText = wasPinged ? `We’ve notified ${tooltipUserName}` : tooltipUserName;

  return (
    <span className="mention mention--task">
      <Tooltip tooltipText={tooltipText} placement="top">
        <span
          className={classNames('mention-presentation', 'mention-presentation--task', {
            'mention-presentation--current-user': isCurrentUser,
          })}
        >
          {userName}
        </span>
      </Tooltip>
    </span>
  );
};

TaskUserMention.displayName = 'TaskUserMention';
