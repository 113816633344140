import {
  SpacesInDeliver,
  UnifiedWebSpotlight,
  WebSpotlightIntegration,
} from './ProjectFeatureFlagsServerKeys.ts';

export type ProjectFeatureFlagValue = boolean;

export type ProjectFeatureFlagsModel = {
  SpacesInDeliver: ProjectFeatureFlagValue;
  UnifiedWebSpotlight: ProjectFeatureFlagValue;
  WebSpotlightIntegration: ProjectFeatureFlagValue;
};

export const ProjectFeatureFlagsStateKeysByServerKeys: Dictionary<keyof ProjectFeatureFlagsModel> =
  {
    [SpacesInDeliver]: 'SpacesInDeliver',
    [UnifiedWebSpotlight]: 'UnifiedWebSpotlight',
    [WebSpotlightIntegration]: 'WebSpotlightIntegration',
  };
