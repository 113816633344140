import { isAbortError } from '@kontent-ai/errors';
import { ThunkPromise } from '../../../@types/Dispatcher.type.ts';
import { upsertUserProperty } from '../../actions/thunkSharedActions.ts';
import { trackUserEvent } from '../../actions/thunks/trackUserEvent.ts';
import { TrackedEvent } from '../../constants/trackedEvent.ts';
import { LastTrackedAvatarTypeServerKey } from '../../models/UserPropertiesServerKeys.ts';
import { AvatarType } from '../../models/events/AvatarTypeEventData.type.ts';
import { repositoryCollection } from '../../repositories/repositories.ts';
import { getCurrentUser } from '../../selectors/getCurrentUser.ts';
import { logError } from '../../utils/logError.ts';
import { useThunkPromise } from '../useThunkPromise.ts';

const { gravatarRepository } = repositoryCollection;

export const useTrackAvatarTypeChanged = () => {
  useThunkPromise(trackAvatarTypeChanged);
};

const trackAvatarTypeChanged =
  (abortSignal: AbortSignal): ThunkPromise =>
  async (dispatch, getState) => {
    try {
      const state = getState();
      const { gravatarHash } = getCurrentUser(state);
      const {
        sharedApp: {
          userProperties: { lastTrackedAvatarType },
        },
      } = state;

      const avatarType =
        gravatarHash && (await isGravatarAvailable(gravatarHash, abortSignal))
          ? AvatarType.Gravatar
          : AvatarType.Initials;

      if (avatarType !== lastTrackedAvatarType) {
        await dispatch(upsertUserProperty(LastTrackedAvatarTypeServerKey, avatarType));
        dispatch(
          trackUserEvent(TrackedEvent.AvatarTypeChanged, {
            avatarType,
            isInitial: !lastTrackedAvatarType,
          }),
        );
      }
    } catch (error) {
      if (!isAbortError(error)) {
        logError('Failed to load user avatar', error);
      }
    }
  };

const nonExistentGravatarHash = '7215ee9c7d9dc229d2921a40e899ec5f';

const isGravatarAvailable = async (
  gravatarHash: string,
  abortSignal: AbortSignal,
): Promise<boolean | null> => {
  const [gravatar, defaultGravatar] = await Promise.all([
    gravatarRepository.get(gravatarHash, abortSignal),
    gravatarRepository.get(nonExistentGravatarHash, abortSignal),
  ]);
  return gravatar?.contentLength !== defaultGravatar?.contentLength;
};
