import React from 'react';
import { connect } from 'react-redux';
import { Dispatch } from '../../../../../../../@types/Dispatcher.type.ts';
import { trackUserEvent } from '../../../../../../../_shared/actions/thunks/trackUserEvent.ts';
import { IStore } from '../../../../../../../_shared/stores/IStore.type.ts';
import {
  AssetTileActions,
  IAssetTileActionsDispatchProps,
  IAssetTileActionsOwnProps,
  IAssetTileActionsStateProps,
} from '../../../components/elements/asset/AssetTileActions.tsx';
import { getImageTransformationNotSupportedMessage } from '../../../utils/renditionUtils.ts';

type IAssetTileActionsContainerProps = IAssetTileActionsOwnProps;

function mapStateToProps(
  state: IStore,
  ownProps: IAssetTileActionsContainerProps,
): IAssetTileActionsStateProps & IAssetTileActionsOwnProps {
  const {
    data: {
      assets: { uploadingAssets },
    },
  } = state;

  const openRenditionDialogDisabledMessage = getImageTransformationNotSupportedMessage(
    ownProps.asset,
  );

  return {
    ...ownProps,
    isDragDisabled: !uploadingAssets.isEmpty() || ownProps.disabled,
    openRenditionDialogDisabledMessage,
  };
}

function mapDispatchToProps(dispatch: Dispatch): IAssetTileActionsDispatchProps {
  return {
    trackUserEvent: (...args) => dispatch(trackUserEvent(...args)),
  };
}

const AssetTileActionsContainer: React.ComponentType<
  React.PropsWithChildren<IAssetTileActionsContainerProps>
> = connect(mapStateToProps, mapDispatchToProps)(AssetTileActions);

export { AssetTileActionsContainer as AssetTileActions };
