import React from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch } from '../../../_shared/hooks/useDispatch.ts';
import { useSelector } from '../../../_shared/hooks/useSelector.ts';
import { getCurrentUser } from '../../../_shared/selectors/getCurrentUser.ts';
import { createFormValidationResolver } from '../../../_shared/utils/validation/createFormValidationResolver.ts';
import { submitProfileForm } from '../actions/thunkUserProfileActions.ts';
import { UserProfileForm } from '../components/UserProfileForm.tsx';
import { IUserProfileFormShape } from '../models/IUserProfileFormShape.type.ts';
import { userProfileFormValidationConfig } from '../validation/userProfileFormValidation.ts';

const UserProfileFormContainer: React.FC = () => {
  const currentUser = useSelector(getCurrentUser);
  const dispatch = useDispatch();

  const initialValues: IUserProfileFormShape = {
    firstName: currentUser.firstName,
    lastName: currentUser.lastName,
  };
  const formProps = useForm<IUserProfileFormShape>({
    defaultValues: initialValues,
    resolver: createFormValidationResolver(userProfileFormValidationConfig, {}),
  });

  const { formState, handleSubmit, reset } = formProps;

  const submitForm = handleSubmit(async (values) => {
    await dispatch(submitProfileForm(values));
    reset(values);
  });

  return (
    <UserProfileForm
      currentUser={currentUser}
      formProps={formProps}
      isPristine={!formState.isDirty}
      isSubmitting={formState.isSubmitting}
      onSubmit={submitForm}
    />
  );
};

UserProfileFormContainer.displayName = 'UserProfileFormContainer';
export { UserProfileFormContainer as UserProfileForm };
