import { Box } from '@kontent-ai/component-library/Box';
import { colorBackgroundLowEmphasis } from '@kontent-ai/component-library/tokens';
import { ReactNode } from 'react';
import { WebSpotlightPreviewError } from '../../../../../webSpotlight/components/preview/WebSpotlightPreviewError.tsx';
import { PreviewError } from '../../../../utils/previewUtils.ts';
import { PreviewContentItemInfo } from '../../../../utils/selectDeepestItemWithPreview.ts';
import { ErrorPreviewNotAvailableTitle } from '../../constants/uiConstants.ts';
import { OpenPreviewUrlSettingsButton } from '../../containers/contentItemPreview/previewEmptyStates/OpenPreviewUrlSettingsButton.tsx';
import { OpenInNewTabButton } from './OpenInNewTabButton.tsx';
import { AccessDenied } from './previewEmptyStates/AccessDenied.tsx';
import { MissingSpaceDomain } from './previewEmptyStates/MissingSpaceDomain.tsx';
import { NoPreview } from './previewEmptyStates/NoPreview.tsx';
import { NoPreviewProjectNotSetup } from './previewEmptyStates/NoPreviewProjectNotSetup.tsx';
import { PreviewEmptyStateDocumentationMessageLink } from './previewEmptyStates/PreviewEmptyStateDocumentationMessageLink.tsx';
import { PreviewErrorState } from './previewEmptyStates/PreviewErrorState.tsx';
import { PreviewLoadError } from './previewEmptyStates/PreviewLoadError.tsx';

export enum ProjectType {
  general = 'general',
  trial = 'trial',
  sample = 'sample',
}

type WebSpotlightPreviewContentProps = Readonly<{
  hideDocumentationLink: boolean;
  previewContentItemInfo: PreviewContentItemInfo | null;
  previewError: PreviewError;
  projectType: ProjectType;
  spaceId: Uuid | null;
}>;

export const PreviewErrors = ({
  hideDocumentationLink,
  previewContentItemInfo,
  previewError,
  projectType,
  spaceId,
}: WebSpotlightPreviewContentProps) => {
  return (
    <Box width="100%" height="100%" backgroundColor={colorBackgroundLowEmphasis}>
      <PreviewErrorMessage
        hideDocumentationLink={hideDocumentationLink}
        previewContentItemInfo={previewContentItemInfo}
        previewError={previewError}
        projectType={projectType}
        spaceId={spaceId}
      />
    </Box>
  );
};

const getPreviewErrorTitle = (error: PreviewError | undefined): string =>
  error === PreviewError.HttpInIFrame
    ? 'Configure your preview URL'
    : ErrorPreviewNotAvailableTitle;

const getPreviewErrorAdditionalInfo = (error: PreviewError | undefined): string | undefined =>
  error === PreviewError.HttpInIFrame
    ? 'You can still see how your content looks by opening it in a new tab.'
    : undefined;

const getPreviewErrorActionButton = (
  error: PreviewError,
  spaceId: Uuid | null,
): ReactNode | null => {
  if (error === PreviewError.HttpInIFrame) {
    return <OpenInNewTabButton spaceId={spaceId} buttonStyle="primary" />;
  }

  if (previewErrorWithoutButton.includes(error)) {
    return null;
  }

  return <OpenPreviewUrlSettingsButton />;
};

const previewErrorWithoutButton: ReadonlyArray<PreviewError> = [
  PreviewError.MissingUrlSlugValue,
  PreviewError.MissingUrlSlugElement,
  PreviewError.NotTranslated,
  PreviewError.TooManyPageContentItemsLinked,
];

const PreviewErrorMessage = ({
  hideDocumentationLink,
  previewContentItemInfo,
  previewError,
  projectType,
  spaceId,
}: WebSpotlightPreviewContentProps) => {
  if (previewError === PreviewError.AccessDenied) {
    return <AccessDenied />;
  }

  if (previewError === PreviewError.LoadFailed) {
    return <PreviewLoadError />;
  }

  if (previewError === PreviewError.NoPreview || !previewContentItemInfo) {
    if (projectType === ProjectType.trial || projectType === ProjectType.sample) {
      return <NoPreviewProjectNotSetup />;
    }

    return <NoPreview />;
  }

  if (previewError === PreviewError.MissingSpaceDomain) {
    return (
      <MissingSpaceDomain
        hideDocumentationLink={hideDocumentationLink}
        previewContentItemInfo={previewContentItemInfo}
      />
    );
  }

  const additionalInstructions = getPreviewErrorAdditionalInfo(previewError);

  return (
    <PreviewErrorState
      actionButton={getPreviewErrorActionButton(previewError, spaceId)}
      title={getPreviewErrorTitle(previewError)}
    >
      <WebSpotlightPreviewError
        error={previewError}
        previewContentItemInfo={previewContentItemInfo}
      />
      {additionalInstructions && <Box>{additionalInstructions}</Box>}
      {!hideDocumentationLink && <PreviewEmptyStateDocumentationMessageLink />}
    </PreviewErrorState>
  );
};
