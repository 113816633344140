import { displayWarningsOnPublishSelected } from '../../../ContentItemEditing/actions/thunkContentItemEditingActions.ts';
import { openChangeWorkflowStepModal } from './openChangeWorkflowStepModal.ts';
import { createOpenPublishModalAction } from './openPublishModal.tsx';
import { createOpenRestoreFromArchivedStepModalAction } from './openRestoreFromArchivedStepModal.ts';
import { createOpenUnpublishAndArchiveModalAction } from './openUnpublishAndArchiveModal.ts';
import { createOpenUpdateStepModalAction } from './openUpdateStepModal.ts';

export const openUpdateStepModal = createOpenUpdateStepModalAction({
  displayWarningsOnPublishSelected,
  openChangeWorkflowStepModal,
});

export const openPublishModal = createOpenPublishModalAction({
  displayWarningsOnPublishSelected,
  openChangeWorkflowStepModal,
});

export const openRestoreItemFromArchivedStepModal = createOpenRestoreFromArchivedStepModalAction({
  openChangeWorkflowStepModal,
});

export const openUnpublishAndArchiveModal = createOpenUnpublishAndArchiveModalAction({
  openChangeWorkflowStepModal,
});
