import { ThunkFunction } from '../../../../../../@types/Dispatcher.type.ts';
import { itemEditingModalWithPropertiesOpened } from '../../../../actions/contentActions.ts';
import { ItemEditingModalDialogType } from '../../../../constants/itemEditingModalDialogType.ts';
import { getModalDialogActionOrigin } from '../../../../selectors/getModalDialogActionOrigin.ts';

export const moveToArchivedStep =
  (workflowId: Uuid): ThunkFunction =>
  (dispatch, getState) => {
    dispatch(
      itemEditingModalWithPropertiesOpened({
        dialogType: ItemEditingModalDialogType.MoveItemToArchivedStepConfirmationDialog,
        actionOrigin: getModalDialogActionOrigin(getState()),
        workflowId,
      }),
    );
  };
