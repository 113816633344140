import { ContentItemId } from '../../../_shared/models/ContentItemId.ts';
import { PublishedItemsServerModel } from '../../../applications/missionControl/repositories/serverModels/missionControlRepositoryServerModels.type.ts';

export type PublishedListingContentItemRecord = Readonly<{
  id: Uuid;
  contentItemId: ContentItemId;
  contentTypeId: Uuid;
  name: string;
  lastPublishedAt: DateTimeStamp | null;
  collectionId: Uuid;
  publishedBy: string;
}>;

export const createPublishedListingContentItemRecordFromServerModel = (
  serverModel: PublishedItemsServerModel,
): PublishedListingContentItemRecord => {
  return {
    id: serverModel.visitRecordId,
    contentItemId: serverModel.contentItemId,
    contentTypeId: serverModel.contentTypeId,
    lastPublishedAt: serverModel.publishedDate,
    name: serverModel.contentItemName,
    collectionId: serverModel.collectionId,
    publishedBy: serverModel.userId,
  };
};
