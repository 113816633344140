import { Collection, notNullNorUndefined } from '@kontent-ai/utils';
import { ThunkFunction, ThunkPromise } from '../../../@types/Dispatcher.type.ts';
import { TypeElement } from '../../../applications/contentInventory/content/models/contentTypeElements/TypeElement.type.ts';
import { currentItemElementsOverwritten } from '../../../applications/itemEditor/actions/contentActions.ts';
import {
  handleCurrentItemOverwritten,
  handleDefaultVariantOverwritten,
} from '../../../applications/itemEditor/actions/thunkContentActions.ts';
import { ContentItemChangeReason } from '../../../applications/itemEditor/models/contentItem/ContentItemChangeReason.type.ts';
import { getEditedContentItemType } from '../../../applications/itemEditor/selectors/getEditedContentItemType.ts';
import { TrackedEvent } from '../../constants/trackedEvent.ts';
import { DefaultVariantId } from '../../constants/variantIdValues.ts';
import {
  AssignmentComparisonDifference,
  Notifications,
} from '../../services/signalR/signalRClient.type.ts';
import { isPublishedWorkflowStepSelected } from '../../utils/contentItemUtils.ts';
import { isUuid } from '../../utils/validation/typeValidators.ts';
import { trackUserEvent } from '../thunks/trackUserEvent.ts';

type ContentChangeNotificationPayload = Notifications['contentChange']['payload'];

const containsElementChanges = (params: ContentChangeNotificationPayload): boolean => {
  const elementChangesReasons: ReadonlyArray<string> = [
    ContentItemChangeReason.DiscardVersion,
    ContentItemChangeReason.RestoreRevision,
    ContentItemChangeReason.Update,
  ];

  return (
    elementChangesReasons.includes(params.changeReason) &&
    !!params.difference.changedElements.length
  );
};

const containsItemWideChanges = (params: ContentChangeNotificationPayload): boolean => {
  const difference = params.difference;

  const containsItemWideDifference =
    difference.assignmentDifferences.length > 0 ||
    difference.codenameChanged ||
    difference.collectionChanged ||
    difference.nameChanged ||
    difference.sitemapChanged;

  return params.changeReason !== ContentItemChangeReason.Update || containsItemWideDifference;
};

const getChangedNonLocalizableElements = (
  changedElements: UuidArray,
  editedTypeElements: ReadonlyArray<TypeElement>,
): UuidArray => {
  const nonLocalizableElementIds = editedTypeElements
    .filter((el) => el.isNonLocalizable)
    .map((el) => el.elementId);
  return Collection.intersect(changedElements, nonLocalizableElementIds);
};

const relevantDefaultVariantDifferences: ReadonlyArray<AssignmentComparisonDifference> = [
  AssignmentComparisonDifference.Workflow,
  AssignmentComparisonDifference.WorkflowStep,
  AssignmentComparisonDifference.PublishScheduleTime,
  AssignmentComparisonDifference.UnpublishScheduleTime,
];

const containsRelevantDefaultVariantDifferences = (
  differences: ReadonlyArray<AssignmentComparisonDifference>,
): boolean => differences.some((value) => relevantDefaultVariantDifferences.includes(value));

const trackEventsForOverwrittenLockedElements =
  (params: ContentChangeNotificationPayload): ThunkFunction =>
  (dispatch, getState) => {
    const {
      contentApp: {
        editedContentItemVariantElements,
        editorUi: { lockedElements },
      },
      data: { user },
    } = getState();

    const currentUserId = user.info.userId;
    const isChangedBySelf = params.changeBy === currentUserId;

    if (isChangedBySelf && !params.changeByManageApi) {
      return;
    }

    const changedElementIds = params.difference.changedElements;
    const changedLockedElements = lockedElements.filter(
      (session) =>
        session.userId === currentUserId && changedElementIds.includes(session.elementId),
    );

    changedLockedElements
      .map((session) =>
        editedContentItemVariantElements.find((e) => e.elementId === session.elementId),
      )
      .filter(notNullNorUndefined)
      .forEach((elementData) => {
        dispatch(
          trackUserEvent(TrackedEvent.ContentEntryLockedElementOverwritten, {
            'changed-by-manage-api': params.changeByManageApi,
            'changed-by-self': isChangedBySelf,
            'element-id': elementData.elementId,
            'element-type': elementData.type,
          }),
        );
      });
  };

export const onCurrentContentItemChange =
  (params: ContentChangeNotificationPayload): ThunkPromise =>
  async (dispatch, getState) => {
    const state = getState();
    const {
      contentApp: { editedContentItemVariant },
      sharedApp: { appInstanceId, currentProjectId },
    } = state;

    if (isUuid(currentProjectId)) {
      const isCurrentProjectEdited = params.projectId === currentProjectId;
      const isSameAppInstanceEdit = params.appInstanceId === appInstanceId;
      const isCurrentItemEdited = editedContentItemVariant?.id.itemId === params.itemId;
      if (!isCurrentProjectEdited || !isCurrentItemEdited || isSameAppInstanceEdit) {
        return;
      }

      const isCurrentVariantEdited =
        !params.variantId || editedContentItemVariant.id.variantId === params.variantId;
      if (isCurrentVariantEdited) {
        if (containsElementChanges(params)) {
          const changedElementIds = params.difference.changedElements;
          dispatch(currentItemElementsOverwritten(changedElementIds));
          dispatch(trackEventsForOverwrittenLockedElements(params));
        }

        if (containsItemWideChanges(params)) {
          await dispatch(handleCurrentItemOverwritten(params));
        }

        return;
      }

      const isDefaultVariantEdited = params.variantId === DefaultVariantId;
      const editedContentItemType = getEditedContentItemType(state);
      const containsNonLocalizableElements = editedContentItemType?.contentElements.some(
        (el) => el.isNonLocalizable,
      );

      if (isDefaultVariantEdited && editedContentItemType && containsNonLocalizableElements) {
        if (
          !isPublishedWorkflowStepSelected(editedContentItemVariant.assignment) &&
          containsElementChanges(params)
        ) {
          const changedNonLocalizableElements = getChangedNonLocalizableElements(
            params.difference.changedElements,
            editedContentItemType.contentElements,
          );
          if (changedNonLocalizableElements.length > 0) {
            dispatch(currentItemElementsOverwritten(changedNonLocalizableElements));
          }
        }

        if (containsRelevantDefaultVariantDifferences(params.difference.assignmentDifferences)) {
          await dispatch(handleDefaultVariantOverwritten(params));
        }
      }
    }
  };
