import { DataUiCollection } from '../../../../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';
import { PublishingState } from '../../../../../../../data/constants/PublishingState.ts';
import {
  IPublishingState,
  PublishingStateOptions,
  convertPublishingStateToQueryParams,
  getPublishingStateFromIds,
} from '../../../../models/filter/IPublishingState.ts';
import { ListingFilterCategorySelector } from '../../components/ListingFilterCategorySelector.tsx';

type Props = {
  readonly onPublishingStatusSelectionChanged: (status: IPublishingState) => void;
  readonly selectedPublishingStateNodes: IPublishingState;
};

const getSelectedPublishingStatusesElements = (
  state: IPublishingState,
): ReadonlySet<PublishingState> => {
  const selectedPublishingStateNodeIds = new Set(convertPublishingStateToQueryParams(state));
  return new Set(
    PublishingStateOptions.filter((option) => selectedPublishingStateNodeIds.has(option.id)).map(
      (option) => option.id,
    ),
  );
};

export const PublishingStatusFilterSelector = (props: Props) => {
  return (
    <ListingFilterCategorySelector
      collection={DataUiCollection.PublishingStates}
      placeholder={label}
      ariaLabel={label}
      title="Publishing state"
      selectedOptionIds={getSelectedPublishingStatusesElements(props.selectedPublishingStateNodes)}
      options={PublishingStateOptions}
      onChange={(selectedIds) =>
        props.onPublishingStatusSelectionChanged(getPublishingStateFromIds(selectedIds))
      }
    />
  );
};

const label = 'Select a publishing state';
