import { trackUserEvent } from '../../../../../_shared/actions/thunks/trackUserEvent.ts';
import { repositoryCollection } from '../../../../../_shared/repositories/repositories.ts';
import { patchItemProperty } from '../../ContentItemEditing/actions/thunkContentItemEditingActions.ts';
import { createMoveItemToCollectionAction } from './moveItemToCollection.ts';

const { contentItemRepository } = repositoryCollection;

export const moveItemToCollection = createMoveItemToCollectionAction({
  contentItemRepository,
  patchItemProperty,
  trackUserEvent,
});
