import { InvariantException } from '@kontent-ai/errors';
import { ThunkPromise } from '../../../../../../@types/Dispatcher.type.ts';
import { TrackedEvent } from '../../../../../../_shared/constants/trackedEvent.ts';
import { DefaultVariantId } from '../../../../../../_shared/constants/variantIdValues.ts';
import {
  CannotPublishNonLocalizableElementsAndViewDefaultVariantError,
  SchedulePublishErrorMessage,
} from '../../../../../../_shared/features/ChangeWorkflowStepModal/constants/uiConstants.ts';
import { ContentItemId } from '../../../../../../_shared/models/ContentItemId.ts';
import { TrackUserEventAction } from '../../../../../../_shared/models/TrackUserEvent.type.ts';
import {
  ContentItemEditingChangeAction,
  ContentItemEditingEventOrigins,
} from '../../../../../../_shared/models/events/ContentItemEditingEventData.type.ts';
import { logError } from '../../../../../../_shared/utils/logError.ts';
import { hasCapabilityInLanguage } from '../../../../../../_shared/utils/permissions/capabilitiesInLanguageUtils.ts';
import { Capability } from '../../../../../../_shared/utils/permissions/capability.ts';
import { IContentItemRepository } from '../../../../../../repositories/interfaces/IContentItemRepository.type.ts';
import { IContentItemWithVariantServerModel } from '../../../../../../repositories/serverModels/INewContentItemServerModel.ts';
import {
  isElementsCompletenessError,
  isNonLocalizableElementPublishError,
} from '../../utils/errorUtils.ts';
import { IParsedItemVariant } from '../../utils/parseContentItem.ts';
import { prepareWorkflowStepTrackingDataWithFeatures } from '../../utils/prepareWorkflowStepTrackingData.ts';
import {
  contentItemEditingSidebarSectionCleanedUp,
  scheduledPublishingFailed,
  scheduledPublishingFinished,
  scheduledPublishingStarted,
} from '../contentItemEditingActions.ts';
import { ILoadRelatedContentItemElementsDataAction } from './loadRelatedContentItemElementsData.ts';

interface ISchedulePublishContentItemVariantDependencies {
  readonly trackUserEvent: TrackUserEventAction;
  readonly contentItemRepository: IContentItemRepository;
  readonly parseContentItemVariant: (
    contentItemWithVariant: IContentItemWithVariantServerModel,
  ) => IParsedItemVariant;
  readonly loadRelatedContentItemElementsData: ILoadRelatedContentItemElementsDataAction;
}

export const schedulePublishOfContentItemVariantActionCreator =
  (deps: ISchedulePublishContentItemVariantDependencies) =>
  (
    { itemId, variantId }: ContentItemId,
    actionOrigin: ContentItemEditingEventOrigins,
  ): ThunkPromise =>
  async (dispatch, getState): Promise<void> => {
    const {
      contentApp: {
        editorUi: { itemEditingModalDialog },
        listingUi: { filter, orderBy },
        changeWorkflowStepModalData: {
          scheduledToPublishAt,
          scheduledPublishDisplayTimeZone,
          scheduledToUnpublishAt,
          scheduledUnpublishDisplayTimeZone,
        },
      },
      data: {
        listingContentItems: { usedSearchMethod },
      },
    } = getState();

    if (!scheduledToPublishAt) {
      throw InvariantException(`${__filename}: scheduledToPublishAt is falsy`);
    }

    dispatch(scheduledPublishingStarted());
    try {
      const itemWithVariant = await deps.contentItemRepository.scheduleVariantPublish(
        itemId,
        variantId,
        scheduledToPublishAt,
        scheduledPublishDisplayTimeZone,
        scheduledToUnpublishAt,
        scheduledUnpublishDisplayTimeZone,
      );
      const variantData = deps.parseContentItemVariant(itemWithVariant);
      await dispatch(
        deps.loadRelatedContentItemElementsData(
          itemId,
          variantId,
          variantData.editedContentItemVariantElements,
          null,
        ),
      );
      const compiledVariant = {
        ...variantData,
        editedContentItemVariantElements: variantData.editedContentItemVariantElements,
      };
      dispatch(
        scheduledPublishingFinished({
          itemWithVariant,
          itemVariantData: compiledVariant,
          filter,
          orderBy,
          usedSearchMethod,
        }),
      );

      dispatch(
        deps.trackUserEvent(
          TrackedEvent.ContentItemEditing,
          prepareWorkflowStepTrackingDataWithFeatures(compiledVariant, {
            action: ContentItemEditingChangeAction.Schedule,
            origin: actionOrigin,
          }),
        ),
      );

      dispatch(contentItemEditingSidebarSectionCleanedUp());
    } catch (error) {
      const isNonLocalizableElementsError = isNonLocalizableElementPublishError(error);

      if (!isElementsCompletenessError(error) && !isNonLocalizableElementsError) {
        logError(`${__filename}: Error during scheduling publish of an item variant`, error);
      }

      if (!itemEditingModalDialog.properties) {
        throw InvariantException(
          `${__filename}: No modal dialog properties when trying to open dialog after failed schedule-publish.`,
        );
      }

      const errorMessage =
        isNonLocalizableElementsError &&
        !hasCapabilityInLanguage(getState(), Capability.ViewContent, DefaultVariantId)
          ? CannotPublishNonLocalizableElementsAndViewDefaultVariantError
          : SchedulePublishErrorMessage;

      dispatch(scheduledPublishingFailed(errorMessage, itemEditingModalDialog.properties));
    }
  };
