import { isXMLHttpRequest } from '@kontent-ai/errors';
import Immutable from 'immutable';
import { Action } from '../../../../@types/Action.type.ts';
import { GetState, ThunkPromise } from '../../../../@types/Dispatcher.type.ts';
import { trackUserEvent } from '../../../../_shared/actions/thunks/trackUserEvent.ts';
import { ItemColumnCode } from '../../../../_shared/constants/itemColumnCode.ts';
import {
  FindRightContentTrackedEvent,
  TrackedEvent,
} from '../../../../_shared/constants/trackedEvent.ts';
import { OrderBy } from '../../../../_shared/models/OrderBy.ts';
import { getSelectedLanguageId } from '../../../../_shared/selectors/getSelectedLanguageId.ts';
import { IStore } from '../../../../_shared/stores/IStore.type.ts';
import { trackError } from '../../../../_shared/utils/logError.ts';
import { RequestTokenFactory } from '../../../../_shared/utils/requestTokenUtils.ts';
import {
  ItemsRequestTrigger,
  convertFromPixelsToItems,
  getMaxItemsForLoading,
  shouldRequestNewItems,
  shouldUseLastContinuationToken,
} from '../../../../_shared/utils/scrollTableUtils.ts';
import { getContentItemScrollTableRowHeightPx } from '../../../../applications/contentInventory/content/constants/uiConstants.ts';
import { ScrollTableState } from '../../../../applications/contentInventory/content/models/ScrollTableState.type.ts';
import {
  IListingFilter,
  areListingFilterAndSearchEmpty,
} from '../../../../applications/contentInventory/content/models/filter/IListingFilter.ts';
import { TemporaryContentItemState } from '../../../../applications/contentInventory/content/models/temporaryContentItemState.ts';
import { BuildFilterForListingContentItemsParams } from '../../../../applications/contentInventory/content/utils/buildFilterWithContinuationForListingContentItems.ts';
import { FullTextSearchStatus } from '../../../../applications/contentInventory/shared/reducers/IContentInventoryStoreState.type.ts';
import { ITemporaryContentItem } from '../../../../applications/itemEditor/models/ITemporaryContentItem.type.ts';
import { ContentItemFilterWithContinuationServerModel } from '../../../../repositories/serverModels/ContentItemFilterWithContinuationServerModel.ts';
import {
  IListItemsResponseServerModel,
  SearchMethod,
} from '../../../../repositories/serverModels/IListingContentItemServerModel.type.ts';
import {
  Data_ListingContentItems_FullTextSearchBecameUnavailable,
  Data_ListingContentItems_Started,
  Data_ListingContentItems_Success,
} from '../../../constants/dataActionTypes.ts';
import {
  IListingContentItem,
  getListingContentItemFromJS,
} from '../../../models/listingContentItems/IListingContentItem.ts';
import { getListingItemsCount } from '../../../reducers/listingContentItems/selectors/getListingItemsCount.ts';
import { getCurrentProjectId } from '../../../reducers/user/selectors/userProjectsInfoSelectors.ts';
import { filterOutPublishedVersionsOfLoadedInventoryItems } from './filterOutPublishedVersionsOfLoadedInventoryItems.ts';

interface IDeps {
  readonly contentItemRepository: {
    readonly getListingItems: (
      variantId: Uuid,
      filter: ContentItemFilterWithContinuationServerModel,
      abortSignal?: AbortSignal,
    ) => Promise<IListItemsResponseServerModel>;
  };
  readonly buildFilterForListingContentItems: (
    params: BuildFilterForListingContentItemsParams,
  ) => Readonly<ContentItemFilterWithContinuationServerModel>;
  readonly requestTokenFactory: RequestTokenFactory<
    (requestTrigger: ItemsRequestTrigger) => Action
  >;
}

export interface ILoadListingItemsParams {
  readonly doesCurrentStateStillMatchArguments: DoesCurrentStateStillMatchArguments;
  readonly filter: IListingFilter;
  readonly getScrollTableState: (getFreshState: GetState) => ScrollTableState;
  readonly itemHeightPx: number;
  readonly languageId: Uuid;
  readonly orderBy: OrderBy<ItemColumnCode>;
  readonly projectId: Uuid;
  readonly scrollPositionChanged: boolean;
}

export type DoesCurrentStateStillMatchArguments = (
  currentState: { projectId: Uuid; languageId: Uuid | null },
  args: { projectId: Uuid; languageId: Uuid },
) => boolean;

export const createTokenizedListingItemsLoadingStarted =
  (raceConditionToken: Uuid) => (requestTrigger: ItemsRequestTrigger) =>
    ({
      type: Data_ListingContentItems_Started,
      payload: {
        raceConditionToken,
        requestTrigger,
      },
    }) as const;

type ListingItemsLoadedActionPayload = {
  readonly contentItems: ReadonlyArray<IListingContentItem>;
  readonly continuationToken: string | null;
  readonly requestTrigger: ItemsRequestTrigger;
  readonly searchMethod: SearchMethod;
};

export const listingItemsLoaded = (payload: ListingItemsLoadedActionPayload) =>
  ({
    type: Data_ListingContentItems_Success,
    payload,
  }) as const;

interface IFullTextSearchBecameUnavailablePayload {
  readonly shouldResetLoadedItems: boolean;
}

export const fullTextSearchBecameUnavailable = (payload: IFullTextSearchBecameUnavailablePayload) =>
  ({
    type: Data_ListingContentItems_FullTextSearchBecameUnavailable,
    payload,
  }) as const;

export type LoadListingContentItemsActionsType =
  | ReturnType<
      typeof listingItemsLoaded | ReturnType<typeof createTokenizedListingItemsLoadingStarted>
    >
  | ReturnType<typeof fullTextSearchBecameUnavailable>;

const filterOutRemovedTemporaryContentItem =
  (temporaryItem: ITemporaryContentItem | null) =>
  (items: ReadonlyArray<IListingContentItem>): ReadonlyArray<IListingContentItem> => {
    if (temporaryItem && temporaryItem.itemState === TemporaryContentItemState.Loaded) {
      return items.filter((item) => item.item.id !== temporaryItem.itemId);
    }

    return items;
  };

export const getFreshTableState = (state: IStore) =>
  convertFromPixelsToItems({
    stats: state.contentApp.listingUi.contentItemListingScrollTableState,
    itemHeight: getContentItemScrollTableRowHeightPx(),
    totalNumberOfItems: null,
  });

const isRouteStillCorrect = (params: ILoadListingItemsParams, getState: GetState): boolean => {
  const state = getState();

  return params.doesCurrentStateStillMatchArguments(
    {
      languageId: getSelectedLanguageId(state),
      projectId: getCurrentProjectId(state),
    },
    {
      languageId: params.languageId,
      projectId: params.projectId,
    },
  );
};

/**
 * Reusable action creator that should not get any specific data from store,
 * as it does not know which scroll table with listingContent items is to be rendered.
 */
export const createLoadListingContentItemsAction =
  (deps: IDeps) =>
  (params: ILoadListingItemsParams, abortSignal?: AbortSignal): ThunkPromise =>
  async (dispatch, getState) => {
    const state = getState();
    const {
      contentApp: {
        editorUi: { temporaryItem },
        listingUi: { filter },
      },
    } = state;

    let requestTrigger = params.scrollPositionChanged
      ? ItemsRequestTrigger.UserScroll
      : ItemsRequestTrigger.Other;

    while (
      shouldRequestNewItems({
        continuationToken: getState().data.listingContentItems.nextContinuationToken,
        loadingStatus: getState().data.listingContentItems.loadingStatus,
        numberOfLoadedItems: getListingItemsCount(getState().data.listingContentItems),
        requestTrigger,
        tableState: getFreshTableState(getState()),
      })
    ) {
      const fullTextSearchStatus = getState().contentInventory.fullTextSearchStatus;
      const serverFilter = deps.buildFilterForListingContentItems({
        continuationToken: shouldUseLastContinuationToken(requestTrigger)
          ? getState().data.listingContentItems.nextContinuationToken
          : null,
        filter: params.filter,
        includePublishedVersions: !areListingFilterAndSearchEmpty(filter),
        maxItemsCount: getMaxItemsForLoading(getFreshTableState(getState())),
        orderBy: params.orderBy,
        useBackUpSearchMethod: fullTextSearchStatus === FullTextSearchStatus.Unavailable,
      });

      if (!isRouteStillCorrect(params, getState)) {
        return;
      }

      const { tokenizedActionCreator: listingItemsLoadingStarted, isCurrentTokenValid } =
        deps.requestTokenFactory(getState);
      dispatch(listingItemsLoadingStarted(requestTrigger));

      if (requestTrigger === ItemsRequestTrigger.UserScroll) {
        dispatch(
          trackUserEvent(TrackedEvent.FindRightContent, {
            name: FindRightContentTrackedEvent.LoadedListingItemsFromServer,
          }),
        );
      }

      let items: IListItemsResponseServerModel | null = null;

      try {
        items = await deps.contentItemRepository.getListingItems(
          params.languageId,
          serverFilter,
          abortSignal,
        );
      } catch (error) {
        if (isXMLHttpRequest(error) && error.statusText === 'Invalid continuation token') {
          trackError(`Invalid continuation token: ${serverFilter.continuationToken}`); // KCL-10965
        }
        throw error;
      }

      if (isCurrentTokenValid() && isRouteStillCorrect(params, getState)) {
        const corrections = [
          filterOutRemovedTemporaryContentItem(temporaryItem),
          filterOutPublishedVersionsOfLoadedInventoryItems({
            currentAllIds: Immutable.OrderedSet<Uuid>(
              getState().data.listingContentItems.allIds ?? [],
            ),
            currentByIds: getState().data.listingContentItems.byId,
            requestTrigger,
          }),
        ];

        const correctedContentItems = corrections.reduce(
          (result, correctItems) => correctItems(result),
          items.data.map(getListingContentItemFromJS),
        );

        if (
          fullTextSearchStatus !== FullTextSearchStatus.Unavailable &&
          items.searchMethod === SearchMethod.StandardAsBackUp
        ) {
          dispatch(fullTextSearchBecameUnavailable({ shouldResetLoadedItems: true }));
        }

        dispatch(
          listingItemsLoaded({
            contentItems: correctedContentItems,
            continuationToken: items.continuationToken,
            requestTrigger,
            searchMethod: items.searchMethod,
          }),
        );
      }

      requestTrigger = ItemsRequestTrigger.TooFewItemsLoaded;
    }
  };
