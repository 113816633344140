import { RestoreFromArchivedStep } from '../../../../../../../_shared/constants/itemActions.ts';
import { useDispatch } from '../../../../../../../_shared/hooks/useDispatch.ts';
import { useSelector } from '../../../../../../../_shared/hooks/useSelector.ts';
import { ContentItemEditingEventOrigins } from '../../../../../../../_shared/models/events/ContentItemEditingEventData.type.ts';
import { DataUiWorkflowAction } from '../../../../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';
import { getNameOfTheFirstWorkflowStepCurrentRoleCanWorkWith } from '../../../../../selectors/getNameOfTheFirstWorkflowStepCurrentRoleCanWorkWith.ts';
import { openRestoreItemFromArchivedStepModal } from '../../../../ChangeWorkflowStep/actions/thunks/changeWorkflowStepModalActions.ts';
import { WorkflowStepActionButton } from '../../../components/details/WorkflowStepActionButton.tsx';

export const RestoreFromArchivedStepButton = () => {
  const dispatch = useDispatch();
  const firstWorkflowStepCurrentRoleCanWorkWithName = useSelector(
    getNameOfTheFirstWorkflowStepCurrentRoleCanWorkWith,
  );
  return (
    <WorkflowStepActionButton
      buttonStyle="primary"
      dataUiAction={DataUiWorkflowAction.RestoreFromArchivedStep}
      label={RestoreFromArchivedStep(firstWorkflowStepCurrentRoleCanWorkWithName)}
      onClick={() =>
        dispatch(openRestoreItemFromArchivedStepModal(ContentItemEditingEventOrigins.ItemDetails))
      }
    />
  );
};
