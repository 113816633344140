import { useSelector } from '../../../../../../_shared/hooks/useSelector.ts';
import { PreviewApiDataStatus } from '../../../../../webSpotlight/models/PreviewApiDataStatus.ts';
import { getPreviewApiDataStatus } from '../../../../../webSpotlight/selectors/webSpotlightSelectors.ts';
import { PreviewRefreshStatus as PreviewRefreshStatusComponent } from '../../components/contentItemPreview/PreviewRefreshStatus.tsx';

export const PreviewRefreshStatus = () => {
  const isLoading = useSelector(
    (s) => getPreviewApiDataStatus(s) === PreviewApiDataStatus.Outdated,
  );

  return isLoading && <PreviewRefreshStatusComponent />;
};
