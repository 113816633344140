import { OnboardingPopover } from '@kontent-ai/component-library/OnboardingBubble';
import { isBefore } from 'date-fns';
import { ComponentProps } from 'react';
import { upsertUserProperty } from '../../../../../../_shared/actions/thunkSharedActions.ts';
import { useDispatch } from '../../../../../../_shared/hooks/useDispatch.ts';
import { useSelector } from '../../../../../../_shared/hooks/useSelector.ts';
import { DismissedSideBySidePreviewOnboardingKey } from '../../../../../../_shared/models/UserPropertiesServerKeys.ts';

type Props = Readonly<{
  renderTarget: ComponentProps<typeof OnboardingPopover>['renderTarget'];
}>;

export const SideBySidePreviewOnboarding = ({ renderTarget }: Props) => {
  const dispatch = useDispatch();

  const shouldShowOnboarding = useSelector(
    (s) =>
      !s.sharedApp.userProperties.dismissedSideBySidePreviewOnboarding &&
      isBefore(s.data.user.info.createdAt, new Date('2025-02-13')),
  );

  const onDismiss = () => {
    dispatch(upsertUserProperty(DismissedSideBySidePreviewOnboardingKey, 'true'));
  };

  return (
    <OnboardingPopover
      headlineText="Preview your content while editing instantly"
      isVisible={shouldShowOnboarding}
      onDismiss={onDismiss}
      placement="bottom"
      renderTarget={renderTarget}
      showPrimaryButton
    >
      <p>
        Edit your content and see live changes before you publish—no more previewing separately.
        Turn on the toggle to see your content in action!
      </p>
    </OnboardingPopover>
  );
};
