import { ContentItemId } from '../ContentItemId.ts';

interface LinkEventData extends ContentItemId {
  readonly linkId: string;
}

export interface LinkVisitedEventData extends LinkEventData {
  readonly 'journey-stage': string;
  readonly projectId: string;
}

export interface ContentItemLinkCopiedEventData extends LinkEventData {
  readonly fieldName: string;
}

export type EditorStatisticsEventData = {
  // We are limited with 5 metadata fields per intercom event
  // for the sake of future extensibility the counters are serialized to JSON which we can parse at SQL level of DW
  readonly counters: string;
  readonly project: Uuid;
  readonly name: string;
};

export type ContentEntryNameOrElementsChangedEventData = {
  readonly 'autosaves-count': number;
  readonly savedElementIds: string;
};

export type ContentEntryOverwrittenEventData = {
  readonly changeByManageApi: boolean;
  readonly changeBySelf: boolean;
  readonly changeReason: string;
  readonly differenceKeys: ReadonlyArray<string>;
};

export enum SimultaneousEditingSaveConflictReason {
  ElementLocked = 'element-locked',
  ElementOutdated = 'element-outdated',
}

export type ContentEntryElementEventData = {
  readonly 'element-id': Uuid;
  readonly 'element-type': string;
};

export interface ContentEntryElementSaveConflictEventData extends ContentEntryElementEventData {
  readonly reason: SimultaneousEditingSaveConflictReason;
}

export interface ContentEntryElementRefreshedEventData extends ContentEntryElementEventData {
  readonly 'changed-by-self': boolean;
}

export interface ContentEntryLockedElementOverwrittenEventData
  extends ContentEntryElementEventData {
  readonly 'changed-by-manage-api': boolean;
  readonly 'changed-by-self': boolean;
}
