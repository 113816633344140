import { Icons } from '@kontent-ai/component-library/Icons';
import {
  ChangeUnpublishingOptions,
  Unpublish,
} from '../../../../../../../_shared/constants/itemActions.ts';
import { useDispatch } from '../../../../../../../_shared/hooks/useDispatch.ts';
import { useSelector } from '../../../../../../../_shared/hooks/useSelector.ts';
import { ContentItemEditingEventOrigins } from '../../../../../../../_shared/models/events/ContentItemEditingEventData.type.ts';
import { DataUiWorkflowAction } from '../../../../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';
import { openUnpublishAndArchiveModal } from '../../../../ChangeWorkflowStep/actions/thunks/changeWorkflowStepModalActions.ts';
import { WorkflowStepActionButton } from '../../../components/details/WorkflowStepActionButton.tsx';

export const UnpublishButton = () => {
  const dispatch = useDispatch();
  const editedContentItemVariant = useSelector(
    (state) => state.contentApp.editedContentItemVariant,
  );
  if (!editedContentItemVariant) {
    return null;
  }
  const { scheduledToUnpublishAt } = editedContentItemVariant.assignment;
  if (scheduledToUnpublishAt) {
    return null;
  }

  return (
    <WorkflowStepActionButton
      buttonStyle="tertiary"
      dataUiAction={DataUiWorkflowAction.Unpublish}
      icon={Icons.Box}
      label={scheduledToUnpublishAt ? ChangeUnpublishingOptions : Unpublish}
      onClick={() =>
        dispatch(openUnpublishAndArchiveModal(ContentItemEditingEventOrigins.ItemDetails))
      }
    />
  );
};
