import { Button } from '@kontent-ai/component-library/Button';
import { Icons } from '@kontent-ai/component-library/Icons';
import { Menu } from '@kontent-ai/component-library/Menu';
import { RefObject } from 'react';
import {
  DataUiAction,
  getDataUiActionAttribute,
} from '../../../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';
import { RefreshPreviewAction } from '../../containers/contentItemPreview/previewActions/RefreshPreviewAction.tsx';
import { ToggleAutoRefreshPreview } from '../../containers/contentItemPreview/previewActions/ToggleAutoRefreshPreview.tsx';
import { ToggleEditableElements } from '../../containers/contentItemPreview/previewActions/ToggleEditableElements.tsx';

type Props = Readonly<{
  isWebSpotlightIntegrationEnabled: boolean;
}>;

export const PreviewActionsMenu = ({ isWebSpotlightIntegrationEnabled }: Props) => {
  return (
    <Menu>
      <Menu.Trigger>
        {(ref, triggerProps, isOpen) => (
          <Button
            activated={isOpen}
            buttonStyle="secondary"
            ref={ref as RefObject<HTMLButtonElement>}
            {...triggerProps}
            {...getDataUiActionAttribute(DataUiAction.OpenPreviewMoreActions)}
          >
            <Button.Icon icon={Icons.Ellipsis} />
            <Button.Label>Actions</Button.Label>
          </Button>
        )}
      </Menu.Trigger>
      <Menu.List>
        {isWebSpotlightIntegrationEnabled && <ToggleEditableElements />}
        <ToggleAutoRefreshPreview />
        <RefreshPreviewAction />
      </Menu.List>
    </Menu>
  );
};
