import { forwardRef, useEffect } from 'react';
import { IAnimatedModalDialogProps } from '../../../../../_shared/components/ModalDialog/IAnimatedModalDialogProps.type.ts';
import { useDispatch } from '../../../../../_shared/hooks/useDispatch.ts';
import { useSelector } from '../../../../../_shared/hooks/useSelector.ts';
import { useThunkPromise } from '../../../../../_shared/hooks/useThunkPromise.ts';
import { LoadingStatus } from '../../../../../_shared/models/LoadingStatusEnum.ts';
import { getEditedContentItem } from '../../../../../_shared/selectors/getEditedContentItem.ts';
import { getSelectedLanguageId } from '../../../../../_shared/selectors/getSelectedLanguageId.ts';
import { HotjarTag, triggerRecording } from '../../../../../_shared/utils/hotjarUtils.ts';
import { itemEditingModalDismissed } from '../../../actions/contentActions.ts';
import { initCascadeModal } from '../actions/thunkCascadeModalActions.ts';
import {
  CascadeModal as CascadeModalComponent,
  CascadeModalOwnProps,
} from '../components/CascadeModal.tsx';
import { getFirstLevelChildrenItemIds } from '../selectors/getFirstLevelChildrenItemIds.ts';
import { sortChildContentItemItemIds } from '../utils/sortChildContentItemItemIds.ts';

export const CascadeModal = forwardRef<
  HTMLDivElement,
  IAnimatedModalDialogProps & CascadeModalOwnProps
>((props, ref) => {
  const isModalContentLoaded = useSelector(
    (s) => s.contentApp.editorUi.cascadePublish.loadingStatus === LoadingStatus.Loaded,
  );

  const firstLevelChildIds = useSelector((s) => {
    const selectedLanguageId = getSelectedLanguageId(s);
    const editedItemId = getEditedContentItem(s).id;

    return !selectedLanguageId || !editedItemId
      ? null
      : sortChildContentItemItemIds(
          getFirstLevelChildrenItemIds(s),
          s.data.listingContentItems.byId,
          s.data.listingContentItems.defaultById,
          selectedLanguageId,
          editedItemId,
        );
  });

  useThunkPromise(initCascadeModal);

  const dispatch = useDispatch();

  useEffect(() => {
    triggerRecording(HotjarTag.CascadePublish);
  }, []);

  if (!firstLevelChildIds) {
    return null;
  }

  return (
    <CascadeModalComponent
      isModalContentLoaded={isModalContentLoaded}
      firstLevelChildIds={firstLevelChildIds}
      onClose={() => dispatch(itemEditingModalDismissed())}
      {...props}
      ref={ref}
    />
  );
});
