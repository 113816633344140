import { getUrlFactory } from '../../../../../_shared/utils/urlFactory.ts';

export const isDemoSamplePreviewUrl = (previewUrlPattern: string | undefined): boolean => {
  if (!previewUrlPattern) {
    return false;
  }

  const demoSampleAppDomain = getUrlFactory().getDeployedSampleAppDomain();
  return previewUrlPattern.includes(demoSampleAppDomain);
};
