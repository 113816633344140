import { Box } from '@kontent-ai/component-library/Box';
import { Spacing } from '@kontent-ai/component-library/tokens';
import { Link } from 'react-router-dom';
import { Warning } from '../../../../_shared/components/infos/Warning.tsx';
import { FormGroup } from '../../../../_shared/components/input/FormGroup.tsx';
import { ValidatedInput } from '../../../../_shared/components/input/ValidatedInput.tsx';
import { SubscriptionsRoute } from '../../../../_shared/constants/routePaths.ts';
import { ValidationConstants } from '../../../../_shared/constants/validationConstants.ts';
import { HookFormProps } from '../../../../_shared/types/hookFormProps.type.ts';
import { DataUiInput } from '../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';
import { buildPath } from '../../../../_shared/utils/routing/routeTransitionUtils.ts';
import { IProjectDetails } from '../../../../data/models/projects/ProjectDetails.ts';
import { ISubscription } from '../../../../data/models/subscriptions/Subscription.ts';
import {
  SampleProjectLocationsLimited,
  SampleProjectLocationsUnavailable,
} from '../../constants/errorMessages.ts';
import { InitialProjectType } from '../../constants/initialProjectType.ts';
import { Step } from '../../containers/projects/NewProjectModal.tsx';
import { IBaseProject } from '../../models/IBaseProject.type.ts';
import { ICopyProjectErrors } from '../../models/ICopyProjectValidationData.type.ts';
import { INewProjectFormShape } from '../../models/INewProjectFormShape.type.ts';
import { CopyProjectDocumentationLinks } from './CopyProjectDocumentationLinks.tsx';
import { CopyProjectDocumentationLinksExtended } from './CopyProjectDocumentationLinksExtended.tsx';
import { IncludeContentSelector } from './IncludeContentSelector.tsx';
import { ProjectLocationSelectorWrapper } from './ProjectLocationSelectorWrapper.tsx';
import { ProjectTemplateSelectorWrapper } from './ProjectTemplateSelectorWrapper.tsx';
import { SubscriptionSelectorWrapper } from './SubscriptionSelectorWrapper.tsx';
import { CloneProjectErrors } from './copyProjectErrors/CloneProjectErrors.tsx';

type Props = Readonly<{
  readonly step: Step;
  readonly activeSubscriptions: ReadonlyArray<ISubscription>;
  readonly availableProjectTemplates: ReadonlyArray<IProjectDetails>;
  readonly destinationSubscription: ISubscription;
  readonly canChangeSubscription: boolean;
  readonly formProps: HookFormProps<INewProjectFormShape>;
  readonly isProjectTemplateSelected: boolean;
  readonly onSubmit: () => void;
  readonly creatingProject: boolean;
  readonly validationErrors: ICopyProjectErrors | null;
}>;

export const ProjectDetailsForm = (props: Props) => {
  if (props.step.type !== 'projectDetails') {
    return null;
  }

  return (
    <div css="min-width: 500px">
      <Form
        initialProjectType={props.step.projectType}
        activeSubscriptions={props.activeSubscriptions}
        availableProjectTemplates={props.availableProjectTemplates}
        destinationSubscriptionId={props.destinationSubscription.subscriptionId}
        disableSubscriptionSelector={!props.canChangeSubscription}
        formProps={props.formProps}
        isProjectTemplateSelected={
          props.isProjectTemplateSelected || props.step.projectType === InitialProjectType.Copy
        }
        onSubmit={props.onSubmit}
      />
      {!props.creatingProject && props.validationErrors && (
        <CloneProjectErrors
          errorsConfig={props.validationErrors}
          isCreatingEmptyProject={!props.isProjectTemplateSelected}
          subscriptionId={props.destinationSubscription.subscriptionId}
          subscriptionName={props.destinationSubscription.subscriptionName}
        />
      )}
      {props.activeSubscriptions.length === 0 && <ProjectLocationsUnavailableWarning />}
      {[InitialProjectType.Kickstart, InitialProjectType.MultiSite].includes(
        props.step.projectType,
      ) &&
        props.activeSubscriptions.length > 0 && <SampleProjectLocationsLimitedWarning />}
    </div>
  );
};

const SampleProjectLocationsLimitedWarning = () => (
  <Box paddingTop={Spacing.XL}>
    <Warning subtitle="Limited choice of subscriptions">
      <p>{SampleProjectLocationsLimited}</p>
    </Warning>
  </Box>
);

const ProjectLocationsUnavailableWarning = () => (
  <Box paddingTop={Spacing.XL}>
    <Warning subtitle="No subscriptions available">
      <p>{SampleProjectLocationsUnavailable}</p>
      <p>
        <Link to={buildPath(SubscriptionsRoute, {})}>Create a new subscription</Link>
        &nbsp;for the sample project.
      </p>
    </Warning>
  </Box>
);

type FormProps = {
  readonly activeSubscriptions: ReadonlyArray<ISubscription>;
  readonly availableProjectTemplates: ReadonlyArray<IBaseProject>;
  readonly destinationSubscriptionId: Uuid;
  readonly disableSubscriptionSelector: boolean;
  readonly formProps: HookFormProps<INewProjectFormShape>;
  readonly isProjectTemplateSelected: boolean;
  readonly onSubmit: (() => void) | undefined;
  readonly initialProjectType: InitialProjectType;
};

const Form = (props: FormProps) => {
  return (
    <form className="form create-project-form" onSubmit={props.onSubmit}>
      <FormGroup>
        <ValidatedInput<INewProjectFormShape>
          name="projectName"
          label="Project name"
          maxLength={ValidationConstants.ProjectNameMaxLength}
          dataUiInputName={DataUiInput.ProjectName}
          autoFocus
          inputSuppressedInHotjar
          formProps={props.formProps}
        />
      </FormGroup>

      <FormGroup>
        <SubscriptionSelectorWrapper<INewProjectFormShape>
          activeSubscriptions={props.activeSubscriptions}
          disabled={props.disableSubscriptionSelector}
          formProps={props.formProps}
          name="subscriptionId"
        />
      </FormGroup>

      {[InitialProjectType.Blank, InitialProjectType.Copy].includes(props.initialProjectType) && (
        <FormGroup>
          <ProjectLocationSelectorWrapper<INewProjectFormShape>
            destinationSubscriptionId={props.destinationSubscriptionId}
            formProps={props.formProps}
            name="projectLocationId"
          />
        </FormGroup>
      )}

      {props.initialProjectType === InitialProjectType.Blank && (
        <FormGroup>
          <ProjectTemplateSelectorWrapper
            availableProjectTemplates={props.availableProjectTemplates}
            formProps={props.formProps}
          />
        </FormGroup>
      )}

      {[InitialProjectType.Blank, InitialProjectType.Copy].includes(props.initialProjectType) && (
        <FormGroup isCompact>
          {props.isProjectTemplateSelected ? (
            <>
              <IncludeContentSelector<INewProjectFormShape>
                formProps={props.formProps}
                name="includeContent"
              />
              <CopyProjectDocumentationLinksExtended />
            </>
          ) : (
            <CopyProjectDocumentationLinks />
          )}
        </FormGroup>
      )}
    </form>
  );
};
