import React from 'react';
import { useController } from 'react-hook-form';
import { HookFormProps } from '../../../../_shared/types/hookFormProps.type.ts';
import { LabelFor } from '../../../../_shared/uiComponents/LabelFor/LabelFor.tsx';
import { ProjectTemplateSelector } from '../../containers/projects/ProjectTemplateSelector.tsx';
import { IBaseProject } from '../../models/IBaseProject.type.ts';
import { INewProjectFormShape } from '../../models/INewProjectFormShape.type.ts';

type Props = {
  readonly availableProjectTemplates: ReadonlyArray<IBaseProject>;
  readonly formProps: HookFormProps<INewProjectFormShape>;
};

export const ProjectTemplateSelectorWrapper: React.FC<Props> = ({
  availableProjectTemplates,
  formProps,
}) => {
  const { field } = useController({
    control: formProps.control,
    name: 'projectTemplateId',
  });

  return (
    <LabelFor
      target={(id: Uuid) => (
        <ProjectTemplateSelector
          ref={field.ref}
          dropdownId={id}
          onSelectionChanged={field.onChange}
          selectedProjectTemplateId={field.value}
          disabled={availableProjectTemplates.length === 0}
          availableProjectTemplates={availableProjectTemplates}
        />
      )}
    >
      Create a clone from
    </LabelFor>
  );
};

ProjectTemplateSelectorWrapper.displayName = 'ProjectTemplateSelectorWrapper';
