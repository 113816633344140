import Immutable from 'immutable';
import { ContextualHelpType } from '../../data/models/contextualHelp/ContextualHelpTypeEnum.ts';
import { OnboardingNotification } from '../../data/models/user/OnboardingNotification.ts';
import { UserFeedbackTimestamps } from '../../data/models/user/UserFeedbackTimestamps.type.ts';
import { AiFeatureSet } from '../constants/AiFeatureSet.ts';
import { ProjectOrderBy as ProjectOrderByEnum } from '../constants/projectOrderBy.ts';
import { ExportedTypes } from '../types/ExportedTypes.type.ts';
import { BusinessRole } from './BusinessRole.ts';
import { BusinessType } from './BusinessType.ts';
import {
  AiFeatureSetServerKey,
  AiFeedbackTimestamps,
  AreIntercomInteractionsDisabled,
  BusinessRoleServerKey,
  BusinessTypeServerKey,
  CommentThreadsOnRemovedContentOnboardingShownAtServerKey,
  CompanyNameServerKey,
  ContentModelingFeedbackServerKey,
  ContextualHelpSettingsServerKey,
  DiscoverCommentsDisplayedAtServerKey,
  DismissedMissionControlOnboardingServerKey,
  DismissedNewWebhooksOnboardingServerKey,
  DismissedSemanticSearchOnboardingServerKey,
  DismissedSideBySidePreviewOnboardingKey,
  EmailNotificationAssignmentDisabledServerKey,
  EmailNotificationCommentsDisabledServerKey,
  EmailNotificationMentionsDisabledServerKey,
  EmailNotificationReportsWithZeroPricesDisabledServerKey,
  EmailNotificationTasksDisabledServerKey,
  EmailNotificationWebhookNotificationsServerKey,
  FilledBusinessRoleServerKey,
  HasCreatedSeventhOrMoreWorkflowStep,
  IntercomMoveInfoBubbleDismissedAt,
  IsAzureMarketplaceUser,
  IsSentryBugReportWidgetEnabledServerKey,
  LastTrackedAvatarTypeServerKey,
  LongProcessingChangesWarningDismissedServerKey,
  OnboardingNotificationsSettingsServerKey,
  PhoneNumber,
  PossiblyIncorrectPlacementWarningDismissedServerKey,
  PrefersClosedEditingSidebar,
  ProductUpdateOpenedAtServerKey,
  ProjectOrderBy,
  SignupReasonServerKey,
  SubscribedToNewsletterAtServerKey,
  UpsellMultipleWorkflowsAfterCreatingStepDismissedServerKey,
  UpsellMultipleWorkflowsInitialDismissedServerKey,
  UserFeedbackServerKey,
  WebSpotlightTreeCollapsedServerKey,
} from './UserPropertiesServerKeys.ts';
import { AvatarType } from './events/AvatarTypeEventData.type.ts';

/**
 * Ensure that the default value of added property won't affect UI tests or Screen Comparison.
 * You can set the right value in KenticoCloud.UI.Tests repository at TestDataFactory\Provider\UserProvider.cs.
 * If you are removing the property from here, make sure code managing the property in UI tests is also removed.
 */
export type UserPropertiesModel = {
  readonly aiFeatureSet: ReadonlyArray<AiFeatureSet>;
  readonly aiFeedbackTimestamps: UserFeedbackTimestamps;
  readonly areIntercomInteractionsDisabled: boolean;
  readonly businessRole: BusinessRole;
  readonly businessType: BusinessType;
  readonly commentThreadsOnRemovedContentOnboardingShownAt: string;
  readonly companyName: string;
  readonly contentModelingFeedback: UserFeedbackTimestamps;
  readonly contextualHelpSettings: Immutable.Map<ContextualHelpType, boolean>;
  readonly discoverCommentsDialogDisplayedAt: string;
  readonly dismissedCollectionsPermissionsRoleBuilderPopover: boolean;
  readonly dismissedMissionControlOnboarding: boolean;
  // The new Popover for the Collections App that is positioned on the Project Settings menu item
  readonly dismissedNewCollectionsAppInSettingsOnboarding: boolean;
  readonly dismissedNewWebhooksOnboarding: boolean;
  // The old Popover for the Collections App that was positioned directly on the Collections app
  readonly dismissedOldCollectionsAppInSettingsOnboarding: boolean;
  readonly dismissedPossiblyIncorrectPlacementWarning: boolean;
  readonly dismissedProjectSettingsCollectionsBadge: boolean;
  readonly dismissedSemanticSearchOnboarding: boolean;
  readonly dismissedSideBySidePreviewOnboarding: boolean;
  readonly emailNotificationAssignmentDisabled: string; // rework all email preferences into Immutable.Map<EmailPreferenceType, boolean>
  readonly emailNotificationCommentsDisabled: string;
  readonly emailNotificationMentionsDisabled: string;
  readonly emailNotificationReportsWithZeroPriceDisabled: string;
  readonly emailNotificationTasksDisabled: string;
  readonly emailNotificationWebhookNotificationsDisabled: string;
  readonly emailNotificationsBeingChanged: boolean;
  readonly emailNotificationsBeingSaved: boolean;
  readonly filledBusinessRole: string;
  readonly hasCreatedSeventhOrMoreWorkflowStep: boolean;
  readonly intercomMoveInfoBubbleDismissedAt?: string;
  readonly isAzureMarketplaceUser: boolean;
  readonly isSentryBugReportWidgetEnabled: boolean;
  readonly lastTrackedAvatarType: AvatarType | null;
  readonly longProcessingChangesWarningDismissed: boolean;
  readonly onboardingNotificationsSettings: Immutable.Map<OnboardingNotification, boolean>;
  readonly phoneNumber: string;
  readonly prefersClosedEditingSidebar: boolean;
  readonly productUpdateOpenedAt: string;
  readonly projectOrderBy: ProjectOrderByEnum;
  readonly signupReason: string;
  readonly subscribedToNewsletterAt: string;
  readonly upsellMultipleWorkflowsAfterCreatingStepDismissed: boolean;
  readonly upsellMultipleWorkflowsInitialDismissed: boolean;
  readonly userFeedback: UserFeedbackTimestamps;
  readonly webSpotlightTreeCollapsed: boolean;
};

export type UserPropertyServerKey = ExportedTypes<typeof import('./UserPropertiesServerKeys')>;

export const UserPropertiesStateKeysByServerKeys: Record<
  UserPropertyServerKey,
  keyof UserPropertiesModel
> = {
  [AiFeatureSetServerKey]: 'aiFeatureSet',
  [AiFeedbackTimestamps]: 'aiFeedbackTimestamps',
  [BusinessRoleServerKey]: 'businessRole',
  [BusinessTypeServerKey]: 'businessType',
  [CommentThreadsOnRemovedContentOnboardingShownAtServerKey]:
    'commentThreadsOnRemovedContentOnboardingShownAt',
  [CompanyNameServerKey]: 'companyName',
  [ContentModelingFeedbackServerKey]: 'contentModelingFeedback',
  [ContextualHelpSettingsServerKey]: 'contextualHelpSettings',
  [DiscoverCommentsDisplayedAtServerKey]: 'discoverCommentsDialogDisplayedAt',
  [DismissedMissionControlOnboardingServerKey]: 'dismissedMissionControlOnboarding',
  [DismissedNewWebhooksOnboardingServerKey]: 'dismissedNewWebhooksOnboarding',
  [DismissedSemanticSearchOnboardingServerKey]: 'dismissedSemanticSearchOnboarding',
  [DismissedSideBySidePreviewOnboardingKey]: 'dismissedSideBySidePreviewOnboarding',
  [EmailNotificationAssignmentDisabledServerKey]: 'emailNotificationAssignmentDisabled',
  [EmailNotificationCommentsDisabledServerKey]: 'emailNotificationCommentsDisabled',
  [EmailNotificationMentionsDisabledServerKey]: 'emailNotificationMentionsDisabled',
  [EmailNotificationReportsWithZeroPricesDisabledServerKey]:
    'emailNotificationReportsWithZeroPriceDisabled',
  [EmailNotificationTasksDisabledServerKey]: 'emailNotificationTasksDisabled',
  [EmailNotificationWebhookNotificationsServerKey]: 'emailNotificationWebhookNotificationsDisabled',
  [FilledBusinessRoleServerKey]: 'filledBusinessRole',
  [HasCreatedSeventhOrMoreWorkflowStep]: 'hasCreatedSeventhOrMoreWorkflowStep',
  [IntercomMoveInfoBubbleDismissedAt]: 'intercomMoveInfoBubbleDismissedAt',
  [IsAzureMarketplaceUser]: 'isAzureMarketplaceUser',
  [AreIntercomInteractionsDisabled]: 'areIntercomInteractionsDisabled',
  [IsSentryBugReportWidgetEnabledServerKey]: 'isSentryBugReportWidgetEnabled',
  [LastTrackedAvatarTypeServerKey]: 'lastTrackedAvatarType',
  [LongProcessingChangesWarningDismissedServerKey]: 'longProcessingChangesWarningDismissed',
  [OnboardingNotificationsSettingsServerKey]: 'onboardingNotificationsSettings',
  [PhoneNumber]: 'phoneNumber',
  [PossiblyIncorrectPlacementWarningDismissedServerKey]:
    'dismissedPossiblyIncorrectPlacementWarning',
  [PrefersClosedEditingSidebar]: 'prefersClosedEditingSidebar',
  [ProductUpdateOpenedAtServerKey]: 'productUpdateOpenedAt',
  [ProjectOrderBy]: 'projectOrderBy',
  [SignupReasonServerKey]: 'signupReason',
  [SubscribedToNewsletterAtServerKey]: 'subscribedToNewsletterAt',
  [UpsellMultipleWorkflowsAfterCreatingStepDismissedServerKey]:
    'upsellMultipleWorkflowsAfterCreatingStepDismissed',
  [UpsellMultipleWorkflowsInitialDismissedServerKey]: 'upsellMultipleWorkflowsInitialDismissed',
  [UserFeedbackServerKey]: 'userFeedback',
  [WebSpotlightTreeCollapsedServerKey]: 'webSpotlightTreeCollapsed',
};
