import { useHistory } from 'react-router';
import { SubscriptionsRoute } from '../../../_shared/constants/routePaths.ts';
import { useDispatch } from '../../../_shared/hooks/useDispatch.ts';
import { useSelector } from '../../../_shared/hooks/useSelector.ts';
import { IProjectDetails } from '../../../data/models/projects/ProjectDetails.ts';
import {
  areAllSubscriptionsCanceled,
  getAdministratedSubscriptions,
} from '../../../data/reducers/subscriptions/selectors/subscriptionSelectors.ts';
import { showTrialActivationModal } from '../actions/projectsActions.ts';
import {
  NoActiveProjectsEmptyState,
  NoActiveSubscriptionsWithTrialEmptyState,
  NoActiveSubscriptionsWithoutTrialEmptyState,
  NoArchivedProjectsEmptyState,
} from '../components/ProjectsEmptyStates.tsx';
import { CopyState } from '../constants/copyState.ts';

type Props = {
  readonly activeProjectsShown: boolean;
  readonly onCreateNewProject: () => void;
};

export const MyProjectsEmptyState = (props: Props) => {
  const dispatch = useDispatch();
  const history = useHistory();

  const showState = useSelector((s) => {
    const hasNoArchivedProject = s.data.projects.byId
      .filter((project: IProjectDetails) => project.inactive)
      .filterNot(
        (project: IProjectDetails) => project.inactive && project.copyState === CopyState.Failed,
      )
      .isEmpty();

    if (!props.activeProjectsShown && hasNoArchivedProject) {
      return 'noArchivedProjects';
    }

    const hasNoActiveSubscription = areAllSubscriptionsCanceled(
      getAdministratedSubscriptions(
        s.data.subscriptions.administratedIds,
        s.data.subscriptions.byId,
      ),
    );
    const hasNoActiveProjects = s.data.projects.byId
      .filter((project) => !project.inactive)
      .isEmpty();

    if (props.activeProjectsShown && hasNoActiveProjects && !hasNoActiveSubscription) {
      return 'noActiveProjects';
    }
    if (props.activeProjectsShown && hasNoActiveProjects && hasNoActiveSubscription) {
      return s.data.user.info.hadTrial
        ? 'noActiveSubscriptionsWithoutTrial'
        : 'noActiveSubscriptionsWithTrial';
    }
    return 'none';
  });

  switch (showState) {
    case 'noActiveProjects':
      return <NoActiveProjectsEmptyState onCreateProject={props.onCreateNewProject} />;
    case 'noArchivedProjects':
      return <NoArchivedProjectsEmptyState />;
    case 'noActiveSubscriptionsWithoutTrial':
      return (
        <NoActiveSubscriptionsWithoutTrialEmptyState
          onRedirectToSubscriptions={() => history.push(SubscriptionsRoute)}
        />
      );
    case 'noActiveSubscriptionsWithTrial':
      return (
        <NoActiveSubscriptionsWithTrialEmptyState
          onCreateFreeTrialClick={() => dispatch(showTrialActivationModal())}
        />
      );
    case 'none':
      return null;
  }
};

MyProjectsEmptyState.displayName = 'MyProjectsEmptyState';
