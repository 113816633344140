import { trackUserEvent } from '../../../_shared/actions/thunks/trackUserEvent.ts';
import { repositoryCollection } from '../../../_shared/repositories/repositories.ts';
import {
  loadCollections,
  loadContentTypes,
  loadLanguages,
  loadTaxonomyGroups,
  loadWorkflows,
} from '../../../data/actions/thunkDataActions.ts';
import { createDisableEntityWebhookAction } from './thunks/disableEntityWebhook.ts';
import { createEnableEntityWebhookAction } from './thunks/enableEntityWebhook.ts';
import { initEntityWebhookCreatorAction } from './thunks/initEntityWebhookCreator.ts';
import { createInitEntityWebhookEditorAction } from './thunks/initEntityWebhookEditor.ts';
import { createInsertEntityWebhookAction } from './thunks/insertEntityWebhook.ts';
import { createResetEntityWebhookAction } from './thunks/resetEntityWebhook.ts';
import { createUpdateEntityWebhookAction } from './thunks/updateEntityWebhook.ts';

const { entityWebhookRepository } = repositoryCollection;

export const initEntityWebhookEditor = createInitEntityWebhookEditorAction({
  entityWebhookRepository,
  loadCollections,
  loadContentTypes,
  loadLanguages,
  loadTaxonomyGroups,
  loadWorkflows,
});

export const initEntityWebhookCreator = initEntityWebhookCreatorAction({
  loadCollections,
  loadContentTypes,
  loadLanguages,
  loadTaxonomyGroups,
  loadWorkflows,
});

export const insertEntityWebhook = createInsertEntityWebhookAction({
  entityWebhookRepository,
  trackUserEvent,
});

export const updateEntityWebhook = createUpdateEntityWebhookAction({
  entityWebhookRepository,
  trackUserEvent,
});

export const enableEntityWebhook = createEnableEntityWebhookAction({
  entityWebhookRepository,
});

export const disableEntityWebhook = createDisableEntityWebhookAction({
  entityWebhookRepository,
});

export const resetEntityWebhook = createResetEntityWebhookAction({
  entityWebhookRepository,
});
