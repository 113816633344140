import { Button } from '@kontent-ai/component-library/Button';
import { EmptyState } from '../../../_shared/components/EmptyState/EmptyState.tsx';
import {
  DataUiAction,
  getDataUiActionAttribute,
} from '../../../_shared/utils/dataAttributes/DataUiAttributes.ts';

const activeProjectsTitleText = 'You have the opportunity to start from scratch.';

export const NoArchivedProjectsEmptyState = () => (
  <EmptyState>
    <EmptyState.Title>There are no archived projects.</EmptyState.Title>
    <EmptyState.Content>Projects that you archive will appear here.</EmptyState.Content>
  </EmptyState>
);

type NoActiveProjectsEmptyStateProps = { readonly onCreateProject: () => void };

export const NoActiveProjectsEmptyState = (props: NoActiveProjectsEmptyStateProps) => (
  <EmptyState>
    <EmptyState.Title>{activeProjectsTitleText}</EmptyState.Title>
    <EmptyState.Content>You have no active projects.</EmptyState.Content>
    <EmptyState.Footer>
      <Button buttonStyle="primary" onClick={props.onCreateProject}>
        Create project
      </Button>
    </EmptyState.Footer>
  </EmptyState>
);

type NoActiveSubscriptionsWithoutTrialEmptyStateProps = {
  readonly onRedirectToSubscriptions: () => void;
};

export const NoActiveSubscriptionsWithoutTrialEmptyState = (
  props: NoActiveSubscriptionsWithoutTrialEmptyStateProps,
) => (
  <EmptyState>
    <EmptyState.Title>{activeProjectsTitleText}</EmptyState.Title>
    <EmptyState.Content>You have no active subscriptions.</EmptyState.Content>
    <EmptyState.Footer>
      <Button
        buttonStyle="primary"
        onClick={props.onRedirectToSubscriptions}
        {...getDataUiActionAttribute(DataUiAction.CreateSubscription)}
      >
        Create subscription
      </Button>
    </EmptyState.Footer>
  </EmptyState>
);

type NoActiveSubscriptionsWithTrialEmptyStateProps = {
  readonly onCreateFreeTrialClick: () => void;
};

export const NoActiveSubscriptionsWithTrialEmptyState = (
  props: NoActiveSubscriptionsWithTrialEmptyStateProps,
) => (
  <EmptyState>
    <EmptyState.Title>{activeProjectsTitleText}</EmptyState.Title>
    <EmptyState.Content>
      If you’d like to create projects, start a free trial subscription and try it out. No credit
      card required.
    </EmptyState.Content>
    <EmptyState.Footer>
      <Button
        buttonStyle="primary"
        onClick={props.onCreateFreeTrialClick}
        {...getDataUiActionAttribute(DataUiAction.StartTrial)}
      >
        Start my free 30-day trial
      </Button>
    </EmptyState.Footer>
  </EmptyState>
);
