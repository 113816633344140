import { ContentItemFilterWithContinuationServerModel } from '../../../../repositories/serverModels/ContentItemFilterWithContinuationServerModel.ts';
import { IListingContentItemServerModel } from '../../../../repositories/serverModels/IListingContentItemServerModel.type.ts';
import { IContentItemIdentifierServerModel } from '../../../../repositories/serverModels/INewContentItemServerModel.ts';

export type ContentStatusFilterRepositoryServerModel =
  ContentItemFilterWithContinuationServerModel & {
    readonly variantIds: ReadonlyArray<Uuid>;
  };

export interface IDoneOnTimeCountSummary {
  readonly allCount: number;
  readonly onTimeCount: number;
}

export interface ITasksDoneOnTime {
  readonly current: IDoneOnTimeCountSummary;
  readonly previous: IDoneOnTimeCountSummary;
}

export type TimeSpentInWorkflowStepRecord = {
  workflowId: Uuid;
  workflowStatusId: Uuid;
  durationInSeconds: number;
};

export type TimeSpentInWorkflowStepByWorkflowServerModel =
  ReadonlyArray<TimeSpentInWorkflowStepRecord>;

export interface IContentPace {
  readonly timeSpentInWorkflowStepByWorkflow: TimeSpentInWorkflowStepByWorkflowServerModel;
}

export type ItemIdsWithTimeSpentInWorkflowStep = ReadonlyArray<TimeSpentInWorkflowStep>;

export type TimeSpentInWorkflowStep = {
  readonly contentItemId: IContentItemIdentifierServerModel;
  readonly durationInSeconds: number;
};

export type WidgetTimeIntervalDTO = 'CurrentWeek' | 'CurrentMonth' | 'PastThirtyDays';

export enum PublishingStage {
  All = 'All',
  BeforeFirstPublish = 'BeforeFirstPublish',
  AfterFirstPublish = 'AfterFirstPublish',
}

export type ContentPaceRequestModel = {
  readonly contentTypeId: Uuid | null;
  readonly isFirstVariant: boolean | null;
  readonly publishingStage: PublishingStage;
  readonly timeInterval: WidgetTimeIntervalDTO;
};

type EnteredWorkflowStep = {
  workflowId: Uuid;
  workflowStatusId: Uuid;
  statusEnteredAt: DateTimeStamp;
};

export type UnchangedUnpublishedItemsServerModel = {
  readonly itemWithVariant: IListingContentItemServerModel;
  readonly lastEnteredWorkflowStep: EnteredWorkflowStep;
};

export type CountsByDueDateServerModel = Readonly<{
  nearDue: number;
  onTrack: number;
  overDue: number;
  scheduled: number;
  withoutDue: number;
}>;

type DoneOnTimeSummaryServerModel = {
  readonly allCount: number;
  readonly onTimeCount: number;
};

export type DoneOnTimeServerModel = {
  readonly current: DoneOnTimeSummaryServerModel;
  readonly previous: DoneOnTimeSummaryServerModel;
};

export type PublishedItemsServerModel = Readonly<{
  collectionId: Uuid;
  contentItemId: IContentItemIdentifierServerModel;
  contentItemName: string;
  contentTypeId: Uuid;
  publishedDate: DateTimeStamp;
  userId: string;
  visitRecordId: Uuid;
}>;
