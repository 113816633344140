import { ThunkFunction, ThunkPromise } from '../../../../../@types/Dispatcher.type.ts';
import { onboardingNotificationTriggered } from '../../../../../_shared/actions/thunkSharedActions.ts';
import { TrackedEvent } from '../../../../../_shared/constants/trackedEvent.ts';
import {
  TrackUserEvent,
  TrackUserEventAction,
} from '../../../../../_shared/models/TrackUserEvent.type.ts';
import { ContentTypeModifiedEventData } from '../../../../../_shared/models/TrackUserEventData.ts';
import { Capability } from '../../../../../_shared/utils/permissions/capability.ts';
import { getFirstCollectionAndLanguageForContentTypeAndCurrentUser } from '../../../../../_shared/utils/permissions/getFirstCollectionAndLanguageForContentTypeAndCurrentUser.ts';
import {
  IContentType,
  convertContentTypeToServerModel,
  getContentTypeFromServerModel,
} from '../../../../../data/models/contentModelsApp/contentTypes/ContentType.ts';
import { OnboardingNotification } from '../../../../../data/models/user/OnboardingNotification.ts';
import { ContentTypeServerModel } from '../../../../../repositories/serverModels/contentModels/contentTypeServerModels.ts';
import {
  saveEditedTypeStarted,
  typeEditorSaveAttempted,
} from '../../../shared/actions/sharedContentModelsActions.ts';
import { IBaseTypeElementData } from '../../../shared/models/elements/types/TypeElementData.ts';
import { getContentTypeConversionOptions } from '../../../shared/selectors/contentTypeElementSelector.ts';
import { createActiveConditionIds } from '../../../shared/utils/conditionUtils.ts';
import { ITrackAssetLimitConfigurationChanged } from '../../../shared/utils/intercomHelpers/trackAssetLimitsUtils.ts';
import { ITrackCustomTypeElementUpserted } from '../../../shared/utils/intercomHelpers/trackCustomTypeElementConfig.ts';
import { saveEditedContentTypeFinished } from '../contentTypesActions.ts';

interface IDeps {
  readonly contentTypeRepository: {
    readonly updateContentType: (
      contentType: ContentTypeServerModel,
    ) => Promise<ContentTypeServerModel>;
  };
  readonly getContentTypeModifiedEventData: (
    typeId: Uuid,
    elements: ReadonlyArray<IBaseTypeElementData>,
    originalElements: ReadonlyArray<IBaseTypeElementData>,
  ) => ContentTypeModifiedEventData;
  readonly loadContentTypesData: () => ThunkPromise;
  readonly saveEditedMultipleChoiceOption: () => ThunkFunction;
  readonly trackAssetLimitConfigurationChanged: ITrackAssetLimitConfigurationChanged;
  readonly trackCustomTypeElementUpserted: ITrackCustomTypeElementUpserted;
  readonly trackUserEvent: TrackUserEventAction;
  readonly validateTypeElement: (updatedTypeElement: IBaseTypeElementData) => ThunkFunction;
}

export const createSaveContentTypeAction =
  (deps: IDeps) =>
  (editedType: IContentType): ThunkPromise =>
  async (dispatch, getState) => {
    dispatch(deps.saveEditedMultipleChoiceOption());

    const {
      sharedApp: { currentProjectId },
      contentModelsApp: {
        contentTypes: {
          data: { originalContentType },
        },
      },
      data: { user, languages, collections },
    } = getState();

    dispatch(typeEditorSaveAttempted(createActiveConditionIds(editedType)));

    dispatch(saveEditedTypeStarted());
    const contentTypeForServer = convertContentTypeToServerModel(editedType);
    const updatedContentTypeServerModel =
      await deps.contentTypeRepository.updateContentType(contentTypeForServer);
    const updatedContentType = getContentTypeFromServerModel(
      updatedContentTypeServerModel,
      getContentTypeConversionOptions(getState()),
    );
    const { languageId, collectionId } = getFirstCollectionAndLanguageForContentTypeAndCurrentUser(
      user,
      updatedContentType.id,
      currentProjectId,
      languages,
      collections,
      Capability.CreateContent,
    );

    const { typeElements: originalTypeElements } = originalContentType;

    const trackEvent: TrackUserEvent = (...args) => dispatch(deps.trackUserEvent(...args));

    deps.trackAssetLimitConfigurationChanged(
      trackEvent,
      updatedContentType.typeElements,
      originalTypeElements,
    );
    deps.trackCustomTypeElementUpserted(
      trackEvent,
      updatedContentType.typeElements,
      originalTypeElements,
    );

    await Promise.all([
      dispatch(deps.loadContentTypesData()),
      dispatch(
        onboardingNotificationTriggered(OnboardingNotification.ContentTypeEditedNotification, {
          editedTypeId: editedType.id,
          languageIdToCreateItem: languageId,
          collectionIdToCreateItem: collectionId,
        }),
      ),
    ]);

    dispatch(
      deps.trackUserEvent(
        TrackedEvent.ContentTypeUpdated,
        deps.getContentTypeModifiedEventData(
          updatedContentType.id,
          updatedContentType.typeElements,
          originalTypeElements,
        ),
      ),
    );

    dispatch(saveEditedContentTypeFinished(updatedContentType));
  };
