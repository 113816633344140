import React, { useContext } from 'react';
import styled from 'styled-components';
// eslint-disable-next-line import/no-restricted-paths
import { RefinedNavigationContext } from '../../../../client/app/applications/refinedNavigation/contexts/RefinedNavigationContext.tsx';
import { Box } from '../../layout/Box/Box.tsx';
import { borderWidthDefault } from '../../tokens/decision/border.ts';
import {
  colorBackgroundLowEmphasis,
  colorBorderLowEmphasis,
} from '../../tokens/decision/colors.ts';
import { Spacing } from '../../tokens/quarks/spacing.ts';
import { px } from '../../tokens/utils/utils.ts';
import { AppMenu } from './components/AppMenu.tsx';
import { navigationBarWidthPx } from './tokens.ts';
import { AppMenuItem } from './types/AppMenuItem.type.ts';

const NavigationBarWrapper = styled.nav`
  padding-top: ${px(Spacing.XXL)};
  width: ${navigationBarWidthPx};
  min-height: 0;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background: ${colorBackgroundLowEmphasis};
  border-right: ${px(borderWidthDefault)} solid ${colorBorderLowEmphasis};
`;

const NavigationTopPane = styled.div`
  display: flex;
  min-height: 0;
  flex-direction: column;
  flex: 1;
`;

NavigationTopPane.displayName = 'NavigationTopPane';

type NavigationBarProps = {
  readonly appMenuItems: ReadonlyArray<AppMenuItem>;
};

export const NavigationBar: React.FC<React.PropsWithChildren<NavigationBarProps>> = ({
  appMenuItems,
  children,
  ...otherProps
}) => {
  const { isRefinedNavigationFeatureEnabled } = useContext(RefinedNavigationContext);

  // DO NOT delete this condition even if it seems redundant! Serves as an example
  return isRefinedNavigationFeatureEnabled ? (
    <NavigationBarWrapper {...otherProps}>
      <NavigationTopPane>
        <AppMenu items={appMenuItems} />
      </NavigationTopPane>
      <Box paddingBottom={Spacing.XXL} paddingTop={Spacing.L}>
        {children}
      </Box>
    </NavigationBarWrapper>
  ) : (
    <NavigationBarWrapper {...otherProps}>
      <NavigationTopPane>
        <AppMenu items={appMenuItems} />
      </NavigationTopPane>
      <Box paddingBottom={Spacing.XXL} paddingTop={Spacing.L}>
        {children}
      </Box>
    </NavigationBarWrapper>
  );
};

NavigationBar.displayName = 'NavigationBar';
