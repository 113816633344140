import { OutwardLink } from '@kontent-ai/component-library/Anchor';
import { Button, RouterLinkButton } from '@kontent-ai/component-library/Button';
import { Card } from '@kontent-ai/component-library/Card';
import { Stack } from '@kontent-ai/component-library/Stack';
import { Spacing, spacingCard } from '@kontent-ai/component-library/tokens';
import classNames from 'classnames';
import { addDays, differenceInCalendarDays } from 'date-fns';
import React from 'react';
import { EmptyState } from '../../../../_shared/components/EmptyState/EmptyState.tsx';
import { HtmlSubscriptionManagementPageTitle } from '../../../../_shared/components/HtmlSubscriptionManagementPageTitle.tsx';
import { Loader } from '../../../../_shared/components/Loader.tsx';
import { PageTitle } from '../../../../_shared/components/PageTitle.tsx';
import {
  BillingHistoryRoute,
  BillingInformationRoute,
  EnsuredSubscriptionRouteParams,
  PlanSelectionRoute,
  SubscriptionsRoute,
} from '../../../../_shared/constants/routePaths.ts';
import { LinkLike } from '../../../../_shared/uiComponents/LinkLike/LinkLike.tsx';
import {
  DataUiAction,
  DataUiAppName,
  DataUiElement,
  getDataUiActionAttribute,
  getDataUiAppNameAttribute,
  getDataUiElementAttribute,
} from '../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';
import { buildPath } from '../../../../_shared/utils/routing/routeTransitionUtils.ts';
import { getUrlFactory } from '../../../../_shared/utils/urlFactory.ts';
import { IPlan } from '../../../../data/models/plans/Plan.ts';
import {
  ISubscription,
  SubscriptionStatus,
} from '../../../../data/models/subscriptions/Subscription.ts';
import { SubscriptionTabName } from '../../shared/constants/subscriptionTabName.ts';
import {
  isSubscriptionSuspendedAutomatically,
  isSubscriptionSuspendedDueToUsingStarterPlan,
} from '../../shared/utils/subscriptionStatusUtils.ts';
import { IBillingPeriodReport } from '../models/BillingPeriodReport.ts';
import { IFupMetricSummary } from '../models/FupMetricSummary.ts';
import { IPaidMetricSummary } from '../models/PaidMetricSummary.ts';
import { CurrentUsageReportUpdateMessage } from './CurrentUsageReportUpdateMessage.tsx';
import { CurrentUsageTable, IMetricRow } from './CurrentUsageTable.tsx';
import { PlanBar } from './PlanBar.tsx';
import { TrialPlanBar } from './TrialPlanBar.tsx';

const getDaysToDate = (dateTime: DateTimeStamp): number => {
  const date: Date = new Date(dateTime);
  const nowDate: Date = new Date();
  return differenceInCalendarDays(date, nowDate);
};

const getRemainingDaysToCancel = (statusLastChanged: DateTimeStamp): string => {
  const daysToCancelSuspendedSubscription = 30;
  const cancellationDate = addDays(new Date(statusLastChanged), daysToCancelSuspendedSubscription);
  const remainingDays = getDaysToDate(cancellationDate.toISOString());
  if (remainingDays > 0) {
    return remainingDays > 1 ? `in ${remainingDays} days` : 'tomorrow';
  }
  return 'today';
};

interface ICurrentUsageProps {
  readonly canChangePlan: boolean;
  readonly currentPlan: IPlan;
  readonly report: IBillingPeriodReport | null;
  readonly showIntercom: () => void;
  readonly showNewIntercomMessage: () => void;
  readonly subscription: ISubscription;
}

export const CurrentUsage: React.FC<ICurrentUsageProps> = ({
  canChangePlan,
  currentPlan,
  report,
  showIntercom,
  showNewIntercomMessage,
  subscription,
}) => {
  const getPlansSelectionPath = (): string =>
    buildPath<EnsuredSubscriptionRouteParams>(PlanSelectionRoute, {
      subscriptionId: subscription.subscriptionId,
    });

  const getBillingHistoryPath = (): string =>
    buildPath<EnsuredSubscriptionRouteParams>(BillingHistoryRoute, {
      subscriptionId: subscription.subscriptionId,
    });

  const getBillingInformationPath = (): string =>
    buildPath<EnsuredSubscriptionRouteParams>(BillingInformationRoute, {
      subscriptionId: subscription.subscriptionId,
    });

  const wrapEmptyState = (emptyState: React.ReactNode): JSX.Element => {
    return (
      <div
        className="canvas__content"
        {...getDataUiAppNameAttribute(DataUiAppName.SubscriptionBillingHistory)}
      >
        <div
          className="canvas__content-pane canvas__content-pane--is-not-project-specific project-management-pane"
          data-hj-suppress=""
        >
          <HtmlSubscriptionManagementPageTitle tabName={SubscriptionTabName.Usage} />
          <Stack spacing={Spacing.XL}>
            <PageTitle>{SubscriptionTabName.Usage}</PageTitle>
            {emptyState}
          </Stack>
        </div>
      </div>
    );
  };

  const renderSuspendedSubscriptionInformation = () => {
    const isSuspendedAutomatically = isSubscriptionSuspendedAutomatically(
      subscription.statusReason,
    );
    const isSuspendedDueToUsingStarterPlan = isSubscriptionSuspendedDueToUsingStarterPlan(
      subscription.statusReason,
    );
    const actionTextToRenew =
      currentPlan.isPrepaid || !isSuspendedAutomatically || isSuspendedDueToUsingStarterPlan
        ? 'Contact our support'
        : 'Select a payment method';
    const remainingDays = isSuspendedAutomatically
      ? ` ${getRemainingDaysToCancel(subscription.statusLastChanged)}`
      : '';

    return (
      <div className="row">
        <div className="col-sm-18 col-lg-16 current-usage__suspended-subscription-information-wrapper">
          <div className="current-usage__suspended-subscription-information">
            <EmptyState>
              <EmptyState.Title>
                {`Your subscription “${subscription.subscriptionName}” has been suspended`}
              </EmptyState.Title>
              <EmptyState.Content>
                <EmptyState.ContentGroup>
                  <p>{actionTextToRenew} to renew your subscription.</p>
                  <p>Otherwise, your subscription will be canceled{remainingDays}.</p>
                </EmptyState.ContentGroup>
              </EmptyState.Content>
              <EmptyState.Footer>
                {currentPlan.isPrepaid ||
                !isSuspendedAutomatically ||
                isSuspendedDueToUsingStarterPlan ? (
                  <Button buttonStyle="primary" onClick={showIntercom}>
                    Contact support to renew
                  </Button>
                ) : (
                  <RouterLinkButton buttonStyle="primary" to={getBillingInformationPath()}>
                    Select a payment method
                  </RouterLinkButton>
                )}
              </EmptyState.Footer>
            </EmptyState>
            {!currentPlan.isPrepaid && (
              <div className="current-usage__suspended-subscription-questions">
                If you have any questions, contact our{' '}
                <LinkLike onClick={showIntercom}>support</LinkLike>.
              </div>
            )}
          </div>
        </div>
      </div>
    );
  };

  if (subscription.status === SubscriptionStatus.Expired) {
    return wrapEmptyState(
      <EmptyState size="fullOffset">
        <EmptyState.Title>Looking for past reports?</EmptyState.Title>
        <EmptyState.Content>
          <EmptyState.ContentGroup>
            <p>Your trial has expired, so you don’t see any current usage.</p>
            <p>To get back to using Kontent.ai, select a subscription plan.</p>
          </EmptyState.ContentGroup>
        </EmptyState.Content>
        <EmptyState.Footer>
          <RouterLinkButton
            buttonStyle="primary"
            to={getPlansSelectionPath()}
            {...getDataUiActionAttribute(DataUiAction.ChangePlan)}
          >
            Select a new plan
          </RouterLinkButton>
        </EmptyState.Footer>
      </EmptyState>,
    );
  }

  if (subscription.status === SubscriptionStatus.Canceled && currentPlan.isTrial) {
    return wrapEmptyState(
      <EmptyState size="fullOffset">
        <EmptyState.Title>Looking for past reports?</EmptyState.Title>
        <EmptyState.Content>
          <EmptyState.ContentGroup>
            <p>Your subscription has been canceled, so we have nothing to report.</p>
            <p>To get back to using Kontent.ai, start a new subscription.</p>
          </EmptyState.ContentGroup>
        </EmptyState.Content>
        <EmptyState.Footer>
          <RouterLinkButton
            buttonStyle="primary"
            to={SubscriptionsRoute}
            {...getDataUiActionAttribute(DataUiAction.StartSubscription)}
          >
            Start subscription
          </RouterLinkButton>
        </EmptyState.Footer>
      </EmptyState>,
    );
  }

  if (subscription.status === SubscriptionStatus.Canceled) {
    return wrapEmptyState(
      <EmptyState size="fullOffset">
        <EmptyState.Title>Looking for past reports?</EmptyState.Title>
        <EmptyState.Content>
          <EmptyState.ContentGroup>
            <p>This subscription has been canceled and won’t receive any new usage reports.</p>
            <p>Find your past usage reports in Billing history.</p>
          </EmptyState.ContentGroup>
        </EmptyState.Content>
        <EmptyState.Footer>
          <RouterLinkButton buttonStyle="primary" to={getBillingHistoryPath()}>
            See Billing history
          </RouterLinkButton>
        </EmptyState.Footer>
      </EmptyState>,
    );
  }

  if (report && !report.reportId) {
    // Empty report - report was not loaded yet
    return <Loader />;
  }

  if (!report) {
    // New account - report was not created yet
    return wrapEmptyState(
      <EmptyState size="fullOffset">
        <EmptyState.Title>Looks like you’re new here.</EmptyState.Title>
        <EmptyState.Content>
          We don’t have a usage report for you just yet. Come back tomorrow!
        </EmptyState.Content>
      </EmptyState>,
    );
  }

  if (report.planId !== currentPlan.planId) {
    // Existing account but different plan - do not show old report values
    return wrapEmptyState(
      <EmptyState size="fullOffset">
        <EmptyState.Title>Your new plan just started.</EmptyState.Title>
        <EmptyState.Content>
          We don’t have a usage report for you just yet. Come back tomorrow!
        </EmptyState.Content>
      </EmptyState>,
    );
  }

  const planInformation = currentPlan.isTrial ? (
    <TrialPlanBar
      subscription={subscription}
      getDaysToDate={getDaysToDate}
      getPlansSelectionPath={getPlansSelectionPath}
    />
  ) : (
    <PlanBar
      subscription={subscription}
      report={report}
      plan={currentPlan}
      canChangePlan={canChangePlan}
      getPlansSelectionPath={getPlansSelectionPath}
    />
  );

  const usageTitle = 'Usage';
  const usage = (
    <Card
      cardLabel={usageTitle}
      component="section"
      {...getDataUiElementAttribute(DataUiElement.UsageSummaryTable)}
    >
      <Card.Headline>{usageTitle}</Card.Headline>
      <Card.Body>
        <CurrentUsageTable
          columnNames={
            currentPlan.isTrial ? ['Your usage'] : ['Included in plan', 'Usage', 'Extra', 'Costs']
          }
          rows={getRowDataFromPaidMetrics(report.paidMetricSummaries)}
          isTrial={currentPlan.isTrial}
        />
      </Card.Body>
    </Card>
  );

  const packageSummariesTitle = 'Services';
  const packageSummaries = (
    <Card
      cardLabel={packageSummariesTitle}
      component="section"
      {...getDataUiElementAttribute(DataUiElement.PackageSummaryTable)}
    >
      <Card.Headline>{packageSummariesTitle}</Card.Headline>
      <Card.Body>
        <div className="current-usage__section-table">
          <table className="current-usage__table">
            <thead className="current-usage__table-head">
              <tr className="current-usage__table-row">
                <th />
                <th>Price</th>
                <th />
                <th />
                <th />
                <th />
              </tr>
            </thead>
            <tbody className="current-usage__table-body">
              {report.packageSummaries.map((row) => (
                <tr key={row.name} className="current-usage__table-row">
                  <td className="current-usage__row-name">{row.displayName}</td>
                  <td className="current-usage__row-value">${row.price}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </Card.Body>
    </Card>
  );

  const fairUsePolicyTitle = 'Fair use policy';
  const fairUsePolicy = (
    <Card
      cardLabel={fairUsePolicyTitle}
      component="section"
      {...getDataUiElementAttribute(DataUiElement.FairUsePolicySummaryTable)}
    >
      <Card.Headline>{fairUsePolicyTitle}</Card.Headline>
      <Card.Body>
        <CurrentUsageTable
          columnNames={
            currentPlan.isTrial ? ['Your usage'] : ['Included in plan', 'Usage', 'Extra', '']
          }
          rows={getRowDataFromFupMetrics(report.fupMetricSummaries)}
          isTrial={currentPlan.isTrial}
        />
      </Card.Body>
    </Card>
  );

  const learnPortalUrl = getUrlFactory().getLearnPortalUrl();
  const learnPortalUsageReportLink = `${learnPortalUrl}/docs/subscriptions-payments/usage-report#a-what-s-in-a-usage-report-`;
  const faq = (
    <div className="current-usage__faq">
      Learn more&nbsp;
      <OutwardLink href={learnPortalUsageReportLink}>about the usage report</OutwardLink>. If you
      have questions about your subscription,&nbsp;
      <LinkLike onClick={showNewIntercomMessage}>contact us</LinkLike>.
    </div>
  );

  return (
    <div
      className="canvas__content-pane canvas__content-pane--is-not-project-specific"
      {...getDataUiAppNameAttribute(DataUiAppName.SubscriptionUsage)}
    >
      <HtmlSubscriptionManagementPageTitle tabName={SubscriptionTabName.Usage} />
      <div className="current-usage canvas__inner-section canvas__inner-section--restricted-width">
        <PageTitle css={`margin-bottom: ${Spacing.XL}px`}>{SubscriptionTabName.Usage}</PageTitle>
        <div
          className={classNames('current-usage__content', {
            'current-usage__content-is-small': currentPlan.isTrial,
          })}
        >
          <Stack spacing={spacingCard}>
            {subscription.status === SubscriptionStatus.Suspended &&
              renderSuspendedSubscriptionInformation()}
            {subscription.isActive && <CurrentUsageReportUpdateMessage report={report} />}
            {planInformation}
            {usage}
            {!!report.packageSummaries.length && packageSummaries}
            {!!report.fupMetricSummaries.length && fairUsePolicy}
          </Stack>
          {faq}
        </div>
      </div>
    </div>
  );
};

CurrentUsage.displayName = 'CurrentUsage';

const getRowDataFromPaidMetrics = (
  metrics: ReadonlyArray<IPaidMetricSummary>,
): ReadonlyArray<IMetricRow> =>
  metrics.map(
    (m: IPaidMetricSummary): IMetricRow => ({
      name: m.name,
      usage: m.usage,
      included: m.includedInPlan,
      extra: m.extraUnits,
      costs: m.extraUnitsCosts,
    }),
  );

const getRowDataFromFupMetrics = (
  metrics: ReadonlyArray<IFupMetricSummary>,
): ReadonlyArray<IMetricRow> =>
  metrics.map(
    (m: IFupMetricSummary): IMetricRow => ({
      name: m.name,
      usage: m.usage,
      included: m.includedInPlan,
      extra: m.overPlan,
    }),
  );
