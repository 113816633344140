import { Box } from '@kontent-ai/component-library/Box';
import { Column, Row } from '@kontent-ai/component-library/Row';
import {
  Spacing,
  borderWidthDefault,
  colorBorderLowEmphasis,
  px,
} from '@kontent-ai/component-library/tokens';
import { PreviewRefreshStatus } from '../../containers/contentItemPreview/PreviewRefreshStatus.tsx';
import { SpaceSelector } from '../../containers/contentItemPreview/SpaceSelector.tsx';
import { OpenInNewTabButton } from './OpenInNewTabButton.tsx';
import { PreviewActionsMenu } from './PreviewActionsMenu.tsx';
import { RefreshPreviewButton } from './RefreshPreviewButton.tsx';

type Props = Readonly<{
  isAutoRefreshAvailable: boolean;
  isAutoRefreshEnabled: boolean;
  isWebSpotlightIntegrationEnabled: boolean;
  setSpaceId: (newState: Uuid) => void;
  spaceId: Uuid | null;
}>;

export const ContentItemPreviewActionBar = ({
  isAutoRefreshAvailable,
  isAutoRefreshEnabled,
  isWebSpotlightIntegrationEnabled,
  setSpaceId,
  spaceId,
}: Props) => {
  return (
    <Box
      paddingX={Spacing.L}
      paddingY={Spacing.S}
      css={`border-bottom: ${px(borderWidthDefault)} solid ${colorBorderLowEmphasis}`}
    >
      <Row spacingY={Spacing.S}>
        <Column>
          <SpaceSelector spaceId={spaceId} setSpaceId={setSpaceId} />
        </Column>

        <Column width="fit-content">
          <Row spacingX={Spacing.M} spacingY={Spacing.S}>
            {isAutoRefreshEnabled && (
              <Column css="display: flex;">
                <PreviewRefreshStatus />
              </Column>
            )}
            <Column>
              <OpenInNewTabButton spaceId={spaceId} buttonStyle="secondary" />
            </Column>
            {isAutoRefreshAvailable ? (
              <Column>
                <PreviewActionsMenu
                  isWebSpotlightIntegrationEnabled={isWebSpotlightIntegrationEnabled}
                />
              </Column>
            ) : (
              <Column>
                <RefreshPreviewButton />
              </Column>
            )}
          </Row>
        </Column>
      </Row>
    </Box>
  );
};
