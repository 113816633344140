import React, { useCallback, useEffect } from 'react';
import { useHistory } from 'react-router';
import { DefaultVariantId } from '../../../../../_shared/constants/variantIdValues.ts';
import { useDispatch } from '../../../../../_shared/hooks/useDispatch.ts';
import { useSelector } from '../../../../../_shared/hooks/useSelector.ts';
import { getSelectedLanguageId } from '../../../../../_shared/selectors/getSelectedLanguageId.ts';
import { itemEditingModalDismissed } from '../../../actions/contentActions.ts';
import { getWorkflowsCurrentUserCanCreateVariantIn } from '../../../selectors/workflows/getWorkflowsCurrentUserCanCreateVariantIn.ts';
import {
  newVariantDialogInitialized,
  newVariantSelectedWorkflowChanged,
} from '../actions/newVariantActions.ts';
import { submitNewVariantWorkflowSelection } from '../actions/thunks/thunkNewVariantActions.ts';
import { NewVariantWorkflowSelectionDialog as SelectWorkflowWhenCreatingItemDialogComponent } from '../components/NewVariantWorkflowSelectionDialog.tsx';

const NewVariantWorkflowSelectionDialogContainer: React.FC = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    dispatch(newVariantDialogInitialized());
  }, []);

  const isSavingDisabled = useSelector((state) => !state.contentApp.newVariant.selectedWorkflowId);

  const workflows = useSelector((state) =>
    getWorkflowsCurrentUserCanCreateVariantIn(
      state,
      getSelectedLanguageId(state) ?? DefaultVariantId,
      state.contentApp.editedContentItem?.collectionId ?? null,
      state.contentApp.editedContentItem?.editedContentItemTypeId ?? null,
    ),
  );

  const onSelectWorkflow = useCallback(
    (workflowId: Uuid) => dispatch(newVariantSelectedWorkflowChanged(workflowId)),
    [],
  );
  const onSave = useCallback(() => dispatch(submitNewVariantWorkflowSelection(history)), [history]);

  const canGoBack = history.length > 1;

  const onCancel = useCallback(() => {
    dispatch(itemEditingModalDismissed());
    history.goBack();
  }, [history]);

  return (
    <SelectWorkflowWhenCreatingItemDialogComponent
      workflows={workflows}
      isDismissable={canGoBack}
      isSavingDisabled={isSavingDisabled}
      onSelectWorkflow={onSelectWorkflow}
      onSave={onSave}
      onCancel={canGoBack ? onCancel : undefined}
    />
  );
};

NewVariantWorkflowSelectionDialogContainer.displayName =
  'NewVariantWorkflowSelectionDialogContainer';

export { NewVariantWorkflowSelectionDialogContainer as NewVariantWorkflowSelectionDialog };
