import { ThunkFunction } from '../../../../../@types/Dispatcher.type.ts';
import { TrackedEvent } from '../../../../../_shared/constants/trackedEvent.ts';
import { TrackUserEventAction } from '../../../../../_shared/models/TrackUserEvent.type.ts';
import { LocalizationEditor_Language_Add } from '../../constants/localizationActionTypes.ts';
import { LanguageFormShape } from '../../models/languageFormShape.type.ts';

interface ILoadSelectedProjectDependencies {
  readonly trackUserEvent: TrackUserEventAction;
}

const languageCreated = (languageId: Uuid, formValues: LanguageFormShape) =>
  ({
    type: LocalizationEditor_Language_Add,
    payload: {
      languageId,
      formValues,
    },
  }) as const;

export type CreateNewLanguageActionsType = ReturnType<typeof languageCreated>;

export const createNewLanguageAction =
  (deps: ILoadSelectedProjectDependencies) =>
  (languageId: Uuid, formValues: LanguageFormShape): ThunkFunction =>
  (dispatch): void => {
    dispatch(languageCreated(languageId, formValues));
    dispatch(deps.trackUserEvent(TrackedEvent.LocalizationLangVariantCreated));
  };
