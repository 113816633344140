import React from 'react';
import { useSelector } from '../../../../../_shared/hooks/useSelector.ts';
import { MissionControlTrackedWidgetName } from '../../../../../_shared/models/TrackUserEventData.ts';
import { repositoryCollection } from '../../../../../_shared/repositories/repositories.ts';
import {
  useTrackWidgetDetailItemClick,
  useTrackWidgetOverviewItemClick,
  useTrackWidgetShowMoreButtonClick,
} from '../../shared/hooks/tracking.ts';
import { PublishedItemsWidget } from '../components/PublishedItemsWidget.tsx';
import { PublishedItemsCountRequestModel } from '../types/PublishedItemsCountRequestModel.type.ts';
import { PublishedItemsListingRequestOptionsModel } from '../types/PublishedItemsListingRequestOptionsModel.type.ts';

export const PublishedItemsWidgetContainer: React.FC = () => {
  const collectionIds = useSelector((s) => s.data.collections).byId;
  const contentTypeByIds = useSelector((s) => s.data.contentTypes.byId);

  const onDetailItemClick = useTrackWidgetDetailItemClick(
    MissionControlTrackedWidgetName.PublishedContent,
  );
  const onOverviewItemClick = useTrackWidgetOverviewItemClick(
    MissionControlTrackedWidgetName.PublishedContent,
  );
  const onShowMoreClick = useTrackWidgetShowMoreButtonClick(
    MissionControlTrackedWidgetName.PublishedContent,
  );

  return (
    <PublishedItemsWidget
      collectionIds={collectionIds}
      contentTypeByIds={contentTypeByIds}
      itemsCountFetcher={itemsCountFetcher}
      itemsFetcher={itemsFetcher}
      onDetailItemClick={onDetailItemClick}
      onOverviewItemClick={onOverviewItemClick}
      onShowMoreClick={onShowMoreClick}
    />
  );
};

const itemsFetcher = (
  requestOptions: PublishedItemsListingRequestOptionsModel,
  abortSignal?: AbortSignal,
) => repositoryCollection.missionControlRepository.getPublishedItems(requestOptions, abortSignal);

const itemsCountFetcher = (
  requestOptions: PublishedItemsCountRequestModel,
  abortSignal?: AbortSignal,
) =>
  repositoryCollection.missionControlRepository.getPublishedItemsCount(requestOptions, abortSignal);
