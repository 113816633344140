import { assert } from '@kontent-ai/utils';
import { Dispatch, GetState, ThunkFunction } from '../../../../../../@types/Dispatcher.type.ts';
import { trackUserEvent } from '../../../../../../_shared/actions/thunks/trackUserEvent.ts';
import { TrackedEvent } from '../../../../../../_shared/constants/trackedEvent.ts';
import { getPreselectedStepForUpdateStep } from '../../../../../../_shared/features/ChangeWorkflowStepModal/selectors/getPreselectedWorkflowStep.ts';
import { OpenWorkflowStepModalAction } from '../../../../../../_shared/features/ChangeWorkflowStepModal/types/workflowStepModalActionTypes.ts';
import {
  ContentItemEditingEventOrigins,
  ContentItemEditingTriggerDialogAction,
} from '../../../../../../_shared/models/events/ContentItemEditingEventData.type.ts';
import { getEditedContentItemVariant } from '../../../../../../_shared/selectors/getEditedContentItemVariant.ts';
import { isPublishedWorkflowStep } from '../../../../../../_shared/utils/contentItemUtils.ts';
import { hideValidationResults } from '../../../../actions/contentActions.ts';
import { IOpenChangeWorkflowStepModal } from './openChangeWorkflowStepModal.ts';

interface IDeps {
  readonly displayWarningsOnPublishSelected: () => ThunkFunction;
  readonly openChangeWorkflowStepModal: IOpenChangeWorkflowStepModal;
}

export const createOpenUpdateStepModalAction =
  ({ displayWarningsOnPublishSelected, openChangeWorkflowStepModal }: IDeps) =>
  (actionOrigin: ContentItemEditingEventOrigins): ThunkFunction =>
  (dispatch: Dispatch, getState: GetState) => {
    dispatch(hideValidationResults());

    dispatch(openChangeWorkflowStepModal(OpenWorkflowStepModalAction.UpdateStep, actionOrigin));

    const state = getState();
    const editedVariant = getEditedContentItemVariant(state);

    assert(
      editedVariant,
      () => 'openUpdateStepModal.ts: Edited content item variant was not found.',
    );

    const { workflowId } = editedVariant.assignment.workflowStatus;
    const preSelectedStep = getPreselectedStepForUpdateStep(state, workflowId);

    if (isPublishedWorkflowStep(preSelectedStep?.step)) {
      dispatch(displayWarningsOnPublishSelected());
    }

    dispatch(
      trackUserEvent(TrackedEvent.ContentItemEditing, {
        action: ContentItemEditingTriggerDialogAction.ChangeWorkflowStepSelected,
        origin: actionOrigin,
      }),
    );
  };
