import { Box } from '@kontent-ai/component-library/Box';
import { TabView } from '@kontent-ai/component-library/TabView';
import { ConditionalWrapper } from '@kontent-ai/component-library/component-utils';
import { Spacing } from '@kontent-ai/component-library/tokens';
import classNames from 'classnames';
import { forwardRef } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { ICompiledContentType } from '../../../../contentInventory/content/models/CompiledContentType.ts';
import { TypeElement } from '../../../../contentInventory/content/models/contentTypeElements/TypeElement.type.ts';
import { ICompiledContentItemElementData } from '../../../models/contentItemElements/ICompiledContentItemElement.type.ts';
import { ContentItemContextProvider } from '../../ContentComponent/context/ContentItemContext.tsx';
import {
  ItemElementsContextProvider,
  OriginalItemElementsContextProvider,
} from '../../ContentComponent/context/ItemElementsContext.tsx';
import { NoElementsElement } from '../../ContentItemEditing/components/NoElementsElement.tsx';
import { ContentGroupTabsInLinkedItem } from '../../ContentItemEditing/containers/ContentGroupTabsInLinkedItem.tsx';
import { ContentNestingContextProvider } from '../../ContentItemEditing/context/ContentNestingContext.tsx';
import { RichTextItemElementContextProvider } from '../../ContentItemEditing/context/RichTextItemElementContext.tsx';
import { ContentGroupTabsId } from '../../ContentItemEditing/utils/contentGroupTabsId.ts';
import { ExpandedItemElement } from './ExpandedItemElement.tsx';

type Props = {
  readonly contentGroupTabsId: ContentGroupTabsId;
  readonly contentItemCodename: string | undefined;
  readonly contentItemCollectionId: Uuid | undefined;
  readonly contentItemId: Uuid;
  readonly contentItemName: string | undefined;
  readonly contentItemVariantId: Uuid | null;
  readonly contentType: ICompiledContentType | null;
  readonly elements: ReadonlyArray<ICompiledContentItemElementData> | null;
  readonly expandedItemId?: string;
  readonly isHighlighted: boolean;
  readonly isItemPage: boolean;
  readonly typeElements: ReadonlyArray<TypeElement> | null;
};

export const ExpandedItemElements = forwardRef<HTMLDivElement, Props>(
  ({
    contentGroupTabsId,
    contentItemCodename,
    contentItemCollectionId,
    contentItemId,
    contentItemName,
    contentItemVariantId,
    contentType,
    elements,
    expandedItemId,
    isHighlighted,
    isItemPage,
    typeElements,
  }) => {
    if (!contentType || !elements || !typeElements) {
      return (
        <div className="bar-item__loading-indicator-pane">
          <div className="bar-item__loading-indicator" />
        </div>
      );
    }

    const renderExpandedItemElements = (
      <div className="expanded-linked-item__elements">
        {typeElements.length === 0 ? (
          <NoElementsElement isDisabled />
        ) : (
          <ContentNestingContextProvider isCurrentLevelContentComponent={false}>
            {typeElements.map((typeElement) => (
              <ExpandedItemElement
                key={typeElement.elementId}
                elements={elements}
                typeElement={typeElement}
                typeElements={typeElements}
              />
            ))}
          </ContentNestingContextProvider>
        )}
      </div>
    );

    return (
      <ErrorBoundary
        fallback={
          <div className="bar-item__alert-pane">
            <div className="bar-item__alert-message">
              {`There was a problem loading this ${isItemPage ? 'page' : 'linked item'}. Check the ${
                isItemPage ? 'page' : 'item'
              } for any inconsistencies or contact our support.`}
            </div>
          </div>
        }
      >
        <div
          data-ui-element="expanded-item-elements"
          className={classNames('expanded-linked-item', {
            'expanded-linked-item--is-highlighted': isHighlighted,
          })}
          id={expandedItemId}
        >
          <ContentItemContextProvider
            itemCodename={contentItemCodename}
            itemId={contentItemId}
            variantId={contentItemVariantId}
            itemCollectionId={contentItemCollectionId}
            itemName={contentItemName}
          >
            <OriginalItemElementsContextProvider elements={null} snapshotTime={null}>
              <ItemElementsContextProvider elements={elements} snapshotTime={null}>
                <RichTextItemElementContextProvider rootRichTextElementId={null}>
                  <ConditionalWrapper
                    condition={!!contentType.contentGroups.length}
                    wrapper={(children) => (
                      <ContentGroupTabsInLinkedItem
                        contentGroupTabsId={contentGroupTabsId}
                        contentItemId={contentItemId}
                        contentType={contentType}
                      >
                        <Box paddingTop={Spacing.L}>
                          <TabView.TabGroup />
                        </Box>
                        {children}
                      </ContentGroupTabsInLinkedItem>
                    )}
                  >
                    {renderExpandedItemElements}
                  </ConditionalWrapper>
                </RichTextItemElementContextProvider>
              </ItemElementsContextProvider>
            </OriginalItemElementsContextProvider>
          </ContentItemContextProvider>
        </div>
      </ErrorBoundary>
    );
  },
);

ExpandedItemElements.displayName = 'ExpandedItemElements';
