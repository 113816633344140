import { useSelector } from '../../../../../../_shared/hooks/useSelector.ts';
import { SpaceSelector as SpaceSelectorComponent } from '../../components/contentItemPreview/SpaceSelector.tsx';
import { getSpacesForPreviewUrls } from '../../utils/getAvailableSpacesForPreviewUrls.ts';

type Props = Readonly<{
  spaceId: Uuid | null;
  setSpaceId: (newState: Uuid) => void;
}>;

export const SpaceSelector = ({ spaceId, setSpaceId }: Props) => {
  const availableSpaces = useSelector(getSpacesForPreviewUrls);

  if (!availableSpaces.length) return null;

  const spaceMenuItems = availableSpaces.map((space) => ({
    id: space.id,
    label: space.name,
  }));

  return (
    <SpaceSelectorComponent
      onSelectionChange={setSpaceId}
      selectedSpaceId={spaceId}
      spaceMenuItems={spaceMenuItems}
    />
  );
};
