import React from 'react';
import { ThunkFunction, ThunkPromise } from '../../../../../../@types/Dispatcher.type.ts';
import { IStore } from '../../../../../../_shared/stores/IStore.type.ts';
import { removeNonPersistentAssets } from '../../../../../../_shared/utils/assets/assetUtils.ts';
import { itemEditorOperationIds } from '../../../../../contentInventory/content/utils/itemEditorOperationIdUtils.ts';
import { UrlSlugMode } from '../../../../constants/urlSlugMode.ts';
import { ICompiledContentItemElementData } from '../../../../models/contentItemElements/ICompiledContentItemElement.type.ts';
import {
  isAssetElement,
  isRichTextElement,
  isTextElement,
  isUrlSlugElement,
} from '../../../../models/contentItemElements/compiledItemElementTypeGuards.ts';
import {
  ElementChangeType,
  getElementChange,
  getElementChangeIncludingPublishedStep,
} from '../../../../stores/utils/contentItemElementDispatchUtils.ts';
import {
  autoDispatchSaveCancelled,
  shouldAutoDispatchCheckFinished,
  shouldAutoDispatchCheckPending,
} from '../../actions/contentItemEditingActions.ts';
import {
  autoDispatchSavePending,
  saveElementValuesToServer,
} from '../../actions/thunkContentItemEditingActions.ts';
import {
  CheckOperation,
  GetElementChanges,
  ObservedStateWithElements,
  SaveOperation,
  createElementsAutoDispatcher,
} from './ElementsAutoDispatcher.tsx';

type ObservedState = ObservedStateWithElements<ICompiledContentItemElementData>;

export function mapObservedState(state: IStore): ObservedState {
  const {
    contentApp: { editedContentItemVariantElements: elements },
  } = state;
  return { elements };
}

const saveElementValues =
  (observed: ObservedState, operation: SaveOperation): ThunkPromise =>
  async (dispatch) => {
    const elementsData = observed.elements.filter((elementData) =>
      operation.elementIdsToSave.includes(elementData.elementId),
    );

    await dispatch(
      saveElementValuesToServer({
        elementsData,
        operationId: operation.id,
        pathname: window.location.pathname,
      }),
    );
  };

const saveOperationPending =
  (pendingOperation: SaveOperation): ThunkFunction =>
  (dispatch) => {
    dispatch(autoDispatchSavePending(createOperationIds(pendingOperation)));
  };

const saveOperationCancelled =
  (cancelledOperation: SaveOperation): ThunkFunction =>
  (dispatch) => {
    dispatch(autoDispatchSaveCancelled(createOperationIds(cancelledOperation)));
  };

const createOperationIds = (operation: SaveOperation) =>
  operation.elementIdsToSave.map((elementId) =>
    itemEditorOperationIds.element(operation.id, elementId),
  );

const shouldElementsBeSavedCheckPending =
  (operation: CheckOperation): ThunkFunction =>
  (dispatch) => {
    dispatch(
      shouldAutoDispatchCheckPending(itemEditorOperationIds.pendingElementsCheck(operation.id)),
    );
  };

const shouldElementsBeSavedCheckFinished =
  (operation: CheckOperation): ThunkFunction =>
  (dispatch) => {
    dispatch(
      shouldAutoDispatchCheckFinished(itemEditorOperationIds.pendingElementsCheck(operation.id)),
    );
  };

const getElementChanges: GetElementChanges<ObservedState> = (
  newState,
  oldObservedState,
  newObservedState,
) => {
  const getChangeType = (elementData: ICompiledContentItemElementData): ElementChangeType => {
    const oldElementData =
      oldObservedState.elements.find(({ elementId }) => elementId === elementData.elementId) ??
      null;

    if (isUrlSlugElement(elementData) && elementData.mode === UrlSlugMode.Auto) {
      return ElementChangeType.NoChange;
    }

    if (isAssetElement(elementData) && isAssetElement(oldElementData)) {
      return getElementChange(
        newState,
        removeNonPersistentAssets(elementData, newState.data.assets.byId),
        removeNonPersistentAssets(oldElementData, newState.data.assets.byId),
      );
    }

    // Comments in RTE, Text and URL slug are matched to the content's style, which needs to be updated when commenting in published step
    if (
      isRichTextElement(elementData) ||
      isTextElement(elementData) ||
      isUrlSlugElement(elementData)
    ) {
      return getElementChangeIncludingPublishedStep(newState, elementData, oldElementData);
    }

    return getElementChange(newState, elementData, oldElementData);
  };

  return new Map(
    newObservedState.elements.map((element) => [element.elementId, getChangeType(element)]),
  );
};

// This is aggregated auto dispatcher for changes to all item elements
export const ContentItemElementsAutoDispatcher: React.ComponentType =
  createElementsAutoDispatcher<ICompiledContentItemElementData>(
    mapObservedState,
    saveElementValues,
    1000,
    getElementChanges,
    saveOperationPending,
    saveOperationCancelled,
    shouldElementsBeSavedCheckPending,
    shouldElementsBeSavedCheckFinished,
  );
