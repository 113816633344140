import { useCallback, useEffect, useState } from 'react';
import { getContentStateActionResult } from '../../../../../../_shared/features/AI/helpers/transformAiResult.ts';
import { useAiTask } from '../../../../../../_shared/features/AI/hooks/aiTasks/useAiTask.ts';
import { useOnFinishedAiActionTask } from '../../../../../../_shared/features/AI/hooks/aiTasks/useOnFinishedAiActionTask.ts';
import { useAiActionTrackingWithSession } from '../../../../../../_shared/features/AI/hooks/useAiActionTrackingWithSession.ts';
import { usePendingAiActionWithSession } from '../../../../../../_shared/features/AI/hooks/usePendingAiActionWithSession.ts';
import { AiActionProps } from '../../../../../../_shared/features/AI/types/AiActionProps.type.ts';
import { AiError } from '../../../../../../_shared/features/AI/types/aiErrors.ts';
import { useSelector } from '../../../../../../_shared/hooks/useSelector.ts';
import { AiActionSource } from '../../../../../../_shared/models/events/AiActionEventData.type.ts';
import { repositoryCollection } from '../../../../../../_shared/repositories/repositories.ts';
import { getLanguage } from '../../../../../../data/reducers/languages/selectors/getLanguages.ts';
import { AiActionName } from '../../../../../../repositories/serverModels/ai/AiActionName.type.ts';
import { createTranslateContentRequest } from '../../../../../../repositories/serverModels/ai/actions/AiServerModels.translateContent.ts';
import { IBaseTextItemElement } from '../../../../../itemEditor/models/contentItemElements/IBaseTextItemElement.type.ts';
import { EmptyTextItemElement } from '../../../../../itemEditor/models/contentItemElements/TextItemElement.ts';
import { isRichTextElement } from '../../../../../itemEditor/models/contentItemElements/compiledItemElementTypeGuards.ts';
import { getElementById } from '../../../../../itemEditor/stores/utils/contentItemElementsUtils.ts';
import { convertElementsToDomainModel } from '../../../../../itemEditor/stores/utils/contentItemHelperMethods.ts';
import { useEditorStateCallbacks } from '../../../../editorCore/hooks/useEditorStateCallbacks.ts';
import { useEditorWithPlugin } from '../../../../editorCore/hooks/useEditorWithPlugin.tsx';
import { PluginComponent } from '../../../../editorCore/types/Editor.composition.type.ts';
import { None } from '../../../../editorCore/types/Editor.contract.type.ts';
import { Apply, Render } from '../../../../editorCore/types/Editor.plugins.type.ts';
import { Decorator } from '../../../../editorCore/utils/decorable.ts';
import {
  IContentChangeInput,
  IContentChangeResult,
  isContentEmpty,
  replaceContent,
} from '../../../../utils/general/editorContentUtils.ts';
import { IRichTextContent } from '../../../clipboard/thunks/pasteContentToRichText.ts';
import { DraftJsEditorPlugin } from '../../../draftJs/DraftJsEditorPlugin.type.ts';
import { StylesPlugin } from '../../../visuals/StylesPlugin.tsx';
import { createAiActionResultSelector } from '../../helpers/createAiActionResultSelector.ts';
import { getContentForActionInput } from '../../helpers/getContentForActionInput.ts';
import { AiTranslateAction } from './containers/AiTranslateAction.tsx';

type Props = AiActionProps & {
  readonly onRichTextContentInserted?: (content: IRichTextContent) => Promise<void>;
};

export type TranslateContentPlugin = DraftJsEditorPlugin<None, Props, None, None, [StylesPlugin]>;

export const TranslateContentPlugin: PluginComponent<TranslateContentPlugin> = (props) => {
  const { element, onRichTextContentInserted } = props;

  const [lastError, setLastError] = useState<AiError | null>(null);

  const languages = useSelector((s) => s.data.languages);

  const { aiSessionId, elementOperationTrackingData, trackFinishedAction, trackStartingAction } =
    useAiActionTrackingWithSession(element);

  const { decorateWithEditorStateCallbacks, executeChange, getApi, getEditorState, refreshEditor } =
    useEditorStateCallbacks<TranslateContentPlugin>();

  const { aiOperationState, startPendingAiAction, finishPendingAiAction } =
    usePendingAiActionWithSession(aiSessionId);

  const { run, result } = useAiTask(
    AiActionName.TranslateContent,
    createAiActionResultSelector(element.elementType, getContentStateActionResult),
  );

  useOnFinishedAiActionTask(result.isFinished, () => {
    if (result.trackingParams) {
      trackFinishedAction(result.trackingParams);
    }
    setLastError(result.error);
    finishPendingAiAction();
  });

  const startTranslateContentAction = useCallback(
    async (sourceVariantId: string | null) => {
      if (!element.itemId) {
        return;
      }

      const {
        itemId: { itemId, variantId: targetVariantId },
        elementId,
      } = element;

      const sourceLanguage = sourceVariantId ? getLanguage(languages, sourceVariantId) : null;
      const targetLanguage = getLanguage(languages, targetVariantId);
      if (!targetLanguage) {
        return;
      }

      startPendingAiAction();

      const sourceElementData = sourceVariantId
        ? await loadSourceElementData(itemId, sourceVariantId, elementId)
        : null;

      const sourceContent = (
        sourceElementData?._editorState ?? getEditorState()
      ).getCurrentContent();

      // The translated content will be streamed to the AI result view together with its original references
      // We need to make sure the original content components and referenced data is available before running the action
      if (sourceElementData && isRichTextElement(sourceElementData)) {
        await onRichTextContentInserted?.({
          editorState: sourceElementData._editorState,
          contentComponents: sourceElementData.contentComponents,
        });
      }

      run(
        createTranslateContentRequest(
          getContentForActionInput(element.elementType, sourceContent),
          sourceLanguage?.name ?? 'unknown',
          sourceLanguage?.codename ?? 'unknown',
          targetLanguage.name,
          targetLanguage.codename,
          elementOperationTrackingData,
        ),
        { element },
      );

      trackStartingAction({
        action: AiActionName.TranslateContent,
        source: AiActionSource.Element,
        contentComponentId: element.contentComponentId ?? undefined,
        sourceVariantId: sourceVariantId ?? undefined,
        sourceLanguageCode: sourceLanguage?.codename,
        targetLanguageCode: targetLanguage.codename,
      });
    },
    [
      languages,
      run,
      element,
      elementOperationTrackingData,
      onRichTextContentInserted,
      startPendingAiAction,
      trackStartingAction,
      getEditorState,
    ],
  );

  useEffect(() => {
    const replaceContentWithTranslation = (input: IContentChangeInput): IContentChangeResult => {
      if (!result.content) {
        return input;
      }

      return replaceContent(input, result.content);
    };

    if (result.content) {
      executeChange((editorState) =>
        getApi().executeContentChange(
          editorState,
          editorState.getSelection(),
          replaceContentWithTranslation,
          'insert-fragment',
        ),
      );

      refreshEditor();
    }
  }, [executeChange, getApi, result.content, refreshEditor]);

  const renderOverlays: Decorator<Render<TranslateContentPlugin>> = useCallback(
    (baseRenderOverlays) => (state) => (
      <>
        {baseRenderOverlays(state)}
        <AiTranslateAction
          isEmpty={isContentEmpty(state.editorState.getCurrentContent())}
          aiOperationState={aiOperationState}
          element={element}
          error={lastError}
          perform={startTranslateContentAction}
        />
      </>
    ),
    [aiOperationState, element, lastError, startTranslateContentAction],
  );

  const apply: Apply<TranslateContentPlugin> = useCallback(
    (state) => {
      decorateWithEditorStateCallbacks(state);
      state.renderOverlays.decorate(renderOverlays);

      return {};
    },
    [decorateWithEditorStateCallbacks, renderOverlays],
  );

  return useEditorWithPlugin(props, { apply });
};

const loadSourceElementData = async (
  itemId: Uuid,
  sourceVariantId: Uuid,
  elementId: Uuid,
): Promise<IBaseTextItemElement> => {
  const variantServerModel =
    await repositoryCollection.contentItemRepository.getVariantWithElements(
      itemId,
      sourceVariantId,
      { elementIds: [elementId] },
    );
  const elements = convertElementsToDomainModel(variantServerModel.contentElements);
  return getElementById<IBaseTextItemElement>(elementId, elements) || EmptyTextItemElement;
};
