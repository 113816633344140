import React from 'react';
import { trackUserEvent } from '../../../../_shared/actions/thunks/trackUserEvent.ts';
import { TrackedEvent } from '../../../../_shared/constants/trackedEvent.ts';
import { useDispatch } from '../../../../_shared/hooks/useDispatch.ts';
import { SampleAppContent as SampleAppContentComponent } from '../components/shared/SampleAppContent.tsx';
import { IResourceItem } from '../data/resources.ts';

type Props = {
  readonly item: IResourceItem;
};

export const SampleAppContent: React.FC<Props> = (props) => {
  const dispatch = useDispatch();

  return (
    <SampleAppContentComponent
      item={props.item}
      onLinkClick={(linkId, technology, target) =>
        dispatch(
          trackUserEvent(TrackedEvent.QuickstartLinkOpened, {
            'link-id': linkId,
            technology,
            target,
          }),
        )
      }
    />
  );
};

SampleAppContent.displayName = 'SampleAppContent';
