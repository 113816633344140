import { combineReducers } from '@reduxjs/toolkit';
import { editedProjectName } from '../../projectSettings/general/reducers/internalReducers/editedProjectName.ts';
import { activatingTrial } from './internalReducers/activatingTrial.ts';
import { cloningProjectsIds } from './internalReducers/cloningProjectsIds.ts';
import { projectsUiTransitionState } from './internalReducers/projectsUiTransitionState.ts';
import { savingStatus } from './internalReducers/savingStatus.ts';
import { serverError } from './internalReducers/serverError.ts';

export const projectsAppReducer = combineReducers({
  activatingTrial,
  savingStatus,
  editedProjectName,
  serverError,
  projectsUiTransitionState,
  cloningProjectsIds,
});
