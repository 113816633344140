import { Button } from '@kontent-ai/component-library/Button';
import React from 'react';
import { useDispatch } from '../../../../../../../../_shared/hooks/useDispatch.ts';
import { ContentItemEditingEventOrigins } from '../../../../../../../../_shared/models/events/ContentItemEditingEventData.type.ts';
import {
  DataUiWorkflowAction,
  ItemEditingForms,
  getDataUiWorkflowActionAttribute,
} from '../../../../../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';
import { ItemEditingModalDialogType } from '../../../../../../constants/itemEditingModalDialogType.ts';
import { changeDueDate } from '../../../../actions/thunkContentItemEditingActions.ts';
import { NonClickableSection } from '../../../../components/sidebar/sectionTypes/NonClickableSection.tsx';

export const NonClickableDueDateSection: React.FC = () => {
  const dispatch = useDispatch();
  const onClick = () =>
    dispatch(
      changeDueDate(
        ContentItemEditingEventOrigins.ItemDetails,
        ItemEditingModalDialogType.ContentItemDueDateDialog,
      ),
    );

  return (
    <NonClickableSection dataUiNameAttribute={ItemEditingForms.DueDate}>
      <Button
        buttonStyle="secondary"
        size="small"
        onClick={onClick}
        {...getDataUiWorkflowActionAttribute(DataUiWorkflowAction.EditDueDate)}
      >
        Set due date
      </Button>
    </NonClickableSection>
  );
};

NonClickableDueDateSection.displayName = 'NonClickableDueDateSection';
