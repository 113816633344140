import { loadWebSpotlightConfigurationActionCreator } from '../../applications/webSpotlight/actions/thunks/loadWebSpotlightConfiguration.ts';
import {
  loadAssetType,
  loadCollections,
  loadLanguages,
} from '../../data/actions/thunkDataActions.ts';
import { dismissContextualHelpCreator } from '../../data/actions/thunks/contextualHelp/dismissContextualHelp.ts';
import { onboardingNotificationTriggeredCreator } from '../../data/actions/thunks/onboardingNotifications/onboardingNotificationTriggered.ts';
import { contentVariantIdStorage } from '../../localStorages/contentVariantStorage.ts';
import { projectIdStorage } from '../../localStorages/projectIdStorage.ts';
import { repositoryCollection } from '../repositories/repositories.ts';
import { projectLocationsLoadingFinished } from './sharedActions.ts';
import { loadAvailableProjectLocationsCreator } from './thunks/loadAvailableProjectLocationsCreator.ts';
import { createLoadBreadcrumbsAction } from './thunks/loadBreadcrumbs.ts';
import { createLoadFallbacksForLinkedContentStatus } from './thunks/loadFallbacksForLinkedContentStatus.ts';
import { createLoadGeneralPropertiesAction } from './thunks/loadGeneralProperties.ts';
import { createLoadProjectFeatureFlags } from './thunks/loadProjectFeatureFlags.ts';
import { createLoadProjectProperties } from './thunks/loadProjectProperties.ts';
import { createLoadSubscriptionProperties } from './thunks/loadSubscriptionProperties.ts';
import { createLoadUserPropertiesAction } from './thunks/loadUserProperties.ts';
import { createProductUpdateLinkOpenedAction } from './thunks/productUpdateLinkOpened.ts';
import { createSendUserFeedbackAction } from './thunks/sendUserFeedback.ts';
import { trackUserEvent } from './thunks/trackUserEvent.ts';
import { createTryEnsuringSelectedVariantAction } from './thunks/tryEnsuringSelectedVariant.ts';
import { createUpdateCurrentProjectAction } from './thunks/updateCurrentProject.ts';
import { updateEmailPreferencesActionCreator } from './thunks/updateEmailPreferences.ts';
import { createUpsertPropertyAction } from './thunks/upsertUserProperty.ts';

const {
  accountRepository,
  generalPropertyRepository,
  projectRepository,
  roleRepository,
  subscriptionRepository,
  userRepository,
  webSpotlightRepository,
} = repositoryCollection;

export const upsertUserProperty = createUpsertPropertyAction({
  accountRepository,
});

export const loadAvailableProjectLocations = loadAvailableProjectLocationsCreator({
  projectRepository,
  projectLocationsLoadingFinished,
});

export const loadProjectProperties = createLoadProjectProperties({
  projectRepository,
});

export const loadProjectFeatureFlags = createLoadProjectFeatureFlags({
  projectRepository,
});

export const loadSubscriptionProperties = createLoadSubscriptionProperties({
  subscriptionRepository,
});

export const loadFallbacksForLinkedContentStatus = createLoadFallbacksForLinkedContentStatus({
  projectRepository,
});

export const loadWebSpotlightConfiguration = loadWebSpotlightConfigurationActionCreator({
  webSpotlightRepository,
});

export const updateCurrentProject = createUpdateCurrentProjectAction({
  projectIdStorage,
  loadProjectProperties,
  loadSubscriptionProperties,
  loadWebSpotlightConfiguration,
  loadAssetType,
  trackUserEvent,
  projectRepository,
  roleRepository,
});

export const updateEmailPreferences = updateEmailPreferencesActionCreator({
  upsertUserProperty,
});

export const loadUserProperties = createLoadUserPropertiesAction({
  accountRepository,
});

export const tryEnsuringSelectedLanguageId = createTryEnsuringSelectedVariantAction({
  loadCollections,
  loadLanguages,
  contentVariantIdStorage,
});

export const loadBreadcrumbs = createLoadBreadcrumbsAction();

export const productUpdateLinkOpened = createProductUpdateLinkOpenedAction({
  trackUserEvent,
  upsertUserProperty,
});

export const dismissContextualHelp = dismissContextualHelpCreator({ upsertUserProperty });

export const onboardingNotificationTriggered = onboardingNotificationTriggeredCreator({
  upsertUserProperty,
});

export const loadGeneralProperties = createLoadGeneralPropertiesAction({
  generalPropertyRepository,
});

export const sendUserFeedback = createSendUserFeedbackAction({
  userRepository,
  trackUserEvent,
});
