import React, { useCallback } from 'react';
import { trackUserEvent } from '../../../../_shared/actions/thunks/trackUserEvent.ts';
import { TrackedEvent } from '../../../../_shared/constants/trackedEvent.ts';
import { useDispatch } from '../../../../_shared/hooks/useDispatch.ts';
import { useSelector } from '../../../../_shared/hooks/useSelector.ts';
import { selectIntercomUtils } from '../../../../_shared/selectors/selectIntercomUtils.ts';
import { IPlan } from '../../../../data/models/plans/Plan.ts';
import { ISubscriptionUsage } from '../../shared/models/SubscriptionUsage.ts';
import { PlanCard as PlanCardComponent } from '../components/PlanCard.tsx';

export type PlanCardProps = {
  readonly action?: ButtonAction;
  readonly plan?: IPlan;
  readonly planDescription: string;
  readonly planPrice: string;
  readonly subscriptionUsage?: ISubscriptionUsage;
  readonly title: string;
};

export type ButtonAction = {
  readonly title: string;
  readonly handler: () => void;
};

export const PlanCard: React.FC<PlanCardProps> = (props: PlanCardProps) => {
  const dispatch = useDispatch();
  const trackPlanDetailsOpened = useCallback(() => {
    dispatch(trackUserEvent(TrackedEvent.PlanDetailsOpened));
  }, []);

  const intercomUtils = useSelector(selectIntercomUtils);

  return (
    <PlanCardComponent
      {...props}
      onContactSupport={intercomUtils.showMessage}
      trackPlanDetailsOpened={trackPlanDetailsOpened}
    />
  );
};

PlanCard.displayName = 'PlanCard';
