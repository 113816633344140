import Immutable from 'immutable';
import { ItemColumnCode } from '../../../../../../_shared/constants/itemColumnCode.ts';
import { OrderBy } from '../../../../../../_shared/models/OrderBy.ts';
import { ScrollTableState } from '../../../models/ScrollTableState.type.ts';
import { IFullTextSearchContext } from '../../../stores/IContentAppStoreState.ts';
import {
  ContentListing_BulkAction_ExecutionTakingTooLong,
  ContentListing_Columns_HideColumn,
  ContentListing_Columns_Init,
  ContentListing_Columns_ShowColumn,
  ContentListing_Filter_Changed,
  ContentListing_FullTextSearchContext_Changed,
  ContentListing_OrderBy_Changed,
  ContentListing_ScrollTableState_Changed,
  ContentListing_SelectAll_Off,
  ContentListing_SelectAll_On,
  ContentListing_StatusInfo_Reset,
} from '../constants/contentItemInventoryActionTypes.ts';

export const listingFilterChanged = () =>
  ({
    type: ContentListing_Filter_Changed,
  }) as const;

export const contentListingOrderByUpdated = (orderBy: OrderBy<ItemColumnCode>) =>
  ({
    type: ContentListing_OrderBy_Changed,
    payload: {
      orderBy,
    },
  }) as const;

export const fullTextSearchContextChanged = (fullTextSearchContext: IFullTextSearchContext) =>
  ({
    type: ContentListing_FullTextSearchContext_Changed,
    payload: {
      fullTextSearchContext,
    },
  }) as const;

export const contentListingScrollTableStateUpdated = (payload: ScrollTableState) =>
  ({
    type: ContentListing_ScrollTableState_Changed,
    payload,
  }) as const;

export const selectAllContentItems = () => ({ type: ContentListing_SelectAll_On }) as const;

export const unselectAllContentItems = () => ({ type: ContentListing_SelectAll_Off }) as const;

export const resetStatusInfo = () => ({ type: ContentListing_StatusInfo_Reset }) as const;

export type BulkActionStartedPayload = {
  readonly operationId: Uuid;
};

export const createBulkActionStarted =
  <TActionType>(actionType: TActionType) =>
  (payload: BulkActionStartedPayload) =>
    ({
      type: actionType,
      payload,
    }) as const;

export const createBulkActionFailed =
  <TActionType>(actionType: TActionType) =>
  (errorMessage: string) =>
    ({
      type: actionType,
      payload: { errorMessage },
    }) as const;

export type BulkActionFinishedPayload = {
  readonly successfulIds: Immutable.Set<Uuid>;
  readonly failedIds: Immutable.Set<Uuid>;
  readonly unmodifiedIds: Immutable.Set<Uuid>;
  readonly variantId: Uuid;
};

export const createBulkActionFinished =
  <TActionType>(actionType: TActionType) =>
  (payload: BulkActionFinishedPayload) =>
    ({
      type: actionType,
      payload,
    }) as const;

export const bulkActionTakingTooLong = () =>
  ({
    type: ContentListing_BulkAction_ExecutionTakingTooLong,
  }) as const;

export const contentListingColumnShown = (column: ItemColumnCode) =>
  ({
    type: ContentListing_Columns_ShowColumn,
    payload: {
      column,
    },
  }) as const;

export const contentListingColumnHidden = (column: ItemColumnCode) =>
  ({
    type: ContentListing_Columns_HideColumn,
    payload: {
      column,
    },
  }) as const;

export const contentListingColumnsInit = (hiddenColumns: Immutable.Set<ItemColumnCode>) =>
  ({
    type: ContentListing_Columns_Init,
    payload: {
      hiddenColumns,
    },
  }) as const;
