import { Button } from '@kontent-ai/component-library/Button';

type Props = Readonly<{
  onClick: () => void;
}>;

export const OpenSpaceDomainSettingsButton = ({ onClick }: Props) => {
  return (
    <Button buttonStyle="primary" onClick={onClick}>
      Set up space domain
    </Button>
  );
};
