import { PreviewError, getPreviewErrorMessage } from '../../../../../utils/previewUtils.ts';
import { ErrorPreviewNotAvailableTitle } from '../../../constants/uiConstants.ts';
import { PreviewErrorState } from './PreviewErrorState.tsx';

export const PreviewLoadError = () => {
  return (
    <PreviewErrorState actionButton={null} title={ErrorPreviewNotAvailableTitle}>
      {getPreviewErrorMessage(PreviewError.LoadFailed)}
    </PreviewErrorState>
  );
};
