import { useHover } from '@react-aria/interactions';
import { mergeProps } from '@react-aria/utils';
import React, { useState, useId } from 'react';
import styled from 'styled-components';
import { ColorCircle } from '../../../containers/ColorCircle/ColorCircle.tsx';
import { useOurFocusRing } from '../../../hooks/useOurFocusRing.ts';
import { SrOnly } from '../../../styles/srOnly.tsx';
import { borderWidthDefault } from '../../../tokens/decision/border.ts';
import { colorDividerDefault } from '../../../tokens/decision/colors.ts';
import { BorderRadius } from '../../../tokens/quarks/border.ts';
import { DarkGradient, GradientAngle, GradientFunction } from '../../../tokens/quarks/gradients.ts';
import { BoxShadow } from '../../../tokens/quarks/shadow.ts';
import { px } from '../../../tokens/utils/utils.ts';
import { Placement } from '../../../types/placement.ts';
import { Tooltip } from '../../Tooltip/Tooltip.tsx';
import { disabledAriaTippyOptions } from '../../Tooltip/constants.ts';
import { AvatarSize } from '../avatarSize.ts';
import { Initials } from './Initials.tsx';

export interface IBaseAvatarProps {
  readonly backgroundGradient: GradientFunction;
  readonly boxShadow?: BoxShadow;
  readonly image?: string;
  readonly initials: string;
  readonly label: string;
  readonly size: AvatarSize;
  readonly tooltipPlacement?: Placement;
}

interface IInjectedProps {
  readonly children: JSX.Element;
  readonly 'aria-describedby': string | undefined;
  readonly isFocusVisible: boolean;
}

export interface IBaseAvatarComponentProps extends IBaseAvatarProps {
  readonly renderContainer: (props: IInjectedProps) => JSX.Element;
  readonly tooltipText?: string;
}

// We offset the image to hide antialiasing artifacts between the image and underlying initials
const imageOffset = 1;

const Image = styled.img<{ readonly $isVisible: boolean; readonly $size: number }>`
  position: absolute;
  z-index: 1;
  display: block;
  left: ${px(-imageOffset)};
  top: ${px(-imageOffset)};
  width: ${({ $size }) => px($size + 2 * imageOffset)};
  height: ${({ $size }) => px($size + 2 * imageOffset)};
  border: ${px(borderWidthDefault)} solid ${colorDividerDefault};
  border-radius: ${px(BorderRadius.Pill)};
  overflow: hidden;
  opacity: ${({ $isVisible }) => ($isVisible ? 1 : 0)};
  pointer-events: none;
`;

export const BaseAvatarComponent: React.FC<IBaseAvatarComponentProps> = ({
  backgroundGradient,
  image,
  initials,
  label,
  renderContainer,
  size,
  tooltipPlacement = 'bottom',
  tooltipText,
}) => {
  const { isFocusVisible, focusProps } = useOurFocusRing();
  const { isHovered, hoverProps } = useHover({});
  const shouldShowTooltip = isFocusVisible || isHovered;
  const tooltipId = useId();

  const [showImage, setShowImage] = useState(!!image);
  return (
    <Tooltip
      placement={tooltipPlacement}
      tippyOptions={disabledAriaTippyOptions}
      tooltipText={tooltipText}
      tooltipTextId={tooltipId}
      visible={shouldShowTooltip}
    >
      {renderContainer({
        children: (
          <ColorCircle
            css="position: relative"
            color={
              backgroundGradient(GradientAngle.ToBottom) ??
              DarkGradient.GrayDark(GradientAngle.ToBottom)
            }
            size={size}
          >
            {image && (
              <Image
                $isVisible={showImage}
                $size={size}
                src={image}
                alt={label}
                onLoad={() => setShowImage(true)}
                onError={() => setShowImage(false)}
                aria-hidden
              />
            )}
            <Initials $size={size} aria-hidden>
              {size === AvatarSize.XS ? initials[0] : initials}
            </Initials>
            <SrOnly>{label}</SrOnly>
          </ColorCircle>
        ),
        isFocusVisible,
        'aria-describedby': shouldShowTooltip ? tooltipId : undefined,
        ...mergeProps(focusProps, hoverProps),
      })}
    </Tooltip>
  );
};

BaseAvatarComponent.displayName = 'BaseAvatarComponent';
