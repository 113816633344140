import { Box } from '@kontent-ai/component-library/Box';
import { Spacing } from '@kontent-ai/component-library/tokens';
import { InvariantException } from '@kontent-ai/errors';
import React, { ReactNode, forwardRef } from 'react';
import { useHistory } from 'react-router';
import { ConfirmationDialog } from '../../../../../../_shared/components/ModalDialog/ConfirmationDialog.tsx';
import { IAnimatedModalDialogProps } from '../../../../../../_shared/components/ModalDialog/IAnimatedModalDialogProps.type.ts';
import { Warning } from '../../../../../../_shared/components/infos/Warning.tsx';
import { DeleteContentItem } from '../../../../../../_shared/constants/itemActions.ts';
import {
  ContentItemRoute,
  ContentItemRouteParams,
  ContentItemsAppRouteSegment,
  ContentItemsRoute,
  ContentItemsRouteParams,
  RootRoute,
} from '../../../../../../_shared/constants/routePaths.ts';
import { ContentItemUsagesLinks } from '../../../../../../_shared/containers/ContentItemUsagesLinks/ContentItemUsagesLinks.tsx';
import { useDispatch } from '../../../../../../_shared/hooks/useDispatch.ts';
import { useSelector } from '../../../../../../_shared/hooks/useSelector.ts';
import { getSelectedLanguageIdOrThrow } from '../../../../../../_shared/selectors/getSelectedLanguageId.ts';
import { isProjectMultilingual } from '../../../../../../_shared/selectors/isProjectMultilingual.ts';
import { getLanguageName } from '../../../../../../_shared/utils/languageSwitchers/languageSwitcherUtils.ts';
import {
  buildPath,
  matchPath,
  parseContentItemIds,
} from '../../../../../../_shared/utils/routing/routeTransitionUtils.ts';
import { ILanguagesData } from '../../../../../../data/reducers/languages/ILanguagesData.type.ts';
import { getAllLanguagesWithDefaultSuffix } from '../../../../../../data/reducers/languages/selectors/getLanguages.ts';
import { ContentItemUsages } from '../../../../../../data/reducers/listingContentItems/IListingContentItems.type.ts';
import { CancelSchedulePublishFriendlyWarning } from '../../../../../contentInventory/content/features/ContentItemInventory/components/CancelSchedulePublishFriendlyWarning.tsx';
import { IContentAppStoreState } from '../../../../../contentInventory/content/stores/IContentAppStoreState.ts';
import { itemEditingModalDismissed } from '../../../../actions/contentActions.ts';
import { IContentItemVariantReference } from '../../../../models/contentItem/ContentItemVariantReference.ts';
import { getNotEmptyItemName } from '../../../../stores/utils/contentItemHelperMethods.ts';
import { archiveEditedContentItemVariant } from '../../actions/thunkContentItemEditingActions.ts';

type ItemUsagesWarningProps = {
  readonly itemId: Uuid;
  readonly contentItemUsages: ContentItemUsages;
};

const ItemUsagesWarning: React.FC<ItemUsagesWarningProps> = ({ itemId, contentItemUsages }) => {
  if (!contentItemUsages.get(itemId)?.size) {
    return null;
  }

  return (
    <Box marginTop={Spacing.M}>
      <Warning>
        Deleting this item may cause broken links because it’s used in other content{' '}
        {contentItemUsages.get(itemId)?.size || 0 > 1 ? 'items' : 'item'}:
        <ContentItemUsagesLinks contentItemId={itemId} />
      </Warning>
    </Box>
  );
};

const getRedirectRoute = (currentPath: string): string => {
  const match = matchPath<ContentItemRouteParams<string>>(currentPath, ContentItemRoute);

  if (!match) {
    return RootRoute;
  }

  const updatedContentItemIds = parseContentItemIds(match.contentItemIds).slice(0, -1);

  if (match.app === ContentItemsAppRouteSegment.WebSpotlight && updatedContentItemIds.length > 0) {
    return buildPath<ContentItemRouteParams<UuidArray>>(ContentItemRoute, {
      app: ContentItemsAppRouteSegment.WebSpotlight,
      projectId: match.projectId,
      variantId: match.variantId,
      spaceId: match.spaceId,
      contentItemIds: updatedContentItemIds,
    });
  }

  return buildPath<ContentItemsRouteParams>(ContentItemsRoute, {
    app: match.app,
    projectId: match.projectId,
    variantId: match.variantId,
    spaceId: match.spaceId,
  });
};

const getMultiLanguageDialogMessage = (languageName: string, itemName: string): ReactNode => (
  <>
    <p className="confirmation-dialog__section">
      This will delete the content item in <strong>{languageName}</strong>. Content in other
      languages will stay intact.
    </p>
    <p className="confirmation-dialog__section">
      Do you want to delete the content item <strong>{itemName}</strong> in{' '}
      <strong>{languageName}</strong>?
    </p>
  </>
);

const getMultiLanguageWithSingleTranslatedVariantDialogMessage = (itemName: string): ReactNode => (
  <p className="confirmation-dialog__section">
    Do you want to delete the content item <strong>{itemName}</strong> and all its content?
  </p>
);

const getSingleLanguageDialogMessage = (itemName: string): ReactNode => (
  <p className="confirmation-dialog__section">
    Do you want to (permanently) delete the content item <strong>{itemName}</strong> and all its
    content?
  </p>
);

type ContentItemDeleteConfirmationDialogMessageProps = {
  readonly contentItemVariants: IContentAppStoreState['contentItemVariants'];
  readonly editedContentItem: IContentAppStoreState['editedContentItem'];
  readonly isMultilingualProject: boolean;
  readonly languages: ILanguagesData;
  readonly selectedLanguageId: Uuid;
};

const ContentItemDeleteConfirmationDialogMessage: React.FC<
  React.PropsWithChildren<ContentItemDeleteConfirmationDialogMessageProps>
> = ({
  contentItemVariants,
  editedContentItem,
  isMultilingualProject,
  languages,
  selectedLanguageId,
}) => {
  if (!editedContentItem) {
    throw InvariantException(
      '"ContentItemDeleteConfirmationDialog.tsx": editedContentItem is falsy',
    );
  }

  const itemName = getNotEmptyItemName(editedContentItem.name);
  if (!isMultilingualProject) {
    return <>{getSingleLanguageDialogMessage(itemName)}</>;
  }

  const languageName = getLanguageName(
    selectedLanguageId,
    getAllLanguagesWithDefaultSuffix(languages),
  );
  const activeVariantsOtherThanCurrent = contentItemVariants
    .filter((variant: IContentItemVariantReference) => variant.id.variantId !== selectedLanguageId)
    .filter((variant: IContentItemVariantReference) => !variant.isArchived);
  const isLastActiveVariant = activeVariantsOtherThanCurrent.count() === 0;

  return (
    <>
      {isLastActiveVariant
        ? getMultiLanguageWithSingleTranslatedVariantDialogMessage(itemName)
        : getMultiLanguageDialogMessage(languageName, itemName)}
    </>
  );
};

export const ContentItemDeleteConfirmationDialog = forwardRef<
  HTMLDivElement,
  IAnimatedModalDialogProps
>((props, ref) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const contentItemUsages = useSelector((s) => s.data.listingContentItems.contentItemUsages);
  const editedContentItem = useSelector((s) => s.contentApp.editedContentItem);
  const contentItemVariants = useSelector((s) => s.contentApp.contentItemVariants);
  const languages = useSelector((s) => s.data.languages);
  const selectedLanguageId = useSelector((s) => getSelectedLanguageIdOrThrow(s));
  const isMultilingualProject = useSelector(isProjectMultilingual);

  return (
    <ConfirmationDialog
      alert
      headerContent={DeleteContentItem}
      confirmButtonText={DeleteContentItem}
      onConfirm={async () => {
        await dispatch(archiveEditedContentItemVariant());
        history.push(getRedirectRoute(history.location.pathname));
      }}
      onClose={() => dispatch(itemEditingModalDismissed())}
      ref={ref}
      {...props}
    >
      <ContentItemDeleteConfirmationDialogMessage
        selectedLanguageId={selectedLanguageId}
        editedContentItem={editedContentItem}
        isMultilingualProject={isMultilingualProject}
        contentItemVariants={contentItemVariants}
        languages={languages}
      />
      <ItemUsagesWarning
        itemId={editedContentItem?.id ?? ''}
        contentItemUsages={contentItemUsages}
      />
      <CancelSchedulePublishFriendlyWarning />
    </ConfirmationDialog>
  );
});

ContentItemDeleteConfirmationDialog.displayName = 'ContentItemDeleteConfirmationDialog';
