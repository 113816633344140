import { InvariantException } from '@kontent-ai/errors';
import { Dispatch, ThunkPromise } from '../../../../../@types/Dispatcher.type.ts';
import { TrackedEvent } from '../../../../../_shared/constants/trackedEvent.ts';
import { TrackUserEventAction } from '../../../../../_shared/models/TrackUserEvent.type.ts';
import { EnvironmentEventType } from '../../../../../_shared/models/TrackUserEventData.ts';
import { IProjectRepository } from '../../../../../repositories/interfaces/IProjectRepository.type.ts';
import { startPollingCloningState } from '../../../../projects/actions/thunks/cloneProject.ts';
import { ProjectStatusPollingInterval } from '../../../../projects/constants/copyState.ts';
import { waitUntilProjectIsActive } from '../../../../projects/utils/projectUtils.ts';
import { ILoadSubscriptionUsage } from '../../../../subscriptionManagement/shared/types/ILoadSubscriptionUsage.type.ts';
import {
  Environment_CreateEnvironment_Failed,
  Environment_CreateEnvironment_Finished,
  Environment_CreateEnvironment_Initialized,
  Environment_CreateEnvironment_Started,
} from '../../constants/environmentActionTypes.ts';
import { CopyDataOption } from '../../types/copyDataOption.ts';

interface ICreateEnvironmentCreatorDependencies {
  readonly loadUserProjectsInfo: () => ThunkPromise;
  readonly loadProjects: () => ThunkPromise;
  readonly loadSubscriptionUsage: ILoadSubscriptionUsage;
  readonly projectRepository: IProjectRepository;
  readonly trackUserEvent: TrackUserEventAction;
}

const start = () =>
  ({
    type: Environment_CreateEnvironment_Started,
  }) as const;

const initialize = (projectId: Uuid) =>
  ({
    type: Environment_CreateEnvironment_Initialized,
    payload: { projectId },
  }) as const;

const fail = () =>
  ({
    type: Environment_CreateEnvironment_Failed,
  }) as const;

const finish = (projectId: Uuid) =>
  ({
    type: Environment_CreateEnvironment_Finished,
    payload: { projectId },
  }) as const;

export type CreateEnvironmentActionsType = ReturnType<
  typeof start | typeof initialize | typeof fail | typeof finish
>;

export const createEnvironmentCreator =
  (deps: ICreateEnvironmentCreatorDependencies) =>
  (
    sourceEnvironmentId: Uuid,
    name: string,
    rolesToActivate: ReadonlyArray<Uuid>,
    copyDataOptions: ReadonlyArray<CopyDataOption>,
  ): ThunkPromise =>
  async (dispatch: Dispatch): Promise<void> => {
    dispatch(start());
    try {
      const environment = await deps.projectRepository.createEnvironment(sourceEnvironmentId, {
        environmentName: name,
        rolesToActivate,
        copyDataOptions: {
          copyContent: copyDataOptions.includes(CopyDataOption.CopyContent),
          copyTimelines: copyDataOptions.includes(CopyDataOption.CopyTimelines),
        },
      });
      const projectId = environment.projectGuid;
      dispatch(initialize(projectId));

      await waitUntilProjectIsActive(projectId, deps.projectRepository);

      await Promise.all([
        dispatch(deps.loadUserProjectsInfo()),
        dispatch(deps.loadProjects()),
        dispatch(deps.loadSubscriptionUsage(environment.subscriptionId)),
      ]);
      dispatch(
        startPollingCloningState(
          deps.projectRepository,
          environment.projectGuid,
          ProjectStatusPollingInterval,
          deps.loadProjects,
        ),
      );
      dispatch(finish(projectId));

      dispatch(
        deps.trackUserEvent(TrackedEvent.Environment, {
          type: EnvironmentEventType.Create,
          environmentId: projectId,
        }),
      );
    } catch (error) {
      dispatch(fail());
      throw InvariantException(
        `Could not create an environment because of the following error: ${JSON.stringify(error)}`,
      );
    }
  };
