import React from 'react';
import { useSelector } from '../../../../../../_shared/hooks/useSelector.ts';
import { isWebSpotlightIntegrationEnabled } from '../../../../../../_shared/selectors/isWebSpotlightIntegrationEnabled.tsx';
import { IStore } from '../../../../../../_shared/stores/IStore.type.ts';
import { isSampleProject } from '../../../../../../_shared/utils/sampleItemsUtils.ts';
import { urlStartsWithHttp, urlStartsWithHttps } from '../../../../../../_shared/utils/urlUtils.ts';
import {
  getCurrentProject,
  isProjectTrial,
} from '../../../../../../data/reducers/user/selectors/userProjectsInfoSelectors.ts';
import { PreviewError } from '../../../../utils/previewUtils.ts';
import { PreviewContentItemInfo } from '../../../../utils/selectDeepestItemWithPreview.ts';
import { PreviewErrors, ProjectType } from '../../components/contentItemPreview/PreviewErrors.tsx';
import { WebSpotlightPreviewSandboxInItemEditing } from '../../components/contentItemPreview/WebSpotlightPreviewSandboxInItemEditing.tsx';
import {
  PreviewMetadata,
  useWebSpotlightInItemEditing,
} from '../../context/WebSpotlightInItemEditingContext.tsx';
import { isDemoSamplePreviewUrl } from '../../utils/isDemoSamplePreviewUrl.ts';
import { getPreviewUrlPattern } from '../selectors/getPreviewUrlPattern.ts';

type Props = Readonly<{
  previewContentItemInfo: PreviewContentItemInfo | null;
  sharedPreviewUrl: string | null;
  spaceId: Uuid | null;
}>;

export const WebSpotlightPreviewInItemEditingContent: React.FC<Props> = ({
  previewContentItemInfo,
  sharedPreviewUrl,
  spaceId,
}) => {
  const { previewMetadata } = useWebSpotlightInItemEditing();
  const previewUrlPattern = useSelector(getPreviewUrlPattern);
  const previewError = getNormalizedPreviewError(
    previewUrlPattern,
    previewMetadata,
    sharedPreviewUrl,
  );
  const projectType = useSelector(getProjectType);
  const enabledWSLIntegration = useSelector(isWebSpotlightIntegrationEnabled);

  return previewError ? (
    <PreviewErrors
      hideDocumentationLink={!!sharedPreviewUrl}
      previewContentItemInfo={previewContentItemInfo}
      previewError={previewError}
      projectType={projectType}
      spaceId={spaceId}
    />
  ) : (
    <WebSpotlightPreviewSandboxInItemEditing isResizable={enabledWSLIntegration} />
  );
};

const getProjectType = (state: IStore): ProjectType => {
  const currentProject = getCurrentProject(state);

  if (isSampleProject(currentProject)) {
    return ProjectType.sample;
  }

  if (isProjectTrial(state, currentProject.projectId)) {
    return ProjectType.trial;
  }

  return ProjectType.general;
};

const sharedPreviewUrlExcludedErrors: ReadonlyArray<PreviewError> = [
  PreviewError.MissingUrlSlugValue,
  PreviewError.MissingUrlSlugElement,
];

const getNormalizedPreviewError = (
  previewUrlPattern: string | undefined,
  previewMetadata: PreviewMetadata,
  sharedPreviewUrl: string | null,
): PreviewError | null => {
  const { error: previewError, url: previewUrl } = previewMetadata;

  const isExcludedError =
    sharedPreviewUrl && previewError && sharedPreviewUrlExcludedErrors.includes(previewError);
  if (previewError && !isExcludedError) {
    return previewError;
  }

  if (isDemoSamplePreviewUrl(previewUrlPattern) || (!sharedPreviewUrl && !previewUrl)) {
    return PreviewError.NoPreview;
  }

  const previewUrlStartsWithHttp = sharedPreviewUrl
    ? urlStartsWithHttp(sharedPreviewUrl)
    : urlStartsWithHttp(previewUrl);

  if (previewUrlStartsWithHttp) {
    return PreviewError.HttpInIFrame;
  }

  const selectedPreviewUrlStartsWithHttps = sharedPreviewUrl
    ? urlStartsWithHttps(sharedPreviewUrl)
    : urlStartsWithHttps(previewUrl);

  if (urlStartsWithHttps(document.location.href) && !selectedPreviewUrlStartsWithHttps) {
    return PreviewError.NotHttps;
  }

  return null;
};
