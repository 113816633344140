import { ErrorPreviewNotAvailableTitle } from '../../../constants/uiConstants.ts';
import { OpenPreviewUrlSettingsButton } from '../../../containers/contentItemPreview/previewEmptyStates/OpenPreviewUrlSettingsButton.tsx';
import { PreviewEmptyStateDocumentationMessageLink } from './PreviewEmptyStateDocumentationMessageLink.tsx';
import { PreviewErrorState } from './PreviewErrorState.tsx';

export const NoPreview = () => {
  return (
    <PreviewErrorState
      actionButton={<OpenPreviewUrlSettingsButton />}
      title={ErrorPreviewNotAvailableTitle}
    >
      <PreviewEmptyStateDocumentationMessageLink />
    </PreviewErrorState>
  );
};
