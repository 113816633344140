export enum WorkflowRoleLimitation {
  RoleLimitationsRemoved = 'role-limitations-removed',
  RoleLimitationsSet = 'role-limitations-set',
  RoleLimitationsForPublish = 'publish-role-limitations-set',
  SameRolesForUnpublishAndCreateNew = 'same-roles-for-unpublish-and-create-new-set',
  DifferentRolesForUnpublishAndCreateNew = 'different-roles-for-unpublish-and-create-new-set',
}

export enum WorkflowScopeChange {
  ContentTypesAdded = 'content-types-added',
  ContentTypesRemoved = 'content-types-removed',
  CollectionsAdded = 'collections-added',
  CollectionsRemoved = 'collections-removed',
}

export enum WorkflowStepChange {
  ArchivedStepChanged = 'archived-step-changed',
  HasAnyRegularTransitionChanged = 'has-any-regular-transition-changed',
  PublishStepChanged = 'publish-step-changed',
  RegularStepAdded = 'regular-step-added',
  RegularStepChanged = 'regular-step-changed',
  RegularStepRemoved = 'regular-step-removed',
}

type WorkflowBaseEventData = {
  readonly 'regular-steps-count': number;
  readonly workflowId: Uuid;
  readonly 'workflows-count': number;
};

type WorkflowScopeEventData = {
  readonly 'scopes-count': number;
  readonly 'scopes-changes': ReadonlyArray<WorkflowScopeChange>;
};

export type DeleteWorkflowEventData = WorkflowBaseEventData;

export type RestoreWorkflowEventData = WorkflowBaseEventData;

export type CreateWorkflowEventData = WorkflowBaseEventData & WorkflowScopeEventData;

export type UpdateWorkflowEventData = WorkflowBaseEventData &
  WorkflowScopeEventData & {
    readonly limitations: ReadonlyArray<WorkflowRoleLimitation>;
    readonly 'regular-steps-delta': number;
    readonly 'scopes-delta': number;
    readonly 'step-changes': ReadonlyArray<WorkflowStepChange>;
  };

export type DocsVisitManageWorkflowEventData = {
  readonly afterCreatingSevenOrMoreSteps: boolean;
};
