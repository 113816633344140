import React from 'react';
import { IUserInfo } from '../../../../../../../_shared/models/UserInfo.ts';
import {
  formatCurrentUserName,
  userLastNameComparer,
} from '../../../../../../../_shared/utils/users/usersUtils.ts';

export interface IContributorsListProps {
  readonly contributors: ReadonlySet<IUserInfo>;
  readonly currentUserId: UserId;
}

export const ContributorsList: React.FC<IContributorsListProps> = ({
  contributors,
  currentUserId,
}) => (
  <ul className="content-item-toolbar__contributors-list">
    {[...contributors].sort(userLastNameComparer).map(
      (contributor) =>
        contributor && (
          <li className="content-item-toolbar__contributor" key={contributor.userId}>
            {formatCurrentUserName(currentUserId, false)(contributor)}
          </li>
        ),
    )}
  </ul>
);

ContributorsList.displayName = 'ContributorsList';
