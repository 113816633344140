import { assert } from '@kontent-ai/utils';
import { Dispatch, GetState, ThunkFunction } from '../../../../../../@types/Dispatcher.type.ts';
import { setInitialChangeWorkflowStepModalData } from '../../../../../../_shared/features/ChangeWorkflowStepModal/actions/changeWorkflowStepModalActions.ts';
import { createInitialChangeWorkflowStepModalDataForItemEditing } from '../../../../../../_shared/features/ChangeWorkflowStepModal/selectors/createInitialChangeWorkflowStepModalDataForItemEditing.ts';
import { OpenWorkflowStepModalAction } from '../../../../../../_shared/features/ChangeWorkflowStepModal/types/workflowStepModalActionTypes.ts';
import { ContentItemEditingEventOrigins } from '../../../../../../_shared/models/events/ContentItemEditingEventData.type.ts';
import { itemEditingModalWithPropertiesOpened } from '../../../../actions/contentActions.ts';
import { ItemEditingModalDialogType } from '../../../../constants/itemEditingModalDialogType.ts';

export type IOpenChangeWorkflowStepModal = (
  workflowAction: OpenWorkflowStepModalAction,
  actionOrigin: ContentItemEditingEventOrigins,
) => ThunkFunction;

export const openChangeWorkflowStepModal =
  (
    workflowAction: OpenWorkflowStepModalAction,
    actionOrigin: ContentItemEditingEventOrigins,
  ): ThunkFunction =>
  (dispatch: Dispatch, getState: GetState) => {
    const initialModalData = createInitialChangeWorkflowStepModalDataForItemEditing(
      getState(),
      workflowAction,
    );

    assert(
      initialModalData,
      () => `${__filename}: Failed to create initial change workflow step modal data.`,
    );

    dispatch(setInitialChangeWorkflowStepModalData(initialModalData));
    dispatch(
      itemEditingModalWithPropertiesOpened({
        dialogType: ItemEditingModalDialogType.ChangeWorkflowStepDialog,
        actionOrigin,
      }),
    );
  };
