import { Collection } from '@kontent-ai/utils';
import { ThunkPromise } from '../../../../../@types/Dispatcher.type.ts';
import { DateRange } from '../../../../../_shared/models/DateRange.type.ts';
import { IProjectSpecificStorage } from '../../../../../localStorages/projectSpecificStorage.ts';
import { TrackFilterChange } from '../../../../contentInventory/content/features/ContentItemInventory/actions/thunks/loadListingItems/trackFilterChange.ts';
import { IListingFilter } from '../../../../contentInventory/content/models/filter/IListingFilter.ts';

interface Deps {
  readonly ContentItemFilterStorage: IProjectSpecificStorage<IListingFilter> | null;
  readonly loadItemsMatchingFilterWithVariantActiveInPeriod: (period: DateRange) => ThunkPromise;
  readonly trackFilterChange: TrackFilterChange;
}

export const createFilterCalendarAction =
  ({
    ContentItemFilterStorage,
    loadItemsMatchingFilterWithVariantActiveInPeriod,
    trackFilterChange,
  }: Deps) =>
  (): ThunkPromise =>
  async (dispatch, getState) => {
    const state = getState();
    const {
      data: { workflows },
      contentApp: {
        listingUi: { filter, savedFiltersUi },
      },
      calendarApp: { selectedPeriod },
      sharedApp: { currentProjectId },
    } = state;

    const previousFilter = savedFiltersUi.lastFilteredUsingSavedFilter
      ? null
      : (ContentItemFilterStorage?.load(currentProjectId) ?? null);

    dispatch(trackFilterChange(previousFilter, filter, Collection.getValues(workflows.byId)));

    ContentItemFilterStorage?.save(filter, currentProjectId);

    await dispatch(loadItemsMatchingFilterWithVariantActiveInPeriod(selectedPeriod));
  };
