import { Collection } from '@kontent-ai/utils';
import { IStore } from '../../../../_shared/stores/IStore.type.ts';
import { isEmptyOrWhitespace } from '../../../../_shared/utils/stringUtils.ts';
import { AiSearchFilterServerModel } from '../../../../paperModels/aiSearch/repository/serverModels/AiSearchFilterServerModel.type.ts';
import { ContentItemFilterServerModel } from '../../../../repositories/serverModels/ContentItemFilterWithContinuationServerModel.ts';
import { ContentItemListingOperationQueryModel } from '../models/filter/ContentItemListOperationQueryModel.type.ts';
import { IListingFilter } from '../models/filter/IListingFilter.ts';
import {
  getItemCompletionStatus,
  getPublishingState,
  getTaxonomyFilterServerModel,
  getWorkflowsFilterSeverModel,
} from '../models/filter/contentItemFilterUtils.ts';
import { getSelectedItems } from '../reducers/listingUi/itemsSelection/selectors/itemsSelectionSelectors.ts';

export const createContentItemFilterServerModelFromFilter = (
  filter: IListingFilter,
): ContentItemFilterServerModel => {
  const result: ContentItemFilterServerModel = {};
  if (filter.selectedContributorsNodes && filter.selectedContributorsNodes.size > 0) {
    result.assignees = [...filter.selectedContributorsNodes];
  }
  const searchPhrase = filter.searchPhrase.trim();
  if (searchPhrase) {
    result.searchParameters = {
      phrase: searchPhrase,
      scope: Array.from(filter.searchScope),
    };
  }
  if (filter.selectedSitemapNodes && filter.selectedSitemapNodes.size > 0) {
    result.sitemapLocations = [...filter.selectedSitemapNodes];
  }
  if (filter.selectedCollectionsNodes.size > 0) {
    result.collectionIds = [...filter.selectedCollectionsNodes];
  }
  if (filter.selectedSpacesNodes.size > 0) {
    result.spaceIds = [...filter.selectedSpacesNodes];
  }
  if (filter.selectedContentTypesNodes.size > 0) {
    result.typeIds = [...filter.selectedContentTypesNodes];
  }

  result.publishingStates = getPublishingState(filter.selectedPublishingStateNodes);
  result.itemCompletionStatuses = getItemCompletionStatus(filter.selectedContentItemStatus);
  result.taxonomiesByGroupId = getTaxonomyFilterServerModel(filter.selectedTaxonomyNodes);
  result.workflows = getWorkflowsFilterSeverModel(filter.selectedWorkflowNodes);

  return result;
};

export const createAiSearchFilter = (filter: IListingFilter): AiSearchFilterServerModel => ({
  assigneeUserIds: filter.selectedContributorsNodes.size
    ? Collection.getValues(filter.selectedContributorsNodes)
    : undefined,
  collectionIds: filter.selectedCollectionsNodes.size
    ? Collection.getValues(filter.selectedCollectionsNodes)
    : undefined,
  contentTypeIds: filter.selectedContentTypesNodes.size
    ? Collection.getValues(filter.selectedContentTypesNodes)
    : undefined,
  itemCompletionStatuses: getItemCompletionStatus(filter.selectedContentItemStatus),
  publishingStates: getPublishingState(filter.selectedPublishingStateNodes),
  searchPhrase: filter.searchPhrase,
  spaceIds: filter.selectedSpacesNodes.size
    ? Collection.getValues(filter.selectedSpacesNodes)
    : undefined,
  taxonomiesByGroupId: getTaxonomyFilterServerModel(filter.selectedTaxonomyNodes),
  stepIdsByWorkflowId: getWorkflowsFilterSeverModel(filter.selectedWorkflowNodes),
});

export const createGetItemListingOperationQueryFromFilter =
  (deps: {
    readonly getSelectedItems: (state: IStore) => ReadonlySet<Uuid>;
  }) =>
  (state: IStore): ContentItemListingOperationQueryModel => {
    const {
      contentApp: {
        listingUi: {
          itemsSelection: { selectAll, unselectedItemIds, selectedItemIds },
          filter,
        },
      },
    } = state;

    // if user explicitly selects some items, we ignore the rest of the filter
    if (!selectAll && selectedItemIds.length) {
      return { includedItemIds: selectedItemIds };
    }

    // If full-text is used, bulk operations need to be performed for specific IDs
    if (selectAll && !isEmptyOrWhitespace(filter.searchPhrase)) {
      const materializedSelectedItemIds = deps.getSelectedItems(state);
      return { includedItemIds: Collection.getValues(materializedSelectedItemIds) };
    }

    const result: ContentItemListingOperationQueryModel =
      createContentItemFilterServerModelFromFilter(filter);

    if (selectAll) {
      result.excludedItemIds = unselectedItemIds;
    }
    return result;
  };

export const getItemListingOperationQueryFromFilter = createGetItemListingOperationQueryFromFilter({
  getSelectedItems,
});
