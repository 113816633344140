import { Box } from '@kontent-ai/component-library/Box';
import { SingleSelect } from '@kontent-ai/component-library/SingleSelect';
import { gridUnit } from '@kontent-ai/component-library/tokens';
import React from 'react';
import {
  DataUiElement,
  getDataUiElementAttribute,
} from '../../../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';

type SpaceMenuItem = {
  id: string;
  label: string;
};

type Props = {
  readonly selectedSpaceId: Uuid | null;
  readonly onSelectionChange: (spaceId: Uuid | null) => void;
  readonly spaceMenuItems: ReadonlyArray<SpaceMenuItem>;
};

const spaceSelectorMaxWidth = 33 * gridUnit;

export const SpaceSelector: React.FC<Props> = ({
  onSelectionChange,
  selectedSpaceId,
  spaceMenuItems,
}) => (
  <Box maxWidth={spaceSelectorMaxWidth}>
    <SingleSelect
      aria-label="Select space"
      items={spaceMenuItems}
      onSelectionChange={onSelectionChange}
      selectedItemId={selectedSpaceId}
      {...getDataUiElementAttribute(DataUiElement.SpaceSelector)}
    />
  </Box>
);
