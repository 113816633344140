import { OutwardLink } from '@kontent-ai/component-library/Anchor';
import { Tooltip } from '@kontent-ai/component-library/Tooltip';
import React, { useCallback } from 'react';
import { AssetDownloadButton } from '../../../../../_shared/components/AssetTile/AssetDownloadButton.tsx';
import { AssetUncategorizedLabel } from '../../../../../_shared/components/AssetTile/AssetUncategorizedLabel.tsx';
import { FailedAssetUploadActions } from '../../../../../_shared/components/AssetTile/FailedAssetUploadActions.tsx';
import { IconName } from '../../../../../_shared/constants/iconEnumGenerated.ts';
import { useDispatch } from '../../../../../_shared/hooks/useDispatch.ts';
import { TrackUserEvent } from '../../../../../_shared/models/TrackUserEvent.type.ts';
import { Icon } from '../../../../../_shared/uiComponents/Icon/Icon.tsx';
import { IImageTransformation } from '../../../../../data/models/assetRenditions/AssetRendition.ts';
import { IAsset } from '../../../../../data/models/assets/Asset.ts';
import { removeFailedAsset } from '../../actions/assetLibraryActions.ts';

export const AssetThumbnailActionsPaneClassname = 'asset-thumbnail__actions-pane';

interface IAssetTileActionsProps {
  readonly asset: IAsset;
  readonly isUncategorized: boolean | null;
  readonly trackUserEvent?: TrackUserEvent;
  readonly showViewAction?: boolean;
  readonly transformation?: IImageTransformation;
}

export const AssetTileActions: React.FC<IAssetTileActionsProps> = ({
  asset,
  isUncategorized,
  showViewAction,
  transformation,
  trackUserEvent,
}) => {
  const dispatch = useDispatch();
  const assetId = asset.id;
  const onRemove = useCallback(() => dispatch(removeFailedAsset(assetId)), [assetId]);

  if (asset._failed) {
    return <FailedAssetUploadActions onRemove={onRemove} />;
  }

  if (asset._uploading) {
    return null;
  }

  return (
    <div className={AssetThumbnailActionsPaneClassname}>
      {isUncategorized && (
        <div className="asset-thumbnail__actions-left">
          <AssetUncategorizedLabel />
        </div>
      )}
      {showViewAction && asset._link ? (
        <Tooltip tooltipText="Open in new tab" placement="bottom">
          <OutwardLink href={asset._link} className="asset-thumbnail__action">
            <Icon iconName={IconName.ArrowRightTopSquare} />
          </OutwardLink>
        </Tooltip>
      ) : undefined}

      <AssetDownloadButton
        asset={asset}
        trackUserEvent={trackUserEvent}
        transformation={transformation}
      />
    </div>
  );
};

AssetTileActions.displayName = 'AssetTileActions';
