import { InvariantException } from '@kontent-ai/errors';
import { Collection } from '@kontent-ai/utils';
import Immutable from 'immutable';
import { Dispatch, ThunkPromise } from '../../../../../@types/Dispatcher.type.ts';
import { TrackedEvent } from '../../../../../_shared/constants/trackedEvent.ts';
import { TrackUserEventAction } from '../../../../../_shared/models/TrackUserEvent.type.ts';
import { AssetBulkActionEventTypes } from '../../../../../_shared/models/TrackUserEventData.ts';
import { IStore } from '../../../../../_shared/stores/IStore.type.ts';
import { AssetsRequestTrigger } from '../../../../../_shared/utils/scrollGridUtils.ts';
import { IAsset } from '../../../../../data/models/assets/Asset.ts';
import { IAssetService } from '../../../content/features/Asset/services/assetService.ts';
import {
  AssetLibrary_Assets_RestoreCompleted,
  AssetLibrary_Assets_RestoreFailed,
  AssetLibrary_Assets_RestoreStarted,
} from '../../constants/assetLibraryActionTypes.ts';
import { StatusMessageAction } from '../../stores/IAssetLibraryState.ts';
import { LoadListingAssets } from '../../types/depsTypes.type.ts';
import { isArchiveAssetsAction } from '../../types/lastAssetsAction.ts';
import { AssetsOrderBy } from '../../types/orderBy.type.ts';

interface IDeps {
  readonly assetService: IAssetService;
  readonly loadListingAssets: LoadListingAssets;
  readonly trackUserEvent: TrackUserEventAction;
}

const started = () =>
  ({
    type: AssetLibrary_Assets_RestoreStarted,
  }) as const;

const completed = (assets: Immutable.Map<Uuid, IAsset>, openedFolderId: Uuid) =>
  ({
    type: AssetLibrary_Assets_RestoreCompleted,
    payload: {
      assets,
      openedFolderId,
    },
  }) as const;

const failed = (errorMessage: string) =>
  ({
    type: AssetLibrary_Assets_RestoreFailed,
    payload: {
      errorMessage,
    },
  }) as const;

export type RestoreAssetsActionsType = ReturnType<
  typeof started | typeof completed | typeof failed
>;

type Args = {
  readonly orderBy: AssetsOrderBy;
};

export const createRestoreAssetsAction =
  (deps: IDeps) =>
  ({ orderBy }: Args): ThunkPromise =>
  async (dispatch: Dispatch, getState: () => IStore): Promise<void> => {
    const {
      assetLibraryApp: { lastAction, openedFolderId },
    } = getState();

    if (!isArchiveAssetsAction(lastAction)) {
      throw new Error(
        `${__filename}: Expected last action to be ${
          StatusMessageAction.ArchivedAssets
        }, but was ${JSON.stringify(lastAction)}.`,
      );
    }

    dispatch(started());

    try {
      const idsToRestore = Collection.getValues(lastAction.successfulIds);
      const restoredAssets = await deps.assetService.restoreAssets(idsToRestore);

      dispatch(completed(restoredAssets, openedFolderId));
      dispatch(
        deps.trackUserEvent(TrackedEvent.AssetBulkAction, {
          action: AssetBulkActionEventTypes.Restored,
          count: restoredAssets.count(),
        }),
      );

      await dispatch(
        deps.loadListingAssets({
          orderBy,
          requestTrigger: AssetsRequestTrigger.FetchAfterBulkAction,
        }),
      );
    } catch (error) {
      dispatch(failed('Assets could not be restored.'));
      throw InvariantException(`${__filename}: ${error}`);
    }
  };
