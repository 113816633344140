import React from 'react';
import { IUserInfo } from '../../../../../_shared/models/UserInfo.ts';
import { renderDatetimeString } from '../../../../../_shared/utils/dateTime/timeUtils.ts';
import { makeSpacesUnbreakable } from '../../../../../_shared/utils/stringUtils.ts';
import { formatCurrentUserName } from '../../../../../_shared/utils/users/usersUtils.ts';
import { TimelineItem } from '../../../models/revisions/TimeLineItem.ts';

interface IRestoreItemProps {
  readonly isCurrent: boolean;
  readonly item: TimelineItem;
  readonly modifiedBy: IUserInfo | null;
  readonly restoredFrom: TimelineItem | null;
  readonly currentUserId: UserId;
}

export const RestoreItem: React.FC<IRestoreItemProps> = ({
  isCurrent,
  item,
  restoredFrom,
  modifiedBy,
  currentUserId,
}) => {
  const titleDateTimeString = makeSpacesUnbreakable(renderDatetimeString(item.time));

  return (
    <div className="revision-item">
      <div className="revision-item__details-pane">
        {isCurrent ? (
          <div className="revision-item__current-version-label">Current version</div>
        ) : null}
        <div className="revision-item__title" title={titleDateTimeString}>
          {titleDateTimeString}
        </div>
        {modifiedBy && (
          <div className="revision-item__detail-note">
            {formatCurrentUserName(currentUserId, false)(modifiedBy)} restored version from{' '}
            {makeSpacesUnbreakable(renderDatetimeString(restoredFrom?.time))}
          </div>
        )}
      </div>
    </div>
  );
};

RestoreItem.displayName = 'RestoreItem';
