import { InvariantException } from '@kontent-ai/errors';
import { forwardRef, useEffect } from 'react';
import { useLocation } from 'react-router';
import { ModalDialog } from '../../../../../../../../component-library/components/Dialogs/ModalDialog/ModalDialog.tsx';
import { trackUserEvent } from '../../../../../../../_shared/actions/thunks/trackUserEvent.ts';
import { IAnimatedModalDialogProps } from '../../../../../../../_shared/components/ModalDialog/IAnimatedModalDialogProps.type.ts';
import {
  EnvironmentRoute,
  EnvironmentRouteParams,
  PreviewURLsRoute,
} from '../../../../../../../_shared/constants/routePaths.ts';
import { TrackedEvent } from '../../../../../../../_shared/constants/trackedEvent.ts';
import { useDispatch } from '../../../../../../../_shared/hooks/useDispatch.ts';
import { useSelector } from '../../../../../../../_shared/hooks/useSelector.ts';
import { ContentItemEditingPreviewAction } from '../../../../../../../_shared/models/events/ContentItemEditingEventData.type.ts';
import { HotjarTag, tagRecording } from '../../../../../../../_shared/utils/hotjarUtils.ts';
import {
  buildPath,
  matchPath,
} from '../../../../../../../_shared/utils/routing/routeTransitionUtils.ts';
import { itemEditingModalDismissed } from '../../../../../actions/contentActions.ts';
import { getModalDialogActionOrigin } from '../../../../../selectors/getModalDialogActionOrigin.ts';
import { NotSetupPreviewLink } from '../../../components/editingActions/actionSubmenuOptions/NotSetupPreviewLink.tsx';

export const NotSetupPreviewDialog = forwardRef<HTMLDivElement, IAnimatedModalDialogProps>(
  ({ isOpen }, ref) => {
    const dispatch = useDispatch();
    const location = useLocation();
    const actionOrigin = useSelector(getModalDialogActionOrigin);

    const matchParams = matchPath<EnvironmentRouteParams>(location.pathname, EnvironmentRoute);
    if (!matchParams) {
      throw InvariantException('Current route does not belong to ProjectRoute routes');
    }

    const settingsPath = buildPath<EnvironmentRouteParams>(PreviewURLsRoute, {
      projectId: matchParams.projectId,
    });

    useEffect(() => {
      dispatch(
        trackUserEvent(TrackedEvent.ContentItemEditing, {
          action: ContentItemEditingPreviewAction.NotSetupPreviewSelected,
          origin: actionOrigin,
        }),
      );

      tagRecording(HotjarTag.DisabledPreviewButtonClicked);
    }, [actionOrigin]);

    const navigate = () => {
      dispatch(trackUserEvent(TrackedEvent.NavigationLinkOpened, { 'link-id': 'preview-setup' }));
      dispatch(itemEditingModalDismissed());
    };

    const onClose = (): void => {
      dispatch(itemEditingModalDismissed());
    };

    return (
      <ModalDialog
        ref={ref}
        headline="Preview not set up"
        onClose={onClose}
        isOpen={isOpen}
        isDismissable
      >
        <NotSetupPreviewLink onNavigate={navigate} settingsPath={settingsPath} />
      </ModalDialog>
    );
  },
);
