import { ThunkFunction } from '../../../../../@types/Dispatcher.type.ts';
import { trackUserEvent } from '../../../../../_shared/actions/thunks/trackUserEvent.ts';
import { TrackedEvent } from '../../../../../_shared/constants/trackedEvent.ts';
import {
  ContentItemEditingEventOrigins,
  ContentItemEditingTriggerDialogAction,
} from '../../../../../_shared/models/events/ContentItemEditingEventData.type.ts';
import { itemEditingModalWithPropertiesOpened } from '../../../actions/contentActions.ts';
import { ItemEditingModalDialogType } from '../../../constants/itemEditingModalDialogType.ts';

export const openChangeWorkflowDialog =
  (actionOrigin: ContentItemEditingEventOrigins): ThunkFunction =>
  (dispatch) => {
    dispatch(
      itemEditingModalWithPropertiesOpened({
        dialogType: ItemEditingModalDialogType.ChangeWorkflowDialog,
        actionOrigin,
      }),
    );

    dispatch(
      trackUserEvent(TrackedEvent.ContentItemEditing, {
        action: ContentItemEditingTriggerDialogAction.ChangeWorkflowSelected,
        origin: actionOrigin,
      }),
    );
  };
