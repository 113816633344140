import Immutable from 'immutable';
import { ItemColumnCode } from '../../../../_shared/constants/itemColumnCode.ts';
import { IChangeWorkflowStepModalData } from '../../../../_shared/features/ChangeWorkflowStepModal/reducers/IChangeWorkflowStepModalData.ts';
import { MemoizedContentItemId } from '../../../../_shared/models/ContentItemId.ts';
import { LoadingStatus } from '../../../../_shared/models/LoadingStatusEnum.ts';
import { OrderBy } from '../../../../_shared/models/OrderBy.ts';
import { IUserIdentifier } from '../../../../_shared/models/UserIdentifier.ts';
import { ActiveCapabilityType } from '../../../../_shared/models/activeCapability.type.ts';
import { ContentItemFilterOrigin } from '../../../../_shared/models/events/ContentItemFilterEventData.type.ts';
import { Notifications } from '../../../../_shared/services/signalR/signalRClient.type.ts';
import { VariantTranslationTaskId } from '../../../../_shared/utils/translations/variantTranslationTaskId.ts';
import { PublishingState } from '../../../../data/constants/PublishingState.ts';
import { IAssignmentWorkflowStep } from '../../../../data/models/workflow/WorkflowStep.ts';
import { AssignmentSections } from '../../../itemEditor/features/ContentItemEditing/constants/AssignmentSections.ts';
import { ContentGroupSelectionReason } from '../../../itemEditor/features/ContentItemEditing/constants/ContentGroupSelectionReason.ts';
import { EditingAction } from '../../../itemEditor/features/ContentItemEditing/models/EditingAction.ts';
import { INewContentItemStoreState } from '../../../itemEditor/features/NewContentItem/reducers/INewContentItemStoreState.type.ts';
import { INewVariantDialogStoreState } from '../../../itemEditor/features/NewContentItem/reducers/INewVariantDialogStoreState.type.ts';
import { ITemporaryContentItem } from '../../../itemEditor/models/ITemporaryContentItem.type.ts';
import { IPublishWarnings } from '../../../itemEditor/models/PublishWarnings.ts';
import { CommentThreadItemType } from '../../../itemEditor/models/comments/CommentThreadItem.ts';
import { ICommentThread } from '../../../itemEditor/models/comments/CommentThreads.ts';
import { ICommentFormShape } from '../../../itemEditor/models/comments/ICommentFormShape.type.ts';
import { INote } from '../../../itemEditor/models/contentItem/Assignment.ts';
import { ContentItemCodenameStatus } from '../../../itemEditor/models/contentItem/ContentItemCodenameStatus.ts';
import { IContentItemVariantReference } from '../../../itemEditor/models/contentItem/ContentItemVariantReference.ts';
import { IContentItemWithVariant } from '../../../itemEditor/models/contentItem/ContentItemWithVariant.ts';
import { IEditedContentItem } from '../../../itemEditor/models/contentItem/edited/EditedContentItem.ts';
import { IEditedContentItemStatus } from '../../../itemEditor/models/contentItem/edited/EditedContentItemStatus.ts';
import { EditedContentItemVariant } from '../../../itemEditor/models/contentItem/edited/EditedContentItemVariant.ts';
import { ICompiledContentItemElementData } from '../../../itemEditor/models/contentItemElements/ICompiledContentItemElement.type.ts';
import { TimelineItem } from '../../../itemEditor/models/revisions/TimeLineItem.ts';
import { ItemEditingModalDialogState } from '../../../itemEditor/reducers/editorUi/reducers/itemEditingModalDialog.ts';
import { LoadedContentIemValidations } from '../../../itemEditor/reducers/internalReducers/loadedContentIemValidations.ts';
import { ItemElementErrors } from '../../../itemEditor/utils/elementErrorCheckers/types/Errors.ts';
import { ItemElementFriendlyWarnings } from '../../../itemEditor/utils/itemElementFriendlyWarningCheckers/types/FriendlyWarnings.ts';
import { ItemElementWarnings } from '../../../itemEditor/utils/itemElementWarningCheckers/types/Warnings.ts';
import { IPreviewConfiguration } from '../../../previewConfiguration/models/PreviewConfiguration.type.ts';
import { IApprovedSuggestion } from '../../../richText/plugins/comments/api/editorSuggestionUtils.ts';
import { ICompiledContentType } from '../models/CompiledContentType.ts';
import { IItemListingBulkActionsCheck } from '../models/ItemListingBulkActionStatus.ts';
import { ScrollTableState } from '../models/ScrollTableState.type.ts';
import { IListingFilter } from '../models/filter/IListingFilter.ts';
import { ISavedFiltersUi } from '../reducers/listingUi/reducers/savedFiltersUi/ISavedFiltersUi.type.ts';
import { ILastAction } from '../reducers/listingUi/statusInfo/reducers/lastAction.ts';
import { ItemEditorOperationId } from '../utils/itemEditorOperationIdUtils.ts';

export type PendingOperationId = ItemEditorOperationId;

export enum StatusMessageAction {
  Moved = 'Moved',
  Moving = 'Moving',

  None = 'None',

  Published = 'Published',
  Publishing = 'Publishing',
  PublishingUndone = 'Publishing undone',

  ScheduledToPublish = 'Scheduled to publish',
  SchedulingToPublish = 'Scheduling to publish',
  SchedulingToPublishUndone = 'Scheduling to publish undone',

  ScheduledToUnpublish = 'Scheduled to unpublish',
  SchedulingToUnpublish = 'Scheduling to unpublish',

  Unpublished = 'Unpublished',
  Unpublishing = 'Unpublishing',

  Cancelled = 'Cancelled',
  Cancelling = 'Cancelling',

  Updated = 'Updated',
  Updating = 'Updating',

  Archived = 'Archived',
  Archiving = 'Archiving',

  Restored = 'Restored',
  Restoring = 'Restoring',

  Undoing = 'Undoing',
  Undone = 'Undone',
}

export interface IStatusInfoState {
  readonly lastAction: ILastAction;
  readonly failedIds: Immutable.Set<Uuid>;
  readonly unmodifiedIds: Immutable.Set<Uuid>;
  readonly successfulIds: Immutable.Set<Uuid>;
  readonly variantId: Uuid;
}

export interface IFullTextSearchContext {
  readonly previousOrderBy: OrderBy<ItemColumnCode>;
}

export interface IListingUi {
  // inventory
  readonly contentItemListingScrollTableState: ScrollTableState;
  readonly filter: IListingFilter;
  readonly filterStatus: ReadonlyRecord<ContentItemFilterOrigin, LoadingStatus>;
  readonly fullTextSearchContext: IFullTextSearchContext | null;
  readonly hiddenColumns: Immutable.Set<ItemColumnCode>;
  readonly itemsSelection: IItemsSelection;
  readonly isOrderByDisplayed: boolean;
  readonly orderBy: OrderBy<ItemColumnCode>;
  readonly statusInfo: IStatusInfoState;

  // shared ui
  readonly bulkActionCheckResult: IItemListingBulkActionsCheck | null;
  readonly highlightedContentItems: Immutable.Set<Uuid>;
  readonly publishWarnings: IPublishWarnings;
  readonly savedFiltersUi: ISavedFiltersUi;
}

export interface IEditorUi {
  readonly activeCapabilities: IVariantActiveCapabilities;
  readonly assignmentMetadata: AssignmentMetadata;
  readonly approvedSuggestions: ReadonlyArray<IApprovedSuggestion>;
  readonly cascadePublish: ICascadePublishState;
  readonly codenameEditor: ICodenameEditor;
  readonly collapsedContentComponents: Immutable.Set<Uuid>;
  readonly collapsedGuidelinesElementIds: ReadonlyArray<Uuid>;
  readonly collapsedSitemapNodes: Immutable.Set<Uuid>;
  readonly contentItemSidebar: IContentItemSidebar;
  readonly convertedItemIds: ReadonlyArray<Uuid>;
  readonly editedContentItemVariantUploadingAssets: ReadonlyArray<Uuid>;
  readonly editingActions: IEditingActionsState;
  readonly elementsAreInaccessibleNotificationBarTrigger: ContentGroupSelectionReason | null;
  readonly firstWorkflowStepCurrentRoleCanWorkWithId: Uuid | null;
  readonly itemEditingModalDialog: ItemEditingModalDialogState;
  readonly itemLiveUserIds: ReadonlySet<UserId>;
  readonly lockedElements: Notifications['lockedElementsChange']['payload']['lockedElementSessions'];
  readonly multipleContentItemsModalSelectorAlreadyLinkedItemIds: Immutable.Set<Uuid>;
  readonly newCommentThreadItemTypeMappings: Immutable.Map<string, CommentThreadItemType>;
  readonly outdatedElementIds: Immutable.Set<Uuid>;
  readonly pendingOperationIds: ReadonlySet<PendingOperationId>;
  readonly pendingRefreshElements: Immutable.Map<Uuid, ICompiledContentItemElementData | null>;
  readonly refreshedElementIds: Immutable.Set<Uuid>;
  readonly restoredVersionId: Uuid | null;
  readonly selectedContentGroups: Immutable.Map<Uuid, Uuid | null>;
  readonly tasks: Tasks;
  readonly temporaryItem: ITemporaryContentItem | null;
}

export type AssignmentMetadata = {
  readonly submittingSection: AssignmentSections | null;
};

export type Tasks = {
  readonly highlightedTaskIds: UuidArray;
  readonly orderedTaskIds: ReadonlyArray<Uuid>;
  readonly selectedTaskId: Uuid | null;
  readonly submittingTasks: ReadonlySet<Uuid>;
  readonly archivingTasks: ReadonlySet<Uuid>;
};

export interface IVariantActiveCapabilities {
  readonly variantCapabilities: ReadonlyArray<ActiveCapabilityType>;
  readonly contentGroupsForViewCapability: UuidArray;
  readonly contentGroupsForUpdateCapability: UuidArray;
}

export interface ICodenameEditor {
  readonly status: ContentItemCodenameStatus;
  readonly isCodenameAutogenerated: boolean;
}

export enum LastCascadeAction {
  None = 'None',
  Publish = 'Publish',
  Schedule = 'Schedule',
  UndoPublish = 'UndoPublish',
  UndoSchedule = 'UndoSchedule',
}

export interface ICascadeResult {
  readonly lastAction: LastCascadeAction;
  readonly modifiedItemIds: ReadonlySet<MemoizedContentItemId>;
  readonly failedItemIds: ReadonlySet<MemoizedContentItemId>;
}

/** Unique ID containing ContentItemId of the item and its ancestors */
export type CascadeNodeId = string;

export type ExpandedCascadeNodeData = {
  readonly contentItemId: MemoizedContentItemId;
  readonly childContentItemIds: ReadonlySet<MemoizedContentItemId>;
};

export interface ICascadePublishState {
  readonly cascadeResult: ICascadeResult;
  readonly expandedNodesData: ReadonlyMap<CascadeNodeId, ExpandedCascadeNodeData>;
  readonly loadingStatus: LoadingStatus;
  readonly nodesLoadingStatus: ReadonlyMap<CascadeNodeId, LoadingStatus>;
  //  Different nodes representing the same content items should be selected together as they are affected by the same submit action
  readonly selectedItems: ReadonlySet<MemoizedContentItemId>;
}

export interface IContentItemSidebar {
  readonly isInitialized: boolean;
  readonly isOpen: boolean;
  readonly openedByUser: boolean;
}

export interface IEditingActionsState {
  readonly activatedAction: EditingAction;
}

export interface IItemsSelection {
  readonly selectAll: boolean;
  readonly selectedItemIds: ReadonlyArray<Uuid>;
  readonly unselectedItemIds: ReadonlyArray<Uuid>;
  readonly lastSelectionTouchedItem: Uuid | null;
}

export interface IContentItemOverwritten {
  readonly changeBy?: string;
  readonly changeByManageApi: boolean;
  readonly changeReason: string;
  readonly difference: IContentItemOverwrittenDifference;
}

export interface IVariantWorkflowStepOverwritten {
  readonly createdAt: DateTimeStamp | null;
  readonly lastPublishedAt: DateTimeStamp | null;
  readonly lastPublishedBy: string;
  readonly publishingState: PublishingState;
  readonly variantCapabilities: ReadonlyArray<ActiveCapabilityType>;
  readonly workflowStatus: IAssignmentWorkflowStep;
}

export interface IContentItemOverwrittenDifference {
  readonly assignees?: ReadonlySet<IUserIdentifier>;
  readonly codename?: string;
  readonly collection?: string;
  readonly dueDate?: DateTimeStamp | null;
  readonly name?: string;
  readonly note?: INote | null;
  readonly publishScheduleTime?: DateTimeStamp | null;
  readonly sitemap?: Immutable.Set<Uuid>;
  readonly unpublishScheduleTime?: DateTimeStamp | null;
  readonly workflowStep?: IVariantWorkflowStepOverwritten;
}

export interface IEditedContentItemThreads {
  readonly focusedCommentThreadId: Uuid | null;
  readonly commentThreads: ReadonlyArray<ICommentThread>;
  readonly newCommentThreadForm: ICommentFormShape;
}

export interface INewContentItemVariantInitState {
  readonly workflowId: Uuid | null;
}

export interface IContentAppStoreState {
  // new structure
  readonly editorUi: IEditorUi;
  readonly listingUi: IListingUi;

  // everything else
  readonly areContentItemElementsHighlighting: boolean;
  readonly changeWorkflowStepModalData: IChangeWorkflowStepModalData;
  readonly compareRevisions: boolean;
  readonly contentItemVariants: Immutable.Map<Uuid, IContentItemVariantReference>;
  readonly defaultVariantOverwritten: IContentItemOverwritten | null;
  readonly editedContentItem: IEditedContentItem | null;
  readonly editedContentItemOverwritten: IContentItemOverwritten | null;
  readonly editedContentItemStatus: IEditedContentItemStatus;
  readonly editedContentItemVariant: EditedContentItemVariant | null;
  readonly editedContentItemVariantComments: IEditedContentItemThreads;
  readonly editedContentItemVariantElements: ReadonlyArray<ICompiledContentItemElementData>;
  readonly entryTimeline: ReadonlyArray<TimelineItem> | null;
  readonly timelineContinuationToken: string | null;
  readonly isLoadMoreTimelineItemsInProgress: boolean;
  readonly firstIncompleteElementId: Uuid;
  readonly isCommentsPaneVisible: boolean;
  readonly variantTranslationTaskIdsInProgress: ReadonlySet<VariantTranslationTaskId>;
  readonly isVariantEditedForTheFirstTime: boolean;
  readonly itemValidationErrors: ItemElementErrors;
  readonly itemValidationFriendlyWarnings: ItemElementFriendlyWarnings;
  readonly itemValidationWarnings: ItemElementWarnings;
  readonly lastSelectedRevisionId: Uuid | null;
  readonly loadedContentItems: Immutable.Map<Uuid, IContentItemWithVariant>;
  readonly loadedContentItemTypes: Immutable.Map<Uuid, ICompiledContentType>;
  readonly loadedContentItemValidations: LoadedContentIemValidations;
  readonly newContentItem: INewContentItemStoreState;
  readonly newContentItemVariantInit: INewContentItemVariantInitState;
  readonly newVariant: INewVariantDialogStoreState;
  readonly previewConfiguration: IPreviewConfiguration | null;
  readonly progressMessage: string;
  readonly selectedComparisonRevision: TimelineItem | null;
  readonly selectedComparisonRevisionElements: ReadonlyArray<ICompiledContentItemElementData> | null;
  readonly selectedRevision: TimelineItem | null;
  readonly selectedRevisionElements: ReadonlyArray<ICompiledContentItemElementData> | null;
  readonly showFriendlyWarnings: boolean;
  readonly showIncomplete: boolean;
  readonly showIncompleteDefaultVariantNotificationBar: boolean;
  readonly showIncompleteItemNotificationBar: boolean;
  readonly showIncompleteItemWarningsBeforePublish: boolean;
}
