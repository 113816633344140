import { Action } from '../../../@types/Action.type.ts';
import { AssetLibrary_Assets_ShowUnsupportedFileTypeError } from '../../../applications/contentInventory/assets/constants/assetLibraryActionTypes.ts';
import {
  ContentListing_MoveToArchivedStep_Started,
  ContentListing_MoveToCollection_Started,
  ContentListing_PublishItems_Failed,
  ContentListing_PublishItems_Started,
  ContentListing_RestoreFromArchivedStep_Started,
  ContentListing_ScheduledPublishItems_Failed,
  ContentListing_ScheduledPublishItems_Started,
  ContentListing_ScheduledUnpublishItems_Failed,
  ContentListing_ScheduledUnpublishItems_Started,
  ContentListing_UnpublishItems_Failed,
  ContentListing_UnpublishItems_Started,
  ContentListing_UpdateStandardStep_Started,
  ContentListing_UpdateWorkflowStep_Failed,
  ContentListing_UpdateWorkflowStep_Finished,
} from '../../../applications/contentInventory/content/features/ContentItemInventory/constants/contentItemInventoryActionTypes.ts';
import { ModalDialogType } from '../../constants/modalDialogType.ts';
import {
  Shared_FeedbackModal_Submitted,
  Shared_ModalDismissed,
  Shared_ModalOpened,
  Shared_ModalOpenedWithProperties,
} from '../../constants/sharedActionTypes.ts';
import { ModalProperties } from '../../types/ModalDialogProperties.type.ts';

export type ModalDialogState = {
  readonly type: ModalDialogType;
  readonly properties: ModalProperties | null;
};

const initialState: ModalDialogState = {
  properties: null,
  type: ModalDialogType.None,
};

export function modalDialog(
  state: ModalDialogState = initialState,
  action: Action,
): ModalDialogState {
  switch (action.type) {
    case Shared_ModalOpened:
      return {
        type: action.payload.type,
        properties: null,
      };

    case Shared_ModalOpenedWithProperties:
      return {
        type: action.payload.properties.dialogType,
        properties: action.payload.properties,
      };

    case ContentListing_ScheduledPublishItems_Failed:
    case ContentListing_ScheduledUnpublishItems_Failed:
    case ContentListing_UpdateWorkflowStep_Failed:
    case ContentListing_PublishItems_Failed:
    case ContentListing_UnpublishItems_Failed:
      return {
        type: ModalDialogType.ChangeWorkflowStepListingDialog,
        properties: null,
      };

    case ContentListing_PublishItems_Started:
    case ContentListing_UnpublishItems_Started:
    case ContentListing_ScheduledPublishItems_Started:
    case ContentListing_ScheduledUnpublishItems_Started:
    case ContentListing_UpdateWorkflowStep_Finished:
    case ContentListing_UpdateStandardStep_Started:
    case ContentListing_RestoreFromArchivedStep_Started:
    case ContentListing_MoveToArchivedStep_Started:
    case ContentListing_MoveToCollection_Started:
    case Shared_ModalDismissed:
      return initialState;

    case Shared_FeedbackModal_Submitted:
      return {
        ...initialState,
        type: ModalDialogType.ThanksForFeedbackDialog,
      };

    case AssetLibrary_Assets_ShowUnsupportedFileTypeError:
      return {
        ...initialState,
        type: ModalDialogType.UnsupportedAssetFileType,
      };

    default:
      return state;
  }
}
