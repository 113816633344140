import { createGuid } from '@kontent-ai/utils';
import { trackUserEvent } from '../../../../_shared/actions/thunks/trackUserEvent.ts';
import { ContentItemFilterOrigin } from '../../../../_shared/models/events/ContentItemFilterEventData.type.ts';
import { repositoryCollection } from '../../../../_shared/repositories/repositories.ts';
import { initializeRequestTokenFactory } from '../../../../_shared/utils/requestTokenUtils.ts';
import { getFilterStorage } from '../../../../localStorages/getFilterStorage.ts';
import { createTrackFilterChangeAction } from '../../../contentInventory/content/features/ContentItemInventory/actions/thunks/loadListingItems/trackFilterChange.ts';
import { createContentItemFilterServerModelFromFilter } from '../../../contentInventory/content/utils/contentItemListModelConverters.ts';
import { createTokenizedProjectOverviewLoadItemsMatchingFilterStarted } from './projectOverviewActions.ts';
import { createFilterProjectOverviewAction } from './thunkActions/filterProjectOverview.ts';
import { createLoadItemsMatchingFilter } from './thunkActions/loadItemsMatchingFilter.ts';

const { contentItemRepository } = repositoryCollection;

const projectOverviewCancellationTokenFactory = initializeRequestTokenFactory(
  (state) => state.projectOverviewApp.cancellationToken,
  createTokenizedProjectOverviewLoadItemsMatchingFilterStarted,
  createGuid,
);

const loadItemsMatchingFilter = createLoadItemsMatchingFilter({
  contentItemRepository,
  createContentItemFilterServerModelFromFilter,
  requestTokenFactory: projectOverviewCancellationTokenFactory,
  trackUserEvent,
});

export const filterProjectOverview = createFilterProjectOverviewAction({
  ContentItemFilterStorage: getFilterStorage(ContentItemFilterOrigin.ProjectOverview),
  loadItemsMatchingFilter,
  trackUserEvent,
  trackFilterChange: createTrackFilterChangeAction(ContentItemFilterOrigin.ProjectOverview),
});
