import { Button } from '@kontent-ai/component-library/Button';
import { EmptyState } from '../../../../../_shared/components/EmptyState/EmptyState.tsx';
import { useDispatch } from '../../../../../_shared/hooks/useDispatch.ts';
import {
  DataUiAction,
  getDataUiActionAttribute,
} from '../../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';
import { compose } from '../../../../../_shared/utils/func/compose.ts';

type WidgetDetailEmptyStateProps = Readonly<{
  action: () => void;
}>;

export const WidgetDetailEmptyState = ({ action }: WidgetDetailEmptyStateProps) => {
  const dispatch = useDispatch();

  return (
    <EmptyState>
      <EmptyState.Title>No published items in this time frame.</EmptyState.Title>
      <EmptyState.Content>
        Try different filters in this time frame or select a different time frame to see published
        items.
      </EmptyState.Content>
      <EmptyState.Footer>
        <Button
          buttonStyle="primary"
          onClick={compose(dispatch, action)}
          {...getDataUiActionAttribute(DataUiAction.ClearFilter)}
        >
          Clear filters
        </Button>
      </EmptyState.Footer>
    </EmptyState>
  );
};
