import { Button, RouterLinkButton } from '@kontent-ai/component-library/Button';
import React from 'react';
import {
  isSubscriptionSuspendedAutomatically,
  isSubscriptionSuspendedDueToMissingToS,
  isSubscriptionSuspendedDueToUsingStarterPlan,
} from '../../../../applications/subscriptionManagement/shared/utils/subscriptionStatusUtils.ts';
import {
  EnsuredSubscriptionRouteParams,
  SubscriptionRoute,
} from '../../../constants/routePaths.ts';
import { buildPath } from '../../../utils/routing/routeTransitionUtils.ts';
import { BillingInformationAction } from './BillingInformationAction.tsx';

type Props = {
  readonly isPlanPrepaid: boolean;
  readonly onContactSupport: () => void;
  readonly subscriptionId: Uuid;
  readonly subscriptionStatusReason: string | null;
};

export const SuspendedSubscriptionWarningAction: React.FC<Props> = ({
  isPlanPrepaid,
  onContactSupport,
  subscriptionId,
  subscriptionStatusReason,
}) => {
  if (isSubscriptionSuspendedDueToMissingToS(subscriptionStatusReason)) {
    return (
      <RouterLinkButton
        size="small"
        buttonStyle="primary-inverse"
        to={buildPath<EnsuredSubscriptionRouteParams>(SubscriptionRoute, { subscriptionId })}
      >
        Accept the Terms of Service
      </RouterLinkButton>
    );
  }

  return isPlanPrepaid ||
    !isSubscriptionSuspendedAutomatically(subscriptionStatusReason) ||
    isSubscriptionSuspendedDueToUsingStarterPlan(subscriptionStatusReason) ? (
    <Button size="small" buttonStyle="primary-inverse" onClick={onContactSupport}>
      Contact support to renew
    </Button>
  ) : (
    <BillingInformationAction subscriptionId={subscriptionId} />
  );
};

SuspendedSubscriptionWarningAction.displayName = 'SuspendedSubscriptionWarningAction';
