import React from 'react';
import { useDispatch } from '../../../_shared/hooks/useDispatch.ts';
import { useSelector } from '../../../_shared/hooks/useSelector.ts';
import { selectIntercomUtils } from '../../../_shared/selectors/selectIntercomUtils.ts';
import { compose } from '../../../_shared/utils/func/compose.ts';
import { showTrialActivationModal } from '../actions/projectsActions.ts';
import { TrialActivation as TrialActivationComponent } from '../components/TrialActivation.tsx';

export const TrialActivation: React.FC<{ readonly text: string }> = (props) => {
  const dispatch = useDispatch();
  const isVisible = useSelector((s) => !s.data.user.info.hadTrial);
  const intercomUtils = useSelector(selectIntercomUtils);

  return (
    <TrialActivationComponent
      isVisible={isVisible}
      onClick={compose(dispatch, showTrialActivationModal)}
      onContactSupport={() => {
        intercomUtils.showIntercom();
        intercomUtils.showMessage();
      }}
      text={props.text}
    />
  );
};

TrialActivation.displayName = 'TrialActivation';
