import React, { memo, useCallback } from 'react';
import { useHistory } from 'react-router';
import { trackUserEvent } from '../../../../../_shared/actions/thunks/trackUserEvent.ts';
import {
  ContentItemRoute,
  ContentItemTimelineLatestPublishedRoute,
} from '../../../../../_shared/constants/routePaths.ts';
import { TrackedEvent } from '../../../../../_shared/constants/trackedEvent.ts';
import { useDispatch } from '../../../../../_shared/hooks/useDispatch.ts';
import { useSelector } from '../../../../../_shared/hooks/useSelector.ts';
import { ContentStatusContentItemClickOrigin } from '../../../../../_shared/models/TrackUserEventData.ts';
import { IUserIdentifier } from '../../../../../_shared/models/UserIdentifier.ts';
import { ContentItemFilterOrigin } from '../../../../../_shared/models/events/ContentItemFilterEventData.type.ts';
import { stringifyContentItemId } from '../../../../../_shared/models/utils/contentItemIdUtils.ts';
import { IStore } from '../../../../../_shared/stores/IStore.type.ts';
import { getCannotCreateMessageForItem } from '../../../../../_shared/utils/permissions/activeCapabilities.ts';
import { getContentItemPath } from '../../../../../_shared/utils/routing/routeTransitionUtils.ts';
import { getUsersInfo } from '../../../../../_shared/utils/users/usersUtils.ts';
import { IListingContentItem } from '../../../../../data/models/listingContentItems/IListingContentItem.ts';
import { WorkflowStepAction } from '../../../../../data/models/workflow/WorkflowStep.ts';
import { WorkflowStatusCellContent } from '../../../../contentInventory/content/features/ContentItemInventory/components/ItemInventoryScrollTable/Cells/ContentItemScrollTableCellsContents.tsx';
import { getCollection } from '../../../../contentInventory/content/selectors/getCollection.ts';
import { useSpaceData } from '../../../../environmentSettings/spaces/hooks/useSpaceData.ts';
import { ContentStatusScrollTableRow as ContentStatusScrollTableRowComponent } from '../../components/ContentStatusScrollTable/ContentStatusScrollTableRow.tsx';
import { useHiddenColumns } from '../../hooks/useHiddenColumn.ts';

export const getLanguage = (variantId: Uuid | null) => (state: IStore) => {
  if (variantId) {
    return state.data.languages.byId.get(variantId) ?? state.data.languages.defaultLanguage;
  }

  return state.data.languages.defaultLanguage;
};

interface IContentItemScrollTableRowContainerProps {
  readonly item: IListingContentItem;
  readonly index: number;
  readonly onTaskButtonClick: () => void;
  readonly onCommentButtonClick: () => void;
}

export const ContentStatusScrollTableRow: React.FC<IContentItemScrollTableRowContainerProps> = memo(
  (props) => {
    const dispatch = useDispatch();
    const history = useHistory();
    const itemType = useSelector((s) => s.data.contentTypes.byId.get(props.item.item.typeId));
    const collectionName = useSelector(
      (s) => getCollection(s, props.item.item.collectionId)?.name ?? '',
    );
    const isHighlighted = useSelector((s) =>
      s.contentApp.listingUi.highlightedContentItems.contains(props.item.item.id),
    );
    const hiddenColumns = useHiddenColumns();
    const spaceData = useSpaceData(props.item.item.collectionId);

    const cannotOpenWithClickMessage = getCannotCreateMessageForItem(props.item);

    const variant = props.item.variant;
    //  In case this item is a published version and there already exists a new version for this item
    const isOlderPublishedVersion =
      variant &&
      variant.assignment.workflowStatus.action === WorkflowStepAction.Publish &&
      variant.actualWorkflowStatus &&
      variant.actualWorkflowStatus.id !== variant.assignment.workflowStatus.id;

    const usersById = useSelector((s) => s.data.users.usersById);
    const assigneesIds = variant?.assignment.assignees ?? new Set<IUserIdentifier>();
    const assignees = Array.from(getUsersInfo(assigneesIds, usersById));

    const route = isOlderPublishedVersion
      ? ContentItemTimelineLatestPublishedRoute
      : ContentItemRoute;
    const itemLinkPath = getContentItemPath(
      history.location.pathname,
      props.item.item.id,
      props.item.variant?.id.variantId,
      { route, origin: ContentItemFilterOrigin.ContentStatus },
    );

    const language = useSelector(getLanguage(variant?.id.variantId ?? null));

    const commentCount = useSelector((s) => {
      if (!variant) {
        return undefined;
      }
      return s.data.contentStatusCommentCounts.byId.get(stringifyContentItemId(variant.id));
    });
    const taskCount = useSelector((s) => {
      if (!variant) {
        return undefined;
      }
      return s.data.contentStatusTaskCounts.byId.get(stringifyContentItemId(variant.id));
    });

    const onItemClick = useCallback(() => {
      dispatch(
        trackUserEvent(TrackedEvent.ContentStatusContentItemClicked, {
          itemId: props.item.item.id,
          origin: ContentStatusContentItemClickOrigin.ScrollTableRow,
          variantId: props.item.variant?.id.variantId,
        }),
      );
    }, [props.item.item.id, props.item.variant?.id.variantId]);

    if (!itemType) {
      return null;
    }

    return (
      <ContentStatusScrollTableRowComponent
        allowNavigationWithOnClick={!cannotOpenWithClickMessage}
        assignees={assignees}
        collectionName={collectionName}
        commentCount={commentCount}
        disabledClickMessage={cannotOpenWithClickMessage}
        hiddenColumns={hiddenColumns}
        isHighlighted={isHighlighted}
        item={props.item}
        itemLinkPath={itemLinkPath}
        itemType={itemType}
        languageName={language.name}
        onCommentButtonClick={props.onCommentButtonClick}
        onItemClick={cannotOpenWithClickMessage ? undefined : onItemClick}
        onTaskButtonClick={props.onTaskButtonClick}
        renderWorkflowStatus={() => <WorkflowStatusCellContent variant={props.item.variant} />}
        spaceData={spaceData}
        taskCount={taskCount}
      />
    );
  },
);

ContentStatusScrollTableRow.displayName = 'ContentStatusScrollTableRow';
