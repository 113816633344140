import { isXMLHttpRequest } from '@kontent-ai/errors';
import { notUndefined } from '@kontent-ai/utils';
import Immutable from 'immutable';
import { Action } from '../../../../../@types/Action.type.ts';
import { GetState, ThunkPromise } from '../../../../../@types/Dispatcher.type.ts';
import { trackUserEvent } from '../../../../../_shared/actions/thunks/trackUserEvent.ts';
import { ItemColumnCode } from '../../../../../_shared/constants/itemColumnCode.ts';
import {
  FindRightContentTrackedEvent,
  TrackedEvent,
} from '../../../../../_shared/constants/trackedEvent.ts';
import { OrderBy } from '../../../../../_shared/models/OrderBy.ts';
import { getSelectedLanguageId } from '../../../../../_shared/selectors/getSelectedLanguageId.ts';
import { IStore } from '../../../../../_shared/stores/IStore.type.ts';
import { trackError } from '../../../../../_shared/utils/logError.ts';
import { RequestTokenFactory } from '../../../../../_shared/utils/requestTokenUtils.ts';
import {
  ItemsRequestTrigger,
  convertFromPixelsToItems,
  getMaxItemsForLoading,
  shouldRequestNewItems,
  shouldUseLastContinuationToken,
} from '../../../../../_shared/utils/scrollTableUtils.ts';
import { filterOutPublishedVersionsOfLoadedInventoryItems } from '../../../../../data/actions/thunks/listingContentItems/filterOutPublishedVersionsOfLoadedInventoryItems.ts';
import {
  IListingContentItem,
  getListingContentItemFromJS,
} from '../../../../../data/models/listingContentItems/IListingContentItem.ts';
import { getListingItemsCount } from '../../../../../data/reducers/listingContentItems/selectors/getListingItemsCount.ts';
import { getCurrentProjectId } from '../../../../../data/reducers/user/selectors/userProjectsInfoSelectors.ts';
import { getContentItemScrollTableRowHeightPx } from '../../../../contentInventory/content/constants/uiConstants.ts';
import { ScrollTableState } from '../../../../contentInventory/content/models/ScrollTableState.type.ts';
import {
  IListingFilter,
  areListingFilterAndSearchEmpty,
} from '../../../../contentInventory/content/models/filter/IListingFilter.ts';
import { TemporaryContentItemState } from '../../../../contentInventory/content/models/temporaryContentItemState.ts';
import { FullTextSearchStatus } from '../../../../contentInventory/shared/reducers/IContentInventoryStoreState.type.ts';
import { ITemporaryContentItem } from '../../../../itemEditor/models/ITemporaryContentItem.type.ts';
import { MissionControlRepository } from '../../../repositories/MissionControlRepository.type.ts';
import { ContentStatusFilterRepositoryServerModel } from '../../../repositories/serverModels/missionControlRepositoryServerModels.type.ts';
import { BuildFilterWithContinuationForContentStatusListingParams } from '../../utils/buildFilterWithContinuationForContentStatusListing.ts';
import { contentStatusItemsLoaded } from '../contentStatusActions.ts';
import { LoadContentStatusCommentOrTaskCountsParams } from './loadContentStatusCommentOrTaskCounts.ts';

interface IDeps {
  readonly missionControlRepository: MissionControlRepository;
  readonly buildFilterWithContinuationForContentStatusListing: (
    params: BuildFilterWithContinuationForContentStatusListingParams,
  ) => Readonly<ContentStatusFilterRepositoryServerModel>;
  readonly loadContentStatusCommentCounts: (
    params: LoadContentStatusCommentOrTaskCountsParams,
    abortSignal?: AbortSignal,
  ) => ThunkPromise;
  readonly loadContentStatusTaskCounts: (
    params: LoadContentStatusCommentOrTaskCountsParams,
    abortSignal?: AbortSignal,
  ) => ThunkPromise;
  readonly requestTokenFactory: RequestTokenFactory<
    (requestTrigger: ItemsRequestTrigger) => Action
  >;
}

export interface ILoadListingItemsParams {
  readonly doesCurrentStateStillMatchArguments: DoesCurrentStateStillMatchArguments;
  readonly filter: IListingFilter;
  readonly getScrollTableState: (getFreshState: GetState) => ScrollTableState;
  readonly itemHeightPx: number;
  readonly languageId: Uuid;
  readonly orderBy: OrderBy<ItemColumnCode>;
  readonly projectId: Uuid;
  readonly scrollPositionChanged: boolean;
  readonly variantIds: ReadonlySet<Uuid>;
}

export type DoesCurrentStateStillMatchArguments = (
  currentState: { projectId: Uuid; languageId: Uuid | null },
  args: { projectId: Uuid; languageId: Uuid },
) => boolean;

const filterOutRemovedTemporaryContentItem =
  (temporaryItem: ITemporaryContentItem | null) =>
  (items: ReadonlyArray<IListingContentItem>): ReadonlyArray<IListingContentItem> => {
    if (temporaryItem && temporaryItem.itemState === TemporaryContentItemState.Loaded) {
      return items.filter((item) => item.item.id !== temporaryItem.itemId);
    }

    return items;
  };

export const getFreshTableState = (state: IStore) =>
  convertFromPixelsToItems({
    stats: state.contentApp.listingUi.contentItemListingScrollTableState,
    itemHeight: getContentItemScrollTableRowHeightPx(),
    totalNumberOfItems: null,
  });

const isRouteStillCorrect = (params: ILoadListingItemsParams, getState: GetState): boolean => {
  const state = getState();

  return params.doesCurrentStateStillMatchArguments(
    {
      languageId: getSelectedLanguageId(state),
      projectId: getCurrentProjectId(state),
    },
    {
      languageId: params.languageId,
      projectId: params.projectId,
    },
  );
};

/**
 * Reusable action creator that should not get any specific data from store,
 * as it does not know which scroll table with listingContent items is to be rendered.
 */
export const createLoadContentStatusContentItemsAction =
  (deps: IDeps) =>
  (params: ILoadListingItemsParams, abortSignal?: AbortSignal): ThunkPromise =>
  async (dispatch, getState) => {
    const state = getState();
    const {
      contentApp: {
        editorUi: { temporaryItem },
        listingUi: { filter },
      },
    } = state;

    let requestTrigger = params.scrollPositionChanged
      ? ItemsRequestTrigger.UserScroll
      : ItemsRequestTrigger.Other;

    while (
      shouldRequestNewItems({
        continuationToken: getState().data.contentStatusContentItems.nextContinuationToken,
        loadingStatus: getState().data.contentStatusContentItems.loadingStatus,
        numberOfLoadedItems: getListingItemsCount(getState().data.contentStatusContentItems),
        requestTrigger,
        tableState: getFreshTableState(getState()),
      })
    ) {
      const fullTextSearchStatus = getState().contentInventory.fullTextSearchStatus;
      const variantIds: Uuid[] = Array.from(params.variantIds);
      const serverFilter = deps.buildFilterWithContinuationForContentStatusListing({
        continuationToken: shouldUseLastContinuationToken(requestTrigger)
          ? getState().data.contentStatusContentItems.nextContinuationToken
          : null,
        filter: params.filter,
        includePublishedVersions: !areListingFilterAndSearchEmpty(filter),
        maxItemsCount: getMaxItemsForLoading(getFreshTableState(getState())),
        orderBy: params.orderBy,
        useBackUpSearchMethod: fullTextSearchStatus === FullTextSearchStatus.Unavailable,
        variantIds,
      });

      if (!isRouteStillCorrect(params, getState)) {
        return;
      }

      const { tokenizedActionCreator: listingItemsLoadingStarted, isCurrentTokenValid } =
        deps.requestTokenFactory(getState);
      dispatch(listingItemsLoadingStarted(requestTrigger));

      if (requestTrigger === ItemsRequestTrigger.UserScroll) {
        dispatch(
          trackUserEvent(TrackedEvent.FindRightContent, {
            name: FindRightContentTrackedEvent.LoadedListingItemsFromServer,
          }),
        );
      }

      try {
        const items = await deps.missionControlRepository.getContentStatusItems(
          serverFilter,
          abortSignal,
        );

        if (isCurrentTokenValid() && isRouteStillCorrect(params, getState)) {
          const corrections = [
            filterOutRemovedTemporaryContentItem(temporaryItem),
            filterOutPublishedVersionsOfLoadedInventoryItems({
              currentAllIds: Immutable.OrderedSet<Uuid>(
                getState().data.contentStatusContentItems.allIds ?? [],
              ),
              currentByIds: getState().data.contentStatusContentItems.byId,
              requestTrigger,
            }),
          ];

          const correctedContentItems = corrections.reduce(
            (result, correctItems) => correctItems(result),
            items.data.map(getListingContentItemFromJS),
          );

          dispatch(
            contentStatusItemsLoaded({
              contentItems: correctedContentItems,
              continuationToken: items.continuationToken,
              requestTrigger,
              searchMethod: items.searchMethod,
            }),
          );

          const contentItemIds = correctedContentItems
            .map((contentItem) => contentItem.variant?.id)
            .filter(notUndefined);
          await Promise.all([
            dispatch(
              deps.loadContentStatusCommentCounts(
                {
                  contentItemIds,
                },
                abortSignal,
              ),
            ),
            dispatch(
              deps.loadContentStatusTaskCounts(
                {
                  contentItemIds,
                },
                abortSignal,
              ),
            ),
          ]);
        }
      } catch (error) {
        if (isXMLHttpRequest(error) && error.statusText === 'Invalid continuation token') {
          trackError(`Invalid continuation token: ${serverFilter.continuationToken}`); // KCL-10965
        }
        throw error;
      }

      requestTrigger = ItemsRequestTrigger.TooFewItemsLoaded;
    }
  };
