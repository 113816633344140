import { InvariantException } from '@kontent-ai/errors';
import Immutable from 'immutable';
import { Dispatch, GetState, ThunkPromise } from '../../../../../../@types/Dispatcher.type.ts';
import { TrackedEvent } from '../../../../../../_shared/constants/trackedEvent.ts';
import { TrackUserEventAction } from '../../../../../../_shared/models/TrackUserEvent.type.ts';
import {
  CommentAction,
  CommentEventData,
} from '../../../../../../_shared/models/events/CommentEventData.type.ts';
import { ICommentRepository } from '../../../../../../repositories/interfaces/ICommentRepository.type.ts';
import { ICommentThreadServerModel } from '../../../../../../repositories/serverModels/CommentThreadServerModel.type.ts';
import { ICompiledContentType } from '../../../../../contentInventory/content/models/CompiledContentType.ts';
import {
  createCommentContentServerModel,
  isCommentContent,
} from '../../../../models/comments/Comment.ts';
import {
  CommentThreadItemType,
  ICommentThreadItemContentModel,
} from '../../../../models/comments/CommentThreadItem.ts';
import {
  ICommentThread,
  createCommentThreadDomainModel,
} from '../../../../models/comments/CommentThreads.ts';
import { isSuggestionContent } from '../../../../models/comments/Suggestion.ts';
import { EditedContentItemVariant } from '../../../../models/contentItem/edited/EditedContentItemVariant.ts';
import {
  ContentItemEditing_CommentThread_NewItemCreationFinished,
  ContentItemEditing_CommentThread_NewItemCreationStarted,
} from '../../constants/contentItemEditingActionTypes.ts';

const commentThreadItemCreationStarted = (threadId: Uuid) =>
  ({
    type: ContentItemEditing_CommentThread_NewItemCreationStarted,
    payload: { threadId },
  }) as const;

const commentThreadItemCreationFinished = (threadId: Uuid, updatedCommentThread: ICommentThread) =>
  ({
    type: ContentItemEditing_CommentThread_NewItemCreationFinished,
    payload: {
      threadId,
      updatedCommentThread,
    },
  }) as const;

export type CreateCommentThreadItemActionsType = ReturnType<
  typeof commentThreadItemCreationStarted | typeof commentThreadItemCreationFinished
>;

interface ICreateCommentThreadItemActionDependencies {
  readonly commentRepository: ICommentRepository;
  readonly trackUserEvent: TrackUserEventAction;
}

function getCommentIntercomEventPayload(
  commentThread: ICommentThread,
  commentType: CommentThreadItemType,
  contentItemTypes: Immutable.Map<Uuid, ICompiledContentType>,
): CommentEventData {
  const action =
    commentType === CommentThreadItemType.Suggestion
      ? CommentAction.SuggestionCreated
      : CommentAction.CommentCreated;

  if (!commentThread.elementId) {
    return {
      action,
      commentThreadId: commentThread.id,
      threadItemsDepth: commentThread.threadItems.length,
    };
  }

  const contentItemTypeElements = contentItemTypes
    .toArray()
    .flatMap((type) => type.contentElements);
  const typeElement = contentItemTypeElements.find(
    (element) => element.elementId === commentThread.elementId,
  );

  return {
    action,
    'element-type': typeElement?.type,
    commentThreadId: commentThread.id,
    threadItemsDepth: commentThread.threadItems.length,
  };
}

const createThreadItem = (
  commentRepository: ICommentRepository,
  editedContentItemVariant: EditedContentItemVariant,
  threadId: Uuid,
  content: ICommentThreadItemContentModel,
): Promise<ICommentThreadServerModel> => {
  const { itemId, variantId } = editedContentItemVariant.id;

  if (isCommentContent(content)) {
    return commentRepository.addComment(itemId, variantId, threadId, {
      type: CommentThreadItemType.Comment,
      content: createCommentContentServerModel(content.content),
    });
  }

  if (isSuggestionContent(content)) {
    return commentRepository.addSuggestion(itemId, variantId, threadId, {
      type: CommentThreadItemType.Suggestion,
      suggestedText: content.suggestedText,
    });
  }

  throw InvariantException('Content is neither comment nor suggestion.');
};

export const createCreateCommentThreadItemAction =
  (deps: ICreateCommentThreadItemActionDependencies) =>
  (
    threadId: Uuid,
    type: CommentThreadItemType,
    content: ICommentThreadItemContentModel,
  ): ThunkPromise =>
  async (dispatch: Dispatch, getState: GetState): Promise<void> => {
    const {
      contentApp: { editedContentItemVariant, loadedContentItemTypes },
    } = getState();

    const isContentValid =
      (isCommentContent(content) && content.content.hasText()) ||
      (isSuggestionContent(content) && !!content.suggestedText);

    if (!editedContentItemVariant || !isContentValid) {
      return;
    }

    dispatch(commentThreadItemCreationStarted(threadId));

    const updatedThreadServerModel = await createThreadItem(
      deps.commentRepository,
      editedContentItemVariant,
      threadId,
      content,
    );

    const updatedThread = createCommentThreadDomainModel(updatedThreadServerModel);
    dispatch(commentThreadItemCreationFinished(threadId, updatedThread));

    const eventPayload = getCommentIntercomEventPayload(
      updatedThread,
      type,
      loadedContentItemTypes,
    );
    dispatch(deps.trackUserEvent(TrackedEvent.Comments, eventPayload));
  };
