import { ThunkPromise } from '../../../../../@types/Dispatcher.type.ts';
import {
  IProjectContributor,
  createProjectContributorFromServerModel,
} from '../../../../../data/models/users/ProjectContributor.ts';
import { IPeopleRepository } from '../../../../../repositories/interfaces/IPeopleRepository.type.ts';
import { Users_ProjectContributorsLoaded } from '../../constants/usersActionTypes.ts';

interface IDeps {
  readonly peopleRepository: Pick<IPeopleRepository, 'getProjectContributors'>;
}

const projectContributorsLoaded = (users: ReadonlyArray<IProjectContributor>) =>
  ({
    type: Users_ProjectContributorsLoaded,
    payload: { users },
  }) as const;

export type LoadProjectContributorsActionsType = ReturnType<typeof projectContributorsLoaded>;

export const createLoadProjectContributorsAction =
  ({ peopleRepository }: IDeps) =>
  (abortSignal?: AbortSignal): ThunkPromise =>
  async (dispatch) => {
    const contributorsServerModel = await peopleRepository.getProjectContributors(abortSignal);
    const users = contributorsServerModel.map(createProjectContributorFromServerModel);
    dispatch(projectContributorsLoaded(users));
  };
