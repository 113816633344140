import { RequiredA11yLabellingProps } from '@kontent-ai/component-library/component-utils';
import { ShortcutsConfig, useHotkeys } from '@kontent-ai/component-library/hooks';
import { getFocusableTreeWalker } from '@react-aria/focus';
import { HTMLAttributes, RefObject } from 'react';

type Props = RequiredA11yLabellingProps & Readonly<{ onClose: () => void }>;

export const useAccessibleLinkedItem = (
  { 'aria-label': ariaLabel, 'aria-labelledby': ariaLabelledby, onClose }: Props,
  expandedContentRef: RefObject<HTMLElement>,
) => {
  const getTreeWalker = (): TreeWalker | null =>
    expandedContentRef.current
      ? getFocusableTreeWalker(expandedContentRef.current, { tabbable: true })
      : null;

  const hotkeysProps = useHotkeys(
    {
      [ShortcutsConfig.Tab]: (e) => {
        const treeWalker = getTreeWalker();
        const lastFocusableChild = treeWalker?.lastChild();
        if (lastFocusableChild?.isSameNode(document.activeElement) || lastFocusableChild === null) {
          e.preventDefault();
          onClose();
        }
      },
      [ShortcutsConfig.ShiftTab]: (e) => {
        const treeWalker = getTreeWalker();
        const firstFocusableChild = treeWalker?.firstChild();
        if (
          firstFocusableChild?.isSameNode(document.activeElement) ||
          firstFocusableChild === null
        ) {
          e.preventDefault();
          onClose();
        }
      },
      [ShortcutsConfig.Escape]: (e) => {
        e.stopPropagation();
        onClose();
      },
    },
    { ref: expandedContentRef },
  );

  const ariaProps = {
    'aria-label': ariaLabel,
    'aria-labelledby': ariaLabelledby,
    tabIndex: -1,
  } satisfies HTMLAttributes<HTMLElement>;

  return {
    expandedContentProps: { ...ariaProps, ...hotkeysProps },
  };
};
