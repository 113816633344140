import { isEmptyOrWhitespace } from './stringUtils.ts';

export function getUrlRelativeOrWithProtocol(url: string): string {
  const protocolPrefix = 'http://';
  const addDefaultProtocol = allowsExecution(url) || !startsWithProtocolOrIsRelative(url);

  return addDefaultProtocol ? protocolPrefix.concat(url) : url;
}

function allowsExecution(uri: string): boolean {
  // Executable uri examples
  // javascript:alert()
  // javascript://%0aalert()
  // data:text/html,<script>alert()</script>
  // It may be even prefixed by white space
  const unsafe = ['javascript:', 'data:', 'vbscript:'];
  try {
    const cleanUrl = decodeURIComponent(uri)
      .replace(/[^\w:]/g, '')
      .toLowerCase();
    return unsafe.some((x) => cleanUrl.startsWith(x));
  } catch {
    return true;
  }
}

function startsWithProtocolOrIsRelative(url: string): boolean {
  // We want to accept all protocols eg. for deeplinking like [scheme]://[host]/[path]
  const protocolsRegex = /^(([A-Z]+:\/\/.+)|\?|#|\/)/i;
  return protocolsRegex.test(url);
}

export function isAbsoluteWebUrl(url: string): boolean {
  try {
    const validUrl = new URL(url);
    return (
      (validUrl.protocol === 'https:' || validUrl.protocol === 'http:') &&
      url.startsWith(`${validUrl.protocol}//`)
    );
  } catch {
    return false;
  }
}

export function isValidUrl(url: string): boolean {
  try {
    const validUrl = new URL(url);
    return !!validUrl;
  } catch {
    return false;
  }
}

export function urlStartsWithHttp(url: string): boolean {
  try {
    const validUrl = new URL(url);
    return validUrl.protocol === 'http:';
  } catch {
    return false;
  }
}

export function urlStartsWithHttps(url: string): boolean {
  try {
    const validUrl = new URL(url);
    return validUrl.protocol === 'https:';
  } catch {
    return false;
  }
}

export function isLocalhostUrl(url: string): boolean {
  try {
    const validUrl = new URL(url);
    return validUrl.hostname === 'localhost';
  } catch {
    return false;
  }
}

export function getUrlOrigin(url: string): string {
  if (isEmptyOrWhitespace(url)) {
    return '';
  }

  const u = new URL(url);
  return u.origin;
}
