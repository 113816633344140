import { Spacing } from '@kontent-ai/component-library/tokens';
import React from 'react';
import { DropDownFrame } from '../../../../../../../_shared/uiComponents/DropDown/DropDownFrame.tsx';
import { IDropdownTippyOptions } from '../../../../../../../_shared/uiComponents/DropDown/dropDownTippyOptions.ts';
import {
  DataUiCollection,
  getDataUiCollectionAttribute,
} from '../../../../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';

type QuickActionsDropDownMenuProps = {
  readonly onClickOutside: () => void;
  readonly renderButton: (ref: React.RefObject<HTMLElement> | undefined) => React.ReactElement;
};

const tippyOptions: IDropdownTippyOptions = {
  placement: 'bottom-start',
  popperOptions: {
    modifiers: [
      {
        name: 'flip',
        options: {
          fallbackPlacements: ['left-start', 'right-start', 'left', 'right'],
        },
      },
      {
        name: 'preventOverflow',
        options: {
          padding: Spacing.S,
        },
      },
    ],
  },
};

export const QuickActionsDropDownMenu: React.FC<
  React.PropsWithChildren<QuickActionsDropDownMenuProps>
> = ({ children, onClickOutside, renderButton }) => (
  <DropDownFrame
    allowAnimation={false}
    isOptionListVisible
    longer
    optionListDataUiAttributes={getDataUiCollectionAttribute(DataUiCollection.QuickActionDropdown)}
    onClickOutside={onClickOutside}
    renderContent={() => children}
    renderSelector={renderButton}
    tippyOptions={tippyOptions}
  />
);
