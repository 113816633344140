import { Collection, alphabetically, createCompare, noOperation } from '@kontent-ai/utils';
import { useCallback, useContext } from 'react';
import { useParams } from 'react-router';
import { trackUserEvent } from '../../../../../../_shared/actions/thunks/trackUserEvent.ts';
import { VariantRouteParams } from '../../../../../../_shared/constants/routePaths.ts';
import {
  FindRightContentTrackedEvent,
  TrackedEvent,
} from '../../../../../../_shared/constants/trackedEvent.ts';
import { DefaultVariantId } from '../../../../../../_shared/constants/variantIdValues.ts';
import { useDataSelector } from '../../../../../../_shared/hooks/useDataSelector.ts';
import { useDispatch } from '../../../../../../_shared/hooks/useDispatch.ts';
import { useSelector } from '../../../../../../_shared/hooks/useSelector.ts';
import { LoadingStatus } from '../../../../../../_shared/models/LoadingStatusEnum.ts';
import { ContentItemFilterOrigin } from '../../../../../../_shared/models/events/ContentItemFilterEventData.type.ts';
import { areCollectionsVisibleForSpaces } from '../../../../../../_shared/selectors/contentCollections.ts';
import { getSelectedLanguageId } from '../../../../../../_shared/selectors/getSelectedLanguageId.ts';
import { getIsAiSearchUsed } from '../../../../../../paperModels/aiSearch/utils/getIsAiSearchUsed.ts';
import { ContentTypeAsComponentUsageDialogContext } from '../../../../../contentModels/contentTypes/context/ContentTypeAsComponentUsageDialogContext.tsx';
import { areSpacesInCollectionsEnabled as areSpacesInCollectionsEnabledSelector } from '../../../../../environmentSettings/selectors/allowedFeaturesUtils.ts';
import { isFullTextSearchUnavailableOrDisabled } from '../../../../shared/selectors/fullTextSearchStatus.ts';
import { IListingFilter } from '../../../models/filter/IListingFilter.ts';
import { getFindRightContentFilterEventData } from '../../ContentItemInventory/utils/getFindRightContentTrackingPayloads.ts';
import {
  onCollectionFilterChanged,
  onContentItemStatusFilterChanged,
  onContentTypeFilterChanged,
  onContributorsFilterChanged,
  onPublishingStatusFilterChanged,
  onSitemapFilterChanged,
  onSpaceFilterChanged,
  onTaxonomyGroupsFilterChanged,
  onWorkflowStatusFilterChanged,
} from '../actions/listingFilterActions.ts';
import { createLinkedFilter, saveFilter } from '../actions/thunkListingFilterActions.ts';
import { ContentItemFilter as ContentItemFilterComponent } from '../components/ContentItemFilter.tsx';
import { ContentItemFilterSelectors } from '../components/ContentItemFilterSelectors.tsx';
import {
  ContentItemFilter_Left,
  ContentItemFilter_SetUp,
} from '../constants/listingFilterActionTypes.ts';
import { PreselectedFilter } from '../hooks/useSetUpContentItemFilter.ts';

type Props = {
  readonly clearFilter: () => void;
  readonly forceContentTypesTooltipText?: string | undefined;
  readonly forcedContentTypeIds?: ReadonlySet<Uuid>;
  readonly hideVariantSpecificInfo?: boolean;
  readonly listingItemsLoadingStatus: LoadingStatus;
  readonly onFilterChange: () => void;
  readonly origin: ContentItemFilterOrigin;
  readonly preselectedFilter?: PreselectedFilter;
};

export const ContentItemFilter = (props: Props) => {
  const { variantId } = useParams<VariantRouteParams>();

  const savedFiltersUi = useSelector((s) => s.contentApp.listingUi.savedFiltersUi);
  const filtersById = useDataSelector((data) => data.filters.byId);
  const storedFilters = Collection.getValues(filtersById).toSorted(
    createCompare({ compare: alphabetically, select: (t) => t.name }),
  );
  const filter = useSelector((s) => s.contentApp.listingUi.filter);
  const isFilterBeingCreated = useSelector(
    (s) => s.contentApp.listingUi.savedFiltersUi.isFilterBeingCreated,
  );
  const user = useDataSelector((data) => data.user);

  const isInContentTypeAsComponentUsageDialog = useContext(
    ContentTypeAsComponentUsageDialogContext,
  );
  const displayTaxonomyWarningStatus = useSelector((s) => {
    const fullTextSearchUnavailableOrDisabled = isFullTextSearchUnavailableOrDisabled(s);
    const isSelectedLanguageDefault = getSelectedLanguageId(s) === DefaultVariantId;
    return (
      fullTextSearchUnavailableOrDisabled &&
      (!isSelectedLanguageDefault || isInContentTypeAsComponentUsageDialog)
    );
  });
  const areSpacesInCollectionsEnabled = useSelector(areSpacesInCollectionsEnabledSelector);

  const dispatch = useDispatch();
  const workflows = useDataSelector((data) => data.workflows.byId);
  const filterSetUp = useCallback(
    (listingFilter: IListingFilter) => {
      dispatch(contentItemFilterSetUp(listingFilter, props.origin));
      dispatch(
        trackUserEvent(TrackedEvent.FindRightContent, {
          name: FindRightContentTrackedEvent.OpenedContentItemInventoryPage,
          ...getFindRightContentFilterEventData(
            listingFilter,
            props.origin,
            Collection.getValues(workflows),
          ),
        }),
      );
    },
    [props.origin, workflows],
  );
  const filterLeft = useCallback(
    () => dispatch(contentItemFilterLeft(props.origin)),
    [props.origin],
  );

  const displaySpacesFilter = useSelector((state) =>
    areCollectionsVisibleForSpaces(state, Collection.getValues(state.data.collections.byId)),
  );

  const isAiSearchUsed = useSelector((s) => getIsAiSearchUsed(s.contentApp.listingUi.filter));

  return (
    <ContentItemFilterComponent
      filter={filter}
      filterLeft={filterLeft}
      filterSetUp={filterSetUp}
      filters={storedFilters}
      forcedContentTypeIds={props.forcedContentTypeIds}
      hideVariantSpecificInfo={props.hideVariantSpecificInfo}
      isFilterBeingCreated={isFilterBeingCreated}
      listingItemsLoadingStatus={props.listingItemsLoadingStatus}
      onClearFilter={props.clearFilter}
      onCreateLinkedFilter={() => dispatch(createLinkedFilter())}
      onFilterChange={props.onFilterChange}
      onSaveFilter={(name) => dispatch(saveFilter({ name }))}
      origin={props.origin}
      preselectedFilter={props.preselectedFilter}
      renderSelectors={() => (
        <ContentItemFilterSelectors
          areSpacesInCollectionsEnabled={areSpacesInCollectionsEnabled}
          displaySpacesFilter={displaySpacesFilter}
          displayTaxonomyWarningStatus={displayTaxonomyWarningStatus}
          filter={filter}
          forceContentTypesTooltipText={props.forceContentTypesTooltipText}
          forcedContentTypeIds={props.forcedContentTypeIds}
          isAiSearchUsed={isAiSearchUsed}
          languageId={variantId}
          onCollectionSelectionChanged={(ids) => dispatch(onCollectionFilterChanged(ids))}
          onContentItemStatusSelectionChanged={(status) =>
            dispatch(onContentItemStatusFilterChanged(status))
          }
          onContentTypesSelectionChanged={
            props.forcedContentTypeIds
              ? noOperation
              : (ids) => dispatch(onContentTypeFilterChanged(ids))
          }
          onContributorsSelectionChanged={(ids) => dispatch(onContributorsFilterChanged(ids))}
          onPublishingStatusSelectionChanged={(publishingStatus) =>
            dispatch(onPublishingStatusFilterChanged(publishingStatus))
          }
          onSitemapSelectionChanged={(selectedSitemapNodes) =>
            dispatch(onSitemapFilterChanged(selectedSitemapNodes))
          }
          onSpaceSelectionChanged={(ids) => dispatch(onSpaceFilterChanged(ids))}
          onTaxonomySelectionChanged={(groupToTerms) =>
            dispatch(onTaxonomyGroupsFilterChanged(groupToTerms))
          }
          onWorkflowsSelectionChanged={(...args) =>
            dispatch(onWorkflowStatusFilterChanged(...args))
          }
          user={user}
        />
      )}
      savedFilters={filtersById}
      savedFiltersUi={savedFiltersUi}
    />
  );
};

const contentItemFilterSetUp = (filter: IListingFilter, origin: ContentItemFilterOrigin) =>
  ({
    type: ContentItemFilter_SetUp,
    payload: {
      filter,
      origin,
    },
  }) as const;

const contentItemFilterLeft = (origin: ContentItemFilterOrigin) =>
  ({
    type: ContentItemFilter_Left,
    payload: {
      origin,
    },
  }) as const;

export type ContentItemFilterActionTypes = ReturnType<
  typeof contentItemFilterSetUp | typeof contentItemFilterLeft
>;

export const isContentItemFilterInitialized = (
  origin: ContentItemFilterOrigin,
  filterStatus: ReadonlyRecord<ContentItemFilterOrigin, LoadingStatus>,
): boolean => filterStatus[origin] === LoadingStatus.Loaded;
