import { assert } from '@kontent-ai/utils';
import {
  Dispatch,
  GetState,
  ThunkFunction,
} from '../../../../../../../../@types/Dispatcher.type.ts';
import { modalOpened } from '../../../../../../../../_shared/actions/sharedActions.ts';
import { ModalDialogType } from '../../../../../../../../_shared/constants/modalDialogType.ts';
import { setInitialChangeWorkflowStepModalData } from '../../../../../../../../_shared/features/ChangeWorkflowStepModal/actions/changeWorkflowStepModalActions.ts';
import { createInitialChangeWorkflowStepModalDataForBulk } from '../../../../../../../../_shared/features/ChangeWorkflowStepModal/selectors/createInitialChangeWorkflowStepModalDataForBulk.ts';
import { OpenWorkflowStepModalAction } from '../../../../../../../../_shared/features/ChangeWorkflowStepModal/types/workflowStepModalActionTypes.ts';

export type IOpenBulkChangeWorkflowStepModal = (
  workflowAction: OpenWorkflowStepModalAction,
) => ThunkFunction;

export const openBulkChangeWorkflowStepModal =
  (workflowAction: OpenWorkflowStepModalAction): ThunkFunction =>
  (dispatch: Dispatch, getState: GetState) => {
    const changeWorkflowStepModalData = createInitialChangeWorkflowStepModalDataForBulk(
      getState(),
      workflowAction,
    );

    assert(
      changeWorkflowStepModalData,
      () =>
        'openBulkChangeWorkflowStepModal.ts: Failed to create initial change workflow step modal data.',
    );

    dispatch(setInitialChangeWorkflowStepModalData(changeWorkflowStepModalData));
    dispatch(modalOpened(ModalDialogType.ChangeWorkflowStepListingDialog));
  };
