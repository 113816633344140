import { ComponentProps, forwardRef } from 'react';
import { useDispatch } from '../../../../../../_shared/hooks/useDispatch.ts';
import { useSelector } from '../../../../../../_shared/hooks/useSelector.ts';
import { getCurrentUser } from '../../../../../../_shared/selectors/getCurrentUser.ts';
import {
  CommentThreadItemType,
  ICommentThreadItemContentModel,
} from '../../../../models/comments/CommentThreadItem.ts';
import { commentThreadItemEditingCanceled } from '../../actions/contentItemEditingActions.ts';
import {
  blurCommentThread,
  focusCommentThread,
  saveNewInlineCommentThreadToServer,
} from '../../actions/thunkContentItemEditingActions.ts';
import { UnsavedInlineCommentThread as UnsavedInlineCommentThreadComponent } from '../../components/comments/UnsavedInlineCommentThread.tsx';
import { getNewCommentThreadItemType } from '../../utils/inlineCommentUtils.ts';

type Props = Pick<
  ComponentProps<typeof UnsavedInlineCommentThreadComponent>,
  'commentThread' | 'onHighlighted'
>;

export const UnsavedInlineCommentThread = forwardRef<HTMLDivElement, Props>((props, ref) => {
  const { commentThread } = props;

  const currentUser = useSelector(getCurrentUser);
  const isHighlighted = useSelector(
    (s) =>
      s.contentApp.editedContentItemVariantComments.focusedCommentThreadId === commentThread.id,
  );
  const type = useSelector((s) =>
    getNewCommentThreadItemType(
      s.contentApp.editorUi.newCommentThreadItemTypeMappings,
      commentThread.id,
    ),
  );

  const dispatch = useDispatch();
  const onBlur = () => dispatch(blurCommentThread());
  const onCancel = () => dispatch(commentThreadItemEditingCanceled(commentThread.id));
  const onSubmit = (itemType: CommentThreadItemType, content: ICommentThreadItemContentModel) =>
    dispatch(saveNewInlineCommentThreadToServer(commentThread.id, itemType, content));
  const onFocus = () => dispatch(focusCommentThread(commentThread.id));

  return (
    <UnsavedInlineCommentThreadComponent
      {...props}
      currentUser={currentUser}
      isHighlighted={isHighlighted}
      onBlur={onBlur}
      onCancel={onCancel}
      onFocus={onFocus}
      onSubmit={onSubmit}
      type={type}
      ref={ref}
    />
  );
});

UnsavedInlineCommentThread.displayName = 'UnsavedInlineCommentThread';
