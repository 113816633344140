import { Button } from '@kontent-ai/component-library/Button';
import { Icons } from '@kontent-ai/component-library/Icons';
import { NotificationBarAlert } from '@kontent-ai/component-library/NotificationBar';
import { Callback } from '../../../../../../_shared/types/RegisterCallback.type.ts';
import { PreviewApiDataStatus } from '../../../../../webSpotlight/models/PreviewApiDataStatus.ts';

type Props = Readonly<{
  onDismiss: Callback;
  onRefresh: Callback;
  previewDataStatus: PreviewApiDataStatus;
}>;

export const PreviewRefreshErrorNotification = ({ onDismiss, onRefresh }: Props) => {
  return (
    <NotificationBarAlert
      actions={{
        alignment: 'left',
        renderActions: () => (
          <Button buttonStyle="primary-inverse" size="small" onClick={onRefresh}>
            <Button.Icon icon={Icons.RotateDoubleRight} />
            <Button.Label>Refresh</Button.Label>
          </Button>
        ),
      }}
      onDismiss={onDismiss}
    >
      Something went wrong. Try manual refresh.
    </NotificationBarAlert>
  );
};
