import { Icons } from '@kontent-ai/component-library/Icons';
import React from 'react';
import {
  RemoveUnpublishingDate,
  RescheduleUnpublishing,
} from '../../../../../../_shared/constants/itemActions.ts';
import { useDispatch } from '../../../../../../_shared/hooks/useDispatch.ts';
import { useSelector } from '../../../../../../_shared/hooks/useSelector.ts';
import { ActiveCapabilityType } from '../../../../../../_shared/models/activeCapability.type.ts';
import { ContentItemEditingEventOrigins } from '../../../../../../_shared/models/events/ContentItemEditingEventData.type.ts';
import { DataUiWorkflowAction } from '../../../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';
import { hasActiveVariantCapabilityForEditedItem } from '../../../../../../_shared/utils/permissions/activeCapabilities.ts';
import { itemEditingModalWithPropertiesOpened } from '../../../../actions/contentActions.ts';
import { ItemEditingModalDialogType } from '../../../../constants/itemEditingModalDialogType.ts';
import { openUnpublishAndArchiveModal } from '../../../ChangeWorkflowStep/actions/thunks/changeWorkflowStepModalActions.ts';
import { WorkflowStepActionButton } from '../../components/details/WorkflowStepActionButton.tsx';

export const ScheduledUnpublishAssignmentSectionActions: React.FC = () => {
  const isScheduledToUnpublish = useSelector(
    (s) => !!s.contentApp.editedContentItemVariant?.assignment.scheduledToUnpublishAt,
  );
  const isScheduledToPublish = useSelector(
    (s) => !!s.contentApp.editedContentItemVariant?.assignment.scheduledToPublishAt,
  );
  const canUnpublish = useSelector((s) =>
    hasActiveVariantCapabilityForEditedItem(ActiveCapabilityType.Unpublish, s),
  );
  const dispatch = useDispatch();
  if (!isScheduledToUnpublish || !canUnpublish) {
    return null;
  }

  return (
    <>
      {!isScheduledToPublish && (
        <WorkflowStepActionButton
          buttonStyle="tertiary"
          label={RescheduleUnpublishing}
          icon={Icons.BoxClock}
          onClick={() =>
            dispatch(openUnpublishAndArchiveModal(ContentItemEditingEventOrigins.ItemDetails))
          }
          dataUiAction={DataUiWorkflowAction.Unpublish}
        />
      )}
      <WorkflowStepActionButton
        buttonStyle="secondary"
        dataUiAction={DataUiWorkflowAction.CancelScheduledUnpublish}
        destructive
        label={RemoveUnpublishingDate}
        onClick={() =>
          dispatch(
            itemEditingModalWithPropertiesOpened({
              dialogType: ItemEditingModalDialogType.CancelItemScheduledUnpublishConfirmationDialog,
              actionOrigin: ContentItemEditingEventOrigins.ItemDetails,
            }),
          )
        }
      />
    </>
  );
};
