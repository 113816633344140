import { DefaultVariantAssignment as Component } from '../../../../../../../_shared/components/VariantAssignments/DefaultVariantAssignment.tsx';
import { useDispatch } from '../../../../../../../_shared/hooks/useDispatch.ts';
import { IVariantStatusVariant } from '../../../../../../../_shared/models/VariantModels.type.ts';
import { ContentItemEditingEventOrigins } from '../../../../../../../_shared/models/events/ContentItemEditingEventData.type.ts';
import { itemEditingModalWithPropertiesOpened } from '../../../../../actions/contentActions.ts';
import { ItemEditingModalDialogType } from '../../../../../constants/itemEditingModalDialogType.ts';

type Props = {
  readonly enableDiscard: boolean;
  readonly variant: IVariantStatusVariant;
  readonly showDueDate?: boolean;
};

export const DefaultVariantAssignment: React.FC<Props> = ({
  variant,
  showDueDate,
  enableDiscard,
}) => {
  const dispatch = useDispatch();
  const openDiscardNewVersionConfirmationDialog = () =>
    dispatch(
      itemEditingModalWithPropertiesOpened({
        dialogType: ItemEditingModalDialogType.DiscardNewVersionConfirmationDialog,
        actionOrigin: ContentItemEditingEventOrigins.ItemDetails,
      }),
    );

  return (
    <Component
      showDueDate={showDueDate}
      variant={variant}
      onDiscardNewVersion={enableDiscard ? openDiscardNewVersionConfirmationDialog : undefined}
    />
  );
};
