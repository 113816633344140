import React from 'react';
import {
  ItemDetailSection,
  getDataUiObjectNameAttribute,
} from '../../../../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';
import { renderDatetimeString } from '../../../../../../../_shared/utils/dateTime/timeUtils.ts';
import { formatUserName } from '../../../../../../../_shared/utils/users/usersUtils.ts';
import { IContributorNote } from '../../../models/IContributorNote.type.ts';

interface INoteAuthorProps {
  readonly note: IContributorNote | null;
}

export const NoteAuthor: React.FC<INoteAuthorProps> = ({ note }) =>
  note?.addedBy && (
    <div
      className="content-item-toolbar__note-author sidebar__section-item sidebar__section-item--adjacent"
      {...getDataUiObjectNameAttribute(ItemDetailSection.NoteAuthor)}
    >
      &mdash; {formatUserName(note.addedBy)} ({renderDatetimeString(note.addedAt)})
    </div>
  );
