import { Collection } from '@kontent-ai/utils';
import {
  FindRightContentTrackedEvent,
  TrackedContentItemFilterPropertyName,
  getFilterFindTheRightContentTrackedEventName,
} from '../../../../../../_shared/constants/trackedEvent.ts';
import { ContentItemFilterOrigin } from '../../../../../../_shared/models/events/ContentItemFilterEventData.type.ts';
import {
  FilterFindRightContentEventData,
  FindRightContentEventActionEventData,
  FindRightContentFilterEventData,
  SearchByNameEventData,
} from '../../../../../../_shared/models/events/FindRightContentEventData.type.ts';
import { MapUtils } from '../../../../../../_shared/utils/mapUtils/mapUtils.ts';
import { SetUtils } from '../../../../../../_shared/utils/setUtils/setUtils.ts';
import {
  Workflow,
  numberOfSystemWorkflowSteps,
} from '../../../../../../data/models/workflow/Workflow.ts';
import { checkEqualityOfContentItemStatus } from '../../../models/filter/IContentItemStatus.ts';
import { IListingFilter, emptyListingFilter } from '../../../models/filter/IListingFilter.ts';
import { checkEqualityOfPublishingStates } from '../../../models/filter/IPublishingState.ts';

export const getFindRightContentFilterEventData = (
  filter: IListingFilter,
  origin: ContentItemFilterOrigin,
  workflows: ReadonlyArray<Workflow>,
): FindRightContentFilterEventData => ({
  origin,
  searchPhrase: filter.searchPhrase,
  searchScope: JSON.stringify(Array.from(filter.searchScope)),
  ...Object.fromEntries(
    Array.from(getFilterTrackingData(emptyListingFilter, filter, workflows)).map(
      ({ name, count }) => [name, count],
    ),
  ),
});

export const getFindRightContentFilterChangeTrackingPayloads = (
  oldFilterState: IListingFilter,
  newFilterState: IListingFilter,
  origin: ContentItemFilterOrigin,
  workflows: ReadonlyArray<Workflow>,
): ReadonlyArray<FindRightContentEventActionEventData> => {
  const filterEvents: ReadonlyArray<FilterFindRightContentEventData> = Array.from(
    getFilterTrackingData(oldFilterState, newFilterState, workflows),
  ).map(
    ({ name, count }) =>
      ({
        count,
        name: getFilterFindTheRightContentTrackedEventName(name),
        origin,
      }) satisfies FilterFindRightContentEventData,
  );

  const searchEvent: SearchByNameEventData | null =
    (!oldFilterState.searchPhrase && !!newFilterState.searchPhrase) ||
    (!newFilterState.searchPhrase.includes(oldFilterState.searchPhrase) &&
      !oldFilterState.searchPhrase.includes(newFilterState.searchPhrase)) ||
    !SetUtils.isEqual(oldFilterState.searchScope, newFilterState.searchScope)
      ? ({
          name: FindRightContentTrackedEvent.FilteredByName,
          searchPhrase: newFilterState.searchPhrase,
          searchScope: [...newFilterState.searchScope],
          origin,
        } satisfies SearchByNameEventData)
      : null;

  return [...filterEvents, ...(searchEvent ? [searchEvent] : [])];
};

type FilterFindRightContentTrackingData = {
  readonly name: TrackedContentItemFilterPropertyName;
  readonly count: number;
};

export function* getFilterTrackingData(
  oldFilterState: IListingFilter,
  newFilterState: IListingFilter,
  workflows: ReadonlyArray<Workflow>,
): Generator<FilterFindRightContentTrackingData> {
  const {
    selectedSpacesNodes: oldSpaces,
    selectedCollectionsNodes: oldCollections,
    selectedContentTypesNodes: oldTypes,
    selectedSitemapNodes: oldSitemapNodes,
    selectedWorkflowNodes: oldWorkflowNodes,
    selectedContributorsNodes: oldContributors,
    selectedTaxonomyNodes: oldTaxonomyTerms,
    selectedContentItemStatus: oldSelectedContentItemStatus,
    selectedPublishingStateNodes: oldPublishingStateNodes,
  } = oldFilterState;
  const {
    selectedSpacesNodes: newSpaces,
    selectedCollectionsNodes: newCollections,
    selectedContentTypesNodes: newTypes,
    selectedSitemapNodes: newSitemapNodes,
    selectedWorkflowNodes: newWorkflowNodes,
    selectedContributorsNodes: newContributors,
    selectedTaxonomyNodes: newTaxonomyTerms,
    selectedContentItemStatus: newSelectedContentItemStatus,
    selectedPublishingStateNodes: newPublishingStateNodes,
  } = newFilterState;

  if (!SetUtils.isEqual(newTypes, oldTypes)) {
    yield { name: TrackedContentItemFilterPropertyName.Type, count: newTypes.size };
  }

  if (!SetUtils.isEqual(newSitemapNodes, oldSitemapNodes)) {
    yield {
      name: TrackedContentItemFilterPropertyName.Sitemap,
      count: newSitemapNodes.size,
    };
  }

  if (!SetUtils.isEqual(newSpaces, oldSpaces)) {
    yield { name: TrackedContentItemFilterPropertyName.Spaces, count: newSpaces.size };
  }

  if (!SetUtils.isEqual(newCollections, oldCollections)) {
    yield {
      name: TrackedContentItemFilterPropertyName.Collections,
      count: newCollections.size,
    };
  }

  if (!MapUtils.isEqual(newWorkflowNodes, oldWorkflowNodes, SetUtils.isEqual)) {
    yield {
      name: TrackedContentItemFilterPropertyName.Workflow,
      count: Collection.getEntries(newWorkflowNodes).reduce((count, [workflowId, stepIds]) => {
        const stepIdsCount = stepIds.size
          ? stepIds.size
          : (workflows.find((workflow) => workflow.id === workflowId)?.steps.length ?? 1) +
            numberOfSystemWorkflowSteps;
        return count + stepIdsCount;
      }, 0),
    };
  }

  if (!SetUtils.isEqual(newContributors, oldContributors)) {
    yield {
      name: TrackedContentItemFilterPropertyName.Contributors,
      count: newContributors.size,
    };
  }

  if (!MapUtils.isEqual(oldTaxonomyTerms, newTaxonomyTerms, SetUtils.isEqual)) {
    yield {
      name: TrackedContentItemFilterPropertyName.Taxonomy,
      count: newTaxonomyTerms.size,
    };
  }

  if (
    !checkEqualityOfContentItemStatus(newSelectedContentItemStatus, oldSelectedContentItemStatus)
  ) {
    yield {
      name: TrackedContentItemFilterPropertyName.RequiredElements,
      count: Object.entries(newSelectedContentItemStatus).filter(([, value]) => value).length,
    };
  }

  if (!checkEqualityOfPublishingStates(newPublishingStateNodes, oldPublishingStateNodes)) {
    yield {
      name: TrackedContentItemFilterPropertyName.PublishingState,
      count: Object.entries(newPublishingStateNodes).filter(([, value]) => value).length,
    };
  }
}
