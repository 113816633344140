import React, { useCallback } from 'react';
import { useHistory } from 'react-router';
import { useDispatch } from '../../../../../_shared/hooks/useDispatch.ts';
import { useSelector } from '../../../../../_shared/hooks/useSelector.ts';
import { getCurrentUser } from '../../../../../_shared/selectors/getCurrentUser.ts';
import { selectIntercomUtils } from '../../../../../_shared/selectors/selectIntercomUtils.ts';
import {
  getNumberOfUsersInActivation,
  getSubscriptionUsage,
  hasAvailableUserSlots,
  hasToUsePaidUserSlot,
  isEmployee,
  isUserCountedInUsage,
} from '../../../../../_shared/utils/subscriptionUsageUtils.ts';
import { isAdmin } from '../../../../../_shared/utils/users/usersUtils.ts';
import {
  IProjectContributor,
  getRolesInAllCollectionsAndLanguages,
} from '../../../../../data/models/users/ProjectContributor.ts';
import { getProjectDetail } from '../../../../../data/reducers/projects/selectors/getProjectsForListing.ts';
import {
  getCurrentProject,
  getCurrentProjectId,
  getCurrentProjectPlan,
} from '../../../../../data/reducers/user/selectors/userProjectsInfoSelectors.ts';
import { getEnvironmentsCount } from '../../../../projectSettings/environments/utils/environmentsUtils.ts';
import { redirectToPlanSelection } from '../../../../subscriptionManagement/shared/utils/subscriptionManagementPathUtils.ts';
import { PredefinedRoles } from '../../../roles/constants/predefinedRoles.ts';
import {
  resendInvite,
  revokeInvite,
  toggleUserActiveState,
} from '../../actions/thunkUsersActions.ts';
import { UserStatusCellContent as UserStatusCellContentComponent } from '../../components/userListing/UserStatusCellContent.tsx';

export interface IUserStatusCellContentOwnProps {
  readonly contributor: IProjectContributor;
}

const isDisabled = (contributor: IProjectContributor, currentUserId: Uuid): boolean =>
  contributor.isAdminOnProject ||
  contributor.isActivating ||
  contributor.isDeactivating ||
  contributor.userId === currentUserId;

export const UserStatusCellContent: React.FC<IUserStatusCellContentOwnProps> = ({
  contributor,
}) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const intercomUtils = useSelector(selectIntercomUtils);

  const currentUserId = useSelector((s) => getCurrentUser(s).userId);
  const subscriptionId = useSelector((s) => getCurrentProject(s).subscriptionId);
  const environmentName = useSelector((s) => getCurrentProject(s).environmentName);
  const areMultipleEnvironments = useSelector(
    (s) => getEnvironmentsCount(s, getCurrentProjectId(s)) > 1,
  );
  const subscriptionUsage = useSelector((s) =>
    getSubscriptionUsage(s.data.subscriptions.subscriptionUsages, subscriptionId),
  );
  const isProjectManager = useSelector((s) =>
    getRolesInAllCollectionsAndLanguages(contributor).some(
      (r) => s.data.roles.rolesById.get(r.roleId)?.codename === PredefinedRoles.ProjectManager,
    ),
  );
  const isCustomPlan = useSelector((s) => getCurrentProjectPlan(s).isCustom);
  const isCurrentUserAdmin = useSelector((s) =>
    isAdmin(getProjectDetail(s, getCurrentProjectId(s))?.subscriptionAdmins ?? [], currentUserId),
  );
  const isUserIncludedUnitsReached = useSelector(
    (s) =>
      !isUserCountedInUsage(s, contributor.userId) && hasToUsePaidUserSlot(s, [contributor.email]),
  );
  const isUserMaxLimitReached = useSelector(
    (s) =>
      !isUserCountedInUsage(s, contributor.userId) &&
      !hasAvailableUserSlots(subscriptionUsage, s.data.plans.byId, getNumberOfUsersInActivation(s)),
  );
  const isCurrentUser = contributor.userId === currentUserId;

  const onResendInvite = useCallback(
    () => dispatch(resendInvite(contributor.userId)),
    [contributor.userId],
  );
  const onRevokeInvite = useCallback(
    () => dispatch(revokeInvite(contributor.userId, subscriptionId)),
    [contributor.userId, subscriptionId],
  );
  const onToggleActiveState = useCallback(
    () =>
      dispatch(
        toggleUserActiveState(contributor.userId, contributor.inactive, subscriptionId, true),
      ),
    [contributor.inactive, contributor.userId, subscriptionId],
  );
  const onSelectPlan = useCallback(
    () =>
      redirectToPlanSelection({
        history,
        subscriptionId,
      }),
    [history, subscriptionId],
  );
  const onShowIntercom = useCallback(() => {
    intercomUtils.showIntercom();
    intercomUtils.showMessage();
  }, [intercomUtils]);

  const disabled = isDisabled(contributor, currentUserId);

  return (
    <UserStatusCellContentComponent
      areMultipleEnvironments={areMultipleEnvironments}
      contributor={contributor}
      disabled={disabled}
      environmentName={environmentName}
      isCurrentUser={isCurrentUser}
      isCurrentUserAdmin={isCurrentUserAdmin}
      isCustomPlan={isCustomPlan}
      isEmployee={isEmployee(contributor.email)}
      isProjectManager={isProjectManager}
      isUserIncludedUnitsReached={isUserIncludedUnitsReached}
      isUserMaxLimitReached={isUserMaxLimitReached}
      onSelectPlan={onSelectPlan}
      onResendInvite={onResendInvite}
      onRevokeInvite={onRevokeInvite}
      onShowIntercom={onShowIntercom}
      onToggleActiveState={onToggleActiveState}
    />
  );
};

UserStatusCellContent.displayName = 'UserStatusCellContent';
