import { useSelector } from '../../../../../../_shared/hooks/useSelector.ts';
import { isWebSpotlightIntegrationEnabled as isWebSpotlightIntegrationEnabledSelector } from '../../../../../../_shared/selectors/isWebSpotlightIntegrationEnabled.tsx';
import { ContentItemPreviewActionBar as ContentItemPreviewActionBarComponent } from '../../components/contentItemPreview/ContentItemPreviewActionBar.tsx';
import { useWebSpotlightInItemEditing } from '../../context/WebSpotlightInItemEditingContext.tsx';

type Props = Readonly<{
  setSpaceId: (newState: Uuid) => void;
  spaceId: Uuid | null;
}>;

export const ContentItemPreviewActionBar = ({ setSpaceId, spaceId }: Props) => {
  const { isAutoRefreshAvailable, isAutoRefreshEnabledByUser, supportedSmartLinkFeatures } =
    useWebSpotlightInItemEditing();

  const isWebSpotlightIntegrationEnabled = useSelector(isWebSpotlightIntegrationEnabledSelector);

  return (
    <ContentItemPreviewActionBarComponent
      spaceId={spaceId}
      setSpaceId={setSpaceId}
      isWebSpotlightIntegrationEnabled={isWebSpotlightIntegrationEnabled}
      isAutoRefreshAvailable={isAutoRefreshAvailable}
      isAutoRefreshEnabled={
        isAutoRefreshEnabledByUser && !!supportedSmartLinkFeatures?.refreshHandler
      }
    />
  );
};
