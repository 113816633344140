import classNames from 'classnames';
import { ForwardedRef, ReactNode } from 'react';
import { BarItemToggleAction } from '../../../../../_shared/components/BarItems/Actions/BarItemToggleAction.tsx';
import { NonExistentBarItemActions } from '../../../../../_shared/components/BarItems/Actions/NonExistentBarItemActions.tsx';
import { FallbacksStatus } from '../../../../../_shared/components/FallbacksForLinkedContent/FallbacksStatus.tsx';
import { FallbacksStatusCompact } from '../../../../../_shared/components/FallbacksForLinkedContent/FallbacksStatusCompact.tsx';
import { NotTranslatedBadge } from '../../../../../_shared/components/Workflow/NotTranslatedBadge.tsx';
import { NotTranslatedTagForVariant } from '../../../../../_shared/components/Workflow/NotTranslatedTagForVariant.tsx';
import { DeletedLinkedItemContent } from '../../../../../_shared/components/linkedItems/DeletedLinkedItemContent.tsx';
import { NonexistentLinkedItemContent } from '../../../../../_shared/components/linkedItems/NonexistentLinkedItemContent.tsx';
import { NotTranslatedOrFallbacksStatus } from '../../../../../_shared/containers/FallbacksForLinkedContent/NotTranslatedOrFallbacksStatus.tsx';
import { WorkflowStatusTagForVariant } from '../../../../../_shared/containers/Workflow/WorkflowStatusTagForVariant.tsx';
import { LoadedLinkedItemContent } from '../../../../../_shared/containers/linkedItems/LoadedLinkedItemContent.tsx';
import { WorkflowBadges } from '../../../../../_shared/uiComponents/WorkflowBadges/WorkflowBadges.tsx';
import {
  VariantCompletionStatus,
  getContentVariantState,
} from '../../../../../_shared/utils/contentItemVariantUtils.ts';
import { IContentType } from '../../../../../data/models/contentModelsApp/contentTypes/ContentType.ts';
import { IListingContentItem } from '../../../../../data/models/listingContentItems/IListingContentItem.ts';
import { ContentItemEditorReachedThresholds } from '../../../types/ContentItemEditorReachedThresholds.type.ts';

type Props = {
  readonly cannotViewDisabledMessage: string | undefined;
  readonly contentItem?: IListingContentItem;
  readonly contentType?: IContentType;
  readonly doNotRenderToggle?: boolean;
  readonly expandedItemId: string;
  readonly isExpanded?: boolean;
  readonly isInvalid?: boolean;
  readonly isPage?: boolean;
  readonly onToggleExpand?: () => void;
  readonly reachedThresholds: ContentItemEditorReachedThresholds;
  readonly triggerButtonForwardedRef?: ForwardedRef<HTMLButtonElement>;
};

export const LinkedItemContent = ({
  cannotViewDisabledMessage,
  contentItem,
  contentType,
  doNotRenderToggle,
  expandedItemId,
  isExpanded,
  isInvalid,
  isPage,
  onToggleExpand,
  reachedThresholds,
  triggerButtonForwardedRef,
}: Props) => {
  if (!contentItem) {
    return (
      <NonexistentLinkedItemContent
        message={`This ${isPage ? 'page' : 'linked item'} doesn’t exist`}
      />
    );
  }
  if (!contentType) {
    return (
      <NonexistentLinkedItemContent
        message={`Content type of this ${isPage ? 'page' : 'linked item'} doesn’t exist`}
      />
    );
  }
  if (contentItem.item.archived) {
    return (
      <DeletedLinkedItemContent
        cannotViewDisabledMessage={cannotViewDisabledMessage}
        name={contentItem.item.name}
        isCompact={reachedThresholds.sizeXS}
        showExpandActionPlaceholder={!doNotRenderToggle}
      />
    );
  }

  const variantData = contentItem.variant;
  const itemData = contentItem.item;
  const workflowStatus =
    variantData && !variantData.isArchived && variantData.assignment.workflowStatus;
  const publishingState = variantData && !variantData.isArchived && variantData.publishingState;
  const scheduledToPublishAt = variantData?.assignment.scheduledToPublishAt || null;
  const scheduledToUnpublishAt = variantData?.assignment.scheduledToUnpublishAt || null;

  const displayDescription = reachedThresholds.sizeL || reachedThresholds.sizeM;
  const allowExpand =
    !doNotRenderToggle && !!onToggleExpand && variantData && !variantData.isArchived;

  const isUnfinished = getContentVariantState(variantData) === VariantCompletionStatus.unfinished;

  const showNotTranslatedAsWFStatusTag = !workflowStatus && !publishingState;

  const renderWorkflowStep = (): ReactNode => {
    if (reachedThresholds.sizeL) {
      return (
        <div className="bar-item__tags-pane bar-item__tags-pane--in-modular-item">
          {showNotTranslatedAsWFStatusTag ? (
            <NotTranslatedOrFallbacksStatus
              itemId={itemData.id}
              renderFallbacksStatus={(itemId) => <FallbacksStatus itemId={itemId} />}
              renderNotTranslatedStatus={() => <NotTranslatedTagForVariant />}
            />
          ) : (
            workflowStatus &&
            publishingState && (
              <WorkflowStatusTagForVariant
                workflowStatus={workflowStatus}
                publishingState={publishingState}
                scheduledToPublishAt={scheduledToPublishAt}
                scheduledToUnpublishAt={scheduledToUnpublishAt}
              />
            )
          )}
        </div>
      );
    }

    return (
      <div
        className={classNames('bar-item__badges-pane', {
          'bar-item__badges-pane--size-s': reachedThresholds.sizeS,
          'bar-item__badges-pane--size-xs': reachedThresholds.sizeXS,
        })}
      >
        <WorkflowBadges
          item={contentItem}
          renderNotTranslatedBadge={() => (
            <NotTranslatedOrFallbacksStatus
              itemId={itemData.id}
              renderFallbacksStatus={(itemId) => <FallbacksStatusCompact itemId={itemId} />}
              renderNotTranslatedStatus={() => <NotTranslatedBadge />}
            />
          )}
        />
      </div>
    );
  };

  const renderExpandToggle = (): ReactNode => (
    <>
      {!doNotRenderToggle &&
        (allowExpand ? (
          <BarItemToggleAction
            ref={triggerButtonForwardedRef}
            ariaLabel={`Toggle ${contentItem.item.name} item`}
            ariaControls={expandedItemId}
            isCollapsed={!isExpanded}
            onExpand={onToggleExpand}
            onCollapse={onToggleExpand}
          />
        ) : (
          <NonExistentBarItemActions />
        ))}
    </>
  );

  return (
    <LoadedLinkedItemContent
      cannotViewDisabledMessage={cannotViewDisabledMessage}
      collectionId={contentItem.item.collectionId}
      contentItemName={contentItem.item.name}
      contentTypeName={contentType.name}
      hideDescription={!displayDescription}
      isCompact={reachedThresholds.sizeXS}
      isInvalid={isInvalid}
      isUnfinished={isUnfinished}
      onClick={allowExpand ? onToggleExpand : undefined}
      renderExpandToggle={renderExpandToggle}
      renderWorkflowStep={renderWorkflowStep}
    />
  );
};
