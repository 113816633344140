import { px } from '@kontent-ai/component-library/tokens';
import { PropsWithChildren, forwardRef, useRef } from 'react';
import { useForm } from 'react-hook-form';
import { ModalDialog } from '../../../../../../../../component-library/components/Dialogs/ModalDialog/ModalDialog.tsx';
import { IDialogActionWithMoreOptions } from '../../../../../../../../component-library/components/Dialogs/components/DialogFooterAction.tsx';
import { ShortcutSymbols } from '../../../../../../../_shared/constants/shortcutSymbols.ts';
import { useDispatch } from '../../../../../../../_shared/hooks/useDispatch.ts';
import { useSelector } from '../../../../../../../_shared/hooks/useSelector.ts';
import {
  DataUiElement,
  getDataUiElementAttribute,
} from '../../../../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';
import { createFormValidationResolver } from '../../../../../../../_shared/utils/validation/createFormValidationResolver.ts';
import { MinDialogWidth } from '../../../../../../projectSettings/environments/constants/uiConstants.ts';
import { itemEditingModalDismissed } from '../../../../../actions/contentActions.ts';
import { TaskForm, TaskFormSharedProps } from '../../../components/details/Tasks/TaskForm.tsx';
import { ITaskFormShape } from '../../../models/ITaskFormShape.type.ts';
import { getTaskAssigneesWithoutAccess } from '../../../selectors/getTaskAssigneesWithoutAccess.ts';
import { taskFormValidationConfig } from '../../../validation/taskFormValidation.ts';

export type TaskFormContainerProps = {
  readonly defaultValues: ITaskFormShape;
  readonly isOpen: boolean;
  readonly modalHeadline: string;
  readonly onCancel: () => void;
  readonly onConfirm: (values: ITaskFormShape) => Promise<void>;
  readonly extraAction?: IDialogActionWithMoreOptions;
  readonly isTaskSubmitting: boolean;
  readonly isTaskArchiving?: boolean;
};

type TaskFormProps = {
  readonly submitButtonName: string;
  readonly submitButtonText: string;
};

type Props = TaskFormProps & TaskFormSharedProps & TaskFormContainerProps;

const TaskFormContainer = forwardRef<HTMLDivElement, PropsWithChildren<Props>>(
  (
    {
      allActiveContributors,
      defaultValues,
      isTaskSubmitting,
      isTaskArchiving = false,
      onCancel,
      onConfirm,
      isOpen,
      modalHeadline,
      submitButtonText,
      extraAction,
      submitButtonName,
    },
    ref,
  ) => {
    const currentUserId = useSelector((s) => s.data.user.info.userId);
    const formRef = useRef<HTMLFormElement>(null);
    const formProps = useForm<ITaskFormShape>({
      defaultValues,
      resolver: createFormValidationResolver(taskFormValidationConfig, {}),
    });

    const { handleSubmit, watch } = formProps;
    const dispatch = useDispatch();

    const formValues = watch();
    const submitForm = handleSubmit(onConfirm);
    const assignees = allActiveContributors.filter(
      (contributor) => contributor.userId === formValues.assignedContributorId,
    );

    const assigneesWithoutAccess = useSelector((s) => getTaskAssigneesWithoutAccess(s, assignees));

    const resetForm = (): void => {
      onCancel();
      dispatch(itemEditingModalDismissed());
    };

    const formStateDisabled = isTaskSubmitting || isTaskArchiving;

    return (
      <ModalDialog
        autoFocusRef={formRef}
        headline={modalHeadline}
        isDismissable
        isOpen={isOpen}
        minWidth={px(MinDialogWidth)}
        onClose={resetForm}
        ref={ref}
        shouldCloseOnInteractOutside={() => false}
        primaryAction={{
          disabled: formStateDisabled,
          onClick: submitForm,
          text: submitButtonText,
          tooltipText: submitButtonName,
          type: 'button',
          tooltipShortcuts: ShortcutSymbols.Enter,
        }}
        cancelAction={{
          disabled: formStateDisabled,
          tooltipShortcuts: ShortcutSymbols.Escape,
        }}
        extraAction={extraAction}
        {...getDataUiElementAttribute(DataUiElement.ContentItemTaskDialog)}
      >
        <TaskForm
          allActiveContributors={allActiveContributors}
          assigneesWithoutAccess={assigneesWithoutAccess}
          currentUserId={currentUserId}
          formStateDisabled={formStateDisabled}
          formProps={formProps}
          formRef={formRef}
          onReset={resetForm}
          onSubmit={submitForm}
        />
      </ModalDialog>
    );
  },
);

TaskFormContainer.displayName = 'TaskFormContainer';
export { TaskFormContainer as TaskForm };
