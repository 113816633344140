import { ThunkFunction } from '../../../../../@types/Dispatcher.type.ts';
import { trackUserEvent } from '../../../../../_shared/actions/thunks/trackUserEvent.ts';
import { TrackedEvent } from '../../../../../_shared/constants/trackedEvent.ts';
import {
  ContentItemEditingEventOrigins,
  ContentItemEditingTriggerDialogAction,
} from '../../../../../_shared/models/events/ContentItemEditingEventData.type.ts';
import { itemEditingModalOpened } from '../../../actions/contentActions.ts';
import { ItemEditingModalDialogType } from '../../../constants/itemEditingModalDialogType.ts';

export const openMoveToCollectionDialog =
  (actionOrigin: ContentItemEditingEventOrigins): ThunkFunction =>
  (dispatch) => {
    dispatch(itemEditingModalOpened(ItemEditingModalDialogType.ChangeContentItemCollectionDialog));

    dispatch(
      trackUserEvent(TrackedEvent.ContentItemEditing, {
        action: ContentItemEditingTriggerDialogAction.ChangeCollectionSelected,
        origin: actionOrigin,
      }),
    );
  };
