import { ThunkPromise } from '../../../../../@types/Dispatcher.type.ts';
import { IContentItemWithVariantsServerModel } from '../../../../../repositories/serverModels/INewContentItemServerModel.ts';
import { itemEditingModalDismissed } from '../../../actions/contentActions.ts';

interface IDeps {
  readonly duplicateEditedContentItem: (
    destinationCollectionId?: Uuid,
  ) => ThunkPromise<IContentItemWithVariantsServerModel | null>;
}

export const createDuplicateItemWithContentAction =
  (deps: IDeps) =>
  (destinationCollectionId?: Uuid): ThunkPromise<IContentItemWithVariantsServerModel | null> =>
  async (dispatch) => {
    const duplicatedItem = await dispatch(deps.duplicateEditedContentItem(destinationCollectionId));

    if (duplicatedItem) {
      dispatch(itemEditingModalDismissed());
    }

    return duplicatedItem;
  };
