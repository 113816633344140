import { ScrollAlignment, scrollElementsToView } from '@kontent-ai/DOM';
import { Stack } from '@kontent-ai/component-library/Stack';
import { Spacing } from '@kontent-ai/component-library/tokens';
import { useAttachRef, usePrevious } from '@kontent-ai/hooks';
import { Collection } from '@kontent-ai/utils';
// eslint-disable-next-line @typescript-eslint/no-restricted-imports
import { useFocusRing } from '@react-aria/focus';
import { useFocus } from '@react-aria/interactions';
import { mergeProps, mergeRefs } from '@react-aria/utils';
import classNames from 'classnames';
import React, {
  forwardRef,
  useContext,
  useEffect,
  useLayoutEffect,
  useRef,
  memo,
  useId,
} from 'react';
import useResizeObserver from 'use-resize-observer';
import { ScrollOptionsContext } from '../../../../../../_shared/components/AutoScroll/ScrollOptionsContext.tsx';
import { IconName } from '../../../../../../_shared/constants/iconEnumGenerated.ts';
import { Icon } from '../../../../../../_shared/uiComponents/Icon/Icon.tsx';
import {
  DataAttributes,
  getDataAttribute,
} from '../../../../../../_shared/utils/dataAttributes/DataAttributes.ts';
import {
  DataUiCollection,
  DataUiElement,
  getDataUiCollectionAttribute,
  getDataUiElementAttribute,
} from '../../../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';
import {
  getUserFriendlyDateString,
  toLocalTime,
} from '../../../../../../_shared/utils/dateTime/timeUtils.ts';
import { formatUserName } from '../../../../../../_shared/utils/users/usersUtils.ts';
import { IProjectContributor } from '../../../../../../data/models/users/ProjectContributor.ts';
import {
  CommentThreadItemType,
  ICommentThreadItem,
  ICommentThreadItemContentModel,
} from '../../../../models/comments/CommentThreadItem.ts';
import { ICommentThread } from '../../../../models/comments/CommentThreads.ts';
import { ISuggestion, isSuggestion } from '../../../../models/comments/Suggestion.ts';
import {
  TipsFromKontentId,
  TipsFromKontentUserName,
  dateComparer,
  getCommentThreadTriggerId,
} from '../../../../utils/commentUtils.ts';
import { CommentThreadCssClass } from '../../constants/uiConstants.ts';
import { CommentThreadItem } from '../../containers/comments/CommentThreadItem.tsx';
import { CommentThreadFocusRing } from './CommentThreadFocusRing.tsx';
import { Reply } from './Reply.tsx';
import { NewCommentThreadItemHandle } from './threadItem/NewCommentThreadItem.tsx';
import { useAccessibleCommentThread } from './useAccessibleCommentThread.ts';

export function getThreadHeight(
  threadRef: React.RefObject<HTMLDivElement> | undefined,
): number | null {
  if (!threadRef) {
    return null;
  }

  const threadElement = threadRef.current;
  if (threadElement instanceof HTMLDivElement) {
    const threadElementRect = threadElement.getBoundingClientRect();
    const threadHeight = Math.round(threadElementRect.height);
    return threadHeight;
  }

  return null;
}

type CommentThreadProps = {
  readonly canSuggest: boolean;
  readonly className: string;
  readonly commentThread: ICommentThread;
  readonly createdBy: IProjectContributor | null;
  readonly isFocused: boolean;
  readonly isInlineThreadWithRemovedContent?: boolean;
  readonly isResolved: boolean;
  readonly onBlur: () => void;
  readonly onBlurNewItemText: (isCommentPending: boolean) => void;
  readonly onCancelNewItem: () => void;
  readonly onFocus: () => void;
  readonly onHighlighted?: () => void;
  readonly onResized?: (threadId: Uuid, oldHeight: number, newHeight: number) => void;
  readonly onStartNewItem: (type: CommentThreadItemType) => void;
  readonly onSubmitNewItem: (
    type: CommentThreadItemType,
    content: ICommentThreadItemContentModel,
  ) => Promise<void>;
  readonly resolvedBy: IProjectContributor | null;
  readonly showReferenceForInlineThreads: boolean;
  readonly type: CommentThreadItemType | undefined;
  readonly withManagedFocus?: boolean;
};

const getUserName = (
  resolvedById: string | null,
  resolvedBy: IProjectContributor | null,
): string => {
  if (resolvedById === TipsFromKontentId) {
    return TipsFromKontentUserName;
  }

  return formatUserName(resolvedBy);
};

export const CommentThread = memo(
  forwardRef<HTMLDivElement, CommentThreadProps>(
    (
      {
        canSuggest,
        className,
        commentThread,
        createdBy,
        isFocused: isHighlighted,
        isInlineThreadWithRemovedContent,
        isResolved,
        onBlur,
        onBlurNewItemText,
        onCancelNewItem,
        onFocus,
        onHighlighted,
        onResized,
        onStartNewItem,
        onSubmitNewItem,
        resolvedBy,
        showReferenceForInlineThreads,
        type,
        withManagedFocus,
      },
      ref,
    ) => {
      const newCommentThreadItemRef = useRef<NewCommentThreadItemHandle>(null);
      const { refObject: commentThreadRef, refToForward } = useAttachRef(ref);

      const commentThreadStatusId = useId();

      const [rootComment, ...replies] = commentThread.threadItems;

      const threadCreatedAt = toLocalTime(rootComment?.createdAt);
      const userFriendlyDate = threadCreatedAt
        ? getUserFriendlyDateString(threadCreatedAt, new Date())
        : null;

      const ariaLabel = rootComment
        ? `${isSuggestion(rootComment) ? 'Suggestion' : 'Comment'} by ${formatUserName(createdBy)}${userFriendlyDate ? ` from ${userFriendlyDate}` : ''}`
        : null;

      const { commentThreadProps } = useAccessibleCommentThread(
        {
          'aria-label': ariaLabel ?? 'Comment thread',
          isDisabled: !isHighlighted || !withManagedFocus,
          getCommentTriggerElement: () =>
            document.getElementById(getCommentThreadTriggerId(commentThread.id)),
          onInteractOutside: onBlur,
          onEscape: onBlur,
        },
        commentThreadRef,
      );
      const { ref: accessibleCommentThreadRef, ...commentThreadPropsWithoutRef } =
        commentThreadProps;

      const { isFocusVisible, focusProps: focusRingProps } = useFocusRing();

      const { focusProps } = useFocus({
        onFocus: () => {
          if (!isHighlighted) {
            onFocus();
          }
        },
      });

      useEffect(() => {
        if (isHighlighted) {
          onHighlighted?.();
        }
      }, [isHighlighted, onHighlighted]);

      const { scrollOptions } = useContext(ScrollOptionsContext);

      useEffect(() => {
        if (commentThread.isReplying && commentThreadRef.current) {
          scrollElementsToView([commentThreadRef.current], {
            ...scrollOptions,
            // We prefer bottom alignment in case of reply to prevent it hiding behind the fold in case the comment thread is long
            alignment: ScrollAlignment.Bottom,
          });
        }
      }, [commentThread.isReplying, commentThreadRef, scrollOptions]);

      const { height = 0 } = useResizeObserver({ ref: commentThreadRef });
      const previousHeight = usePrevious(height);

      useLayoutEffect(() => {
        if (height !== previousHeight) {
          onResized?.(commentThread.id, previousHeight, height);
        }
      }, [commentThread.id, height, previousHeight, onResized]);

      const onClick = (): void => {
        if (!isHighlighted) {
          onFocus();
          commentThreadRef.current?.focus();
        }
      };

      const lastApprovedSuggestion = Collection.getLast(
        commentThread.threadItems
          .filter(
            (comment: ICommentThreadItem) =>
              isSuggestion(comment) && !!comment.suggestionApprovedAt,
          )
          .sort((a: ISuggestion, b: ISuggestion) =>
            dateComparer(a.suggestionApprovedAt, b.suggestionApprovedAt),
          ),
      );

      const itemsBeingEdited = commentThread.threadItems.filter(
        (comment: ICommentThreadItem) => comment.isEditing,
      );
      const shouldRenderNewCommentThreadItem =
        !itemsBeingEdited.length &&
        !isResolved &&
        (isHighlighted ||
          commentThread.isReplying ||
          commentThread.isSubmitting ||
          !withManagedFocus);

      return (
        <div
          id={commentThread.id}
          className={classNames(
            CommentThreadCssClass,
            {
              'comment-thread--is-resolved': commentThread.resolvedAt,
              'comment-thread--is-highlighted': isHighlighted,
            },
            className,
          )}
          aria-describedby={commentThreadStatusId}
          onClick={onClick}
          // we need to merge with the forwarded ref for the comment positioning to work correctly
          ref={mergeRefs(accessibleCommentThreadRef, refToForward)}
          {...mergeProps(focusProps, commentThreadPropsWithoutRef, focusRingProps)}
          {...getDataUiCollectionAttribute(DataUiCollection.CommentThreadItems)}
          {...getDataUiElementAttribute(DataUiElement.CommentThread)}
          {...getDataAttribute(DataAttributes.CommentThreadId, commentThread.id)}
        >
          <CommentThreadFocusRing isFocusVisible={isFocusVisible} />
          {isResolved && (
            <div className="comment-thread__comment-status comment-status">
              <Icon
                iconName={IconName.CheckCircle}
                className="comment-status__icon"
                dataAttribute={getDataUiElementAttribute(DataUiElement.CommentStatusIsResolved)}
              />
              <div id={commentThreadStatusId} className="comment-status__label">
                Resolved by {getUserName(commentThread.resolvedBy, resolvedBy)}
              </div>
            </div>
          )}
          <Stack spacing={Spacing.XXL}>
            {rootComment && (
              <CommentThreadItem
                className="comment-thread__comment"
                commentThreadItem={rootComment}
                commentThread={commentThread}
                isThreadRoot
                showReferenceForInlineThreads={showReferenceForInlineThreads}
                isLastApprovedSuggestion={rootComment === lastApprovedSuggestion}
                isInlineThreadWithRemovedContent={isInlineThreadWithRemovedContent}
              />
            )}
            {!!replies.length && (
              <Stack component="ol" spacing={Spacing.XXL} aria-label="Replies">
                {replies.map((comment) => (
                  <li key={comment.id}>
                    <CommentThreadItem
                      className="comment-thread__comment"
                      commentThreadItem={comment}
                      commentThread={commentThread}
                      isThreadRoot={false}
                      showReferenceForInlineThreads={false}
                      isLastApprovedSuggestion={comment === lastApprovedSuggestion}
                      isInlineThreadWithRemovedContent={isInlineThreadWithRemovedContent}
                    />
                  </li>
                ))}
              </Stack>
            )}
          </Stack>

          {shouldRenderNewCommentThreadItem && (
            <Reply
              ref={newCommentThreadItemRef}
              canSuggest={canSuggest}
              commentThread={commentThread}
              isResolved={isResolved}
              onBlurNewItemText={onBlurNewItemText}
              onCancelNewItem={onCancelNewItem}
              onStartNewItem={onStartNewItem}
              onSubmitNewItem={onSubmitNewItem}
              type={type}
            />
          )}
        </div>
      );
    },
  ),
);

CommentThread.displayName = 'CommentThread';
