import { trackUserEvent } from '../../../../_shared/actions/thunks/trackUserEvent.ts';
import { repositoryCollection } from '../../../../_shared/repositories/repositories.ts';
import { loadProjects, loadUserProjectsInfo } from '../../../../data/actions/thunkDataActions.ts';
import { initializeProjectManagement } from '../../../projects/actions/thunkProjectsActions.ts';
import { loadSubscriptionUsage } from '../../../subscriptionManagement/shared/actions/thunkSubscriptionManagementActions.ts';
import { createEnvironmentCreator } from './thunks/createEnvironment.ts';
import { deleteEnvironmentCreator } from './thunks/deleteEnvironment.ts';
import { initEnvironmentListingCreator } from './thunks/initEnvironmentListing.ts';
import { loadEnvironmentRolesCreator } from './thunks/loadEnvironmentRoles.ts';
import { swapEnvironmentsCreator } from './thunks/swapEnvironment.ts';

const { projectRepository, roleRepository } = repositoryCollection;

export const initEnvironmentListing = initEnvironmentListingCreator({
  initializeProjectManagement,
  loadSubscriptionUsage,
});

export const loadEnvironmentRoles = loadEnvironmentRolesCreator({
  roleRepository,
});

export const createEnvironment = createEnvironmentCreator({
  projectRepository,
  loadProjects,
  loadUserProjectsInfo,
  loadSubscriptionUsage,
  trackUserEvent,
});

export const deleteEnvironment = deleteEnvironmentCreator({
  projectRepository,
  loadUserProjectsInfo,
  loadProjects,
  loadSubscriptionUsage,
  trackUserEvent,
});

export const swapEnvironments = swapEnvironmentsCreator({
  projectRepository,
  loadProjects,
  loadUserProjectsInfo,
  trackUserEvent,
});
