import { DataUiElement } from '../utils/dataAttributes/DataUiAttributes.ts';

export enum ItemColumnCode {
  // Keep them in sync with backend ContentItemSortingColumns
  Collection = 'collection',
  Comments = 'comments',
  ContentType = 'contentType',
  Contributors = 'contributors',
  DueDate = 'due',
  Language = 'language',
  LastLogin = 'lastLogin',
  LastModifiedAt = 'lastModifiedAt',
  LastPublishedAt = 'lastPublishedAt',
  Name = 'name',
  Space = 'space',
  Tasks = 'tasks',
  WorkflowStep = 'workflowStep',
  None = '',
}

export const translateColumnCodeToUiAttribute = (
  column: ItemColumnCode,
): DataUiElement | undefined => {
  switch (column) {
    case ItemColumnCode.WorkflowStep:
      return DataUiElement.ColumnOptionWorkflowStep;
    case ItemColumnCode.ContentType:
      return DataUiElement.ColumnOptionContentType;
    case ItemColumnCode.DueDate:
      return DataUiElement.ColumnOptionDueDate;
    case ItemColumnCode.LastPublishedAt:
      return DataUiElement.ColumnOptionPublished;
    case ItemColumnCode.LastModifiedAt:
      return DataUiElement.ColumnOptionLastModified;
    case ItemColumnCode.Collection:
      return DataUiElement.ColumnOptionCollection;
    case ItemColumnCode.Space:
      return DataUiElement.ColumnOptionSpace;
    default:
      return undefined;
  }
};

export const translateColumnCodeToTitle = (column: ItemColumnCode): string => {
  switch (column) {
    case ItemColumnCode.Collection:
      return 'Collection';
    case ItemColumnCode.Comments:
      return 'Comments';
    case ItemColumnCode.ContentType:
      return 'Content type';
    case ItemColumnCode.Contributors:
      return 'Contributors';
    case ItemColumnCode.DueDate:
      return 'Due date';
    case ItemColumnCode.Language:
      return 'Language';
    case ItemColumnCode.LastLogin:
      return 'Last login';
    case ItemColumnCode.LastModifiedAt:
      return 'Last modified';
    case ItemColumnCode.LastPublishedAt:
      return 'Published';
    case ItemColumnCode.Name:
      return 'Name';
    case ItemColumnCode.Space:
      return 'Space';
    case ItemColumnCode.Tasks:
      return 'Tasks';
    case ItemColumnCode.WorkflowStep:
      return 'Workflow step';
    default:
      return ItemColumnCode.None;
  }
};
