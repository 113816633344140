import { memoize } from '@kontent-ai/memoization';
import { forwardRef } from 'react';
import { ThunkPromise } from '../../../../../@types/Dispatcher.type.ts';
import { useSelector } from '../../../../../_shared/hooks/useSelector.ts';
import { useThunkPromise } from '../../../../../_shared/hooks/useThunkPromise.ts';
import { IStore } from '../../../../../_shared/stores/IStore.type.ts';
import { getViewableContentGroups } from '../../../../../_shared/utils/contentItemUtils.ts';
import { TypeElement } from '../../../../contentInventory/content/models/contentTypeElements/TypeElement.type.ts';
import { ICompiledContentItemElementData } from '../../../models/contentItemElements/ICompiledContentItemElement.type.ts';
import {
  getSelectedContentGroupIdFromStateOrFirst,
  getTypeElementsInContentGroup,
} from '../../../stores/utils/contentItemElementsUtils.ts';
import { isElementVisible } from '../../../utils/itemElementConditionUtils.ts';
import { useItemVariantId } from '../../ContentComponent/context/ContentItemContext.tsx';
import { ensureExpandedModularContentItemsData } from '../../ContentItemEditing/actions/thunkContentItemEditingActions.ts';
import { ContentGroupTabsId } from '../../ContentItemEditing/utils/contentGroupTabsId.ts';
import { ExpandedItemElements as ExpandedItemElementsComponent } from '../components/ExpandedItemElements.tsx';

type Props = {
  readonly expandedItemId?: string;
  readonly contentGroupTabsId: ContentGroupTabsId;
  readonly contentItemId: Uuid;
  readonly isHighlighted?: boolean;
  readonly isItemPage?: boolean;
};

const getContentTypeElements = (
  state: IStore,
  editedContentItemTypeId: Uuid | undefined,
): ReadonlyArray<TypeElement> | null =>
  (editedContentItemTypeId &&
    state.contentApp.loadedContentItemTypes.get(editedContentItemTypeId)?.contentElements) ||
  null;

const getVisibleTypeElements = memoize.weak(
  (
    typeElements: ReadonlyArray<TypeElement>,
    itemElements: ReadonlyArray<ICompiledContentItemElementData> | null,
    selectedContentGroupId: Uuid | null,
  ): ReadonlyArray<TypeElement> =>
    getTypeElementsInContentGroup(typeElements, selectedContentGroupId).filter((typeElement) =>
      isElementVisible(typeElement, itemElements || []),
    ),
);

export const ExpandedItemElements = forwardRef<HTMLDivElement, Props>(
  ({ contentGroupTabsId, contentItemId, expandedItemId, isHighlighted, isItemPage }, ref) => {
    const { variantId } = useItemVariantId();
    const contentItem =
      useSelector((state) => state.contentApp.loadedContentItems.get(contentItemId)) ?? null;
    const contentTypeId = useSelector(
      (state) => state.data.listingContentItems.byId.get(contentItemId)?.item.typeId,
    );
    const contentType = useSelector((state) =>
      contentTypeId ? (state.contentApp.loadedContentItemTypes.get(contentTypeId) ?? null) : null,
    );
    const typeElements = useSelector((state) =>
      getContentTypeElements(state, contentItem?.item.editedContentItemTypeId),
    );
    const itemElements = contentItem?.variant?.elements || null;
    const viewableContentGroups =
      contentType && contentItem ? getViewableContentGroups(contentType, contentItem) : [];

    const selectedContentGroupId = useSelector((state) =>
      getSelectedContentGroupIdFromStateOrFirst(contentGroupTabsId, viewableContentGroups, state),
    );

    const visibleTypeElements =
      typeElements && getVisibleTypeElements(typeElements, itemElements, selectedContentGroupId);

    useThunkPromise(ensureData, contentItemId, contentTypeId ?? null, { canRun: !!contentTypeId });

    return contentType ? (
      <ExpandedItemElementsComponent
        contentGroupTabsId={contentGroupTabsId}
        contentItemCodename={contentItem?.item.codename}
        contentItemCollectionId={contentItem?.item.collectionId}
        contentItemId={contentItemId}
        contentItemName={contentItem?.item.name}
        contentItemVariantId={variantId}
        contentType={contentType}
        elements={itemElements}
        expandedItemId={expandedItemId}
        isHighlighted={!!isHighlighted}
        isItemPage={!!isItemPage}
        typeElements={visibleTypeElements}
        ref={ref}
      />
    ) : null;
  },
);

const ensureData =
  (contentItemId: Uuid, contentTypeId: Uuid | null, abortSignal: AbortSignal): ThunkPromise =>
  async (dispatch) => {
    if (contentTypeId) {
      await dispatch(
        ensureExpandedModularContentItemsData(contentItemId, contentTypeId, abortSignal),
      );
    }
  };

ExpandedItemElements.displayName = 'ExpandedItemElements';
