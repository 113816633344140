import { Collection } from '@kontent-ai/utils';
import { IStore } from '../../../../../_shared/stores/IStore.type.ts';
import { ISpace } from '../../../../../data/models/space/space.ts';
import { spacesContainCollection } from '../../../../environmentSettings/spaces/utils/spacesUtils.ts';
import { getAvailableSpacesForUser } from './getAvailableSpacesForUser.ts';

export const getSpacesForPreviewUrls = (state: IStore): ReadonlyArray<ISpace> => {
  const availableSpacesForUser = getAvailableSpacesForUser(
    state.contentApp.editedContentItemVariant?.id.variantId ?? null,
    state.sharedApp.currentProjectId,
    Collection.getValues(state.data.spaces.byId),
    Collection.getValues(state.data.collections.byId),
    state.data.user,
  );

  const editedItemCollectionId = state.contentApp.editedContentItem?.collectionId ?? null;

  const spacesForCurrentCollection = editedItemCollectionId
    ? spacesContainCollection(availableSpacesForUser, [editedItemCollectionId])
    : availableSpacesForUser;

  // because of backwards compatibility, if the collection doesn't belong to any space, we treat it as if it belongs to all spaces
  return spacesForCurrentCollection.length > 0
    ? spacesForCurrentCollection
    : availableSpacesForUser;
};
