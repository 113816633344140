import { trackUserEvent } from '../../../../../_shared/actions/thunks/trackUserEvent.ts';
import { repositoryCollection } from '../../../../../_shared/repositories/repositories.ts';
import { convertElementsToDomainModel } from '../../../stores/utils/contentItemHelperMethods.ts';
import {
  createNewVersion,
  loadRelatedContentItemElementsData,
  updateAssignmentSection,
} from '../../ContentItemEditing/actions/thunkContentItemEditingActions.ts';
import { createRedirectToCompareVersionsAction } from '../../ContentItemEditing/actions/thunks/redirectToCompareVersions.ts';
import { loadTimeline } from '../../Timeline/actions/thunkTimelineActions.ts';
import { comparisonEnabled, comparisonRevisionLoaded, revisionLoaded } from './revisionsActions.ts';
import { enableComparisonActionCreator } from './thunks/enableComparison.ts';
import { createInitCompareRevisionAction } from './thunks/initCompareRevision.ts';
import { createInitViewRevisionAction } from './thunks/initViewRevision.ts';
import { loadRevisionActionCreator } from './thunks/loadRevision.ts';
import { restoreRevisionActionCreator } from './thunks/restoreRevision.ts';

const { contentItemRepository } = repositoryCollection;

const loadRevisionDependencies = {
  contentItemRepository,
  trackUserEvent,
  convertElementsToDomainModel,
  revisionLoaded,
  loadRelatedContentItemElementsData,
};
export const loadRevision = loadRevisionActionCreator(loadRevisionDependencies);

const loadComparisonRevisionDependencies = {
  contentItemRepository,
  convertElementsToDomainModel,
  revisionLoaded: comparisonRevisionLoaded,
  loadRelatedContentItemElementsData,
};
export const loadComparisonRevision = loadRevisionActionCreator(loadComparisonRevisionDependencies);

const restoreRevisionDependencies = {
  contentItemRepository,
  createNewVersion,
  trackUserEvent,
  updateAssignment: updateAssignmentSection,
};
export const restoreRevision = restoreRevisionActionCreator(restoreRevisionDependencies);

const enableComparisonDependencies = {
  trackUserEvent,
  comparisonEnabled,
};
export const enableComparison = enableComparisonActionCreator(enableComparisonDependencies);

export const redirectToCompareVersions = createRedirectToCompareVersionsAction({
  loadTimeline,
  enableComparison,
});

export const initViewRevision = createInitViewRevisionAction({
  loadRevision,
});

export const initCompareRevision = createInitCompareRevisionAction({
  loadComparisonRevision,
  contentItemRepository,
});
