import React, { useCallback, useState } from 'react';
import { DropDownMenuControlled, DropDownMenuControlledProps } from './DropDownMenuControlled.tsx';

type RenderTriggerType = DropDownMenuControlledProps['renderTrigger'];
type TriggerProps = Parameters<RenderTriggerType>[0];
type RenderDropDownType = DropDownMenuControlledProps['renderDropDown'];
type RenderDropDownArgsType = Parameters<RenderDropDownType>;

export type DropDownMenuProps = Omit<
  DropDownMenuControlledProps,
  'isDropDownVisible' | 'onClickOutside' | 'renderTrigger' | 'renderDropDown'
> & {
  readonly renderTrigger: (
    triggerProps: TriggerProps,
    isDropDownVisible: boolean,
  ) => ReturnType<RenderTriggerType>;
  readonly renderDropDown: (
    hideDropDown: () => void,
    ...rest: RenderDropDownArgsType
  ) => ReturnType<RenderDropDownType>;
};

/**
 * @deprecated This component doesn’t provide keyboard navigation and is not accessible. Use `Menu` instead.
 */
export const DropDownMenu: React.FC<DropDownMenuProps> = ({
  renderTrigger,
  renderDropDown,
  ...props
}) => {
  const [isDropDownVisible, setIsDropDownVisible] = useState(false);
  const hideDropDown = useCallback(() => setIsDropDownVisible(false), []);

  const renderTriggerEnhanced = useCallback(
    (triggerProps: TriggerProps) => {
      return renderTrigger(triggerProps, isDropDownVisible);
    },
    [isDropDownVisible, renderTrigger],
  );
  const renderDropDownEnhanced = useCallback(
    (...args: RenderDropDownArgsType) => {
      return renderDropDown(hideDropDown, ...args);
    },
    [renderDropDown, hideDropDown],
  );

  return (
    <DropDownMenuControlled
      {...props}
      isDropDownVisible={isDropDownVisible}
      onDropDownVisibilityChange={setIsDropDownVisible}
      renderTrigger={renderTriggerEnhanced}
      renderDropDown={renderDropDownEnhanced}
    />
  );
};

DropDownMenu.displayName = 'DropDownMenu';
