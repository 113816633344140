import { OutwardLink } from '@kontent-ai/component-library/Anchor';
import { Box } from '@kontent-ai/component-library/Box';
import { Button } from '@kontent-ai/component-library/Button';
import { Card } from '@kontent-ai/component-library/Card';
import { Icons } from '@kontent-ai/component-library/Icons';
import { Inline } from '@kontent-ai/component-library/Inline';
import { Stack } from '@kontent-ai/component-library/Stack';
import { Spacing, typographyCardHeadline } from '@kontent-ai/component-library/tokens';
import React from 'react';
import { documentationLinks } from '../../../../_shared/constants/documentationLinks.ts';
import {
  DataUiAction,
  getDataUiActionAttribute,
} from '../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';
import { IPlan } from '../../../../data/models/plans/Plan.ts';
import { ISubscriptionUsage } from '../../shared/models/SubscriptionUsage.ts';
import { ButtonAction } from '../containers/PlanCard.tsx';
import { ChangePlanButton } from './ChangePlanButton.tsx';

type Props = {
  readonly action?: ButtonAction;
  readonly onContactSupport: () => void;
  readonly plan?: IPlan;
  readonly planDescription: string;
  readonly planPrice: string;
  readonly subscriptionUsage?: ISubscriptionUsage;
  readonly title: string;
  readonly trackPlanDetailsOpened: () => void;
};

export const PlanCard: React.FC<Props> = ({
  action,
  onContactSupport,
  planDescription,
  planPrice,
  title,
  trackPlanDetailsOpened,
  plan,
  subscriptionUsage,
}) => {
  const actionButton =
    action && plan && subscriptionUsage ? (
      <ChangePlanButton action={action} plan={plan} subscriptionUsage={subscriptionUsage} />
    ) : (
      <Button
        buttonStyle="primary"
        buttonDisplay="block"
        onClick={onContactSupport}
        {...getDataUiActionAttribute(DataUiAction.OpenIntercom)}
      >
        Contact us
      </Button>
    );

  return (
    <Box width={350}>
      <Card cardLabel={title} component="section">
        <Card.Headline>
          <Box textAlign="center">{title}</Box>
        </Card.Headline>
        <Card.Body>
          <Box textAlign="center">
            <Stack align="center" spacing={Spacing.XL}>
              <p>{planDescription}</p>
              <Box typography={typographyCardHeadline}>{planPrice}</Box>
              <OutwardLink
                href={documentationLinks.subscriptionPlans}
                onClick={trackPlanDetailsOpened}
              >
                <Inline spacingX={Spacing.S} align="center">
                  Plan details <Icons.ArrowRightTopSquare />
                </Inline>
              </OutwardLink>
            </Stack>
          </Box>
        </Card.Body>
        <Card.Footer>{actionButton}</Card.Footer>
      </Card>
    </Box>
  );
};

PlanCard.displayName = 'PlanCard';
