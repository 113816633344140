import { CompiledTypeElementType } from '../../../applications/contentInventory/content/models/ContentItemElementType.ts';

export enum CommentAction {
  CommentCreated = 'comment-created',
  CommentHighlighted = 'comment-highlighted',
  CommentThreadResolved = 'comment-thread-resolved',
  CommentThreadUnresolved = 'comment-thread-unresolved',
  CopyLink = 'copy-link',
  SuggestionApproved = 'suggestion-approved',
  SuggestionCreated = 'suggestion-created',
  MentionCreated = 'mention-created',
}

export type CommentEventData = {
  readonly action: CommentAction;
  readonly commentThreadId?: Uuid;
  readonly 'element-type'?: CompiledTypeElementType;
  readonly threadItemsDepth?: number;
};
