import { DataTableRow } from '../../../../_shared/components/DataTable/DataTableRow.tsx';
import { LinkDataTableCell } from '../../../../_shared/components/DataTable/LinkDataTableCell.tsx';
import { LastUpdatedAt } from '../../../../_shared/components/LastUpdatedAt.tsx';
import { IProjectContributor } from '../../../../data/models/users/ProjectContributor.ts';
import { EmptyCellContent } from '../../../contentInventory/content/features/ContentItemInventory/components/ItemInventoryScrollTable/Cells/ContentItemScrollTableCellsContents.tsx';
import { ICommentThreadItem } from '../../../itemEditor/models/comments/CommentThreadItem.ts';
import { ICommentThread } from '../../../itemEditor/models/comments/CommentThreads.ts';
import { CommentCellContent } from './CommentCellContent.tsx';
import { ScrollTableItemContributors } from './ScrollTableItemContributors.tsx';

type ContentStatusCommentsTableRowProps = Readonly<{
  commentThread: ICommentThread;
  commentThreadItem: ICommentThreadItem;
  createdBy: IProjectContributor | null;
  linkPath: string;
}>;

export const ContentStatusCommentsTableRow: React.FC<ContentStatusCommentsTableRowProps> = ({
  commentThread,
  commentThreadItem,
  createdBy,
  linkPath,
}) => {
  const numberOfReplies = commentThread.threadItems.length - 1;

  return (
    <DataTableRow key={commentThread.id} dataUiObjectName={commentThread.id} id={commentThread.id}>
      <LinkDataTableCell linkPath={linkPath}>
        <CommentCellContent commentThread={commentThread} commentThreadItem={commentThreadItem} />
      </LinkDataTableCell>
      <LinkDataTableCell linkPath={linkPath}>
        {numberOfReplies > 0 ? numberOfReplies : '—'}
      </LinkDataTableCell>
      <LinkDataTableCell linkPath={linkPath}>
        {commentThreadItem.createdAt ? (
          <LastUpdatedAt time={commentThreadItem.createdAt} />
        ) : (
          <EmptyCellContent />
        )}
      </LinkDataTableCell>
      <LinkDataTableCell linkPath={linkPath}>
        {createdBy && <ScrollTableItemContributors contributors={[createdBy]} />}
      </LinkDataTableCell>
    </DataTableRow>
  );
};

ContentStatusCommentsTableRow.displayName = 'ContentStatusCommentsTableRow';
