import { InvariantException } from '@kontent-ai/errors';
import { ThunkPromise } from '../../../../../@types/Dispatcher.type.ts';
import { TrackedEvent } from '../../../../../_shared/constants/trackedEvent.ts';
import { DefaultWorkflowId } from '../../../../../_shared/constants/variantIdValues.ts';
import { TrackUserEventAction } from '../../../../../_shared/models/TrackUserEvent.type.ts';
import {
  ContentItemEditingChangeAction,
  ContentItemEditingEventOrigins,
} from '../../../../../_shared/models/events/ContentItemEditingEventData.type.ts';
import { getSelectedLanguageIdOrThrow } from '../../../../../_shared/selectors/getSelectedLanguageId.ts';
import { logError } from '../../../../../_shared/utils/logError.ts';
import { canCreateItemVariant } from '../../../../../_shared/utils/permissions/activeCapabilities.ts';
import { IContentItemRepository } from '../../../../../repositories/interfaces/IContentItemRepository.type.ts';
import {
  IContentItemWithVariantsServerModel,
  createEmptyContentItem,
  createEmptyContentItemVariant,
} from '../../../../../repositories/serverModels/INewContentItemServerModel.ts';
import { ElementType } from '../../../../contentInventory/content/models/ContentItemElementType.ts';
import { isEditableElement } from '../../../../contentInventory/content/models/contentTypeElements/compiledTypeElementTypeGuards.ts';
import {
  ContentItemEditing_ConvertingContentComponent_Failed,
  ContentItemEditing_ConvertingContentComponent_Finished,
  ContentItemEditing_ConvertingContentComponent_Started,
} from '../../../../itemEditor/features/ContentItemEditing/constants/contentItemEditingActionTypes.ts';
import { ElementReference } from '../../../../itemEditor/features/ContentItemEditing/containers/hooks/useItemElementReference.ts';
import { createEmptyAssignment } from '../../../../itemEditor/features/ContentItemEditing/utils/assignmentUtils.ts';
import { IEnsureLoadedListingItemsAction } from '../../../../itemEditor/features/LoadedItems/actions/thunks/ensureLoadedListingItems.ts';
import { isRichTextElement } from '../../../../itemEditor/models/contentItemElements/compiledItemElementTypeGuards.ts';
import {
  getElementById,
  getItemElementOrDefault,
} from '../../../../itemEditor/stores/utils/contentItemElementsUtils.ts';
import { getItemElementServerModelConverter } from '../../../../itemEditor/utils/getItemElementDataConverters.ts';
import {
  GetAssets,
  IItemElementServerModelConverterDataDependencies,
} from '../../../../itemEditor/utils/itemElementDataConverters/types/IItemElementDataConverters.type.ts';

interface IDeps {
  readonly contentItemRepository: IContentItemRepository;
  readonly ensureLoadedListingItems: IEnsureLoadedListingItemsAction;
  readonly trackUserEvent: TrackUserEventAction;
  readonly createGuid: () => Uuid;
}

const start = () =>
  ({
    type: ContentItemEditing_ConvertingContentComponent_Started,
  }) as const;

const fail = () =>
  ({
    type: ContentItemEditing_ConvertingContentComponent_Failed,
  }) as const;

const finish = (itemId: Uuid) =>
  ({
    type: ContentItemEditing_ConvertingContentComponent_Finished,
    payload: {
      itemId,
    },
  }) as const;

export type ConvertContentComponentToContentItemVariantActionsType = ReturnType<
  typeof start | typeof fail | typeof finish
>;

export const createConvertContentComponentToContentItemVariant =
  (deps: IDeps) =>
  (
    element: ElementReference,
    contentComponentId: Uuid,
    selectedWorkflowId: Uuid | undefined = DefaultWorkflowId,
  ): ThunkPromise<IContentItemWithVariantsServerModel | null> =>
  async (dispatch, getState) => {
    try {
      const state = getState();
      const {
        data: { user },
        contentApp: {
          editedContentItem,
          editedContentItemVariant,
          editedContentItemVariantElements,
          loadedContentItemTypes,
        },
      } = state;

      if (!editedContentItem || !editedContentItemVariant) {
        return null;
      }

      const elementId = element.rootRichTextElementId ?? element.elementId;
      const rootRichTextElement = getElementById(elementId, editedContentItemVariantElements);
      if (!isRichTextElement(rootRichTextElement)) {
        return null;
      }

      const contentComponent = rootRichTextElement.contentComponents.get(contentComponentId);
      if (!contentComponent) {
        return null;
      }

      dispatch(start());

      const collectionId = editedContentItem.collectionId; // TODO KCL-5887, KCL-5849 adjust accordingly
      if (
        !canCreateItemVariant(
          contentComponent.contentTypeId,
          collectionId,
          getSelectedLanguageIdOrThrow(state),
          state,
        )
      ) {
        throw InvariantException(
          'Convert component to item variant action called without having the capability to create content.',
        );
      }

      const item = {
        ...createEmptyContentItem({
          typeId: contentComponent.contentTypeId,
          collectionId,
        }),
        sitemapLocation: editedContentItem.sitemapLocation.toArray(),
      };
      const newContentItem = await deps.contentItemRepository.createItem(item);
      const assignment = createEmptyAssignment(deps.createGuid(), user.info, selectedWorkflowId);
      const componentContentType = loadedContentItemTypes.get(contentComponent.contentTypeId);
      const getAssets: GetAssets = () => getState().data.assets.byId;
      const elementDependencies: IItemElementServerModelConverterDataDependencies = {
        getAssets,
        getContentComponent: (id) => rootRichTextElement.contentComponents.get(id),
      };

      // Use content type as a source of elements collection
      const contentElements = (componentContentType?.contentElements || [])
        .filter(isEditableElement)
        .map((typeElement) => {
          const itemElement = getItemElementOrDefault(typeElement, contentComponent.elements);
          return getItemElementServerModelConverter(itemElement.type)(
            itemElement,
            elementDependencies,
          );
        });

      const variant = createEmptyContentItemVariant({
        itemId: newContentItem.item.id,
        variantId: editedContentItemVariant.id.variantId,
        contentElements,
        contentLastUpdatedBy: user.info.userId,
        assignment,
      });
      await deps.contentItemRepository.createVariant(variant);

      dispatch(
        deps.trackUserEvent(TrackedEvent.ContentItemEditing, {
          action: ContentItemEditingChangeAction.ConvertComponentToContentItem,
          origin: ContentItemEditingEventOrigins.Paper,
          contentElementType: ElementType.RichText,
        }),
      );

      await dispatch(deps.ensureLoadedListingItems([newContentItem.item.id]));
      dispatch(finish(newContentItem.item.id));

      return newContentItem;
    } catch (error) {
      logError(error);
      dispatch(fail());
      throw error;
    }
  };
