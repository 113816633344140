import { usePrevious } from '@kontent-ai/hooks';
import React, {
  ForwardRefExoticComponent,
  RefAttributes,
  useCallback,
  useEffect,
  useState,
} from 'react';
import { UnsupportedAssetFileTypeModal } from '../../../applications/contentInventory/assets/components/AssetListing/UnsupportedAssetFileTypeModal.tsx';
import { ChangeWorkflowStepListingModal } from '../../../applications/contentInventory/content/features/ContentItemInventory/containers/ChangeWorkflowStepListingModal.tsx';
import { ContentItemsBulkDeleteConfirmationDialog } from '../../../applications/contentInventory/content/features/ContentItemInventory/containers/ContentItemsBulkDeleteConfirmationDialog.tsx';
import { MoveItemsToArchivedStepConfirmationDialog } from '../../../applications/contentInventory/content/features/ContentItemInventory/containers/MoveItemsToArchivedStepConfirmationDialog.tsx';
import { ChangeDefaultRootConfirmationDialog } from '../../../applications/contentInventory/relations/containers/ChangeDefaultRootConfirmationDialog.tsx';
import { ContentTypeUsedAsComponentItemListingModal } from '../../../applications/contentModels/contentTypes/containers/ContentTypeUsedAsComponentItemListingModal.tsx';
import { ContentModelingSurveyModal } from '../../../applications/contentModels/shared/containers/ContentModelingSurveyModal.tsx';
import { TermRemoveConfirmationDialog } from '../../../applications/contentModels/taxonomy/containers/TermRemoveConfirmationDialog.tsx';
import { NewContentItemDialog } from '../../../applications/itemEditor/features/NewContentItem/containers/NewContentItemDialogWrapper.tsx';
import { ConfigureContentTypePreviewUrlsDialog } from '../../../applications/previewConfiguration/components/ConfigureContentTypePreviewUrlsDialog.tsx';
import { RevokeApiKeyDialog } from '../../../applications/projectSettings/apiKeys/containers/ApiKeyDetail/RevokeApiKeyDialog.tsx';
import { ApiKeyRegenerationDialog } from '../../../applications/projectSettings/apiKeys/containers/ApiKeyRegenerationDialog.tsx';
import { SubscriptionKeyGenerationDialog } from '../../../applications/subscriptionManagement/ApiKeys/containers/SubscriptionKeyGenerationDialog.tsx';
import { ModalDialogType } from '../../constants/modalDialogType.ts';
import { AiFeedbackDialog } from '../../containers/AiSurvey/AiFeedbackDialog.tsx';
import { ThanksForFeedbackModal } from '../../containers/FeedbackModal/ThanksForFeedbackModal.tsx';
import { HelpUsImproveSurveyDialog } from '../../containers/HelpUsImproveSurvey/HelpUsImproveSurveyDialog.tsx';
import { RestoreRevisionConfirmationDialog } from '../../containers/StatusBar/revisions/RestoreRevisionConfirmationDialog.tsx';
import { SendYourFeedbackDialog } from '../SendYourFeedbackModal/SendYourFeedbackDialog.tsx';
import { IAnimatedModalDialogProps } from './IAnimatedModalDialogProps.type.ts';

function useRenderAnimatedDialog(currentModal: ModalDialogType) {
  const previousModal = usePrevious(currentModal);
  const [renderAnimatedDialog, setRenderAnimatedDialog] = useState<AnimatedModals | null>(null);
  useEffect(() => {
    if (!isOldModalDialogType(currentModal) && currentModal !== previousModal) {
      setRenderAnimatedDialog(currentModal);
    }
  }, [currentModal, previousModal]);

  const resetRenderAnimatedDialogOnUnmount = useCallback(
    (element: HTMLDivElement | null) => {
      // on unmount
      if (!element) {
        // skip when switching between 2 animated dialogs
        if (!isOldModalDialogType(currentModal) && currentModal !== previousModal) {
          return;
        }
        setRenderAnimatedDialog(null);
      }
    },
    [currentModal, previousModal],
  );
  return {
    renderAnimatedDialog,
    resetRenderAnimatedDialogOnUnmount,
  };
}

export interface IModalDialogSelectorProps {
  readonly currentModal: ModalDialogType;
}

const NoDialog: React.FC = () => null;

type AnimatedModals =
  | ModalDialogType.ApiKeyRegenerationDialog
  | ModalDialogType.BulkItemsDeleteConfirmationDialog
  | ModalDialogType.ChangeDefaultRootConfirmationDialog
  | ModalDialogType.ChangeWorkflowStepListingDialog
  | ModalDialogType.ConfigureContentTypePreviewUrls
  | ModalDialogType.MoveItemsToArchivedStepConfirmationDialog
  | ModalDialogType.NewContentItemDialog
  | ModalDialogType.NewContentItemDialogForWebSpotlight
  | ModalDialogType.RemoveTaxonomyTermConfirmationDialog
  | ModalDialogType.RestoreRevisionConfirmationDialog
  | ModalDialogType.RevokeApiKeyDialog
  | ModalDialogType.SapiKeyGenerationDialog
  | ModalDialogType.SendYourFeedbackDialog;

type OldModals = Exclude<ModalDialogType, AnimatedModals>;

const oldModalDialogsMap: ReadonlyRecord<OldModals, React.ComponentType> = {
  [ModalDialogType.AiFeedbackDialog]: AiFeedbackDialog,
  [ModalDialogType.ContentTypeAsComponentUsageDialog]: ContentTypeUsedAsComponentItemListingModal,
  [ModalDialogType.None]: NoDialog,
  [ModalDialogType.ThanksForFeedbackDialog]: ThanksForFeedbackModal,
  [ModalDialogType.UnsupportedAssetFileType]: UnsupportedAssetFileTypeModal,
  [ModalDialogType.HelpUsImproveSurveyDialog]: HelpUsImproveSurveyDialog,
  [ModalDialogType.ContentModelingSatisfactionSurveyDialog]: ContentModelingSurveyModal,
};

const isOldModalDialogType = (type: ModalDialogType): type is keyof typeof oldModalDialogsMap =>
  (Object.keys(oldModalDialogsMap) as ReadonlyArray<ModalDialogType>).includes(type);

type AnimatedModalComponentWithRef = ForwardRefExoticComponent<
  IAnimatedModalDialogProps & RefAttributes<HTMLDivElement>
>;

const animatedModalDialogsMap: ReadonlyRecord<AnimatedModals, AnimatedModalComponentWithRef> = {
  [ModalDialogType.ApiKeyRegenerationDialog]: ApiKeyRegenerationDialog,
  [ModalDialogType.ChangeWorkflowStepListingDialog]: ChangeWorkflowStepListingModal,
  [ModalDialogType.ConfigureContentTypePreviewUrls]: ConfigureContentTypePreviewUrlsDialog,
  [ModalDialogType.NewContentItemDialogForWebSpotlight]: NewContentItemDialog,
  [ModalDialogType.NewContentItemDialog]: NewContentItemDialog,
  [ModalDialogType.RestoreRevisionConfirmationDialog]: RestoreRevisionConfirmationDialog,
  [ModalDialogType.RevokeApiKeyDialog]: RevokeApiKeyDialog,
  [ModalDialogType.SapiKeyGenerationDialog]: SubscriptionKeyGenerationDialog,
  [ModalDialogType.SendYourFeedbackDialog]: SendYourFeedbackDialog,
  [ModalDialogType.BulkItemsDeleteConfirmationDialog]: ContentItemsBulkDeleteConfirmationDialog,
  [ModalDialogType.ChangeDefaultRootConfirmationDialog]: ChangeDefaultRootConfirmationDialog,
  [ModalDialogType.MoveItemsToArchivedStepConfirmationDialog]:
    MoveItemsToArchivedStepConfirmationDialog,
  [ModalDialogType.RemoveTaxonomyTermConfirmationDialog]: TermRemoveConfirmationDialog,
};

export const ModalDialogSelector: React.FC<IModalDialogSelectorProps> = ({ currentModal }) => {
  const OldModalDialog = isOldModalDialogType(currentModal)
    ? oldModalDialogsMap[currentModal]
    : NoDialog;

  const { renderAnimatedDialog, resetRenderAnimatedDialogOnUnmount } =
    useRenderAnimatedDialog(currentModal);

  const AnimatedDialog = renderAnimatedDialog && animatedModalDialogsMap[renderAnimatedDialog];

  return (
    <>
      <OldModalDialog />
      {AnimatedDialog && (
        <AnimatedDialog
          isOpen={renderAnimatedDialog === currentModal}
          ref={resetRenderAnimatedDialogOnUnmount}
        />
      )}
    </>
  );
};

ModalDialogSelector.displayName = 'ModalDialogSelector';
