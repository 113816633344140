import { noOperation } from '@kontent-ai/utils';
import React, { useCallback } from 'react';
import { trackUserEvent } from '../../../../../_shared/actions/thunks/trackUserEvent.ts';
import { TrackedEvent } from '../../../../../_shared/constants/trackedEvent.ts';
import { useDispatch } from '../../../../../_shared/hooks/useDispatch.ts';
import { useSelector } from '../../../../../_shared/hooks/useSelector.ts';
import { getCurrentUser } from '../../../../../_shared/selectors/getCurrentUser.ts';
import { HotjarTag, tagRecording } from '../../../../../_shared/utils/hotjarUtils.ts';
import { isSampleProject } from '../../../../../_shared/utils/sampleItemsUtils.ts';
import { isEmployee } from '../../../../../_shared/utils/subscriptionUsageUtils.ts';
import { getCurrentProject } from '../../../../../data/reducers/user/selectors/userProjectsInfoSelectors.ts';
import {
  DemoSampleAppsSection as DemoSampleAppsSectionComponent,
  IDemoSampleAppSectionProps,
} from '../../components/SampleProjectQuickstart/DemoSections/DemoSampleAppsSection.tsx';
import { FallbackSampleProjectId } from '../../constants/onboardingConstants.ts';

const generatedAppetizeIframeUrl =
  'https://appetize.io/embed/lv3l2vcpavdnqe73wyfpidq4vm?device=pixel4&scale=70&orientation=portrait&deviceColor=black&xdocMsg=true';

// since there is no (.*)AppUrl nor fallback(.*)AppUrl, hasEmployeePlan and tagMobileApp have no effect. However, trackLinkClicked, is always
// used (e.g. for docs links) so it should be always provided (for the sake of edge-case when quickstart is shown out of sample project).
const NonSampleProjectProps: Omit<IDemoSampleAppSectionProps, 'trackLinkClicked'> = {
  fallbackSampleMobileAppUrl: '',
  isCurrentUserEmployee: false,
  isSampleProject: false,
  projectActivatedAt: '',
  tagMobileApp: noOperation,
  userSampleMobileAppUrl: '',
};

export const DemoSampleAppsSection: React.FC = () => {
  const dispatch = useDispatch();
  const isCurrentUserEmployee = useSelector((s) => isEmployee(getCurrentUser(s).email));
  const currentProject = useSelector(getCurrentProject);

  const trackLinkClicked = useCallback(
    (linkId: string, target: string) =>
      dispatch(
        trackUserEvent(TrackedEvent.QuickstartLinkOpened, {
          'link-id': linkId,
          target,
        }),
      ),
    [],
  );

  if (!isSampleProject(currentProject)) {
    return (
      <DemoSampleAppsSectionComponent
        {...NonSampleProjectProps}
        trackLinkClicked={trackLinkClicked}
      />
    );
  }

  const userAppetizeParams = encodeURIComponent(
    JSON.stringify({ projectId: currentProject.projectId }),
  );
  const userSampleMobileAppUrl = `${generatedAppetizeIframeUrl}&params=${userAppetizeParams}`;

  const fallbackAppetizeParams = encodeURIComponent(
    JSON.stringify({ projectId: FallbackSampleProjectId }),
  );
  const fallbackSampleMobileAppUrl = `${generatedAppetizeIframeUrl}&params=${fallbackAppetizeParams}`;

  return (
    <DemoSampleAppsSectionComponent
      projectActivatedAt={currentProject.projectActivatedAt}
      userSampleMobileAppUrl={userSampleMobileAppUrl}
      fallbackSampleMobileAppUrl={fallbackSampleMobileAppUrl}
      isCurrentUserEmployee={isCurrentUserEmployee}
      isSampleProject
      trackLinkClicked={trackLinkClicked}
      tagMobileApp={tagMobileApp}
    />
  );
};

DemoSampleAppsSection.displayName = 'DemoSampleAppsSection';

const tagMobileApp = () => tagRecording(HotjarTag.SampleMobileAppOpened);
