import { usePrevious } from '@kontent-ai/hooks';
import { assert } from '@kontent-ai/utils';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { bindApiMethods } from './hooks/bindApiMethods.ts';
import { GetApi } from './types/Editor.api.type.ts';
import {
  BaseEditor,
  BaseEditorProps,
  BaseState,
  IsEditorLocked,
} from './types/Editor.base.type.ts';
import { InternalPluginProps } from './types/Editor.composition.type.ts';
import { Api, Contract } from './types/Editor.contract.type.ts';
import { Callbacks, Render } from './types/Editor.plugins.type.ts';
import { decorable, seal, sealable } from './utils/decorable.ts';

type EditorProps = BaseEditorProps & Pick<InternalPluginProps, 'hooks'>;

export const Editor: React.FC<EditorProps> = (props) => {
  const { disabled, hooks } = props;

  const [editorId, setEditorId] = useState(getUniqueEditorId);
  const getEditorId = useCallback(() => editorId, [editorId]);

  // We have to force DraftJS Editor re-render when disabled prop is changed
  // to reflect the disabled state in the respective components
  // e.g. show/hide Remove button in linked item/component or edit button in links
  const previousDisabled = usePrevious(disabled);
  useEffect(() => {
    if (disabled !== previousDisabled) {
      setEditorId(getUniqueEditorId());
    }
  }, [previousDisabled, disabled]);

  const completeApi: Api<BaseEditor> = useMemo(() => {
    const api =
      hooks?.reduce((aggregatedApi, plugin) => {
        const pluginApi = plugin.getApiMethods?.(aggregatedApi);
        return pluginApi ? { ...aggregatedApi, ...pluginApi } : aggregatedApi;
      }, emptyApi) ?? emptyApi;
    return bindApiMethods(api);
  }, [hooks]);

  const getApi: GetApi<Contract> = useCallback(() => completeApi, [completeApi]);

  const canUpdateContent = decorable<IsEditorLocked>(() => !disabled);
  const isEditorLocked = decorable<IsEditorLocked>(() => false);
  const render = decorable<Render<BaseEditor>>(() => null);
  const attachCallbacks = decorable<Render<BaseEditor>>(() => null);
  const refreshEditor = useCallback(() => {
    setEditorId(getUniqueEditorId());
  }, []);

  const state = useMemo(() => {
    const initialCallbacks: Callbacks<BaseState> = sealable({
      attachCallbacks,
      canUpdateContent,
      getApi,
      getEditorId,
      isEditorLocked,
      refreshEditor,
      render,
    });

    const completeCallbacks: Callbacks<BaseState> =
      hooks?.reduce((finalState, plugin) => {
        const pluginState = plugin.apply?.(finalState);
        for (const propName in pluginState) {
          if (Object.hasOwn(pluginState, propName)) {
            assert(
              !Object.hasOwn(finalState, propName),
              () =>
                `Plugin is not allowed to change existing state property ${propName}, use ${propName}.decorate(...)`,
            );
          }
        }
        return pluginState ? { ...pluginState, ...finalState } : finalState;
      }, initialCallbacks) ?? initialCallbacks;

    hooks?.forEach((plugin) => plugin.finalize?.(completeCallbacks));

    return seal(sealable(completeCallbacks));
  }, [
    attachCallbacks,
    canUpdateContent,
    getApi,
    getEditorId,
    hooks,
    isEditorLocked,
    render,
    refreshEditor,
  ]);

  state.attachCallbacks(state);

  return state.render(state);
};

const emptyApi = {} as const;

let editorIdIndex = 0;

const getUniqueEditorId = () => `e${editorIdIndex++}`;
