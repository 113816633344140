import { ComponentProps } from 'react';
import { trackUserEvent } from '../../../../../../../_shared/actions/thunks/trackUserEvent.ts';
import { TrackedEvent } from '../../../../../../../_shared/constants/trackedEvent.ts';
import { useDispatch } from '../../../../../../../_shared/hooks/useDispatch.ts';
import {
  ContentItemEditingChangeAction,
  ContentItemEditingEventOrigins,
} from '../../../../../../../_shared/models/events/ContentItemEditingEventData.type.ts';
import { ElementType } from '../../../../../../contentInventory/content/models/ContentItemElementType.ts';
import { CustomElementSelectionDialog as CustomElementSelectionDialogComponent } from '../../../components/elements/customElement/CustomElementSelectionDialog.tsx';

type Props = ComponentProps<typeof CustomElementSelectionDialogComponent>;

export const CustomElementSelectionDialog = ({
  onAssetSelected,
  onAssetsSelected,
  onItemSelected,
  onItemsSelected,
  ...restProps
}: Props) => {
  const dispatch = useDispatch();

  return (
    <CustomElementSelectionDialogComponent
      onAssetSelected={(id) => {
        dispatch(
          trackUserEvent(TrackedEvent.ContentItemEditing, {
            action: ContentItemEditingChangeAction.AssignAsset,
            origin: ContentItemEditingEventOrigins.Paper,
            contentElementType: ElementType.Custom,
            selectedItemsCount: 1,
          }),
        );
        onAssetSelected(id);
      }}
      onAssetsSelected={(ids) => {
        dispatch(
          trackUserEvent(TrackedEvent.ContentItemEditing, {
            action: ContentItemEditingChangeAction.AssignAsset,
            origin: ContentItemEditingEventOrigins.Paper,
            contentElementType: ElementType.Custom,
            selectedItemsCount: ids.length,
          }),
        );
        onAssetsSelected(ids);
      }}
      onItemSelected={(id) => {
        dispatch(
          trackUserEvent(TrackedEvent.ContentItemEditing, {
            action: ContentItemEditingChangeAction.LinkContentItem,
            allowedContentTypesCount: 0,
            origin: ContentItemEditingEventOrigins.Paper,
            contentElementType: ElementType.Custom,
            selectedItemsCount: 1,
          }),
        );
        onItemSelected(id);
      }}
      onItemsSelected={(ids) => {
        dispatch(
          trackUserEvent(TrackedEvent.ContentItemEditing, {
            action: ContentItemEditingChangeAction.LinkContentItem,
            allowedContentTypesCount: 0,
            origin: ContentItemEditingEventOrigins.Paper,
            contentElementType: ElementType.Custom,
            selectedItemsCount: ids.length,
          }),
        );
        onItemsSelected(ids);
      }}
      {...restProps}
    />
  );
};
