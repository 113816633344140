import { CopyState } from '../constants/copyState.ts';
import {
  People_IntercomChatWindowOpened,
  ProjectApp_RouteEntered,
  ProjectApp_RouteLeft,
  ProjectListing_ActivateProject_Finished,
  ProjectListing_ActivateProject_Started,
  ProjectListing_DeactivateProject_Finished,
  ProjectListing_DeactivateProject_Started,
  ProjectListing_ProjectCloning_Initialized,
  ProjectListing_ProjectCreation_Initialized,
  Project_CopyState_Finished,
  Project_CopyState_Populate,
  Project_CopyState_Start,
  Project_CopyState_Stop,
  Project_CopyState_Updated,
  Project_ProjectContext_Left,
  Project_TrialActivation_Close,
  Project_TrialActivation_Show,
} from '../constants/projectActionTypes.ts';

export const projectContextLeft = () =>
  ({
    type: Project_ProjectContext_Left,
  }) as const;

export const showIntercom = () =>
  ({
    type: People_IntercomChatWindowOpened,
  }) as const;

export const showTrialActivationModal = () =>
  ({
    type: Project_TrialActivation_Show,
  }) as const;

export const closeTrialActivationModal = () =>
  ({
    type: Project_TrialActivation_Close,
  }) as const;

export const activateProjectStarted = (projectId: Uuid) =>
  ({
    type: ProjectListing_ActivateProject_Started,
    payload: {
      projectId,
    },
  }) as const;

export const activateProjectFinished = (projectId: Uuid) =>
  ({
    type: ProjectListing_ActivateProject_Finished,
    payload: {
      projectId,
    },
  }) as const;

export const deactivateProjectStarted = (projectId: Uuid) =>
  ({
    type: ProjectListing_DeactivateProject_Started,
    payload: {
      projectId,
    },
  }) as const;

export const deactivateProjectFinished = (projectId: Uuid) =>
  ({
    type: ProjectListing_DeactivateProject_Finished,
    payload: {
      projectId,
    },
  }) as const;

export const projectCreationInitialized = (projectId: Uuid) =>
  ({
    type: ProjectListing_ProjectCreation_Initialized,
    payload: {
      projectId,
    },
  }) as const;

export const projectCloningInitialized = (projectId: Uuid) =>
  ({
    type: ProjectListing_ProjectCloning_Initialized,
    payload: {
      projectId,
    },
  }) as const;

export const populateProjectsToClone = (projectId: Uuid) =>
  ({
    type: Project_CopyState_Populate,
    payload: {
      projectId,
    },
  }) as const;

export const projectCopyStateUpdated = (projectId: Uuid, copyState: CopyState) =>
  ({
    type: Project_CopyState_Updated,
    payload: {
      data: {
        projectId,
        copyState,
      },
    },
  }) as const;

export const projectCopyStateFinished = (projectId: Uuid) =>
  ({
    type: Project_CopyState_Finished,
    payload: {
      data: {
        projectId,
      },
    },
  }) as const;

export const projectCopyStateStart = (projectId: Uuid) =>
  ({
    type: Project_CopyState_Start,
    payload: {
      projectId,
    },
  }) as const;

export const projectCopyStateStop = () =>
  ({
    type: Project_CopyState_Stop,
  }) as const;

export const projectAppRouteEntered = () =>
  ({
    type: ProjectApp_RouteEntered,
  }) as const;

export const projectAppRouteLeft = () =>
  ({
    type: ProjectApp_RouteLeft,
  }) as const;
