import Immutable from 'immutable';
import { Dispatch, GetState, ThunkPromise } from '../../../../../@types/Dispatcher.type.ts';
import { TrackedEvent } from '../../../../../_shared/constants/trackedEvent.ts';
import { TrackUserEventAction } from '../../../../../_shared/models/TrackUserEvent.type.ts';
import { IContentType } from '../../../../../data/models/contentModelsApp/contentTypes/ContentType.ts';
import { TypeListOperationResultServerModel } from '../../../../../repositories/serverModels/contentModels/contentTypeServerModels.ts';
import {
  ContentType_Listing_ArchiveContentTypesFinished,
  ContentType_Listing_ArchiveContentTypesStarted,
} from '../../constants/contentTypesActionTypes.ts';

interface IDeps {
  readonly contentTypeRepository: {
    readonly archiveContentTypes: (
      typeIds: UuidArray,
    ) => Promise<TypeListOperationResultServerModel>;
  };
  readonly loadContentTypesData: () => ThunkPromise;
  readonly trackUserEvent: TrackUserEventAction;
}

const archiveContentTypesStarted = (affectedContentTypeIds: Immutable.Set<Uuid>) =>
  ({
    type: ContentType_Listing_ArchiveContentTypesStarted,
    payload: { affectedContentTypeIds },
  }) as const;

const archiveContentTypesFinished = (
  affectedContentTypes: Immutable.Set<IContentType>,
  successfulIds: Immutable.Set<Uuid>,
) =>
  ({
    type: ContentType_Listing_ArchiveContentTypesFinished,
    payload: {
      affectedContentTypes,
      successfulIds,
    },
  }) as const;

export type ArchiveContentTypesActionType = ReturnType<
  typeof archiveContentTypesStarted | typeof archiveContentTypesFinished
>;

export const createArchiveContentTypesAction =
  (deps: IDeps) =>
  (contentTypeIds: Immutable.Set<Uuid>): ThunkPromise =>
  async (dispatch: Dispatch, getState: GetState): Promise<void> => {
    const affectedTypes = getState()
      .data.contentTypes.byId.filter((type: IContentType) => contentTypeIds.contains(type.id))
      .toSet();
    dispatch(archiveContentTypesStarted(contentTypeIds));

    const operationResult = await deps.contentTypeRepository.archiveContentTypes(
      contentTypeIds.toArray(),
    );
    dispatch(
      archiveContentTypesFinished(affectedTypes, Immutable.Set(operationResult.modifiedTypeIds)),
    );

    dispatch(
      deps.trackUserEvent(TrackedEvent.ContentTypeDeleted, {
        'content-type-count': operationResult.modifiedTypeIds.length,
      }),
    );
    await dispatch(deps.loadContentTypesData());
  };
