import { trackUserEvent } from '../../../../../_shared/actions/thunks/trackUserEvent.ts';
import { repositoryCollection } from '../../../../../_shared/repositories/repositories.ts';
import {
  loadContentItemUsage,
  loadListingContentItemsByIds,
  loadListingItemsWithAllVariants,
} from '../../../../../data/actions/thunkDataActions.ts';
import {
  convertElementsToDomainModel,
  ensureContentItemNameValidity,
} from '../../../stores/utils/contentItemHelperMethods.ts';
import {
  publishContentItemVariant,
  schedulePublishOfContentItemVariant,
} from '../../ContentItemEditing/actions/thunkContentItemEditingActions.ts';
import { createItemParser } from '../../ContentItemEditing/utils/parseContentItem.ts';
import {
  ensureLoadedContentTypes,
  loadDefaultListingItems,
  loadListingItems,
} from '../../LoadedItems/actions/thunkLoadedItemsActions.ts';
import { cascadePublishDialogSubmittedActionCreator } from './thunks/cascadePublishDialogSubmitted.ts';
import { cascadeScheduleDialogSubmittedActionCreator } from './thunks/cascadeScheduleDialogSubmitted.ts';
import { createEnsureDefaultVariantsAction } from './thunks/ensureDefaultVariants.ts';
import { createExpandNodeAction } from './thunks/expandNode.ts';
import { initCascadeModalActionCreator } from './thunks/initCascadeModal.ts';
import { undoCascadeActionCreator } from './thunks/undoCascadeAction.ts';

const { contentItemRepository } = repositoryCollection;

const itemParser = createItemParser({
  convertElementsToDomainModel,
  ensureContentItemNameValidity,
});

export const ensureDefaultVariants = createEnsureDefaultVariantsAction({
  loadDefaultListingItems,
});

export const initCascadeModal = initCascadeModalActionCreator({
  loadListingItems,
  ensureDefaultVariants,
  ensureLoadedContentTypes,
  loadListingItemsWithAllVariants,
});

export const expandNode = createExpandNodeAction({
  loadListingContentItemsByIds,
  ensureLoadedContentTypes,
  loadListingItemsWithAllVariants,
});

export const cascadePublishDialogSubmitted = cascadePublishDialogSubmittedActionCreator({
  loadListingItems,
  loadDefaultListingItems,
  loadContentItemUsage,
  contentItemRepository,
  publishSingleVariant: publishContentItemVariant,
  parseContentItemVariant: itemParser.parseContentItemVariant,
  trackUserEvent,
});

export const cascadeScheduleDialogSubmitted = cascadeScheduleDialogSubmittedActionCreator({
  loadListingItems,
  loadDefaultListingItems,
  loadContentItemUsage,
  contentItemRepository,
  scheduleSingleVariant: schedulePublishOfContentItemVariant,
  parseContentItemVariant: itemParser.parseContentItemVariant,
  trackUserEvent,
});

export const undoCascadeAction = undoCascadeActionCreator({
  loadListingItems,
  loadDefaultListingItems,
  loadContentItemUsage,
  contentItemRepository,
  parseContentItemVariant: itemParser.parseContentItemVariant,
});
