import { forwardRef } from 'react';
import { IAnimatedModalDialogProps } from '../../../../../_shared/components/ModalDialog/IAnimatedModalDialogProps.type.ts';
import { useDispatch } from '../../../../../_shared/hooks/useDispatch.ts';
import { useSelector } from '../../../../../_shared/hooks/useSelector.ts';
import { IStore } from '../../../../../_shared/stores/IStore.type.ts';
import { compose } from '../../../../../_shared/utils/func/compose.ts';
import { pluralizeWithoutCount } from '../../../../../_shared/utils/stringUtils.ts';
import { ItemEditingModalDialogType } from '../../../constants/itemEditingModalDialogType.ts';
import { cascadeScheduleDialogSubmitted } from '../actions/thunkCascadeModalActions.ts';
import { getEditedContentItemName } from '../selectors/getEditedContentItemName.ts';
import { getSelectedItems } from '../selectors/getSelectedItems.ts';
import { getCannotPublishReasonForContext } from '../utils/getCannotPublishReason.ts';
import { getStatusInfoMessage } from '../utils/getStatusInfoMessage.ts';
import { CascadeModal } from './CascadeModal.tsx';

const getSelectedItemsCount = (s: IStore) =>
  getSelectedItems(
    s,
    getCannotPublishReasonForContext(ItemEditingModalDialogType.CascadeScheduleDialog),
  ).length;
const createTooltipText = (selectedItemsCount: number) =>
  selectedItemsCount > 0
    ? undefined
    : 'Select what to schedule. Already published content or content scheduled for earlier won’t be affected.';
const createCallToAction = (selectedItemsCount: number) =>
  selectedItemsCount > 0
    ? `Schedule item with selected ${pluralizeWithoutCount('item', selectedItemsCount)}`
    : 'Schedule';

export const CascadeScheduleModal = forwardRef<HTMLDivElement, IAnimatedModalDialogProps>(
  (props, ref) => {
    const dispatch = useDispatch();

    const modalTitle = useSelector(
      (s) => `Select what should be scheduled with ${getEditedContentItemName(s)}`,
    );
    const onPublish = () => dispatch(cascadeScheduleDialogSubmitted());
    const statusInfoMessage = useSelector((s) =>
      getStatusInfoMessage(getEditedContentItemName(s), getSelectedItemsCount(s)),
    );

    const tooltipText = useSelector(compose(createTooltipText, getSelectedItemsCount));
    const callToAction = useSelector(compose(createCallToAction, getSelectedItemsCount));

    return (
      <CascadeModal
        modalTitle={modalTitle}
        statusInfoMessage={statusInfoMessage}
        primaryActionAttrs={{
          callToAction,
          tooltipText,
          onClick: onPublish,
        }}
        {...props}
        ref={ref}
      />
    );
  },
);

CascadeScheduleModal.displayName = 'CascadeScheduleModal';
