import { ThunkFunction } from '../../../../../../../@types/Dispatcher.type.ts';
import { trackUserEvent } from '../../../../../../../_shared/actions/thunks/trackUserEvent.ts';
import { ItemColumnCode } from '../../../../../../../_shared/constants/itemColumnCode.ts';
import {
  FindRightContentTrackedEvent,
  TrackedEvent,
} from '../../../../../../../_shared/constants/trackedEvent.ts';
import { OrderBy, OrderByDirection } from '../../../../../../../_shared/models/OrderBy.ts';
import { UpdateListingOrderBy } from './updateListingOrderBy.ts';

function getToggledOrderBy(
  currentOrderBy: OrderBy<ItemColumnCode>,
  clickedColumnCode: ItemColumnCode,
): OrderBy<ItemColumnCode> {
  if (clickedColumnCode === currentOrderBy.columnCode) {
    return {
      columnCode: clickedColumnCode,
      direction:
        currentOrderBy.direction === OrderByDirection.Ascending
          ? OrderByDirection.Descending
          : OrderByDirection.Ascending,
    };
  }

  return {
    columnCode: clickedColumnCode,
    direction: OrderByDirection.Ascending,
  };
}

interface IOrderContentItemsActionDependencies {
  readonly updateListingOrderBy: UpdateListingOrderBy;
}

export const createOrderContentItemsAction =
  ({ updateListingOrderBy }: IOrderContentItemsActionDependencies) =>
  (clickedColumnCode: ItemColumnCode, saveToLocalStorage: boolean): ThunkFunction =>
  (dispatch, getState) => {
    const {
      listingUi: { orderBy },
    } = getState().contentApp;

    const updatedOrderBy = getToggledOrderBy(orderBy, clickedColumnCode);

    dispatch(updateListingOrderBy(updatedOrderBy, saveToLocalStorage));

    dispatch(
      trackUserEvent(TrackedEvent.FindRightContent, {
        column: updatedOrderBy.columnCode,
        name: FindRightContentTrackedEvent.SortedByColumn,
      }),
    );
  };
