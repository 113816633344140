import { Icons } from '@kontent-ai/component-library/Icons';
import { Menu } from '@kontent-ai/component-library/Menu';
import { useWebSpotlightInItemEditing } from '../../../context/WebSpotlightInItemEditingContext.tsx';

export const ToggleEditableElements = () => {
  const { editableElementsVisible, toggleEditableElements } = useWebSpotlightInItemEditing();

  return editableElementsVisible ? (
    <Menu.Item
      id="hide-editable-elements"
      label="Hide editable elements"
      leadingElement={<Icons.EyeSlash />}
      onAction={toggleEditableElements}
    />
  ) : (
    <Menu.Item
      id="show-editable-elements"
      label="Show editable elements"
      leadingElement={<Icons.Eye />}
      onAction={toggleEditableElements}
    />
  );
};
