import { createGuid, identity } from '@kontent-ai/utils';
import classNames from 'classnames';
import Immutable from 'immutable';
import React, { memo, useCallback, useContext, useRef, useState } from 'react';
import { DragMoveHandler } from '../../../../../../../_shared/components/DragDrop/dragDrop.type.ts';
import { DndTypes } from '../../../../../../../_shared/constants/dndTypes.ts';
import { EditorPaperContext } from '../../../../../../../_shared/contexts/EditorPaperContext.tsx';
import {
  DataUiCollection,
  getDataUiCollectionAttribute,
} from '../../../../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';
import { useCrossedHalfTargetHeight } from '../../../../../../../_shared/utils/dragDrop/hoveringCollisionStrategies.ts';
import { getReachedPaperThresholds } from '../../../../../../../_shared/utils/editorViewContext/editorPaperContext/utils/getReachedPaperThresholds.ts';
import { IContentType } from '../../../../../../../data/models/contentModelsApp/contentTypes/ContentType.ts';
import { ExpandedItemElements } from '../../../../LinkedItems/containers/ExpandedItemElements.tsx';
import { CreateContentGroupTabsId } from '../../../utils/contentGroupTabsId.ts';
import { DraggableLinkedItem } from './DraggableLinkedItem.tsx';

interface ILinkedItemsListOwnProps {
  readonly allowedContentTypes: Immutable.Set<IContentType>;
  readonly contentComponentId: Uuid | null;
  readonly contentItemIds: ReadonlyArray<Uuid>;
  readonly elementId: Uuid;
  readonly isDisabled: boolean;
  readonly areItemsPages?: boolean;
  readonly onItemDuplicate?: (contentItemId: Uuid, destinationCollectionId: Uuid) => void;
  readonly onRelatedEntryDeleted: (contentItemId: Uuid) => void;
  readonly onRelatedEntryMoved: DragMoveHandler;
}

interface ILinkedItemsListProps extends ILinkedItemsListOwnProps {}

export const LinkedItemsList: React.FC<ILinkedItemsListProps> = memo(
  ({
    allowedContentTypes,
    contentComponentId,
    contentItemIds,
    elementId,
    isDisabled,
    areItemsPages,
    onItemDuplicate,
    onRelatedEntryDeleted,
    onRelatedEntryMoved,
  }) => {
    const [isDragging, setIsDragging] = useState(false);
    const onDragStart = useCallback(() => setIsDragging(true), []);
    const onDragEnd = useCallback(() => setIsDragging(false), []);

    const { current: componentId } = useRef<Uuid>(createGuid());

    const paperContext = useContext(EditorPaperContext);
    const reachedThresholds = getReachedPaperThresholds(paperContext.thresholds);

    const renderExpandedLinkedItem = useCallback(
      (contentItemId: string, expandedItemId: string): JSX.Element => {
        const contentGroupTabsId = CreateContentGroupTabsId.forLinkedItem(
          elementId,
          contentComponentId ?? '',
          contentItemId,
        );

        return (
          <ExpandedItemElements
            contentGroupTabsId={contentGroupTabsId}
            contentItemId={contentItemId}
            expandedItemId={expandedItemId}
            isItemPage={areItemsPages}
          />
        );
      },
      [contentComponentId, elementId, areItemsPages],
    );

    // setting different ids for different modular content lists
    // it ensures that items are not draggable/droppable in context of one rich text element between multiple modular content lists
    const modularContentDndIdentifier = `${DndTypes.Related_Content_Item_Dnd_Identifier}_${componentId}`;

    const displayDragButton = !reachedThresholds.sizeXS || contentItemIds.length > 1;

    const hoveringCollisionStrategy = useCrossedHalfTargetHeight(contentItemIds, identity);

    // If there are many items, we need to optimize their rendering by hiding the ones outside the viewport to reduce React overhead
    // as hundreds/thousands of items produce too many components and DOM nodes to be rendered
    const hideItemsOutsideViewport = contentItemIds.length > 10;

    return (
      <div
        className={classNames('bar-item__list', {
          'bar-item__list--is-dragging': isDragging,
        })}
        {...getDataUiCollectionAttribute(DataUiCollection.ContentModuleListing)}
      >
        {contentItemIds.map((contentItemId) => (
          <DraggableLinkedItem
            allowedContentTypes={allowedContentTypes}
            contentComponentId={contentComponentId}
            contentItemId={contentItemId}
            displayDragButton={displayDragButton}
            elementId={elementId}
            hoveringCollisionStrategy={hoveringCollisionStrategy}
            hideOutsideViewport={hideItemsOutsideViewport}
            isDisabled={!!isDisabled}
            isPage={areItemsPages}
            key={contentItemId}
            modularContentDndIdentifier={modularContentDndIdentifier}
            onDelete={onRelatedEntryDeleted}
            onDragEnd={onDragEnd}
            onDragStart={onDragStart}
            onDuplicate={onItemDuplicate}
            onMove={onRelatedEntryMoved}
            renderExpanded={renderExpandedLinkedItem}
          />
        ))}
      </div>
    );
  },
);

LinkedItemsList.displayName = 'LinkedItemsList';
