import { memoize } from '@kontent-ai/memoization';
import { Collection } from '@kontent-ai/utils';
import Immutable from 'immutable';
import React from 'react';
import { IUserInfo, createUnknownUserInfo } from '../../../../../_shared/models/UserInfo.ts';
import {
  getUsersByIds,
  makeCurrentUserFirst,
} from '../../../../../_shared/utils/users/usersUtils.ts';
import { IProjectContributor } from '../../../../../data/models/users/ProjectContributor.ts';
import { Workflow } from '../../../../../data/models/workflow/Workflow.ts';
import {
  TimelineItem as TimelineItemModel,
  TimelineItemType,
} from '../../../models/revisions/TimeLineItem.ts';
import { isRevisionCurrent } from '../utils/revisionUtils.ts';
import { AssignmentItem } from './AssignmentItem.tsx';
import { DiscardItem } from './Discardtem.tsx';
import { RestoreItem } from './RestoreItem.tsx';
import { RevisionClickWrapper } from './RevisionClickWrapper.tsx';
import { RevisionItem } from './RevisionItem.tsx';

const getUserListForRevision = memoize.weak(
  (
    projectContributors: ReadonlyMap<UserId, IProjectContributor>,
    userIds: ReadonlyArray<UserId>,
    modifiedBy: UserId | undefined,
    currentUserId: UserId,
  ): ReadonlyArray<IUserInfo> => {
    // Backward compatibility - old revisions have just a single user
    const allUsers = modifiedBy ? [modifiedBy, ...userIds] : userIds;

    const contributors = getUsersByIds(
      projectContributors,
      Collection.getValues(new Set(allUsers)),
    );
    return makeCurrentUserFirst(contributors, currentUserId);
  },
);

type TimelineItemProps = {
  readonly currentUserId: Uuid;
  readonly projectContributors: ReadonlyMap<UserId, IProjectContributor>;
  readonly timelineItem: TimelineItemModel;
  readonly variantTimeline: ReadonlyArray<TimelineItemModel> | null;
  readonly workflows: ReadonlyMap<Uuid, Workflow>;
};

const TimelineItem = ({
  currentUserId,
  projectContributors,
  timelineItem,
  variantTimeline,
  workflows,
}: TimelineItemProps) => {
  const isCurrent = isRevisionCurrent(variantTimeline, timelineItem);
  const modifiedBy =
    projectContributors.get(timelineItem.lastModifiedBy) ??
    createUnknownUserInfo(timelineItem.lastModifiedBy);

  switch (timelineItem.type) {
    case TimelineItemType.Revision:
      return (
        <RevisionItem
          key={timelineItem.itemId}
          item={timelineItem}
          isCurrent={isCurrent}
          currentUserId={currentUserId}
          contributors={getUserListForRevision(
            projectContributors,
            timelineItem.contentChangedBy,
            modifiedBy?.userId,
            currentUserId,
          )}
        />
      );
    case TimelineItemType.Assignment: {
      const assignment = timelineItem.assignment;
      const assignedByUserId = assignment?.assignedBy;
      const assignedBy = assignedByUserId
        ? (projectContributors.get(assignedByUserId) ?? createUnknownUserInfo(assignedByUserId))
        : null;
      const assignees = assignment
        ? getUsersByIds(projectContributors, Array.from(assignment.assignees))
        : null;

      return (
        <AssignmentItem
          key={timelineItem.itemId}
          assignedBy={assignedBy}
          assignees={assignees}
          assignment={assignment}
          currentUserId={currentUserId}
          isCurrent={isCurrent}
          workflows={workflows}
        />
      );
    }
    case TimelineItemType.Restored: {
      const restoredFrom =
        timelineItem.restoredFrom && variantTimeline
          ? (variantTimeline.find(
              (item) => !!item && item.revisionId === timelineItem.restoredFrom,
            ) ?? null)
          : null;

      return (
        <RestoreItem
          key={timelineItem.itemId}
          item={timelineItem}
          isCurrent={isCurrent}
          modifiedBy={modifiedBy}
          restoredFrom={restoredFrom}
          currentUserId={currentUserId}
        />
      );
    }
    case TimelineItemType.DiscardedTo: {
      const discardedFrom =
        variantTimeline?.[variantTimeline.findIndex((t) => t.itemId === timelineItem.itemId) + 1] ??
        null;
      const discardedTo =
        variantTimeline?.find(
          (t) => t.revisionId === timelineItem.revisionId && t.itemId !== timelineItem.itemId,
        ) ?? null;

      return (
        <DiscardItem
          key={timelineItem.itemId}
          item={timelineItem}
          isCurrent={isCurrent}
          modifiedBy={modifiedBy}
          discardedFrom={discardedFrom}
          discardedTo={discardedTo}
          currentUserId={currentUserId}
        />
      );
    }
  }
};

type WrapperTimelineItemProps = {
  readonly currentUserId: UserId;
  readonly paths: Immutable.Map<Uuid, string> | null;
  readonly projectContributors: ReadonlyMap<UserId, IProjectContributor>;
  readonly timelineItem: TimelineItemModel;
  readonly variantTimeline: ReadonlyArray<TimelineItemModel> | null;
  readonly workflows: ReadonlyMap<Uuid, Workflow>;
};

const WrappedTimelineItem = (props: WrapperTimelineItemProps) => {
  return (
    <RevisionClickWrapper
      key={props.timelineItem.itemId}
      to={props.paths?.get(props.timelineItem.itemId) ?? ''}
    >
      <TimelineItem
        currentUserId={props.currentUserId}
        projectContributors={props.projectContributors}
        timelineItem={props.timelineItem}
        variantTimeline={props.variantTimeline}
        workflows={props.workflows}
      />
    </RevisionClickWrapper>
  );
};

type Props = {
  readonly currentUserId: UserId;
  readonly projectContributors: ReadonlyMap<UserId, IProjectContributor>;
  readonly variantTimeline: ReadonlyArray<TimelineItemModel> | null;
  readonly workflows: ReadonlyMap<Uuid, Workflow>;
  readonly renderMoreItemsButton: () => React.ReactElement | null;
  readonly paths: Immutable.Map<Uuid, string> | null;
};

export const RevisionTimelineList = (props: Props) => {
  if (!props.variantTimeline) {
    return null;
  }

  return (
    <>
      {props.variantTimeline.map((timelineItem) => (
        <WrappedTimelineItem
          currentUserId={props.currentUserId}
          key={timelineItem.itemId}
          paths={props.paths}
          projectContributors={props.projectContributors}
          timelineItem={timelineItem}
          variantTimeline={props.variantTimeline}
          workflows={props.workflows}
        />
      ))}
      {props.renderMoreItemsButton()}
    </>
  );
};
