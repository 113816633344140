import { Button } from '@kontent-ai/component-library/Button';
import { Card } from '@kontent-ai/component-library/Card';
import { Stack } from '@kontent-ai/component-library/Stack';
import { Spacing } from '@kontent-ai/component-library/tokens';
import Immutable from 'immutable';
import { FC, useState } from 'react';
import { OptionsList } from '../../../_shared/components/Options/OptionsList.tsx';
import { Warning } from '../../../_shared/components/infos/Warning.tsx';
import { EmailPreferenceType } from '../../../_shared/models/EmailPreferenceTypeEnum.ts';
import { OptionMode } from '../../../_shared/models/optionMode.ts';
import {
  DataUiElement,
  getDataUiElementAttribute,
} from '../../../_shared/utils/dataAttributes/DataUiAttributes.ts';

const options = [
  {
    id: EmailPreferenceType.Assignment,
    label: 'Workflow changes',
  },
  {
    id: EmailPreferenceType.Comments,
    label: 'Comments',
  },
  {
    id: EmailPreferenceType.Mentions,
    label: 'Mentions',
  },
  {
    id: EmailPreferenceType.Tasks,
    label: 'Tasks',
  },
  {
    id: EmailPreferenceType.ReportsWithZeroPrice,
    label: 'Usage reports with a total price of $0',
  },
  {
    id: EmailPreferenceType.WebhookNotifications,
    label: 'Webhook notifications',
  },
];

type UserEmailPreferencesProps = {
  readonly initialEmailPreferences: Immutable.Map<EmailPreferenceType, boolean>;
  readonly isUpdating: boolean;
  readonly onChange: (changed: boolean) => void;
  readonly onUpdate: (preferences: Immutable.Map<EmailPreferenceType, boolean>) => void;
};

export const UserEmailPreferences: FC<UserEmailPreferencesProps> = ({
  initialEmailPreferences,
  isUpdating,
  onChange,
  onUpdate,
}) => {
  const [emailPreferences, setEmailPreferences] = useState(initialEmailPreferences);

  const isChanged = !Immutable.is(emailPreferences, initialEmailPreferences);

  const updatePreferences = (): void => onUpdate(emailPreferences);

  const togglePreference = (type: EmailPreferenceType): void => {
    setEmailPreferences((prevPreferences) => prevPreferences.set(type, !prevPreferences.get(type)));
    onChange(isChanged);
  };

  const selectedOptions = emailPreferences
    .filter((preference) => !!preference && preference.valueOf())
    .keySeq()
    .toArray();

  return (
    <Card css="flex: 1" component="section">
      <Card.Headline>Email preferences</Card.Headline>
      <Card.Body>
        <Stack spacing={Spacing.XL}>
          <Stack spacing={Spacing.L}>
            <p>Select what you want to be notified about.</p>
            <Warning hideTitle>
              <p>E-mail preferences apply to all projects you are working on.</p>
            </Warning>
            <OptionsList
              mode={OptionMode.InvertedMultiple}
              options={options}
              selectedOptions={selectedOptions}
              onOptionClick={togglePreference}
            />
          </Stack>
          <Button
            buttonStyle="primary"
            onClick={updatePreferences}
            disabled={!isChanged || isUpdating}
          >
            {isUpdating && (
              <Button.ProgressIcon
                {...getDataUiElementAttribute(DataUiElement.SavingInProgressButtonIcon)}
              />
            )}
            <Button.Label>
              {isUpdating ? 'Updating preferences' : 'Update preferences'}
            </Button.Label>
          </Button>
        </Stack>
      </Card.Body>
    </Card>
  );
};
