import React from 'react';
import { useDispatch } from '../../../../../../../../_shared/hooks/useDispatch.ts';
import { compose } from '../../../../../../../../_shared/utils/func/compose.ts';
import { openPublishModal } from '../../../../../ChangeWorkflowStep/actions/thunks/changeWorkflowStepModalActions.ts';
import {
  EditingActionDynamicDetailProvider,
  EditingActionDynamicDetailProviderOwnProps,
} from '../../../../components/editingActions/actions/EditingActionDynamicDetailProvider.tsx';

export const PublishItemAction: React.FC<EditingActionDynamicDetailProviderOwnProps> = ({
  children,
  ...props
}) => {
  const dispatch = useDispatch();
  const performAction = compose(dispatch, openPublishModal);

  return (
    <EditingActionDynamicDetailProvider {...props} performAction={performAction}>
      {children}
    </EditingActionDynamicDetailProvider>
  );
};
