import { Badge } from '@kontent-ai/component-library/Badge';
import { Box } from '@kontent-ai/component-library/Box';
import { TabView } from '@kontent-ai/component-library/TabView';
import { Spacing } from '@kontent-ai/component-library/tokens';
import {
  getDataUiComponentAttribute,
  getDataUiObjectNameAttribute,
} from '@kontent-ai/component-library/utils';
import classNames from 'classnames';
import Immutable from 'immutable';
import { FC, PropsWithChildren } from 'react';
import { TabBadgeType } from '../../../../../../_shared/components/TabMenu/TabBadgeType.tsx';
import {
  DataUiElement,
  getDataUiElementAttribute,
} from '../../../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';
import { pluralizeWithCount } from '../../../../../../_shared/utils/stringUtils.ts';
import { IContentGroup } from '../../../../../contentInventory/content/models/contentTypeElements/types/ContentGroup.ts';
import { getTooltipText } from '../../utils/tooltipUilts.ts';

type ContentGroupExpandedContentTabViewProps = {
  readonly badgeType: TabBadgeType | undefined;
  readonly contentTypeGroups: readonly IContentGroup[];
  readonly incompleteElementsPerGroup: Immutable.Map<Uuid, number>;
  readonly selectedContentGroupId: Uuid | null;
  readonly onSelect: (id: Uuid) => void;
};

export const ContentGroupExpandedContentTabView: FC<
  PropsWithChildren<ContentGroupExpandedContentTabViewProps>
> = ({
  badgeType,
  contentTypeGroups,
  incompleteElementsPerGroup,
  onSelect,
  selectedContentGroupId,
  children,
}) => {
  const contentTypeGroupsList = contentTypeGroups.map(({ id, name }) => {
    const incompleteElements = incompleteElementsPerGroup.get(id) ?? 0;

    return {
      id,
      label: name,
      ariaDescription: incompleteElements
        ? `${badgeType}: ${pluralizeWithCount('incomplete element', incompleteElements)}`
        : undefined,
      leadingElement: incompleteElements ? (
        <Box
          display="flex"
          width={Spacing.L}
          height={Spacing.L}
          className={classNames({
            'tabbed-navigation__tab--with-warning': badgeType === TabBadgeType.Warning,
            'tabbed-navigation__tab--with-error': badgeType === TabBadgeType.Error,
          })}
          {...getDataUiComponentAttribute(Badge)}
        />
      ) : null,
      tooltipText: incompleteElements ? getTooltipText(incompleteElements, 0) : undefined,
      ...getDataUiObjectNameAttribute(name),
      ...getDataUiElementAttribute(DataUiElement.ContentGroup),
    };
  });

  return (
    <TabView
      items={contentTypeGroupsList}
      onSelectionChange={onSelect}
      selectedKey={selectedContentGroupId}
    >
      {children}
    </TabView>
  );
};
