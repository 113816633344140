import React from 'react';
import { useDispatch } from '../../../../../../../../_shared/hooks/useDispatch.ts';
import { useSelector } from '../../../../../../../../_shared/hooks/useSelector.ts';
import { ContentItemEditingExpandCollapseAction } from '../../../../../../../../_shared/models/events/ContentItemEditingEventData.type.ts';
import { compose } from '../../../../../../../../_shared/utils/func/compose.ts';
import { getDiscussionsThreads } from '../../../../../../utils/commentUtils.ts';
import { discussionSidebarOpened } from '../../../../actions/contentItemEditingActions.ts';
import {
  EditingActionDynamicDetailProvider,
  EditingActionDynamicDetailProviderOwnProps,
} from '../../../../components/editingActions/actions/EditingActionDynamicDetailProvider.tsx';
import { trackContentItemEditingAction } from '../../../../utils/editingActions/trackContentItemEditingAction.ts';

export const OpenDiscussionAction: React.FC<EditingActionDynamicDetailProviderOwnProps> = ({
  children,
  ...props
}) => {
  const badgeValue = useSelector(
    (s) =>
      getDiscussionsThreads(s.contentApp.editedContentItemVariantComments.commentThreads).length,
  );

  const dispatch = useDispatch();
  const performAction = compose(
    dispatch,
    trackContentItemEditingAction(
      discussionSidebarOpened(),
      ContentItemEditingExpandCollapseAction.DiscussionsTabSelected,
    ),
  );

  return (
    <EditingActionDynamicDetailProvider
      {...props}
      badgeValue={badgeValue}
      performAction={performAction}
    >
      {children}
    </EditingActionDynamicDetailProvider>
  );
};
