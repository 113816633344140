import { Collection } from '@kontent-ai/utils';
import { IStore } from '../../../../../../_shared/stores/IStore.type.ts';
import { getAvailableSpacesForUser } from '../../utils/getAvailableSpacesForUser.ts';
import { PreviewLink, selectPreviewLinks } from './selectPreviewLinks.ts';

export const getAvailablePreviewLinksForUser = (state: IStore): ReadonlyArray<PreviewLink> => {
  const availableSpaceIds = getAvailableSpacesForUser(
    state.contentApp.editedContentItemVariant?.id.variantId ?? null,
    state.sharedApp.currentProjectId,
    Collection.getValues(state.data.spaces.byId),
    Collection.getValues(state.data.collections.byId),
    state.data.user,
  ).map((space) => space.id);

  const previewLinks = selectPreviewLinks(state);

  return previewLinks.filter(
    (link) => link && (!link.spaceId || availableSpaceIds.includes(link.spaceId)),
  );
};
