import { ThunkFunction } from '../../../../../../@types/Dispatcher.type.ts';
import { TrackedEvent } from '../../../../../../_shared/constants/trackedEvent.ts';
import { TrackUserEventAction } from '../../../../../../_shared/models/TrackUserEvent.type.ts';
import {
  ContentItemEditingChangeAction,
  ContentItemEditingEventOrigins,
} from '../../../../../../_shared/models/events/ContentItemEditingEventData.type.ts';
import { ElementType } from '../../../../../contentInventory/content/models/ContentItemElementType.ts';
import { getNewContentItemForm } from '../../../NewContentItem/selectors/newContentItemDialog.ts';

interface IDeps {
  readonly trackUserEvent: TrackUserEventAction;
}

export const createTrackContentComponentCreatedAction =
  (deps: IDeps) => (): ThunkFunction => (dispatch, getState) => {
    const { contentTypeSection } = getNewContentItemForm(getState());

    dispatch(
      deps.trackUserEvent(TrackedEvent.ContentItemEditing, {
        action: ContentItemEditingChangeAction.CreateComponent,
        origin: ContentItemEditingEventOrigins.Paper,
        contentElementType: ElementType.RichText,
        contentTypeSection,
      }),
    );
  };
