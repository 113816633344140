import { noOperation } from '@kontent-ai/utils';
import React from 'react';
import { useDispatch } from '../../../../../../../../_shared/hooks/useDispatch.ts';
import { useSelector } from '../../../../../../../../_shared/hooks/useSelector.ts';
import { ContentItemEditingExpandCollapseAction } from '../../../../../../../../_shared/models/events/ContentItemEditingEventData.type.ts';
import { compose } from '../../../../../../../../_shared/utils/func/compose.ts';
import { showAllComments } from '../../../../actions/contentItemEditingActions.ts';
import {
  EditingActionDynamicDetailProvider,
  EditingActionDynamicDetailProviderOwnProps,
} from '../../../../components/editingActions/actions/EditingActionDynamicDetailProvider.tsx';
import { usePreviewContext } from '../../../../context/PreviewContextProvider.tsx';
import { selectUnresolvedInlineCommentThreads } from '../../../../selectors/inlineCommentSelectors.ts';
import { trackContentItemEditingAction } from '../../../../utils/editingActions/trackContentItemEditingAction.ts';

export const OpenInlineCommentsAction: React.FC<EditingActionDynamicDetailProviderOwnProps> = ({
  children,
  ...props
}) => {
  const badgeValue = useSelector((s) => selectUnresolvedInlineCommentThreads(s).length);

  const dispatch = useDispatch();
  const { isPreviewOpened } = usePreviewContext();
  const performAction = isPreviewOpened
    ? noOperation
    : compose(
        dispatch,
        trackContentItemEditingAction(
          showAllComments(),
          ContentItemEditingExpandCollapseAction.ShowAllComments,
        ),
      );

  return (
    <EditingActionDynamicDetailProvider
      {...props}
      badgeValue={badgeValue}
      isDisabled={isPreviewOpened}
      performAction={performAction}
      tooltip={isPreviewOpened ? 'To see all comments, turn off the Preview toggle.' : undefined}
    >
      {children}
    </EditingActionDynamicDetailProvider>
  );
};
