import { useEffect } from 'react';
import { trackUserEvent } from '../../../../../_shared/actions/thunks/trackUserEvent.ts';
import { TrackedEvent } from '../../../../../_shared/constants/trackedEvent.ts';
import { useDispatch } from '../../../../../_shared/hooks/useDispatch.ts';
import { useSelector } from '../../../../../_shared/hooks/useSelector.ts';
import { DateRange } from '../../../../../_shared/models/DateRange.type.ts';
import { LoadingStatus } from '../../../../../_shared/models/LoadingStatusEnum.ts';
import { CalendarEventTypes } from '../../../../../_shared/models/TrackUserEventData.ts';
import { ContentItemFilterOrigin } from '../../../../../_shared/models/events/ContentItemFilterEventData.type.ts';
import { isContentItemFilterInitialized } from '../../../../contentInventory/content/features/ListingFilter/containers/ContentItemFilter.tsx';
import { clearListingFilter } from '../../../../itemEditor/actions/contentActions.ts';
import {
  calendarDrillDownDateSelected,
  calendarItemDetailsSelected,
} from '../../actions/calendarActions.ts';
import {
  filterCalendar,
  leaveCalendar,
  loadItemsMatchingFilterWithVariantActiveInPeriod,
} from '../../actions/thunkCalendarActions.ts';
import { Calendar as CalendarComponent } from '../../components/Calendar/Calendar.tsx';
import { convertContentItemsToCalendarEvents } from '../../selectors/calendarSelectors.tsx';

export const Calendar = () => {
  const listingItemsLoadingStatus = useSelector((s) => s.calendarApp.itemsLoadingStatus);
  const wasItemsLimitExceeded = useSelector((s) => s.calendarApp.wasDataCountLimitExceeded);
  const selectedArrangement = useSelector((s) => s.calendarApp.selectedArrangement);
  const range = useSelector((s) => s.calendarApp.selectedPeriod);
  const isFilterInitialized = useSelector(
    (s) =>
      isContentItemFilterInitialized(
        ContentItemFilterOrigin.Calendar,
        s.contentApp.listingUi.filterStatus,
      ) && s.calendarApp.itemsLoadingStatus !== LoadingStatus.InitialEmpty,
  );
  const isCurrentMonthLoaded = useSelector(
    (s) => s.calendarApp.itemsLoadingStatus === LoadingStatus.Loaded,
  );
  const events = useSelector((s) =>
    convertContentItemsToCalendarEvents(
      ...s.calendarApp.orderedItemIds
        .toArray()
        .map((id) => s.data.listingContentItems.byId.get(id))
        .filter((item) => !!item),
    ),
  );
  const calendarView = useSelector((s) => s.calendarApp.calendarView);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(
      trackUserEvent(TrackedEvent.Calendar, {
        action: CalendarEventTypes.CalendarOpened,
      }),
    );

    return () => dispatch(leaveCalendar());
  }, []);

  const periodChanged = (period: DateRange) =>
    dispatch(loadItemsMatchingFilterWithVariantActiveInPeriod(period));

  const drillDownDateSelected = (date: DateTimeStamp) =>
    dispatch(calendarDrillDownDateSelected(date));

  const itemDetailSelected = (itemId: Uuid, date: DateTimeStamp) => {
    dispatch(calendarItemDetailsSelected(itemId, date));
    dispatch(
      trackUserEvent(TrackedEvent.Calendar, {
        action: CalendarEventTypes.ItemDetailsSelected,
      }),
    );
  };

  const filterChanged = () => dispatch(filterCalendar());

  const clearFilter = () => dispatch(clearListingFilter());

  return (
    <CalendarComponent
      calendarView={calendarView}
      events={events}
      isCurrentMonthLoaded={isCurrentMonthLoaded}
      isFilterInitialized={isFilterInitialized}
      listingItemsLoadingStatus={listingItemsLoadingStatus}
      onClearFilter={clearFilter}
      onDrillDownDateSelected={drillDownDateSelected}
      onFilterChange={filterChanged}
      onItemDetailSelected={itemDetailSelected}
      onPeriodChanged={periodChanged}
      range={range}
      selectedArrangement={selectedArrangement}
      wasItemsLimitExceeded={wasItemsLimitExceeded}
    />
  );
};
