import { Button } from '@kontent-ai/component-library/Button';

type Props = Readonly<{
  onClick: () => void;
}>;

export const OpenPreviewUrlSettingsButton = ({ onClick }: Props) => {
  return (
    <Button buttonStyle="primary" onClick={onClick}>
      Set up preview URL
    </Button>
  );
};
