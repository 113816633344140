import { memoize } from '@kontent-ai/memoization';
import { ContentItemEditingEventOrigins } from '../../../../../../_shared/models/events/ContentItemEditingEventData.type.ts';
import { StaticActionDetail } from '../../constants/editingActions/staticDetails.ts';

export type DynamicActionDetail = {
  readonly badgeValue?: number;
  readonly isDisabled?: boolean;
  readonly outwardUrl?: string;
  readonly performAction: (origin: ContentItemEditingEventOrigins) => void;
  readonly tooltip?: string;
};

export type ContentItemActionDescriptor = StaticActionDetail & DynamicActionDetail;

export const mergeActionDetails = memoize.maxOne(
  (
    staticDetail: StaticActionDetail,
    dynamicDetail: DynamicActionDetail,
  ): ContentItemActionDescriptor => ({
    ...staticDetail,
    ...dynamicDetail,
  }),
);
