import { Dispatch, ThunkPromise } from '../../../../../../../../@types/Dispatcher.type.ts';
import { showErrorNotification } from '../../../../../../../../_shared/actions/sharedActions.ts';
import { PublishErrorMessage } from '../../../../../../../../_shared/features/ChangeWorkflowStepModal/constants/uiConstants.ts';
import { OpenWorkflowStepModalAction } from '../../../../../../../../_shared/features/ChangeWorkflowStepModal/types/workflowStepModalActionTypes.ts';
import { IOpenBulkChangeWorkflowStepModal } from './openBulkChangeWorkflowStepModal.ts';

interface IOpenBulkPublishModalActionDeps {
  readonly checkPublishWarnings: () => ThunkPromise;
  readonly openBulkChangeWorkflowStepModal: IOpenBulkChangeWorkflowStepModal;
}

export const createOpenBulkPublishModalAction =
  ({ checkPublishWarnings, openBulkChangeWorkflowStepModal }: IOpenBulkPublishModalActionDeps) =>
  (): ThunkPromise =>
  async (dispatch: Dispatch) => {
    try {
      await dispatch(checkPublishWarnings());
      dispatch(openBulkChangeWorkflowStepModal(OpenWorkflowStepModalAction.Publish));
    } catch {
      dispatch(showErrorNotification(PublishErrorMessage));
    }
  };
