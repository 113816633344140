import { useEffect, useState } from 'react';
import { IntercomUtils } from '../utils/intercomUtils.ts';

export const useIntercomBoot = (
  intercomUtils: IntercomUtils,
  userId: Uuid,
  userHash: string,
): void => {
  const [isIntercomBooted, setIsIntercomBooted] = useState(false);

  useEffect(() => {
    if (isIntercomBooted) return;

    intercomUtils.boot({
      app_id: self._envConfig.intercomAppId,
      user_id: userId,
      user_hash: userHash,
    });

    setIsIntercomBooted(true);
  }, [intercomUtils, isIntercomBooted, userId, userHash]);
};
