import { Icons } from '@kontent-ai/component-library/Icons';
import { Publish } from '../../../../../../../_shared/constants/itemActions.ts';
import { useDispatch } from '../../../../../../../_shared/hooks/useDispatch.ts';
import { ContentItemEditingEventOrigins } from '../../../../../../../_shared/models/events/ContentItemEditingEventData.type.ts';
import { DataUiWorkflowAction } from '../../../../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';
import { openPublishModal } from '../../../../ChangeWorkflowStep/actions/thunks/changeWorkflowStepModalActions.ts';
import { WorkflowStepActionButton } from '../../../components/details/WorkflowStepActionButton.tsx';

export const PublishButton = () => {
  const dispatch = useDispatch();
  return (
    <WorkflowStepActionButton
      buttonStyle="primary"
      dataUiAction={DataUiWorkflowAction.Publish}
      icon={Icons.PaperPlane}
      label={Publish}
      onClick={() => {
        dispatch(openPublishModal(ContentItemEditingEventOrigins.ItemDetails));
      }}
    />
  );
};
