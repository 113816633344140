import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Warning } from '../../../_shared/components/infos/Warning.tsx';
import {
  BillingInformationRoute,
  EnsuredSubscriptionRouteParams,
  SubscriptionRoute,
} from '../../../_shared/constants/routePaths.ts';
import { AvailableSubscriptionAdminsWithoutCurrentProject } from '../../../_shared/containers/infos/AvailableSubscriptionAdminsWithoutCurrentProject.tsx';
import { LinkLike } from '../../../_shared/uiComponents/LinkLike/LinkLike.tsx';
import { buildPath } from '../../../_shared/utils/routing/routeTransitionUtils.ts';
import {
  isSubscriptionSuspendedAutomatically,
  isSubscriptionSuspendedDueToMissingToS,
} from '../../subscriptionManagement/shared/utils/subscriptionStatusUtils.ts';
import { TrialActivation } from '../containers/TrialActivation.tsx';

type SuspendedSubscriptionsWarningProps = {
  readonly administratedSubscriptionLinks: ReadonlyArray<SubscriptionWarningLinkData>;
  readonly notAdministratedSubscriptionLinks: ReadonlyArray<SubscriptionWarningLinkData>;
  readonly onContactSupport: () => void;
};

export type SubscriptionWarningLinkData = {
  readonly isPrepaid: boolean;
  readonly statusReason: string | null;
  readonly subscriptionId: Uuid;
  readonly subscriptionName: string;
};
export const SuspendedSubscriptionsWarning: React.FC<SuspendedSubscriptionsWarningProps> = ({
  administratedSubscriptionLinks,
  notAdministratedSubscriptionLinks,
  onContactSupport,
}) => {
  const [showAdminsDialog, setShowAdminsDialog] = useState(false);
  const [subscriptionId, setSubscriptionId] = useState<Uuid | null>(null);

  const closeSubscriptionAdminDialog = () => {
    setShowAdminsDialog(false);
    setSubscriptionId(null);
  };

  const openSubscriptionAdminDialog = (openedSubscriptionId: Uuid): void => {
    setShowAdminsDialog(true);
    setSubscriptionId(openedSubscriptionId);
  };

  const suspendedSubscriptionCount =
    administratedSubscriptionLinks.length + notAdministratedSubscriptionLinks.length;
  const warningText = getWarningTexts(suspendedSubscriptionCount);

  return (
    <div className="row">
      <div className="col-sm-12">
        <Warning subtitle={warningText.title}>
          <div className="callout__text-block">
            <p>{warningText.explanation}</p>
            <p>
              Now you can: <br />
            </p>
            <ul>
              {administratedSubscriptionLinks.map((s: SubscriptionWarningLinkData, index) => (
                <li key={index}>
                  <RenewSubscriptionMessageLink {...s} onContactSupport={onContactSupport} /> to
                  renew the subscription named “{s.subscriptionName}”.
                </li>
              ))}
              {notAdministratedSubscriptionLinks.map((s: SubscriptionWarningLinkData, index) => (
                <li key={index}>
                  <p>
                    <LinkLike onClick={() => openSubscriptionAdminDialog(s.subscriptionId)}>
                      Contact an admin
                    </LinkLike>
                    {` of the “${s.subscriptionName}” subscription to renew it.`}
                  </p>
                  {showAdminsDialog && subscriptionId === s.subscriptionId && (
                    <AvailableSubscriptionAdminsWithoutCurrentProject
                      onClose={closeSubscriptionAdminDialog}
                      subscriptionId={s.subscriptionId}
                    />
                  )}
                </li>
              ))}
            </ul>
          </div>
          <div className="callout__text-block">
            <TrialActivation text="You can also start trial and create your own project. No credit card required." />
          </div>
        </Warning>
      </div>
    </div>
  );
};

SuspendedSubscriptionsWarning.displayName = 'SuspendedSubscriptionsWarning';

type RenewSubscriptionMessageLinkProps = SubscriptionWarningLinkData & {
  readonly onContactSupport: () => void;
};

const RenewSubscriptionMessageLink = ({
  isPrepaid,
  onContactSupport,
  statusReason,
  subscriptionId,
}: RenewSubscriptionMessageLinkProps): JSX.Element => {
  if (isSubscriptionSuspendedDueToMissingToS(statusReason)) {
    return (
      <Link to={buildPath<EnsuredSubscriptionRouteParams>(SubscriptionRoute, { subscriptionId })}>
        Accept the Terms of Service
      </Link>
    );
  }

  return isPrepaid || !isSubscriptionSuspendedAutomatically(statusReason) ? (
    <LinkLike onClick={onContactSupport}>Contact support</LinkLike>
  ) : (
    <Link
      to={buildPath<EnsuredSubscriptionRouteParams>(BillingInformationRoute, { subscriptionId })}
    >
      Enter a payment method
    </Link>
  );
};

const getWarningTexts = (subscriptionCount: number) =>
  subscriptionCount > 1
    ? {
        title: 'Suspended subscriptions',
        explanation:
          'Some of your subscriptions have been suspended. This means you cannot make any changes to the projects under those subscriptions.',
      }
    : {
        title: 'Suspended subscription',
        explanation:
          'One of your subscriptions has been suspended. This means you cannot make any changes to the projects under that subscription.',
      };
