import { Action } from '../../../../../../../@types/Action.type.ts';
import {
  ModalContentItemSelector_Closed,
  ModalMultipleContentItemsSelector_Opened,
} from '../../../../../../features/ModalContentItemSelector/constants/modalContentItemSelectorActionTypes.ts';
import { ContentItemEditing_Archiving_Finished } from '../../../../../../itemEditor/features/ContentItemEditing/constants/contentItemEditingActionTypes.ts';
import {
  ContentListing_CancelScheduledPublish_Failed,
  ContentListing_CancelScheduledPublish_Finished,
  ContentListing_CancelScheduledPublish_Started,
  ContentListing_DeleteItems_Finished,
  ContentListing_DeleteItems_Started,
  ContentListing_Filter_Changed,
  ContentListing_Init_Started,
  ContentListing_Items_Selected,
  ContentListing_Items_Unselected,
  ContentListing_MoveToArchivedStep_Started,
  ContentListing_MoveToCollection_Failed,
  ContentListing_MoveToCollection_Finished,
  ContentListing_MoveToCollection_Started,
  ContentListing_PublishItems_Failed,
  ContentListing_PublishItems_Finished,
  ContentListing_PublishItems_Started,
  ContentListing_RestoreFromArchivedStep_Started,
  ContentListing_ScheduledPublishItems_Failed,
  ContentListing_ScheduledPublishItems_Finished,
  ContentListing_ScheduledPublishItems_Started,
  ContentListing_ScheduledUnpublishItems_Failed,
  ContentListing_ScheduledUnpublishItems_Finished,
  ContentListing_ScheduledUnpublishItems_Started,
  ContentListing_SelectAll_Off,
  ContentListing_SelectAll_On,
  ContentListing_StatusInfo_Reset,
  ContentListing_UndoArchiveItems_Finished,
  ContentListing_UndoArchiveItems_Started,
  ContentListing_UndoPublishItems_Finished,
  ContentListing_UndoPublishItems_Started,
  ContentListing_UndoScheduledPublish_Finished,
  ContentListing_UndoScheduledPublish_Started,
  ContentListing_UnpublishItems_Failed,
  ContentListing_UnpublishItems_Finished,
  ContentListing_UnpublishItems_Started,
  ContentListing_UpdateStandardStep_Started,
  ContentListing_UpdateWorkflowStep_Failed,
  ContentListing_UpdateWorkflowStep_Finished,
} from '../../../../features/ContentItemInventory/constants/contentItemInventoryActionTypes.ts';
import { StatusMessageAction } from '../../../../stores/IContentAppStoreState.ts';

export interface ILastAction {
  readonly lastActionType: StatusMessageAction;
  readonly operationId: Uuid | null;
}

const initialState: ILastAction = {
  lastActionType: StatusMessageAction.None,
  operationId: null,
};

export const lastAction = (state: ILastAction = initialState, action: Action): ILastAction => {
  switch (action.type) {
    case ContentListing_Init_Started:
    case ContentListing_Filter_Changed:
    case ContentListing_StatusInfo_Reset:
    case ContentListing_MoveToCollection_Failed:
    case ContentListing_UnpublishItems_Failed:
    case ContentListing_ScheduledPublishItems_Failed:
    case ContentListing_ScheduledUnpublishItems_Failed:
    case ContentListing_UpdateWorkflowStep_Failed:
    case ContentListing_PublishItems_Failed:
    case ContentListing_CancelScheduledPublish_Failed:
    case ContentListing_SelectAll_On:
    case ContentListing_SelectAll_Off:
    case ContentListing_Items_Selected:
    case ContentListing_Items_Unselected:
    case ModalContentItemSelector_Closed:
    case ModalMultipleContentItemsSelector_Opened:
      return initialState;

    case ContentListing_MoveToCollection_Started:
      return {
        lastActionType: StatusMessageAction.Moving,
        operationId: action.payload.operationId,
      };
    case ContentListing_MoveToCollection_Finished:
      return {
        lastActionType: StatusMessageAction.Moved,
        operationId: null,
      };

    case ContentListing_PublishItems_Started:
      return {
        lastActionType: StatusMessageAction.Publishing,
        operationId: action.payload.operationId,
      };
    case ContentListing_PublishItems_Finished:
      return {
        lastActionType: StatusMessageAction.Published,
        operationId: null,
      };

    case ContentListing_ScheduledPublishItems_Started:
      return {
        lastActionType: StatusMessageAction.SchedulingToPublish,
        operationId: action.payload.operationId,
      };
    case ContentListing_ScheduledPublishItems_Finished:
      return {
        lastActionType: StatusMessageAction.ScheduledToPublish,
        operationId: null,
      };

    case ContentListing_ScheduledUnpublishItems_Started:
      return {
        lastActionType: StatusMessageAction.SchedulingToUnpublish,
        operationId: action.payload.operationId,
      };
    case ContentListing_ScheduledUnpublishItems_Finished:
      return {
        lastActionType: StatusMessageAction.ScheduledToUnpublish,
        operationId: null,
      };

    case ContentListing_UnpublishItems_Started:
      return {
        lastActionType: StatusMessageAction.Unpublishing,
        operationId: action.payload.operationId,
      };
    case ContentListing_UnpublishItems_Finished:
      return {
        lastActionType: StatusMessageAction.Unpublished,
        operationId: null,
      };

    case ContentListing_CancelScheduledPublish_Started:
      return {
        lastActionType: StatusMessageAction.Cancelling,
        operationId: action.payload.operationId,
      };
    case ContentListing_CancelScheduledPublish_Finished:
      return {
        lastActionType: StatusMessageAction.Cancelled,
        operationId: null,
      };

    case ContentListing_UpdateStandardStep_Started:
    case ContentListing_RestoreFromArchivedStep_Started:
    case ContentListing_MoveToArchivedStep_Started:
      return {
        lastActionType: StatusMessageAction.Updating,
        operationId: action.payload.operationId,
      };
    case ContentListing_UpdateWorkflowStep_Finished:
      return {
        lastActionType: StatusMessageAction.Updated,
        operationId: null,
      };

    case ContentListing_DeleteItems_Started:
      return {
        lastActionType: StatusMessageAction.Archiving,
        operationId: action.payload.operationId,
      };
    case ContentItemEditing_Archiving_Finished:
    case ContentListing_DeleteItems_Finished:
      return {
        lastActionType: StatusMessageAction.Archived,
        operationId: null,
      };

    case ContentListing_UndoArchiveItems_Started:
    case ContentListing_UndoPublishItems_Started:
    case ContentListing_UndoScheduledPublish_Started:
      return {
        lastActionType: StatusMessageAction.Undoing,
        operationId: action.payload.operationId,
      };

    case ContentListing_UndoScheduledPublish_Finished:
      return {
        lastActionType: StatusMessageAction.SchedulingToPublishUndone,
        operationId: null,
      };

    case ContentListing_UndoPublishItems_Finished:
      return {
        lastActionType: StatusMessageAction.PublishingUndone,
        operationId: null,
      };
    case ContentListing_UndoArchiveItems_Finished:
      return {
        lastActionType: StatusMessageAction.Restored,
        operationId: null,
      };

    default:
      return state;
  }
};
