import { memoize } from '@kontent-ai/memoization';
import { ContentState } from 'draft-js';
import { TrackedEvent, TrackedStatistics } from '../../../../../_shared/constants/trackedEvent.ts';
import { ContentItemId } from '../../../../../_shared/models/ContentItemId.ts';
import { EditorStatisticsEventData } from '../../../../../_shared/models/events/ContentItemEventData.type.ts';
import {
  IEventTrackingListener,
  eventTrackingService,
} from '../../../../../_shared/services/eventTrackingService.ts';
import { DebouncedFunction, debounce } from '../../../../../_shared/utils/func/debounce.ts';
import { getCharacterCount } from '../../textApi/api/editorTextUtils.ts';

type ICharactersWritten = (count: number) => void;

type IContentPasted = (content: ContentState) => void;

export interface IEditorStatistics {
  readonly charactersWritten: ICharactersWritten;
  readonly contentPasted: IContentPasted;
}

export interface IEditorCounters {
  readonly pastedChars: number;
  readonly typedChars: number;
}

// Interval to save counters to local storage to prevent losing data (1 second of inactivity)
const SAVE_INTERVAL = 1000;

// Interval to submit counters to Intercom to reduce the number of total events (1 minute of inactivity)
// Counters also submit when application initializes and there are existing values from previous run
const SUBMIT_INTERVAL = 60 * 1000;

class EditorStatistics implements IEditorStatistics {
  private counters!: IEditorCounters;
  private readonly name: string;
  private readonly projectId: Uuid;
  private readonly contentItemId: ContentItemId;
  private readonly storageKey: string;
  private readonly trackUserEvent: IEventTrackingListener;

  private readonly debouncedSaveCounters: DebouncedFunction;
  private readonly debouncedSubmitCounters: DebouncedFunction;

  constructor(
    name: string,
    projectId: Uuid,
    contentItemId: ContentItemId,
    deps: {
      trackUserEvent: IEventTrackingListener;
    },
  ) {
    this.name = name;
    this.projectId = projectId;
    this.contentItemId = contentItemId;
    this.trackUserEvent = deps.trackUserEvent;
    this.storageKey = `editorStatistics_${name}_${projectId}`;

    const previousNotSubmitted = localStorage.getItem(this.storageKey);
    if (previousNotSubmitted) {
      this.setCounters(() => JSON.parse(previousNotSubmitted) as IEditorCounters, false);
    } else {
      this.setCounters(
        () => ({
          typedChars: 0,
          pastedChars: 0,
          internalPastedChars: 0,
        }),
        false,
      );
    }

    this.debouncedSaveCounters = debounce(this.saveCounters, SAVE_INTERVAL);
    this.debouncedSubmitCounters = debounce(this.submitCounters, SUBMIT_INTERVAL);

    if (previousNotSubmitted) {
      this.submitCounters();
    }
  }

  private readonly setCounters = (
    updater: (prevState: IEditorCounters) => Partial<IEditorCounters>,
    notify: boolean = true,
  ): void => {
    this.counters = {
      ...this.counters,
      ...updater(this.counters),
    };

    if (notify) {
      this.countersUpdated();
    }
  };

  private readonly clearCounters = (): void => {
    this.setCounters(
      () => ({
        internalPastedChars: 0,
        pastedChars: 0,
        typedChars: 0,
      }),
      false,
    );
  };

  private readonly saveCounters = (): void => {
    const counters = this.counters;
    if (counters.typedChars || counters.pastedChars) {
      localStorage.setItem(this.storageKey, JSON.stringify(counters));
    }
  };

  private readonly submitCounters = (): void => {
    const { name, counters, projectId, contentItemId } = this;

    if (counters.typedChars || counters.pastedChars) {
      const eventData: EditorStatisticsEventData = {
        name,
        project: projectId,
        // We are limited with 5 metadata fields per intercom event
        // for the sake of future extensibility the counters are serialized to JSON which we can parse at SQL level of DW
        counters: JSON.stringify(counters),
        ...contentItemId,
      };
      this.trackUserEvent(TrackedEvent.Statistics, eventData);
      this.clearCounters();
      this.debouncedSubmitCounters().now();
    }
  };

  private readonly countersUpdated = (): void => {
    this.debouncedSubmitCounters();
    this.debouncedSaveCounters();
  };

  charactersWritten: ICharactersWritten = (count: number): void => {
    this.setCounters((prevState) => ({
      typedChars: prevState.typedChars + count,
    }));
  };

  contentPasted: IContentPasted = (content: ContentState): void => {
    const count = getCharacterCount(content);
    if (count > 0) {
      this.setCounters((prevState) => ({
        pastedChars: prevState.pastedChars + count,
      }));
    }
  };
}

const createRichTextElementStatistics = (
  projectId: Uuid,
  itemVariantId: ContentItemId,
): IEditorStatistics =>
  new EditorStatistics(TrackedStatistics.RichTextElement, projectId, itemVariantId, {
    trackUserEvent: eventTrackingService.trackUserEvent,
  });

export const getRichTextElementStatistics = memoize.maxNWithTransformedArgs(
  (projectId: Uuid, itemVariantId: ContentItemId): IEditorStatistics =>
    createRichTextElementStatistics(projectId, itemVariantId),
  (args) => [args[0]],
  1,
);
