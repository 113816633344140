import { Dispatch, ThunkPromise } from '../../../../@types/Dispatcher.type.ts';
import { ICurrentUserInfo } from '../../../../data/models/user/CurrentUserInfo.ts';
import { ISubscriptionServerModel } from '../../../../repositories/serverModels/SubscriptionServerModel.type.ts';
import { INewSubscriptionFormShape } from '../../../subscriptionManagement/SubscriptionListing/components/NewSubscriptionForm.tsx';
import {
  Subscription_ActivateTrial_Failed,
  Subscription_ActivateTrial_Finished,
  Subscription_ActivateTrial_Started,
} from '../../constants/projectActionTypes.ts';

interface IDeps {
  readonly subscriptionRepository: {
    readonly activateTrial: (
      subscriptionName: string,
      acceptTerms: boolean,
      isTrialExtended: boolean,
    ) => Promise<ISubscriptionServerModel>;
  };
  readonly loadUserProjectsInfo: () => ThunkPromise;
  readonly loadAdministratedSubscriptions: () => ThunkPromise;
  readonly loadUserInfo: () => ThunkPromise<ICurrentUserInfo>;
}

const start = () =>
  ({
    type: Subscription_ActivateTrial_Started,
  }) as const;

const finish = () =>
  ({
    type: Subscription_ActivateTrial_Finished,
  }) as const;

const fail = () =>
  ({
    type: Subscription_ActivateTrial_Failed,
  }) as const;

export type ActivateTrialSubscriptionActionsType = ReturnType<
  typeof start | typeof finish | typeof fail
>;

export const activateTrialSubscriptionCreator =
  (deps: IDeps) =>
  (values: INewSubscriptionFormShape): ThunkPromise =>
  async (dispatch: Dispatch): Promise<void> => {
    dispatch(start());
    try {
      await deps.subscriptionRepository.activateTrial(
        values.subscriptionName,
        values.acceptTerms,
        false,
      );

      await Promise.all([
        dispatch(deps.loadUserProjectsInfo()),
        dispatch(deps.loadAdministratedSubscriptions()),
        dispatch(deps.loadUserInfo()),
      ]);

      dispatch(finish());
    } catch (error) {
      dispatch(fail());
      throw new Error(`Could not activate a trial subscription: ${error}`);
    }
  };
