import { Warning } from '../../../../../_shared/components/infos/Warning.tsx';
import { LinkLike } from '../../../../../_shared/uiComponents/LinkLike/LinkLike.tsx';

type Props = Readonly<{
  onContactSupport: (() => void) | null;
  renewalAction: JSX.Element;
  subscriptionName: string;
}>;

export const SuspendedSubscriptionWarning = ({
  onContactSupport,
  renewalAction,
  subscriptionName,
}: Props) => {
  return (
    <div className="user-listing__suspended-subscription-warning">
      <Warning hideTitle subtitle={`The subscription “${subscriptionName}” has been suspended.`}>
        <p>
          This means you can’t currently make any changes to the structure of projects created under
          this subscription.
          <br />
          To continue working on your projects, {renewalAction} to renew the subscription.
          {onContactSupport && (
            <>
              <br />
              If you have any questions,{' '}
              <LinkLike onClick={onContactSupport}>contact our support</LinkLike>.
            </>
          )}
        </p>
      </Warning>
    </div>
  );
};
