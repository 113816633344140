import { ContentItemEditingEventOrigins } from '../../../_shared/models/events/ContentItemEditingEventData.type.ts';
import { IStore } from '../../../_shared/stores/IStore.type.ts';
import {
  ContentItemEditingModalProperties,
  ItemEditingModalProperties,
} from '../types/ItemEditingModalDialogProperties.type.ts';

const isModalWithActionOrigin = (
  modalProperties: ItemEditingModalProperties | null,
): modalProperties is ContentItemEditingModalProperties =>
  !!modalProperties && !!(modalProperties as ContentItemEditingModalProperties).actionOrigin;

export const getModalDialogActionOrigin = ({
  contentApp: {
    editorUi: { itemEditingModalDialog },
  },
}: IStore): ContentItemEditingEventOrigins =>
  isModalWithActionOrigin(itemEditingModalDialog.properties)
    ? itemEditingModalDialog.properties.actionOrigin
    : ContentItemEditingEventOrigins.Undefined;
