import React from 'react';
import { trackUserEvent } from '../../../../../../_shared/actions/thunks/trackUserEvent.ts';
import { TrackedEvent } from '../../../../../../_shared/constants/trackedEvent.ts';
import { useDispatch } from '../../../../../../_shared/hooks/useDispatch.ts';
import { useSelector } from '../../../../../../_shared/hooks/useSelector.ts';
import { ActiveCapabilityType } from '../../../../../../_shared/models/activeCapability.type.ts';
import {
  ContentItemEditingChangeAction,
  ContentItemEditingEventOrigins,
} from '../../../../../../_shared/models/events/ContentItemEditingEventData.type.ts';
import { IStore } from '../../../../../../_shared/stores/IStore.type.ts';
import { hasActiveVariantCapabilityForEditedItem } from '../../../../../../_shared/utils/permissions/activeCapabilities.ts';
import { IWorkflowStep } from '../../../../../../data/models/workflow/WorkflowStep.ts';
import { LastCascadeAction } from '../../../../../contentInventory/content/stores/IContentAppStoreState.ts';
import { itemEditingModalOpened } from '../../../../actions/contentActions.ts';
import { ItemEditingModalDialogType } from '../../../../constants/itemEditingModalDialogType.ts';
import { undoCascadeAction } from '../../../CascadePublish/actions/thunkCascadeModalActions.ts';
import { openUnpublishAndArchiveModal } from '../../../ChangeWorkflowStep/actions/thunks/changeWorkflowStepModalActions.ts';
import {
  cancelContentItemVariantsScheduledPublish,
  createNewVersion,
} from '../../actions/thunkContentItemEditingActions.ts';
import { PublishingNotificationBar as NotificationBarComponent } from '../../components/contentItemEditorNotifications/publishingNotification/PublishingNotificationBar.tsx';
import { getPublishingPrivileges } from '../../utils/getPublishingPrivileges.ts';

const selectPublishingPrivileges =
  (workflowStatus: IWorkflowStep | undefined, lastAction: LastCascadeAction) => (state: IStore) => {
    if (!workflowStatus) {
      return null;
    }

    const canCancelScheduling = hasActiveVariantCapabilityForEditedItem(
      ActiveCapabilityType.CancelSchedule,
      state,
    );
    const canCreateNewVersion = hasActiveVariantCapabilityForEditedItem(
      ActiveCapabilityType.CreateNewVersion,
      state,
    );
    const canUnpublish = hasActiveVariantCapabilityForEditedItem(
      ActiveCapabilityType.Unpublish,
      state,
    );

    return getPublishingPrivileges(
      {
        canCancelScheduling,
        canCreateNewVersion,
        canUnpublish,
      },
      workflowStatus,
      lastAction,
    );
  };

export const PublishingNotificationBar: React.FC = () => {
  const assignment = useSelector((state) => state.contentApp.editedContentItemVariant?.assignment);
  const lastAction = useSelector(
    (state) => state.contentApp.editorUi.cascadePublish.cascadeResult.lastAction,
  );
  const lastPublishedAt = useSelector(
    (state) => state.contentApp.editedContentItemVariant?.lastPublishedAt ?? null,
  );
  const numberOfProcessedItems = useSelector(
    (state) => state.contentApp.editorUi.cascadePublish.cascadeResult.modifiedItemIds.size,
  );
  const publishingPrivilege = useSelector(
    selectPublishingPrivileges(assignment?.workflowStatus, lastAction),
  );

  const dispatch = useDispatch();

  if (!assignment || !publishingPrivilege) {
    return null;
  }

  return (
    <NotificationBarComponent
      lastAction={lastAction}
      numberOfProcessedItems={numberOfProcessedItems}
      onCancelScheduling={() =>
        dispatch(
          cancelContentItemVariantsScheduledPublish(ContentItemEditingEventOrigins.NotificationBar),
        )
      }
      onCascadeCancelScheduling={() => dispatch(undoCascadeAction())}
      onCascadeUnpublish={() =>
        dispatch(
          itemEditingModalOpened(ItemEditingModalDialogType.CascadeUnpublishConfirmationDialog),
        )
      }
      onCreateNewVersion={async () => {
        await dispatch(
          createNewVersion(() => dispatch(trackUserEvent(TrackedEvent.ContentEntryNewVersion))),
        );
        dispatch(
          trackUserEvent(TrackedEvent.ContentItemEditing, {
            action: ContentItemEditingChangeAction.CreateANewVersion,
            origin: ContentItemEditingEventOrigins.NotificationBar,
          }),
        );
      }}
      onUnpublish={() =>
        dispatch(openUnpublishAndArchiveModal(ContentItemEditingEventOrigins.NotificationBar))
      }
      publishingPrivilege={publishingPrivilege}
      publishedAt={lastPublishedAt}
      scheduledToPublishAt={assignment.scheduledToPublishAt}
      scheduledToUnpublishAt={assignment.scheduledToUnpublishAt}
      workflowStatus={assignment.workflowStatus}
    />
  );
};
