import { IStore } from '../../../_shared/stores/IStore.type.ts';
import {
  ItemEditingModalProperties,
  ItemEditingModalTypesWithRequiredProperties,
} from '../types/ItemEditingModalDialogProperties.type.ts';

const areModalPropertiesForCorrectDialog = <
  TDialogType extends keyof ItemEditingModalTypesWithRequiredProperties,
  TModalProperties extends ItemEditingModalTypesWithRequiredProperties[TDialogType],
>(
  modalProperties: ItemEditingModalProperties,
  dialogType: TDialogType,
): modalProperties is TModalProperties => modalProperties.dialogType === dialogType;

export const getItemEditingModalDialogProperties =
  <
    TDialogType extends keyof ItemEditingModalTypesWithRequiredProperties,
    TModalProperties extends ItemEditingModalTypesWithRequiredProperties[TDialogType],
  >(
    dialogType: TDialogType,
  ) =>
  (state: IStore): TModalProperties | null => {
    const { properties } = state.contentApp.editorUi.itemEditingModalDialog;

    return properties &&
      areModalPropertiesForCorrectDialog<TDialogType, TModalProperties>(properties, dialogType)
      ? properties
      : null;
  };
