import { InvariantException } from '@kontent-ai/errors';
import { Dispatch, GetState, ThunkPromise } from '../../../../../../@types/Dispatcher.type.ts';
import { trackUserEvent } from '../../../../../../_shared/actions/thunks/trackUserEvent.ts';
import { TrackedEvent } from '../../../../../../_shared/constants/trackedEvent.ts';
import {
  ContentItemEditingChangeAction,
  ContentItemEditingEventOrigins,
} from '../../../../../../_shared/models/events/ContentItemEditingEventData.type.ts';
import { logError } from '../../../../../../_shared/utils/logError.ts';
import { IContentItemWithVariantServerModel } from '../../../../../../repositories/serverModels/INewContentItemServerModel.ts';
import { CancelItemScheduledPublishErrorMessage } from '../../constants/uiConstants.ts';
import { IParsedItemVariant } from '../../utils/parseContentItem.ts';
import { prepareWorkflowStepTrackingData } from '../../utils/prepareWorkflowStepTrackingData.ts';
import {
  cancelScheduledPublishingFailed,
  cancelScheduledPublishingFinished,
  cancelScheduledPublishingStarted,
  contentItemEditingSidebarSectionCleanedUp,
} from '../contentItemEditingActions.ts';
import { ILoadRelatedContentItemElementsDataAction } from './loadRelatedContentItemElementsData.ts';

interface IDeps {
  readonly contentItemRepository: {
    readonly cancelVariantScheduledPublish: (
      contentItemId: Uuid,
      variantId: Uuid,
    ) => Promise<IContentItemWithVariantServerModel>;
  };
  readonly parseContentItemVariant: (
    contentItemWithVariant: IContentItemWithVariantServerModel,
  ) => IParsedItemVariant;
  readonly loadRelatedContentItemElementsData: ILoadRelatedContentItemElementsDataAction;
}

export const cancelContentItemVariantScheduledPublishCreator =
  (deps: IDeps) =>
  (origin: ContentItemEditingEventOrigins): ThunkPromise =>
  async (dispatch: Dispatch, getState: GetState): Promise<void> => {
    const {
      contentApp: {
        editedContentItemVariant,
        listingUi: { filter, orderBy },
      },
      data: {
        listingContentItems: { usedSearchMethod },
      },
    } = getState();

    if (!editedContentItemVariant) {
      throw InvariantException(`${__filename}: editedContentItemVariant is falsy`);
    }
    const { itemId, variantId } = editedContentItemVariant.id;

    dispatch(cancelScheduledPublishingStarted());
    try {
      const itemWithVariant = await deps.contentItemRepository.cancelVariantScheduledPublish(
        itemId,
        variantId,
      );
      const variantData = deps.parseContentItemVariant(itemWithVariant);
      await dispatch(
        deps.loadRelatedContentItemElementsData(
          itemId,
          variantId,
          variantData.editedContentItemVariantElements,
          null,
        ),
      );
      const compiledVariant = {
        ...variantData,
        editedContentItemVariantElements: variantData.editedContentItemVariantElements,
      };
      dispatch(
        cancelScheduledPublishingFinished({
          itemWithVariant,
          itemVariantData: compiledVariant,
          filter,
          orderBy,
          usedSearchMethod,
        }),
      );
      dispatch(
        trackUserEvent(
          TrackedEvent.ContentItemEditing,
          prepareWorkflowStepTrackingData(compiledVariant, {
            action: ContentItemEditingChangeAction.CancelScheduledPublish,
            origin,
          }),
        ),
      );
      dispatch(contentItemEditingSidebarSectionCleanedUp());
    } catch (error) {
      logError(
        `${__filename}: Error during cancelling of scheduled publish of an item variant`,
        error,
      );
      dispatch(cancelScheduledPublishingFailed(CancelItemScheduledPublishErrorMessage));
    }
  };
