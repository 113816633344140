import { trackUserEvent } from '../../../../_shared/actions/thunks/trackUserEvent.ts';
import { repositoryCollection } from '../../../../_shared/repositories/repositories.ts';
import { loadTaxonomyGroups, loadUsers } from '../../../../data/actions/thunkDataActions.ts';
import { createArchiveTaxonomyGroupsAction } from './thunks/archiveTaxonomyGroups.ts';
import { createCreateNewTaxonomyGroupAction } from './thunks/createNewTaxonomyGroup.ts';
import { createInitTaxonomyGroupEditAction } from './thunks/initTaxonomyGroupEdit.ts';
import { createLoadTaxonomyGroupsAction } from './thunks/loadTaxonomyGroups.ts';
import { createMoveTaxonomyTermAction } from './thunks/moveTaxonomyTerm.ts';
import { createTaxonomyTermPickedUpAction } from './thunks/pickUpTaxonomyTerm.ts';
import { createRestoreArchivedTaxonomyGroupsAction } from './thunks/restoreArchivedTaxonomyGroups.ts';
import { createUpdateEditedTaxonomyGroupAction } from './thunks/saveEditedTaxonomyGroup.ts';
import { createSelectTaxonomyGroupsAction } from './thunks/selectTaxonomyGroups.ts';
import { createUnselectTaxonomyGroupsAction } from './thunks/unselectTaxonomyGroups.ts';

const { contentItemRepository, taxonomyRepository } = repositoryCollection;

export const initTaxonomyGroupsListing = createLoadTaxonomyGroupsAction({
  taxonomyRepository,
  loadUsers,
});

export const initTaxonomyGroupEdit = createInitTaxonomyGroupEditAction({
  taxonomyRepository,
  contentItemRepository,
});

export const createNewTaxonomyGroup = createCreateNewTaxonomyGroupAction({
  taxonomyRepository,
  trackUserEvent,
  loadTaxonomyGroups,
});

export const updateEditedTaxonomyGroup = createUpdateEditedTaxonomyGroupAction({
  taxonomyRepository,
  loadTaxonomyGroups,
});

export const selectTaxonomyGroups = createSelectTaxonomyGroupsAction();

export const unselectTaxonomyGroups = createUnselectTaxonomyGroupsAction();

export const restoreArchivedTaxonomyGroups = createRestoreArchivedTaxonomyGroupsAction({
  taxonomyRepository,
  loadTaxonomyGroups,
});

export const archiveTaxonomyGroups = createArchiveTaxonomyGroupsAction({
  taxonomyRepository,
  trackUserEvent,
  loadTaxonomyGroups,
});

export const moveTaxonomyTerm = createMoveTaxonomyTermAction();

export const pickUpTaxonomyTerm = createTaxonomyTermPickedUpAction();
