import { createGuid } from '@kontent-ai/utils';
import { trackUserEvent } from '../../../../_shared/actions/thunks/trackUserEvent.ts';
import { ContentItemFilterOrigin } from '../../../../_shared/models/events/ContentItemFilterEventData.type.ts';
import { repositoryCollection } from '../../../../_shared/repositories/repositories.ts';
import { initializeRequestTokenFactory } from '../../../../_shared/utils/requestTokenUtils.ts';
import { calendarArrangementStorage } from '../../../../localStorages/calendarArrangementStorage.ts';
import { getFilterStorage } from '../../../../localStorages/getFilterStorage.ts';
import { createTrackFilterChangeAction } from '../../../contentInventory/content/features/ContentItemInventory/actions/thunks/loadListingItems/trackFilterChange.ts';
import { createContentItemFilterServerModelFromFilter } from '../../../contentInventory/content/utils/contentItemListModelConverters.ts';
import {
  calendarArrangementSelected,
  createTokenizedLoadItemsWithVariantActiveInPeriodStarted,
} from './calendarActions.ts';
import { createSaveCalendarArrangement } from './thunkActions/createSaveCalendarArrangement.ts';
import { createFilterCalendarAction } from './thunkActions/filterCalendar.ts';
import { createInitializeCalendarAction } from './thunkActions/initializeCalendar.ts';
import { createLeaveCalendarAction } from './thunkActions/leaveCalendar.ts';
import { createLoadItemsMatchingFilterWithVariantActiveInPeriod } from './thunkActions/loadItemsMatchingFilterWithVariantActiveInPeriod.ts';

const { contentItemRepository } = repositoryCollection;
const calendarCancellationTokenFactory = initializeRequestTokenFactory(
  (state) => state.calendarApp.cancellationToken,
  createTokenizedLoadItemsWithVariantActiveInPeriodStarted,
  createGuid,
);

export const loadItemsMatchingFilterWithVariantActiveInPeriod =
  createLoadItemsMatchingFilterWithVariantActiveInPeriod({
    contentItemRepository,
    createContentItemFilterServerModelFromFilter,
    requestTokenFactory: calendarCancellationTokenFactory,
  });

export const filterCalendar = createFilterCalendarAction({
  ContentItemFilterStorage: getFilterStorage(ContentItemFilterOrigin.Calendar),
  loadItemsMatchingFilterWithVariantActiveInPeriod,
  trackFilterChange: createTrackFilterChangeAction(ContentItemFilterOrigin.Calendar),
});

export const initializeCalendar = createInitializeCalendarAction({
  loadItemsMatchingFilterWithVariantActiveInPeriod,
  trackUserEvent,
});

export const leaveCalendar = createLeaveCalendarAction({ trackUserEvent });

export const saveCalendarArrangement = createSaveCalendarArrangement({
  trackUserEvent,
  onArrangementSelected: calendarArrangementSelected,
  calendarArrangementStorage,
});
