import { isAbortError } from '@kontent-ai/errors';
import { assert } from '@kontent-ai/utils';
import { ThunkPromise } from '../../../../../../@types/Dispatcher.type.ts';
import { trackUserEvent } from '../../../../../../_shared/actions/thunks/trackUserEvent.ts';
import { TrackedEvent } from '../../../../../../_shared/constants/trackedEvent.ts';
import {
  ContentItemEditingChangeAction,
  ContentItemEditingEventOrigins,
} from '../../../../../../_shared/models/events/ContentItemEditingEventData.type.ts';
import { getSelectedLanguageIdOrThrow } from '../../../../../../_shared/selectors/getSelectedLanguageId.ts';
import { IContentItemRepository } from '../../../../../../repositories/interfaces/IContentItemRepository.type.ts';
import {
  IContentItemWithVariantsServerModel,
  IDuplicateContentItemServerModel,
} from '../../../../../../repositories/serverModels/INewContentItemServerModel.ts';
import {
  contentItemDuplicatingFailed,
  contentItemDuplicatingFinished,
  contentItemDuplicatingStarted,
} from '../contentItemEditingActions.ts';

interface IDeps {
  readonly contentItemRepository: IContentItemRepository;
}

export const createDuplicateEditedContentItemAction =
  (deps: IDeps) =>
  (
    destinationCollectionId?: Uuid,
    abortSignal?: AbortSignal,
  ): ThunkPromise<IContentItemWithVariantsServerModel> =>
  async (dispatch, getState) => {
    const {
      contentApp: { editedContentItem, listingUi },
      data: {
        listingContentItems: { usedSearchMethod },
      },
    } = getState();

    assert(
      !!editedContentItem,
      () => 'Cannot dispatch duplicateEditedContentItem when no edited item is set.',
    );

    const selectedLanguageId = getSelectedLanguageIdOrThrow(getState());
    const itemId = editedContentItem.id;
    const duplicateItemModel: IDuplicateContentItemServerModel = {
      destinationCollectionId: destinationCollectionId ?? editedContentItem.collectionId,
    };

    dispatch(contentItemDuplicatingStarted());
    try {
      const duplicatedItem = await deps.contentItemRepository.duplicateItem(
        itemId,
        duplicateItemModel,
        abortSignal,
      );
      const duplicatedItemWithVariant = await deps.contentItemRepository.getItemWithVariant(
        duplicatedItem.item.id,
        selectedLanguageId,
        abortSignal,
      );

      dispatch(
        contentItemDuplicatingFinished({
          itemWithVariant: duplicatedItemWithVariant,
          filter: listingUi.filter,
          usedSearchMethod,
        }),
      );

      dispatch(
        trackUserEvent(TrackedEvent.ContentItemEditing, {
          action: ContentItemEditingChangeAction.Duplicate,
          origin: ContentItemEditingEventOrigins.MoreActions,
        }),
      );

      return duplicatedItem;
    } catch (error) {
      if (!isAbortError(error)) {
        dispatch(contentItemDuplicatingFailed());
      }

      throw error;
    }
  };
