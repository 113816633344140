import { Action } from '../../../../../../@types/Action.type.ts';
import { Dispatch } from '../../../../../../@types/Dispatcher.type.ts';
import { trackUserEvent } from '../../../../../../_shared/actions/thunks/trackUserEvent.ts';
import { TrackedEvent } from '../../../../../../_shared/constants/trackedEvent.ts';
import {
  ContentItemEditingBasicActions,
  ContentItemEditingEventOrigins,
} from '../../../../../../_shared/models/events/ContentItemEditingEventData.type.ts';

export const trackContentItemEditingAction =
  (action: Action, actionType: ContentItemEditingBasicActions) =>
  (actionOrigin: ContentItemEditingEventOrigins) =>
  (dispatch: Dispatch) => {
    dispatch(action);
    dispatch(
      trackUserEvent(TrackedEvent.ContentItemEditing, {
        action: actionType,
        origin: actionOrigin,
      }),
    );
  };
