import { ThunkFunction } from '../../../../../../../../@types/Dispatcher.type.ts';
import { trackUserEvent } from '../../../../../../../../_shared/actions/thunks/trackUserEvent.ts';
import {
  FindRightContentTrackedEvent,
  TrackedEvent,
} from '../../../../../../../../_shared/constants/trackedEvent.ts';
import { ContentItemFilterOrigin } from '../../../../../../../../_shared/models/events/ContentItemFilterEventData.type.ts';
import { Workflow } from '../../../../../../../../data/models/workflow/Workflow.ts';
import { IListingFilter } from '../../../../../models/filter/IListingFilter.ts';
import {
  getFindRightContentFilterChangeTrackingPayloads,
  getFindRightContentFilterEventData,
} from '../../../utils/getFindRightContentTrackingPayloads.ts';

export type TrackFilterChange = (
  previousFilter: IListingFilter | null,
  newFilter: IListingFilter,
  workflows: ReadonlyArray<Workflow>,
) => ThunkFunction;

export const createTrackFilterChangeAction =
  (origin: ContentItemFilterOrigin): TrackFilterChange =>
  (previousFilter, newFilter, workflows) =>
  (dispatch) => {
    if (previousFilter) {
      Array.from(
        getFindRightContentFilterChangeTrackingPayloads(
          previousFilter,
          newFilter,
          origin,
          workflows,
        ),
      ).forEach((payload) => dispatch(trackUserEvent(TrackedEvent.FindRightContent, payload)));
    } else {
      dispatch(
        trackUserEvent(TrackedEvent.FindRightContent, {
          name: FindRightContentTrackedEvent.FilterLoaded,
          ...getFindRightContentFilterEventData(newFilter, origin, workflows),
        }),
      );
    }
  };
