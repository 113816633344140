import { useEffect } from 'react';
import { useDispatch } from '../../../_shared/hooks/useDispatch.ts';
import { useSelector } from '../../../_shared/hooks/useSelector.ts';
import { itemEditingModalDismissed } from '../actions/contentActions.ts';
import { ItemEditingModalDialogSelector as ItemEditingModalDialogSelectorComponent } from '../components/ItemEditingModalDialogSelector.tsx';

export const ItemEditingModalDialogSelector = () => {
  const dispatch = useDispatch();
  useEffect(function init() {
    dispatch(itemEditingModalDismissed());
  }, []);

  const currentModal = useSelector(
    (state) => state.contentApp.editorUi.itemEditingModalDialog.type,
  );

  return <ItemEditingModalDialogSelectorComponent currentModal={currentModal} />;
};
