import { DataTableRow } from '../../../../_shared/components/DataTable/DataTableRow.tsx';
import { LinkDataTableCell } from '../../../../_shared/components/DataTable/LinkDataTableCell.tsx';
import { Task } from '../../../../_shared/models/Task.ts';
import { IProjectContributor } from '../../../../data/models/users/ProjectContributor.ts';
import { TableCellDueDate } from '../../../contentInventory/content/features/ContentItemInventory/components/TableCellDueDate.tsx';
import { ScrollTableItemContributors } from './ScrollTableItemContributors.tsx';

type Props = Readonly<{
  assignees: ReadonlyArray<IProjectContributor>;
  linkPath: string;
  task: Task;
}>;

export const ContentStatusTasksTableRow: React.FC<Props> = ({ assignees, linkPath, task }) => (
  <DataTableRow dataUiObjectName={task.id} id={task.id}>
    <LinkDataTableCell linkPath={linkPath}>{task.description}</LinkDataTableCell>
    <LinkDataTableCell linkPath={linkPath}>
      <TableCellDueDate dueDate={task.dueDate} />
    </LinkDataTableCell>
    <LinkDataTableCell linkPath={linkPath}>
      <ScrollTableItemContributors contributors={assignees} />
    </LinkDataTableCell>
  </DataTableRow>
);
