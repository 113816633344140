import React, { useCallback } from 'react';
import {
  DataUiCollection,
  getDataUiCollectionAttribute,
} from '../../../../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';
import { ElementType } from '../../../../../../contentInventory/content/models/ContentItemElementType.ts';
import { ILinkedItemsTypeElement } from '../../../../../../contentInventory/content/models/contentTypeElements/LinkedItemsTypeElement.ts';
import { CreateContentGroupTabsId } from '../../../../ContentItemEditing/utils/contentGroupTabsId.ts';
import { ExpandedItemElements } from '../../../../LinkedItems/containers/ExpandedItemElements.tsx';
import { IMergedListItem } from '../../../utils/getListDiff.ts';
import { SimpleLinkedListItem } from './SimpleLinkedListItem.tsx';

interface ISimpleLinkedItemsWithDiffProps {
  readonly getLinkedItemClassName?: (linkedItemsWithDiffMetadata: IMergedListItem<Uuid>) => string;
  readonly linkedItemIds: ReadonlyArray<IMergedListItem<Uuid>>;
  readonly typeElement: ILinkedItemsTypeElement;
}

export const SimpleLinkedItems: React.FC<ISimpleLinkedItemsWithDiffProps> = ({
  getLinkedItemClassName,
  linkedItemIds,
  typeElement,
}) => {
  const renderExpandedLinkedItem = useCallback(
    (contentItemId: string, expandedItemId: string): JSX.Element => {
      const contentGroupTabsId = CreateContentGroupTabsId.forLinkedItem(
        typeElement.elementId,
        '',
        contentItemId,
      );

      return (
        <ExpandedItemElements
          contentGroupTabsId={contentGroupTabsId}
          contentItemId={contentItemId}
          expandedItemId={expandedItemId}
          isItemPage={typeElement.type === ElementType.Subpages}
        />
      );
    },
    [typeElement],
  );

  // If there are many items, we need to optimize their rendering by hiding the ones outside the viewport to reduce React overhead
  // as hundreds/thousands of items produce too many components and DOM nodes to be rendered
  const hideItemsOutsideViewport = linkedItemIds.length > 10;

  return (
    <div
      className="bar-item__list"
      {...getDataUiCollectionAttribute(DataUiCollection.ContentModuleListing)}
    >
      {linkedItemIds.map((item, index) => (
        // Key includes index because within diff there may be two items with same id due to order change (one removed, and one added)
        <SimpleLinkedListItem
          key={`${item.value}_${index}`}
          className={getLinkedItemClassName?.(item)}
          contentItemId={item.value}
          hideOutsideViewport={hideItemsOutsideViewport}
          renderExpanded={renderExpandedLinkedItem}
        />
      ))}
    </div>
  );
};

SimpleLinkedItems.displayName = 'SimpleLinkedItems';
