import { memoize } from '@kontent-ai/memoization';
import { Collection } from '@kontent-ai/utils';
import { forwardRef, useCallback } from 'react';
import { trackUserEvent } from '../../../../../../../_shared/actions/thunks/trackUserEvent.ts';
import { TrackedEvent } from '../../../../../../../_shared/constants/trackedEvent.ts';
import { useDispatch } from '../../../../../../../_shared/hooks/useDispatch.ts';
import { useSelector } from '../../../../../../../_shared/hooks/useSelector.ts';
import { CommentAction } from '../../../../../../../_shared/models/events/CommentEventData.type.ts';
import { getCurrentUserId } from '../../../../../../../_shared/selectors/getCurrentUser.ts';
import { doesEntitySatisfyFilterPhrase } from '../../../../../../../_shared/utils/filter/nameFilterUtils.ts';
import {
  formatUserName,
  makeCurrentUserFirst,
  userLastNameComparer,
} from '../../../../../../../_shared/utils/users/usersUtils.ts';
import { IProjectContributor } from '../../../../../../../data/models/users/ProjectContributor.ts';
import { IUser } from '../../../../../../../data/reducers/user/IUser.type.ts';
import { MentionStartingChar } from '../../../../../../richText/plugins/mentions/api/editorMentionUtils.ts';
import {
  NewMention as NewMentionComponent,
  NewMentionHandle,
  NewMentionProps,
} from '../../../components/comments/input/NewMention.tsx';

const getUsers = memoize.maxOne(
  (usersById: ReadonlyMap<UserId, IProjectContributor>, searchText: string, currentUser: IUser) => {
    const users = Collection.getValues(usersById)
      .filter(
        (user) =>
          !!user &&
          !user.inactive &&
          !user.isVirtual &&
          doesEntitySatisfyFilterPhrase(searchText, user, [formatUserName]),
      )
      .sort(userLastNameComparer);

    return makeCurrentUserFirst(users, currentUser.info.userId);
  },
);

type NewMentionContainerProps = Pick<
  NewMentionProps,
  'children' | 'onUserSelected' | 'onCancel'
> & {
  readonly text: string;
};

export const NewMention = forwardRef<NewMentionHandle, NewMentionContainerProps>(
  ({ children, text, ...ownProps }, ref) => {
    const currentUserId = useSelector(getCurrentUserId);
    const searchText = text.startsWith(MentionStartingChar) ? text.substring(1) : text;
    const sortedUsers = useSelector((state) =>
      getUsers(state.data.users.usersById, searchText, state.data.user),
    );

    const dispatch = useDispatch();
    const onCreateMention = useCallback(
      () =>
        dispatch(
          trackUserEvent(TrackedEvent.Comments, {
            action: CommentAction.MentionCreated,
          }),
        ),
      [],
    );

    return (
      <NewMentionComponent
        {...ownProps}
        currentUserId={currentUserId}
        users={sortedUsers}
        searchText={searchText}
        onCreateMention={onCreateMention}
        ref={ref}
      >
        {children}
      </NewMentionComponent>
    );
  },
);

NewMention.displayName = 'NewMention';
