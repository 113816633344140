import React from 'react';
import { trackUserEvent } from '../../../../../../../../_shared/actions/thunks/trackUserEvent.ts';
import { TrackedEvent } from '../../../../../../../../_shared/constants/trackedEvent.ts';
import { useDispatch } from '../../../../../../../../_shared/hooks/useDispatch.ts';
import { useSelector } from '../../../../../../../../_shared/hooks/useSelector.ts';
import { ContentItemEditingPreviewAction } from '../../../../../../../../_shared/models/events/ContentItemEditingEventData.type.ts';
import { HotjarTag, tagRecording } from '../../../../../../../../_shared/utils/hotjarUtils.ts';
import {
  EditingActionDynamicDetailProvider,
  EditingActionDynamicDetailProviderOwnProps,
} from '../../../../components/editingActions/actions/EditingActionDynamicDetailProvider.tsx';
import { selectFirsLinkInfo } from '../../../selectors/selectPreviewLinks.ts';

export const PreviewItemAction: React.FC<
  React.PropsWithChildren<EditingActionDynamicDetailProviderOwnProps>
> = ({ children, ...props }) => {
  const dispatch = useDispatch();
  const linkInfo = useSelector(selectFirsLinkInfo);

  return (
    <EditingActionDynamicDetailProvider
      {...props}
      outwardUrl={linkInfo?.url}
      performAction={(origin) => {
        dispatch(
          trackUserEvent(TrackedEvent.ContentItemEditing, {
            action: ContentItemEditingPreviewAction.DefaultPreview,
            origin,
          }),
        );

        tagRecording(HotjarTag.PreviewButtonClicked);
      }}
    >
      {children}
    </EditingActionDynamicDetailProvider>
  );
};

PreviewItemAction.displayName = 'PreviewItemAction';
