import { ThunkFunction, ThunkPromise } from '../../../../../@types/Dispatcher.type.ts';
import { onboardingNotificationTriggered } from '../../../../../_shared/actions/thunkSharedActions.ts';
import { TrackedEvent } from '../../../../../_shared/constants/trackedEvent.ts';
import {
  TrackUserEvent,
  TrackUserEventAction,
} from '../../../../../_shared/models/TrackUserEvent.type.ts';
import { ContentTypeCreatedEventData } from '../../../../../_shared/models/TrackUserEventData.ts';
import { Capability } from '../../../../../_shared/utils/permissions/capability.ts';
import { getFirstCollectionAndLanguageForContentTypeAndCurrentUser } from '../../../../../_shared/utils/permissions/getFirstCollectionAndLanguageForContentTypeAndCurrentUser.ts';
import {
  IContentType,
  getContentTypeFromServerModel,
} from '../../../../../data/models/contentModelsApp/contentTypes/ContentType.ts';
import { OnboardingNotification } from '../../../../../data/models/user/OnboardingNotification.ts';
import {
  ContentTypeServerModel,
  CreateNewContentTypeServerModel,
} from '../../../../../repositories/serverModels/contentModels/contentTypeServerModels.ts';
import { ContentTypeElementServerModel } from '../../../../../repositories/serverModels/contentModels/sharedContentTypeModels.type.ts';
import {
  IContentGroup,
  getContentGroupServerModel,
} from '../../../../contentInventory/content/models/contentTypeElements/types/ContentGroup.ts';
import { typeEditorSaveAttempted } from '../../../shared/actions/sharedContentModelsActions.ts';
import { IBaseTypeElementData } from '../../../shared/models/elements/types/TypeElementData.ts';
import { getContentTypeConversionOptions } from '../../../shared/selectors/contentTypeElementSelector.ts';
import { createActiveConditionIds } from '../../../shared/utils/conditionUtils.ts';
import { ITrackAssetLimitConfigurationChanged } from '../../../shared/utils/intercomHelpers/trackAssetLimitsUtils.ts';
import { ITrackCustomTypeElementUpserted } from '../../../shared/utils/intercomHelpers/trackCustomTypeElementConfig.ts';
import { UntitledContentTypeName } from '../../constants/contentTypeConstants.ts';
import { contentTypeCreationFinished, contentTypeCreationStarted } from '../contentTypesActions.ts';

interface IDeps {
  readonly contentTypeRepository: {
    readonly createContentType: (
      contentType: CreateNewContentTypeServerModel,
    ) => Promise<ContentTypeServerModel>;
  };
  readonly convertTypeElementsToServerModel: (
    typeElementRecord: ReadonlyArray<IBaseTypeElementData>,
  ) => Array<ContentTypeElementServerModel>;
  readonly getContentTypeCreatedEventData: (
    typeId: Uuid,
    elements: ReadonlyArray<IBaseTypeElementData>,
  ) => ContentTypeCreatedEventData;
  readonly loadContentTypesData: () => ThunkPromise;
  readonly saveEditedMultipleChoiceOption: () => ThunkFunction;
  readonly trackAssetLimitConfigurationChanged: ITrackAssetLimitConfigurationChanged;
  readonly trackCustomTypeElementUpserted: ITrackCustomTypeElementUpserted;
  readonly trackUserEvent: TrackUserEventAction;
  readonly validateTypeElement: (updatedTypeElement: IBaseTypeElementData) => ThunkFunction;
}

export const createCreateContentTypeAction =
  (deps: IDeps) =>
  (editedType: IContentType): ThunkPromise<IContentType> =>
  async (dispatch, getState) => {
    dispatch(deps.saveEditedMultipleChoiceOption());

    const {
      sharedApp: { currentProjectId },
      data: { user, languages, collections },
    } = getState();

    dispatch(typeEditorSaveAttempted(createActiveConditionIds(editedType)));

    dispatch(contentTypeCreationStarted());

    const name = editedType.name || UntitledContentTypeName;
    const contentGroups = editedType.contentGroups.map((group: IContentGroup) =>
      getContentGroupServerModel(group),
    );

    const contentElements = deps.convertTypeElementsToServerModel(editedType.typeElements);

    const contentTypeForServer: CreateNewContentTypeServerModel = {
      contentElements,
      contentGroups,
      name,
    };
    const createdContentTypeServerModel =
      await deps.contentTypeRepository.createContentType(contentTypeForServer);
    const createdContentType = getContentTypeFromServerModel(
      createdContentTypeServerModel,
      getContentTypeConversionOptions(getState()),
    );
    const { languageId, collectionId } = getFirstCollectionAndLanguageForContentTypeAndCurrentUser(
      user,
      createdContentType.id,
      currentProjectId,
      languages,
      collections,
      Capability.CreateContent,
    );

    const trackEvent: TrackUserEvent = (...args) => dispatch(deps.trackUserEvent(...args));
    deps.trackAssetLimitConfigurationChanged(trackEvent, createdContentType.typeElements);
    deps.trackCustomTypeElementUpserted(trackEvent, createdContentType.typeElements);

    await Promise.all([
      dispatch(deps.loadContentTypesData()),
      dispatch(
        onboardingNotificationTriggered(OnboardingNotification.ContentTypeEditedNotification, {
          editedTypeId: createdContentType.id,
          languageIdToCreateItem: languageId,
          collectionIdToCreateItem: collectionId,
        }),
      ),
    ]);

    dispatch(
      deps.trackUserEvent(
        TrackedEvent.ContentTypeCreated,
        deps.getContentTypeCreatedEventData(createdContentType.id, createdContentType.typeElements),
      ),
    );

    dispatch(contentTypeCreationFinished(createdContentType));

    return createdContentType;
  };
