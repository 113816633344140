import { InvariantException } from '@kontent-ai/errors';
import React from 'react';
import { connect } from 'react-redux';
import { Dispatch } from '../../../../../@types/Dispatcher.type.ts';
import { trackUserEvent } from '../../../../../_shared/actions/thunks/trackUserEvent.ts';
import { TrackedEvent } from '../../../../../_shared/constants/trackedEvent.ts';
import { CalendarEventTypes } from '../../../../../_shared/models/TrackUserEventData.ts';
import { hasProjectMultipleWorkflows } from '../../../../../_shared/selectors/Workflows/hasProjectMultipleWorkflows.ts';
import { getSelectedLanguageIdOrThrow } from '../../../../../_shared/selectors/getSelectedLanguageId.ts';
import { IStore } from '../../../../../_shared/stores/IStore.type.ts';
import { isScheduledToPublishWorkflowStep } from '../../../../../_shared/utils/contentItemUtils.ts';
import { getUsersInfo } from '../../../../../_shared/utils/users/usersUtils.ts';
import { getWorkflow } from '../../../../../data/reducers/workflow/selectors/workflowSelectors.ts';
import { navigateBackFromCalendarItemDetails } from '../../actions/calendarActions.ts';
import {
  IItemDetailsDispatchProps,
  IItemDetailsOwnProps,
  IItemDetailsStateProps,
  ItemDetails as ItemDetailsComponent,
} from '../../components/Popups/ItemDetails.tsx';
import { AgendaItemStatus } from '../../constants/AgendaItemStatus.ts';
import { IListingContentItemWithEnsuredVariant } from '../../models/CalendarModels.type.ts';
import { itemHasVariant } from '../../selectors/calendarSelectors.tsx';
import { getAgendaStateForDueItems, getCurrentDate } from '../../utils/calendarUtils.ts';

const getStatus = ({ variant }: IListingContentItemWithEnsuredVariant) => {
  if (variant.assignment.due) {
    return getAgendaStateForDueItems(variant.assignment.due, getCurrentDate);
  }

  if (isScheduledToPublishWorkflowStep(variant.assignment.workflowStatus)) {
    return AgendaItemStatus.Scheduled;
  }

  return AgendaItemStatus.Published;
};

const mapStateToProps = (state: IStore): IItemDetailsStateProps => {
  const {
    calendarApp: { itemDetails, drillDownDate },
    data: {
      users: { usersById },
    },
  } = state;

  const selectedLanguageId = getSelectedLanguageIdOrThrow(state);
  if (!itemDetails) {
    throw InvariantException('ItemDetails.tsx: item id or variant id not assigned');
  }

  const itemById = state.data.listingContentItems.byId.get(itemDetails.id);
  if (!itemById || !itemHasVariant(itemById)) {
    throw InvariantException('ItemDetails.tsx: item without variant encountered');
  }

  return {
    item: {
      id: {
        itemId: itemById.item.id,
        variantId: selectedLanguageId,
      },
      name: itemById.item.name,
      status: getStatus(itemById),
      assignment: itemById.variant.assignment,
      lastModified: itemById.variant.lastModified,
      lastPublishedAt: itemById.variant.lastPublishedAt,
      publishingState: itemById.variant.publishingState,
      isWorkflowNameShown: hasProjectMultipleWorkflows(state),
      workflowName: getWorkflow(state, itemById.variant.assignment.workflowStatus.workflowId)?.name,
    },
    assignees: getUsersInfo(itemById.variant.assignment.assignees, usersById),
    isNavigationBackEnabled: drillDownDate !== null,
  };
};

const mapDispatchToProps = (dispatch: Dispatch): IItemDetailsDispatchProps => ({
  onItemSelected: () =>
    dispatch(
      trackUserEvent(TrackedEvent.Calendar, {
        action: CalendarEventTypes.ContentItemSelected,
      }),
    ),
  onBack: () => dispatch(navigateBackFromCalendarItemDetails()),
});

export const ItemDetails: React.ComponentType<
  IItemDetailsOwnProps & React.ComponentPropsWithRef<React.ComponentType>
> = connect(mapStateToProps, mapDispatchToProps, undefined, {
  forwardRef: true,
})(ItemDetailsComponent);
