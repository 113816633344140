import { usePrevious } from '@kontent-ai/hooks';
import { useEffect, useState } from 'react';
import { useDebouncedCallback } from 'use-debounce';

export const useDebouncedFilterInputValue = <T>(
  valueFromProps: T,
  onChange: (value: T) => void,
  wait: number,
  equalityCheck: ((a: T, b: T) => boolean) | undefined,
): [value: T, setValue: (value: T) => void] => {
  const [currentValue, setCurrentValue] = useState(valueFromProps);

  const debouncedOnChange = useDebouncedCallback(onChange, wait);
  const setValue = (newValue: T): void => {
    setCurrentValue(newValue);
    debouncedOnChange(newValue);
  };

  // Allow update based on external change to the value
  const previousValueFromProps = usePrevious(valueFromProps);
  const shouldUpdateValue =
    valueFromProps !== previousValueFromProps &&
    valueFromProps !== currentValue &&
    !debouncedOnChange.isPending() &&
    !(equalityCheck?.(valueFromProps, previousValueFromProps) ?? false);

  useEffect(() => {
    if (shouldUpdateValue) {
      setCurrentValue(valueFromProps);
    }
  }, [valueFromProps, shouldUpdateValue]);

  return [currentValue, setValue];
};
