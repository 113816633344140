import { Icons } from '@kontent-ai/component-library/Icons';
import { Menu } from '@kontent-ai/component-library/Menu';
import { useIsPreviewUrlOutdated } from '../../../../../../webSpotlight/hooks/useIsPreviewUrlOutdated.ts';
import { useWebSpotlightInItemEditing } from '../../../context/WebSpotlightInItemEditingContext.tsx';

export const RefreshPreviewAction = () => {
  const { refreshPreview } = useWebSpotlightInItemEditing();
  const isPreviewUrlOutdated = useIsPreviewUrlOutdated();

  const refresh = () => refreshPreview({ isManualRefresh: true, isPreviewUrlOutdated });

  return (
    <Menu.Item
      id="refresh-manually"
      label="Refresh manually"
      leadingElement={<Icons.RotateDoubleRight />}
      onAction={refresh}
    />
  );
};
