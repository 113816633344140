import { areShallowEqual } from '@kontent-ai/utils';
import { ThunkFunction } from '../../../../../../../@types/Dispatcher.type.ts';
import { AppNames } from '../../../../../../../_shared/constants/applicationNames.ts';
import { ItemColumnCode } from '../../../../../../../_shared/constants/itemColumnCode.ts';
import {
  ContentItemsAppRouteSegment,
  ContentItemsRoute,
  ContentItemsRouteParams,
} from '../../../../../../../_shared/constants/routePaths.ts';
import { VariantIdMacro } from '../../../../../../../_shared/constants/variantIdValues.ts';
import { LoadingStatus } from '../../../../../../../_shared/models/LoadingStatusEnum.ts';
import { OrderBy } from '../../../../../../../_shared/models/OrderBy.ts';
import { buildPath } from '../../../../../../../_shared/utils/routing/routeTransitionUtils.ts';
import { IContentListingHiddenColumnsStorage } from '../../../../../../../localStorages/contentListingColumnsFilterStorage.ts';
import { IFullTextSearchContextStorage } from '../../../../../../../localStorages/fullTextSearchContextStorage.ts';
import {
  IListingOrderByStorage,
  ListingContext,
  parseLocalStorageDataToOrderBy,
} from '../../../../../../../localStorages/listingOrderByStorage.ts';
import { contentItemsBreadcrumbsOriginChanged } from '../../../../../shared/actions/contentInventoryActions.ts';
import { IListingFilter } from '../../../../models/filter/IListingFilter.ts';
import {
  ContentListing_Init_Finished,
  ContentListing_Init_Started,
} from '../../constants/contentItemInventoryActionTypes.ts';
import {
  contentListingColumnsInit,
  contentListingOrderByUpdated,
  fullTextSearchContextChanged,
} from '../contentItemInventoryActions.ts';

interface IDeps {
  readonly contentListingHiddenColumnsStorage: IContentListingHiddenColumnsStorage;
  readonly fullTextSearchContextStorage: IFullTextSearchContextStorage;
  readonly listingOrderByStorage: IListingOrderByStorage;
}

const initContentItemListingStarted = () =>
  ({
    type: ContentListing_Init_Started,
  }) as const;

export const initContentItemListingFinished = (filter: IListingFilter) =>
  ({
    type: ContentListing_Init_Finished,
    payload: {
      filter,
    },
  }) as const;

export type InitializeContentListingActionsType = ReturnType<
  typeof initContentItemListingStarted | typeof initContentItemListingFinished
>;

export const createInitializeContentListingAction =
  (deps: IDeps) => (): ThunkFunction => (dispatch, getState) => {
    const {
      data: {
        listingContentItems,
        user: {
          info: { userId },
        },
      },
      contentApp: {
        listingUi: { filter, orderBy },
      },
      sharedApp: { currentProjectId },
    } = getState();

    dispatch(initContentItemListingStarted());

    const inventoryPath = buildPath<ContentItemsRouteParams>(ContentItemsRoute, {
      app: ContentItemsAppRouteSegment.Content,
      projectId: currentProjectId,
      variantId: VariantIdMacro,
      spaceId: undefined,
    });

    dispatch(
      contentItemsBreadcrumbsOriginChanged({
        path: inventoryPath,
        title: AppNames.ContentItems,
      }),
    );

    const newOrderBy = deps.listingOrderByStorage.load<OrderBy<ItemColumnCode>>(
      ListingContext.ContentItemInventory,
      currentProjectId,
      parseLocalStorageDataToOrderBy(ItemColumnCode),
    );

    if (newOrderBy && !areShallowEqual(newOrderBy, orderBy)) {
      dispatch(contentListingOrderByUpdated(newOrderBy));
    }

    const shouldLoadInventory = listingContentItems.loadingStatus === LoadingStatus.InitialEmpty;

    if (shouldLoadInventory) {
      const fullTextSearchContext = deps.fullTextSearchContextStorage.load(
        ListingContext.ContentItemInventory,
        currentProjectId,
      );
      if (fullTextSearchContext) {
        dispatch(fullTextSearchContextChanged(fullTextSearchContext));
      }

      const hiddenColumns = deps.contentListingHiddenColumnsStorage.load(currentProjectId, userId);

      if (hiddenColumns) {
        dispatch(contentListingColumnsInit(hiddenColumns));
      }
    }

    dispatch(initContentItemListingFinished(filter));
  };
