import { isElementVisible } from '@kontent-ai/DOM';
import classNames from 'classnames';
import React, { MouseEvent } from 'react';
import { IUserInfo } from '../../models/UserInfo.ts';
import { getDataUiObjectNameAttribute } from '../../utils/dataAttributes/DataUiAttributes.ts';
import { formatUserName } from '../../utils/users/usersUtils.ts';
import { HighlightedOption } from './HighlightedOption.tsx';
import { OptionType } from './MultipleSelectDropDownOption.tsx';

interface IMultipleOptionSelectDropDownOptionDataProps {
  readonly option: IUserInfo;
  readonly isHighlighted: boolean;
  readonly isSelected: boolean;
  readonly highlightedPattern: string;
}

interface IMultipleOptionSelectDropDownOptionCallbackProps {
  readonly onClick: (option: IUserInfo) => void;
  readonly onHover: (option: IUserInfo) => void;
  readonly getOptionName: (option: IUserInfo) => string;
  readonly getOptionType?: (option: IUserInfo) => OptionType;
}

interface IMultipleOptionSelectDropDownOptionProps
  extends IMultipleOptionSelectDropDownOptionDataProps,
    IMultipleOptionSelectDropDownOptionCallbackProps {}

export class MultipleSelectDropDownUserOption extends React.PureComponent<IMultipleOptionSelectDropDownOptionProps> {
  static displayName = 'MultipleSelectDropDownUserOption';

  private readonly _optionRef = React.createRef<HTMLDivElement>();

  componentDidUpdate() {
    if (this.props.isHighlighted) {
      const optionElement = this._optionRef.current;
      if (optionElement && !isElementVisible(optionElement)) {
        optionElement.scrollIntoView();
      }
    }
  }

  private readonly _onClick = (event: MouseEvent<HTMLDivElement>): void => {
    event.stopPropagation();
    this.props.onClick(this.props.option);
  };

  private readonly _onHover = (): void => {
    this.props.onHover(this.props.option);
  };

  render(): JSX.Element {
    const { option, highlightedPattern, isHighlighted, isSelected, getOptionType } = this.props;

    if (getOptionType?.(option) === OptionType.Category) {
      return (
        <div className="multi-select__dropdown-option multi-select__dropdown-option--is-category">
          {option.email}
        </div>
      );
    }
    const userName = formatUserName(option);

    return (
      <div
        className={classNames(
          'multi-select__dropdown-option',
          'multi-select__dropdown-user-option',
          {
            'multi-select__dropdown-option--is-highlighted': isHighlighted,
            'multi-select__dropdown-option--is-selected': isSelected,
          },
        )}
        ref={this._optionRef}
        onClick={this._onClick}
        onMouseOver={this._onHover}
        {...getDataUiObjectNameAttribute(option.email)}
      >
        <div className="multi-select__dropdown-option-name" title={userName}>
          <HighlightedOption optionName={userName} pattern={highlightedPattern} />
        </div>
        <div className="multi-select__dropdown-option-aux" title={option.email}>
          <HighlightedOption optionName={option.email} pattern={highlightedPattern} />
        </div>
      </div>
    );
  }
}
