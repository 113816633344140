import { createGuid, delay } from '@kontent-ai/utils';
import { trackUserEvent } from '../../../../_shared/actions/thunks/trackUserEvent.ts';
import { repositoryCollection } from '../../../../_shared/repositories/repositories.ts';
import {
  loadAssetType,
  loadCollections,
  loadContentTypes,
  loadContentTypesUsage,
  loadSnippets,
  loadSnippetsUsage,
  loadTaxonomyGroups,
} from '../../../../data/actions/thunkDataActions.ts';
import { loadAiGuidelines } from '../../../../paperModels/aiReview/data/actions/thunkAiReviewDataActions.ts';
import { loadContentTypeReferencesForTypeEditing } from '../../shared/actions/thunkSharedContentModelsActions.ts';
import { createSaveEditedMultipleChoiceOptionAction } from '../../shared/actions/thunks/saveEditedMultipleChoiceOption.ts';
import { createDefaultTypeElementData } from '../../shared/utils/defaultTypeElementDataCreator.ts';
import { trackAssetLimitConfigurationChanged } from '../../shared/utils/intercomHelpers/trackAssetLimitsUtils.ts';
import { trackCustomTypeElementUpserted } from '../../shared/utils/intercomHelpers/trackCustomTypeElementConfig.ts';
import { convertTypeElementsToServerModel } from '../../shared/utils/typeElementDataConverter.ts';
import {
  deleteMultipleChoiceOption,
  multipleChoiceOptionDeselected,
  updateMultipleChoiceOption,
} from './snippetsActions.ts';
import {
  createAddNewSnippetTypeElementAction,
  createInsertNewSnippetTypeElementBeforeAction,
} from './thunks/addNewSnippetTypeElement.ts';
import { createArchiveContentTypeSnippetsAction } from './thunks/archiveContentTypeSnippets.ts';
import { createCreateContentTypeSnippetAction } from './thunks/createContentTypeSnippet.ts';
import { createInitContentTypeSnippetCreatorAction } from './thunks/initContentTypeSnippetCreator.ts';
import { createInitContentTypeSnippetEditorAction } from './thunks/initContentTypeSnippetEditor.ts';
import { createInitContentTypeSnippetListingAction } from './thunks/initContentTypeSnippetListing.ts';
import { createLoadSnippetsDataAction } from './thunks/loadSnippetsData.ts';
import { createRemoveSnippetElement } from './thunks/removeSnippetElement.ts';
import { createSaveEditedContentTypeSnippetAction } from './thunks/saveEditedContentTypeSnippet.ts';
import { createSelectContentTypeSnippetsAction } from './thunks/selectContentTypeSnippets.ts';
import { createUndoContentTypeSnippetListingOperationAction } from './thunks/undoContentTypeSnippetListingOperation.ts';
import { createUnselectContentTypeSnippetsAction } from './thunks/unselectContentTypeSnippets.ts';
import { createUpdateTypeSnippetElementAction } from './thunks/updateTypeSnippetElement.ts';
import { createValidateTypeSnippetElementAction } from './thunks/validateTypeSnippetElements.ts';

const { contentItemRepository, contentTypeSnippetRepository } = repositoryCollection;

export const loadSnippetsData = createLoadSnippetsDataAction({
  loadSnippets,
  loadSnippetsUsage,
});

export const initContentTypeSnippetCreator = createInitContentTypeSnippetCreatorAction({
  loadAssetType,
  loadCollections,
  loadContentTypes,
  loadTaxonomyGroups,
});

export const addNewSnippetTypeElementAction = createAddNewSnippetTypeElementAction({
  createGuid,
  createDefaultTypeElementData,
});

export const insertNewSnippetTypeElementBeforeAction =
  createInsertNewSnippetTypeElementBeforeAction({
    createGuid,
    createDefaultTypeElementData,
  });

export const selectContentTypeSnippets = createSelectContentTypeSnippetsAction();

export const unselectContentTypeSnippets = createUnselectContentTypeSnippetsAction();

export const archiveContentTypeSnippets = createArchiveContentTypeSnippetsAction({
  contentTypeSnippetRepository,
  loadSnippetsData,
  trackUserEvent,
});

export const undoContentTypeSnippetListingOperation =
  createUndoContentTypeSnippetListingOperationAction({
    contentTypeSnippetRepository,
    loadSnippetsData,
    delay,
  });

export const initContentTypeSnippetListing = createInitContentTypeSnippetListingAction({
  loadSnippetsData,
});

export const removeSnippetElement = createRemoveSnippetElement();

const validateTypeElement = createValidateTypeSnippetElementAction();

export const initContentTypeSnippetEditor = createInitContentTypeSnippetEditorAction({
  contentItemRepository,
  loadAiGuidelines,
  loadAssetType,
  loadCollections,
  loadContentTypeReferencesForTypeEditing,
  loadContentTypes,
  loadContentTypesUsage,
  loadSnippetsData,
  loadTaxonomyGroups,
  validateTypeElement,
});

const saveEditedMultipleChoiceOption = createSaveEditedMultipleChoiceOptionAction({
  deleteOption: deleteMultipleChoiceOption,
  deselectOption: multipleChoiceOptionDeselected,
  getEditedMultipleChoiceElement: (state) =>
    state.contentModelsApp.snippets.editor.editedMultipleChoiceElement,
  getTypeElements: (state) =>
    state.contentModelsApp.snippets.editor.editedContentTypeSnippet.typeElements,
  updateOption: updateMultipleChoiceOption,
});

export const createContentTypeSnippet = createCreateContentTypeSnippetAction({
  contentTypeSnippetRepository,
  saveEditedMultipleChoiceOption,
  convertTypeElementsToServerModel,
  loadSnippetsData,
  trackAssetLimitConfigurationChanged,
  trackCustomTypeElementUpserted,
  trackUserEvent,
  validateTypeElement,
});

export const saveEditedContentTypeSnippet = createSaveEditedContentTypeSnippetAction({
  contentTypeSnippetRepository,
  saveEditedMultipleChoiceOption,
  loadSnippetsData,
  trackAssetLimitConfigurationChanged,
  trackCustomTypeElementUpserted,
  trackUserEvent,
  validateTypeElement,
});

export const updateTypeSnippetElement = createUpdateTypeSnippetElementAction({
  validateTypeElement,
});
