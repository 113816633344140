import { Avatar, AvatarSize } from '@kontent-ai/component-library/Avatar';
import { DarkGradient } from '@kontent-ai/component-library/tokens';
import { TipsFromKontentUserName } from '../../../../../utils/commentUtils.ts';

const TipsFromKontentInitials = 'TK';

export const TipsFromKontent = ({ size }: { readonly size: AvatarSize }) => (
  <Avatar
    aria-hidden
    backgroundGradient={DarkGradient.OrangeDark}
    initials={TipsFromKontentInitials}
    label={TipsFromKontentUserName}
    size={size}
  />
);
