export const AiFeatureSet = 'AiFeatureSet';
export const AssetReplacement = 'AssetReplacement';
export const AssetCollectionMandatory = 'AssetCollectionMandatory';
export const ContentPace = 'ContentPace';
export const SampleProjectType = 'SampleProjectType';
export const RefinedNavigation = 'RefinedNavigation';

/**
 * The mismatch between the name and the value was caused by a rename. We opted to keep the feature status without a migration.
 * Content status is a part of Mission control.
 */
export const MissionControlFeature = 'ContentStatus';
export const MissionControlConsent = 'content-status-consent';
export const MultiselectOptimization = 'MultiselectOptimization';
export const SecureAssets = 'SecureAssets';
export const SignalRAlternativeTransport = 'SignalRAlternativeTransport';
export const SpacesInCollections = 'SpacesInCollections';
