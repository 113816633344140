import { Action } from '../../../@types/Action.type.ts';
import { Users_ProjectContributorsLoaded } from '../../../applications/environmentSettings/users/constants/usersActionTypes.ts';
import {
  ContentItemEditing_DiscussionsSidebarClosed,
  ContentItemEditing_DiscussionsSidebarOpened,
  ContentItemEditing_ResolvedCommentsSidebarClosed,
  ContentItemEditing_ResolvedCommentsSidebarOpened,
} from '../../../applications/itemEditor/features/ContentItemEditing/constants/contentItemEditingActionTypes.ts';
import {
  People_IntercomChatWindowOpened,
  Project_CloneProject_Finished,
  Project_CreateSampleProject_Finished,
  Project_TrialActivation_Close,
  Project_TrialActivation_Show,
  Subscription_ActivateTrial_Finished,
} from '../../../applications/projects/constants/projectActionTypes.ts';
import {
  Subscription_AddNewSubscriptionAdmin_ShowModal,
  Subscription_PromoteAdmin_Canceled,
  Subscription_PromoteAdmin_Finished,
  Subscription_PromoteExistingUsers_ShowModal,
  Subscription_SubscriptionAdminInvitation_Canceled,
  Subscription_SubscriptionAdminInvitation_Finished,
} from '../../../applications/subscriptionManagement/Admins/constants/actionTypes.ts';
import {
  Subscription_ChangePlan_Finished,
  Subscription_PlanChange_CloseDialog,
  Subscription_PlanChange_ShowDialog,
} from '../../../applications/subscriptionManagement/PlanSelection/constants/actionTypes.ts';
import {
  Subscription_CreateSubscription_CloseModal,
  Subscription_CreateSubscription_Finished,
  Subscription_CreateSubscription_ShowModal,
} from '../../../applications/subscriptionManagement/SubscriptionListing/contants/actionTypes.ts';
import { Shared_AnySidebar_Unmounted } from '../../constants/sharedActionTypes.ts';
import { SidebarNames } from '../../models/SidebarNames.ts';

export type ISidebarStatus = {
  readonly isVisible: boolean;
  readonly sidebarName: SidebarNames | null;
};

const initialState: ISidebarStatus = {
  isVisible: false,
  sidebarName: null,
};

export const sidebarStatus = (
  state: ISidebarStatus = initialState,
  action: Action,
): ISidebarStatus => {
  switch (action.type) {
    case ContentItemEditing_DiscussionsSidebarOpened:
      return {
        isVisible: true,
        sidebarName: SidebarNames.DiscussionsSidebar,
      };

    case ContentItemEditing_ResolvedCommentsSidebarOpened:
      return {
        isVisible: true,
        sidebarName: SidebarNames.ResolvedCommentsSidebar,
      };

    case Subscription_AddNewSubscriptionAdmin_ShowModal:
      return {
        isVisible: true,
        sidebarName: SidebarNames.SubscriptionAdminInvitationModal,
      };

    case Subscription_CreateSubscription_ShowModal:
      return {
        isVisible: true,
        sidebarName: SidebarNames.CreateNewSubscriptionModal,
      };

    case Subscription_PlanChange_ShowDialog:
      return {
        isVisible: true,
        sidebarName: SidebarNames.ChangePlanModal,
      };

    case Subscription_PromoteExistingUsers_ShowModal:
      return {
        isVisible: true,
        sidebarName: SidebarNames.PromoteUsersModal,
      };
    case Project_TrialActivation_Show:
      return {
        isVisible: true,
        sidebarName: SidebarNames.TrialActivationModal,
      };

    case ContentItemEditing_DiscussionsSidebarClosed:
    case ContentItemEditing_ResolvedCommentsSidebarClosed:
    case People_IntercomChatWindowOpened:
    case Subscription_ChangePlan_Finished:
    case Subscription_CreateSubscription_CloseModal:
    case Subscription_CreateSubscription_Finished:
    case Subscription_PlanChange_CloseDialog:
    case Subscription_PromoteAdmin_Canceled:
    case Subscription_PromoteAdmin_Finished:
    case Subscription_SubscriptionAdminInvitation_Canceled:
    case Subscription_SubscriptionAdminInvitation_Finished:
    case Project_CloneProject_Finished:
    case Project_CreateSampleProject_Finished:
    case Project_TrialActivation_Close:
    case Subscription_ActivateTrial_Finished:
    case Shared_AnySidebar_Unmounted:
    case Users_ProjectContributorsLoaded:
      return initialState;

    default:
      return state;
  }
};
