import { ThunkPromise } from '../../../../../../@types/Dispatcher.type.ts';
import { TrackedEvent } from '../../../../../../_shared/constants/trackedEvent.ts';
import { TrackUserEventAction } from '../../../../../../_shared/models/TrackUserEvent.type.ts';
import { CommentAction } from '../../../../../../_shared/models/events/CommentEventData.type.ts';
import { ICommentRepository } from '../../../../../../repositories/interfaces/ICommentRepository.type.ts';
import {
  ICommentThread,
  createCommentThreadDomainModel,
} from '../../../../models/comments/CommentThreads.ts';
import {
  commentThreadUnresolved,
  commentThreadUnresolvingStarted,
} from '../contentItemEditingActions.ts';

interface IUnresolveCommentThread {
  readonly commentRepository: ICommentRepository;
  readonly trackUserEvent: TrackUserEventAction;
}

export const createUnresolveCommentThread =
  (deps: IUnresolveCommentThread) =>
  (commentThreadId: Uuid): ThunkPromise =>
  async (dispatch, getState) => {
    const {
      contentApp: { editedContentItemVariant, editedContentItemVariantComments },
    } = getState();

    const threadThread = editedContentItemVariantComments.commentThreads.find(
      (thread: ICommentThread) => thread.id === commentThreadId,
    );
    if (!editedContentItemVariant || !threadThread) {
      return;
    }

    dispatch(commentThreadUnresolvingStarted(commentThreadId, threadThread.elementId));

    const { itemId, variantId } = editedContentItemVariant.id;
    const commentThreadServerModel = await deps.commentRepository.markCommentThreadAsUnresolved(
      itemId,
      variantId,
      commentThreadId,
    );
    const commentThread = createCommentThreadDomainModel(commentThreadServerModel);
    dispatch(commentThreadUnresolved(commentThread));

    dispatch(
      deps.trackUserEvent(TrackedEvent.Comments, {
        action: CommentAction.CommentThreadUnresolved,
      }),
    );
  };
