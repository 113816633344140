import { ComponentProps, memo } from 'react';
import { useLocation } from 'react-router';
import { LinkDataTableCell } from '../../../../../_shared/components/DataTable/LinkDataTableCell.tsx';
import { LastUpdatedAt } from '../../../../../_shared/components/LastUpdatedAt.tsx';
import { getContentItemPath } from '../../../../../_shared/utils/routing/routeTransitionUtils.ts';
import { PublishedListingContentItemRecord } from '../../../../../data/models/listingContentItems/PublishedListingContentItemRecord.ts';
import {
  StyledDataTableCell,
  StyledDataTableRow,
} from '../../shared/components/StyledDataTable.tsx';

type PublishedItemsWidgetRowProps = Readonly<{
  collectionName: string;
  contentTypeName: string;
  onItemClick?: () => void;
  publishedItem: PublishedListingContentItemRecord;
  rowFocusLinkAriaLabel: string;
}>;

export const PublishedItemsWidgetRow = memo(
  ({
    collectionName,
    contentTypeName,
    publishedItem,
    rowFocusLinkAriaLabel,
    onItemClick,
  }: PublishedItemsWidgetRowProps) => {
    const { pathname } = useLocation();

    const linkPath = getContentItemPath(
      pathname,
      publishedItem.contentItemId.itemId,
      publishedItem.contentItemId.variantId,
    );

    const linkProps = {
      linkPath,
      onClick: onItemClick,
    } satisfies ComponentProps<typeof LinkDataTableCell>;

    return (
      <StyledDataTableRow dataUiObjectName={publishedItem.name} id={publishedItem.id}>
        <StyledDataTableCell {...linkProps} focusableRowLinkAriaLabel={rowFocusLinkAriaLabel}>
          {publishedItem.name}
        </StyledDataTableCell>
        <StyledDataTableCell {...linkProps}>{contentTypeName}</StyledDataTableCell>
        <StyledDataTableCell {...linkProps}>{collectionName}</StyledDataTableCell>
        <StyledDataTableCell {...linkProps}>
          <LastUpdatedAt time={publishedItem.lastPublishedAt} />
        </StyledDataTableCell>
      </StyledDataTableRow>
    );
  },
);
