import { Callout } from '@kontent-ai/component-library/Callout';
import { Card } from '@kontent-ai/component-library/Card';
import { Stack } from '@kontent-ai/component-library/Stack';
import { Spacing } from '@kontent-ai/component-library/tokens';
import { useSelector } from '../../../../../_shared/hooks/useSelector.ts';
import {
  isAiSearchEnabled,
  isEnablingAiSearchActivated,
} from '../../../../../_shared/selectors/aiSelectors.ts';
import { selectIntercomUtils } from '../../../../../_shared/selectors/selectIntercomUtils.ts';
import { FeatureActivationDialog } from '../../components/FeatureActivationDialog.tsx';
import { FeatureDeactivationDialog } from '../../components/FeatureDeactivationDialog.tsx';
import { InnovationLabFeatureButton } from '../../components/InnovationLabFeatureButton.tsx';
import { InnovationLabFeatureCard } from '../../components/InnovationLabFeatureCard.tsx';
import { InnovationLabFeatureGenericallyUnavailable } from '../../components/InnovationLabFeatureGenericallyUnavailable.tsx';
import {
  InnovationLabActivationState,
  InnovationLabStatus,
} from '../../components/InnovationLabFeatureStatus.tsx';
import { InnovationLabGenericConsent } from '../../components/InnovationLabGenericConsent.tsx';
import { InnovationLabGenericDeactivationWarning } from '../../components/InnovationLabGenericDeactivationWarning.tsx';
import { useFeatureActivationDialog } from '../../hooks/useFeatureActivationDialog.ts';
import { activateInnovationLabFeature } from '../../thunks/activateInnovationLabFeature.ts';
import { deactivateInnovationLabFeature } from '../../thunks/deactivateInnovationLabFeature.ts';
import { InnovationLabCardComponent } from '../../types/InnovationLabCardComponent.type.ts';

const useGetActivationState = (): InnovationLabActivationState => {
  const isEnablingLinkedItems = useSelector(isEnablingAiSearchActivated);
  const isLinkedItemsActivated = useSelector(AiSearchCard.isFeatureEnabledSelector);

  if (isLinkedItemsActivated) {
    return 'active';
  }

  if (isEnablingLinkedItems) {
    return 'activation-pending';
  }

  return 'inactive';
};

export const AiSearchCard: InnovationLabCardComponent = ({ featureInfo }) => {
  const featureName = AiSearchCard.featureName;
  const activationState = useGetActivationState();
  const intercomUtils = useSelector(selectIntercomUtils);

  const {
    dialogDisplayed,
    isAsyncOperationInProgress,
    onCardActionButtonClick,
    onDialogActionClick,
    onDialogClose,
  } = useFeatureActivationDialog(
    featureInfo,
    activationState,
    activateInnovationLabFeature('ai-enabling-search'),
    deactivateInnovationLabFeature('ai-search'),
  );

  const featureState = featureInfo.status;

  return (
    <>
      <InnovationLabFeatureCard component="section" featureState={featureState}>
        <Card.Headline
          renderAuxiliaryElements={() => <InnovationLabStatus activationState={activationState} />}
        >
          {featureName}
        </Card.Headline>

        <Card.Body>
          <Stack spacing={Spacing.M}>
            <p>
              Spend less time guessing the right words and more time finding the content you need.
            </p>
            <p>
              You don’t need to remember the exact name of a content item or use specific
              keywords—semantic search helps you find what you’re looking for, even if you describe
              it differently.
            </p>
            <InnovationLabFeatureGenericallyUnavailable
              activationState={activationState}
              featureState={featureState}
              onContactSupport={intercomUtils.showIntercom}
            />
          </Stack>
        </Card.Body>
        <Card.Footer>
          <Stack spacing={Spacing.XL}>
            <InnovationLabFeatureButton
              activationState={activationState}
              featureName={featureName}
              featureState={featureState}
              isAsyncOperationInProgress={isAsyncOperationInProgress}
              onClick={onCardActionButtonClick}
            />
            {activationState === 'activation-pending' && (
              <Callout calloutType="friendlyWarning">
                Feature is being activated, which could take from a couple of minutes to a few days,
                depending on your project’s complexity. While this is in progress, you can continue
                your work as usual.
              </Callout>
            )}
          </Stack>
        </Card.Footer>
      </InnovationLabFeatureCard>
      <FeatureActivationDialog
        headline={`Activate ${featureName}`}
        isOpen={dialogDisplayed === 'activation'}
        isActivating={isAsyncOperationInProgress}
        onClose={onDialogClose}
        onActivate={onDialogActionClick}
      >
        <Stack spacing={Spacing.M}>
          <Callout calloutType="quickTip" hideSubheadline>
            <p>
              <b>Innovation Lab feature activation</b>
            </p>
            <p>You’re about to activate {featureName}.</p>
            <p>
              The AI analyzes your written content and suggests relevant content items based on your
              search terms.
            </p>
          </Callout>
          <InnovationLabGenericConsent />
        </Stack>
      </FeatureActivationDialog>
      <FeatureDeactivationDialog
        headline={`Deactivate ${featureName}`}
        isOpen={dialogDisplayed === 'deactivation'}
        isDeactivating={isAsyncOperationInProgress}
        onClose={onDialogClose}
        onDeactivate={onDialogActionClick}
      >
        <InnovationLabGenericDeactivationWarning featureName={featureName} />
      </FeatureDeactivationDialog>
    </>
  );
};

AiSearchCard.isFeatureEnabledSelector = isAiSearchEnabled;
AiSearchCard.featureName = 'Semantic search';
AiSearchCard.releaseDate = '2025-01-01T00:00:00.000Z';
