import { Callout } from '@kontent-ai/component-library/Callout';
import React from 'react';
import { LinkLike } from '../../../../_shared/uiComponents/LinkLike/LinkLike.tsx';
import { InnovationLabFeatureStatus } from '../types/InnovationLabFeatureStatus.ts';
import { isInnovationLabFeatureAvailable } from '../utils/innovationLabFeatureStatusUtils.ts';
import { InnovationLabActivationState } from './InnovationLabFeatureStatus.tsx';

type Props = {
  readonly activationState: InnovationLabActivationState;
  readonly featureState: InnovationLabFeatureStatus;
  readonly onContactSupport: () => void;
};

export const InnovationLabFeatureGenericallyUnavailable: React.FC<Props> = ({
  activationState,
  featureState,
  onContactSupport,
}) => {
  if (
    isInnovationLabFeatureAvailable(featureState) ||
    activationState === 'active' ||
    activationState === 'activation-pending'
  ) {
    return;
  }

  return (
    <Callout calloutType="friendlyWarning" hideSubheadline>
      <p>
        <b>This feature is currently unavailable.</b>
      </p>
      <p>We’re actively working on resolving the issue and apologize for the inconvenience.</p>
      <p>
        Please <LinkLike onClick={onContactSupport}>contact us</LinkLike> if you need to access the
        feature or have any questions.
      </p>
    </Callout>
  );
};
