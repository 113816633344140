import { QuickTip } from '../../../_shared/components/infos/QuickTip.tsx';
import { TrialActivation } from '../containers/TrialActivation.tsx';

export const StartTrialQuickTip = () => (
  <div className="row">
    <div className="col-sm-12">
      <QuickTip subtitle="Your own projects" className="callout--is-in-projects">
        <TrialActivation text="If you’d like to create projects, start a free trial subscription and try it out. No credit card required." />
      </QuickTip>
    </div>
  </div>
);

StartTrialQuickTip.displayName = 'StartTrialQuickTip';
