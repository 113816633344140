import { OnboardingPopover } from '@kontent-ai/component-library/OnboardingBubble';
import { Stack } from '@kontent-ai/component-library/Stack';
import { Spacing } from '@kontent-ai/component-library/tokens';
import { isBefore } from 'date-fns';
import React, { ComponentProps } from 'react';
import { useRouteMatch } from 'react-router';
import { upsertUserProperty } from '../../../../_shared/actions/thunkSharedActions.ts';
import { ContentItemsRoute } from '../../../../_shared/constants/routePaths.ts';
import { useDispatch } from '../../../../_shared/hooks/useDispatch.ts';
import { useSelector } from '../../../../_shared/hooks/useSelector.ts';
import { DismissedSemanticSearchOnboardingServerKey } from '../../../../_shared/models/UserPropertiesServerKeys.ts';
import { PlayableImage } from '../../../../_shared/uiComponents/PlayableImage/PlayableImage.tsx';

type Props = Readonly<{
  isSemanticSearchEnabled: boolean;
  renderTarget: ComponentProps<typeof OnboardingPopover>['renderTarget'];
}>;

export const SemanticSearchOnboarding: React.FC<Props> = ({
  isSemanticSearchEnabled,
  renderTarget,
}) => {
  const shouldShowOnboarding = useSelector(
    (s) =>
      !s.sharedApp.userProperties.dismissedSemanticSearchOnboarding &&
      isBefore(s.data.user.info.createdAt, new Date('2025-01-31')),
  );
  const isInInventory = !!useRouteMatch(ContentItemsRoute)?.isExact;

  const isVisible = shouldShowOnboarding && isInInventory && isSemanticSearchEnabled;

  const dispatch = useDispatch();
  const onDismiss = () => {
    dispatch(upsertUserProperty(DismissedSemanticSearchOnboardingServerKey, 'true'));
  };

  return (
    <OnboardingPopover
      headlineText="Search by meaning"
      isVisible={isVisible}
      onDismiss={onDismiss}
      placement="bottom"
      renderTarget={renderTarget}
      includeFog
    >
      <Stack spacing={Spacing.XL}>
        <p>
          Introducing semantic search! No need to remember the exact names of content items or use
          specific keywords. Semantic search helps you find what you’re looking for, even if you
          describe it in your own words.
        </p>
        <PlayableImage
          playUrl="/images/content/semantic-search-example.gif"
          previewUrl="/images/content/semantic-search-example-preview.gif"
        />
      </Stack>
    </OnboardingPopover>
  );
};
