import React from 'react';
import { IUserInfo } from '../../../../../_shared/models/UserInfo.ts';
import { renderDatetimeString } from '../../../../../_shared/utils/dateTime/timeUtils.ts';
import { makeSpacesUnbreakable } from '../../../../../_shared/utils/stringUtils.ts';
import { formatCurrentUserName } from '../../../../../_shared/utils/users/usersUtils.ts';
import { TimelineItem } from '../../../models/revisions/TimeLineItem.ts';

interface IDiscardItemProps {
  readonly currentUserId: UserId;
  readonly isCurrent: boolean;
  readonly item: TimelineItem;
  readonly modifiedBy: IUserInfo | null;
  readonly discardedFrom: TimelineItem | null;
  readonly discardedTo: TimelineItem | null;
}

export const DiscardItem: React.FC<IDiscardItemProps> = ({
  currentUserId,
  discardedFrom,
  discardedTo,
  isCurrent,
  item,
  modifiedBy,
}) => {
  const time = makeSpacesUnbreakable(renderDatetimeString(item.time));
  const originalPublishTime = makeSpacesUnbreakable(renderDatetimeString(discardedTo?.time));
  const discardedVersionTime = makeSpacesUnbreakable(renderDatetimeString(discardedFrom?.time));

  return (
    <div className="revision-item">
      <div className="revision-item__details-pane">
        {isCurrent && <div className="revision-item__current-version-label">Current version</div>}
        <div className="revision-item__title" title={time}>
          {time}
        </div>
        {modifiedBy && (
          <div className="revision-item__detail-note">
            {formatCurrentUserName(currentUserId, false)(modifiedBy)} discarded version{' '}
            {discardedVersionTime}.<br />
            {!!discardedTo?.time && <>Current state is Published from {originalPublishTime}.</>}
          </div>
        )}
      </div>
    </div>
  );
};

DiscardItem.displayName = 'DiscardItem';
