import { usePrevious } from '@kontent-ai/hooks';
import React, {
  ForwardRefExoticComponent,
  RefAttributes,
  useState,
  useEffect,
  useCallback,
} from 'react';
import { IAnimatedModalDialogProps } from '../../../_shared/components/ModalDialog/IAnimatedModalDialogProps.type.ts';
import { ItemEditingModalDialogType } from '../constants/itemEditingModalDialogType.ts';
import { CancelScheduleUnpublishConfirmationDialog } from '../features/CancelSchedule/containers/CancelScheduleUnpublishConfirmationDialog.tsx';
import { CascadePublishFailedItemsModal } from '../features/CascadePublish/containers/CascadePublishFailedItemsModal.tsx';
import { CascadePublishModal } from '../features/CascadePublish/containers/CascadePublishModal.tsx';
import { CascadeScheduleFailedItemsModal } from '../features/CascadePublish/containers/CascadeScheduleFailedItemsModal.tsx';
import { CascadeScheduleModal } from '../features/CascadePublish/containers/CascadeScheduleModal.tsx';
import { ChangeWorkflowDialog } from '../features/ChangeWorkflowDialog/containers/ChangeWorkflowDialog.tsx';
import { ChangeWorkflowStepEditingModal } from '../features/ChangeWorkflowStep/containers/ChangeWorkflowStepEditingModal.tsx';
import { CascadeUnpublishConfirmationDialog } from '../features/ContentItemEditing/containers/confirmationDialogs/CascadeUnpublishConfirmationDialog.tsx';
import { ContentItemDeleteConfirmationDialog } from '../features/ContentItemEditing/containers/confirmationDialogs/ContentItemDeleteConfirmationDialog.tsx';
import { DiscardNewVersionConfirmationDialog } from '../features/ContentItemEditing/containers/confirmationDialogs/DiscardNewVersionConfirmationDialog.tsx';
import { MoveItemToArchivedStepConfirmationDialog } from '../features/ContentItemEditing/containers/confirmationDialogs/MoveItemToArchivedStepConfirmationDialog.tsx';
import { ContributorsFormDialog } from '../features/ContentItemEditing/containers/details/Contributors/ContributorsFormDialog.tsx';
import { DueDateFormDialog } from '../features/ContentItemEditing/containers/details/DueDate/DueDateFormDialog.tsx';
import { NoteFormDialog } from '../features/ContentItemEditing/containers/details/Note/NoteFormDialog.tsx';
import { AddTaskFormDialog } from '../features/ContentItemEditing/containers/details/Tasks/AddTaskFormDialog.tsx';
import { EditTaskFormDialog } from '../features/ContentItemEditing/containers/details/Tasks/EditTaskFormDialog.tsx';
import { CodenameDialog } from '../features/ContentItemEditing/containers/editingActions/CodenameDialog.tsx';
import { NotSetupPreviewDialog } from '../features/ContentItemEditing/containers/editingActions/actionSubmenuOptions/NotSetupPreviewDialog.tsx';
import { DuplicateToCollectionDialog } from '../features/DuplicateToCollection/containers/DuplicateToCollectionDialog.tsx';
import { MoveItemToCollectionDialog } from '../features/MoveToCollectionDialog/containers/MoveItemToCollectionDialog.tsx';
import { NewVariantWorkflowSelectionDialog } from '../features/NewVariantWorkflowSelectionDialog/containers/NewVariantWorkflowSelectionDialog.tsx';

type AnimatedModals =
  | ItemEditingModalDialogType.CancelItemScheduledUnpublishConfirmationDialog
  | ItemEditingModalDialogType.CascadePublishDialog
  | ItemEditingModalDialogType.CascadePublishFailedItemsDialog
  | ItemEditingModalDialogType.CascadeScheduleDialog
  | ItemEditingModalDialogType.CascadeScheduleFailedItemsDialog
  | ItemEditingModalDialogType.CascadeUnpublishConfirmationDialog
  | ItemEditingModalDialogType.ChangeContentItemCollectionDialog
  | ItemEditingModalDialogType.ChangeWorkflowDialog
  | ItemEditingModalDialogType.ChangeWorkflowStepDialog
  | ItemEditingModalDialogType.ContentItemAddTaskDialog
  | ItemEditingModalDialogType.ContentItemAssignContributorsDialog
  | ItemEditingModalDialogType.ContentItemDueDateDialog
  | ItemEditingModalDialogType.ContentItemEditTaskDialog
  | ItemEditingModalDialogType.ContentItemNoteDialog
  | ItemEditingModalDialogType.ContentItemCodenameDialog
  | ItemEditingModalDialogType.ContentItemConfigurePreviewDialog
  | ItemEditingModalDialogType.DeleteItemConfirmationDialog
  | ItemEditingModalDialogType.DiscardNewVersionConfirmationDialog
  | ItemEditingModalDialogType.DuplicateItemWithContent
  | ItemEditingModalDialogType.DuplicateItemWithoutContent
  | ItemEditingModalDialogType.MoveItemToArchivedStepConfirmationDialog;

type OldModals = Exclude<ItemEditingModalDialogType, AnimatedModals>;

const NoDialog = () => null;

const oldModalDialogsMap: ReadonlyRecord<OldModals, React.ComponentType> = {
  [ItemEditingModalDialogType.NewVariantWorkflowSelectionDialog]: NewVariantWorkflowSelectionDialog,
  [ItemEditingModalDialogType.None]: NoDialog,
};

const isOldModalDialogType = (
  type: ItemEditingModalDialogType,
): type is keyof typeof oldModalDialogsMap =>
  (Object.keys(oldModalDialogsMap) as ReadonlyArray<ItemEditingModalDialogType>).includes(type);

type AnimatedModalComponentWithRef = ForwardRefExoticComponent<
  IAnimatedModalDialogProps & RefAttributes<HTMLDivElement>
>;

const animatedModalDialogsMap: ReadonlyRecord<AnimatedModals, AnimatedModalComponentWithRef> = {
  [ItemEditingModalDialogType.CancelItemScheduledUnpublishConfirmationDialog]:
    CancelScheduleUnpublishConfirmationDialog,
  [ItemEditingModalDialogType.CascadePublishDialog]: CascadePublishModal,
  [ItemEditingModalDialogType.CascadePublishFailedItemsDialog]: CascadePublishFailedItemsModal,
  [ItemEditingModalDialogType.CascadeScheduleDialog]: CascadeScheduleModal,
  [ItemEditingModalDialogType.CascadeScheduleFailedItemsDialog]: CascadeScheduleFailedItemsModal,
  [ItemEditingModalDialogType.CascadeUnpublishConfirmationDialog]:
    CascadeUnpublishConfirmationDialog,
  [ItemEditingModalDialogType.ChangeContentItemCollectionDialog]: MoveItemToCollectionDialog,
  [ItemEditingModalDialogType.ChangeWorkflowDialog]: ChangeWorkflowDialog,
  [ItemEditingModalDialogType.ChangeWorkflowStepDialog]: ChangeWorkflowStepEditingModal,
  [ItemEditingModalDialogType.ContentItemAddTaskDialog]: AddTaskFormDialog,
  [ItemEditingModalDialogType.ContentItemAssignContributorsDialog]: ContributorsFormDialog,
  [ItemEditingModalDialogType.ContentItemCodenameDialog]: CodenameDialog,
  [ItemEditingModalDialogType.ContentItemConfigurePreviewDialog]: NotSetupPreviewDialog,
  [ItemEditingModalDialogType.ContentItemDueDateDialog]: DueDateFormDialog,
  [ItemEditingModalDialogType.ContentItemEditTaskDialog]: EditTaskFormDialog,
  [ItemEditingModalDialogType.ContentItemNoteDialog]: NoteFormDialog,
  [ItemEditingModalDialogType.DeleteItemConfirmationDialog]: ContentItemDeleteConfirmationDialog,
  [ItemEditingModalDialogType.DiscardNewVersionConfirmationDialog]:
    DiscardNewVersionConfirmationDialog,
  [ItemEditingModalDialogType.DuplicateItemWithContent]: DuplicateToCollectionDialog,
  [ItemEditingModalDialogType.DuplicateItemWithoutContent]: DuplicateToCollectionDialog,
  [ItemEditingModalDialogType.MoveItemToArchivedStepConfirmationDialog]:
    MoveItemToArchivedStepConfirmationDialog,
};

function useRenderAnimatedDialog(currentModal: ItemEditingModalDialogType) {
  const previousModal = usePrevious(currentModal);
  const [renderAnimatedDialog, setRenderAnimatedDialog] = useState<AnimatedModals | null>(null);
  useEffect(() => {
    if (!isOldModalDialogType(currentModal) && currentModal !== previousModal) {
      setRenderAnimatedDialog(currentModal);
    }
  }, [currentModal, previousModal]);

  const resetRenderAnimatedDialogOnUnmount = useCallback(
    (element: HTMLDivElement | null) => {
      // on unmount
      if (!element) {
        // skip when switching between 2 animated dialogs
        if (!isOldModalDialogType(currentModal) && currentModal !== previousModal) {
          return;
        }
        setRenderAnimatedDialog(null);
      }
    },
    [currentModal, previousModal],
  );
  return {
    renderAnimatedDialog,
    resetRenderAnimatedDialogOnUnmount,
  };
}

type Props = {
  readonly currentModal: ItemEditingModalDialogType;
};

export const ItemEditingModalDialogSelector: React.FC<Props> = ({ currentModal }) => {
  const OldModalDialog = isOldModalDialogType(currentModal)
    ? oldModalDialogsMap[currentModal]
    : NoDialog;

  const { renderAnimatedDialog, resetRenderAnimatedDialogOnUnmount } =
    useRenderAnimatedDialog(currentModal);

  const AnimatedDialog = renderAnimatedDialog && animatedModalDialogsMap[renderAnimatedDialog];

  return (
    <>
      <OldModalDialog />
      {AnimatedDialog && (
        <AnimatedDialog
          isOpen={renderAnimatedDialog === currentModal}
          ref={resetRenderAnimatedDialogOnUnmount}
        />
      )}
    </>
  );
};
