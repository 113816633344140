import { Action } from '../../../../../../@types/Action.type.ts';
import { ThunkPromise } from '../../../../../../@types/Dispatcher.type.ts';
import { TrackedEvent } from '../../../../../../_shared/constants/trackedEvent.ts';
import { TrackUserEventAction } from '../../../../../../_shared/models/TrackUserEvent.type.ts';
import {
  CommentAction,
  CommentEventData,
} from '../../../../../../_shared/models/events/CommentEventData.type.ts';
import { ICommentRepository } from '../../../../../../repositories/interfaces/ICommentRepository.type.ts';
import {
  ICommentThread,
  createCommentThreadDomainModel,
} from '../../../../models/comments/CommentThreads.ts';
import { ICompiledContentItemElementData } from '../../../../models/contentItemElements/ICompiledContentItemElement.type.ts';

interface IResolveCommentThread {
  readonly commentThreadResolvingStarted: (
    threadId: Uuid,
    threadElementId: Uuid | null | undefined,
    enableUndo: boolean,
  ) => Action;
  readonly commentThreadResolved: (commentThread: ICommentThread, enableUndo: boolean) => Action;
  readonly commentRepository: ICommentRepository;
  readonly trackUserEvent: TrackUserEventAction;
}

function getCommentIntercomEventPayload(
  commentThread: ICommentThread,
  editedContentItemVariantElements: ReadonlyArray<ICompiledContentItemElementData>,
): CommentEventData {
  const commentedElement = editedContentItemVariantElements.find(
    (element) => element.elementId === commentThread.elementId,
  );

  return commentedElement
    ? {
        action: CommentAction.CommentThreadResolved,
        'element-type': commentedElement.type,
      }
    : {
        action: CommentAction.CommentThreadResolved,
      };
}

export const createResolveCommentThread =
  (deps: IResolveCommentThread) =>
  (commentThreadId: Uuid, shouldSendUserEvent: boolean, enableUndo: boolean = true): ThunkPromise =>
  async (dispatch, getState) => {
    const {
      contentApp: {
        editedContentItemVariant,
        editedContentItemVariantComments,
        editedContentItemVariantElements,
      },
    } = getState();

    const threadThread = editedContentItemVariantComments.commentThreads.find(
      (thread: ICommentThread) => thread.id === commentThreadId,
    );
    if (!editedContentItemVariant || !threadThread) {
      return;
    }

    dispatch(
      deps.commentThreadResolvingStarted(commentThreadId, threadThread.elementId, enableUndo),
    );

    const { itemId, variantId } = editedContentItemVariant.id;
    const resolvedThreadServerModel = await deps.commentRepository.markCommentThreadAsResolved(
      itemId,
      variantId,
      commentThreadId,
    );
    const resolvedThread = createCommentThreadDomainModel(resolvedThreadServerModel);

    dispatch(deps.commentThreadResolved(resolvedThread, enableUndo));

    if (shouldSendUserEvent) {
      const eventPayload = getCommentIntercomEventPayload(
        resolvedThread,
        editedContentItemVariantElements,
      );
      dispatch(deps.trackUserEvent(TrackedEvent.Comments, eventPayload));
    }
  };
