import React from 'react';
import { useSelector } from '../../../../../_shared/hooks/useSelector.ts';
import { Task } from '../../../../../_shared/models/Task.ts';
import { getUsersInfo } from '../../../../../_shared/utils/users/usersUtils.ts';
import { PublishingState } from '../../../../../data/constants/PublishingState.ts';
import { WorkflowStepColor } from '../../../../../data/constants/WorkflowStepColor.ts';
import {
  IContentType,
  emptyContentType,
} from '../../../../../data/models/contentModelsApp/contentTypes/ContentType.ts';
import { IListingContentItem } from '../../../../../data/models/listingContentItems/IListingContentItem.ts';
import { emptyListingVariantData } from '../../../../../data/models/listingContentItems/IListingVariantData.ts';
import { WorkflowStepAction } from '../../../../../data/models/workflow/WorkflowStep.ts';
import { emptyAssignment } from '../../../../itemEditor/models/contentItem/Assignment.ts';
import { TaskWidgetDetailTable } from '../components/TaskWidgetDetailTable.tsx';
import { TaskWidgetDetailTableRow } from '../components/TaskWidgetDetailTableRow.tsx';
import { TaskListWidgetDetailProps } from './TaskWidgetDetailTableLiveContainer.tsx';

const listingVariantWidgetData: IListingContentItem = {
  activeCapabilities: {
    variantCapabilities: [],
  },
  highlightedName: null,
  isPublishedVersion: false,
  item: {
    codeName: '',
    id: '',
    collectionId: '',
    spaceIds: [],
    name: 'Why is Kontent.ai the best headless CMS',
    typeId: '',
    archived: false,
  },
  variant: {
    ...emptyListingVariantData,
    publishingState: PublishingState.Published,
    assignment: {
      ...emptyAssignment,
      workflowStatus: {
        id: '',
        workflowId: '',
        name: 'Draft',
        color: WorkflowStepColor.Red,
        action: WorkflowStepAction.NoAction,
        codename: '',
        transitionsTo: new Set(),
      },
    },
  },
  translatedLanguageIds: [],
};

const contentTypeWidgetData: IContentType = {
  ...emptyContentType,
  name: 'Promoted Blogpost',
};

export const TaskWidgetDetailTableDemoContainer: React.FC<TaskListWidgetDetailProps> = ({
  tasks,
  state: tasksState,
}) => {
  const usersById = useSelector((s) => s.data.users.usersById);

  const getContributors = (task: Task) => {
    const userIdentifiers = new Set([...task.assignees].map((userId) => ({ userId })));
    return Array.from(getUsersInfo(userIdentifiers, usersById));
  };

  return (
    <TaskWidgetDetailTable state={tasksState}>
      {tasks.map((task) => (
        <TaskWidgetDetailTableRow
          key={task.id}
          task={task}
          contentItem={listingVariantWidgetData}
          contentType={contentTypeWidgetData}
          contributors={getContributors(task)}
          linkPath={null}
        />
      ))}
    </TaskWidgetDetailTable>
  );
};

TaskWidgetDetailTableDemoContainer.displayName = 'TaskWidgetDetailTableDemoContainer';
