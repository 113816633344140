import { useState } from 'react';
import { useSelector } from '../../../../../../_shared/hooks/useSelector.ts';
import { useIsPreviewUrlOutdated } from '../../../../../webSpotlight/hooks/useIsPreviewUrlOutdated.ts';
import { PreviewApiDataStatus } from '../../../../../webSpotlight/models/PreviewApiDataStatus.ts';
import { getPreviewApiDataStatus } from '../../../../../webSpotlight/selectors/webSpotlightSelectors.ts';
import { PreviewRefreshErrorNotification as PreviewRefreshErrorNotificationComponent } from '../../components/contentItemPreview/PreviewRefreshErrorNotification.tsx';
import { useWebSpotlightInItemEditing } from '../../context/WebSpotlightInItemEditingContext.tsx';

export const PreviewRefreshErrorNotification = () => {
  const previewDataStatus = useSelector(getPreviewApiDataStatus);
  const isPreviewUrlOutdated = useIsPreviewUrlOutdated();
  const { refreshPreview } = useWebSpotlightInItemEditing();

  const [previousPreviewDataStatus, setPreviousPreviewDataStatus] = useState(previewDataStatus);
  const [isVisible, setIsVisible] = useState(false);

  if (
    previewDataStatus === PreviewApiDataStatus.FetchingFailed &&
    previousPreviewDataStatus !== PreviewApiDataStatus.FetchingFailed
  ) {
    setIsVisible(true);
  }

  if (previewDataStatus !== previousPreviewDataStatus) {
    setPreviousPreviewDataStatus(previewDataStatus);
  }

  const onRefreshPreview = () => {
    refreshPreview({
      isManualRefresh: true,
      isPreviewUrlOutdated,
    });
    setIsVisible(false);
  };

  return (
    isVisible && (
      <PreviewRefreshErrorNotificationComponent
        onDismiss={() => setIsVisible(false)}
        onRefresh={onRefreshPreview}
        previewDataStatus={previewDataStatus}
      />
    )
  );
};
