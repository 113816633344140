import { Action } from '../../../../../@types/Action.type.ts';
import { ThunkFunction } from '../../../../../@types/Dispatcher.type.ts';
import { TrackedEvent } from '../../../../../_shared/constants/trackedEvent.ts';
import { TrackUserEventAction } from '../../../../../_shared/models/TrackUserEvent.type.ts';
import { CalendarEventTypes } from '../../../../../_shared/models/TrackUserEventData.ts';
import { CalendarArrangement } from '../../models/CalendarArrangement.ts';

interface IDeps {
  readonly trackUserEvent: TrackUserEventAction;
  readonly onArrangementSelected: (arrangement: CalendarArrangement) => Action;
  readonly calendarArrangementStorage: {
    readonly save: (arrangement: CalendarArrangement) => void;
  };
}

export const createSaveCalendarArrangement =
  (deps: IDeps) =>
  (selectedArrangement: CalendarArrangement): ThunkFunction =>
  (dispatch) => {
    deps.calendarArrangementStorage.save(selectedArrangement);

    dispatch(
      deps.trackUserEvent(TrackedEvent.Calendar, {
        action: CalendarEventTypes.ArrangementSelected,
      }),
    );

    dispatch(deps.onArrangementSelected(selectedArrangement));
  };
