import { Callout } from '@kontent-ai/component-library/Callout';
import { Card } from '@kontent-ai/component-library/Card';
import { Stack } from '@kontent-ai/component-library/Stack';
import { Spacing } from '@kontent-ai/component-library/tokens';
import { isRefinedNavigationFeatureEnabled } from '../../../../refinedNavigation/selectors/isRefinedNavigationFeatureToggleEnabled.ts';
import { FeatureActivationDialog } from '../../components/FeatureActivationDialog.tsx';
import { FeatureDeactivationDialog } from '../../components/FeatureDeactivationDialog.tsx';
import { InnovationLabFeatureButton } from '../../components/InnovationLabFeatureButton.tsx';
import { InnovationLabFeatureCard } from '../../components/InnovationLabFeatureCard.tsx';
import { InnovationLabFeatureGenericallyUnavailable } from '../../components/InnovationLabFeatureGenericallyUnavailable.tsx';
import { InnovationLabStatus } from '../../components/InnovationLabFeatureStatus.tsx';
import { InnovationLabGenericConsent } from '../../components/InnovationLabGenericConsent.tsx';
import { InnovationLabGenericDeactivationWarning } from '../../components/InnovationLabGenericDeactivationWarning.tsx';
import { useFeatureActivationDialog } from '../../hooks/useFeatureActivationDialog.ts';
import { useGetFeatureActivationState } from '../../hooks/useGetFeatureActivationState.ts';
import { activateInnovationLabFeature } from '../../thunks/activateInnovationLabFeature.ts';
import { deactivateInnovationLabFeature } from '../../thunks/deactivateInnovationLabFeature.ts';
import { InnovationLabCardComponent } from '../../types/InnovationLabCardComponent.type.ts';

export const RefinedNavigationCard: InnovationLabCardComponent = ({ featureInfo }) => {
  const activationState = useGetFeatureActivationState(
    RefinedNavigationCard.isFeatureEnabledSelector,
  );

  const {
    dialogDisplayed,
    isAsyncOperationInProgress,
    onCardActionButtonClick,
    onDialogActionClick,
    onDialogClose,
  } = useFeatureActivationDialog(
    featureInfo,
    activationState,
    activateInnovationLabFeature('refined-navigation'),
    deactivateInnovationLabFeature('refined-navigation'),
  );

  const featureName = RefinedNavigationCard.featureName;
  const featureState = featureInfo.status;

  return (
    <>
      <InnovationLabFeatureCard component="section" featureState={featureState}>
        <Card.Headline
          renderAuxiliaryElements={() => <InnovationLabStatus activationState={activationState} />}
        >
          {featureName}
        </Card.Headline>

        <Card.Body>
          <Stack spacing={Spacing.M}>
            <p>
              We’ve streamlined your experience by reworking the main navigation and moving filters
              from the sidebar to a top-level section. Enjoy faster access to what matters most with
              a cleaner, more intuitive layout.
            </p>
            <InnovationLabFeatureGenericallyUnavailable
              activationState={activationState}
              featureState={featureState}
              onContactSupport={() => null}
            />
          </Stack>
        </Card.Body>

        <Card.Footer>
          <InnovationLabFeatureButton
            activationState={activationState}
            featureName={featureName}
            featureState={featureState}
            isAsyncOperationInProgress={isAsyncOperationInProgress}
            onClick={onCardActionButtonClick}
          />
        </Card.Footer>
      </InnovationLabFeatureCard>
      <FeatureActivationDialog
        headline={`Activate ${featureName}`}
        isOpen={dialogDisplayed === 'activation'}
        isActivating={isAsyncOperationInProgress}
        onClose={onDialogClose}
        onActivate={onDialogActionClick}
      >
        <Stack spacing={Spacing.M}>
          <Callout calloutType="quickTip" hideSubheadline>
            <p>
              <b>Innovation Lab feature activation</b>
            </p>
            <p>
              You’re about to activate the {featureName} enhancements. If you deactivate, Refined
              Navigation won’t disappear, but only the enhancements that are being piloted before
              they’re available to everyone.
            </p>
          </Callout>
          <InnovationLabGenericConsent subject="the enhancements" />
        </Stack>
      </FeatureActivationDialog>
      <FeatureDeactivationDialog
        headline={`Deactivate ${featureName}`}
        isOpen={dialogDisplayed === 'deactivation'}
        isDeactivating={isAsyncOperationInProgress}
        onClose={onDialogClose}
        onDeactivate={onDialogActionClick}
      >
        <InnovationLabGenericDeactivationWarning featureName={featureName} />
      </FeatureDeactivationDialog>
    </>
  );
};

RefinedNavigationCard.displayName = 'RefinedNavigationCard';
RefinedNavigationCard.isFeatureEnabledSelector = isRefinedNavigationFeatureEnabled;
RefinedNavigationCard.featureName = 'Refined Navigation';
RefinedNavigationCard.releaseDate = '2025-03-01T00:00:00.000Z';
