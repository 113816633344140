import { memoize } from '@kontent-ai/memoization';
import { forwardRef } from 'react';
import { modalDismissed, modalOpened } from '../../../../../../_shared/actions/sharedActions.ts';
import { IAnimatedModalDialogProps } from '../../../../../../_shared/components/ModalDialog/IAnimatedModalDialogProps.type.ts';
import { ModalDialogType } from '../../../../../../_shared/constants/modalDialogType.ts';
import {
  dueDateChanged,
  hideNotificationBarInChangeWorkflowStepModal,
  noteChanged,
} from '../../../../../../_shared/features/ChangeWorkflowStepModal/actions/changeWorkflowStepModalActions.ts';
import { ChangeWorkflowStepModal as ChangeWorkflowStepModalComponent } from '../../../../../../_shared/features/ChangeWorkflowStepModal/components/ChangeWorkflowStepModal.tsx';
import { getChangeWorkflowStepModalSubmitProps } from '../../../../../../_shared/features/ChangeWorkflowStepModal/containers/selectors/getChangeWorkflowStepModalSubmitProps.ts';
import { getWorkflowStepModalTitle } from '../../../../../../_shared/features/ChangeWorkflowStepModal/containers/selectors/getWorkflowStepModalTitle.ts';
import { isWorkflowStepModalSubmitDisabled } from '../../../../../../_shared/features/ChangeWorkflowStepModal/containers/selectors/isWorkflowStepModalSubmitDisabled.ts';
import { useDispatch } from '../../../../../../_shared/hooks/useDispatch.ts';
import { useSelector } from '../../../../../../_shared/hooks/useSelector.ts';
import { DateTime } from '../../../../../../_shared/models/DateTime.ts';
import { IPublishWarnings } from '../../../../../itemEditor/models/PublishWarnings.ts';
import {
  BrokenLinksInListing,
  BrokenModularsInListing,
  IFriendlyWarning,
} from '../../../../../itemEditor/utils/itemElementFriendlyWarningCheckers/types/FriendlyWarnings.ts';
import { useSelectedContentItemUsedIn } from '../../../hooks/useSelectedContentItemUsedIn.ts';
import {
  publishSelectedContentItems,
  restoreSelectedVariantsFromArchivedStep,
  schedulePublishContentItems,
  scheduleUnpublishContentItems,
  selectWorkflowStepForListing,
  unpublishSelectedContentItems,
  updateSelectedVariantsStandardWorkflowStep,
} from '../actions/thunkContentItemInventoryActions.ts';

const getFriendlyWarningReasonsForListing = memoize.maxOne(
  (publishWarnings: IPublishWarnings): ReadonlyArray<IFriendlyWarning> => {
    const result: Array<IFriendlyWarning> = [];
    if (publishWarnings.itemsWithBrokenModulars.isEmpty()) {
      result.push(BrokenModularsInListing);
    }
    if (publishWarnings.itemsWithBrokenLinks.isEmpty()) {
      result.push(BrokenLinksInListing);
    }

    return result;
  },
);

export const ChangeWorkflowStepListingModal = forwardRef<HTMLDivElement, IAnimatedModalDialogProps>(
  (props, ref) => {
    const publishWarnings = useSelector((s) => s.contentApp.listingUi.publishWarnings);
    const bulkActionCheckResult = useSelector((s) => s.contentApp.listingUi.bulkActionCheckResult);
    const activeUsers = Array<string>();
    const changeWorkflowStepModalData = useSelector(
      (s) => s.contentApp.changeWorkflowStepModalData,
    );
    const friendlyWarningReasons = getFriendlyWarningReasonsForListing(publishWarnings);
    const isSubmitDisabled = useSelector((s) => isWorkflowStepModalSubmitDisabled(s));
    const modalTitle = getWorkflowStepModalTitle(changeWorkflowStepModalData.workflowStepAction);

    const dispatch = useDispatch();
    const onDueDateChange = (dateTime: DateTime) => dispatch(dueDateChanged(dateTime));
    const onNoteChanged = (newNote: string) => dispatch(noteChanged(newNote));
    const onPublish = () => dispatch(publishSelectedContentItems());
    const onScheduledPublish = () => dispatch(schedulePublishContentItems());
    const onUnpublish = () => dispatch(unpublishSelectedContentItems());
    const onScheduledUnpublish = () => dispatch(scheduleUnpublishContentItems());
    const onUpdate = () => dispatch(updateSelectedVariantsStandardWorkflowStep());
    const onRestoreFromArchivedStep = () => dispatch(restoreSelectedVariantsFromArchivedStep());
    const onMoveToArchivedStep = () =>
      dispatch(modalOpened(ModalDialogType.MoveItemsToArchivedStepConfirmationDialog));
    const onCloseModal = () => dispatch(modalDismissed());
    const onHideNotificationBar = () => dispatch(hideNotificationBarInChangeWorkflowStepModal());
    const { workflowId } = changeWorkflowStepModalData.workflowStep;
    const onSelectorValueChanged = (workflowStepId: Uuid) =>
      dispatch(selectWorkflowStepForListing(workflowId, workflowStepId));

    const { isLoading, contentItemUsages, selectedItemIds } = useSelectedContentItemUsedIn();

    const updateSubmitProps = getChangeWorkflowStepModalSubmitProps(
      changeWorkflowStepModalData,
      false,
      isSubmitDisabled,
      false,
      bulkActionCheckResult,
      {
        onMoveToArchivedStep,
        onUpdate,
        onScheduledPublish,
        onPublish,
        onScheduledUnpublish,
        onUnpublish,
        onRestoreFromArchivedStep,
      },
    );

    return (
      <ChangeWorkflowStepModalComponent
        activeUsersNames={activeUsers}
        changeWorkflowStepModalData={changeWorkflowStepModalData}
        friendlyWarningReasons={friendlyWarningReasons}
        modalTitle={modalTitle}
        onCloseModal={onCloseModal}
        onDueDateChange={onDueDateChange}
        onHideNotificationBar={onHideNotificationBar}
        onNoteChanged={onNoteChanged}
        onSelectorValueChanged={onSelectorValueChanged}
        updateSubmitProps={updateSubmitProps}
        itemIds={selectedItemIds}
        contentItemUsages={contentItemUsages}
        isLoading={isLoading}
        ref={ref}
        {...props}
      />
    );
  },
);

ChangeWorkflowStepListingModal.displayName = 'ChangeWorkflowStepListingModal';
