import { Button } from '@kontent-ai/component-library/Button';
import { useObserveElementPresence } from '@kontent-ai/hooks';
import { Loader } from '@storybook/components';
import { useCallback, useEffect, useState } from 'react';
import { createPortal } from 'react-dom';
import { useDataSelector } from '../../../_shared/hooks/useDataSelector.ts';
import { useDispatch } from '../../../_shared/hooks/useDispatch.ts';
import { useSelector } from '../../../_shared/hooks/useSelector.ts';
import { useThunkPromise } from '../../../_shared/hooks/useThunkPromise.ts';
import { LoadingStatus } from '../../../_shared/models/LoadingStatusEnum.ts';
import { SidebarNames } from '../../../_shared/models/SidebarNames.ts';
import { IStore } from '../../../_shared/stores/IStore.type.ts';
import {
  DataUiAction,
  DataUiCollection,
  getDataUiActionAttribute,
  getDataUiCollectionAttribute,
} from '../../../_shared/utils/dataAttributes/DataUiAttributes.ts';
import { IProjectDetails } from '../../../data/models/projects/ProjectDetails.ts';
import {
  areAllSubscriptionsCanceled,
  areAllSubscriptionsExpired,
  getActiveSubscriptions,
  getAdministratedSubscriptions,
} from '../../../data/reducers/subscriptions/selectors/subscriptionSelectors.ts';
import { INewSubscriptionFormShape } from '../../subscriptionManagement/SubscriptionListing/components/NewSubscriptionForm.tsx';
import { SubscriptionFormContextProvider } from '../../subscriptionManagement/shared/components/SubscriptionFormContext.tsx';
import { closeTrialActivationModal, projectCopyStateStop } from '../actions/projectsActions.ts';
import {
  activateTrialSubscription,
  initializeProjectManagement,
} from '../actions/thunkProjectsActions.ts';
import { MyProjectsPage } from '../components/MyProjectsPage.tsx';
import { StartTrialQuickTip } from '../components/StartTrialQuickTip.tsx';
import { TrialActivationModal } from '../components/TrialActivationModal.tsx';
import { IsCreatingANewProject, IsLoadingProjectLocations } from '../constants/errorMessages.ts';
import { editableAppBarCreateButtonPortalId } from '../constants/portalIds.ts';
import { IProjectViewModel, createProjectList } from '../selectors/createProjectList.ts';
import { ExpiredSubscriptionsWarning } from './ExpiredSubscriptionsWarning.tsx';
import { MyProjectsEmptyState } from './MyProjectsEmptyState.tsx';
import { SuspendedSubscriptionsWarning } from './SuspendedSubscriptionsWarning.tsx';
import { MyProject } from './projects/MyProject.tsx';
import { NewProjectModal } from './projects/NewProjectModal.tsx';
import { ProjectDeleteConfirmationDialog } from './projects/ProjectDeleteConfirmationDialog.tsx';

interface IMyProjectsProps {
  readonly projectsToShow: 'active' | 'archived';
}

type CreateModalState =
  | { readonly isOpen: false }
  | { readonly isOpen: true; readonly projectIdToCopy: Uuid | null };

export const MyProjects = (props: IMyProjectsProps) => {
  const [projectIdToDelete, setProjectIdToDelete] = useState<Uuid | null>(null);
  const [createModalState, setCreateModalState] = useState<CreateModalState>({ isOpen: false });

  const dispatch = useDispatch();
  const projectsLoaded = useSelector((s) => s.data.projects.loadingStatus === LoadingStatus.Loaded);
  const projectList = useSelector((s) =>
    createProjectList(s, s.sharedApp.userProperties.projectOrderBy).filter(
      createShouldBeShown(props.projectsToShow),
    ),
  );

  const { current: createButtonPlaceholder } = useObserveElementPresence(
    editableAppBarCreateButtonPortalId,
  );

  useThunkPromise(initializeProjectManagement, true);

  useEffect(() => {
    return () => {
      dispatch(projectCopyStateStop());
    };
  }, []);

  const canCreateNewProject = useSelector((state) => {
    const activeSubscriptions = getActiveSubscriptions(
      getAdministratedSubscriptions(
        state.data.subscriptions.administratedIds,
        state.data.subscriptions.byId,
      ),
    );

    return (
      activeSubscriptions.length > 0 &&
      !areAllSubscriptionsExpired(activeSubscriptions) &&
      !areAllSubscriptionsCanceled(activeSubscriptions)
    );
  });

  const createDisabledTooltip = useSelector((state) =>
    createDisabledCreateTooltip(state, createModalState.isOpen),
  );

  const canHaveTrial = useDataSelector((store) => {
    const hadTrial = store.user.info.hadTrial;
    const hasAnyProject = !store.projects.byId.isEmpty();
    const noExpiredOrSuspendedProjects = store.projects.byId.every(
      (p: IProjectDetails) => !p.planIsExpired && !p.planIsSuspended,
    );
    return !hadTrial && hasAnyProject && noExpiredOrSuspendedProjects;
  });
  const isAzureMarketplaceUser = useSelector(
    (state) => state.sharedApp.userProperties.isAzureMarketplaceUser,
  );
  const onActivateTrial = useCallback(
    async (values: INewSubscriptionFormShape) =>
      dispatch(activateTrialSubscription(values)).then(() =>
        setCreateModalState({ isOpen: true, projectIdToCopy: null }),
      ),
    [],
  );
  const showTrialActivationModal = useSelector((s) => {
    const { isVisible, sidebarName } = s.sharedApp.sidebarStatus;
    return isVisible && sidebarName === SidebarNames.TrialActivationModal;
  });
  const startingTrialInProgress = useSelector((s) => s.projectsApp.activatingTrial);

  if (!projectsLoaded) {
    return <Loader />;
  }

  return (
    <MyProjectsPage>
      {createButtonPlaceholder &&
        canCreateNewProject &&
        createPortal(
          <CreateProjectButton
            disabledTooltip={createDisabledTooltip}
            onCreate={() => setCreateModalState({ isOpen: true, projectIdToCopy: null })}
          />,
          createButtonPlaceholder,
        )}
      <ExpiredSubscriptionsWarning />
      <SuspendedSubscriptionsWarning />

      <div className="my-projects" {...getDataUiCollectionAttribute(DataUiCollection.Projects)}>
        {projectList.map((project) => (
          <MyProject
            key={project.projectId}
            project={project}
            onDeleteProject={() => setProjectIdToDelete(project.projectId)}
            onCloneProject={() =>
              setCreateModalState({ isOpen: true, projectIdToCopy: project.projectId })
            }
          />
        ))}
      </div>
      {props.projectsToShow === 'active' && canHaveTrial && !isAzureMarketplaceUser && (
        <StartTrialQuickTip />
      )}

      <MyProjectsEmptyState
        activeProjectsShown={props.projectsToShow === 'active'}
        onCreateNewProject={() => setCreateModalState({ isOpen: true, projectIdToCopy: null })}
      />
      <SubscriptionFormContextProvider>
        <TrialActivationModal
          onClose={() => dispatch(closeTrialActivationModal())}
          onFormSubmit={onActivateTrial}
          showSidebar={showTrialActivationModal}
          startingTrialInProgress={startingTrialInProgress}
        />
      </SubscriptionFormContextProvider>
      {canCreateNewProject && createModalState.isOpen && (
        <NewProjectModal
          sourceProjectId={createModalState.projectIdToCopy}
          onClose={() => setCreateModalState({ isOpen: false })}
        />
      )}
      {projectIdToDelete && (
        <ProjectDeleteConfirmationDialog
          projectId={projectIdToDelete}
          onClose={() => setProjectIdToDelete(null)}
        />
      )}
    </MyProjectsPage>
  );
};

const createShouldBeShown =
  (shownProjects: 'active' | 'archived') =>
  (project: IProjectViewModel): boolean => {
    if (shownProjects === 'archived') {
      return project.inactive;
    }

    return !project.inactive;
  };

type CreateProjectButtonProps = {
  readonly disabledTooltip: string | null;
  readonly onCreate: () => void;
};

const CreateProjectButton = (props: CreateProjectButtonProps) => (
  <Button
    tooltipText={props.disabledTooltip}
    tooltipPlacement="left"
    buttonStyle="secondary"
    onClick={props.onCreate}
    disabled={!!props.disabledTooltip}
    {...getDataUiActionAttribute(DataUiAction.CreateNew)}
  >
    Create new project
  </Button>
);

const createDisabledCreateTooltip = (state: IStore, isCreating: boolean): string | null => {
  if (isCreating) {
    return IsCreatingANewProject;
  }

  if (!state.sharedApp.availableProjectLocations.length) {
    return IsLoadingProjectLocations;
  }

  return null;
};
