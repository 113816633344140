import { Dispatch, GetState, ThunkPromise } from '../../../../@types/Dispatcher.type.ts';
import { TrackedEvent } from '../../../../_shared/constants/trackedEvent.ts';
import { TrackUserEventAction } from '../../../../_shared/models/TrackUserEvent.type.ts';
import { IProjectRepository } from '../../../../repositories/interfaces/IProjectRepository.type.ts';
import { ProjectUiTransitionState } from '../../types/projectUiTransitionState.ts';
import { waitUntilProjectIsActive } from '../../utils/projectUtils.ts';
import {
  activateProjectFinished,
  activateProjectStarted,
  deactivateProjectFinished,
  deactivateProjectStarted,
} from '../projectsActions.ts';

interface IDeps {
  readonly projectRepository: IProjectRepository;
  readonly trackUserEvent: TrackUserEventAction;
  readonly loadUserProjectsInfo: () => ThunkPromise;
  readonly loadSubscriptionUsage: (subscriptionId: Uuid) => ThunkPromise;
  readonly loadAdministratedSubscriptions: () => ThunkPromise;
  readonly loadProjects: () => ThunkPromise;
}

export const createToggleProjectActiveStateAction =
  (deps: IDeps) =>
  (projectId: Uuid, activate: ProjectUiTransitionState): ThunkPromise =>
  async (dispatch: Dispatch, getState: GetState): Promise<void> => {
    const projects = getState().data.projects.byId;
    const selectedProject = projects.get(projectId);
    if (selectedProject) {
      if (activate === ProjectUiTransitionState.Activating) {
        dispatch(activateProjectStarted(selectedProject.projectId));
      } else if (activate === ProjectUiTransitionState.Deactivating) {
        dispatch(deactivateProjectStarted(selectedProject.projectId));
      } else {
        return;
      }

      const stateAction =
        activate === ProjectUiTransitionState.Activating
          ? deps.projectRepository.activateProject
          : deps.projectRepository.deactivateProject;
      const updatedProject = await stateAction(projectId);

      if (activate === ProjectUiTransitionState.Activating) {
        await waitUntilProjectIsActive(projectId, deps.projectRepository);
      }

      await Promise.all([
        dispatch(deps.loadUserProjectsInfo()),
        dispatch(deps.loadSubscriptionUsage(updatedProject.subscriptionId)),
        dispatch(deps.loadProjects()),
      ]);
      // must stay here for now so that loadUserProjectsInfo() won't overwrite admins in the administrated subscriptions
      // should be fixed by KCP-1473
      await dispatch(deps.loadAdministratedSubscriptions());

      const action =
        activate === ProjectUiTransitionState.Activating
          ? activateProjectFinished
          : deactivateProjectFinished;
      dispatch(action(selectedProject.projectId));

      dispatch(
        deps.trackUserEvent(
          activate === ProjectUiTransitionState.Activating
            ? TrackedEvent.ProjectRestored
            : TrackedEvent.ProjectArchived,
        ),
      );
    }
  };
