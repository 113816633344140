import { Avatar } from '@kontent-ai/component-library/Avatar';
import { ComponentProps } from 'react';
import {
  CommonUser,
  getGravatarUrl,
  getUserInitials,
  getUserName,
  getUserNewColorGradient,
} from '../../utils/users/usersUtils.ts';

type Props = Pick<ComponentProps<typeof Avatar>, 'size' | 'boxShadow'> & {
  readonly user: CommonUser | null;
};

export const UserAvatar = ({ user, size, ...otherProps }: Props) => {
  const gravatarUrl = getGravatarUrl(user?.gravatarHash);

  return (
    <Avatar
      aria-hidden
      backgroundGradient={getUserNewColorGradient(user)}
      image={gravatarUrl}
      initials={getUserInitials(user)}
      label={getUserName(user)}
      size={size}
      {...otherProps}
    />
  );
};
