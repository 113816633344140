import { ReactNode } from 'react';
import { RefinedNavigationContextProvider } from '../../applications/refinedNavigation/contexts/RefinedNavigationContext.tsx';
import { isRefinedNavigationFeatureEnabled } from '../../applications/refinedNavigation/selectors/isRefinedNavigationFeatureToggleEnabled.ts';
import { useSelector } from '../hooks/useSelector.ts';

export const FeatureToggleProviders = ({ children }: { readonly children: ReactNode }) => {
  const isRefinedNavigationEnabled = useSelector(isRefinedNavigationFeatureEnabled);

  return (
    <RefinedNavigationContextProvider
      isRefinedNavigationFeatureEnabled={isRefinedNavigationEnabled}
    >
      {children}
    </RefinedNavigationContextProvider>
  );
};
