import { Box } from '@kontent-ai/component-library/Box';
import { Spacing } from '@kontent-ai/component-library/tokens';
import { Collection, isMap, isSet } from '@kontent-ai/utils';
import React, { useState, useMemo } from 'react';
import { StructuredFeedbackButtons } from '../../../../../../../_shared/features/StructuredFeedback/containers/StructuredFeedbackButtons.tsx';
import {
  AiSearchFeedbackReason,
  createFeedbackReasons,
  getAiFeedbackFeature,
} from '../../../../../../../_shared/features/StructuredFeedback/structuredFeedback.ts';
import { useDispatch } from '../../../../../../../_shared/hooks/useDispatch.ts';
import { useLiveAnnouncer } from '../../../../../../../_shared/hooks/useLiveAnnouncer.ts';
import { useSelector } from '../../../../../../../_shared/hooks/useSelector.ts';
import { TrackingAiActionName } from '../../../../../../../_shared/models/events/AiActionEventData.type.ts';
import { ListingMessage } from '../../../../../../../_shared/uiComponents/ListingMessage/ListingMessage.tsx';
import { getIsAiSearchUsed } from '../../../../../../../paperModels/aiSearch/utils/getIsAiSearchUsed.ts';
import { CancelBulkSchedulePublishConfirmationDialog } from '../../../../components/cancelConfirmation/CancelBulkSchedulePublishConfirmationDialog.tsx';
import { isSomethingSelected } from '../../../../reducers/listingUi/itemsSelection/selectors/itemsSelectionSelectors.ts';
import { makeGetStatusInfo } from '../../../../reducers/listingUi/statusInfo/selectors/statusInfo.ts';
import { StatusMessageAction } from '../../../../stores/IContentAppStoreState.ts';
import {
  undoDeleteContentItems,
  undoPublishContentItems,
  undoSchedulePublishContentItems,
} from '../../actions/thunkContentItemInventoryActions.ts';

type Props = {
  readonly announceMoreActionStatus?: boolean;
};

const getStatusInfo = makeGetStatusInfo({
  undoDeleteContentItems,
  undoPublishContentItems,
  undoSchedulePublishContentItems,
});

const replaceCollections = (_: any, value: any) => {
  if (isSet(value)) {
    return [...value];
  }
  if (isMap(value)) {
    return Collection.getEntries(value);
  }

  return value;
};

// makes sure AI feedback is reset (not disabled, can be submitted again) after any change to filter
const useAiFeedbackFormResetKey = () => {
  const filtersState = useSelector((s) => s.contentApp.listingUi.filter);
  return useMemo(() => JSON.stringify(filtersState, replaceCollections), [filtersState]);
};

export const ContentItemScrollTableTitle: React.FC<Props> = ({ announceMoreActionStatus }) => {
  const lastActionType = useSelector(
    (state) => state.contentApp.listingUi.statusInfo.lastAction.lastActionType,
  );
  const statusInfoMessage = useSelector(getStatusInfo);
  const { undo } = statusInfoMessage;

  const isAnyContentItemSelected = useSelector(isSomethingSelected);
  const announcerMessage = `${statusInfoMessage.text}, ${undo ? `${undo.undoText} action available` : ''} ${isAnyContentItemSelected && announceMoreActionStatus ? 'more actions button available' : ''}`;
  useLiveAnnouncer(announcerMessage, 'polite');

  const [isUndoConfirmationDialogOpen, setIsUndoConfirmationDialogOpen] = useState(false);

  const dispatch = useDispatch();
  const undoAction = undo && (() => dispatch(undo.undoFn()));

  const onUndo = undoAction
    ? () => {
        if (lastActionType === StatusMessageAction.ScheduledToPublish) {
          setIsUndoConfirmationDialogOpen(true);
        } else {
          undoAction();
        }
      }
    : null;

  const showAiSearchFeedback = useSelector((s) => getIsAiSearchUsed(s.contentApp.listingUi.filter));
  const aiSearchFeedbackResetKey = useAiFeedbackFormResetKey();

  return (
    <>
      <ListingMessage
        onUndo={onUndo}
        statusInfoMessage={statusInfoMessage}
        undoButtonText={undo?.undoText}
      >
        {showAiSearchFeedback && (
          <Box paddingLeft={Spacing.L}>
            <StructuredFeedbackButtons
              key={aiSearchFeedbackResetKey}
              label="How relevant are the search results?"
              feature={getAiFeedbackFeature(TrackingAiActionName.AiSearch)}
              feedbackReasons={createFeedbackReasons([
                AiSearchFeedbackReason.TooManyResults,
                AiSearchFeedbackReason.ResultsAreIrrelevant,
                AiSearchFeedbackReason.FiltersWereNotEffective,
                AiSearchFeedbackReason.ICouldNotFindTheResult,
              ])}
            />
          </Box>
        )}
      </ListingMessage>
      <CancelBulkSchedulePublishConfirmationDialog
        isOpen={
          lastActionType === StatusMessageAction.ScheduledToPublish &&
          isUndoConfirmationDialogOpen &&
          !!undoAction
        }
        onCancel={() => setIsUndoConfirmationDialogOpen(false)}
        onConfirm={() => undoAction?.()}
      />
    </>
  );
};
