import { IBaseSelectItem } from '@kontent-ai/component-library/Selects';
import { Collection } from '@kontent-ai/utils';
import { useMemo } from 'react';
import { useSelector } from '../../../../../_shared/hooks/useSelector.ts';
import { areCollectionsVisibleForSpaces } from '../../../../../_shared/selectors/contentCollections.ts';
import { DataUiCollection } from '../../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';
import { Capability } from '../../../../../_shared/utils/permissions/capability.ts';
import { filterCollectionsByCapability } from '../../../../../_shared/utils/permissions/filterCollectionsByCapability.ts';
import { IUser } from '../../../../../data/reducers/user/IUser.type.ts';
import { getCurrentProject } from '../../../../../data/reducers/user/selectors/userProjectsInfoSelectors.ts';
import { ListingFilterCategorySelector } from '../../../../contentInventory/content/features/ListingFilter/components/ListingFilterCategorySelector.tsx';
import { spacesContainCollection } from '../../../../environmentSettings/spaces/utils/spacesUtils.ts';

type Props = {
  readonly onSpaceSelectionChanged: (ids: ReadonlySet<Uuid>) => void;
  readonly selectedSpaces: ReadonlySet<Uuid>;
  readonly user: IUser;
};

export const SpacesFilterSelector = (props: Props) => {
  const collectionsById = useSelector((state) => state.data.collections.byId);
  const { projectId } = useSelector(getCurrentProject);
  const spacesById = useSelector((state) => state.data.spaces.byId);
  const spaces = useMemo(() => Collection.getValues(spacesById), [spacesById]);

  const availableCollections = useMemo(
    () =>
      filterCollectionsByCapability(Capability.ViewContent, collectionsById, projectId, props.user),
    [collectionsById, projectId, props.user],
  );

  const areAllCollectionsAvailable = availableCollections.length === collectionsById.size;
  const availableSpaces = useMemo(
    () =>
      areAllCollectionsAvailable
        ? spaces
        : spacesContainCollection(
            spaces,
            availableCollections.map((collection) => collection.id),
          ),
    [spaces, availableCollections, areAllCollectionsAvailable],
  );

  const showFilter = useSelector(
    (state) =>
      areCollectionsVisibleForSpaces(state, Collection.getValues(state.data.collections.byId)) &&
      !!availableSpaces.length,
  );

  const selectedOptionIds: ReadonlySet<Uuid> = useMemo(
    () =>
      new Set(
        availableSpaces.filter((space) => props.selectedSpaces.has(space.id)).map((c) => c.id),
      ),
    [props.selectedSpaces, availableSpaces],
  );

  const options: ReadonlyArray<IBaseSelectItem> = availableSpaces.map((space) => ({
    id: space.id,
    label: space.name,
  }));

  return showFilter ? (
    <ListingFilterCategorySelector
      options={options}
      onChange={props.onSpaceSelectionChanged}
      collection={DataUiCollection.Spaces}
      placeholder="Select a space"
      selectedOptionIds={selectedOptionIds}
      title="Space"
    />
  ) : null;
};
