import { ProjectOrderBy } from '../../../_shared/constants/projectOrderBy.ts';
import {
  EnvironmentRouteParams,
  EnvironmentSettingsRoute,
  ProjectRouteParams,
  ProjectSettingsRoute,
} from '../../../_shared/constants/routePaths.ts';
import { IProjectLocation } from '../../../_shared/models/ProjectLocation.ts';
import { IStore } from '../../../_shared/stores/IStore.type.ts';
import { buildQueryString } from '../../../_shared/utils/routing/queryParamsUtils.ts';
import { buildPath } from '../../../_shared/utils/routing/routeTransitionUtils.ts';
import {
  formatUserName,
  getCustomizedPeopleList,
  isAdmin,
} from '../../../_shared/utils/users/usersUtils.ts';
import {
  IProjectDetails,
  IProjectDetailsUserRole,
} from '../../../data/models/projects/ProjectDetails.ts';
import { ICurrentUserInfo } from '../../../data/models/user/CurrentUserInfo.ts';
import { getProjectsForListing } from '../../../data/reducers/projects/selectors/getProjectsForListing.ts';
import {
  getSubscriptionActiveProjectsCount,
  getSubscriptionPlan,
} from '../../../data/reducers/subscriptions/selectors/subscriptionSelectors.ts';
import { EnvironmentUiState } from '../../projectSettings/environments/types/EnvironmentUiState.ts';
import { getEnvironmentsCount } from '../../projectSettings/environments/utils/environmentsUtils.ts';
import { CopyState } from '../constants/copyState.ts';
import { ProjectUiTransitionState } from '../types/projectUiTransitionState.ts';

export interface IUserListItem {
  readonly isMe: boolean;
  readonly name: string;
  readonly suffix: string;
}

export interface IProjectViewModel {
  readonly activeLanguagesCount: number;
  readonly activeProjectContainerUsersCount: number;
  readonly copyState: CopyState;
  readonly copyType: string;
  readonly environmentName: string;
  readonly environmentsCount: number;
  readonly inactive: boolean;
  readonly isAdminOnProject: boolean;
  readonly isBeingDeleted: boolean;
  readonly masterEnvironmentId: Uuid;
  readonly planIsExpired: boolean;
  readonly planIsSuspended: boolean;
  readonly projectContainerId: Uuid;
  readonly projectId: Uuid;
  readonly projectLocationName: string;
  readonly projectManagers: ReadonlyArray<IUserListItem>;
  readonly projectName: string;
  readonly roles: ReadonlyArray<IProjectDetailsUserRole>;
  readonly subscriptionId: Uuid;
  readonly subscriptionName: string;
  readonly subscriptionProjectsCount: number;
  readonly subscriptionProjectsLimit: number | null;
  readonly uiTransitionState: ProjectUiTransitionState | null;
  readonly url: string;
}

const getUserListItem = (name: string, isMe: boolean, suffix: string): IUserListItem => ({
  isMe,
  name,
  suffix,
});

const getIsAdminSuffix = (project: IProjectDetails, userId: UserId): string =>
  isAdmin(project.subscriptionAdmins, userId) ? '(admin)' : '';

const getProjectManagers = (
  project: IProjectDetails,
  userInfo: ICurrentUserInfo,
): ReadonlyArray<IUserListItem> =>
  getCustomizedPeopleList(
    project.projectManagers,
    userInfo.userId,
    (u) => getUserListItem('You', true, getIsAdminSuffix(project, u.userId)),
    (u) => getUserListItem(formatUserName(u), false, getIsAdminSuffix(project, u.userId)),
  );

export const createProjectList = (
  state: IStore,
  orderBy: ProjectOrderBy,
): ReadonlyArray<IProjectViewModel> => {
  const {
    data: { user, projects },
    sharedApp: { availableProjectLocations },
    subscriptionApp: { selectedSubscriptionId },
    projectsApp: { projectsUiTransitionState },
  } = state;

  const sortedProjects = getProjectsForListing(projects.byId, orderBy, selectedSubscriptionId);

  const result = sortedProjects.map((project: IProjectDetails): IProjectViewModel => {
    const {
      copyState,
      copyType,
      environmentName,
      inactive,
      masterEnvironmentId,
      planIsExpired,
      planIsSuspended,
      projectContainerId,
      projectId,
      projectName,
      roles,
      subscriptionId,
      subscriptionName,
    } = project;

    const uiTransitionState = projectsUiTransitionState.get(project.projectId) ?? null;
    const projectLocation = availableProjectLocations.find(
      (x: IProjectLocation) => x.projectLocationId === project.projectLocationId,
    );
    const projectLocationName = projectLocation ? projectLocation.projectLocationName : '';
    const subscriptionPlan = getSubscriptionPlan(state, subscriptionId);
    const subscriptionProjectsLimit = subscriptionPlan.features.maxSubscriptionProjects;
    const subscriptionProjectsCount = getSubscriptionActiveProjectsCount(state, subscriptionId);

    const projectState = state.environmentsApp.environmentUiStates.get(project.projectId);

    const url = selectedSubscriptionId
      ? buildPath<EnvironmentRouteParams>(EnvironmentSettingsRoute, { projectId }) +
        buildQueryString({ redirect_to_default: 'true' })
      : buildPath<ProjectRouteParams>(ProjectSettingsRoute, { projectContainerId });

    return {
      activeLanguagesCount: inactive ? 0 : project.activeLanguagesCount,
      activeProjectContainerUsersCount: inactive ? 0 : project.activeProjectContainerUsersCount,
      inactive,
      isAdminOnProject: isAdmin(project.subscriptionAdmins, user.info.userId),
      planIsExpired,
      planIsSuspended,
      projectContainerId,
      projectId,
      projectLocationName,
      projectManagers: getProjectManagers(project, user.info),
      projectName,
      masterEnvironmentId,
      environmentName,
      environmentsCount: getEnvironmentsCount(state, projectId),
      roles,
      subscriptionId,
      subscriptionName,
      uiTransitionState,
      url,
      copyState,
      copyType,
      isBeingDeleted: projectState === EnvironmentUiState.Deleting,
      subscriptionProjectsLimit,
      subscriptionProjectsCount,
    };
  });

  return result;
};
