import { Collection } from '@kontent-ai/utils';
import { ThunkPromise } from '../../../../../../../@types/Dispatcher.type.ts';
import { LoadingStatus } from '../../../../../../../_shared/models/LoadingStatusEnum.ts';
import { IListingFilter } from '../../../../models/filter/IListingFilter.ts';
import { listingFilterChanged } from '../contentItemInventoryActions.ts';
import { TrackFilterChange } from './loadListingItems/trackFilterChange.ts';

interface IDeps {
  readonly loadListingContentItems: () => ThunkPromise;
  readonly trackFilterChange: TrackFilterChange;
}

export const createFilterContentItemsInDialogAction = (deps: IDeps) => {
  // There is no stored state for the dialog filter, so we keep track of the last one for tracking here
  let lastTrackedFilterState: IListingFilter | null = null;

  return (): ThunkPromise => async (dispatch, getState) => {
    const {
      data: {
        workflows,
        listingContentItems: { loadingStatus },
      },
      contentApp: {
        listingUi: { filter, savedFiltersUi },
      },
    } = getState();

    if (loadingStatus === LoadingStatus.InitialEmpty) {
      // We don't want to track differences between filters from various dialogs
      // so we reset the last filter upon new dialog load
      lastTrackedFilterState = filter;
    }

    dispatch(listingFilterChanged());

    const previousFilter = savedFiltersUi.lastFilteredUsingSavedFilter
      ? null
      : lastTrackedFilterState;

    dispatch(deps.trackFilterChange(previousFilter, filter, Collection.getValues(workflows.byId)));

    lastTrackedFilterState = filter;

    await dispatch(deps.loadListingContentItems());
  };
};
