import React from 'react';
import { isRevisionCurrent } from '../../../../applications/itemEditor/features/Revisions/utils/revisionUtils.ts';
import { ContentItemRevisionTitle as ContentItemRevisionTitleComponent } from '../../../components/StatusBar/revisions/ContentItemRevisionTitle.tsx';
import { useSelector } from '../../../hooks/useSelector.ts';
import { canRestoreRevision } from '../../../selectors/StatusBar/revisions/revisionSelectors.ts';
import { formatCurrentUserName } from '../../../utils/users/usersUtils.ts';

export const ContentItemRevisionTitle: React.FC = () => {
  const canRestore = useSelector(canRestoreRevision);

  const lastModifiedDate = useSelector(
    ({ contentApp: { selectedRevision } }) => selectedRevision?.lastModified ?? null,
  );

  const lastModifiedByFullName = useSelector(
    ({ contentApp: { selectedRevision }, data: { user, users } }) => {
      const lastModifiedBy =
        selectedRevision && users.usersById.get(selectedRevision.lastModifiedBy);
      return lastModifiedBy ? formatCurrentUserName(user.info.userId, false)(lastModifiedBy) : null;
    },
  );

  const isCurrent = useSelector(({ contentApp: { entryTimeline, selectedRevision } }) =>
    isRevisionCurrent(entryTimeline, selectedRevision),
  );

  return (
    <ContentItemRevisionTitleComponent
      canRestoreRevision={canRestore}
      date={lastModifiedDate}
      fullName={lastModifiedByFullName}
      isCurrent={isCurrent}
    />
  );
};
