import { Box } from '@kontent-ai/component-library/Box';
import { Label, LabelSize } from '@kontent-ai/component-library/Label';
import { SingleSelect } from '@kontent-ai/component-library/SingleSelect';
import { Spacing } from '@kontent-ai/component-library/tokens';
import React from 'react';
import { TimeInterval, timeIntervals } from '../../../constants/timeIntervals.ts';
import { PublishedItemsFilterModel } from '../types/PublishedItemsFilterModel.type.ts';

type PublishedItemsTimeIntervalSelectorProps = React.PropsWithChildren &
  Readonly<{
    filter: PublishedItemsFilterModel;
    setFilter: React.Dispatch<React.SetStateAction<PublishedItemsFilterModel>>;
  }>;

export const PublishedItemsTimeIntervalSelector = ({
  filter,
  setFilter,
}: PublishedItemsTimeIntervalSelectorProps) => {
  return (
    <>
      <Box>
        <Label size={LabelSize.L}>Time frame</Label>
      </Box>
      <Box paddingTop={Spacing.S}>
        <SingleSelect
          aria-label="Select timeframe"
          items={timeIntervals}
          selectedItemId={filter.timeInterval}
          onSelectionChange={(interval: TimeInterval) => {
            setFilter({
              ...filter,
              timeInterval: interval,
            });
          }}
        />
      </Box>
    </>
  );
};
