import { isAbortError } from '@kontent-ai/errors';
import { ThunkPromise } from '../../../../../@types/Dispatcher.type.ts';
import { TrackedEvent } from '../../../../../_shared/constants/trackedEvent.ts';
import { TrackUserEventAction } from '../../../../../_shared/models/TrackUserEvent.type.ts';
import { IProjectSpecificStorage } from '../../../../../localStorages/projectSpecificStorage.ts';
import { IRelationsConfigStorage } from '../../../../../localStorages/relationsConfigStorage.ts';
import { IRelationRepository } from '../../../../../repositories/interfaces/IRelationRepository.type.ts';
import {
  Relations_SetDefaultRoot_Failed,
  Relations_SetDefaultRoot_Finished,
  Relations_SetDefaultRoot_Started,
} from '../../constants/relationsActionTypes.ts';
import { UnableToSetProjectRootErrorMessage } from '../../constants/uiConstants.ts';

interface IDeps {
  readonly relationsConfigStorage: IProjectSpecificStorage<IRelationsConfigStorage>;
  readonly relationRepository: IRelationRepository;
  readonly trackUserEvent: TrackUserEventAction;
}

interface IParams {
  readonly itemId: Uuid;
}

const started = () =>
  ({
    type: Relations_SetDefaultRoot_Started,
  }) as const;

const finished = (itemId: Uuid) =>
  ({
    type: Relations_SetDefaultRoot_Finished,
    payload: {
      itemId,
    },
  }) as const;

const failed = (errorMessage: string) =>
  ({
    type: Relations_SetDefaultRoot_Failed,
    payload: {
      errorMessage,
    },
  }) as const;

export type SetDefaultRelationsRootActionsType = ReturnType<
  typeof started | typeof finished | typeof failed
>;

export const setDefaultRelationsRootActionCreator =
  (deps: IDeps) =>
  (params: IParams, abortSignal?: AbortSignal): ThunkPromise =>
  async (dispatch, getState) => {
    const {
      sharedApp: { currentProjectId },
    } = getState();

    if (!params.itemId) {
      throw new Error('setDefaultRelationsRoot.ts: item is not selected.');
    }

    dispatch(started());

    try {
      await deps.relationRepository.setDefaultRootItemId(params.itemId, abortSignal);

      deps.relationsConfigStorage.save(
        {
          rootId: null,
        },
        currentProjectId,
      );

      dispatch(finished(params.itemId));
      dispatch(deps.trackUserEvent(TrackedEvent.RelationsTabDefaultRootItemChanged));
    } catch (error) {
      if (!isAbortError(error)) {
        dispatch(failed(UnableToSetProjectRootErrorMessage));
      }

      throw error;
    }
  };
