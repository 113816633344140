import { ThunkFunction } from '../../../../../../@types/Dispatcher.type.ts';
import { trackUserEvent } from '../../../../../../_shared/actions/thunks/trackUserEvent.ts';
import { TrackedEvent } from '../../../../../../_shared/constants/trackedEvent.ts';
import { ContentItemEditingTriggerDialogAction } from '../../../../../../_shared/models/events/ContentItemEditingEventData.type.ts';
import { itemEditingModalWithPropertiesOpened } from '../../../../actions/contentActions.ts';
import { ContentItemEditingModalProperties } from '../../../../types/ItemEditingModalDialogProperties.type.ts';
import { ContentItemSidebarEditableSection } from '../../constants/ContentItemSidebarEditableSection.ts';

type ActionType =
  | ContentItemEditingTriggerDialogAction.ChangeDueDateSelected
  | ContentItemEditingTriggerDialogAction.AssignContributorsSelected
  | ContentItemEditingTriggerDialogAction.NoteSelected
  | ContentItemEditingTriggerDialogAction.TasksSelected;

const sectionToAction: ReadonlyRecord<ContentItemSidebarEditableSection, ActionType> = {
  [ContentItemSidebarEditableSection.Contributors]:
    ContentItemEditingTriggerDialogAction.AssignContributorsSelected,
  [ContentItemSidebarEditableSection.DueDate]:
    ContentItemEditingTriggerDialogAction.ChangeDueDateSelected,
  [ContentItemSidebarEditableSection.Note]: ContentItemEditingTriggerDialogAction.NoteSelected,
  [ContentItemSidebarEditableSection.Tasks]: ContentItemEditingTriggerDialogAction.TasksSelected,
};

type IParams = {
  readonly defaultFocus: ContentItemSidebarEditableSection;
};

export const createOpenItemDetailsWithFocusedSection =
  ({ defaultFocus }: IParams) =>
  (
    origin: ContentItemEditingModalProperties['actionOrigin'],
    dialogType: ContentItemEditingModalProperties['dialogType'],
  ): ThunkFunction =>
  (dispatch): void => {
    dispatch(itemEditingModalWithPropertiesOpened({ dialogType, actionOrigin: origin }));
    dispatch(
      trackUserEvent(TrackedEvent.ContentItemEditing, {
        action: sectionToAction[defaultFocus],
        origin,
      }),
    );
  };
