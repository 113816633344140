import { useCallback } from 'react';
// eslint-disable-next-line @typescript-eslint/no-restricted-imports
import { useSelector as _useSelector } from 'react-redux';
import { IStore } from '../stores/IStore.type.ts';

export function useDataSelector<TReturn>(
  selector: (state: IStore['data']) => TReturn,
): Readonly<TReturn> {
  const dataSelector = useCallback((state: IStore): TReturn => selector(state.data), [selector]);
  return _useSelector<IStore, TReturn>(dataSelector, {
    devModeChecks: { stabilityCheck: 'never' },
  });
}
