export const People_IntercomChatWindowOpened = 'People_IntercomChatWindowOpened';
export const Project_TrialActivation_Show = 'Project_TrialActivation_Show';
export const Project_TrialActivation_Close = 'Project_TrialActivation_Close';
export const Project_CreateSampleProject_Failed = 'Project_CreateSampleProject_Failed';
export const Project_CreateSampleProject_Started = 'Project_CreateSampleProject_Started';
export const Project_CreateSampleProject_Finished = 'Project_CreateSampleProject_Finished';
export const Project_CloneProject_Failed = 'Project_CloneProject_Failed';
export const Project_CloneProject_Finished = 'Project_CloneProject_Finished';
export const Project_CloneProject_Started = 'Project_CloneProject_Started';
export const Project_CloneProjectValidation_Failed = 'Project_CloneProjectValidation_Failed';
export const Project_CopyState_Populate = 'Project_CopyState_Populate';
export const Project_CopyState_Start = 'Project_CopyState_Start';
export const Project_CopyState_Updated = 'Project_CopyState_Updated';
export const Project_CopyState_Finished = 'Project_CopyState_Finished';
export const Project_CopyState_Stop = 'Project_CopyState_Stop';
export const Project_Delete_Failed = 'Project_Delete_Failed';
export const Project_Delete_Finished = 'Project_Delete_Finished';
export const Project_Delete_Started = 'Project_Delete_Started';
export const Project_Template_Finished = 'Project_Template_Finished';

export const Project_NewProject_Failed = 'Project_NewProject_Failed';
export const Project_NewProject_Finished = 'Project_NewProject_Finished';
export const Project_NewProject_Started = 'Project_NewProject_Started';
export const ProjectListing_ActivateProject_Finished = 'ProjectListing_ActivateProject_Finished';
export const ProjectListing_ActivateProject_Started = 'ProjectListing_ActivateProject_Started';
export const ProjectListing_ProjectCreation_Initialized =
  'ProjectListing_ProjectCreation_Initialized';
export const ProjectListing_ProjectCloning_Initialized =
  'ProjectListing_ProjectCloning_Initialized';
export const ProjectListing_DeactivateProject_Finished =
  'ProjectListing_DeactivateProject_Finished';
export const ProjectListing_DeactivateProject_Started = 'ProjectListing_DeactivateProject_Started';
export const ProjectListing_Init = 'ProjectListing_Init';
export const Subscription_ActivateTrial_Failed = 'Subscription_ActivateTrial_Failed';
export const Subscription_ActivateTrial_Finished = 'Subscription_ActivateTrial_Finished';
export const Subscription_ActivateTrial_Started = 'Subscription_ActivateTrial_Started';

export const Project_ProjectContext_Left = 'Project_ProjectContext_Left';
export const ProjectApp_RouteEntered = 'ProjectApp_RouteEntered';
export const ProjectApp_RouteLeft = 'ProjectApp_RouteLeft';
