import { Box } from '@kontent-ai/component-library/Box';
import { Label, LabelSize } from '@kontent-ai/component-library/Label';
import { MultiSelect } from '@kontent-ai/component-library/MultiSelect';
import { IBaseSelectItem } from '@kontent-ai/component-library/Selects';
import { Spacing } from '@kontent-ai/component-library/tokens';
import React from 'react';
import {
  DataUiCollection,
  getDataUiCollectionAttribute,
} from '../../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';
import { PublishedItemsFilterModel } from '../types/PublishedItemsFilterModel.type.ts';

type PublishedItemsLanguageSelectorProps = React.PropsWithChildren &
  Readonly<{
    availableLanguages: ReadonlyArray<IBaseSelectItem>;
    filter: PublishedItemsFilterModel;
    setFilter: React.Dispatch<React.SetStateAction<PublishedItemsFilterModel>>;
  }>;

export const PublishedItemsLanguageSelector = ({
  availableLanguages,
  filter,
  setFilter,
}: PublishedItemsLanguageSelectorProps) => {
  return (
    <>
      <Box>
        <Label size={LabelSize.L}>Language</Label>
      </Box>
      <Box paddingTop={Spacing.S}>
        <MultiSelect
          aria-label="Select a language"
          items={availableLanguages}
          onSelectionChange={(languageIds: ReadonlySet<string>) => {
            setFilter({
              ...filter,
              languageIds: languageIds.size > 0 ? Array.from(languageIds) : null,
            });
          }}
          placeholder="Select a language"
          selectedItemIds={filter.languageIds}
          verticalMenuDataAttributes={getDataUiCollectionAttribute(DataUiCollection.LanguageList)}
          {...getDataUiCollectionAttribute(DataUiCollection.LanguageList)}
        />
      </Box>
    </>
  );
};
