import { IStore } from '../../../../../../_shared/stores/IStore.type.ts';
import { getContentItemPreviewUrlPattern } from '../../../../utils/previewUtils.ts';

export const getPreviewUrlPattern = ({
  contentApp: { previewConfiguration, editedContentItem },
}: IStore): string | undefined => {
  const contentTypeId = editedContentItem?.editedContentItemTypeId;

  return contentTypeId && previewConfiguration
    ? getContentItemPreviewUrlPattern(contentTypeId, previewConfiguration, null)
    : undefined;
};
