import { onboardingNotificationTriggered } from '../../../../../../_shared/actions/thunkSharedActions.ts';
import { trackUserEvent } from '../../../../../../_shared/actions/thunks/trackUserEvent.ts';
import { ContentItemFilterOrigin } from '../../../../../../_shared/models/events/ContentItemFilterEventData.type.ts';
import { repositoryCollection } from '../../../../../../_shared/repositories/repositories.ts';
import {
  loadCollections,
  loadContentTypeAsComponentUsage,
  loadContentTypes,
  loadListingContentItems,
  loadSitemap,
  loadSpaces,
  loadTaxonomyGroups,
  loadUsers,
  loadWorkflows,
} from '../../../../../../data/actions/thunkDataActions.ts';
import { contentListingColumnsFilterStorage } from '../../../../../../localStorages/contentListingColumnsFilterStorage.ts';
import { fullTextSearchContextStorage } from '../../../../../../localStorages/fullTextSearchContextStorage.ts';
import { getFilterStorage } from '../../../../../../localStorages/getFilterStorage.ts';
import { listingOrderByStorage } from '../../../../../../localStorages/listingOrderByStorage.ts';
import { pickSearchAction } from '../../../../../../paperModels/aiSearch/actions/pickSearchAction.ts';
import { aiSearchLoadListingContentItemsForInventory } from '../../../../../../paperModels/aiSearch/actions/thunkAiSearchActions.ts';
import { loadListingItems } from '../../../../../itemEditor/features/LoadedItems/actions/thunkLoadedItemsActions.ts';
import { convertAssignmentToUpdateServerModel } from '../../../../../itemEditor/models/contentItem/Assignment.ts';
import { cancelScheduledPublishSelectedContentItemsActionCreator } from './thunks/bulkActions/cancelScheduledPublishSelectedContentItems.ts';
import { createCheckPublishWarningsAction } from './thunks/bulkActions/checkPublishWarnings.ts';
import { deleteSelectedContentItemsActionCreator } from './thunks/bulkActions/deleteSelectedContentItems.ts';
import { createMoveSelectedContentItemsToCollectionAction } from './thunks/bulkActions/moveSelectedContentItemsToCollection.ts';
import { createMoveSelectedVariantsToArchivedStepWorkflowAction } from './thunks/bulkActions/moveSelectedVariantsToArchivedStep.ts';
import { openBulkChangeWorkflowStepModal } from './thunks/bulkActions/openBulkChangeWorkflowStepModal.ts';
import { createOpenBulkPublishModalAction } from './thunks/bulkActions/openBulkPublishModal.ts';
import { publishSelectedContentItemsActionCreator } from './thunks/bulkActions/publishSelectedContentItems.ts';
import { createRestoreSelectedVariantsFromArchivedStepWorkflowAction } from './thunks/bulkActions/restoreSelectedVariantsFromArchivedStep.ts';
import { schedulePublishSelectedContentItemsActionCreator } from './thunks/bulkActions/schedulePublishSelectedContentItems.ts';
import { scheduleUnpublishSelectedContentItemsActionCreator } from './thunks/bulkActions/scheduleUnpublishSelectedContentItems.ts';
import { undoDeleteContentItemsActionCreator } from './thunks/bulkActions/undoDeleteContentItems.ts';
import { undoPublishContentItemsActionCreator } from './thunks/bulkActions/undoPublishContentItems.ts';
import { undoSchedulePublishContentItemsActionCreator } from './thunks/bulkActions/undoScheduleContentItems.ts';
import { unpublishSelectedContentItemsActionCreator } from './thunks/bulkActions/unpublishSelectedContentItems.ts';
import { createUpdateSelectedVariantsStandardWorkflowStepAction } from './thunks/bulkActions/updateSelectedVariantsStandardWorkflowStep.ts';
import {
  CreateUpdateWorkflowStepEventData,
  IUpdateWorkflowStep,
  createUpdateSelectedVariantsWorkflowStepAction,
} from './thunks/bulkActions/updateSelectedVariantsWorkflowStep.ts';
import { createClearContentItemListingFilterAction } from './thunks/clearContentItemListingFilter.ts';
import { createFilterItemsWithContentTypeAsComponentUsage } from './thunks/createFilterItemsWithContentTypeAsComponentUsage.ts';
import { createEnsureInventoryRelatedDataAction } from './thunks/ensureInventoryRelatedData.ts';
import { createFilterContentItemsAction } from './thunks/filterContentItems.ts';
import { createFilterContentItemsInDialogAction } from './thunks/filterContentItemsInDialog.ts';
import { createHandleFullTextSearchTurnedOffAction } from './thunks/fullTextSearch/handleFullTextSearchTurnedOff.ts';
import { createHandleFullTextSearchTurnedOnAction } from './thunks/fullTextSearch/handleFullTextSearchTurnedOn.ts';
import { createInitializeContentListingAction } from './thunks/initializeContentListing.ts';
import {
  createLoadListingContentItemsForContentTypesAction,
  createLoadListingContentItemsForInventoryAction,
} from './thunks/loadListingItems/loadListingContentItems.ts';
import { createTrackFilterChangeAction } from './thunks/loadListingItems/trackFilterChange.ts';
import { createLoadOperationsStatuses } from './thunks/loadOperationsStatuses.ts';
import { createLoadSelectedContentItemUsage } from './thunks/loadSelectedContentItemUsage.ts';
import { createOrderContentItemsAction } from './thunks/orderContentItems.ts';
import { resetFullTextSortingActionCreator } from './thunks/resetFullTextSorting.ts';
import { setContentItemSelectionActionCreator } from './thunks/selectContentItems.ts';
import { createSelectWorkflowStepForListingAction } from './thunks/selectWorkflowStepForListing.ts';
import { createUpdateColumnFilterAction } from './thunks/updateColumnFilter.ts';
import { createUpdateFilterSearchPhraseAction } from './thunks/updateFilterSearchPhrase.ts';
import { createUpdateListingOrderByActionCreator } from './thunks/updateListingOrderBy.ts';

const { contentItemRepository } = repositoryCollection;

export const setContentItemSelection = setContentItemSelectionActionCreator();

const originalLoadListingContentItemsForInventory = createLoadListingContentItemsForInventoryAction(
  {
    loadListingContentItems,
  },
);

export const loadListingContentItemsForInventory = pickSearchAction(
  originalLoadListingContentItemsForInventory,
  aiSearchLoadListingContentItemsForInventory,
);

export const loadListingContentItemsForContentTypesAction =
  createLoadListingContentItemsForContentTypesAction({
    loadListingContentItems,
  });

export const undoDeleteContentItems = undoDeleteContentItemsActionCreator({
  contentItemRepository,
  loadListingContentItems: loadListingContentItemsForInventory,
});

export const ensureInventoryRelatedData = createEnsureInventoryRelatedDataAction({
  loadCollections,
  loadContentTypes,
  loadSitemap,
  loadSpaces,
  loadTaxonomyGroups,
  loadUsers,
  loadWorkflows,
});

export const initializeContentListing = createInitializeContentListingAction({
  contentListingHiddenColumnsStorage: contentListingColumnsFilterStorage,
  fullTextSearchContextStorage,
  listingOrderByStorage,
});

export const filterContentItems = createFilterContentItemsAction({
  loadListingContentItemsForInventory,
  ContentItemFilterStorage: getFilterStorage(ContentItemFilterOrigin.ContentInventory),
  trackFilterChange: createTrackFilterChangeAction(ContentItemFilterOrigin.ContentInventory),
});

export const filterContentItemsInItemSelector = createFilterContentItemsInDialogAction({
  loadListingContentItems: loadListingContentItemsForInventory,
  trackFilterChange: createTrackFilterChangeAction(
    ContentItemFilterOrigin.ModalContentItemSelector,
  ),
});

export const filterContentItemsInMultipleItemSelector = createFilterContentItemsInDialogAction({
  loadListingContentItems: loadListingContentItemsForInventory,
  trackFilterChange: createTrackFilterChangeAction(
    ContentItemFilterOrigin.ModalMultipleContentItemsSelector,
  ),
});

export const filterItemsWithContentTypeAsComponentUsage =
  createFilterItemsWithContentTypeAsComponentUsage({
    loadListingContentItems: loadContentTypeAsComponentUsage,
  });

export const deleteSelectedContentItems = deleteSelectedContentItemsActionCreator({
  contentItemRepository,
  trackUserEvent,
  loadListingContentItems: loadListingContentItemsForInventory,
  loadContentTypes,
});

export const undoPublishContentItems = undoPublishContentItemsActionCreator({
  contentItemRepository,
  loadListingContentItems: loadListingContentItemsForInventory,
});

export const unpublishSelectedContentItems = unpublishSelectedContentItemsActionCreator({
  contentItemRepository,
  trackUserEvent,
  loadListingContentItems: loadListingContentItemsForInventory,
});

export const scheduleUnpublishContentItems = scheduleUnpublishSelectedContentItemsActionCreator({
  contentItemRepository,
  trackUserEvent,
  loadListingContentItems: loadListingContentItemsForInventory,
});

export const cancelScheduledPublishSelectedContentItems =
  cancelScheduledPublishSelectedContentItemsActionCreator({
    contentItemRepository,
    trackUserEvent,
    loadListingContentItems: loadListingContentItemsForInventory,
  });

export const checkPublishWarnings = createCheckPublishWarningsAction({
  contentItemRepository,
});

export const selectWorkflowStepForListing = createSelectWorkflowStepForListingAction({
  checkPublishWarnings,
});

export const publishSelectedContentItems = publishSelectedContentItemsActionCreator({
  contentItemRepository,
  trackUserEvent,
  loadListingContentItems: loadListingContentItemsForInventory,
  onboardingNotificationTriggered,
});

export const schedulePublishContentItems = schedulePublishSelectedContentItemsActionCreator({
  contentItemRepository,
  trackUserEvent,
  loadListingContentItems: loadListingContentItemsForInventory,
});

export const undoSchedulePublishContentItems = undoSchedulePublishContentItemsActionCreator({
  contentItemRepository,
  loadListingContentItems: loadListingContentItemsForInventory,
});

export const updateSelectedVariantsStandardWorkflowStep =
  createUpdateSelectedVariantsStandardWorkflowStepAction({
    contentItemRepository,
    updateSelectedVariantsWorkflowStep: (
      operationId: Uuid,
      createUpdateWorkflowEventData: CreateUpdateWorkflowStepEventData,
      updateWorkflow: IUpdateWorkflowStep,
    ) =>
      updateSelectedVariantsWorkflowStep(
        operationId,
        createUpdateWorkflowEventData,
        updateWorkflow,
      ),
  });

export const moveSelectedVariantsToArchivedStep =
  createMoveSelectedVariantsToArchivedStepWorkflowAction({
    contentItemRepository,
    updateSelectedVariantsWorkflowStep: (
      operationId: Uuid,
      createUpdateWorkflowStepEventData: CreateUpdateWorkflowStepEventData,
      updateWorkflow: IUpdateWorkflowStep,
    ) =>
      updateSelectedVariantsWorkflowStep(
        operationId,
        createUpdateWorkflowStepEventData,
        updateWorkflow,
      ),
  });

export const moveSelectedContentItemsToCollection =
  createMoveSelectedContentItemsToCollectionAction({
    contentItemRepository,
    loadListingContentItems: loadListingContentItemsForInventory,
    loadListingItems,
    trackUserEvent,
  });

export const restoreSelectedVariantsFromArchivedStep =
  createRestoreSelectedVariantsFromArchivedStepWorkflowAction({
    contentItemRepository,
    updateSelectedVariantsWorkflowStep: (
      operationId: Uuid,
      createUpdateWorkflowStepEventData: CreateUpdateWorkflowStepEventData,
      updateWorkflowStep: IUpdateWorkflowStep,
    ) =>
      updateSelectedVariantsWorkflowStep(
        operationId,
        createUpdateWorkflowStepEventData,
        updateWorkflowStep,
      ),
  });

const updateSelectedVariantsWorkflowStep = createUpdateSelectedVariantsWorkflowStepAction({
  loadListingContentItems: loadListingContentItemsForInventory,
  trackUserEvent,
  convertAssignmentToUpdateServerModel,
});

export const updateListingOrderBy = createUpdateListingOrderByActionCreator({
  listingOrderByStorage,
});

export const orderContentItems = createOrderContentItemsAction({
  updateListingOrderBy,
});

export const resetFulltextSorting = resetFullTextSortingActionCreator({
  loadListingContentItems: loadListingContentItemsForInventory,
  updateListingOrderBy,
});

export const loadOperationsStatuses = createLoadOperationsStatuses({
  contentItemRepository,
});

export const loadSelectedContentItemUsage = createLoadSelectedContentItemUsage();

const handleFullTextSearchTurnedOff = createHandleFullTextSearchTurnedOffAction({
  updateListingOrderBy,
});

const handleFullTextSearchTurnedOn = createHandleFullTextSearchTurnedOnAction({
  fullTextSearchContextStorage,
  updateListingOrderBy,
});

export const updateFilterSearchPhrase = createUpdateFilterSearchPhraseAction({
  handleFullTextSearchTurnedOff,
  handleFullTextSearchTurnedOn,
});

export const updateColumnFilter = createUpdateColumnFilterAction({
  contentListingColumnsFilterStorage,
});

export const clearContentItemListingFilter = createClearContentItemListingFilterAction();

export const openBulkPublishModal = createOpenBulkPublishModalAction({
  checkPublishWarnings,
  openBulkChangeWorkflowStepModal,
});
