import { Box } from '@kontent-ai/component-library/Box';
import { Clipboard } from '@kontent-ai/component-library/Clipboard';
import { InputState } from '@kontent-ai/component-library/Input';
import { SrOnly } from '@kontent-ai/component-library/styles';
import { Spacing, px } from '@kontent-ai/component-library/tokens';
import { forwardRef, useRef, useState } from 'react';
import { useLocation } from 'react-router';
import { ModalDialog } from '../../../../../../../component-library/components/Dialogs/ModalDialog/ModalDialog.tsx';
import { showErrorNotification } from '../../../../../../_shared/actions/sharedActions.ts';
import { trackUserEvent } from '../../../../../../_shared/actions/thunks/trackUserEvent.ts';
import { CodenameErrorMessage } from '../../../../../../_shared/components/Codename/CodenameErrorMessage.tsx';
import { IAnimatedModalDialogProps } from '../../../../../../_shared/components/ModalDialog/IAnimatedModalDialogProps.type.ts';
import { Warning } from '../../../../../../_shared/components/infos/Warning.tsx';
import { TrackedEvent } from '../../../../../../_shared/constants/trackedEvent.ts';
import { useDispatch } from '../../../../../../_shared/hooks/useDispatch.ts';
import { useSelector } from '../../../../../../_shared/hooks/useSelector.ts';
import { ContentItemEditingChangeAction } from '../../../../../../_shared/models/events/ContentItemEditingEventData.type.ts';
import { getSelectedLanguageIdOrThrow } from '../../../../../../_shared/selectors/getSelectedLanguageId.ts';
import {
  DataUiAction,
  DataUiElement,
  DataUiInput,
  getDataUiActionAttribute,
  getDataUiElementAttribute,
  getDataUiInputAttribute,
} from '../../../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';
import {
  ServerApiErrorCode,
  tryParseApiError,
} from '../../../../../../repositories/serverModels/ServerApiError.ts';
import { isCodenameValid } from '../../../../../contentModels/shared/utils/typeCodenameUtils.ts';
import { MinDialogWidth } from '../../../../../projectSettings/environments/constants/uiConstants.ts';
import { itemEditingModalDismissed } from '../../../../actions/contentActions.ts';
import { ContentItemCodenameStatus } from '../../../../models/contentItem/ContentItemCodenameStatus.ts';
import { getModalDialogActionOrigin } from '../../../../selectors/getModalDialogActionOrigin.ts';
import {
  contentItemCodenameChanged,
  contentItemCodenameEditorErrorStatusCleared,
  contentItemCodenameNotUniqueError,
  contentItemCodenameSavingFailed,
  contentItemCodenameSavingFinished,
  deactivateContentItemEditingAction,
} from '../../actions/contentItemEditingActions.ts';
import { saveContentItemCodenameToServer } from '../../actions/thunkContentItemEditingActions.ts';
import {
  CodenameChangeMayBreakYourAppMessage,
  CodenameEditingPossibleOnlyInDefaultLanguage,
  CodenameIsAutoGeneratedMessage,
  CodenameIsNotUniqueErrorMessage,
  CodenameSavingFailedErrorMessage,
} from '../../constants/uiConstants.ts';
import {
  getContentItemSavingOriginFromRoute,
  isContentItemNameAndCodenameEditingAllowed,
} from '../../utils/itemEditingUtils.ts';

export const CodenameDialog = forwardRef<HTMLDivElement, IAnimatedModalDialogProps>(
  ({ isOpen }, ref) => {
    const dispatch = useDispatch();
    const actionOrigin = useSelector(getModalDialogActionOrigin);
    const handleClose = () => {
      dispatch(itemEditingModalDismissed());
      dispatch(contentItemCodenameEditorErrorStatusCleared());
    };
    const initialCodename = useSelector((s) => s.contentApp.editedContentItem?.codename ?? '');
    const [itemCodename, setItemCodename] = useState<string>(initialCodename);
    const hiddenContainerRef = useRef<HTMLDivElement>(null);

    const isCodenameAutogenerated = useSelector(
      (s) => s.contentApp.editorUi.codenameEditor.isCodenameAutogenerated,
    );

    const isBeingSaved = useSelector(
      (s) => s.contentApp.editorUi.codenameEditor.status === ContentItemCodenameStatus.IsBeingSaved,
    );

    const isCodenameUnique = useSelector(
      (s) =>
        s.contentApp.editorUi.codenameEditor.status !==
        ContentItemCodenameStatus.CodenameNotUniqueError,
    );

    const disabledEditingMessage = useSelector((s) =>
      isContentItemNameAndCodenameEditingAllowed(
        s.contentApp.contentItemVariants,
        s.data.languages.defaultLanguage.id,
        getSelectedLanguageIdOrThrow(s),
      )
        ? null
        : CodenameEditingPossibleOnlyInDefaultLanguage,
    );

    const renderWarning = !isCodenameAutogenerated && itemCodename !== initialCodename;
    const isCodenameUniqueAndValid = isCodenameUnique && isCodenameValid(itemCodename);
    const codenameInputState = (): InputState => {
      if (!isCodenameUniqueAndValid) {
        return InputState.Alert;
      }
      if (isBeingSaved || !!disabledEditingMessage) {
        return InputState.ReadOnly;
      }

      return InputState.Default;
    };
    const editedContentItem = useSelector((s) => s.contentApp.editedContentItem);
    const usedSearchMethod = useSelector((s) => s.data.listingContentItems.usedSearchMethod);
    const filter = useSelector((s) => s.contentApp.listingUi.filter);
    const orderBy = useSelector((s) => s.contentApp.listingUi.orderBy);
    const location = useLocation();

    if (!editedContentItem) {
      return null;
    }

    const onSave = async (): Promise<void> => {
      const contentItemSavingOrigin = getContentItemSavingOriginFromRoute(location.pathname);
      try {
        await dispatch(
          saveContentItemCodenameToServer(
            editedContentItem.id,
            editedContentItem.lastModifiedAt,
            itemCodename,
            usedSearchMethod,
            filter,
            orderBy,
            contentItemSavingOrigin,
          ),
        );
        dispatch(contentItemCodenameSavingFinished());
      } catch (error) {
        const apiError = tryParseApiError(error);
        if (apiError?.code === ServerApiErrorCode.CodenameIsNotUnique) {
          dispatch(contentItemCodenameNotUniqueError());

          if (!isOpen) {
            dispatch(showErrorNotification(CodenameIsNotUniqueErrorMessage));
          }
        } else {
          dispatch(showErrorNotification(CodenameSavingFailedErrorMessage));
          throw error;
        }
        dispatch(contentItemCodenameSavingFailed());
      }
    };

    return (
      <ModalDialog
        headline={disabledEditingMessage ? 'Copy codename' : 'Edit or copy codename'}
        isOpen={isOpen}
        isDismissable
        ref={ref}
        onClose={handleClose}
        primaryAction={{
          onClick: onSave,
          text: isBeingSaved ? 'Saving…' : 'Save',
          disabled: !isCodenameUniqueAndValid || !!disabledEditingMessage || isBeingSaved,
          tooltipText: disabledEditingMessage ?? '',
          ...getDataUiActionAttribute(DataUiAction.Save),
        }}
        cancelAction={{
          onClick: handleClose,
          ...getDataUiActionAttribute(DataUiAction.CloseDialog),
        }}
        minWidth={px(MinDialogWidth)}
        {...getDataUiElementAttribute(DataUiElement.CodenameForm)}
      >
        <SrOnly ref={hiddenContainerRef} />
        <Clipboard
          label="Codename"
          containerRef={hiddenContainerRef}
          inputState={codenameInputState()}
          value={itemCodename}
          onInputChange={(event) => {
            setItemCodename(event.target.value);
            dispatch(contentItemCodenameChanged());
          }}
          onSuccess={() =>
            dispatch(
              trackUserEvent(TrackedEvent.ContentItemEditing, {
                action: ContentItemEditingChangeAction.Codename,
                origin: actionOrigin,
              }),
            )
          }
          onCopyCompleted={() => dispatch(deactivateContentItemEditingAction())}
          caption={isCodenameAutogenerated ? CodenameIsAutoGeneratedMessage : undefined}
          {...getDataUiInputAttribute(DataUiInput.Codename)}
        />
        <CodenameErrorMessage
          isCodenameUnique={isCodenameUnique}
          hasCodenameErrors={!isCodenameUniqueAndValid}
        />
        <Box paddingTop={Spacing.L}>
          {renderWarning && (
            <Warning subtitle="Change the codename?">
              {CodenameChangeMayBreakYourAppMessage}
            </Warning>
          )}
        </Box>
      </ModalDialog>
    );
  },
);
