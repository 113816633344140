import React, { forwardRef } from 'react';
import { ContentItemEditingEventOrigins } from '../../../../../../../_shared/models/events/ContentItemEditingEventData.type.ts';
import { PreviewLinks } from '../../../containers/editingActions/actionSubmenuOptions/PreviewLinks.tsx';
import { EditingAction } from '../../../models/EditingAction.ts';
import { QuickActionsButtonToActionMapper } from './QuickActionsButtonToActionMapper.tsx';
import { QuickActionsDropDownMenu } from './QuickActionsDropDownMenu.tsx';

type QuickActionProps = {
  readonly activatedAction: EditingAction;
  readonly action: EditingAction;
  readonly onClickOutside: () => void;
};

export const QuickAction: React.FC<QuickActionProps> = ({
  action,
  activatedAction,
  onClickOutside,
}) => {
  if (action === EditingAction.previewItemInMultipleSpaces && activatedAction === action) {
    return (
      <QuickActionsDropDownMenu
        onClickOutside={onClickOutside}
        renderButton={(ref: React.RefObject<HTMLButtonElement>) => (
          <QuickActionsButton action={action} isActive ref={ref} />
        )}
      >
        <PreviewLinks isQuickActionsMenu />
      </QuickActionsDropDownMenu>
    );
  }

  return <QuickActionsButton action={action} />;
};

type QuickActionsButtonProps = {
  readonly action: EditingAction;
  readonly isActive?: boolean;
};

const QuickActionsButton = forwardRef<HTMLButtonElement, QuickActionsButtonProps>(
  ({ action, isActive }, ref) => (
    <QuickActionsButtonToActionMapper
      action={action}
      actionOrigin={ContentItemEditingEventOrigins.QuickActions}
      isActive={isActive}
      ref={ref}
    />
  ),
);
