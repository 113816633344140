import { InvariantException, isAbortError } from '@kontent-ai/errors';
import { Collection } from '@kontent-ai/utils';
import { Pathname } from 'history';
import { ThunkFunction, ThunkPromise } from '../../../../../../@types/Dispatcher.type.ts';
import { trackUserEvent } from '../../../../../../_shared/actions/thunks/trackUserEvent.ts';
import { TrackedEvent } from '../../../../../../_shared/constants/trackedEvent.ts';
import { ContentItemId } from '../../../../../../_shared/models/ContentItemId.ts';
import {
  SampleContentItemActionEventData,
  SampleContentItemEventTypes,
} from '../../../../../../_shared/models/TrackUserEventData.ts';
import { SimultaneousEditingSaveConflictReason } from '../../../../../../_shared/models/events/ContentItemEventData.type.ts';
import { compose } from '../../../../../../_shared/utils/func/compose.ts';
import { logError } from '../../../../../../_shared/utils/logError.ts';
import { IContentItemRepository } from '../../../../../../repositories/interfaces/IContentItemRepository.type.ts';
import { IContentItemPatchServerModel } from '../../../../../../repositories/serverModels/INewContentItemServerModel.ts';
import { tryParseApiError } from '../../../../../../repositories/serverModels/ServerApiError.ts';
import { IContentItemElementServerModel } from '../../../../../../repositories/serverModels/elementServerModels.type.ts';
import { JsonPatchOperation } from '../../../../../../repositories/utils/jsonPatchConstants.ts';
import { itemEditorOperationIds } from '../../../../../contentInventory/content/utils/itemEditorOperationIdUtils.ts';
import { FailureStatus } from '../../../../models/contentItem/edited/EditedContentItemStatus.ts';
import { ICompiledContentItemElementData } from '../../../../models/contentItemElements/ICompiledContentItemElement.type.ts';
import {
  convertElementToServerModel,
  createItemElementPatchPath,
} from '../../../../stores/utils/contentItemHelperMethods.ts';
import { apiErrorToFailureStatus } from '../../../../utils/apiErrorToFailureStatus.ts';
import { GetAssets } from '../../../../utils/itemElementDataConverters/types/IItemElementDataConverters.type.ts';
import { isRevisionCurrent } from '../../../Revisions/utils/revisionUtils.ts';
import { getContentItemSavingOriginFromRoute } from '../../utils/itemEditingUtils.ts';
import {
  contentItemElementsSavingFailed,
  contentItemElementsSavingFinished,
  contentItemElementsSavingStarted,
} from '../contentItemEditingActions.ts';

type SaveElementValuesToServerDependencies = Readonly<{
  checkConcurrentConflictsBeforeSave: (
    contentItemId: ContentItemId,
    elementId: Uuid,
  ) => ThunkPromise<SimultaneousEditingSaveConflictReason | null>;
  contentItemRepository: Pick<IContentItemRepository, 'patchItemVariant'>;
  updateAutogeneratedUrlSlugAfterDataPatch: (
    patchedElements: ReadonlyArray<IContentItemElementServerModel>,
    originalElements: ReadonlyArray<ICompiledContentItemElementData>,
  ) => ThunkFunction;
  trackSampleContentItemEvent: (eventData: SampleContentItemActionEventData) => ThunkFunction;
}>;

type Params = Readonly<{
  elementsData: ReadonlyArray<ICompiledContentItemElementData>;
  operationId: Uuid;
  pathname: Pathname;
}>;

export type SaveElementValuesToServerAction = (
  params: Params,
  abortSignal?: AbortSignal,
) => ThunkPromise;

const withRetry =
  <T>(
    operation: () => Promise<T>,
    {
      retries,
      shouldSkip,
    }: {
      retries: number;
      shouldSkip?: (error: unknown) => boolean;
    },
  ): (() => Promise<T>) =>
  async () => {
    let attempt = 0;
    while (attempt < retries) {
      try {
        return await operation();
      } catch (error) {
        if (shouldSkip?.(error)) {
          throw error;
        }
        attempt++;
        if (attempt === retries) {
          throw error;
        }
      }
    }
    throw InvariantException(`${__filename}: withRetry: This error should never be thrown.`);
  };

const isExpectedFailureStatus = (s: FailureStatus) =>
  [
    FailureStatus.CodenameIsNotUnique,
    FailureStatus.MissingCapability,
    FailureStatus.NonLocalizableContentTooLarge,
    FailureStatus.ContentTooLarge,
    FailureStatus.ElementTooLarge,
    FailureStatus.ModelValidationFailed,
    FailureStatus.ElementHasConcurrentConflict,
  ].includes(s);

const shouldSkip = compose(
  isExpectedFailureStatus,
  (e) => e.status,
  apiErrorToFailureStatus,
  tryParseApiError,
);

export const createSaveElementValuesToServerAction =
  (deps: SaveElementValuesToServerDependencies): SaveElementValuesToServerAction =>
  ({ elementsData, operationId, pathname: currentPath }, abortSignal) =>
  async (dispatch, getState) => {
    const {
      contentApp: {
        compareRevisions,
        editedContentItemVariant,
        editedContentItemVariantElements,
        entryTimeline,
        listingUi: { filter },
        selectedRevision,
      },
      data: {
        listingContentItems: { usedSearchMethod },
      },
    } = getState();

    // Disallow updates when revision or revision comparison is displayed
    if (
      compareRevisions ||
      (entryTimeline && selectedRevision && !isRevisionCurrent(entryTimeline, selectedRevision))
    ) {
      return;
    }

    if (!editedContentItemVariant) {
      throw InvariantException(
        `saveElementValuesToServer.ts: "editedContentItemVariant" is ${editedContentItemVariant}.`,
      );
    }

    if (!editedContentItemVariantElements) {
      throw InvariantException(
        `saveElementValuesToServer.ts: "editedContentItemVariantElements" is ${editedContentItemVariantElements}.`,
      );
    }

    dispatch(
      contentItemElementsSavingStarted(
        elementsData.map((data) => itemEditorOperationIds.element(operationId, data.elementId)),
      ),
    );

    // Check for concurrent conflicts before each save (useful when user returns from offline mode to online again).
    // TODO In order to concurrent editing be working properly it is needed to pick the element last touched by the user
    // TODO and check it for concurrency conflicts, not the last element in the batch
    const lastElementData = Collection.getLast(elementsData);
    if (!lastElementData) {
      return;
    }
    const saveConflictReason = await dispatch(
      deps.checkConcurrentConflictsBeforeSave(
        editedContentItemVariant.id,
        lastElementData.elementId,
      ),
    );

    if (saveConflictReason) {
      dispatch(
        contentItemElementsSavingFailed({
          operationIds: elementsData.map((data) =>
            itemEditorOperationIds.element(operationId, data.elementId),
          ),
          failure: { status: FailureStatus.ElementHasConcurrentConflict },
          apiError: null,
        }),
      );

      dispatch(
        trackUserEvent(TrackedEvent.ContentEntryElementSaveConflict, {
          'element-id': lastElementData.elementId,
          'element-type': lastElementData.type,
          reason: saveConflictReason,
        }),
      );

      return;
    }

    const contentItemPatches: Array<IContentItemPatchServerModel> = [];
    try {
      const getAssets: GetAssets = () => getState().data.assets.byId;
      elementsData.forEach((data) => {
        const serverModel = convertElementToServerModel(data, { getAssets });
        contentItemPatches.push({
          op: JsonPatchOperation.Replace,
          path: createItemElementPatchPath(serverModel.elementId),
          value: serverModel,
        });
      });
    } catch (e) {
      dispatch(
        contentItemElementsSavingFailed({
          operationIds: elementsData.map((data) =>
            itemEditorOperationIds.element(operationId, data.elementId),
          ),
          apiError: null,
        }),
      );
      logError('Element value is invalid.', e);
      throw e;
    }

    const { itemId, variantId } = editedContentItemVariant.id;

    try {
      const patchItemVariantWithRetry = withRetry(
        () =>
          deps.contentItemRepository.patchItemVariant(
            itemId,
            variantId,
            contentItemPatches,
            abortSignal,
          ),
        {
          retries: 3,
          shouldSkip,
        },
      );

      const itemWithPatchedVariant = await patchItemVariantWithRetry();

      // We send only server model of the elements to the update function to avoid conversion of the full variant to client model
      dispatch(
        deps.updateAutogeneratedUrlSlugAfterDataPatch(
          itemWithPatchedVariant.variant.contentElements,
          editedContentItemVariantElements,
        ),
      );

      dispatch(
        contentItemElementsSavingFinished({
          filter,
          itemPreviouslyUpdatedAt: editedContentItemVariant.contentLastUpdatedAt,
          itemWithVariant: itemWithPatchedVariant,
          operationIds: elementsData.map((data) =>
            itemEditorOperationIds.element(operationId, data.elementId),
          ),
          origin: getContentItemSavingOriginFromRoute(currentPath),
          usedSearchMethod,
        }),
      );

      elementsData.forEach((data) => {
        dispatch(
          deps.trackSampleContentItemEvent({
            action: SampleContentItemEventTypes.ElementUpdated,
            'element-id': data.elementId,
            'element-type': data.type,
          }),
        );
      });
    } catch (error) {
      if (isAbortError(error)) {
        dispatch(
          contentItemElementsSavingFailed({
            operationIds: elementsData.map((data) =>
              itemEditorOperationIds.element(operationId, data.elementId),
            ),
            apiError: null,
          }),
        );
        throw error as unknown;
      }

      dispatch(
        contentItemElementsSavingFailed({
          operationIds: elementsData.map((data) =>
            itemEditorOperationIds.element(operationId, data.elementId),
          ),
          apiError: tryParseApiError(error),
        }),
      );

      const elements = elementsData.map((e) => `${e.elementId} (${e.type})`).join(', ');
      logError(`Saving element values failed. Elements: ${elements}`, error);
      throw error;
    }
  };
