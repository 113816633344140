import { Box } from '@kontent-ai/component-library/Box';
import { Hint } from '@kontent-ai/component-library/Hint';
import { Column, Row } from '@kontent-ai/component-library/Row';
import { IBaseSelectItem } from '@kontent-ai/component-library/Selects';
import { Stack } from '@kontent-ai/component-library/Stack';
import { Spacing, Typography, gridUnit } from '@kontent-ai/component-library/tokens';
import React from 'react';
import { DataTable } from '../../../../../_shared/components/DataTable/DataTable.tsx';
import {
  DataTableHeadRow,
  Column as TableHeadColumn,
} from '../../../../../_shared/components/DataTable/DataTableHeadRow.tsx';
import {
  ItemColumnCode,
  translateColumnCodeToTitle,
} from '../../../../../_shared/constants/itemColumnCode.ts';
import { OrderByDirection } from '../../../../../_shared/models/OrderBy.ts';
import { DataUiCollection } from '../../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';
import { WidgetDetailTableStateContent } from '../../shared/components/WidgetDetailTableStateContent.tsx';
import { WidgetListingState } from '../../shared/types/WidgetListingState.type.ts';
import { PublishedItemsFilterModel } from '../types/PublishedItemsFilterModel.type.ts';
import { PublishedItemsLanguageSelector } from './PublishedItemsLanguageSelector.tsx';
import { PublishedItemsPublishedBySelector } from './PublishedItemsPublishedBySelector.tsx';
import { PublishedItemsTimeIntervalSelector } from './PublishedItemsTimeIntervalSelector.tsx';
import { WidgetDetailEmptyState } from './WidgetDetailEmptyState.tsx';

type PublishedItemsWidgetDetailProps = React.PropsWithChildren &
  Readonly<{
    ariaLabelledBy: string;
    availableLanguages: ReadonlyArray<IBaseSelectItem>;
    availableUsers: IBaseSelectItem[];
    clearFilters: () => void;
    filter: PublishedItemsFilterModel;
    publishedItemsCount: number;
    setFilter: React.Dispatch<React.SetStateAction<PublishedItemsFilterModel>>;
    state: WidgetListingState;
  }>;

export const PublishedItemsWidgetDetail = ({
  ariaLabelledBy,
  availableLanguages,
  availableUsers,
  clearFilters,
  filter,
  publishedItemsCount,
  setFilter,
  state,
  children,
}: PublishedItemsWidgetDetailProps) => {
  return (
    <>
      <Stack spacing={Spacing.XL}>
        <Row alignX="start" alignY="center" spacing={Spacing.M} noWrap>
          <Column>
            <Box typography={Typography.HeadlineSmall} minWidth={10 * gridUnit}>
              Published Items: {publishedItemsCount}{' '}
              <Hint tooltipText="Some items may not be visible due to the permissions set for your role, but they are included in the total count." />
            </Box>
          </Column>
          <Column width="fit-content">
            <PublishedItemsLanguageSelector
              availableLanguages={availableLanguages}
              filter={filter}
              setFilter={setFilter}
            />
          </Column>
          <Column width="fit-content">
            <PublishedItemsPublishedBySelector
              availableUsers={availableUsers}
              filter={filter}
              setFilter={setFilter}
            />
          </Column>
          <Column width="fit-content">
            <PublishedItemsTimeIntervalSelector filter={filter} setFilter={setFilter} />
          </Column>
        </Row>
      </Stack>
      {publishedItemsCount !== 0 ? (
        <WidgetDetailTableStateContent state={state}>
          <DataTable
            ariaLabelledBy={ariaLabelledBy}
            dataUiCollectionName={DataUiCollection.ContentItems}
            header={<DataTableHeadRow columns={tableHeadColumns} />}
          >
            {children}
          </DataTable>
        </WidgetDetailTableStateContent>
      ) : (
        <Box>
          <WidgetDetailEmptyState action={clearFilters} />
        </Box>
      )}
    </>
  );
};

const tableHeadColumns: ReadonlyArray<TableHeadColumn> = [
  {
    columnName: translateColumnCodeToTitle(ItemColumnCode.Name),
    orderBy: OrderByDirection.None,
    className: 'data-table__column--3',
  },
  {
    columnName: translateColumnCodeToTitle(ItemColumnCode.ContentType),
    orderBy: OrderByDirection.None,
    className: 'data-table__column--2',
  },
  {
    columnName: translateColumnCodeToTitle(ItemColumnCode.Collection),
    orderBy: OrderByDirection.None,
    className: 'data-table__column--2',
  },
  {
    columnName: translateColumnCodeToTitle(ItemColumnCode.Language),
    orderBy: OrderByDirection.None,
    className: 'data-table__column--2',
  },
  {
    columnName: 'Publisher',
    orderBy: OrderByDirection.None,
    className: 'data-table__column--1',
  },
  {
    columnName: translateColumnCodeToTitle(ItemColumnCode.LastPublishedAt),
    orderBy: OrderByDirection.Descending,
    className: 'data-table__column--1',
  },
];
