import { Box } from '@kontent-ai/component-library/Box';
import { Button, RouterLinkButton } from '@kontent-ai/component-library/Button';
import { Callout } from '@kontent-ai/component-library/Callout';
import { Spacing, px } from '@kontent-ai/component-library/tokens';
import { FC } from 'react';
import { EnsuredSubscriptionRouteParams, PlanSelectionRoute } from '../../constants/routePaths.ts';
import {
  DataUiAction,
  DataUiElement,
  getDataUiActionAttribute,
  getDataUiElementAttribute,
} from '../../utils/dataAttributes/DataUiAttributes.ts';
import { buildPath } from '../../utils/routing/routeTransitionUtils.ts';

const ProjectManagerMessage: FC = () => (
  <p>
    To add more users to the project, contact your subscription admin to upgrade the subscription.
  </p>
);

type SubscriptionAdminCustomPlanMessageProps = {
  readonly onCloseInvitationModalDialog: () => void;
  readonly onContactSupport: () => void;
  readonly hideButton?: boolean;
};

const SubscriptionAdminCustomPlanMessage: FC<SubscriptionAdminCustomPlanMessageProps> = ({
  onCloseInvitationModalDialog,
  onContactSupport,
  hideButton,
}) => (
  <>
    <p>To add more users to the project, contact us to adjust the plan to your needs.</p>
    {!hideButton && (
      <Button
        onClick={() => {
          onContactSupport();
          onCloseInvitationModalDialog();
        }}
        buttonStyle="primary"
        css={`
            margin-top: ${px(Spacing.L)};
          `}
      >
        Contact us
      </Button>
    )}
  </>
);

type SubscriptionAdminMessageProps = {
  readonly subscriptionId: Uuid | undefined | null;
  readonly onCloseInvitationModalDialog: () => void;
  readonly hideButton?: boolean;
};

const SubscriptionAdminMessage: FC<SubscriptionAdminMessageProps> = ({
  onCloseInvitationModalDialog,
  subscriptionId,
  hideButton,
}) => (
  <>
    <p>To invite more users, you need to upgrade your subscription plan.</p>
    {subscriptionId && !hideButton && (
      <Box marginTop={Spacing.L}>
        <RouterLinkButton
          onPress={onCloseInvitationModalDialog}
          to={buildPath<EnsuredSubscriptionRouteParams>(PlanSelectionRoute, { subscriptionId })}
          buttonStyle="primary"
          {...getDataUiActionAttribute(DataUiAction.ChangePlan)}
        >
          Select plan
        </RouterLinkButton>
      </Box>
    )}
  </>
);

type Props = {
  readonly closeInvitationModalDialog: () => void;
  readonly currentUserIsAdmin: boolean;
  readonly hideButton: boolean;
  readonly isVisible: boolean;
  readonly onContactSupport: () => void;
  readonly planIsCustom: boolean;
  readonly subscriptionId: Uuid | undefined | null;
};

export const SubscriptionPlanMaxUserLimitReachedWarning = ({
  closeInvitationModalDialog,
  currentUserIsAdmin,
  hideButton,
  isVisible,
  onContactSupport,
  planIsCustom,
  subscriptionId,
}: Props) =>
  isVisible ? (
    <Callout
      headline="You have reached your plan limit"
      calloutType="friendlyWarning"
      maxWidth={960}
    >
      <div
        {...getDataUiElementAttribute(DataUiElement.SubscriptionPlanMaxUsersLimitReachedWarning)}
      >
        <p>You have reached the active user limit of your current subscription plan.</p>
        {currentUserIsAdmin ? (
          planIsCustom ? (
            <SubscriptionAdminCustomPlanMessage
              onCloseInvitationModalDialog={closeInvitationModalDialog}
              onContactSupport={onContactSupport}
              hideButton={hideButton}
            />
          ) : (
            <SubscriptionAdminMessage
              subscriptionId={subscriptionId}
              onCloseInvitationModalDialog={closeInvitationModalDialog}
              hideButton={hideButton}
            />
          )
        ) : (
          <ProjectManagerMessage />
        )}
      </div>
    </Callout>
  ) : null;
