import React, { useEffect, useState } from 'react';
import { useDispatch } from '../../../../../_shared/hooks/useDispatch.ts';
import { useSelector } from '../../../../../_shared/hooks/useSelector.ts';
import { Task } from '../../../../../_shared/models/Task.ts';
import { getContentItemPath } from '../../../../../_shared/utils/routing/routeTransitionUtils.ts';
import { getUsersInfo } from '../../../../../_shared/utils/users/usersUtils.ts';
import { loadContentTypes } from '../../../../../data/actions/thunkDataActions.ts';
import { loadListingItems } from '../../../../itemEditor/features/LoadedItems/actions/thunkLoadedItemsActions.ts';
import { TaskWidgetDetailTable } from '../components/TaskWidgetDetailTable.tsx';
import { TaskWidgetDetailTableRow } from '../components/TaskWidgetDetailTableRow.tsx';
import { WidgetListingState } from '../types/WidgetListingState.type.ts';

export type TaskListWidgetDetailProps = Readonly<{
  onItemClick?: () => void;
  state: WidgetListingState;
  tasks: ReadonlyArray<Task>;
}>;

export const TaskWidgetDetailTableLiveContainer: React.FC<TaskListWidgetDetailProps> = ({
  onItemClick,
  state: tasksState,
  tasks,
}) => {
  const [dependencyState, setDependencyState] = useState(WidgetListingState.Loading);
  const dispatch = useDispatch();

  const contentTypeByIds = useSelector((s) => s.data.contentTypes.byId);
  const listingContentItemById = useSelector((s) => s.data.listingContentItems.byId);
  const usersById = useSelector((s) => s.data.users.usersById);

  useEffect(() => {
    setDependencyState(WidgetListingState.Loading);

    const itemIds = tasks.map((task) => task.contentItemId.itemId);
    Promise.all([dispatch(loadListingItems(itemIds)), dispatch(loadContentTypes())])
      .then(() => setDependencyState(WidgetListingState.Loaded))
      .catch(() => setDependencyState(WidgetListingState.Error));
  }, [tasks]);

  const getContentItem = (itemId: Uuid) => {
    return listingContentItemById.get(itemId);
  };

  const getContentTypeForItemId = (itemId: Uuid) => {
    const typeId = getContentItem(itemId)?.item.typeId;
    return typeId ? contentTypeByIds.get(typeId) : undefined;
  };

  const getContributors = (task: Task) => {
    const userIdentifiers = new Set([...task.assignees].map((userId) => ({ userId })));
    return Array.from(getUsersInfo(userIdentifiers, usersById));
  };

  const getLinkPath = (task: Task) =>
    getContentItemPath(
      window.location.pathname,
      task.contentItemId.itemId,
      task.contentItemId.variantId,
    );

  return (
    <TaskWidgetDetailTable
      state={dependencyState === WidgetListingState.Loaded ? tasksState : dependencyState}
    >
      {tasks.map((task) => (
        <TaskWidgetDetailTableRow
          key={task.id}
          task={task}
          contentItem={getContentItem(task.contentItemId.itemId)}
          contentType={getContentTypeForItemId(task.contentItemId.itemId)}
          contributors={getContributors(task)}
          linkPath={getLinkPath(task)}
          onClick={onItemClick}
        />
      ))}
    </TaskWidgetDetailTable>
  );
};

TaskWidgetDetailTableLiveContainer.displayName = 'TaskWidgetDetailTableLiveContainer';
