import { IStore } from '../../../../_shared/stores/IStore.type.ts';
import { isPublishingStepSelected } from '../../../../_shared/utils/contentItemUtils.ts';
import { ICompiledContentItemElementData } from '../../models/contentItemElements/ICompiledContentItemElement.type.ts';
import { getItemElementEqualityChecker } from '../../utils/getItemElementsEqualityChecker.ts';

export enum ElementChangeType {
  HasErrorUnableToSave = 'HasErrorUnableToSave',
  NoChange = 'NoChange',
  RefreshedFromServer = 'RefreshedFromServer',
  UpdatedNeedsSave = 'UpdatedNeedsSave',
}

export const getElementChange = (
  state: IStore,
  newElementData: ICompiledContentItemElementData,
  oldElementData: ICompiledContentItemElementData | null,
): ElementChangeType => {
  const editedVariant = state.contentApp.editedContentItemVariant;

  const isVariantInPublishingStep =
    editedVariant && isPublishingStepSelected(editedVariant.assignment);
  if (isVariantInPublishingStep) {
    return ElementChangeType.NoChange;
  }

  return getElementChangeIncludingPublishedStep(state, newElementData, oldElementData);
};

export const getElementChangeIncludingPublishedStep = (
  state: IStore,
  newElementData: ICompiledContentItemElementData,
  oldElementData: ICompiledContentItemElementData | null,
): ElementChangeType => {
  if (state.contentApp.itemValidationErrors.get(newElementData.elementId)) {
    return ElementChangeType.HasErrorUnableToSave;
  }

  if (state.contentApp.editorUi.refreshedElementIds.contains(newElementData.elementId)) {
    return ElementChangeType.RefreshedFromServer;
  }

  if (!oldElementData) {
    return ElementChangeType.UpdatedNeedsSave;
  }

  const isElementValueTheSame = getItemElementEqualityChecker(newElementData.type);
  return isElementValueTheSame(oldElementData, newElementData)
    ? ElementChangeType.NoChange
    : ElementChangeType.UpdatedNeedsSave;
};
