import { CollectionSection } from '../../../applications/itemEditor/features/NewContentItem/constants/collectionSection.ts';
import { ContentTypeSection } from '../../../applications/itemEditor/features/NewContentItem/constants/contentTypeSection.ts';

export enum ContentItemEditingEventOrigins {
  FriendlyWarning = 'friendly-warning',
  ItemDetails = 'sidebar',
  MoreActions = 'more-actions',
  NewVariantModal = 'new-variant-modal',
  NotificationBar = 'notification-bar',
  Paper = 'paper',
  Revisions = 'revisions',
  QuickActions = 'quick-actions',
  StatusBar = 'status-bar',
  Undefined = 'undefined',
}

export enum ContentItemEditingExpandCollapseAction {
  CollapseComponents = 'collapse-components',
  ContentItemDetailsSidebarClosed = 'content-item-details-sidebar--closed',
  ContentItemDetailsSidebarOpened = 'content-item-details-sidebar--opened',
  ContentItemDetailsSidebarPinned = 'content-item-details-sidebar--pinned',
  ContentItemDetailsSidebarUnpinned = 'content-item-details-sidebar--unpinned',
  DiscussionsTabSelected = 'discussions-tab--selected',
  ExpandComponents = 'expand-components',
  HideAllComments = 'hide-all-comments',
  OpenResolved = 'open-resolved',
  ShowAllComments = 'show-all',
}

export enum ContentItemEditingPreviewAction {
  DefaultPreview = 'default-preview',
  MultipleSpacesPreviewSelected = 'multiple-spaces-preview--selected',
  NotSetupPreviewSelected = 'not-setup-preview--selected',
  SampleAppPreview = 'sample-app-preview',
  SampleAppPreviewSelected = 'sample-app-preview--selected',
}

export enum ContentItemEditingTriggerDialogAction {
  AssignContributorsSelected = 'assign-contributors--selected',
  ChangeCollectionSelected = 'change-collection--selected',
  ChangeDueDateSelected = 'change-due-date--selected',
  ChangeNoteSelected = 'change-note--selected',
  ChangeWorkflowSelected = 'change-workflow--selected',
  ChangeWorkflowStepSelected = 'change-workflow-step--selected',
  CodenameSelected = 'codename--selected',
  CopyFromLanguageSelected = 'copy-from-language--selected',
  DuplicateSelected = 'duplicate--selected',
  NewVariantWorkflowSelected = 'new-variant-workflow--selected',
  NoteSelected = 'note--selected',
  PublishSelected = 'publish--selected',
  ShareSelected = 'share--selected',
  TasksSelected = 'tasks--selected',
}

export enum ContentItemEditingNavigationAction {
  CompareVersions = 'compare-versions',
  ContentType = 'content-type',
  ShareItemLink = 'share-item-link',
  SharePreviewLink = 'share-preview-link',
  UsedIn = 'used-in',
}

export enum ContentItemEditingChangeAction {
  AssignAsset = 'assign-asset',
  AssignContributors = 'assign-contributors',
  CancelScheduledPublish = 'cancel-scheduled-publish',
  CancelScheduledUnpublish = 'cancel-scheduled-unpublish',
  ChangeDueDate = 'change-due-date',
  ChangeSitemapLocation = 'change-sitemap-location',
  ChangeWorkflowStep = 'change-workflow-step',
  Codename = 'codename',
  ConvertComponentToContentItem = 'convert-component-to-content-item',
  CopyFromLanguage = 'copy-from-language',
  CreateANewVersion = 'create-a-new-version',
  CreateComponent = 'create-component',
  CreateContentItem = 'create-content-item',
  CreateNew = 'create-new',
  Delete = 'delete',
  DiscardNewVersion = 'discard-new-version',
  Duplicate = 'duplicate',
  LinkContentItem = 'link-content-item',
  MoveToCollection = 'move-to-collection',
  Note = 'note',
  Publish = 'publish',
  Schedule = 'schedule',
  ScheduleUnpublish = 'schedule-unpublish',
  Unpublish = 'unpublish',
  FirstSavingChanges = 'first-saving-changes',
}

export enum ContentItemEditingGeneralAction {
  CopyToClipboard = 'copy-to-clipboard',
}

export enum ContentItemEditingWorkflowStepChangeFeatures {
  HasNote = 'has-note',
  HasDueDate = 'has-due-date',
  HasScheduledToPublish = 'has-scheduled-to-publish',
  HasScheduledToUnpublish = 'has-scheduled-to-unpublish',
}

type ContentItemEditingChangeWorkflowSharedEventData = {
  readonly origin: ContentItemEditingEventOrigins;
  readonly workflowStepId: Uuid;
  readonly workflowId: Uuid;
};

type ContentItemEditingBasicChangeWorkflowActions =
  | ContentItemEditingChangeAction.Publish
  | ContentItemEditingChangeAction.Unpublish
  | ContentItemEditingChangeAction.CancelScheduledPublish
  | ContentItemEditingChangeAction.CancelScheduledUnpublish;

type ContentItemEditingBasicChangeWorkflowEventData =
  ContentItemEditingChangeWorkflowSharedEventData & {
    readonly action: ContentItemEditingBasicChangeWorkflowActions;
  };

type ContentItemEditingCustomWorkflowStepEventData =
  ContentItemEditingChangeWorkflowSharedEventData & {
    readonly action: ContentItemEditingChangeAction.ChangeWorkflowStep;
    readonly assigneesCount: number;
    readonly features: ReadonlyArray<ContentItemEditingWorkflowStepChangeFeatures>;
    readonly oldWorkflowId: Uuid;
    readonly oldWorkflowStepId: Uuid;
    readonly regularStepIndex: number;
    readonly secondsSinceLastAssignmentUpdate: number;
  };

type ContentItemEditingScheduleUnpublishEventData =
  ContentItemEditingChangeWorkflowSharedEventData & {
    readonly action: ContentItemEditingChangeAction.ScheduleUnpublish;
    readonly secondsSinceLastPublish: number;
  };

type ContentItemEditingScheduleEventData = ContentItemEditingChangeWorkflowSharedEventData & {
  readonly action: ContentItemEditingChangeAction.Schedule;
  readonly features: ReadonlyArray<ContentItemEditingWorkflowStepChangeFeatures>;
};

type ContentItemEditingChangeWorkflowEventData =
  | ContentItemEditingBasicChangeWorkflowEventData
  | ContentItemEditingCustomWorkflowStepEventData
  | ContentItemEditingScheduleEventData
  | ContentItemEditingScheduleUnpublishEventData;

type ContentItemEditingElementEventData = {
  readonly origin: ContentItemEditingEventOrigins;
  readonly contentElementType: string;
};

type ContentItemEditingCreateContentItemEventData = ContentItemEditingElementEventData & {
  readonly action: ContentItemEditingChangeAction.CreateContentItem;
  readonly allowedContentTypesCount: number;
  readonly collectionSection: CollectionSection;
  readonly contentTypeSection: ContentTypeSection;
};

type ContentItemEditingConvertComponentItemEventData = ContentItemEditingElementEventData & {
  readonly action: ContentItemEditingChangeAction.ConvertComponentToContentItem;
};

type ContentItemEditingCreateComponentItemEventData = ContentItemEditingElementEventData & {
  readonly action: ContentItemEditingChangeAction.CreateComponent;
  readonly contentTypeSection: ContentTypeSection;
};

type ContentItemEditingComponentItemEventData =
  | ContentItemEditingConvertComponentItemEventData
  | ContentItemEditingCreateComponentItemEventData;

type ContentItemEditingLinkExistingItemEventData = ContentItemEditingElementEventData & {
  readonly action: ContentItemEditingChangeAction.LinkContentItem;
  readonly allowedContentTypesCount: number;
  readonly selectedItemsCount: number;
};

type ContentItemEditingLinkAssetEventData = ContentItemEditingElementEventData & {
  readonly action: ContentItemEditingChangeAction.AssignAsset;
  readonly selectedItemsCount: number;
};

type ContentItemEditingAssignContributorsEventData = {
  readonly action: ContentItemEditingChangeAction.AssignContributors;
  readonly origin: ContentItemEditingEventOrigins;
  readonly assignees: ReadonlyArray<UserId>;
};

export type ContentItemEditingBasicActions = Exclude<
  | ContentItemEditingChangeAction
  | ContentItemEditingGeneralAction
  | ContentItemEditingExpandCollapseAction
  | ContentItemEditingNavigationAction
  | ContentItemEditingPreviewAction
  | ContentItemEditingTriggerDialogAction,
  ContentItemEditingSpecificEventData['action']
>;

type ContentItemEditingBasicEventData = {
  readonly action: ContentItemEditingBasicActions;
  readonly origin: ContentItemEditingEventOrigins;
};

export type ContentItemEditingOpenedData = {
  readonly 'is-web-spotlight-enabled': boolean;
};

export type ContentItemEditingSpecificEventData =
  | ContentItemEditingAssignContributorsEventData
  | ContentItemEditingChangeWorkflowEventData
  | ContentItemEditingComponentItemEventData
  | ContentItemEditingCreateContentItemEventData
  | ContentItemEditingLinkAssetEventData
  | ContentItemEditingLinkExistingItemEventData;

export type ContentItemEditingEventData =
  | ContentItemEditingSpecificEventData
  | ContentItemEditingBasicEventData;
