import { forwardRef } from 'react';
import { IAnimatedModalDialogProps } from '../../../../../_shared/components/ModalDialog/IAnimatedModalDialogProps.type.ts';
import { useDispatch } from '../../../../../_shared/hooks/useDispatch.ts';
import { compose } from '../../../../../_shared/utils/func/compose.ts';
import { itemEditingModalDismissed } from '../../../actions/contentActions.ts';
import { cancelContentItemVariantsScheduledUnpublish } from '../../ContentItemEditing/actions/thunkContentItemEditingActions.ts';
import { CancelScheduleUnpublishConfirmationDialog as CancelScheduleUnpublishConfirmationDialogComponent } from '../components/CancelScheduleUnpublishConfirmationDialog.tsx';

export const CancelScheduleUnpublishConfirmationDialog = forwardRef<
  HTMLDivElement,
  IAnimatedModalDialogProps
>((props, ref) => {
  const dispatch = useDispatch();
  const onCancel = compose(dispatch, itemEditingModalDismissed);
  const onConfirm = compose(dispatch, cancelContentItemVariantsScheduledUnpublish);

  return (
    <CancelScheduleUnpublishConfirmationDialogComponent
      onCancel={onCancel}
      onConfirm={onConfirm}
      ref={ref}
      {...props}
    />
  );
});

CancelScheduleUnpublishConfirmationDialog.displayName =
  'CancelScheduleUnpublishConfirmationDialogContainer';
