export enum ContentItemFilterOrigin {
  Calendar = 'calendar',
  ContentInventory = 'content-inventory',
  ModalContentItemSelector = 'modal-content-item-selector',
  ModalMultipleContentItemsSelector = 'modal-multiple-content-items-selector',
  ContentStatus = 'content-status',
  ProjectOverview = 'project-overview',
}

export type SavedFilterSelectedEventData = {
  readonly 'filter-id': Uuid;
  readonly origin: ContentItemFilterOrigin;
};
