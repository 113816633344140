import React from 'react';
import { trackUserEvent } from '../../../../../../../_shared/actions/thunks/trackUserEvent.ts';
import { TrackedEvent } from '../../../../../../../_shared/constants/trackedEvent.ts';
import { useDispatch } from '../../../../../../../_shared/hooks/useDispatch.ts';
import { useSelector } from '../../../../../../../_shared/hooks/useSelector.ts';
import { isWebSpotlightEnabled } from '../../../../../../webSpotlight/selectors/webSpotlightSelectors.ts';
import { PreviewLinks as PreviewLinksComponent } from '../../../components/editingActions/actionSubmenuOptions/PreviewLinks.tsx';
import { selectPreviewLinks } from '../../selectors/selectPreviewLinks.ts';

export const PreviewLinks: React.FC<{ readonly isQuickActionsMenu?: boolean }> = (props) => {
  const dispatch = useDispatch();
  const previewLinks = useSelector(selectPreviewLinks);
  const isWebSpotlightActive = useSelector(isWebSpotlightEnabled);

  const onPreviewOpened = (spaceId: Uuid | null) => {
    dispatch(
      trackUserEvent(TrackedEvent.SpacePreviewOpened, {
        'space-id': spaceId ?? '',
        'is-web-spotlight-enabled': isWebSpotlightActive,
      }),
    );
  };

  return (
    <PreviewLinksComponent
      isQuickActionsMenu={props.isQuickActionsMenu}
      previewLinks={previewLinks}
      onPreviewOpened={onPreviewOpened}
    />
  );
};
