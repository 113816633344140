import {
  Project_NewProject_Failed,
  Project_NewProject_Finished,
  Project_NewProject_Started,
  Project_Template_Finished,
} from '../constants/projectActionTypes.ts';

export const startCreatingNewProject = () =>
  ({
    type: Project_NewProject_Started,
  }) as const;

export const finishCreatingNewProject = (projectId: Uuid) =>
  ({
    type: Project_NewProject_Finished,
    payload: {
      data: {
        projectId,
      },
    },
  }) as const;

export const finishCreatingTemplateProject = (projectId: Uuid) =>
  ({
    type: Project_Template_Finished,
    payload: {
      data: {
        projectId,
      },
    },
  }) as const;

export const failCreatingNewProject = (errorMessage: string = '') =>
  ({
    type: Project_NewProject_Failed,
    payload: { errorMessage },
  }) as const;
