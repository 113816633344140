import { Dispatch, ThunkPromise } from '../../../../@types/Dispatcher.type.ts';
import { TrackedEvent } from '../../../../_shared/constants/trackedEvent.ts';
import { TrackUserEventAction } from '../../../../_shared/models/TrackUserEvent.type.ts';
import { IProjectRepository } from '../../../../repositories/interfaces/IProjectRepository.type.ts';
import { INewProjectFormShape } from '../../models/INewProjectFormShape.type.ts';
import { waitUntilProjectIsActive } from '../../utils/projectUtils.ts';
import {
  failCreatingNewProject,
  finishCreatingNewProject,
  startCreatingNewProject,
} from '../createProjectActions.ts';
import { projectCreationInitialized } from '../projectsActions.ts';

interface IDeps {
  readonly projectRepository: IProjectRepository;
  readonly trackUserEvent: TrackUserEventAction;
  readonly loadUserProjectsInfo: () => ThunkPromise;
  readonly loadProjects: () => ThunkPromise;
}

export const createCreateEmptyProjectAction =
  (deps: IDeps) =>
  (project: INewProjectFormShape): ThunkPromise =>
  async (dispatch: Dispatch) => {
    try {
      dispatch(startCreatingNewProject());

      const createdProject = await deps.projectRepository.createProject(project);
      dispatch(projectCreationInitialized(createdProject.projectGuid));
      await waitUntilProjectIsActive(createdProject.projectGuid, deps.projectRepository);

      await Promise.all([dispatch(deps.loadUserProjectsInfo()), dispatch(deps.loadProjects())]);
      dispatch(finishCreatingNewProject(createdProject.projectGuid));

      // if you are going to change the code below, please, consider whether you should make the same changes in createProjectFromTemplate.ts
      dispatch(deps.trackUserEvent(TrackedEvent.ProjectCreated, {}));
    } catch (error) {
      dispatch(failCreatingNewProject());

      throw error;
    }
  };
