import { IStore } from '../../../../../../_shared/stores/IStore.type.ts';
import { isSampleProject } from '../../../../../../_shared/utils/sampleItemsUtils.ts';
import { getCurrentProject } from '../../../../../../data/reducers/user/selectors/userProjectsInfoSelectors.ts';
import { isDemoSamplePreviewUrl } from '../../utils/isDemoSamplePreviewUrl.ts';
import { getPreviewUrlPattern } from './getPreviewUrlPattern.ts';

export const canShowSampleAppPreview = (state: IStore): boolean => {
  const currentProject = getCurrentProject(state);
  const previewUrlPattern = getPreviewUrlPattern(state);

  return isSampleProject(currentProject) && isDemoSamplePreviewUrl(previewUrlPattern);
};
