import { TypeElementType } from '../../applications/contentInventory/content/models/ContentItemElementType.ts';
import { IBaseTypeElementData } from '../../applications/contentModels/shared/models/elements/types/TypeElementData.ts';
import {
  ContentTypeCreatedEventData,
  ContentTypeDuplicatedEventData,
  ContentTypeModifiedEventData,
} from '../models/TrackUserEventData.ts';

const containsElement = (
  source: ReadonlyArray<IBaseTypeElementData>,
  element: IBaseTypeElementData,
): boolean => source.map((x) => x.elementId).includes(element.elementId);

const getElementType = (x: IBaseTypeElementData): TypeElementType => x.type;

export const getContentTypeCreatedEventData = (
  typeId: Uuid,
  elements: ReadonlyArray<IBaseTypeElementData>,
): ContentTypeCreatedEventData => ({
  'added-elements': elements.map(getElementType).join(','),
  'contains-active-condition': elements.some((e) => e.condition?.isActive),
  'contains-non-localizable-elements': elements.some((e) => e.isNonLocalizable),
  'type-id': typeId,
});

export const getContentTypeDuplicatedEventData = (
  elements: ReadonlyArray<IBaseTypeElementData>,
): ContentTypeDuplicatedEventData => ({
  'duplicated-elements': elements.map(getElementType).join(','),
});

export const getContentTypeModifiedEventData = (
  typeId: Uuid,
  elements: ReadonlyArray<IBaseTypeElementData>,
  originalElements: ReadonlyArray<IBaseTypeElementData>,
): ContentTypeModifiedEventData => {
  const addedElements = elements.filter((x) => !containsElement(originalElements, x));
  const removedElements = originalElements
    ? originalElements.filter((x) => !containsElement(elements, x))
    : [];

  return {
    'added-elements': addedElements.map(getElementType).join(','),
    'contains-active-condition': elements.some((e) => e.condition?.isActive),
    'contains-non-localizable-elements': elements.some((e) => e.isNonLocalizable),
    'removed-elements': removedElements.map(getElementType).join(','),
    'type-id': typeId,
  };
};
