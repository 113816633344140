import { Hint } from '@kontent-ai/component-library/Hint';
import { InputType } from '@kontent-ai/component-library/Input';
import React from 'react';
import { TextFilter } from '../../../../_shared/components/TextFilter.tsx';
import { ValidationConstants } from '../../../../_shared/constants/validationConstants.ts';
import {
  AiSearchHelpMessage,
  AiSearchPlaceholder,
} from '../../../../paperModels/aiSearch/constants/uiConstants.ts';
import { FullTextSearchStatus } from '../../shared/reducers/IContentInventoryStoreState.type.ts';
import {
  DisabledSearchHelpMessage,
  ExactMatchHelpMessage,
  NameOrContentPlaceholder,
  NamePlaceholder,
} from '../constants/fullTextSearch.ts';
import { MaxFullTextSearchPhraseLength } from '../constants/uiConstants.ts';
import {
  ItemListingAiSearchTextFilterDebounceTime,
  ItemListingFilterDebounceTime,
} from '../features/ListingFilter/constants/listingFilterConstants.ts';
import { useDebouncedFilterInputValue } from '../features/ListingFilter/hooks/useDebouncedFilterInputValue.ts';
import { validateFullTextSearchPhrase } from '../utils/validateFullTextSearchPhrase.ts';
import { ContentItemsSearchSort } from './ContentItemsSearchSort.tsx';

type Props = {
  readonly autofocus?: boolean;
  readonly fullTextSearchStatus: FullTextSearchStatus;
  readonly isAiSearch: boolean;
  readonly onSortReset: () => void;
  readonly onUpdateSearchPhrase: (text: string) => void;
  readonly orderByColumnIsCustom: boolean;
  readonly searchPhrase: string;
};

export const ContentItemsTextFilter: React.FC<Props> = (props: Props) => {
  const { autofocus, fullTextSearchStatus, isAiSearch, onSortReset, orderByColumnIsCustom } = props;

  const [value, setValue] = useDebouncedFilterInputValue(
    props.searchPhrase,
    props.onUpdateSearchPhrase,
    isAiSearch ? ItemListingAiSearchTextFilterDebounceTime : ItemListingFilterDebounceTime,
    undefined,
  );

  const isFullTextSearchEnabled = fullTextSearchStatus === FullTextSearchStatus.Enabled;

  const helpMessage = getHelpMessage(isAiSearch, isFullTextSearchEnabled);

  const errorMessage = validateFullTextSearchPhrase({
    fullTextSearchStatus,
    searchPhrase: value,
  });

  return (
    <TextFilter
      autofocus={autofocus}
      errorMessage={errorMessage}
      extraIconsAfterReset={[
        <Hint
          key="full-text-search-help"
          tooltipPlacement="bottom"
          tooltipText={helpMessage}
          aria-label="Show more information"
        />,
      ]}
      extraIconsBeforeReset={[
        <ContentItemsSearchSort
          isVisible={!isAiSearch && isFullTextSearchEnabled && !!value}
          key="full-text-search-sort"
          onSortReset={onSortReset}
          orderByColumnIsCustom={orderByColumnIsCustom}
        />,
      ]}
      maxLength={
        isFullTextSearchEnabled || isAiSearch
          ? MaxFullTextSearchPhraseLength
          : ValidationConstants.ContentItemNameMaxLength
      }
      onChange={setValue}
      placeholder={getPlaceholder(isAiSearch, isFullTextSearchEnabled)}
      text={value}
      ariaLabel="Search content items"
      type={InputType.Search}
    />
  );
};

const getPlaceholder = (isAiSearch: boolean, isFullTextSearchEnabled: boolean) => {
  if (isAiSearch) return AiSearchPlaceholder;

  return isFullTextSearchEnabled ? NameOrContentPlaceholder : NamePlaceholder;
};

const getHelpMessage = (isAiSearch: boolean, isFullTextSearchEnabled: boolean) => {
  if (isAiSearch) return AiSearchHelpMessage;

  return isFullTextSearchEnabled ? ExactMatchHelpMessage : DisabledSearchHelpMessage;
};
