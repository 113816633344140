import { InvariantException } from '@kontent-ai/errors';
import { History } from 'history';
import { Dispatch, ThunkPromise } from '../../../../../../@types/Dispatcher.type.ts';
import { trackUserEvent } from '../../../../../../_shared/actions/thunks/trackUserEvent.ts';
import { TrackedEvent } from '../../../../../../_shared/constants/trackedEvent.ts';
import {
  ContentItemEditingChangeAction,
  ContentItemEditingEventOrigins,
} from '../../../../../../_shared/models/events/ContentItemEditingEventData.type.ts';
import { IStore } from '../../../../../../_shared/stores/IStore.type.ts';
import { SearchMethod } from '../../../../../../repositories/serverModels/IListingContentItemServerModel.type.ts';
import { IContentItemWithVariantServerModel } from '../../../../../../repositories/serverModels/INewContentItemServerModel.ts';
import { IListingFilter } from '../../../../../contentInventory/content/models/filter/IListingFilter.ts';
import {
  ContentItemEditing_CopyContentFromVariant_Failed,
  ContentItemEditing_CopyContentFromVariant_Finished,
  ContentItemEditing_CopyContentFromVariant_Started,
} from '../../constants/contentItemEditingActionTypes.ts';
import { InitContentItemEditing } from './initContentItemEditing.ts';

interface IDeps {
  readonly contentItemRepository: {
    readonly copyFromVariant: (
      contentItemId: Uuid,
      variantId: Uuid,
      fromVariantId: Uuid,
    ) => Promise<IContentItemWithVariantServerModel>;
  };
  readonly initContentItemEditing: InitContentItemEditing;
}

const started = () =>
  ({
    type: ContentItemEditing_CopyContentFromVariant_Started,
  }) as const;

type FinishedPayload = {
  readonly itemWithVariant: IContentItemWithVariantServerModel;
  readonly filter: IListingFilter;
  readonly usedSearchMethod: SearchMethod;
};

const finished = (payload: FinishedPayload) =>
  ({
    type: ContentItemEditing_CopyContentFromVariant_Finished,
    payload,
  }) as const;

const failed = () =>
  ({
    type: ContentItemEditing_CopyContentFromVariant_Failed,
  }) as const;

export type CopyContentFromVariantActionsType = ReturnType<
  typeof started | typeof finished | typeof failed
>;

export const createCopyContentValuesFromDifferentExistingVariantAction =
  (deps: IDeps) =>
  (
    history: History,
    fromVariantId: Uuid,
    actionOrigin: ContentItemEditingEventOrigins,
  ): ThunkPromise =>
  async (dispatch: Dispatch, getState: () => IStore): Promise<void> => {
    const {
      contentApp: {
        editedContentItem,
        editedContentItemVariant,
        listingUi: { filter },
      },
      data: {
        listingContentItems: { usedSearchMethod },
      },
    } = getState();

    if (!editedContentItem || !editedContentItemVariant) {
      throw InvariantException(
        'copyContentFromDifferentVariant: "editedContentItem" or "editedContentItemVariant" is null',
      );
    }

    const editedContentItemId = editedContentItem.id;
    const editedVariantId = editedContentItemVariant.id.variantId;

    dispatch(started());
    try {
      const itemWithVariant = await deps.contentItemRepository.copyFromVariant(
        editedContentItemId,
        editedVariantId,
        fromVariantId,
      );

      await dispatch(deps.initContentItemEditing(history, null));

      dispatch(
        trackUserEvent(TrackedEvent.ContentItemEditing, {
          action: ContentItemEditingChangeAction.CopyFromLanguage,
          origin: actionOrigin,
        }),
      );

      dispatch(
        finished({
          itemWithVariant,
          filter,
          usedSearchMethod,
        }),
      );
    } catch (error) {
      dispatch(failed());
      throw error;
    }
  };
