import { Callout } from '@kontent-ai/component-library/Callout';
import { Stack } from '@kontent-ai/component-library/Stack';
import { Spacing } from '@kontent-ai/component-library/tokens';
import { InvariantException } from '@kontent-ai/errors';
import React, { useCallback } from 'react';
import { useHistory } from 'react-router';
import {
  ActionsPlacement,
  PendingInvitationActions,
} from '../../../../../_shared/components/PendingInvitationActions.tsx';
import {
  IPopoverTexts,
  IUserActiveStatus,
  UserActiveStatusToggleWithConfirmationDialog,
} from '../../../../../_shared/components/users/UserActiveStatusToggleWithConfirmationDialog.tsx';
import { SubscriptionPlanIncludedUserLimitReachedWarning } from '../../../../../_shared/containers/SubscriptionWarnings/SubscriptionPlanIncludedUserLimitReachedWarning.tsx';
import { SubscriptionPlanMaxUserLimitReachedWarning } from '../../../../../_shared/containers/SubscriptionWarnings/SubscriptionPlanMaxUserLimitReachedWarning.tsx';
import { useDispatch } from '../../../../../_shared/hooks/useDispatch.ts';
import { useSelector } from '../../../../../_shared/hooks/useSelector.ts';
import { selectIntercomUtils } from '../../../../../_shared/selectors/selectIntercomUtils.ts';
import {
  getNumberOfUsersInActivation,
  getSubscriptionUsage,
  hasAvailableUserSlots,
  hasToUsePaidUserSlot,
  isUserCountedInUsage,
} from '../../../../../_shared/utils/subscriptionUsageUtils.ts';
import { formatUserName } from '../../../../../_shared/utils/users/usersUtils.ts';
import { getSubscriptionPlan } from '../../../../../data/reducers/subscriptions/selectors/subscriptionSelectors.ts';
import { redirectToPlanSelection } from '../../../shared/utils/subscriptionManagementPathUtils.ts';
import {
  resendInviteToProjects,
  revokeInviteToProjects,
  toggleUserActiveStateOnProjects,
} from '../../actions/thunkSubscriptionUsersActions.ts';
import { getSubscriptionUserUsage } from '../../selectors/subscriptionUsersUsageSelectors.ts';

export interface IUserProjectSettingActiveStatus {
  readonly hasNonProjectManagerRole: boolean;
  readonly isActiveGlobal: boolean;
  readonly isActivating: boolean;
  readonly isDeactivating: boolean;
  readonly isInvitationPending: boolean;
  readonly isInviteRevoking: boolean;
  readonly isInviteSending: boolean;
  readonly isInviteSent: boolean;
  readonly isProjectManagerRole: boolean;
  readonly projectIds: ReadonlyArray<Uuid>;
}

interface IUserProjectSettingsOwnProps {
  readonly activeStatus: IUserProjectSettingActiveStatus;
  readonly userId: UserId;
  readonly isSingleProject: boolean;

  readonly onNavigateBack: () => void;
}

const getPopoverTexts = (isActiveGlobal: boolean): IPopoverTexts => {
  const toggleAction = isActiveGlobal ? 'Deactivate' : 'Activate';

  return {
    headlineText: `${toggleAction} user in this project?`,
    primaryActionText: `${toggleAction} in project`,
  };
};

const getTooltipText = (isSubscriptionAdmin: boolean, isActiveGlobal: boolean) => {
  if (isSubscriptionAdmin) {
    return 'This user is a subscription Admin, you can manage their status in the "Subscription admins" settings.';
  }

  return `${isActiveGlobal ? 'Deactivate' : 'Activate'} user in this project.`;
};

export const UserProjectSettingsStatus: React.FC<IUserProjectSettingsOwnProps> = ({
  activeStatus,
  userId,
  isSingleProject,
  onNavigateBack,
}) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const intercomUtils = useSelector(selectIntercomUtils);

  const allUsersUsages = useSelector((s) => s.data.subscriptions.subscriptionUsersUsage);
  if (!allUsersUsages) {
    throw InvariantException('UserProjectSettingsStatus.tsx: SubscriptionUsersUsage not loaded.');
  }

  const subscriptionUser = getSubscriptionUserUsage(allUsersUsages.subscriptionUsers, userId);
  if (!subscriptionUser) {
    throw InvariantException('UserProjectSettingsStatus.tsx: SubscriptionUser not found.');
  }

  const subscriptionId = allUsersUsages.subscriptionId;

  const isCustomPlan = useSelector((s) => getSubscriptionPlan(s, subscriptionId).isCustom);

  const isUserIncludedUnitsReached = useSelector(
    (s) => !isUserCountedInUsage(s, userId) && hasToUsePaidUserSlot(s, [subscriptionUser.email]),
  );

  const isUserMaxLimitReached = useSelector((s) => {
    const subscriptionUsages = s.data.subscriptions.subscriptionUsages;
    const subscriptionUsage = getSubscriptionUsage(subscriptionUsages, subscriptionId);
    const plans = s.data.plans.byId;
    const numberOfUsersInActivation = getNumberOfUsersInActivation(s);

    return (
      !isUserCountedInUsage(s, userId) &&
      !hasAvailableUserSlots(subscriptionUsage, plans, numberOfUsersInActivation)
    );
  });

  const {
    hasNonProjectManagerRole,
    isActiveGlobal,
    isActivating,
    isDeactivating,
    isInvitationPending,
    isInviteRevoking,
    isInviteSending,
    isInviteSent,
    isProjectManagerRole,
    projectIds,
  } = activeStatus;

  const disabled = subscriptionUser.isSubscriptionAdmin || isActivating || isDeactivating;

  const popoverTexts = getPopoverTexts(isActiveGlobal);

  const userActiveStatus: IUserActiveStatus = {
    isActive: isActiveGlobal,
    isActivating,
    isDeactivating,
    isInvitationPending,
  };

  // If user is being activated on any other project, an activation in this project cannot break limits
  const isActivatingOnAnyProject = subscriptionUser.userProjectsSettings.some(
    (ups) => ups.isActivating,
  );
  const activatingUserOverMaxLimit =
    !subscriptionUser.isEmployee &&
    !isActiveGlobal &&
    isUserMaxLimitReached &&
    !isActivatingOnAnyProject;
  const activatingUserOverIncludedUnitsLimit =
    !subscriptionUser.isEmployee &&
    !isActiveGlobal &&
    isUserIncludedUnitsReached &&
    !isActivatingOnAnyProject;

  const fullName = formatUserName(subscriptionUser);

  const tooltipText = getTooltipText(subscriptionUser.isSubscriptionAdmin, isActiveGlobal);

  const onSelectPlan = useCallback(
    () =>
      redirectToPlanSelection({
        history,
        subscriptionId,
      }),
    [history, subscriptionId],
  );

  const onShowIntercom = useCallback(() => {
    intercomUtils.showIntercom();
    intercomUtils.showMessage();
  }, [intercomUtils]);

  const onToggleUserActiveState = () =>
    dispatch(toggleUserActiveStateOnProjects(userId, subscriptionId, projectIds, !isActiveGlobal));

  const onRevokeInvite = () =>
    dispatch(
      revokeInviteToProjects(
        userId,
        subscriptionId,
        projectIds,
        isSingleProject ? onNavigateBack : undefined,
      ),
    );
  const onResendInvite = () =>
    dispatch(resendInviteToProjects(userId, subscriptionUser.email, projectIds));

  return isInvitationPending ? (
    <PendingInvitationActions
      isAdminOnProject={subscriptionUser.isSubscriptionAdmin}
      isInviteRevoking={isInviteRevoking}
      isInviteSending={isInviteSending}
      isInviteSent={isInviteSent}
      onResendInvite={onResendInvite}
      onRevokeInvite={onRevokeInvite}
      placement={ActionsPlacement.SubscriptionUserDetail}
    />
  ) : (
    <UserActiveStatusToggleWithConfirmationDialog
      activatingUserOverMaxLimit={activatingUserOverMaxLimit}
      disabled={disabled}
      isCurrentUserAdmin
      isCustomPlan={isCustomPlan}
      popoverTexts={popoverTexts}
      showConfirmationDialog
      tooltipText={tooltipText}
      userActiveStatus={userActiveStatus}
      onSelectPlan={onSelectPlan}
      onShowIntercom={onShowIntercom}
      onToggleUserActiveState={onToggleUserActiveState}
      renderPopoverDialogContent={() => {
        return activatingUserOverMaxLimit ? (
          <SubscriptionPlanMaxUserLimitReachedWarning hideButton />
        ) : (
          <Stack spacing={Spacing.M}>
            <p>
              Are you sure you want to {isActiveGlobal ? 'deactivate' : 'activate'} user{' '}
              <strong>{fullName}</strong> in this project?
            </p>
            {activatingUserOverIncludedUnitsLimit && (
              <SubscriptionPlanIncludedUserLimitReachedWarning
                newEmails={[subscriptionUser.email]}
                isInActivationMode
              />
            )}
            {!isActiveGlobal && isProjectManagerRole && hasNonProjectManagerRole && (
              <Callout calloutType="friendlyWarning" headline="This user is a project manager">
                They’ll have access to all collections, languages, and environments in the project.
              </Callout>
            )}
          </Stack>
        );
      }}
    />
  );
};

UserProjectSettingsStatus.displayName = 'UserProjectSettingsStatus';
