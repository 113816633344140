import { Box } from '@kontent-ai/component-library/Box';
import { Spacing } from '@kontent-ai/component-library/tokens';
import React from 'react';
import { Route, Switch } from 'react-router';
import { MyProjectsTabs } from '../../applications/projects/components/MyProjectsTabs.tsx';
import { editableAppBarCreateButtonPortalId } from '../../applications/projects/constants/portalIds.ts';
import { SubscriptionSavingStatus } from '../../applications/subscriptionManagement/SubscriptionListing/containers/SubscriptionSavingStatus.tsx';
import { UserProfileSavingStatus } from '../../applications/userProfile/containers/UserProfileSavingStatus.tsx';
import {
  ActiveProjectsRoute,
  ArchivedProjectsRoute,
  SubscriptionEnvironmentSettingsRoute,
  SubscriptionRoute,
  SubscriptionsRoute,
  UserProfileRoute,
} from '../constants/routePaths.ts';
import { ApplicationBreadcrumbs } from '../containers/ApplicationBreadcrumbs.tsx';
import { HeaderNotificationBar } from '../containers/HeaderNotificationBar.tsx';
import { ProjectSortingDropdown } from '../containers/ProjectSortingDropdown.tsx';
import { EditableAppBar } from './EditableAppBar.tsx';
import { StatusBar } from './StatusBar/StatusBar.tsx';

export const Header: React.FC = () => (
  <Switch>
    <Route path={[ActiveProjectsRoute, ArchivedProjectsRoute]}>
      <EditableAppBar
        renderLeftContainer={() => (
          <Box paddingRight={Spacing.S}>
            <ApplicationBreadcrumbs />
          </Box>
        )}
        renderCentralContainer={(ref) => <MyProjectsTabs containerRef={ref} />}
        renderRightContainer={() => (
          <>
            <ProjectSortingDropdown />
            <div id={editableAppBarCreateButtonPortalId} />
          </>
        )}
      />
    </Route>
    <Route path={UserProfileRoute}>
      <EditableAppBar
        renderLeftContainer={() => null}
        renderCentralContainer={() => (
          <ApplicationBreadcrumbs renderSavingStatusComponent={() => <UserProfileSavingStatus />} />
        )}
        renderRightContainer={() => null}
      />
    </Route>
    <Route path={SubscriptionsRoute} exact>
      <EditableAppBar
        renderLeftContainer={() => null}
        renderCentralContainer={() => <ApplicationBreadcrumbs />}
        renderRightContainer={() => null}
      />
    </Route>
    <Route path={SubscriptionEnvironmentSettingsRoute}>
      <HeaderNotificationBar />
      <StatusBar />
    </Route>
    <Route path={SubscriptionRoute}>
      <HeaderNotificationBar />
      <EditableAppBar
        renderLeftContainer={() => null}
        renderCentralContainer={() => (
          <ApplicationBreadcrumbs
            renderSavingStatusComponent={() => <SubscriptionSavingStatus />}
          />
        )}
        renderRightContainer={() => null}
      />
    </Route>
    <Route>
      <HeaderNotificationBar />
      <StatusBar />
    </Route>
  </Switch>
);

Header.displayName = 'Header';
