import { forwardRef, useCallback, useEffect, useState } from 'react';
import { useLocation } from 'react-router';
import {
  ContentItemRoute,
  ContentItemRouteParams,
} from '../../../../../../_shared/constants/routePaths.ts';
import { useDispatch } from '../../../../../../_shared/hooks/useDispatch.ts';
import { useSelector } from '../../../../../../_shared/hooks/useSelector.ts';
import { useThunkPromise } from '../../../../../../_shared/hooks/useThunkPromise.ts';
import { getEditedContentItem } from '../../../../../../_shared/selectors/getEditedContentItem.ts';
import { IStore } from '../../../../../../_shared/stores/IStore.type.ts';
import {
  matchPath,
  parseContentItemIds,
} from '../../../../../../_shared/utils/routing/routeTransitionUtils.ts';
import { shallowEqual } from '../../../../../../_shared/utils/shallowEqual.ts';
import { getCurrentProjectId } from '../../../../../../data/reducers/user/selectors/userProjectsInfoSelectors.ts';
import { SpaceSelectorInWebSpotlightStorage } from '../../../../../../localStorages/spaceSelectorInWebSpotlightStorage.ts';
import { loadItemPreviewInfo } from '../../../../../webSpotlight/actions/thunkWebSpotlightActions.ts';
import { itemPreviewInfoReloadRequested } from '../../../../../webSpotlight/actions/webSpotlightActions.ts';
import { PreviewError, PreviewUrlInfo } from '../../../../utils/previewUtils.ts';
import { ContentItemPreview as ContentItemPreviewComponent } from '../../components/contentItemPreview/ContentItemPreview.tsx';
import {
  PreviewMetadata,
  WebSpotlightInItemEditingContextProvider,
} from '../../context/WebSpotlightInItemEditingContext.tsx';
import { getSpacesForPreviewUrls } from '../../utils/getAvailableSpacesForPreviewUrls.ts';
import { getAvailablePreviewLinksForUser } from '../selectors/getAvailablePreviewLinksForUser.tsx';

export const ContentItemPreview = forwardRef<HTMLDivElement>((_, ref) => {
  const { id: contentItemId } = useSelector(getEditedContentItem);
  const { spaceId, setSpaceId } = usePersistentSpace();
  const previewMetadata = useSelector((state) => getPreviewMetadata(state, spaceId));

  const { isLoading: isPreviewLoading } = usePreview(spaceId);

  if (!previewMetadata || isPreviewLoading) {
    return null;
  }

  return (
    <WebSpotlightInItemEditingContextProvider previewMetadata={previewMetadata} spaceId={spaceId}>
      <ContentItemPreviewComponent
        contentItemId={contentItemId}
        spaceId={spaceId}
        setSpaceId={setSpaceId}
        ref={ref}
      />
    </WebSpotlightInItemEditingContextProvider>
  );
});

const usePersistentSpace = () => {
  const environmentId = useSelector(getCurrentProjectId);

  const availableSpaces = useSelector((s) => getSpacesForPreviewUrls(s), shallowEqual);

  const [spaceId, setSpaceId] = useState<Uuid | null>(null);

  const setAndStoreSpaceId = useCallback(
    (newSpaceId: Uuid): void => {
      setSpaceId(newSpaceId);
      SpaceSelectorInWebSpotlightStorage.save(newSpaceId, environmentId);
    },
    [environmentId],
  );

  useEffect(() => {
    const lastSelectedSpaceId = SpaceSelectorInWebSpotlightStorage.load(environmentId);

    if (lastSelectedSpaceId && availableSpaces.find((s) => s.id === lastSelectedSpaceId)) {
      setSpaceId(lastSelectedSpaceId);
    } else if (availableSpaces[0]) {
      setAndStoreSpaceId(availableSpaces[0].id);
    }
  }, [availableSpaces, environmentId, setAndStoreSpaceId]);

  return {
    spaceId,
    setSpaceId: setAndStoreSpaceId,
  };
};

const noPreviewUrlMetadata: PreviewMetadata = {
  url: '',
  usedUrlSlug: null,
  error: PreviewError.NoPreview,
};

const getPreviewMetadata = (state: IStore, selectedSpaceId: Uuid | null): PreviewMetadata => {
  const availableSpaces = getSpacesForPreviewUrls(state);
  const availablePreviewLinks = getAvailablePreviewLinksForUser(state);

  const previewLink =
    availableSpaces.length > 0 && selectedSpaceId
      ? (availablePreviewLinks.find((l) => l.spaceId === selectedSpaceId) ??
        availablePreviewLinks[0])
      : availablePreviewLinks[0];

  return isPreviewMetadata(previewLink) ? previewLink : noPreviewUrlMetadata;
};

const isPreviewMetadata = (
  previewUrlInfo: Pick<PreviewUrlInfo, 'error' | 'url' | 'usedUrlSlug'> | undefined,
): previewUrlInfo is PreviewMetadata =>
  !!previewUrlInfo && (!!previewUrlInfo.url || !!previewUrlInfo.error);

const usePreview = (spaceId: Uuid | null) => {
  const location = useLocation();
  const dispatch = useDispatch();
  const itemPreviewInfo = useSelector((state) => state.webSpotlightApp.itemPreviewInfo);
  const isPreviewConfigurationLoaded = useSelector(
    (state) => !!state.contentApp.previewConfiguration,
  );

  const currentPath = location.pathname;
  const matchedParams = matchPath<ContentItemRouteParams<string>>(currentPath, ContentItemRoute);
  const routeContentItemIds = matchedParams
    ? parseContentItemIds(matchedParams.contentItemIds)
    : [];
  const isCorrectPreviewInfoLoaded = itemPreviewInfo?.routeContentItemIds === routeContentItemIds;

  useEffect(() => {
    dispatch(itemPreviewInfoReloadRequested());
  }, []);

  useThunkPromise(loadItemPreviewInfo, routeContentItemIds, spaceId, {
    canRun: !isCorrectPreviewInfoLoaded && isPreviewConfigurationLoaded,
  });

  return {
    isLoading: !itemPreviewInfo || !isCorrectPreviewInfoLoaded || !isPreviewConfigurationLoaded,
  };
};
