import { trackUserEvent } from '../../../../_shared/actions/thunks/trackUserEvent.ts';
import { repositoryCollection } from '../../../../_shared/repositories/repositories.ts';
import { loadProjects, loadUserProjectsInfo } from '../../../../data/actions/thunkDataActions.ts';
import { createSaveEnvironmentNameToServerAction } from './thunks/saveEnvironmentNameToServer.ts';

const { projectRepository } = repositoryCollection;

export const saveEnvironmentNameToServer = createSaveEnvironmentNameToServerAction({
  projectRepository,
  loadUserProjectsInfo,
  loadProjects,
  trackUserEvent,
});
