import { ThunkPromise } from '../../../../../@types/Dispatcher.type.ts';
import { DateRange } from '../../../../../_shared/models/DateRange.type.ts';
import { TrackUserEventAction } from '../../../../../_shared/models/TrackUserEvent.type.ts';
import { HotjarTag, tagRecording } from '../../../../../_shared/utils/hotjarUtils.ts';
import { getFormattedDateRangeForGivenDateEntry } from '../../utils/calendarUtils.ts';
import {
  calendarInitializationFinished,
  calendarInitializationStarted,
} from '../calendarActions.ts';

interface IDeps {
  readonly loadItemsMatchingFilterWithVariantActiveInPeriod: (
    period: DateRange,
    abortSignal?: AbortSignal,
  ) => ThunkPromise;
  readonly trackUserEvent: TrackUserEventAction;
}

export const createInitializeCalendarAction =
  (deps: IDeps) =>
  (abortSignal: AbortSignal): ThunkPromise =>
  async (dispatch, getState) => {
    const {
      calendarApp: { selectedPeriod },
      contentApp: {
        listingUi: { filter },
      },
    } = getState();

    dispatch(calendarInitializationStarted());

    const period =
      selectedPeriod.from === selectedPeriod.to
        ? getFormattedDateRangeForGivenDateEntry(selectedPeriod.from)
        : selectedPeriod;

    dispatch(calendarInitializationFinished(filter));

    await dispatch(deps.loadItemsMatchingFilterWithVariantActiveInPeriod(period, abortSignal));

    tagRecording(HotjarTag.CalendarOpened);
  };
