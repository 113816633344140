import { Icons } from '@kontent-ai/component-library/Icons';
import { NotificationBarAlert } from '@kontent-ai/component-library/NotificationBar';
import { gridUnit } from '@kontent-ai/component-library/tokens';
import { ReactNode } from 'react';
import { ModalDialog } from '../../../../../component-library/components/Dialogs/ModalDialog/ModalDialog.tsx';
import {
  DataUiAction,
  getDataUiActionAttribute,
} from '../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';
import { InitialProjectType } from '../../constants/initialProjectType.ts';
import { Step } from '../../containers/projects/NewProjectModal.tsx';

type Props = {
  readonly errorMessage: string | null;
  readonly onClose: (() => void) | undefined;
  readonly onGoBack: (() => void) | null;
  readonly onNotificationBarDismiss: () => void;
  readonly onPrimaryActionClick: (() => void) | undefined;
  readonly progressMessage: string | null;
  readonly step: Step;
  readonly submitButtonTooltipText: string | null;
  readonly children: ReactNode;
};

export const NewProjectModal = (props: Props) => {
  return (
    <ModalDialog
      isOpen
      minWidth={`min(90vw, ${maxModalWidth}px)`}
      isDismissable
      onClose={props.onClose}
      headline={createModalHeadline(props.step)}
      extraAction={
        props.onGoBack
          ? {
              text: 'Back',
              iconBefore: Icons.ArrowDoubleLeft,
              onClick: props.onGoBack,
              disabled: !!props.progressMessage,
            }
          : undefined
      }
      primaryAction={{
        text: createPrimaryActionText(props.step, props.progressMessage),
        iconAfter: props.step.type === 'projectType' ? Icons.ArrowDoubleRight : undefined,
        type: 'submit',
        disabled: !!props.submitButtonTooltipText,
        tooltipText: props.submitButtonTooltipText,
        ...getDataUiActionAttribute(DataUiAction.CreateNew),
        onClick: props.onPrimaryActionClick,
      }}
      renderNotificationBar={
        props.errorMessage
          ? () => (
              <NotificationBarAlert onDismiss={props.onNotificationBarDismiss}>
                {props.errorMessage}
              </NotificationBarAlert>
            )
          : undefined
      }
    >
      {props.children}
    </ModalDialog>
  );
};

const maxModalWidth = gridUnit * 120;

const createModalHeadline = (step: Step) => {
  if (step.type === 'projectType') {
    return 'Choose a template for your new project';
  }
  switch (step.projectType) {
    case InitialProjectType.Copy:
      return 'Copy project';
    case InitialProjectType.Blank:
      return 'Create a new project';
    case InitialProjectType.MultiSite:
      return 'Create a multi-site sample project';
    case InitialProjectType.Kickstart:
      return 'Create a kickstart sample project';
  }
};

const createPrimaryActionText = (step: Step, progressMessage: string | null) => {
  if (step.type === 'projectType') {
    return '1/2 Next Step';
  }
  if (!progressMessage && step.projectType === InitialProjectType.Copy) {
    return 'Copy project';
  }
  return progressMessage ?? 'Create project';
};

NewProjectModal.displayName = 'NewProjectModal';
