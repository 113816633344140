import { Button } from '@kontent-ai/component-library/Button';
import { Collection } from '@kontent-ai/utils';
import { FC } from 'react';
import { useDispatch } from '../../../../../../../../_shared/hooks/useDispatch.ts';
import { useSelector } from '../../../../../../../../_shared/hooks/useSelector.ts';
import { ContentItemEditingEventOrigins } from '../../../../../../../../_shared/models/events/ContentItemEditingEventData.type.ts';
import {
  DataUiWorkflowAction,
  ItemEditingForms,
  getDataUiWorkflowActionAttribute,
} from '../../../../../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';
import { ItemEditingModalDialogType } from '../../../../../../constants/itemEditingModalDialogType.ts';
import { changeContributors } from '../../../../actions/thunkContentItemEditingActions.ts';
import { ContributorsList } from '../../../../components/details/Contributors/ContributorsList.tsx';
import { ClickableSection } from '../../../../components/sidebar/sectionTypes/ClickableSection.tsx';
import { getContributorState } from '../../../selectors/getContributorState.ts';

export const ClickableContributorsSection: FC = () => {
  const dispatch = useDispatch();
  const onClick = () =>
    dispatch(
      changeContributors(
        ContentItemEditingEventOrigins.ItemDetails,
        ItemEditingModalDialogType.ContentItemAssignContributorsDialog,
      ),
    );

  const { currentUserId, initialContributors } = useSelector(getContributorState);

  const hasContributors = !Collection.isEmpty(initialContributors);

  return (
    <ClickableSection
      dataUiActionAttribute={DataUiWorkflowAction.OpenContributorsSelection}
      dataUiNameAttribute={ItemEditingForms.Contributors}
      onEditClick={onClick}
    >
      {hasContributors ? (
        <ContributorsList contributors={initialContributors} currentUserId={currentUserId} />
      ) : (
        <Button
          buttonStyle="secondary"
          size="small"
          onClick={onClick}
          {...getDataUiWorkflowActionAttribute(DataUiWorkflowAction.OpenContributorsSelection)}
        >
          Assign contributors
        </Button>
      )}
    </ClickableSection>
  );
};
