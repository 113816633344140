import { loadAvailableProjectLocations } from '../../../_shared/actions/thunkSharedActions.ts';
import { trackUserEvent } from '../../../_shared/actions/thunks/trackUserEvent.ts';
import { repositoryCollection } from '../../../_shared/repositories/repositories.ts';
import {
  loadAdministratedSubscriptions,
  loadProjects,
  loadUserInfo,
  loadUserProjectsInfo,
  startPollingProjects,
} from '../../../data/actions/thunkDataActions.ts';
import { loadSubscriptionUsage } from '../../subscriptionManagement/shared/actions/thunkSubscriptionManagementActions.ts';
import { activateTrialSubscriptionCreator } from './thunks/activateTrialSubscription.ts';
import { createCloneProjectAction } from './thunks/cloneProject.ts';
import { createCreateEmptyProjectAction } from './thunks/createEmptyProject.ts';
import { createCreateProjectFromTemplateAction } from './thunks/createProjectFromTemplate.ts';
import { createSampleProjectCreator } from './thunks/createSampleProject.ts';
import { deleteProjectCreator } from './thunks/deleteProject.ts';
import { createInitializeProjectManagementAction } from './thunks/initializeProjectManagement.ts';
import { createToggleProjectActiveStateAction } from './thunks/toggleProjectActiveState.ts';

const { projectRepository, subscriptionRepository } = repositoryCollection;

export const createSampleProject = createSampleProjectCreator({
  loadProjects,
  loadUserProjectsInfo,
  projectRepository,
  trackUserEvent,
});

export const activateTrialSubscription = activateTrialSubscriptionCreator({
  subscriptionRepository,
  loadUserProjectsInfo,
  loadAdministratedSubscriptions,
  loadUserInfo,
});

export const createEmptyProject = createCreateEmptyProjectAction({
  projectRepository,
  trackUserEvent,
  loadProjects,
  loadUserProjectsInfo,
});

export const createProjectFromTemplate = createCreateProjectFromTemplateAction({
  projectRepository,
  trackUserEvent,
  loadProjects,
  loadUserProjectsInfo,
});

export const cloneProject = createCloneProjectAction({
  projectRepository,
  loadProjects,
  loadUserProjectsInfo,
});

export const deleteProject = deleteProjectCreator({
  projectRepository,
  loadUserProjectsInfo,
  loadAdministratedSubscriptions,
  loadSubscriptionUsage,
  loadProjects,
  trackUserEvent,
});

export const toggleProjectActiveState = createToggleProjectActiveStateAction({
  projectRepository,
  trackUserEvent,
  loadUserProjectsInfo,
  loadAdministratedSubscriptions,
  loadSubscriptionUsage,
  loadProjects,
});

export const initializeProjectManagement = createInitializeProjectManagementAction({
  loadAvailableProjectLocations,
  loadProjects,
  startPollingProjects,
});
