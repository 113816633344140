import { assert } from '@kontent-ai/utils';
import { History } from 'history';
import { Dispatch, GetState, ThunkPromise } from '../../../../../../@types/Dispatcher.type.ts';
import { trackUserEvent } from '../../../../../../_shared/actions/thunks/trackUserEvent.ts';
import { TrackedEvent } from '../../../../../../_shared/constants/trackedEvent.ts';
import { ConfirmationDialogEventTypes } from '../../../../../../_shared/models/TrackUserEventData.ts';
import { ContentItemEditingEventOrigins } from '../../../../../../_shared/models/events/ContentItemEditingEventData.type.ts';
import { IStore } from '../../../../../../_shared/stores/IStore.type.ts';
import { getUsersInfo } from '../../../../../../_shared/utils/users/usersUtils.ts';
import { getWorkflow } from '../../../../../../data/reducers/workflow/selectors/workflowSelectors.ts';
import { ItemEditingModalDialogType } from '../../../../constants/itemEditingModalDialogType.ts';
import { IAssignment } from '../../../../models/contentItem/Assignment.ts';
import { getItemEditingModalDialogProperties } from '../../../../selectors/getItemEditingModalDialogPropeties.ts';
import { createAssignmentWithArchivedStep } from '../../../../utils/workflowUtils.ts';
import { AssignmentSections } from '../../constants/AssignmentSections.ts';
import { contentItemEditingSidebarSectionCleanedUp } from '../contentItemEditingActions.ts';
import { IUpdateAssignmentSectionParams } from './updateAssignment.ts';

const getModalProps = getItemEditingModalDialogProperties(
  ItemEditingModalDialogType.MoveItemToArchivedStepConfirmationDialog,
);

const getActionOrigin = (state: IStore): ContentItemEditingEventOrigins => {
  const modalProperties = getModalProps(state);

  assert(
    modalProperties,
    () =>
      `${__filename}: Provide modal properties with an origin action to track this action correctly.`,
  );

  return modalProperties.actionOrigin;
};

const getAssignment = (state: IStore): IAssignment => {
  const {
    data: {
      user: { info },
      users: { usersById },
    },
  } = state;

  const contributorsIdentifiers =
    state.contentApp.editedContentItemVariant?.assignment.assignees ?? new Set();
  const contributors = getUsersInfo(contributorsIdentifiers, usersById);
  const workflowId = getModalProps(state)?.workflowId;
  const currentWorkflow = workflowId && getWorkflow(state, workflowId);

  assert(
    currentWorkflow,
    () => `moveItemToArchivedStep.ts: Did not find workflow with id ${workflowId}.`,
  );

  return createAssignmentWithArchivedStep(
    currentWorkflow.id,
    currentWorkflow.archivedStep,
    info,
    contributors,
  );
};

interface IDeps {
  readonly updateAssignmentSection: (args: IUpdateAssignmentSectionParams) => ThunkPromise;
}

export const createMoveItemToArchivedStepAction =
  (deps: IDeps) =>
  (history: History): ThunkPromise =>
  async (dispatch: Dispatch, getState: GetState): Promise<void> => {
    const state = getState();

    await dispatch(
      deps.updateAssignmentSection({
        history,
        actionOrigin: getActionOrigin(state),
        getUpdatedAssignment: () => getAssignment(state),
        submittingSection: AssignmentSections.WorkflowStep,
      }),
    );

    dispatch(
      trackUserEvent(TrackedEvent.MoveItemToArchivedStepConfirmationDialog, {
        action: ConfirmationDialogEventTypes.Confirm,
      }),
    );

    dispatch(contentItemEditingSidebarSectionCleanedUp());
  };
