import { Toggle } from '@kontent-ai/component-library/Toggle';
import {
  DataUiElement,
  getDataUiElementAttribute,
} from '../../../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';
import { SideBySidePreviewOnboarding } from '../../containers/editingActions/SideBySidePreviewOnboarding.tsx';

type PreviewToggleProps = Readonly<{
  isActive: boolean;
  onToggle?: (newStatus: boolean) => void;
}>;

export const PreviewToggle = ({ isActive, onToggle }: PreviewToggleProps) => {
  return (
    <SideBySidePreviewOnboarding
      renderTarget={(props) => (
        <Toggle
          labelText="Preview"
          status={isActive ? 'on' : 'off'}
          tooltipPlacement="bottom-start"
          onToggleOn={() => onToggle?.(true)}
          onToggleOff={() => onToggle?.(false)}
          {...props}
          {...getDataUiElementAttribute(DataUiElement.ContentItemPreviewToggle)}
        />
      )}
    />
  );
};
