import { memoize } from '@kontent-ai/memoization';
import React from 'react';
import { ISubscription, SubscriptionStatus } from '../../data/models/subscriptions/Subscription.ts';
import {
  getSelectedSubscription,
  getSubscriptionPlan,
  isAdminOnSubscription,
} from '../../data/reducers/subscriptions/selectors/subscriptionSelectors.ts';
import {
  getCurrentProjectContainerSubscription,
  getCurrentProjectSubscription,
} from '../../data/reducers/user/selectors/userProjectsInfoSelectors.ts';
import {
  HeaderNotificationBar as HeaderNotificationBarComponent,
  HeaderNotifications,
} from '../components/HeaderNotificationBar/HeaderNotificationBar.tsx';
import { useSelector } from '../hooks/useSelector.ts';
import { selectIntercomUtils } from '../selectors/selectIntercomUtils.ts';
import { IStore } from '../stores/IStore.type.ts';

const getVisibleNotifications = memoize.maxOne(
  (subscription: ISubscription): ReadonlySet<HeaderNotifications> => {
    let visibleNotifications = new Set<HeaderNotifications>();

    if (subscription.subscriptionId) {
      if (subscription.status === SubscriptionStatus.Canceled) {
        visibleNotifications = visibleNotifications.add(
          HeaderNotifications.CanceledSubscriptionNotification,
        );
      }
      if (subscription.status === SubscriptionStatus.Expired) {
        visibleNotifications = visibleNotifications.add(
          HeaderNotifications.ExpiredSubscriptionNotification,
        );
      }
      if (subscription.status === SubscriptionStatus.Suspended) {
        visibleNotifications = visibleNotifications.add(
          HeaderNotifications.SuspendedSubscriptionNotification,
        );
      }
      if (
        subscription.status === SubscriptionStatus.Active &&
        subscription.subscriptionDetails.wasCreditCardNotificationSent
      ) {
        visibleNotifications = visibleNotifications.add(
          HeaderNotifications.LimitsExceededNotification,
        );
      }
    }
    return visibleNotifications;
  },
);

const getSubscription = (state: IStore): ISubscription => {
  const selectedSubscription = getSelectedSubscription(state);
  if (selectedSubscription?.subscriptionId) {
    return selectedSubscription;
  }

  const projectContainerSubscription = getCurrentProjectContainerSubscription(state);
  if (projectContainerSubscription.subscriptionId) {
    return projectContainerSubscription;
  }

  return getCurrentProjectSubscription(state);
};

export const HeaderNotificationBar: React.FC = () => {
  const subscription = useSelector(getSubscription);
  const subscriptionPlan = useSelector((s) => getSubscriptionPlan(s, subscription.subscriptionId));
  const currentUserHasMoreSubscriptions = useSelector((s) => s.data.subscriptions.byId.count() > 1);
  const isSubscriptionAdmin = useSelector((s) =>
    isAdminOnSubscription(s, subscription.subscriptionId),
  );
  const intercomUtils = useSelector(selectIntercomUtils);

  const visibleNotifications = getVisibleNotifications(subscription);

  return (
    <HeaderNotificationBarComponent
      currentUserHasMoreSubscriptions={currentUserHasMoreSubscriptions}
      isSubscriptionAdmin={isSubscriptionAdmin}
      onContactSupport={intercomUtils.showIntercom}
      subscription={subscription}
      subscriptionPlan={subscriptionPlan}
      visibleNotifications={visibleNotifications}
    />
  );
};

HeaderNotificationBar.displayName = 'HeaderNotificationBar';
