import { Stack } from '@kontent-ai/component-library/Stack';
import { Spacing } from '@kontent-ai/component-library/tokens';
import React from 'react';
import { Route } from 'react-router';
import { HtmlSettingsPageTitle } from '../../../../../_shared/components/HtmlSettingsPageTitle.tsx';
import { PageTitle } from '../../../../../_shared/components/PageTitle.tsx';
import {
  InviteModalRouteParams,
  InviteUserRoute,
  SubscriptionEnvironmentInviteUserRoute,
  SubscriptionEnvironmentUserDetailRoute,
  UserDetailRoute,
  UserDetailRouteParams,
} from '../../../../../_shared/constants/routePaths.ts';
import { AuthorizedSection } from '../../../../../_shared/containers/routing/AuthorizedSection.tsx';
import {
  IRouteContext,
  RouteContext,
} from '../../../../../_shared/containers/routing/RouteContext.tsx';
import {
  DataUiAppName,
  getDataUiAppNameAttribute,
} from '../../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';
import { Capability } from '../../../../../_shared/utils/permissions/capability.ts';
import { EnvironmentSettingsAppNames } from '../../../root/constants/EnvironmentSettingsAppNames.ts';
import { InvitationModal } from '../../containers/invitations/InvitationModal.tsx';
import { UserDetail } from '../../containers/userDetail/UserDetail.tsx';
import { UserDetailDataEnsurer } from '../../containers/userDetail/UserDetailDataEnsurer.tsx';
import { SuspendedSubscriptionWarning } from '../../containers/userListing/SuspendedSubscriptionWarning.tsx';
import { UserListingDataTable } from '../../containers/userListing/UserListingDataTable.tsx';
import { UserListingFilter } from '../../containers/userListing/UserListingFilter.tsx';
import { UserListingState } from '../../utils/getUserListingState.ts';

type Props = {
  readonly onCreateUserDetailLink: (userId: Uuid) => string;
  readonly state: UserListingState;
  readonly userListingLink: string;
};

export const UserListing: React.FC<Props> = ({
  onCreateUserDetailLink,
  state,
  userListingLink,
}) => (
  <div
    className="canvas__inner-section canvas__inner-section--restricted-width"
    {...getDataUiAppNameAttribute(DataUiAppName.Users)}
  >
    <PageTitle css={`margin-bottom: ${Spacing.XL}px`}>
      {EnvironmentSettingsAppNames.Users}
    </PageTitle>
    <SuspendedSubscriptionWarning />
    <HtmlSettingsPageTitle settingsAppName={EnvironmentSettingsAppNames.Users} />
    <Stack spacing={Spacing.XL}>
      {state !== UserListingState.EmptyProject && <UserListingFilter />}
      <UserListingDataTable createUserDetailLink={onCreateUserDetailLink} state={state} />
    </Stack>
    <Route path={[InviteUserRoute, SubscriptionEnvironmentInviteUserRoute]}>
      <RouteContext>
        {({ match }: IRouteContext<InviteModalRouteParams>) => (
          <AuthorizedSection
            appName={EnvironmentSettingsAppNames.Users}
            requiresOneOfCapabilities={[Capability.ManageUsers]}
          >
            <InvitationModal
              createUserDetailLink={onCreateUserDetailLink}
              initialUrlEncodedEmail={match.params.urlEncodedEmail}
              userListingLink={userListingLink}
            />
          </AuthorizedSection>
        )}
      </RouteContext>
    </Route>
    <Route path={[UserDetailRoute, SubscriptionEnvironmentUserDetailRoute]}>
      <RouteContext>
        {({ match }: IRouteContext<UserDetailRouteParams>) => (
          <AuthorizedSection
            appName={EnvironmentSettingsAppNames.Users}
            requiresOneOfCapabilities={[Capability.ManageUsers]}
          >
            <UserDetailDataEnsurer userId={match.params.userId} userListingLink={userListingLink}>
              {(user, onNavigateBack) => <UserDetail user={user} onNavigateBack={onNavigateBack} />}
            </UserDetailDataEnsurer>
          </AuthorizedSection>
        )}
      </RouteContext>
    </Route>
  </div>
);

UserListing.displayName = 'UserListing';
