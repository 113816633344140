import { Menu } from '@kontent-ai/component-library/Menu';
import { createGuid } from '@kontent-ai/utils';
import React, { useState } from 'react';
import { trackUserEvent } from '../../../../../../../_shared/actions/thunks/trackUserEvent.ts';
import { TrackedEvent } from '../../../../../../../_shared/constants/trackedEvent.ts';
import { useDispatch } from '../../../../../../../_shared/hooks/useDispatch.ts';
import { useSelector } from '../../../../../../../_shared/hooks/useSelector.ts';
import { ContentItemEditingNavigationAction } from '../../../../../../../_shared/models/events/ContentItemEditingEventData.type.ts';
import { IStore } from '../../../../../../../_shared/stores/IStore.type.ts';
import { buildPath } from '../../../../../../../_shared/utils/routing/routeTransitionUtils.ts';
import { getUrlFactory } from '../../../../../../../_shared/utils/urlFactory.ts';
import {
  EditItemRoute,
  EditItemRouteParams,
} from '../../../../../../smartLink/constants/routePaths.ts';
import { deactivateContentItemEditingAction } from '../../../actions/contentItemEditingActions.ts';
import { EditingActionItemsProps } from '../../../components/editingActions/EditingActionSubmenuItems.tsx';
import { selectPreviewLinks } from '../../selectors/selectPreviewLinks.ts';

enum FieldName {
  ItemLink = 'ItemLink',
  PreviewLink = 'PreviewLink',
}

export const ShareItemOptions: React.FC<EditingActionItemsProps> = ({ actionOrigin }) => {
  // eslint-disable-next-line react/hook-use-state
  const [linkId] = useState(() => createGuid().replace(/-/g, ''));

  const itemId = useSelector((s) => s.contentApp.editedContentItemVariant?.id);
  const previewLinks = useSelector(selectPreviewLinks);
  const itemLink = useSelector((s) => getItemLink(s, linkId));
  const dispatch = useDispatch();

  const onCopyUsed = (fieldName: FieldName) => {
    if (!itemId) {
      return;
    }

    dispatch(
      trackUserEvent(TrackedEvent.FeatureUsed, {
        feature: 'share-dialog--opened',
        info: JSON.stringify({
          itemId: itemId?.itemId,
          variantId: itemId?.variantId,
          linkId,
        }),
      }),
    );

    dispatch(
      trackUserEvent(TrackedEvent.ContentItemLinkCopied, {
        itemId: itemId.itemId,
        variantId: itemId.variantId,
        fieldName,
        linkId,
      }),
    );

    dispatch(
      trackUserEvent(TrackedEvent.ContentItemEditing, {
        action:
          fieldName === FieldName.PreviewLink
            ? ContentItemEditingNavigationAction.SharePreviewLink
            : ContentItemEditingNavigationAction.ShareItemLink,
        origin: actionOrigin,
      }),
    );
  };

  return (
    <>
      <Menu.ClipboardItem
        id="copy-item-link"
        label="Copy item link"
        value={itemLink}
        onCopySuccess={() => {
          onCopyUsed(FieldName.ItemLink);
          dispatch(dispatch(deactivateContentItemEditingAction()));
        }}
      />
      {previewLinks.map(
        (link) =>
          link.url && (
            <Menu.ClipboardItem
              key={link.spaceName ?? 'space-id'}
              id={link.spaceName ?? 'space-id'}
              value={link.url}
              label={`Share preview link${link.spaceName ? ` (${link.spaceName})` : ''}`}
              onCopySuccess={() => {
                onCopyUsed(FieldName.PreviewLink);
                dispatch(dispatch(deactivateContentItemEditingAction()));
              }}
            />
          ),
      )}
    </>
  );
};

const getItemLink = (state: IStore, linkId: string): string => {
  const {
    sharedApp: { currentProjectId },
    contentApp: { editedContentItemVariant },
  } = state;

  const itemId = editedContentItemVariant?.id;

  if (!itemId) {
    return '';
  }

  return `${getUrlFactory().getDraftUiRootUrl()}${buildPath<EditItemRouteParams>(EditItemRoute, {
    projectId: currentProjectId,
    itemGuid: itemId.itemId,
    variantGuid: itemId.variantId,
  })}?linkId=${linkId}`;
};
