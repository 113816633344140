import { Box } from '@kontent-ai/component-library/Box';
import { Spacing } from '@kontent-ai/component-library/tokens';
import { forwardRef } from 'react';
import {
  DataUiElement,
  getDataUiElementAttribute,
} from '../../../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';
import { WebSpotlightZIndex } from '../../../../../webSpotlight/constants/WebSpotlightZIndex.ts';
import { ContentItemPreviewActionBar } from '../../containers/contentItemPreview/ContentItemPreviewActionBar.tsx';
import { PreviewRefreshErrorNotification } from '../../containers/contentItemPreview/PreviewRefreshErrorNotification.tsx';
import { ContentItemPreviewCanvas } from './ContentItemPreviewCanvas.tsx';

type ContentItemPreviewProps = Readonly<{
  contentItemId: Uuid;
  spaceId: Uuid | null;
  setSpaceId: (newState: Uuid) => void;
}>;

export const ContentItemPreview = forwardRef<HTMLDivElement, ContentItemPreviewProps>(
  ({ contentItemId, spaceId, setSpaceId }, ref) => {
    return (
      <Box
        css="grid-area: preview"
        display="flex"
        flexDirection="column"
        ref={ref}
        {...getDataUiElementAttribute(DataUiElement.ContentItemPreview)}
      >
        <ContentItemPreviewActionBar spaceId={spaceId} setSpaceId={setSpaceId} />
        <Box width="100%" height="100%" position="relative" flexGrow={1}>
          <Box
            padding={Spacing.S}
            position="absolute"
            width="100%"
            zIndex={WebSpotlightZIndex.PreviewErrorNotification}
          >
            <PreviewRefreshErrorNotification key={spaceId} />
          </Box>
          <ContentItemPreviewCanvas contentItemId={contentItemId} spaceId={spaceId} />
        </Box>
      </Box>
    );
  },
);
