import { CodenameTargetAge } from '../models/TrackUserEventData.ts';
import { selectEnabledAiFeatureSets } from '../selectors/aiSelectors.ts';
import { IStore } from '../stores/IStore.type.ts';
import { isUuid, uuidPattern } from './validation/typeValidators.ts';

const trackUrlParams = () => {
  const { pathname: path, search: query } = window.location;
  const pathPattern = path.replace(new RegExp(uuidPattern, 'gi'), '').toLowerCase();
  const queryParams = query
    .replace('?', '')
    .split('&')
    .filter((param) => !!param);

  return {
    path,
    ...(pathPattern === path ? undefined : { pathPattern }),
    ...(queryParams.length ? { queryParams } : undefined),
  };
};

const trackCurrentAiFeatureSets = (state: IStore) =>
  areUserAndProjectPropertiesLoaded(state)
    ? {
        aiFeatureSets: selectEnabledAiFeatureSets(state),
      }
    : undefined;

const trackCurrentObjectAge = ({ sharedApp: { isObjectNewlyCreated } }: IStore) =>
  isObjectNewlyCreated
    ? {
        age: isObjectNewlyCreated ? CodenameTargetAge.New : CodenameTargetAge.Existing,
      }
    : undefined;

const trackCurrentProjectId = ({ sharedApp: { currentProjectId } }: IStore) =>
  isUuid(currentProjectId)
    ? {
        project: currentProjectId,
      }
    : undefined;

const trackCurrentlyEditedItemVariantId = ({ contentApp: { editedContentItemVariant } }: IStore) =>
  editedContentItemVariant?.id;

const areUserAndProjectPropertiesLoaded = (state: IStore): boolean =>
  !!state.sharedApp.projectProperties && !!state.sharedApp.userProperties;

const getContextuallyTrackedDataInternal = (state: IStore) => ({
  ...trackUrlParams(),
  ...trackCurrentProjectId(state),
  ...trackCurrentlyEditedItemVariantId(state),
  ...trackCurrentObjectAge(state),
  ...trackCurrentAiFeatureSets(state),
});

export const getContextuallyTrackedData = (
  state: IStore,
): Readonly<ReturnType<typeof getContextuallyTrackedDataInternal>> =>
  getContextuallyTrackedDataInternal(state);
