import { Collection } from '@kontent-ai/utils';
import { ThunkPromise } from '../../../../../@types/Dispatcher.type.ts';
import { IProjectSpecificStorage } from '../../../../../localStorages/projectSpecificStorage.ts';
import { listingFilterChanged } from '../../../../contentInventory/content/features/ContentItemInventory/actions/contentItemInventoryActions.ts';
import { TrackFilterChange } from '../../../../contentInventory/content/features/ContentItemInventory/actions/thunks/loadListingItems/trackFilterChange.ts';
import { IListingFilter } from '../../../../contentInventory/content/models/filter/IListingFilter.ts';

interface IFilterContentItemActionDependencies {
  readonly ContentItemFilterStorage: IProjectSpecificStorage<IListingFilter> | null;
  readonly loadListingContentItemsForInventory: (params: {
    variantIds: ReadonlySet<Uuid>;
  }) => ThunkPromise;
  readonly trackFilterChange: TrackFilterChange;
}

export const createFilterContentItemsAction =
  ({
    ContentItemFilterStorage,
    loadListingContentItemsForInventory,
    trackFilterChange,
  }: IFilterContentItemActionDependencies) =>
  (variantIds: ReadonlySet<Uuid>): ThunkPromise =>
  async (dispatch, getState) => {
    const {
      data: { workflows },
      sharedApp: { currentProjectId },
      contentApp: {
        listingUi: { filter, savedFiltersUi },
      },
    } = getState();

    const previousFilter = savedFiltersUi.lastFilteredUsingSavedFilter
      ? null
      : (ContentItemFilterStorage?.load(currentProjectId) ?? null);

    dispatch(listingFilterChanged());
    dispatch(trackFilterChange(previousFilter, filter, Collection.getValues(workflows.byId)));

    ContentItemFilterStorage?.save(filter, currentProjectId);

    await dispatch(loadListingContentItemsForInventory({ variantIds }));
  };
