import { ThunkPromise } from '../../../../@types/Dispatcher.type.ts';
import { TrackedEvent } from '../../../../_shared/constants/trackedEvent.ts';
import { TrackUserEventAction } from '../../../../_shared/models/TrackUserEvent.type.ts';
import { logError } from '../../../../_shared/utils/logError.ts';
import {
  IEntityWebhookSetting,
  createEntityWebhookSettingDomainModel,
  createEntityWebhookSettingServerModel,
} from '../../../../data/models/webhooks/EntityWebhookSetting.ts';
import { IEntityWebhookServerModel } from '../../../../repositories/serverModels/IEntityWebhookServerModel.type.ts';
import { EntityWebhooks_EntityWebhook_UpdateCompleted } from '../../constants/entityWebhooksActionTypes.ts';
import { IEntityWebhookFormShape } from '../../models/IEntityWebhookFormShape.type.ts';
import { createEntityWebhookSettingFromFormValues } from '../../utils/createEntityWebhookSettingFromFormValues.ts';

interface IDeps {
  readonly entityWebhookRepository: {
    readonly update: (serverModel: IEntityWebhookServerModel) => Promise<IEntityWebhookServerModel>;
  };
  readonly trackUserEvent: TrackUserEventAction;
}

const completed = (webhook: IEntityWebhookSetting) =>
  ({
    type: EntityWebhooks_EntityWebhook_UpdateCompleted,
    payload: {
      webhook,
    },
  }) as const;

export type UpdateEntityWebhookActionsType = ReturnType<typeof completed>;

export const createUpdateEntityWebhookAction =
  (deps: IDeps) =>
  (
    formValues: IEntityWebhookFormShape,
    entityWebhookSetting: IEntityWebhookSetting,
  ): ThunkPromise =>
  async (dispatch) => {
    const entityWebhook = createEntityWebhookSettingFromFormValues(
      formValues,
      entityWebhookSetting,
    );
    try {
      const response = await deps.entityWebhookRepository.update(
        createEntityWebhookSettingServerModel(entityWebhook),
      );
      const webhookModel = createEntityWebhookSettingDomainModel(response);
      dispatch(deps.trackUserEvent(TrackedEvent.EntityWebhookSaved, { url: webhookModel.url }));
      dispatch(completed(webhookModel));
    } catch (error) {
      logError(`${__filename}: Failed to update webhook with id ${entityWebhook.id}.`, error);
      throw error;
    }
  };
