import { noOperation } from '@kontent-ai/utils';
import { DraftBlockRenderConfig, EditorProps as DraftJSEditorProps } from 'draft-js';
import Immutable from 'immutable';
import React, { useCallback, useMemo } from 'react';
import { DragSource } from '../../../../_shared/components/DragDrop/DragSource.tsx';
import { DndTypes } from '../../../../_shared/constants/dndTypes.ts';
import { IconName } from '../../../../_shared/constants/iconEnumGenerated.ts';
import { ControlAltShortcutTemplate } from '../../../../_shared/constants/shortcutSymbols.ts';
import { TrackedEvent } from '../../../../_shared/constants/trackedEvent.ts';
import { useSelector } from '../../../../_shared/hooks/useSelector.ts';
import { TrackUserEvent } from '../../../../_shared/models/TrackUserEvent.type.ts';
import {
  ContentItemEditingChangeAction,
  ContentItemEditingEventOrigins,
} from '../../../../_shared/models/events/ContentItemEditingEventData.type.ts';
import {
  DataUiElement,
  DataUiRteAction,
} from '../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';
import { Capability } from '../../../../_shared/utils/permissions/capability.ts';
import { currentUserHasCapabilities } from '../../../../_shared/utils/permissions/capabilityUtils.ts';
import { ModalMultipleAssetsSelector } from '../../../contentInventory/assets/features/ModalAssetSelector/containers/ModalMultipleAssetsSelector.tsx';
import { CannotViewAssetsMessage } from '../../../contentInventory/content/constants/cannotViewMessages.ts';
import { ElementType } from '../../../contentInventory/content/models/ContentItemElementType.ts';
import { AssetTileAction } from '../../../itemEditor/features/ContentItemEditing/components/elements/asset/AssetTileActions.tsx';
import { getAssetValidationSettings } from '../../../itemEditor/features/ContentItemEditing/containers/elements/asset/AssetTile.tsx';
import { RichTextAssetTile } from '../../../itemEditor/features/ContentItemEditing/containers/elements/asset/RichTextAssetTile.tsx';
import { ElementReference } from '../../../itemEditor/features/ContentItemEditing/containers/hooks/useItemElementReference.ts';
import { CommentThreadItemType } from '../../../itemEditor/models/comments/CommentThreadItem.ts';
import { useEditorApi } from '../../editorCore/hooks/useEditorApi.ts';
import { useEditorWithPlugin } from '../../editorCore/hooks/useEditorWithPlugin.tsx';
import { BaseEditorProps } from '../../editorCore/types/Editor.base.type.ts';
import { PluginComponent } from '../../editorCore/types/Editor.composition.type.ts';
import { None, Optional, WithoutProps } from '../../editorCore/types/Editor.contract.type.ts';
import { DecoratedEditor } from '../../editorCore/types/Editor.decorated.type.ts';
import {
  Apply,
  PluginProps,
  PluginState,
  Render,
} from '../../editorCore/types/Editor.plugins.type.ts';
import { EditorChangeReason } from '../../editorCore/types/EditorChangeReason.ts';
import { Decorator } from '../../editorCore/utils/decorable.ts';
import { BaseBlockType, BlockType } from '../../utils/blocks/blockType.ts';
import { isInTable } from '../../utils/blocks/blockTypeUtils.ts';
import { getBaseBlockType } from '../../utils/blocks/editorBlockGetters.ts';
import { IEditorBlockProps } from '../../utils/blocks/editorBlockUtils.ts';
import { getNewBlockPlaceholderType } from '../../utils/general/editorContentUtils.ts';
import {
  AssetLimitations,
  UnlimitedEditorLimitations,
} from '../apiLimitations/api/EditorFeatureLimitations.ts';
import {
  TableBlockCategoryFeature,
  TopLevelBlockCategoryFeature,
} from '../apiLimitations/api/editorLimitationUtils.ts';
import { EditAssetPlugin } from '../assets/EditAssetPlugin.tsx';
import { returnFocusFromCustomBlock } from '../behavior/CustomSelectionHandlingPlugin.tsx';
import { CommentsPlugin, CommentsPluginProps } from '../comments/CommentsPlugin.tsx';
import { getCommentSegmentIdFromBlock } from '../comments/api/editorCommentUtils.ts';
import { CustomBlocksPlugin } from '../customBlocks/CustomBlocksPlugin.tsx';
import { DraftJsEditorPlugin } from '../draftJs/DraftJsEditorPlugin.type.ts';
import { GetBaseBlockRenderMap } from '../draftJs/DraftJsPlugin.type.ts';
import { BaseBlockRenderMap, mergeBlockRenderMaps } from '../draftJs/utils/draftJsEditorUtils.ts';
import { DragDropPlugin } from '../dragDrop/DragDropPlugin.tsx';
import { DroppableCustomBlockWrapper } from '../dragDrop/components/DroppableCustomBlockWrapper.tsx';
import {
  ExecuteCommand,
  KeyboardShortcutsPlugin,
} from '../keyboardShortcuts/KeyboardShortcutsPlugin.tsx';
import { RichTextInputCommand } from '../keyboardShortcuts/api/EditorCommand.ts';
import { BlockToolbarPlugin, GetInsertBlockMenuItems } from '../toolbars/BlockToolbarPlugin.tsx';
import { CommandToolbarMenuItem } from '../toolbars/components/menu/EditorCommandMenu.tsx';
import { EditorImageApi } from './api/EditorImageApi.type.ts';
import { editorImageApi } from './api/editorImageApi.ts';
import { getImageAssetReference } from './api/editorImageUtils.ts';
import { InlineImageWithAutoScroll } from './containers/InlineImageWithAutoScroll.tsx';

type ImagesPluginProps = {
  readonly editedEntityName: string;
  readonly limitations: AssetLimitations;
  readonly element: ElementReference;
  readonly trackUserEvent: TrackUserEvent;
};

export type ImagesPlugin = DraftJsEditorPlugin<
  None,
  ImagesPluginProps,
  EditorImageApi,
  None,
  [
    Optional<CommentsPlugin>,
    DragDropPlugin,
    CustomBlocksPlugin,
    KeyboardShortcutsPlugin<RichTextInputCommand>,
    EditAssetPlugin,
    BlockToolbarPlugin,
  ]
>;

type ImageBlockCustomProps = Pick<
  PluginState<ImagesPlugin>,
  | 'createComment'
  | 'deleteCustomBlock'
  | 'editAsset'
  | 'getApi'
  | 'hoveringCollisionStrategy'
  | 'getEditorId'
  | 'onDragEnd'
  | 'onDragStart'
  | 'onMoveBlocks'
  | 'executeChange'
  | 'focus'
> &
  Pick<PluginProps<ImagesPlugin>, 'disabled' | 'element' | 'limitations'>;

const ImageBlock: React.FC<IEditorBlockProps<ImageBlockCustomProps>> = (props) => {
  const {
    block,
    blockProps: {
      createComment,
      deleteCustomBlock,
      disabled,
      editAsset,
      element: { contentComponentId, elementId },
      getApi,
      hoveringCollisionStrategy,
      getEditorId,
      limitations,
      onDragEnd,
      onDragStart,
      onMoveBlocks,
      executeChange,
      focus,
    },
    ...otherProps
  } = props;

  const { allowedBlocks, allowedTableBlocks } = getApi().getLimitations();

  const assetReference = getImageAssetReference(block);
  const commentSegmentId = getCommentSegmentIdFromBlock(block);
  const blockKey = block.getKey();

  const isImageInTable = isInTable(block);
  const ignoreAssetLimitations =
    !allowedBlocks.has(TopLevelBlockCategoryFeature.Images) ||
    (isImageInTable &&
      (!allowedBlocks.has(TopLevelBlockCategoryFeature.Tables) ||
        !allowedTableBlocks.has(TableBlockCategoryFeature.Images)));

  const validationSettings = useMemo(
    () =>
      getAssetValidationSettings(ignoreAssetLimitations ? UnlimitedEditorLimitations : limitations),
    [ignoreAssetLimitations, limitations],
  );

  if (!assetReference) {
    return null;
  }

  const commonActions: ReadonlyArray<AssetTileAction> = [
    AssetTileAction.Download,
    AssetTileAction.Delete,
    AssetTileAction.Edit,
  ];

  const actions: ReadonlyArray<AssetTileAction> = createComment
    ? [...commonActions, AssetTileAction.AddComment]
    : commonActions;

  return (
    <DroppableCustomBlockWrapper
      block={block}
      canUpdate={!disabled}
      className="rte__inline-image asset-tile-wrapper"
      hoveringCollisionStrategy={hoveringCollisionStrategy}
      key={blockKey}
      onMove={onMoveBlocks}
      parentId={getEditorId()}
      uiElement={DataUiElement.InlineImage}
      {...otherProps}
    >
      <DragSource
        onDragEnd={onDragEnd}
        onDragStart={() => onDragStart(blockKey)}
        parentId={getEditorId()}
        renderDraggable={(connectDragSource, isDragging) => (
          <InlineImageWithAutoScroll
            actions={actions}
            assetId={assetReference.id}
            commentSegmentId={commentSegmentId}
            connectDragSource={connectDragSource}
            contentComponentId={contentComponentId}
            disabled={!!disabled}
            elementId={elementId}
            isDragging={isDragging}
            onEdit={() => editAsset(assetReference.id)}
            onNewComment={
              createComment
                ? () => createComment(CommentThreadItemType.Comment, blockKey)
                : noOperation
            }
            onRemove={() => deleteCustomBlock(blockKey)}
            validationSettings={validationSettings}
            onFocusEscape={() => {
              focus();
              executeChange(returnFocusFromCustomBlock);
            }}
          />
        )}
        renderPreview={() => (
          <RichTextAssetTile
            actions={actions}
            assetId={assetReference.id}
            commentSegmentId={commentSegmentId}
            contentComponentId={contentComponentId}
            disabled={!!disabled}
            elementId={elementId}
            isDragging={false}
            onEdit={noOperation}
            onNewComment={noOperation}
            onRemove={noOperation}
            validationSettings={validationSettings}
          />
        )}
        sourceId={blockKey}
        type={DndTypes.Rich_Text_Image}
      />
    </DroppableCustomBlockWrapper>
  );
};

ImageBlock.displayName = 'ImageBlock';

type EditorWithImagesProps = Pick<ImagesPluginProps, 'limitations' | 'element'> &
  Pick<BaseEditorProps, 'disabled'> &
  Pick<Partial<CommentsPluginProps>, 'commentThreads'> &
  Pick<PluginState<ImagesPlugin>, 'editAsset'>;

const EditorWithImages: DecoratedEditor<WithoutProps<ImagesPlugin>, EditorWithImagesProps> = ({
  baseRender,
  disabled,
  editAsset,
  element,
  limitations,
  state,
}) => {
  const {
    createComment,
    deleteCustomBlock,
    draggedBlockKey,
    getEditorId,
    editorProps: { blockRendererFn: baseBlockRendererFn },
    getApi,
    hoveringCollisionStrategy,
    onDragEnd,
    onDragStart,
    onMoveBlocks,
    executeChange,
    focus,
  } = state;

  const blockProps: ImageBlockCustomProps = useMemo(
    () => ({
      createComment,
      deleteCustomBlock,
      disabled,
      draggedBlockKey,
      editAsset,
      getEditorId,
      element,
      getApi,
      hoveringCollisionStrategy,
      limitations,
      onDragEnd,
      onDragStart,
      onMoveBlocks,
      executeChange,
      focus,
    }),
    [
      createComment,
      deleteCustomBlock,
      disabled,
      draggedBlockKey,
      editAsset,
      getEditorId,
      element,
      getApi,
      hoveringCollisionStrategy,
      limitations,
      onDragEnd,
      onDragStart,
      onMoveBlocks,
      executeChange,
      focus,
    ],
  );

  const blockRendererFn = useCallback<Required<DraftJSEditorProps>['blockRendererFn']>(
    (block) => {
      const baseBlockType = getBaseBlockType(block);
      if (baseBlockType === BlockType.Image) {
        return {
          component: ImageBlock,
          props: blockProps,
          editable: false,
        };
      }

      return baseBlockRendererFn?.(block) ?? null;
    },
    [baseBlockRendererFn, blockProps],
  );

  const stateWithImages: PluginState<ImagesPlugin> = {
    ...state,
    editorProps: {
      ...state.editorProps,
      blockRendererFn,
    },
  };

  return baseRender(stateWithImages);
};

EditorWithImages.displayName = 'EditorWithImages';

const imageRenderMap: BaseBlockRenderMap = Immutable.Map<BaseBlockType, DraftBlockRenderConfig>({
  [BaseBlockType.Image]: {
    element: 'div',
  },
});

const getBaseBlockRenderMap: Decorator<GetBaseBlockRenderMap> = (baseGetBaseBlockRenderMap) => () =>
  mergeBlockRenderMaps(baseGetBaseBlockRenderMap(), imageRenderMap);

const insertImageMenuItem: CommandToolbarMenuItem = {
  label: 'Insert image',
  command: RichTextInputCommand.InsertAsset,
  shortcuts: ControlAltShortcutTemplate('A'),
  iconName: IconName.Picture,
  uiAction: DataUiRteAction.AddImage,
};

export const ImagesPlugin: PluginComponent<ImagesPlugin> = (props) => {
  const { disabled, editedEntityName, element, limitations, trackUserEvent } = props;

  const canViewAssets = useSelector((state) =>
    currentUserHasCapabilities(state, Capability.ViewAssets),
  );

  const render: Decorator<Render<ImagesPlugin>> = useCallback(
    (baseRender) => (state) => (
      <EditorWithImages
        baseRender={baseRender}
        disabled={disabled}
        editAsset={state.editAsset}
        element={element}
        limitations={limitations}
        state={state}
      />
    ),
    [disabled, element, limitations],
  );

  const getAddBlockMenuItems: Decorator<GetInsertBlockMenuItems> = useCallback(
    (baseGetAddBlockMenuItems) => () => [
      ...baseGetAddBlockMenuItems(),
      {
        ...insertImageMenuItem,
        disabled: !canViewAssets,
        disabledTooltipText: canViewAssets ? undefined : CannotViewAssetsMessage,
      },
    ],
    [canViewAssets],
  );

  const apply: Apply<ImagesPlugin> = useCallback(
    (state) => {
      state.render.decorate(render);
      state.getInsertBlockMenuItems.decorate(getAddBlockMenuItems);
      state.getBaseBlockRenderMap.decorate(getBaseBlockRenderMap);

      const executeCommand: Decorator<ExecuteCommand<RichTextInputCommand>> =
        (baseExecuteCommand) => (command, isShiftPressed) => {
          switch (command) {
            case RichTextInputCommand.InsertAsset: {
              state.createNewBlockPlaceholder(BlockType.Image);
              return true;
            }

            default:
              return baseExecuteCommand(command, isShiftPressed);
          }
        };

      state.executeCommand.decorate(executeCommand);

      const insertImages = (placeholderBlockKey: string, assetIds: ReadonlyArray<Uuid>): void => {
        state.executeChange((editorState) => {
          const newEditorState = state
            .getApi()
            .insertImages(editorState, placeholderBlockKey, assetIds, false);
          if (newEditorState !== editorState) {
            trackUserEvent(TrackedEvent.ContentItemEditing, {
              action: ContentItemEditingChangeAction.AssignAsset,
              origin: ContentItemEditingEventOrigins.Paper,
              contentElementType: ElementType.RichText,
              selectedItemsCount: assetIds.length,
            });
          }
          state.resetEditedBlockKey();
          return newEditorState;
        }, EditorChangeReason.Internal);
      };

      const renderModal: Decorator<Render<ImagesPlugin>> = (baseRenderModal) => (baseState) => {
        const { cancelNewBlock, editedBlockKey, editorState, focus } = baseState;

        if (editedBlockKey) {
          const content = editorState.getCurrentContent();
          const block = content.getBlockForKey(editedBlockKey);
          const newBlockType = getNewBlockPlaceholderType(block);
          if (newBlockType === BlockType.Image) {
            return (
              <ModalMultipleAssetsSelector
                isOpen
                limitations={limitations}
                onClose={() => {
                  cancelNewBlock(editedBlockKey);
                  focus();
                }}
                onSelect={(assetIds) => {
                  insertImages(editedBlockKey, assetIds);
                  focus();
                }}
                primaryButtonText="Insert"
                showImagesOnly
                titleBarText={`Insert assets to ${editedEntityName}`}
              />
            );
          }
        }

        return baseRenderModal(baseState);
      };

      state.renderModal.decorate(renderModal);

      return {};
    },
    [editedEntityName, getAddBlockMenuItems, limitations, render, trackUserEvent],
  );

  const { getApiMethods } = useEditorApi<ImagesPlugin>(editorImageApi);

  return useEditorWithPlugin(props, { apply, getApiMethods });
};
