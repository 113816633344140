import { forwardRef } from 'react';
import { useHistory } from 'react-router';
import { IAnimatedModalDialogProps } from '../../../../../_shared/components/ModalDialog/IAnimatedModalDialogProps.type.ts';
import { useDispatch } from '../../../../../_shared/hooks/useDispatch.ts';
import { useSelector } from '../../../../../_shared/hooks/useSelector.ts';
import { IStore } from '../../../../../_shared/stores/IStore.type.ts';
import {
  isArchivedWorkflowStep,
  isPublishedWorkflowStep,
  isScheduledToPublishWorkflowStep,
} from '../../../../../_shared/utils/contentItemUtils.ts';
import { shallowEqual } from '../../../../../_shared/utils/shallowEqual.ts';
import { getWorkflow } from '../../../../../data/reducers/workflow/selectors/workflowSelectors.ts';
import { itemEditingModalDismissed } from '../../../actions/contentActions.ts';
import { getEditedVariantWorkflowId } from '../../../selectors/getEditedVariantWorkflowId.ts';
import {
  getHintForWorkflowsCurrentUserCanMoveEditedVariantTo,
  getWorkflowsCurrentUserCanMoveEditedVariantTo,
} from '../../../selectors/workflows/getWorkflowsCurrentUserCanMoveEditedVariantTo.ts';
import { getTooltipForWorkflowsCurrentUserCanMoveEditedVariantTo } from '../../../utils/workflowTooltipUtils.ts';
import { changeWorkflow } from '../../ContentItemEditing/actions/thunkContentItemEditingActions.ts';
import { ChangeWorkflowDialog as ChangeWorkflowDialogComponent } from '../components/ChangeWorkflowDialog.tsx';

export const ChangeWorkflowDialog = forwardRef<HTMLDivElement, IAnimatedModalDialogProps>(
  ({ isOpen }, ref) => {
    const dispatch = useDispatch();
    const history = useHistory();
    const workflowOptions = useSelector(
      getWorkflowsCurrentUserCanMoveEditedVariantTo,
      shallowEqual,
    );
    const workflowsHint = useSelector(getHintForWorkflowsCurrentUserCanMoveEditedVariantTo);

    return (
      <ChangeWorkflowDialogComponent
        currentWorkflowName={useSelector(getCurrentWorkflowName)}
        isOpen={isOpen}
        onClose={() => dispatch(itemEditingModalDismissed())}
        onSubmit={(workflowId) => dispatch(changeWorkflow(workflowId, history))}
        ref={ref}
        showWillBeMovedToFirstStepWarning={useSelector(getShowWillBeMovedToFirstStepWarning)}
        workflowOptions={workflowOptions}
        workflowTooltipText={getTooltipForWorkflowsCurrentUserCanMoveEditedVariantTo(workflowsHint)}
      />
    );
  },
);

ChangeWorkflowDialog.displayName = 'ChangeWorkflowDialog';

const getCurrentWorkflowName = (state: IStore): string => {
  const variantWorkflowId = getEditedVariantWorkflowId(state);

  return (variantWorkflowId && getWorkflow(state, variantWorkflowId)?.name) || '';
};

const getShowWillBeMovedToFirstStepWarning = ({
  contentApp: { editedContentItemVariant: variant },
}: IStore): boolean =>
  [isPublishedWorkflowStep, isScheduledToPublishWorkflowStep, isArchivedWorkflowStep].every(
    (isStep) => !isStep(variant?.assignment.workflowStatus),
  );
