import { ThunkPromise } from '../../../@types/Dispatcher.type.ts';
import { UserFeedbackTimestamps } from '../../../data/models/user/UserFeedbackTimestamps.type.ts';
import { IntercomMessageServerModel } from '../../../repositories/serverModels/IntercomMessageServerModel.type.ts';
import { TrackedEvent } from '../../constants/trackedEvent.ts';
import { UserPropertyServerKey } from '../../models/UserPropertiesModel.ts';
import { UmuxSurveyEventTypes } from '../../models/events/UmuxSurveyEventData.type.ts';
import {
  UmuxFeedbackIntercomMessage,
  UserFeedbackSource,
} from '../../models/events/UserFeedbackEventData.type.ts';
import { repositoryCollection } from '../../repositories/repositories.ts';
import { upsertUserProperty } from '../thunkSharedActions.ts';
import { trackUserEvent } from './trackUserEvent.ts';

type Params = {
  readonly source: UserFeedbackSource;
  readonly propertyServerKey: UserPropertyServerKey;
  readonly feedbackTimestamps: UserFeedbackTimestamps;
  readonly ease: string;
  readonly requirements: string;
  readonly comment: string;
};

export const submitUserFeedback =
  ({
    source,
    propertyServerKey,
    feedbackTimestamps,
    ease,
    requirements,
    comment,
  }: Params): ThunkPromise =>
  async (dispatch) => {
    const feedbackMessage: UmuxFeedbackIntercomMessage = {
      ease,
      comment,
      source,
      requirements,
    };
    await repositoryCollection.userRepository.sendIntercomMessage(
      createFeedbackMessage(feedbackMessage),
    );
    dispatch(trackUserEvent(TrackedEvent.UserFeedback, feedbackMessage));

    const payload: UserFeedbackTimestamps = {
      ...feedbackTimestamps,
      answeredAt: new Date().toISOString(),
      displayedAt: feedbackTimestamps.displayedAt ?? new Date().toISOString(),
    };
    const userFeedbackString = JSON.stringify(payload);
    await dispatch(upsertUserProperty(propertyServerKey, userFeedbackString));
    dispatch(
      trackUserEvent(TrackedEvent.UmuxSurvey, {
        action: UmuxSurveyEventTypes.Sent,
        source,
      }),
    );
  };

const createFeedbackMessage = (
  feedback: UmuxFeedbackIntercomMessage,
): IntercomMessageServerModel => {
  return {
    text: `This message was automatically generated by Kontent.ai. \nFeedback on: ${
      feedback.source
    } \n${createCustomizedFeedbackText(feedback)}`,
  };
};

const createCustomizedFeedbackText = (feedback: UmuxFeedbackIntercomMessage): string => {
  const withComment = feedback.comment ? `Comment: ${feedback.comment}` : '';

  return `Requirements: ${feedback.requirements} \nEase: ${feedback.ease} \n${withComment}`;
};
