import { ThunkPromise } from '../../../../@types/Dispatcher.type.ts';
import { trackUserEvent } from '../../../../_shared/actions/thunks/trackUserEvent.ts';
import { MaximumLimitPerRequest } from '../../../../_shared/constants/scrollGridConstants.ts';
import {
  FindRightAssetTrackedEvent,
  TrackedEvent,
} from '../../../../_shared/constants/trackedEvent.ts';
import { OrderBy } from '../../../../_shared/models/OrderBy.ts';
import { RequestTokenFactory } from '../../../../_shared/utils/requestTokenUtils.ts';
import {
  AssetsRequestTrigger,
  shouldRequestNewItems as shouldRequestNewItemsFunc,
} from '../../../../_shared/utils/scrollGridUtils.ts';
import { isAssetLibraryVisibleQueryEmpty } from '../../../../applications/contentInventory/assets/models/IAssetLibraryQuery.ts';
import { AssetsOrderBy } from '../../../../applications/contentInventory/assets/types/orderBy.type.ts';
import { AssetOrderingCode } from '../../../../applications/contentInventory/assets/utils/assetListingOrderingUtils.ts';
import { mapAssetQueryToServerModel } from '../../../../applications/contentInventory/assets/utils/mapAssetQueryToServerModel.ts';
import {
  IAssetListingServerModel,
  IAssetQueryServerModel,
} from '../../../../repositories/serverModels/AssetServerModels.type.ts';
import {
  Data_AssetListing_Started,
  Data_AssetListing_Success,
} from '../../../constants/dataActionTypes.ts';
import { IAsset } from '../../../models/assets/Asset.ts';
import { createAssetFromServerModel } from '../../../models/assets/assetModelUtils.ts';

interface IDeps {
  readonly assetRepository: {
    readonly getAssets: (
      query: IAssetQueryServerModel,
      abortSignal?: AbortSignal,
    ) => Promise<IAssetListingServerModel>;
  };
  readonly requestTokenFactory: RequestTokenFactory<StartedActionCreator>;
}

type ListingAssetsLoadedActionPayload = {
  readonly assets: ReadonlyArray<IAsset>;
  readonly continuationToken: string | null;
  readonly orderBy: AssetsOrderBy;
  readonly requestTrigger: AssetsRequestTrigger;
};

export const createTokenizedListingAssetsLoadingStarted =
  (raceConditionToken: Uuid) =>
  ({ requestTrigger }: { readonly requestTrigger: AssetsRequestTrigger }) =>
    ({
      type: Data_AssetListing_Started,
      payload: {
        raceConditionToken,
        requestTrigger,
      },
    }) as const;

const listingAssetsLoaded = (payload: ListingAssetsLoadedActionPayload) =>
  ({
    type: Data_AssetListing_Success,
    payload,
  }) as const;

export type StartedActionCreator = ReturnType<typeof createTokenizedListingAssetsLoadingStarted>;
export type LoadListingAssetsActionsType = ReturnType<
  typeof listingAssetsLoaded | StartedActionCreator
>;

type Args = {
  readonly orderBy: OrderBy<AssetOrderingCode>;
  readonly requestTrigger: AssetsRequestTrigger;
};

export const createLoadListingAssetsAction =
  (deps: IDeps) =>
  ({ requestTrigger, orderBy }: Args, abortSignal?: AbortSignal): ThunkPromise =>
  async (dispatch, getState) => {
    const state = getState();
    const {
      data,
      assetLibraryApp: { query, assetListingScrollGridState, openedFolderId },
    } = state;

    const scrollPositionChanged = requestTrigger === AssetsRequestTrigger.UserScroll;
    const shouldRequestNewItems = shouldRequestNewItemsFunc(
      data.assets.loadingStatus,
      data.assets.nextContinuationToken,
      assetListingScrollGridState,
    );

    if (scrollPositionChanged && !shouldRequestNewItems) {
      return;
    }

    if (scrollPositionChanged && shouldRequestNewItems) {
      dispatch(
        trackUserEvent(TrackedEvent.FindRightAsset, {
          name: FindRightAssetTrackedEvent.LoadedMoreAssets,
        }),
      );
    }

    const assetQueryServerModel = mapAssetQueryToServerModel({
      continuationToken: scrollPositionChanged ? data.assets.nextContinuationToken : null,
      folderId: openedFolderId,
      includeSubFolders: !isAssetLibraryVisibleQueryEmpty(query),
      maxAssetsCount: MaximumLimitPerRequest,
      query,
      orderBy,
    });

    const { tokenizedActionCreator: listingAssetsLoadingStarted, isCurrentTokenValid } =
      deps.requestTokenFactory(getState);
    dispatch(listingAssetsLoadingStarted({ requestTrigger }));

    const loadedAssets = await deps.assetRepository.getAssets(assetQueryServerModel, abortSignal);

    if (isCurrentTokenValid()) {
      const assets = loadedAssets.data.map((asset) => createAssetFromServerModel(asset));
      dispatch(
        listingAssetsLoaded({
          assets,
          continuationToken: loadedAssets.continuationToken,
          requestTrigger,
          orderBy,
        }),
      );
    }
  };
