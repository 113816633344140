import React from 'react';
import { useDispatch } from '../../../../../../../../_shared/hooks/useDispatch.ts';
import {
  ContentItemEditingChangeAction,
  ContentItemEditingEventOrigins,
} from '../../../../../../../../_shared/models/events/ContentItemEditingEventData.type.ts';
import { itemEditingModalWithPropertiesOpened } from '../../../../../../actions/contentActions.ts';
import { ItemEditingModalDialogType } from '../../../../../../constants/itemEditingModalDialogType.ts';
import {
  EditingActionDynamicDetailProvider,
  EditingActionDynamicDetailProviderOwnProps,
} from '../../../../components/editingActions/actions/EditingActionDynamicDetailProvider.tsx';
import { trackContentItemEditingAction } from '../../../../utils/editingActions/trackContentItemEditingAction.ts';

export const DiscardNewVersionAction: React.FC<EditingActionDynamicDetailProviderOwnProps> = ({
  children,
  ...props
}) => {
  const dispatch = useDispatch();

  return (
    <EditingActionDynamicDetailProvider
      {...props}
      performAction={(actionOrigin: ContentItemEditingEventOrigins) => {
        dispatch(
          trackContentItemEditingAction(
            itemEditingModalWithPropertiesOpened({
              dialogType: ItemEditingModalDialogType.DiscardNewVersionConfirmationDialog,
              actionOrigin,
            }),
            ContentItemEditingChangeAction.DiscardNewVersion,
          )(actionOrigin),
        );
      }}
    >
      {children}
    </EditingActionDynamicDetailProvider>
  );
};
