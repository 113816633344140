import { OutwardLink } from '@kontent-ai/component-library/Anchor';
import { AvatarSize } from '@kontent-ai/component-library/Avatar';
import { Button } from '@kontent-ai/component-library/Button';
import { Card } from '@kontent-ai/component-library/Card';
import { Inline } from '@kontent-ai/component-library/Inline';
import { Input, InputState } from '@kontent-ai/component-library/Input';
import { Label, LabelSize } from '@kontent-ai/component-library/Label';
import { Stack } from '@kontent-ai/component-library/Stack';
import { Spacing } from '@kontent-ai/component-library/tokens';
import { FC } from 'react';
import { ValidatedInput } from '../../../_shared/components/input/ValidatedInput.tsx';
import { UserAvatar } from '../../../_shared/components/users/UserAvatar.tsx';
import { documentationLinks } from '../../../_shared/constants/documentationLinks.ts';
import { HookFormProps } from '../../../_shared/types/hookFormProps.type.ts';
import {
  DataUiElement,
  getDataUiElementAttribute,
} from '../../../_shared/utils/dataAttributes/DataUiAttributes.ts';
import { ICurrentUserInfo } from '../../../data/models/user/CurrentUserInfo.ts';
import { IUserProfileFormShape } from '../models/IUserProfileFormShape.type.ts';

type Props = {
  readonly currentUser: ICurrentUserInfo;
  readonly formProps: HookFormProps<IUserProfileFormShape>;
  readonly isPristine: boolean;
  readonly isSubmitting: boolean;
  readonly onSubmit: () => void;
};

export const UserProfileForm: FC<Props> = ({
  currentUser,
  formProps,
  isPristine,
  isSubmitting,
  onSubmit,
}) => (
  <Card css="flex: 1" component="section">
    <Card.Headline>Profile details</Card.Headline>
    <Card.Body>
      <form onSubmit={onSubmit}>
        <Stack spacing={Spacing.XL}>
          <Stack spacing={Spacing.L}>
            <UserProfilePicture currentUser={currentUser} />
            <Input value={currentUser.email} inputState={InputState.ReadOnly} label="Email" />
            <ValidatedInput<IUserProfileFormShape>
              label="First name"
              name="firstName"
              maxLength={200}
              formProps={formProps}
            />
            <ValidatedInput<IUserProfileFormShape>
              label="Last name"
              name="lastName"
              maxLength={200}
              formProps={formProps}
            />
          </Stack>
          <Button buttonStyle="primary" type="submit" disabled={isSubmitting || isPristine}>
            {isSubmitting && (
              <Button.ProgressIcon
                {...getDataUiElementAttribute(DataUiElement.SavingInProgressButtonIcon)}
              />
            )}
            <Button.Label>{isSubmitting ? 'Updating profile' : 'Update profile'}</Button.Label>
          </Button>
        </Stack>
      </form>
    </Card.Body>
  </Card>
);

const UserProfilePicture = ({ currentUser }: { readonly currentUser: ICurrentUserInfo }) => (
  <Stack spacing={Spacing.S}>
    <Label size={LabelSize.L}>Profile image</Label>
    <Inline noWrap spacing={Spacing.L}>
      <UserAvatar user={currentUser} size={AvatarSize.L} />
      <div>
        To{' '}
        <OutwardLink href={documentationLinks.accountSettings}>
          manage your profile image
        </OutwardLink>
        , you need a Gravatar account.
      </div>
    </Inline>
  </Stack>
);
