import { isArray, noOperation } from '@kontent-ai/utils';
import { IntercomTrackedEvents } from '../constants/trackedEvent.ts';
import {
  IEventTrackingListener,
  TrackUserEventDirectData,
  eventTrackingService,
} from '../services/eventTrackingService.ts';
import { logError } from './logError.ts';

type IntercomLauncherPosition = Required<
  Pick<
    Intercom_.IntercomSettings,
    | 'alignment'
    | 'horizontal_padding'
    | 'vertical_padding'
    | 'custom_launcher_selector'
    | 'hide_default_launcher'
  >
>;

let warned = false;

/**
 * The position is saved to be later sent with every update.
 * This is due to a bug in the intercom widget. When you set a non-default position and then
 * call update without re-specifying the position, it resets.
 * This can be removed, when this can no longer be reproduced without the code:
 * https://kontent-ai.atlassian.net/browse/KCL-1987?focusedCommentId=330636&page=com.atlassian.jira.plugin.system.issuetabpanels%3Acomment-tabpanel#comment-330636
 */
let intercomPosition: IntercomLauncherPosition = {
  alignment: 'right',
  horizontal_padding: 20,
  vertical_padding: 20,
  custom_launcher_selector: '',
  hide_default_launcher: false,
};

const stringifyArrays = (
  obj: TrackUserEventDirectData,
): ReadonlyRecord<string, string | number | boolean | null | undefined> => {
  const deArrayedEntries = Object.entries(obj).map(
    ([key, value]) => [key, isArray(value) ? JSON.stringify(value) : value] as const,
  );

  return Object.fromEntries(deArrayedEntries);
};

const trackUserEventDirect: IEventTrackingListener = (eventName, metadata): void => {
  if (IntercomTrackedEvents.includes(eventName)) {
    // Intercom cannot work with arrays (produces invalid data)
    const correctedMetadata = metadata && stringifyArrays(metadata);
    Intercom('trackEvent', eventName, correctedMetadata);
  }
};

function boot(settings: Intercom_.IntercomSettings): void {
  Intercom('boot', settings);

  if (self._envConfig.isIntercomTrackingEnabled) {
    eventTrackingService.subscribe(trackUserEventDirect);
  }
}

function shutdown(): void {
  Intercom('shutdown');
  eventTrackingService.unsubscribe(trackUserEventDirect);
}

function showIntercom(): void {
  Intercom('show');
}

function showMessage(prepopulateMessage?: string): void {
  Intercom('showNewMessage', prepopulateMessage);
}

function update(settings: Intercom_.IntercomSettings): void {
  const {
    alignment,
    horizontal_padding,
    vertical_padding,
    custom_launcher_selector,
    hide_default_launcher,
    ...restOfUpdate
  } = settings;

  intercomPosition = {
    ...intercomPosition,
    ...(typeof alignment !== 'undefined' && { alignment }),
    ...(typeof horizontal_padding !== 'undefined' && { horizontal_padding }),
    ...(typeof vertical_padding !== 'undefined' && { vertical_padding }),
    ...(typeof custom_launcher_selector !== 'undefined' && { custom_launcher_selector }),
    ...(typeof hide_default_launcher !== 'undefined' && { hide_default_launcher }),
  };

  Intercom('update', {
    ...intercomPosition,
    ...restOfUpdate,
  });
}

function pathUpdate(): void {
  Intercom('update', intercomPosition);
}

function onShow(callback: () => void): void {
  Intercom('onShow', callback);
}

function onHide(callback: () => void): void {
  Intercom('onHide', callback);
}

function onUnreadCountChange(callback: (unreadCount: number) => void): void {
  Intercom('onUnreadCountChange', callback);
}

const createIntercomCommand = <TFunction extends (...args: any[]) => void>(
  fc: TFunction,
): ((...args: Parameters<TFunction>) => void) => {
  return (...args) => {
    if (!self._envConfig.intercomAppId || typeof Intercom !== 'function') {
      if (!warned) {
        warned = true;
        logError(
          'Intercom has been called without being present! Are you running tests? (If you are, never mind this...)',
        );
      }
      return;
    }

    try {
      fc(...args);
    } catch (e) {
      logError(JSON.stringify(e));
    }
  };
};

const intercomUtils = {
  boot: createIntercomCommand(boot),
  onHide: createIntercomCommand(onHide),
  onShow: createIntercomCommand(onShow),
  onUnreadCountChange: createIntercomCommand(onUnreadCountChange),
  pathUpdate: createIntercomCommand(pathUpdate),
  showIntercom: createIntercomCommand(showIntercom),
  showMessage: createIntercomCommand(showMessage),
  shutdown: createIntercomCommand(shutdown),
  update: createIntercomCommand(update),
} as const;

export type IntercomUtils = Readonly<typeof intercomUtils>;

const noopUtils: IntercomUtils = {
  boot: noOperation,
  onHide: noOperation,
  onShow: noOperation,
  onUnreadCountChange: noOperation,
  pathUpdate: noOperation,
  showIntercom: noOperation,
  showMessage: noOperation,
  shutdown: noOperation,
  update: noOperation,
};

export const getIntercomUtils = (isIntercomDisabled: boolean): IntercomUtils =>
  isIntercomDisabled ? noopUtils : intercomUtils;
