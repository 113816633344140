import { OutwardLink } from '@kontent-ai/component-library/Anchor';
import { Card } from '@kontent-ai/component-library/Card';
import { Icons } from '@kontent-ai/component-library/Icons';
import { Inline } from '@kontent-ai/component-library/Inline';
import {
  SimpleStatusDefault,
  SimpleStatusSuccess,
} from '@kontent-ai/component-library/SimpleStatus';
import { Stack } from '@kontent-ai/component-library/Stack';
import { Spacing } from '@kontent-ai/component-library/tokens';
import React from 'react';
import { documentationLinks } from '../../../../_shared/constants/documentationLinks.ts';
import { LinkLike } from '../../../../_shared/uiComponents/LinkLike/LinkLike.tsx';

export type FallbacksCardProps = {
  readonly areFallbacksForLinkedContentEnabled: boolean | null;
  readonly onContactSupport: () => void;
};

const getStatusComponent = (areFallbacksForLinkedContentEnabled: boolean | null) => {
  switch (areFallbacksForLinkedContentEnabled) {
    case false:
      return <SimpleStatusDefault label="Inactive" icon={Icons.TimesCircle} />;
    case true:
      return <SimpleStatusSuccess label="Active" icon={Icons.CbCheck} />;
    default:
      return <SimpleStatusDefault label="Loading..." icon={Icons.Spinner} />;
  }
};

export const FallbacksCard: React.FC<FallbacksCardProps> = ({
  areFallbacksForLinkedContentEnabled,
  onContactSupport,
}) => (
  <Card component="section">
    <Card.Headline>Fallbacks</Card.Headline>
    <Card.Body>
      <Stack spacing={Spacing.XL}>
        <div>
          <p>
            Every language you create has a fallback on another language. By default, fallback
            languages don’t apply for linked content.
          </p>

          <p>
            To activate{' '}
            <OutwardLink href={documentationLinks.languageFallbacks}>
              fallbacks for linked content
            </OutwardLink>{' '}
            for your project, contact our <LinkLike onClick={onContactSupport}>support</LinkLike> or
            your Customer Success Manager.
          </p>
        </div>

        <Inline align="center" spacingX={Spacing.L}>
          <strong>Fallbacks for linked content</strong>
          {getStatusComponent(areFallbacksForLinkedContentEnabled)}
        </Inline>
      </Stack>
    </Card.Body>
  </Card>
);

FallbacksCard.displayName = 'FallbacksCard';
