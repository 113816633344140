import Immutable from 'immutable';
import React, { useEffect } from 'react';
import { trackUserEvent } from '../../../../_shared/actions/thunks/trackUserEvent.ts';
import {
  FindRightContentTrackedEvent,
  TrackedEvent,
} from '../../../../_shared/constants/trackedEvent.ts';
import { useDispatch } from '../../../../_shared/hooks/useDispatch.ts';
import { useSelector } from '../../../../_shared/hooks/useSelector.ts';
import { ContentItemFilterOrigin } from '../../../../_shared/models/events/ContentItemFilterEventData.type.ts';
import { getItemListForScrollTable } from '../../../../data/reducers/listingContentItems/selectors/getItemListForScrollTable.ts';
import { isItemsInitialLoadFinished } from '../../../../data/reducers/listingContentItems/selectors/isItemsInitialLoadFinished.ts';
import {
  clearContentItemListingFilter,
  filterContentItemsInMultipleItemSelector,
  loadListingContentItemsForContentTypesAction,
  loadListingContentItemsForInventory,
} from '../../../contentInventory/content/features/ContentItemInventory/actions/thunkContentItemInventoryActions.ts';
import { isContentItemFilterInitialized } from '../../../contentInventory/content/features/ListingFilter/containers/ContentItemFilter.tsx';
import { PreselectedFilter } from '../../../contentInventory/content/features/ListingFilter/hooks/useSetUpContentItemFilter.ts';
import { getNumberOfItemsInViewport } from '../../../contentInventory/content/reducers/listingUi/selectors/getNumberOfItemsInViewport.ts';
import {
  modalMultipleContentItemsSelectorClosed,
  modalMultipleContentItemsSelectorOpened,
} from '../actions/modalContentItemSelectorActions.ts';
import { ModalMultipleContentItemsSelector as ModalMultipleContentItemsSelectorComponent } from '../components/ModalMultipleContentItemsSelector.tsx';

type Props = {
  readonly hideVariantSpecificInfo?: boolean;
  readonly itemIsAlreadyLinkedMsg?: string;
  readonly submitButtonText?: string;
  readonly titleBarText: string;
  readonly allowedTypesIds?: Immutable.Set<Uuid>;
  readonly alreadyLinkedItemIds?: Immutable.Set<Uuid>;
  readonly collectionId?: Uuid | undefined;
  readonly onClose: () => void;
  readonly onSelect: (contentItemIds: ReadonlyArray<Uuid>) => void;
  readonly preselectedFilter?: PreselectedFilter;
};

export const ModalMultipleContentItemsSelector: React.FC<Props> = (props) => {
  const dispatch = useDispatch();
  const listingItemsLoadingStatus = useSelector((s) => s.data.listingContentItems.loadingStatus);

  const isFilterInitialized = useSelector((s) =>
    isContentItemFilterInitialized(
      ContentItemFilterOrigin.ModalMultipleContentItemsSelector,
      s.contentApp.listingUi.filterStatus,
    ),
  );
  const renderScrollTable = useSelector(
    (s) => isItemsInitialLoadFinished(s.data.listingContentItems) && isFilterInitialized,
  );

  const isInitRetrieving = useSelector(
    (s) => !isItemsInitialLoadFinished(s.data.listingContentItems),
  );
  const selectedEntries = useSelector((s) => s.contentApp.listingUi.itemsSelection.selectedItemIds);
  const items = useSelector((s) =>
    getItemListForScrollTable(
      s.data.listingContentItems,
      getNumberOfItemsInViewport(s.contentApp.listingUi.contentItemListingScrollTableState),
    ),
  );

  useEffect(() => {
    dispatch(
      modalMultipleContentItemsSelectorOpened(props.alreadyLinkedItemIds ?? Immutable.Set()),
    );
  }, [props.alreadyLinkedItemIds]);

  const onCancel = () => {
    props.onClose();
    dispatch(
      trackUserEvent(TrackedEvent.FindRightContent, {
        name: FindRightContentTrackedEvent.ModalSelectorCancelled,
      }),
    );
  };

  const onSelect = (itemIds: UuidArray) => {
    props.onSelect(itemIds);
    const newCount = itemIds.filter((itemId) => !props.alreadyLinkedItemIds?.has(itemId)).length;
    if (newCount) {
      dispatch(
        trackUserEvent(TrackedEvent.FindRightContent, {
          name: FindRightContentTrackedEvent.InsertedItemsFromModalSelector,
          count: newCount,
        }),
      );
    }
  };

  return (
    <ModalMultipleContentItemsSelectorComponent
      {...props}
      filterContentItems={() => dispatch(filterContentItemsInMultipleItemSelector())}
      items={items}
      isFilterInitialized={isFilterInitialized}
      isInitRetrieving={isInitRetrieving}
      listingItemsLoadingStatus={listingItemsLoadingStatus}
      loadContentItems={
        props.hideVariantSpecificInfo
          ? loadListingContentItemsForContentTypesAction(true)
          : loadListingContentItemsForInventory(true)
      }
      renderScrollTable={renderScrollTable}
      selectedEntries={selectedEntries}
      onCancel={() => {
        dispatch(modalMultipleContentItemsSelectorClosed());
        onCancel();
      }}
      onAssignSelectedEntries={() => {
        dispatch(modalMultipleContentItemsSelectorClosed());
        onSelect(selectedEntries);
      }}
      onItemDoubleClick={(contentItemId: Uuid) => {
        dispatch(modalMultipleContentItemsSelectorClosed());
        onSelect([contentItemId]);
      }}
      onClearFilter={() => dispatch(clearContentItemListingFilter())}
    />
  );
};
