import { assert } from '@kontent-ai/utils';
import { ThunkPromise } from '../../../../../@types/Dispatcher.type.ts';
import { TrackedEvent } from '../../../../../_shared/constants/trackedEvent.ts';
import { TrackUserEventAction } from '../../../../../_shared/models/TrackUserEvent.type.ts';
import {
  ContentItemEditingChangeAction,
  ContentItemEditingEventOrigins,
} from '../../../../../_shared/models/events/ContentItemEditingEventData.type.ts';
import { IContentItemRepository } from '../../../../../repositories/interfaces/IContentItemRepository.type.ts';
import {
  ICollectionReferenceServerModel,
  IContentItemWithVariantServerModel,
} from '../../../../../repositories/serverModels/INewContentItemServerModel.ts';
import { itemEditorOperationIds } from '../../../../contentInventory/content/utils/itemEditorOperationIdUtils.ts';
import { UpdateItemCollectionErrorMessage } from '../../ContentItemEditing/constants/uiConstants.ts';
import {
  ContentEditing_MoveItemToCollection_Finished,
  ContentEditing_MoveItemToCollection_Started,
} from '../constants/moveToCollectionDialogActionTypes.ts';

interface IDeps {
  readonly contentItemRepository: Pick<IContentItemRepository, 'getItemWithVariant'>;
  readonly patchItemProperty: (
    operationId: string,
    value: ICollectionReferenceServerModel,
    patchFailedErrorMessage: string,
  ) => ThunkPromise;
  readonly trackUserEvent: TrackUserEventAction;
}

const started = () => ({ type: ContentEditing_MoveItemToCollection_Started }) as const;
const finished = (updatedContentItem: IContentItemWithVariantServerModel) =>
  ({
    type: ContentEditing_MoveItemToCollection_Finished,
    payload: { itemWithVariant: updatedContentItem },
  }) as const;

export type MoveItemToCollectionActionTypes = ReturnType<typeof started | typeof finished>;

export const createMoveItemToCollectionAction =
  (deps: IDeps) =>
  (collectionId: Uuid): ThunkPromise =>
  async (dispatch, getState) => {
    const {
      contentApp: { editedContentItemVariant },
      data: {
        collections: { byId },
      },
    } = getState();

    assert(editedContentItemVariant, () => '"editedContentItemVariant" is not loaded.');

    const collectionName = byId.get(collectionId)?.name ?? '';
    const saveFailedErrorMessage = UpdateItemCollectionErrorMessage(collectionName);

    dispatch(started());
    await dispatch(
      deps.patchItemProperty(
        itemEditorOperationIds.collection,
        { id: collectionId },
        saveFailedErrorMessage,
      ),
    );
    const updatedContentItem = await deps.contentItemRepository.getItemWithVariant(
      editedContentItemVariant.id.itemId,
      editedContentItemVariant.id.variantId,
    );

    dispatch(
      deps.trackUserEvent(TrackedEvent.ContentItemEditing, {
        action: ContentItemEditingChangeAction.MoveToCollection,
        origin: ContentItemEditingEventOrigins.ItemDetails,
      }),
    );

    dispatch(finished(updatedContentItem));
  };
