import { setUser as setSentryUser } from '@sentry/react';
import { ThunkPromise } from '../../../@types/Dispatcher.type.ts';
import {
  loadGeneralProperties,
  loadUserProperties,
} from '../../../_shared/actions/thunkSharedActions.ts';
import { gtmTrackUser } from '../../../_shared/utils/bootstrap.ts';
import {
  loadAdministratedSubscriptions,
  loadUserInfo,
  loadUserProjectsInfo,
} from '../../../data/actions/thunkDataActions.ts';

export const initializeApp =
  (abortSignal: AbortSignal): ThunkPromise =>
  async (dispatch) => {
    const userInfo = await dispatch(loadUserInfo(abortSignal));

    setSentryUser({
      id: userInfo.userId,
      email: userInfo.email,
    });

    gtmTrackUser(userInfo.userId);

    await Promise.all([
      dispatch(loadUserProperties(userInfo.userId, abortSignal)),
      dispatch(loadUserProjectsInfo(abortSignal)),
      dispatch(loadAdministratedSubscriptions(abortSignal)),
      dispatch(loadGeneralProperties(abortSignal)),
    ]);
  };
