import { Action } from '../../../../@types/Action.type.ts';
import { Shared_AccessUnauthorized } from '../../../../_shared/constants/sharedActionTypes.ts';
import {
  UserProfile_Saved,
  UserProfile_Saving,
} from '../../../../applications/userProfile/constants/userProfileActionTypes.ts';
import { Data_UserInfo_Failed, Data_UserInfo_Success } from '../../../constants/dataActionTypes.ts';
import { ICurrentUserInfo, createCurrentUserInfo } from '../../../models/user/CurrentUserInfo.ts';

const initialState: ICurrentUserInfo = createCurrentUserInfo();

export function info(state: ICurrentUserInfo = initialState, action: Action): ICurrentUserInfo {
  switch (action.type) {
    case Data_UserInfo_Success:
      return action.payload.data;

    case Data_UserInfo_Failed:
    case Shared_AccessUnauthorized:
      return initialState;

    case UserProfile_Saving:
      return {
        ...state,
        isBeingSaved: true,
      };

    case UserProfile_Saved:
      return {
        ...state,
        isBeingSaved: false,
      };

    default:
      return state;
  }
}
