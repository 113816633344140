import { useCallback, useState } from 'react';
import { Loader } from '../../../../../_shared/components/Loader.tsx';
import { ProjectOrderBy } from '../../../../../_shared/constants/projectOrderBy.ts';
import { useDataSelector } from '../../../../../_shared/hooks/useDataSelector.ts';
import { useDispatch } from '../../../../../_shared/hooks/useDispatch.ts';
import { useSelector } from '../../../../../_shared/hooks/useSelector.ts';
import { useThunkPromise } from '../../../../../_shared/hooks/useThunkPromise.ts';
import { LoadingStatus } from '../../../../../_shared/models/LoadingStatusEnum.ts';
import { SidebarNames } from '../../../../../_shared/models/SidebarNames.ts';
import { emptyPlan } from '../../../../../data/models/plans/Plan.ts';
import { IProjectDetails } from '../../../../../data/models/projects/ProjectDetails.ts';
import { emptySubscription } from '../../../../../data/models/subscriptions/Subscription.ts';
import {
  areAllSubscriptionsExpired,
  getAdministratedSubscriptions,
  getSelectedSubscription,
} from '../../../../../data/reducers/subscriptions/selectors/subscriptionSelectors.ts';
import { closeTrialActivationModal } from '../../../../projects/actions/projectsActions.ts';
import {
  activateTrialSubscription,
  initializeProjectManagement,
  toggleProjectActiveState,
} from '../../../../projects/actions/thunkProjectsActions.ts';
import { TrialActivationModal } from '../../../../projects/components/TrialActivationModal.tsx';
import { NewProjectModal } from '../../../../projects/containers/projects/NewProjectModal.tsx';
import { createProjectList } from '../../../../projects/selectors/createProjectList.ts';
import { ProjectUiTransitionState } from '../../../../projects/types/projectUiTransitionState.ts';
import { INewSubscriptionFormShape } from '../../../SubscriptionListing/components/NewSubscriptionForm.tsx';
import { SubscriptionFormContextProvider } from '../../components/SubscriptionFormContext.tsx';
import { SubscriptionProjects as SubscriptionProjectsComponent } from '../../components/SubscriptionProjects/SubscriptionProjects.tsx';

export const SubscriptionProjects = () => {
  const [isCreateModalOpen, setIsCreateModalOpen] = useState(false);

  const projectsLoaded = useSelector(
    (state) => state.data.projects.loadingStatus === LoadingStatus.Loaded,
  );
  const administratedSubscriptions = useSelector((state) =>
    getAdministratedSubscriptions(
      state.data.subscriptions.administratedIds,
      state.data.subscriptions.byId,
    ),
  );
  const selectedSubscription = useSelector(
    (state) => getSelectedSubscription(state) ?? emptySubscription,
  );
  const selectedSubscriptionPlan = useSelector(
    (state) =>
      state.data.plans.byId.get(selectedSubscription?.currentPlan.planId ?? '') ?? emptyPlan,
  );

  const projectsList = useSelector((state) => createProjectList(state, ProjectOrderBy.ProjectName));
  const isEnvironmentsColumnVisible =
    selectedSubscriptionPlan.features.maxProjectEnvironments !== 0 &&
    projectsList.filter((p) => !!p && p.environmentsCount > 0).length > 0;

  const activeProjects = projectsList.filter((project) => !project?.inactive);
  const isProjectsLimitReached =
    !!selectedSubscriptionPlan.features.maxSubscriptionProjects &&
    activeProjects.length >= selectedSubscriptionPlan.features?.maxSubscriptionProjects;

  const dispatch = useDispatch();
  const onToggleProjectActiveState = (projectId: Uuid, activate: ProjectUiTransitionState) =>
    dispatch(toggleProjectActiveState(projectId, activate));

  useThunkPromise(initializeProjectManagement, false);

  const canHaveTrial = useDataSelector((store) => {
    const hadTrial = store.user.info.hadTrial;
    const hasAnyProject = !store.projects.byId.isEmpty();
    const noExpiredOrSuspendedProjects = store.projects.byId.every(
      (p: IProjectDetails) => !p.planIsExpired && !p.planIsSuspended,
    );
    return !hadTrial && hasAnyProject && noExpiredOrSuspendedProjects;
  });
  const isAzureMarketplaceUser = useSelector(
    (state) => state.sharedApp.userProperties.isAzureMarketplaceUser,
  );
  const onActivateTrial = useCallback(
    async (values: INewSubscriptionFormShape) =>
      dispatch(activateTrialSubscription(values)).then(() => setIsCreateModalOpen(false)),
    [],
  );
  const showTrialActivationModal = useSelector((s) => {
    const { isVisible, sidebarName } = s.sharedApp.sidebarStatus;
    return isVisible && sidebarName === SidebarNames.TrialActivationModal;
  });
  const startingTrialInProgress = useSelector((s) => s.projectsApp.activatingTrial);

  if (!projectsLoaded) {
    return <Loader />;
  }

  return (
    <>
      <SubscriptionProjectsComponent
        allSubscriptionsExpired={areAllSubscriptionsExpired(administratedSubscriptions)}
        projectsList={projectsList}
        isCurrentUserAdmin={administratedSubscriptions.length > 0}
        selectedSubscriptionIsActive={selectedSubscription.isActive}
        isEnvironmentsColumnVisible={isEnvironmentsColumnVisible}
        isCreatingProject={isCreateModalOpen}
        isProjectsLimitReached={isProjectsLimitReached}
        projectsLimitCount={selectedSubscriptionPlan.features?.maxSubscriptionProjects ?? 0}
        onToggleProjectActiveState={onToggleProjectActiveState}
        onShowCreateProjectModal={() => setIsCreateModalOpen(true)}
        showStartTrialQuickTip={canHaveTrial && !isAzureMarketplaceUser}
      />
      {isCreateModalOpen && (
        <NewProjectModal
          sourceProjectId={null}
          onClose={() => setIsCreateModalOpen(false)}
          fixedSubscriptionId={selectedSubscription.subscriptionId}
        />
      )}
      <SubscriptionFormContextProvider>
        <TrialActivationModal
          onClose={() => dispatch(closeTrialActivationModal())}
          onFormSubmit={onActivateTrial}
          showSidebar={showTrialActivationModal}
          startingTrialInProgress={startingTrialInProgress}
        />
      </SubscriptionFormContextProvider>
    </>
  );
};
