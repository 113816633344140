import { QuinaryButton } from '@kontent-ai/component-library/Button';
import { useAttachRef } from '@kontent-ai/hooks';
import { useButton } from '@react-aria/button';
import { forwardRef } from 'react';
import {
  AnimatedChevron,
  RestDirection,
} from '../../../uiComponents/AnimatedChevron/AnimatedChevron.tsx';
import {
  DataUiAction,
  getDataUiActionAttribute,
} from '../../../utils/dataAttributes/DataUiAttributes.ts';

interface IBarItemToggleActionProps {
  readonly ariaControls?: string;
  readonly ariaLabel: string;
  readonly disabled?: boolean;
  readonly isCollapsed: boolean;
  readonly onExpand?: () => void;
  readonly onCollapse?: () => void;
}

export const BarItemToggleAction = forwardRef<HTMLButtonElement, IBarItemToggleActionProps>(
  ({ disabled, isCollapsed, onExpand, onCollapse, ariaLabel, ...props }, forwardedRef) => {
    const { refObject, refToForward } = useAttachRef(forwardedRef);

    const { buttonProps } = useButton(
      {
        ...props,
        'aria-expanded': !isCollapsed,
        onPress: disabled ? undefined : isCollapsed ? onExpand : onCollapse,
      },
      refObject,
    );

    return (
      <QuinaryButton
        disabled={disabled}
        aria-label={ariaLabel}
        tooltipText=""
        ref={refToForward}
        {...buttonProps}
        {...getDataUiActionAttribute(isCollapsed ? DataUiAction.Expand : DataUiAction.Collapse)}
      >
        <AnimatedChevron isTurned={!isCollapsed} restDirection={RestDirection.Down} />
      </QuinaryButton>
    );
  },
);

BarItemToggleAction.displayName = 'BarItemToggleAction';
