import { ContentItemWorkflowStepBulkActionEventData } from '../../../../../../_shared/models/TrackUserEventData.ts';
import { IWorkflowStep } from '../../../../../../data/models/workflow/WorkflowStep.ts';

export const createUpdateWorkflowEventDataForRestore = (
  workflowStep: IWorkflowStep,
  modifiedVariantsCount: number,
): ContentItemWorkflowStepBulkActionEventData =>
  createUpdateWorkflowStepEventData(workflowStep, modifiedVariantsCount, true);

export const createUpdateWorkflowEventDataForUpdateStep = (
  workflowStep: IWorkflowStep,
  modifiedVariantsCount: number,
): ContentItemWorkflowStepBulkActionEventData =>
  createUpdateWorkflowStepEventData(workflowStep, modifiedVariantsCount, false);

export const createUpdateWorkflowStepEventData = (
  workflowStep: IWorkflowStep,
  modifiedVariantsCount: number,
  isRestore: boolean,
): ContentItemWorkflowStepBulkActionEventData => {
  return {
    'wf-step-name': workflowStep ? workflowStep.name : '',
    'wf-step-id': workflowStep ? workflowStep.id : '',
    count: modifiedVariantsCount,
    isRestore,
  };
};
