import { memoize } from '@kontent-ai/memoization';
import { Collection } from '@kontent-ai/utils';
import React from 'react';
import { connect } from 'react-redux';
import { IStore } from '../../../../../../_shared/stores/IStore.type.ts';
import { getUsersByIds } from '../../../../../../_shared/utils/users/usersUtils.ts';
import {
  IItemLiveUsersNotificationBarOwnProps,
  IItemLiveUsersNotificationBarStateProps,
  ItemLiveUsersNotificationBar as ItemLiveUsersNotificationBarComponent,
} from '../../components/contentItemEditorNotifications/ItemLiveUsersNotificationBar.tsx';

const removeCurrentUserFromLiveIds = memoize.weak(
  (liveUserIds: ReadonlySet<UserId>, currentUserId: UserId): ReadonlyArray<UserId> => [
    ...Collection.remove(liveUserIds, currentUserId),
  ],
);

const mapStateToProps = (state: IStore): IItemLiveUsersNotificationBarStateProps => {
  const {
    contentApp: {
      editorUi: { itemLiveUserIds },
    },
    data: { user, users },
  } = state;

  const currentUserId = user.info.userId;
  const currentUser = users.usersById.get(currentUserId) ?? null;
  const fellowLiveUserIds = removeCurrentUserFromLiveIds(itemLiveUserIds, currentUserId);
  const fellowLiveUsers = getUsersByIds(users.usersById, fellowLiveUserIds);

  return {
    currentUser,
    fellowLiveUsers,
  };
};

export const ItemLiveUsersNotificationBar: React.ComponentType<
  React.PropsWithChildren<IItemLiveUsersNotificationBarOwnProps>
> = connect(mapStateToProps)(ItemLiveUsersNotificationBarComponent);
