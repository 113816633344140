import { UserFeedbackSource } from './UserFeedbackEventData.type.ts';

export enum UmuxSurveyEventTypes {
  AutomaticallyShown = 'automaticallyShown',
  Close = 'close',
  Open = 'open',
  Sent = 'sent',
}

export type UmuxSurveyEventData = {
  readonly action: UmuxSurveyEventTypes;
  readonly source: UserFeedbackSource;
};
