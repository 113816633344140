import { Icons } from '@kontent-ai/component-library/Icons';
import { colorIconDisabled } from '@kontent-ai/component-library/tokens';
import React from 'react';
import { WidgetBaseEmptyState } from '../../shared/components/WidgetBaseEmptyState.tsx';
import {
  NoPublishedItemsMessage,
  NoPublishedItemsTitle,
} from '../../shared/constants/uiConstants.ts';

export const WidgetEmptyState: React.FC = () => {
  return (
    <WidgetBaseEmptyState
      icon={Icons.Doc}
      iconColor={colorIconDisabled}
      title={NoPublishedItemsTitle}
      message={NoPublishedItemsMessage}
    />
  );
};
