import { Dispatch, ThunkFunction } from '../../../../../../@types/Dispatcher.type.ts';
import { trackUserEvent } from '../../../../../../_shared/actions/thunks/trackUserEvent.ts';
import { TrackedEvent } from '../../../../../../_shared/constants/trackedEvent.ts';
import { OpenWorkflowStepModalAction } from '../../../../../../_shared/features/ChangeWorkflowStepModal/types/workflowStepModalActionTypes.ts';
import {
  ContentItemEditingEventOrigins,
  ContentItemEditingTriggerDialogAction,
} from '../../../../../../_shared/models/events/ContentItemEditingEventData.type.ts';
import { IOpenChangeWorkflowStepModal } from './openChangeWorkflowStepModal.ts';

interface IDeps {
  readonly openChangeWorkflowStepModal: IOpenChangeWorkflowStepModal;
}

export const createOpenUnpublishAndArchiveModalAction =
  ({ openChangeWorkflowStepModal }: IDeps) =>
  (actionOrigin: ContentItemEditingEventOrigins): ThunkFunction =>
  (dispatch: Dispatch) => {
    dispatch(
      openChangeWorkflowStepModal(OpenWorkflowStepModalAction.UnpublishAndArchive, actionOrigin),
    );

    dispatch(
      trackUserEvent(TrackedEvent.ContentItemEditing, {
        action: ContentItemEditingTriggerDialogAction.ChangeWorkflowStepSelected,
        origin: actionOrigin,
      }),
    );
  };
