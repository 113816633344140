import { MapUtils } from '../../../../../_shared/utils/mapUtils/mapUtils.ts';
import { SetUtils } from '../../../../../_shared/utils/setUtils/setUtils.ts';
import { isString } from '../../../../../_shared/utils/stringUtils.ts';
import {
  IContentItemStatus,
  checkEqualityOfContentItemStatus,
  emptyContentItemStatus,
  isContentItemStatusEmpty,
} from './IContentItemStatus.ts';
import {
  IPublishingState,
  checkEqualityOfPublishingStates,
  emptyPublishingState,
  isPublishingStateEmpty,
} from './IPublishingState.ts';

export enum SearchScope {
  Content = 'Content',
  Name = 'Name',
  Ai = 'Ai',
}

export const isSearchScope = (input: unknown): input is SearchScope =>
  isString(input) && Object.values(SearchScope).includes(input as SearchScope);

export type ISelectedWorkflowNodes = ReadonlyMap<Uuid, ReadonlySet<Uuid>>;
export type ISelectedTaxonomyNodes = ReadonlyMap<Uuid, ReadonlySet<Uuid>>;

export interface IListingFilter {
  readonly selectedCollectionsNodes: ReadonlySet<Uuid>;
  readonly selectedContentItemStatus: IContentItemStatus;
  readonly selectedContentTypesNodes: ReadonlySet<Uuid>;
  readonly selectedContributorsNodes: ReadonlySet<string>;
  readonly selectedPublishingStateNodes: IPublishingState;
  readonly selectedSitemapNodes: ReadonlySet<Uuid>;
  readonly selectedSpacesNodes: ReadonlySet<Uuid>;
  readonly selectedTaxonomyNodes: ISelectedTaxonomyNodes;
  readonly selectedWorkflowNodes: ISelectedWorkflowNodes;

  readonly searchPhrase: string;
  readonly searchScope: ReadonlySet<SearchScope>;
}

export const emptyListingFilter: IListingFilter = {
  searchPhrase: '',
  searchScope: new Set([SearchScope.Name, SearchScope.Content]),
  selectedCollectionsNodes: new Set(),
  selectedContentItemStatus: emptyContentItemStatus,
  selectedContentTypesNodes: new Set(),
  selectedContributorsNodes: new Set(),
  selectedPublishingStateNodes: emptyPublishingState,
  selectedSitemapNodes: new Set(),
  selectedSpacesNodes: new Set(),
  selectedTaxonomyNodes: new Map(),
  selectedWorkflowNodes: new Map(),
};

export const isListingFilterEmpty = (
  filter: IListingFilter,
  forcedContentTypes?: ReadonlySet<Uuid>,
): boolean => getActiveFiltersCount(filter, forcedContentTypes) <= 0;
export const areListingFilterAndSearchEmpty = (filter: IListingFilter): boolean =>
  isListingFilterEmpty(filter) && !filter.searchPhrase;

function getActiveFiltersCount(
  filter: IListingFilter,
  forcedContentTypes: ReadonlySet<Uuid> | undefined,
): number {
  const activeFiltersCount = [
    filter.selectedSitemapNodes.size,
    filter.selectedWorkflowNodes.size,
    filter.selectedCollectionsNodes.size,
    filter.selectedSpacesNodes.size,
    filter.selectedContributorsNodes.size,
    filter.selectedContentTypesNodes.size && !forcedContentTypes?.size,
    filter.selectedTaxonomyNodes.size,
    !isPublishingStateEmpty(filter.selectedPublishingStateNodes),
    !isContentItemStatusEmpty(filter.selectedContentItemStatus),
  ].filter(Boolean).length;

  return activeFiltersCount;
}

export const areListingFiltersEqual = (x: IListingFilter, y: IListingFilter): boolean =>
  x.searchPhrase.trim() === y.searchPhrase.trim() &&
  SetUtils.isEqual(x.searchScope, y.searchScope) &&
  checkEqualityOfPublishingStates(x.selectedPublishingStateNodes, y.selectedPublishingStateNodes) &&
  checkEqualityOfContentItemStatus(x.selectedContentItemStatus, y.selectedContentItemStatus) &&
  SetUtils.isEqual(x.selectedSitemapNodes, y.selectedSitemapNodes) &&
  MapUtils.isEqual(x.selectedWorkflowNodes, y.selectedWorkflowNodes, SetUtils.isEqual) &&
  SetUtils.isEqual(x.selectedCollectionsNodes, y.selectedCollectionsNodes) &&
  SetUtils.isEqual(x.selectedSpacesNodes, y.selectedSpacesNodes) &&
  SetUtils.isEqual(x.selectedContentTypesNodes, y.selectedContentTypesNodes) &&
  MapUtils.isEqual(x.selectedTaxonomyNodes, y.selectedTaxonomyNodes, SetUtils.isEqual) &&
  SetUtils.isEqual(x.selectedContributorsNodes, y.selectedContributorsNodes);
