import { Button } from '@kontent-ai/component-library/Button';
import { Icons } from '@kontent-ai/component-library/Icons';
import React from 'react';
import {
  DataUiAction,
  getDataUiActionAttribute,
} from '../../utils/dataAttributes/DataUiAttributes.ts';

export const UserListingFilterClearButton: React.FC<{ readonly onClear: () => void }> = ({
  onClear,
}) => (
  <Button
    buttonStyle="tertiary"
    size="medium"
    onClick={onClear}
    destructive
    destructiveIcon={Icons.Times}
    {...getDataUiActionAttribute(DataUiAction.ClearFilter)}
  >
    clear
  </Button>
);

UserListingFilterClearButton.displayName = 'UserListingFilterClearButton';
