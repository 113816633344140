import { useSelector } from '../../../../_shared/hooks/useSelector.ts';
import { LoadingStatus } from '../../../../_shared/models/LoadingStatusEnum.ts';
import { selectIntercomUtils } from '../../../../_shared/selectors/selectIntercomUtils.ts';
import { IStore } from '../../../../_shared/stores/IStore.type.ts';
import { isListingFilterEmpty } from '../../../contentInventory/content/models/filter/IListingFilter.ts';
import { ProjectOverview as ProjectOverviewComponent } from '../components/ProjectOverview.tsx';
import { WorkflowOverviewState } from '../models/WorkflowOverviewState.ts';
import { createWorkflowOverview } from '../utils/createWorkflowOverview.ts';

const getWorkflowOverviewState = (state: IStore): WorkflowOverviewState => {
  const {
    contentApp: {
      listingUi: { filter },
    },
    projectOverviewApp: { loadingStatus },
  } = state;

  if (loadingStatus !== LoadingStatus.Loaded) {
    return WorkflowOverviewState.Loading;
  }

  if (filter.selectedWorkflowNodes.size > 0) {
    return WorkflowOverviewState.FilteredByWorkflow;
  }

  if (!isListingFilterEmpty(filter)) {
    return WorkflowOverviewState.FilteredByOthers;
  }

  return WorkflowOverviewState.Default;
};

export const ProjectOverview = () => {
  const workflowOverviewState = useSelector(getWorkflowOverviewState);
  const itemsWithoutDueDateCount = useSelector(
    (state) => state.projectOverviewApp.aggregations.itemsWithoutDueDatesIds.size,
  );
  const itemsOnTrackCount = useSelector(
    (state) => state.projectOverviewApp.aggregations.itemsOnTrackIds.size,
  );
  const delayedItemsCount = useSelector(
    (state) => state.projectOverviewApp.aggregations.delayedItemsIds.size,
  );
  const scheduledToPublishItemsCount = useSelector(
    (state) => state.projectOverviewApp.aggregations.scheduledToPublishItemsIds.size,
  );

  const workflows = useSelector((s) => {
    return createWorkflowOverview(
      s.projectOverviewApp.aggregations.itemsByWorkflowByStep,
      s.contentApp.listingUi.filter.selectedWorkflowNodes,
      s.data.workflows.byId,
    );
  });

  const intercomUtils = useSelector(selectIntercomUtils);

  return (
    <ProjectOverviewComponent
      delayedItemsCount={delayedItemsCount}
      itemsOnTrackCount={itemsOnTrackCount}
      itemsWithoutDueDateCount={itemsWithoutDueDateCount}
      onContactSupport={intercomUtils.showMessage}
      scheduledToPublishItemsCount={scheduledToPublishItemsCount}
      workflowOverviewState={workflowOverviewState}
      workflows={workflows}
    />
  );
};
