import { OrderedMap } from 'immutable';
import { ILanguage } from '../../../../../data/models/languages/Language.ts';
import { getLanguageName } from '../../../../environmentSettings/localization/utils/languageUtils.ts';

export const getLanguageNameFromVariantId = (
  variantId: Uuid,
  defaultLanguage: ILanguage,
  languagesById: OrderedMap<string, ILanguage>,
) => {
  return variantId
    ? (getLanguageName(variantId, defaultLanguage, languagesById) ?? 'Unknown variant')
    : 'Unknown variant';
};
