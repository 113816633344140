import { ThunkPromise } from '../../../../@types/Dispatcher.type.ts';
import { TrackedEvent } from '../../../../_shared/constants/trackedEvent.ts';
import { TrackUserEventAction } from '../../../../_shared/models/TrackUserEvent.type.ts';
import {
  IWebhookSetting,
  createWebhookSettingDomainModel,
} from '../../../../data/models/webhooks/WebhookSetting.ts';
import { IWebhookRepository } from '../../../../repositories/interfaces/IWebhookRepository.type.ts';
import { IWebhookServerModel } from '../../../../repositories/serverModels/WebhookServerModel.ts';
import {
  Webhooks_Webhook_EnableCompleted,
  Webhooks_Webhook_EnableFailed,
  Webhooks_Webhook_EnableStarted,
} from '../../constants/webhooksActionTypes.ts';

interface IEnableWebhookDependencies {
  readonly webhookRepository: IWebhookRepository;
  readonly trackUserEvent: TrackUserEventAction;
}

const started = (webhook: IWebhookSetting) =>
  ({
    type: Webhooks_Webhook_EnableStarted,
    payload: {
      webhook,
    },
  }) as const;

const completed = (webhook: IWebhookSetting) =>
  ({
    type: Webhooks_Webhook_EnableCompleted,
    payload: {
      webhook,
    },
  }) as const;

const failed = (webhook: IWebhookSetting) =>
  ({
    type: Webhooks_Webhook_EnableFailed,
    payload: {
      webhook,
    },
  }) as const;

export type EnableWebhookActionsType = ReturnType<
  typeof started | typeof completed | typeof failed
>;

export const createEnableWebhookAction =
  (deps: IEnableWebhookDependencies) =>
  (webhook: IWebhookSetting): ThunkPromise =>
  async (dispatch): Promise<void> => {
    dispatch(started(webhook));

    try {
      const response: IWebhookServerModel = await deps.webhookRepository.enable(webhook.id);
      dispatch(completed(createWebhookSettingDomainModel(response)));
      dispatch(deps.trackUserEvent(TrackedEvent.WebhookEnabled, { url: response.url }));
    } catch {
      dispatch(failed(webhook));
    }
  };
