import { Dispatch, GetState, ThunkFunction } from '../../../../../@types/Dispatcher.type.ts';
import { TrackedEvent } from '../../../../../_shared/constants/trackedEvent.ts';
import { TrackUserEventAction } from '../../../../../_shared/models/TrackUserEvent.type.ts';

interface IDeps {
  readonly trackUserEvent: TrackUserEventAction;
}

export const createTrackUserListingFilterChange =
  (deps: IDeps) =>
  (): ThunkFunction =>
  (dispatch: Dispatch, getState: GetState): void => {
    const {
      usersApp: { filter },
    } = getState();

    dispatch(
      deps.trackUserEvent(TrackedEvent.UserListingFilterChanged, {
        languageIds: [...filter.byLanguage],
        roleIds: [...filter.byRoles],
        status: filter.byStatus,
      }),
    );
  };
